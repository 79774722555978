<!-- <app-flood-light></app-flood-light> -->

<form #withdrawalForm="ngForm" (ngSubmit)="onSubmit()">

    <div class="container-menuView">

        <section>
            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="withdrawalForm.invalid && isFormSubmit" translateTag="withdrawal"></app-invalid-form>

            <div role="alert" class="alert alert-danger" *ngIf="false">
                <p>
                    <strong translate="withdrawal.errors.criticalError"></strong>
                    <br />
                    <span translate="withdrawal.errors.criticalErrorDescription"></span>
                </p>
            </div>


            <h1 translate="withdrawal.title"></h1>
            <p [innerHTML]="'withdrawal.description' | translate | safeHtml" class="add-margin b32px"></p>

            <h2 id="ariaWithdrawalAnnual" translate="withdrawal.withdrawalAnnualTitle"></h2>

            <div class="more-info-wrapper add-margin b24px">

                <a role="button" id="withdrawAnnualAmountTitleOpen" aria-controls="withdrawAnnualAmountOpen"
                    aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                    attr.aria-label="{{ 'withdrawal.ariaLabels.withdrawalAnnual' | translate }}">
                    <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                    <span class="icon icon-large chevron-down-blue"></span>
                </a>

                <div class="more-info-content new-bmo-card-content" id="withdrawAnnualAmountOpen">
                    <p translate="withdrawal.withdrawalAnnualDescription01"></p>
                    <p translate="withdrawal.withdrawalAnnualDescription02"></p>
                    <p>
                        <a role="button" id="withdrawAnnualAmountTitleClose" aria-controls="withdrawAnnualAmountTitleClose"
                            aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                            attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                            <span class="btn-icon-text-left" translate="linkButton.close"></span>
                            <span class="icon icon-large chevron-up-blue"></span>
                        </a>
                    </p>

                </div>

                <!-- <app-contextual-help class="button-wrapper va-middle"
                                arialabel="withdrawal.ariaLabels.withdrawalAnnual"
                                    content="withdrawal.contextualHelp.withdrawalAnnual"
                                    inputDataAna="investorline:wealth:onboarding:rrif:amount info">
                </app-contextual-help> -->
            </div>

            <div class="form-wrapper">
                <div role="radiogroup"
                     aria-labelledby="ariaWithdrawalAnnual"
                     id='withdrawal-annual-error'
                     class="form-row">

                    <div class="form-col">
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': annualAmountSelectCtrl.errors && annualAmountSelectCtrl.touched || (isFormSubmit && annualAmountSelectCtrl.errors)}">
                            <input id="minAnnualAmmount"
                                   name="AnnualAmmount"
                                   data-ana="investorline:wealth:onboarding:rrif:amount:min"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.amountType"
                                   (change)="setAmountAndTax()"
                                   value="1"
                                   #annualAmountSelectCtrl="ngModel" required />
                            <label class="prefix" for="minAnnualAmmount" translate="withdrawal.labels.minAnnualAmmount"></label>
                        </div>
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': annualAmountSelectCtrl.errors && annualAmountSelectCtrl.touched || (isFormSubmit && annualAmountSelectCtrl.errors)}">
                            <input id="moreMinAnnualAmmount"
                                   name="AnnualAmmount"
                                   data-ana="investorline:wealth:onboarding:rrif:amount:max"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.amountType"
                                   value="2"
                                   (change)="setAmountAndTax()"
                                   #annualAmountSelectCtrl="ngModel" required />
                            <label class="prefix" for="moreMinAnnualAmmount" translate="withdrawal.labels.moreMinAnnualAmmount"></label>
                        </div>
                        <div role="alert" class="error-message" id="AnnualAmmount-error" *ngIf="annualAmountSelectCtrl.errors && annualAmountSelectCtrl.touched || (isFormSubmit && annualAmountSelectCtrl.errors)">
                            <span translate="withdrawal.errors.minAnnualAmmount"></span>
                        </div>
                    </div>
                </div>
                <div class="form-row" *ngIf="isGreaterAmtSelected">
                    <div class="form-col">
                        <div class="form-field">
                            <div class="form-label-group">
                                <input id="annualAmmountFeild"
                                       class="no-text-transform"
                                       type="tel"
                                       name="annualAmmountFeild"
                                       data-ana="investorline:wealth:onboarding:rrif:amount:more:specify"
                                       [(ngModel)]="withdrawalInformationObj.amount"
                                       #annualAmountCtrl="ngModel" minLength="1" maxLength="8" appCurrency InputValidator="ct3" required />
                                <label [ngClass]="{'error-field': annualAmountCtrl.invalid && annualAmountCtrl.touched  || (isFormSubmit && annualAmountCtrl.errors), 'active-label': annualAmountCtrl.value}"
                                       class="slide-label" for="annualAmmountFeild">
                                    <span class="label-text" translate="withdrawal.labels.annualAmmountFeild"></span>
                                </label>
                            </div>
                        </div>
                        <div role="alert" id="annualAmmountFeild-error" class="error-message" *ngIf="annualAmountCtrl.invalid && annualAmountCtrl.touched  || (isFormSubmit && annualAmountCtrl.errors) ||  (annualAmountCtrl.touched && annualAmountCtrl?.errors?.minLengthError)">
                            <span translate="withdrawal.errors.annualAmmountFeild"></span>
                        </div>

                    </div>



                </div>
            </div>

            <h2 translate="withdrawal.withdrawalTaxTitle" *ngIf="isGreaterAmtSelected"></h2>

            <div class="form-wrapper" *ngIf="isGreaterAmtSelected">
                <div role="radiogroup"
                     id='withdrawal-annual-error'
                     class="form-row">

                    <div class="form-col">
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': taxTypeCtrl.errors && taxTypeCtrl.touched || (isFormSubmit && taxTypeCtrl.errors)}">
                            <input id="beforeTax"
                                   name="taxType"
                                   data-ana="investorline:wealth:onboarding:rrif:amount:gross"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.greaterThanMinimumAmountType"
                                   value="1"
                                   #taxTypeCtrl="ngModel" required />
                            <label class="prefix" for="beforeTax" translate="withdrawal.labels.beforeTax"></label>
                        </div>
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': taxTypeCtrl.errors && taxTypeCtrl.touched || (isFormSubmit && taxTypeCtrl.errors)}">
                            <input id="afterTax"
                                   name="taxType"
                                   data-ana="investorline:wealth:onboarding:rrif:amount:net"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.greaterThanMinimumAmountType"
                                   value="2"
                                   #taxTypeCtrl="ngModel" required />
                            <label class="prefix" for="afterTax" translate="withdrawal.labels.afterTax"></label>
                        </div>
                        <div role="alert" class="error-message" id="taxType-error" *ngIf="taxTypeCtrl.errors && taxTypeCtrl.touched || (isFormSubmit && taxTypeCtrl.errors)">
                            <span translate="withdrawal.errors.taxType"></span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="withdrawal.withdrawalSpouseTitle" *ngIf="showPartnerAgeRqmt"></h2>
            <p translate="withdrawal.withdrawalSpouseDescription" id="ariaWithdrawalSpouse" *ngIf="showPartnerAgeRqmt"></p>
            <div class="alert alert-info" role="alert" *ngIf="withdrawalInformationObj.isSpouseAgeUsed === 'true'">
                <p class="font-bold" translate="withdrawal.alertTakeNoteTitle"></p>
                <p translate="withdrawal.alertTakeNoteDescription"></p>
            </div>
            <div class="form-wrapper" *ngIf="showPartnerAgeRqmt">
                <div role="radiogroup"
                     aria-labelledby="ariaWithdrawalSpouse"
                     id='withdrawal-spouse-error'
                     class="form-row">

                    <div class="form-col">
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': spouseWithdrawalCtrl.errors && spouseWithdrawalCtrl.touched || (isFormSubmit && spouseWithdrawalCtrl.errors)}">
                            <input id="spouseYes"
                                   name="spouseWithdrawal"
                                   data-ana="investorline:wealth:onboarding:rrif:spouse dob:yes"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.isSpouseAgeUsed"
                                   value="true"
                                   #spouseWithdrawalCtrl="ngModel" required />
                            <label class="prefix" for="spouseYes" translate="withdrawal.labels.yes"></label>
                        </div>
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': spouseWithdrawalCtrl.errors && spouseWithdrawalCtrl.touched || (isFormSubmit && spouseWithdrawalCtrl.errors)}">
                            <input id="spouseNo"
                                   name="spouseWithdrawal"
                                   data-ana="investorline:wealth:onboarding:rrif:spouse dob:no"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.isSpouseAgeUsed"
                                   value="false"
                                   #spouseWithdrawalCtrl="ngModel" required />
                            <label class="prefix" for="spouseNo" translate="withdrawal.labels.no"></label>
                        </div>
                        <div role="alert" class="error-message" id="spouseYes-error" *ngIf="spouseWithdrawalCtrl.errors && spouseWithdrawalCtrl.touched || (isFormSubmit && spouseWithdrawalCtrl.errors)">
                            <span translate="withdrawal.errors.spouseWithdrawal"></span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="withdrawal.withdrawalSpouseBirthTitle" id="SpouseBirthTitle" *ngIf="withdrawalInformationObj.isSpouseAgeUsed === 'true'"></h2>
            <div class="form-wrapper" *ngIf="withdrawalInformationObj.isSpouseAgeUsed === 'true'">
                <div class="form-row">
                    <div class="flex-60 form-col">
                        <div class="form-field">
                            <div class="form-label-group">
                                <app-date-picker id="dateOfBirth"
                                                 name="dateOfBirth"
                                                 data-ana="investorline:wealth:onboarding:rrif:spouse dob"
                                                 [(ngModel)]="withdrawalInformationObj.spouseDateOfBirth"
                                                 #spouseDateOfBirthCtrl="ngModel"
                                                 [readonly]="disableSpouseDob"
                                                 (underAgeValidation) = "isUnder18Age($event)">
                                </app-date-picker>
                            </div>
                        </div>
                        <div role="alert" class="error-message" id="SpouseBirthTitle-error" *ngIf="spouseDateOfBirthCtrl?.invalid && spouseDateOfBirthCtrl?.touched || (isFormSubmit && spouseDateOfBirthCtrl?.errors)">

                            <span *ngIf="!isAgeUnder18" translate="withdrawal.errors.SpouseBirthTitle"></span>
                            <span *ngIf="isAgeUnder18" translate="withdrawal.errors.SpouseBirthTitleUnderage"></span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="withdrawal.withdrawalAmmountTitle" id="ariaWithdrawalAmmountTitle"></h2>
            <p translate="withdrawal.labels.withdrawalAmmountChooseTitle"></p>
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="flex-60 form-col">
                        <div class="form-field">
                            <div class="form-label-group">
                                <select id="withdrawalAmountTime"
                                        name="withdrawalAmountTime"
                                        data-ana="investorline:wealth:onboarding:rrif:frequency"
                                        [(ngModel)]="withdrawalInformationObj.frequency"
                                        (change)="setMonthList()"
                                        #withdrawalAmmountTimeCtl="ngModel" required
                                        aria-labelledby="ariaWithdrawalAmmountTitle">
                                    <option *ngFor="let freq of frequencyList" [value]="freq.code">
                                        {{freq.label}}
                                    </option>
                                </select>



                                <label [ngClass]="{'error-field': withdrawalAmmountTimeCtl.invalid && withdrawalAmmountTimeCtl.touched  || (isFormSubmit && withdrawalAmmountTimeCtl.errors), 'active-label': withdrawalAmmountTimeCtl.value}"
                                       class="slide-label" for="withdrawalAmountTime">
                                    <span class="label-text" translate="withdrawal.labels.frequency"></span>
                                </label>
                            </div>
                        </div>
                        <div role="alert" id="withdrawalTime-error" class="error-message" *ngIf="withdrawalAmmountTimeCtl.errors && withdrawalAmmountTimeCtl.touched || (isFormSubmit && withdrawalAmmountTimeCtl.errors)">
                            <span translate="withdrawal.errors.withdrawalAmmountTime"></span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="withdrawal.withdrawalMonthTitle" id="ariaWithdrawalMonthTitle"></h2>
            <p translate="withdrawal.withdrawalMonthDescription"></p>
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="flex-60 form-col">
                        <div class="form-field">
                            <div class="form-label-group">
                                <select id="withdrawalTime"
                                        name="withdrawalTime"
                                        data-ana="investorline:wealth:onboarding:rrif:start month"
                                        [(ngModel)]="withdrawalInformationObj.startMonth"
                                        #withdrawalTimeCtl="ngModel" required
                                        aria-labelledby="ariaWithdrawalMonthTitle">
                                        <option  *ngIf="isIEBrowser || isIosDevice"></option>
                                    <option *ngFor="let startMonth of startMonthList" [value]="startMonth.code">
                                        {{startMonth.label}}
                                    </option>
                                </select>

                                <label [ngClass]="{'error-field': withdrawalTimeCtl.invalid && withdrawalTimeCtl.touched  || (isFormSubmit && withdrawalTimeCtl.errors), 'active-label': withdrawalTimeCtl.value}"
                                       class="slide-label" for="withdrawalTime">
                                    <span class="label-text" translate="withdrawal.labels.month"></span>
                                </label>
                            </div>
                        </div>
                        <div role="alert" id="withdrawalTime-error" class="error-message" *ngIf="withdrawalTimeCtl.errors && withdrawalTimeCtl.touched || (isFormSubmit && withdrawalTimeCtl.errors)">
                            <span translate="withdrawal.errors.withdrawalTime"></span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="withdrawal.withdrawalTimeMonthTitle" id="ariaWthdrawalTimeMonthTitle"></h2>
            <p translate="withdrawal.withdrawalTimeMonthDescription"></p>
            <div class="form-wrapper">
                <div role="radiogroup"
                     aria-labelledby="ariaWthdrawalTimeMonthTitle"
                     id='withdrawal-time-month-error'
                     class="form-row">

                    <div class="form-col">
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': whenInMonthCtrl.errors && whenInMonthCtrl.touched || (isFormSubmit && whenInMonthCtrl.errors)}">
                            <input id="mid"
                                   name="timeMonth"
                                   data-ana="investorline:wealth:onboarding:rrif:month mid"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.whenInMonth"
                                   #whenInMonthCtrl="ngModel"
                                   value="1"
                                   required />
                            <label class="prefix" for="mid" translate="withdrawal.labels.mid"></label>
                        </div>
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': whenInMonthCtrl.errors && whenInMonthCtrl.touched || (isFormSubmit && whenInMonthCtrl.errors)}">
                            <input id="end"
                                   name="timeMonth"
                                   data-ana="investorline:wealth:onboarding:rrif:month end"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.whenInMonth"
                                   #whenInMonthCtrl="ngModel"
                                   value="2"
                                   required />
                            <label class="prefix" for="end" translate="withdrawal.labels.end"></label>
                        </div>
                        <div role="alert" class="error-message" id="timeMonth-error" *ngIf="whenInMonthCtrl.errors && whenInMonthCtrl.touched || (isFormSubmit && whenInMonthCtrl.errors)">
                            <span translate="withdrawal.errors.withdrawalTimeMonthTitle"></span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="withdrawal.withdrawalWhereTitle" id="ariaWithdrawalWhereTitle"></h2>
            <p translate="withdrawal.withdrawalWhereDescription" ></p>
            <div class="alert alert-info" role="alert" *ngIf="isNBMOEFT">
                <p class="font-bold" translate="withdrawal.alertTakeNoteTitle"></p>
                <p translate="withdrawal.alertTakeNoteDescriptionCheque"></p>
            </div>
            <div class="form-wrapper">
                <div role="radiogroup"
                     aria-labelledby="ariaWithdrawalWhereTitle"
                     id='withdrawal-where-error'
                     class="form-row">

                    <div class="form-col">
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': withdrawalAmountDepositCtrl.errors && withdrawalAmountDepositCtrl.touched || (isFormSubmit && withdrawalAmountDepositCtrl.errors)}">
                            <input id="personal"
                                   name="wherePlace"
                                   data-ana="investorline:wealth:onboarding:rrif:account:bmo"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.method"
                                   #withdrawalAmountDepositCtrl="ngModel"
                                   (change)="displayTransitInstitute()"
                                   value="BMOEFT" required />
                            <label class="prefix" for="personal" translate="withdrawal.labels.personal"></label>
                        </div>
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': withdrawalAmountDepositCtrl.errors && withdrawalAmountDepositCtrl.touched || (isFormSubmit && withdrawalAmountDepositCtrl.errors)}">
                            <input id="investorLine"
                                   name="wherePlace"
                                   data-ana="investorline:wealth:onboarding:rrif:account:investorline"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.method"
                                   (change)="displayTransitInstitute()"
                                   #withdrawalAmountDepositCtrl="ngModel"
                                   value="TFR" required />
                            <label class="prefix" for="investorLine" translate="withdrawal.labels.investorLine"></label>
                        </div>
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': withdrawalAmountDepositCtrl.errors && withdrawalAmountDepositCtrl.touched || (isFormSubmit && withdrawalAmountDepositCtrl.errors)}">
                            <input id="personal-other"
                                   name="wherePlace"
                                   data-ana="investorline:wealth:onboarding:rrif:account:other"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.method"
                                   #withdrawalAmountDepositCtrl="ngModel"
                                   (change)="displayTransitInstitute()"
                                   value="NBMOEFT" required />
                            <label class="prefix" for="personal-other" translate="withdrawal.labels.personal-other"></label>
                        </div>
                        <div role="alert" class="error-message" id="wherePlace-error" *ngIf="withdrawalAmountDepositCtrl.errors && withdrawalAmountDepositCtrl.touched || (isFormSubmit && withdrawalAmountDepositCtrl.errors)">
                            <span translate="withdrawal.errors.wherePlace"></span>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="flex-80 form-col" *ngIf="showInstituteCode">
                        <div class="form-field">
                            <div class="form-label-group">
                                <select id="institution"
                                        name="institution"
                                        data-ana="investorline:wealth:onboarding:rrif:account:institution code"
                                        [(ngModel)]="selectedInstitution"
                                        (change)=setInstitution()
                                        #institutionCodeCtl="ngModel" required>
                                    <option *ngFor="let fin of financialInstitutionList" [ngValue]="fin">
                                        {{fin.fiId}} - {{fin.name}}
                                    </option>
                                </select>
                                <label [ngClass]="{'error-field': institutionCodeCtl.errors && institutionCodeCtl.touched || (isFormSubmit && institutionCodeCtl.errors), 'active-label': institutionCodeCtl.value}"
                                       class="slide-label"
                                       for="institution">
                                    <span class="label-text"
                                          translate="withdrawal.labels.institution">
                                    </span>
                                </label>
                            </div>
                            <div class="form-field-button">
                                <app-contextual-help class="button-wrapper va-middle"
                                                     content="withdrawal.contextualHelp.institution"
                                                     inputDataAna="investorline:wealth:onboarding:rrif:institution code info"
                                                     arialabel="withdrawal.ariaLabels.institution">
                                </app-contextual-help>
                            </div>
                        </div>
                        <div role="alert" class="error-message" id="institution-error" *ngIf="institutionCodeCtl.invalid && institutionCodeCtl.touched  || (isFormSubmit && institutionCodeCtl.errors) ||  (institutionCodeCtl.touched && institutionCodeCtl?.errors?.minLengthError)">
                            <span [innerHTML]="'withdrawal.errors.institution' | translate | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="flex-80 form-col" *ngIf="showTransitCode">
                        <div class="form-field">
                            <div class="form-label-group">
                                <input id="transit"
                                       minlength="5"
                                       maxlength="5"
                                       name="transit"
                                       data-ana="investorline:wealth:onboarding:rrif:account:transit number"
                                       type="tel"
                                       InputValidator="ct3"
                                       class="display-inline"
                                       [(ngModel)]="withdrawalInformationObj.transitCode"
                                       #transitCodeCtl="ngModel" required>
                                <label [ngClass]="{'error-field': transitCodeCtl.invalid && transitCodeCtl.touched  || (isFormSubmit && transitCodeCtl.errors), 'active-label': transitCodeCtl.value}"
                                       class="slide-label"
                                       for="transit">
                                    <span class="label-text"
                                          translate="withdrawal.labels.transit">
                                    </span>
                                </label>
                            </div>
                            <div class="form-field-button">
                                <app-contextual-help class="button-wrapper va-middle"
                                                     content="withdrawal.contextualHelp.transit"
                                                     inputDataAna="investorline:wealth:onboarding:rrif:transit number info"
                                                     arialabel="withdrawal.ariaLabels.transit">
                                </app-contextual-help>
                            </div>
                        </div>

                        <div role="alert" class="error-message" id="transit-error" *ngIf="transitCodeCtl.invalid && transitCodeCtl.touched  || (isFormSubmit && transitCodeCtl.errors) ||  (transitCodeCtl.touched && transitCodeCtl?.errors?.minLengthError)">
                            <span [innerHTML]="'withdrawal.errors.transit' | translate | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="flex-80 form-col" *ngIf="withdrawalInformationObj.method">
                        <div class="form-field">
                            <div class="form-label-group">
                                <input id="account"
                                       minlength="7"
                                       maxlength="12"
                                       name="account"
                                       data-ana="investorline:wealth:onboarding:rrif:account:account number"
                                       type="tel"
                                       InputValidator="ct3"
                                       class="display-inline"
                                       [(ngModel)]="withdrawalInformationObj.transferAccountNumber"
                                       #transferAccountNumberCtl="ngModel" required>
                                <label [ngClass]="{'error-field': transferAccountNumberCtl.invalid && transferAccountNumberCtl.touched  || (isFormSubmit && transferAccountNumberCtl.errors), 'active-label': transferAccountNumberCtl.value}"
                                       class="slide-label"
                                       for="account">
                                    <span class="label-text"
                                          translate="withdrawal.labels.account">
                                    </span>
                                </label>
                            </div>
                            <div class="form-field-button">
                                <app-contextual-help class="button-wrapper va-middle"
                                                     content="withdrawal.contextualHelp.account"
                                                     inputDataAna="investorline:wealth:onboarding:rrif:account number info"
                                                     arialabel="withdrawal.ariaLabels.account">
                                </app-contextual-help>
                            </div>
                        </div>
                        <div role="alert" class="error-message" id="account-error" *ngIf="transferAccountNumberCtl.invalid && transferAccountNumberCtl.touched  || (isFormSubmit && transferAccountNumberCtl.errors) ||  (transferAccountNumberCtl.touched && transferAccountNumberCtl?.errors?.minLengthError)">
                            <span [innerHTML]="'withdrawal.errors.account' | translate | safeHtml"></span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="withdrawal.withdrawalSingleJointTitle" id="ariaSingleJoint" *ngIf="isNBMOEFT"></h2>
            <div class="form-wrapper" *ngIf="isNBMOEFT">
                <div role="radiogroup"
                     aria-labelledby="ariaSingleJoint"
                     id='withdrawal-single-error'
                     class="form-row">

                    <div class="form-col no-margin">
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': singleJointCtrl.errors && singleJointCtrl.touched || (isFormSubmit && singleJointCtrl.errors)}">
                            <input id="single"
                                   name="SingleJoint"
                                   data-ana="iinvestorline:wealth:onboarding:rrif:other account:single"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.isJointAccount"
                                   value="false"
                                   #singleJointCtrl="ngModel" required />
                            <label class="prefix" for="single" translate="withdrawal.labels.single"></label>
                        </div>
                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field': singleJointCtrl.errors && singleJointCtrl.touched || (isFormSubmit && singleJointCtrl.errors)}">
                            <input id="joint"
                                   name="SingleJoint"
                                   data-ana="investorline:wealth:onboarding:rrif:other account:joint"
                                   type="radio"
                                   [(ngModel)]="withdrawalInformationObj.isJointAccount"
                                   value="true"
                                   #singleJointCtrl="ngModel" required />
                            <label class="prefix" for="joint" translate="withdrawal.labels.joint"></label>
                        </div>
                        <div role="alert" class="error-message" id="single-error" *ngIf="singleJointCtrl.errors && singleJointCtrl.touched || (isFormSubmit && singleJointCtrl.errors)">
                            <span translate="withdrawal.errors.withdrawalSingleJointTitle"></span>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">

                        <div class="form-input-checks check-default" [ngClass]="{'error-field': validAccountCtl.errors && validAccountCtl.touched || (isFormSubmit && validAccountCtl.errors)}">
                            <input id="validAccount"
                                   type="checkbox"
                                   name="validAccount"
                                   data-ana="investorline:wealth:onboarding:rrif:other account:terms"
                                   [(ngModel)]="withdrawalInformationObj.validAccount"
                                   #validAccountCtl="ngModel" required />
                            <label for="validAccount" translate="withdrawal.labels.validAccount"></label>
                        </div>
                        <div role="alert" class="error-message" id="validAccount-error" *ngIf="(isFormSubmit && validAccountCtl.errors)">
                            <span translate="withdrawal.errors.validAccount"></span>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section>
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater"
                              formName="withdrawal" [backRoute]="backRoute"></app-form-buttons>
        </section>
    </div>

</form>
