import { Injectable, Inject, Injector } from '@angular/core';

/*
    Author: Dilraj, April 2018
*/
export class ServiceLocator {
    constructor(private _injector: Injector) {
        ServiceLocator.injector = _injector;
    }

    public static get(token: any, notFoundValue?: any): any {
        return ServiceLocator.injector.get(token, notFoundValue);
    }

    public static injector: Injector;
}
