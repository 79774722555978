import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BACKSPACE, TAB, ENTER, SHIFT, END, HOME, LEFT_ARROW, RIGHT_ARROW, DELETE, CTRL, SPACE} from './../../config/app-config.constants';
import { Utils } from '../utils/utils.class';

@Directive({
    selector: '[appPostalCode]'
})
export class PostalCodeDirective {
    private alphaNumericRegex: RegExp = new RegExp(/^[a-zA-Z0-9 ]*$/);
    public postalCodePattern: RegExp = new RegExp(/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i);
    private inputValue: string;

    constructor(private el: ElementRef, public control: NgControl) { }
    
    ngOnInit() {
        Promise.resolve(null).then(() => {
            this.onblur();
        });
    }

    @HostListener('keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {

        if (event.keyCode === DELETE) {
            // event.preventDefault();
            return false;
        }

        if (this.el.nativeElement.length >= 6) {
            event.preventDefault();
            return;
        }

        if (!this.alphaNumericRegex.test(event.key)) {
            event.preventDefault();
            return;
        }

        if (this.postalCodePattern.test(event.key)) {
            event.preventDefault();
            return;
        }

        if (event.keyCode == SPACE  ) { // this is to block more than one space in the field
            if (this.el.nativeElement.value.indexOf(' ') > -1){
                event.preventDefault();
                return;
            }
        }

    }

    @HostListener('blur', ['$event'])
    onblur(event?: MouseEvent) {
        
     
        let value = this.el.nativeElement.value;
        
        if (this.el.nativeElement.value !== undefined && this.el.nativeElement.value != null) {
            value = value.trim();
            let numbers = value.replace(/[ -]/, ''),
                char = {
                    3: ' ',
                };
                value = '';
    
            for (let i = 0; i < numbers.length; i++) {
                value += (char[i] || '') + numbers[i];
            }

            this.el.nativeElement.value = value;
            
    
        }
    }

    @HostListener('focus', ['$event'])
    onfocus(event: MouseEvent) {



        /**This was added to get rid of the error thrown by the browser when user focuses on the postal-code field. */
        this.el.nativeElement.value = this.el.nativeElement.value;
    }

    @HostListener('paste', ['$event'])
    blockPaste(event: KeyboardEvent) {
        event.preventDefault();
    }

    @HostListener('mousedown', ['$event'])
    onmspointerdown(event: MouseEvent) {
        if (event.button === 2) {
            event.preventDefault();
        }
    }
}
