<div id="headerAccountName" class="bmo-wrapper justify-end" role="complementary">

    <div class="account-type" [class]="isBranchAssisted ? 'account-type' : 'account-type height-auto'">
        <div *ngIf="applicationNumber !== ''">
            <span [innerHTML]="'header.labels.accountNumber' | translate | safeHtml"></span>
            <strong>{{applicationNumber}}</strong>
        </div>

        <div *ngIf="accountType !== undefined && accountType.length !== 0" class="flex-elements justify-end">
            <span [innerHTML]="'header.labels.accountType' | translate | safeHtml"></span>
            <ng-continer *ngIf="accountType.length;else singleAccount">
                <span *ngFor="let multiAccountType of accountType; let idx = index">
                    <span>&nbsp;</span> 
                    <strong [innerHTML]="multiAccountType.description | translate | safeHtml"></strong>
                    <span *ngIf="idx+1 != accountType.length">&nbsp;&nbsp;|&nbsp;</span>
                </span>
            </ng-continer>
            <ng-template #singleAccount>
                <span>&nbsp;</span>
                <strong [innerHTML]="accountType.description | translate | safeHtml"></strong>
            </ng-template>
        </div>

    </div>
</div>
