import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../service/application.service';
import { map, flatMap, delay } from 'rxjs/operators';
import { DocumentTrackerService } from '../service/document-tracker.service';
import { DOCUMENT_TYPE, LANGUAGES } from './../../config/app-config.constants';
import { Utils } from '../../shared/utils/utils.class';


@Injectable()
export class RetrieveApplicationResolver implements Resolve<any> {

    constructor(private applicationService: ApplicationService,
        private documentTrackerService: DocumentTrackerService) { }

    resolve(route: ActivatedRouteSnapshot) {

        return this.applicationService.retrieveApplication().pipe(
            flatMap(application => {
                Utils.setNextStepsData(application);
                if (Utils.getNextStepsData().preferredLanguage == LANGUAGES.FRENCH) {
                    Utils.setLanguage('fr');
                } else { // default
                    Utils.setLanguage('en');
                }
                return this.documentTrackerService.generateSpecificDocuments
                    (Utils.getNextStepsData().applicationId, DOCUMENT_TYPE.BENEFICIARY)
                    .pipe(
                        map(document => {
                            Utils.setDocumentsPackage(JSON.parse(document.body));
                        }));
            }));
    }
}
