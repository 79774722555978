<app-flood-light></app-flood-light>
<form #riskAndReturnForm="ngForm" (ngSubmit)="onSubmit()">

    <div class="container-menuView">

        <section id="riskAndReturnMainSection">

            <app-critical-error *ngIf="criticalError"></app-critical-error>

            <app-invalid-form #appInvalidForm  [isFormInvalid]="(riskAndReturnForm.invalid && isFormSubmit)"
            translateTag="riskAndReturn">
            </app-invalid-form>


            <section id="riskAndReturnSection">
                <h1 translate="riskAndReturn.mainTitle" id="riskReturnMainTitle"></h1>


                <div class="more-info-wrapper add-margin t12px b24px">

                    <a role="button" id="bmoRiskReturnTitleOpen" aria-controls="bmoRiskReturnOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'riskAndReturn.ariaLabels.titleRiskAndReturn' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="bmoRiskReturnOpen">

                        <p [innerHTML]="'riskAndReturn.titleRiskAndReturn' | translate"></p>
                        <p>
                            <a role="button" id="bmoRiskReturnTitleClose" aria-controls="bmoRiskReturnTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>
                <div class="form-wrapper">
                        <app-radio-card
                            [options]="volatilityRiskQuestionOptions"
                            [form]="riskAndReturnForm"
                            [section]="'volatilityRiskQuestion'"
                            [name]="'volatilityRiskQuestionOption'"
                            isFullImageUrl="true"
                            ngDefaultControl
                            [dataAna]="'mywealth:onboarding:Risk and Return: volatility question'"
                            [isError]="false"
                            [accessErrorId]="{ id: 'volatilityRiskQuestion-error', parentId: 'parent-volatilityRiskQuestion-error',  submitted: isFormSubmit }"
                            [errorMessage]="'riskAndReturn.errors.twentyPercentLoss'"
                            [templateType]="RADIO_TYPES.LARGE"
                            [(ngModel)]="riskAndReturn.riskTolerance">
                        </app-radio-card>
                </div>

            </section>
            <section  id="riskTolerance" [hidden]="!riskAndReturn.riskTolerance">
                <h1 translate="riskAndReturn.idealRiskReturnObjective" id="idealRiskReturn"></h1>

                <div class="more-info-wrapper add-margin t24px b24px">

                    <a role="button" id="bmoRiskReturnDescribeTitleOpen" aria-controls="bmoRiskReturnDescribeOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'riskAndReturn.ariaLabels.idealRiskReturnObjective' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="bmoRiskReturnDescribeOpen">

                        <p [innerHTML]="'riskAndReturn.idealRiskReturnDesc' | translate"></p>
                        <p>
                            <a role="button" id="bmoRiskReturnDescribeTitleClose" aria-controls="bmoRiskReturnDescribeTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>




                <div class="form-wrapper" >
                        <app-radio-card [options]="idealRiskReturnOptions"
                            [form]="riskAndReturnForm"
                            [section]="'idealRiskReturn'"
                            [name]="'idealRiskReturnQuestion'"
                            isFullImageUrl="true"
                            ngDefaultControl
                            [dataAna]="'mywealth:onboarding:Risk and Return:ideal investment portfolio and return objective'"
                            [isError]="false"
                            [accessErrorId]="{ id: 'idealRiskReturnQuestion-error', parentId: 'parent-idealRiskReturnQuestion-error',  submitted: isFormSubmit }"
                            [errorMessage]="'riskAndReturn.errors.idealInvestment'"
                            [templateType]="RADIO_TYPES.LARGE"
                            [(ngModel)]="riskAndReturn.riskReturnInvestment">
                        </app-radio-card>


                </div>
            </section>

        </section>

        <section id="riskAndReturnFormButtons">

            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater"
                formName="riskAndReturn" [backRoute]="APP_ROUTES.investment_knowledge"></app-form-buttons>

        </section>

    </div>

</form>

