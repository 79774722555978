<!-- todo: temp buttons for lang-selector -->
<div class="bmo-container-flex form-wrapper">
    <div class="flex-row form-row">

        <div class="flex-elements form-col">
            <div class="form-radio-checks radio-buttons">
                <input id="english" type="radio" name="prefix" value="English" (click)="setLang('en')" />
                <label class="prefix" for="english">English</label>
            </div>
            <div class="form-radio-checks radio-buttons">
                <input id="french" type="radio" name="prefix" value="French" (click)="setLang('fr')" />
                <label class="prefix" for="french">French</label>
            </div>
        </div>

    </div>
</div>

<!-- temp buttons for self-direct/advice-direct-->
<div class="bmo-container-flex form-wrapper">
    <div class="flex-row form-row">

        <div class="flex-elements form-col">
            <div class="form-radio-checks radio-buttons">
                <input id="selfDirect" type="radio" name="prefix" value="Self Direct" />
                <label class="prefix" for="selfDirect">Self Direct</label>
            </div>
            <div class="form-radio-checks radio-buttons">
                <input id="adviceDirect" type="radio" name="prefix" value="Advice Direct" />
                <label class="prefix" for="adviceDirect">Advice Direct</label>
            </div>
        </div>

    </div>
</div>