import { ControlValueAccessor, Validator, UntypedFormControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Component, OnInit, Input, Output, forwardRef } from '@angular/core';

const noop = () => {};

@Component({
  selector: 'app-confirmation-yes-no',
  templateUrl: './confirmation-yes-no.component.html',
  providers: [
      { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConfirmationYesNoComponent), multi: true },
      { provide: NG_VALIDATORS, useExisting: forwardRef(() => ConfirmationYesNoComponent), multi: true }
    ],
})
export class ConfirmationYesNoComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() isFormSubmit: Boolean;
  @Input() accessId: string;
  @Input() id: string;
  @Input() name: string;
  @Input() dataAna?: string;
  @Input() isDisabled?: boolean = false;

  selectedOption1: string;
  selectedOption2: string;

  valueOne: string;
  valueTwo: string;
  @Input() value1: string; // use this input to custom set values
  @Input() value2: string;

  @Input() errorMessage: string;

  constructor() { }

  ngOnInit() {
    this.selectedOption1 = this.id + '1';
    this.selectedOption2 = this.id + '2';
    this.valueOne = !this.value1 ? 'Yes' : this.value1;
    this.valueTwo = !this.value2 ? 'No' : this.value2;
    if (this.dataAna === null || this.dataAna === undefined) {
        this.dataAna = '';
    }
  }

  // The following code is to bind the sin 'value' to the ngModel in <app-sin-number [(ngModel)]="model.sinNumber"> :
    // The internal data model
    private innerValue: any = '';

    // Placeholders for the callbacks which are later provided
    // by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
    propagateChange: any = () => {};
    validateFn: any = () => {};

    // get accessor
    get value(): any {
        return this.innerValue;
    }

    // set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    // Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    // admittedly a hacky way of using the validators. These are projected to the parent component
    // the validators in the HTML are used locally
    validate(c: UntypedFormControl) {
      return Validators.required(c);
    }

}
