<div class="form-wrapper  add-margin t24px">
    <div class="form-row label-row">
    <label class="slide-label" [for]="section + 'button'">
        <span class="label-text">{{title | translate}}</span>
    </label>
</div>
<label translate="familyInformation.dependentsCountQuestion" [for]="section"  ></label>
<div class="form-row">
    <div class="flex-20 form-col">
            <div class="form-field">
                <div class="form-field-button">
                    <a role="button" class="btn-icon" href="javascript://"
                    (click)="decrement()"
                    attr.aria-label="{{ 'familyInformation.ariaLabels.decrement' | translate }}" >
                    <span class="icon icon-large minus-circle-blue"></span>
                </a>
            </div>
            <div class="form-label-group border-bottom">
                <input class="content-center no-padding"
                        min="{{minValue}}"
                        max="{{maxValue}}"
                        [attr.name]="section"
                        type="text"
                        [name]="section"
                        [id]="section"
                        #incrementCtrl="ngModel"
                        [(ngModel)]="incrementModel"
                        maxlength="{{maxLength}}"
                        minlength="{{minLength}}"
                        appInputValidator
                        InputValidator="ct3"
                        (keyup)="eventHandler($event)"
                        aria-live="polite"/>
            </div>
            <div class="form-field-button">
                <a role="button" class="btn-icon" href="javascript://"
                (click)="increment()"
                attr.aria-label="{{ 'familyInformation.ariaLabels.increment' | translate }}" >
                <span class="icon icon-large plus-circle-blue"></span>
                </a>
            </div>
        </div>
    </div>

</div>
</div>
