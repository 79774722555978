import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-approval-declined',
  templateUrl: './application-approval-declined.component.html'
})
export class ApplicationApprovalDeclinedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
