<section>

    <div class="container-smallNoMenu content-center">

        <div class="celebration-img application-downgrade"></div>

        <h1 [innerHtml]="('applicationApprovalDowngrade.titleDowngradeSD' | translate).replace('[Name]', firstName)  | safeHtml" *ngIf="!isSubProductAD"></h1>
        <h1 [innerHtml]="('applicationApprovalDowngrade.titleDowngradeAD' | translate).replace('[Name]', firstName)  | safeHtml" *ngIf="isSubProductAD"></h1>
        <ng-container *ngIf="!isMultiAccountFlow && !isBranchSDMultiAccountFlow">
            <div class="header-contextual-help add-margin t32px">
                <p  class="display-inline" translate="applicationApprovalDowngrade.descriptionDowngrade01"> 
                    <app-contextual-help class="button-wrapper va-middle" content="applicationApprovalDowngrade.contextualHelp.downgradeMoreInfo" arialabel="applicationApprovalDowngrade.ariaLabels.downgradeTool" 
                    [changeContent]="applicationNumber">
                    </app-contextual-help>
                </p>
             </div>
             <h2  translate="applicationApprovalDowngrade.descriptionDowngrade02"></h2>
             <div class="note-white content-left">
                <h3  translate="applicationApprovalDowngrade.titleDowngradeCard01" class="font-bold"></h3>
                    <p translate="applicationApprovalDowngrade.descriptionDowngradeCard01"></p>
                    <h3 translate="applicationApprovalDowngrade.titleDowngradeCard02"  class="font-bold"></h3>
                    <p translate="applicationApprovalDowngrade.descriptionDowngradeCard02"></p>
                    <h3 translate="applicationApprovalDowngrade.titleDowngradeCard03"  class="font-bold"></h3>
    
                <ul class="check-list">
                    <li translate="applicationApprovalDowngrade.descriptionDowngradeCard03a"></li>
                    <li translate="applicationApprovalDowngrade.descriptionDowngradeCard03b"></li>
                    <li translate="applicationApprovalDowngrade.descriptionDowngradeCard03c"></li>
                </ul>
                    <h3 translate="applicationApprovalDowngrade.titleDowngradeCard04"  class="font-bold"></h3>
                    <p [innerHTML]="'applicationApprovalDowngrade.descriptionDowngradeCard04a' | translate | safeHtml"></p>
                    <p [innerHTML]="'applicationApprovalDowngrade.descriptionDowngradeCard04b' | translate | safeHtml"></p>
                    <p [innerHTML]="'applicationApprovalDowngrade.descriptionDowngradeCard04c' | translate | safeHtml"></p>    
                </div>
                <div class="form-wrapper">
                    <div class="form-row form-page-buttons flex-maxis-center">
        
                        <div  class="form-col left-side">
                            <button  data-ana="Investorline:wealth:onboarding:downgrade cancel" class="secondary" type="submit" translate="button.noCancel" (click)="continueApplication(downgradeDecisions.cancel_downgrade)"></button>
                        </div>
                        <div class="form-col right-side">
                            <button  data-ana="Investorline:wealth:onboarding:downgrade continue" class="primary" type="submit" translate="button.yesContinue" (click)="continueApplication(downgradeDecisions.accept_downgrade)"></button>
                           </div>
        
                    </div>
                </div>
        </ng-container>
        <ng-container *ngIf="isMultiAccountFlow || isBranchSDMultiAccountFlow">
            <div class="header-contextual-help add-margin t32px">
                <p class="display-inline" translate="applicationApprovalDowngrade.descriptionDowngrade03">
                    <app-contextual-help class="button-wrapper va-middle" content="applicationApprovalDowngrade.contextualHelp.downgradeMoreInfo01" arialabel="applicationApprovalDowngrade.ariaLabels.downgradeTool" 
                    [changeContent]="applicationNumber">
                    </app-contextual-help>
                </p>
             </div>
             <div class="note-white content-left">
             <h2 translate="applicationApprovalDowngrade.titleDowngradeCard01"></h2>
                <p  translate="applicationApprovalDowngrade.titleDowngradeCard03"></p>
                <ul class="check-list">
                    <li translate="applicationApprovalDowngrade.descriptionDowngradeCard03a"></li>
                    <li translate="applicationApprovalDowngrade.descriptionDowngradeCard03b"></li>
                    <li translate="applicationApprovalDowngrade.descriptionDowngradeCard03c"></li>
                </ul>
            </div>
            <h2 class="content-left add-margin t32px" translate="applicationApprovalDowngrade.titleDowngradeWithToProceed"></h2>
            <p class="content-left" translate="applicationApprovalDowngrade.descriptionDowngradeWithToProceed"></p>
         
        <!--Multi account Flow Downgrade Tiles-->
          <div class="form-wrapper add-margin t24px">
            <div role="group" aria-labelledby="ariaSelectAccount" class="form-row" id='parent-accountSelect-error'>

                <div class="flex-33 form-radio-checks selection-card flow-card" *ngIf = "accountTypesCount === 1"
                    id="CashSingleAccountTile">

                    <input id="CashSingleAccountOpt"
                        type="radio"
                        name="CashSingleAccount"
                        [value]="downgradeDecisions.accept_downgrade"
                        [(ngModel)]="selectedDowngradeDecision"
                        [checked]="selectedDowngradeDecision === downgradeDecisions.accept_downgrade" required/>

                    <label class="large-card content-left active-label" for="CashSingleAccountOpt">
                        <span class="check"></span>
                        <span class="one-col justify-space-between">
                            <span class="title no-margin-title">
                                <span translate="selectAccount.accounts.CASHTitle"></span>
                            </span>
                            <span aria-hidden="true">
                                <span class="color-code blue-light"></span>
                                <span class="footer" translate="{{(selectedDowngradeDecision === downgradeDecisions.accept_downgrade ) ? 'applicationApprovalDowngrade.labels.selected' : 'selectAccount.labels.select'}}"></span>
                            </span>
                        </span>
                    </label>

                </div>

                 <!-- Cash and Registered Account(s) selection-->
                 <div class="flex-33 form-radio-checks selection-card flow-card" *ngIf = "accountTypesCount > 1"
                    [ngClass]="{'error-field': false}"
                    [id]="'multiAccountTile01'">


                    <input id="multiAccountWithCash"
                        type="radio"
                        [value]="downgradeDecisions.accept_downgrade"
                        [(ngModel)]="selectedDowngradeDecision"
                        name="multiAccountWithCash"
                        [checked]="selectedDowngradeDecision === downgradeDecisions.accept_downgrade" required/>

                    <label class="large-card content-left active-label" for="multiAccountWithCash">
                        <span class="check"></span>
                        <span class="one-col justify-space-between">
                            <span class="title no-margin-title">
                                <span> {{'selectAccount.labels.cash' | translate|titlecase}}{{ 'applicationApprovalDowngrade.accountTypesAnd' | translate }}</span>
                                <span *ngFor="let multiAccountType of selectedRegisteredAccounts; let idx=index">
                                    <span [innerHTML]="multiAccountType.description | translate | safeHtml"></span>
                                    <span *ngIf="idx+1 != selectedRegisteredAccounts.length">,&nbsp;</span>
                                </span>
                            </span>
                            <span aria-hidden="true">
                                <span class="color-code blue-light"></span>
                                <span class="footer" translate="{{(selectedDowngradeDecision === downgradeDecisions.accept_downgrade) ? 'applicationApprovalDowngrade.labels.selected' : 'selectAccount.labels.select'}}"></span>
                            </span>
                        </span>
                    </label>

                </div>
                
                <!-- Registered Account(s) only selection , Cash Excluded -->
                <div class="flex-33 form-radio-checks selection-card flow-card" *ngIf = "accountTypesCount > 1"
                    [ngClass]="{'error-field': false}"
                    id="multiAccountTile02">


                    <input id="multiAccountNoCash" type="radio" 
                    [value]="downgradeDecisions.process_otheraccounts"
                    [(ngModel)]="selectedDowngradeDecision"
                    name="multiAccountWithoutCash"
                    [checked]="selectedDowngradeDecision === downgradeDecisions.process_otheraccounts"
                    required/>
                           
                    <label class="large-card content-left active-label" for="multiAccountNoCash">
                        <span class="check"></span>
                        <span class="one-col justify-space-between">
                            <span class="title no-margin-title">
                                
                                <span *ngFor="let multiAccountType of selectedRegisteredAccounts; let idx=index">
                                    <span [innerHTML]="multiAccountType.description | translate | safeHtml"></span>
                                    <span *ngIf="idx+1 != selectedRegisteredAccounts.length">,&nbsp;</span>
                                </span>
                                <span>{{ 'applicationApprovalDowngrade.accountTypesOnly' | translate }}</span>
                                
                            </span>
                            <span aria-hidden="true">
                                <span class="color-code blue-light"></span>
                                <span class="footer" translate="{{(selectedDowngradeDecision === downgradeDecisions.process_otheraccounts) ? 'selectAccount.labels.selected' : 'selectAccount.labels.select'}}"></span>
                            </span>
                        </span>
                    </label>

                </div>


                <!-- Cancel Application -->
                <div class="flex-33 form-radio-checks selection-card flow-card" *ngIf = "accountTypesCount >= 1"
                     id="cancelApplicationAccountTile">

                    <input id="cancelApplicationAccount"
                        type="radio"
                        name="CashSingleAccount"
                        [value]="downgradeDecisions.cancel_downgrade"
                        [(ngModel)]="selectedDowngradeDecision"
                        (change)="onCancelDowngrade()"
                        [checked]="selectedDowngradeDecision === downgradeDecisions.cancel_downgrade" required/>

                    <label class="large-card content-left active-label" for="cancelApplicationAccount">
                        <span class="check"></span>
                        <span class="one-col justify-space-between">
                            <span class="title no-margin-title">
                                <span translate="applicationApprovalDowngrade.labels.cancelYourApplication"></span>
                            </span>
                            <span aria-hidden="true">
                                <span class="color-code blue-light"></span>
                                <span class="footer" translate="{{(selectedDowngradeDecision === downgradeDecisions.cancel_downgrade) ? 'selectAccount.labels.selected' : 'selectAccount.labels.select'}}"></span>
                            </span>
                        </span>
                    </label>

                </div>

                <div role="alert" id='accountSelect-error' class="error-message content-left" *ngIf="false">
                    <span translate="applicationApprovalDowngrade.errors.mustSelectOneOption"></span>
                 </div>
                
            </div>

        </div>
        
        <div class="form-wrapper">
            <div class="form-row form-page-buttons flex-maxis-center">
                <div  class="form-col left-side">  </div>
                <div class="form-col right-side">
                    <button  class="primary" type="submit" translate="button.continue" (click)="continueApplication(selectedDowngradeDecision)" 
                    [attr.aria-label]="(downgradeDecisions.cancel_downgrade ? 'applicationApprovalDowngrade.ariaLabels.cancelApplication' : null ) | translate"></button>
                </div>
            </div>
        </div>
        </ng-container>
    </div>

</section>

<!-- SHOW this modal when the "Cancel application" button is pressed -->
<!-- --- -->
<div class="bmo-modal bmo-downgrade-cancel">
    <form>
        <a role="button"
           attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
           attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
           class="btn-icon btn-close tooltip-show"
           href="javascript://">
            <span class="icon icon-large close-grey"></span>
        </a>
        <div class="modal-content-scroll no-margin content-left">

            <section>

                <div class="header-img money-bags"></div>
                <div class="form-wrapper no-margin">

                    <div class="form-row flex-maxis-center content-center">
                        <h2 translate="applicationApprovalDowngrade.modalDowngrade.modalTitle"></h2>
                        <p translate="applicationApprovalDowngrade.modalDowngrade.modalDescription"></p>
                    </div>

                    <div class="form-row form-page-buttons flex-maxis-center">

                        <div class="form-col left-side">
                            <button class="secondary" type="submit" translate="button.yes"></button>
                        </div>
                        <div class="form-col right-side">
                            <button class="primary" type="submit" translate="button.no"></button>
                        </div>

                    </div>
                </div>

            </section>

        </div>
    </form>
</div>

<!-- SHOW this modal when the "Yes, proceed" button is pressed -->
<!-- --- -->
<div class="bmo-modal bmo-open-cash">
    <form>
        <a role="button"
           attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
           attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
           class="btn-icon btn-close tooltip-show"
           href="javascript://">
            <span class="icon icon-large close-grey"></span>
        </a>
        <div class="modal-content-scroll no-margin content-left">
            <section>
                
                <div class="header-img money-bags"></div>
                <div class="form-wrapper no-margin">
                    
                    <div class="form-row flex-maxis-center content-center">
                        <h2 translate="applicationApprovalDowngrade.modalDowngrade.modalTitle"></h2>
                    </div>
                    <div class="form-row form-page-buttons flex-maxis-center">
                        <div class="form-col left-side">
                            <button class="secondary" type="submit" translate="button.yes"></button>
                        </div>
                        <div class="form-col right-side">
                            <button class="primary" type="submit" translate="button.no"></button>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    </form>
</div>
