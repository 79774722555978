import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPostalCode'
})
export class FormatPostalCodePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value !== undefined && value != null) {
      value = value.trim().toUpperCase();
        let numbers = value.replace(/[ -]/, ''),
          char = {
            3: ' ',
          };
        value = '';

        for (let i = 0; i < numbers.length; i++) {
          value += (char[i] || '') + numbers[i];
        }

       
      return value;
    }
  }
}
