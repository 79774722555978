import { APP_ROUTES, ANALYTICS } from './../../config/app-config.constants';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormService } from '../../shared/service/form.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
@Component({
    selector: 'app-celebration',
    templateUrl: './celebration.component.html'
})
export class CelebrationComponent implements OnInit, AfterViewInit {
    private isFormSubmit: boolean;
    public firstName: String;
    private isCurrentLangEnglish: boolean;
    private isCurrentLangFrench: boolean;
    public showFinishLater;
    public url;

    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        private winRef: WindowRefService,
        private formService: FormService,
        private analytics: AnalyticsService) {
        this.isFormSubmit = false;
        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.url = '/' + APP_ROUTES.celebration;
    }

    ngOnInit() {

        this.titleService.setTitle(Utils.getPageTitleMidWayCelebration());
        // this.formService.forms.financialInformation.isVisited = true;
        Promise.resolve(null).then(() => {
             
            const _data = this.formService.forms.personalInformation.data;
            this.firstName = (_data ? (_data.preferredFirstName ? _data.preferredFirstName :
                (_data.firstname ? _data.firstname : '')) : '') + '!';
        });

        // save for later
        this.showFinishLater = true;

        setTimeout(() => {
            this.setGTM();
        }, 200);

    }

    ngAfterViewInit() {
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('Celebration');

    }

    onSubmit() {
        this.isFormSubmit = true;
        // this javascript based navigation to avoid application restart on edge browser
        if (Utils.isSubProductAD()) {
            window.location.href = window.location.href.split('#/')[0].concat('#/' + APP_ROUTES.financial_details + '?lang=')
                .concat(this.applicationService.getCurrentLanguage()).concat('&subProduct=').concat(Utils.getSubProduct());
        }
        else {
            window.location.href = window.location.href.split('#/')[0].concat('#/' + APP_ROUTES.financial_details + '?lang=')
                .concat(this.applicationService.getCurrentLanguage());
        }
        
        if(Utils.getMboxDisable() && Utils.getMboxEdit()){
            window.location.href = window.location.href.concat('&mboxEdit=').concat(Utils.getMboxEdit())
                                    .concat('&mboxDisable=').concat(Utils.getMboxDisable());
        }
    }

    setGTM() {

        const gtmDataLayerForCelebration: GTMDataLayer =  {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleMidWayCelebration(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.CELEBRATION,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.celebration,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth' : Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForCelebration);
        // console.log(this.winRef.nativeWindow.dataLayer);

    }

}
