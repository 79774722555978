<!-- <app-flood-light></app-flood-light> -->

<!--
@ Created
@updated: Hari Prasad kori last updated on (13 April,2018)
 -->

<form #familyInformationForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section>

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="familyInformationForm.invalid && isFormSubmit"
                translateTag="familyInformation"></app-invalid-form>

            <section id="maritalStatusSection">

                <h1 translate="familyInformation.titleMaritalStatus"></h1>
                <p translate="familyInformation.descriptionMaritalStatus" id="spousalMaritalStatus"></p>

                <div class="form-wrapper">

                    <!-- HIDE this form-row for Spousal RRSP account -->
                    <!-- --- -->

                    <div *ngIf="!isSpousalRRSP" class="form-row">
                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <select id="maritalStatus" name="maritalStatus"
                                        [attr.data-ana]="'mywealth:onboarding:family '+ maritalStatusValue"
                                        (change)=updateMaritalStatus($event)
                                        [(ngModel)]="familyInformation.maritalStatus" #selectMartialStatusCtrl="ngModel"
                                        [appErrorAccess]="{id:'selectMartialStatus-error',  submitted: isFormSubmit}"
                                        required>

                                        <option *ngFor="let status of maritalStatus" [value]="status.code"
                                            [selected]="status.selected"
                                            [attr.data-ana]="'mywealth:onboarding:family '+ status.label">
                                            {{status.label}}
                                        </option>
                                    </select>

                                    <label
                                        [ngClass]="{'error-field': selectMartialStatusCtrl.errors && selectMartialStatusCtrl.touched || (isFormSubmit && selectMartialStatusCtrl.errors), 'active-label': selectMartialStatusCtrl.value}"
                                        class="slide-label" for="maritalStatus">
                                        <span class="label-text"
                                            translate="familyInformation.labels.maritalStatus"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="selectMartialStatus-error" class="error-message"
                                *ngIf="selectMartialStatusCtrl.errors && selectMartialStatusCtrl.touched || (isFormSubmit && selectMartialStatusCtrl.errors)">
                                <span translate="familyInformation.errors.maritalStatus"></span>
                            </div>
                        </div>
                    </div>

                    <!-- SHOW this form-row for Spousal RRSP account -->
                    <!-- --- -->
                    <div *ngIf="isSpousalRRSP" class="form-row">
                        <div role="radiogroup" aria-labelledby="spousalMaritalStatus" id='parent-spousalMaritalStatus-error' class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': spousalMaritalStatusCtrl.errors && spousalMaritalStatusCtrl.touched || (isFormSubmit && spousalMaritalStatusCtrl.errors), 'active-label': spousalMaritalStatusCtrl.value}">
                                <input id="statusMarried"
                                       type="radio"
                                       name="maritalStatus"
                                       value="100002"
                                       [(ngModel)]="familyInformation.spousalMaritalStatus"
                                       #spousalMaritalStatusCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'spousalMaritalStatus-error', parentId: 'parent-spousalMaritalStatus-error', submitted: isFormSubmit}"
                                       required
                                       data-ana="mywealth:onboarding:family:Married"
                                       (click)="analytics.triggerView('spousalMaritalStatusMarried')"/>
                                <label class="prefix" for="statusMarried" translate="familyInformation.labels.statusMarried"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': spousalMaritalStatusCtrl.errors && spousalMaritalStatusCtrl.touched || (isFormSubmit && spousalMaritalStatusCtrl.errors), 'active-label': spousalMaritalStatusCtrl.value}">
                                <input id="statusCommonLaw"
                                       type="radio"
                                       name="maritalStatus"
                                       value="100003"
                                       [(ngModel)]="familyInformation.spousalMaritalStatus"
                                       #spousalMaritalStatusCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'spousalMaritalStatus-error', parentId: 'parent-spousalMaritalStatus-error', submitted: isFormSubmit}"
                                       required (click)="analytics.triggerView('spousalMaritalStatusCommonLaw')"
                                       data-ana="mywealth:onboarding:family:Common Law" />
                                <label class="prefix" for="statusCommonLaw" translate="familyInformation.labels.statusCommonLaw"></label>
                            </div>
                            <div role="alert" id="spousalMaritalStatus-error" class="error-message"
                                *ngIf="spousalMaritalStatusCtrl.errors && spousalMaritalStatusCtrl.touched || (isFormSubmit && spousalMaritalStatusCtrl.errors)">
                                <span translate="familyInformation.errors.criticalError"></span>
                            </div>
                        </div>
                    </div>

                </div>

                <section *ngIf="familyInformation.isMarried()" id="marriedSection">

                    <h2 translate="familyInformation.subtitleSpouseName"></h2>

                    <div class="form-wrapper">

                        <div class="form-row">

                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="firstName" data-ana="mywealth:onboarding:family:first name"
                                            type="text" name="firstName" minlength="2" maxlength="20"
                                            [(ngModel)]="familyInformation.firstName" #firstNameCtrl="ngModel"
                                            [value]="firstNameCtrl.value" formatPipe="Capitalize" appMinLengthValidator
                                            minLengthVal='2' InputValidator="ct16" [blockPaste]="true"
                                            [appErrorAccess]="{id:'firstName-error',  submitted: isFormSubmit }"
                                            required />
                                        <label
                                            [ngClass]="{'error-field': firstNameCtrl.errors && firstNameCtrl.touched || (isFormSubmit && firstNameCtrl.errors), 'active-label': firstNameCtrl.value}"
                                            class="slide-label" for="firstName">
                                            <span class="label-text"
                                                translate="familyInformation.labels.firstName"></span>
                                        </label>
                                    </div>

                                </div>
                                <div class="error-message" id="firstName-error"
                                    *ngIf="firstNameCtrl.errors && firstNameCtrl.touched || (isFormSubmit && firstNameCtrl.errors)  ">
                                    <span translate="familyInformation.errors.spousefirstName"
                                        *ngIf="!firstNameCtrl.value"></span>
                                    <span translate="familyInformation.errors.spousefirstNameMinLength"
                                        *ngIf="firstNameCtrl.touched && firstNameCtrl.value &&firstNameCtrl?.errors?.minLengthError"></span>
                                </div>

                            </div>

                            <div class="flex-30 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="initial" type="text" maxlength="1" name="initial"
                                            [(ngModel)]="familyInformation.initial" #initialCtrl="ngModel"
                                            InputValidator="ct18" formatPipe="Capitalize" [value]="initialCtrl.value" />
                                        <label
                                            [ngClass]="{'error-field': initialCtrl.errors && initialCtrl.touched || (isFormSubmit && initialCtrl.errors), 'active-label': initialCtrl.value}"
                                            class="slide-label" for="initial">
                                            <span class="label-text"
                                                translate="familyInformation.labels.initial"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-row">

                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="lastName" type="text" name="lastName" minlength="2" maxlength="20"
                                            [(ngModel)]="familyInformation.lastName" #lastNameCtrl="ngModel"
                                            [value]="lastNameCtrl.value | capitalize:{capitalizeOnce:formService.forms.familyInformation.data?false:true}"
                                            InputValidator="ct16" [blockPaste]="true" minLengthVal='2'
                                            appMinLengthValidator
                                            [appErrorAccess]="{id:'lastName-error',  submitted: isFormSubmit }"
                                            required />
                                        <label
                                            [ngClass]="{'error-field': lastNameCtrl.errors && lastNameCtrl.touched || (isFormSubmit && lastNameCtrl.errors), 'active-label': lastNameCtrl.value}"
                                            class="slide-label" for="lastName">
                                            <span class="label-text"
                                                translate="familyInformation.labels.lastName"></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="error-message" id="lastName-error"
                                    *ngIf="lastNameCtrl.errors && lastNameCtrl.touched || (isFormSubmit && lastNameCtrl.errors)">
                                    <span translate="familyInformation.errors.spouseLastName"
                                        *ngIf="!lastNameCtrl.value"></span>
                                    <span translate="familyInformation.errors.spouseLastNameMinLength"
                                        *ngIf=" lastNameCtrl.touched && lastNameCtrl.value &&lastNameCtrl?.errors?.minLengthError"></span>
                                </div>

                            </div>

                        </div>

                        <div class="form-row" *ngIf="BILAssisted && !branchSDMultiAccount && (isTFSA && isNonQuebec)">
                            <div class="form-col">

                                <div class="form-input-checks check-default">
                                    <input type="checkbox"
                                    id="hasSuccessorAnnuitant"
                                    name="hasSuccessorAnnuitant"
                                    [(ngModel)]="familyInformation.hasSuccessorAnnuitant"
                                    [value]="familyInformation.hasSuccessorAnnuitant"
                                    class="ng-untouched ng-pristine ng-invalid"
                                    (change)="analytics.triggerView('HasSuccessorAnnuitant' + familyInformation.hasSuccessorAnnuitant)">
                                    <label for="hasSuccessorAnnuitant"
                                    class="display-inline"
                                    translate="familyInformation.labels.appointSpouseHolder">               </label>
                                </div>

                                <div class="more-info-wrapper add-margin t8px" *ngIf="true">
                                    <a role="button"  id="learnMoreFamilyOne" class="btn-icon btn-more-info-show"
                                        href="javascript://"
                                        attr.aria-label="{{ 'familyInformation.ariaLabels.appointSpouseHolderLearnMore' | translate }}"
                                        aria-controls="appointSpouseHolderControls" aria-expanded="false">
                                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                                        <span class="icon icon-large chevron-down-blue"></span>
                                    </a>
                                    <div class="more-info-content" id="appointSpouseHolderControls">
                                        <div
                                            [innerHTML]="'familyInformation.contextualHelp.appointSpouseHolderTFSA' | translate | safeHtml">
                                        </div>
                                        <p>
                                            <a role="button" class="btn-icon btn-more-info-close" href="javascript://"
                                                id="appointSpouseHolderlearnMoreClose" aria-controls="appointSpouseHolderlearnMoreClose"
                                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}" aria-expanded="true">
                                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                                <span class="icon icon-large chevron-up-blue"></span>
                                            </a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!--RIF-->
                        <div class="form-row" *ngIf="BILAssisted && !branchSDMultiAccount && (isRRIF && isNonQuebec)">
                            <div class="form-col">

                                <div class="form-input-checks check-default">
                                    <input type="checkbox"
                                    id="hasSuccessorAnnuitant"
                                    name="hasSuccessorAnnuitant"
                                    [(ngModel)]="familyInformation.hasSuccessorAnnuitant"
                                    [value]="familyInformation.hasSuccessorAnnuitant"
                                     class="ng-untouched ng-pristine ng-invalid"
                                     (change)="analytics.triggerView('HasSuccessorAnnuitant' + familyInformation.hasSuccessorAnnuitant)"
                                     >
                                    <label for="hasSuccessorAnnuitant"
                                    class="display-inline"
                                    translate="familyInformation.labels.appointSpouseHolderRRIF">

                                    </label>
                                </div>

                                <div class="more-info-wrapper add-margin t8px">
                                    <a role="button" id="learnMoreFamilyTwo" aria-controls="learnMoreAppointSpouseHolderControls"
                                        class="btn-icon btn-more-info-show"
                                        href="javascript://"
                                        attr.aria-label="{{ 'familyInformation.ariaLabels.appointSpouseHolderLearnMore' | translate }}"
                                        aria-expanded="false">
                                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                                        <span class="icon icon-large chevron-down-blue"></span>
                                    </a>
                                    <div class="more-info-content" id="learnMoreAppointSpouseHolderControls">
                                        <div
                                            [innerHTML]="'familyInformation.contextualHelp.appointSpouseHolderRIF' | translate | safeHtml">
                                        </div>
                                        <p>
                                            <a role="button" class="btn-icon btn-more-info-close" href="javascript://"
                                                id="learnMoreAppointSpouseHolderClose" aria-controls="learnMoreAppointSpouseHolderClose"
                                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}" aria-expanded="true">
                                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                                <span class="icon icon-large chevron-up-blue"></span>
                                            </a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <ng-container *ngIf="(familyInformation.hasSuccessorAnnuitant && ((isTFSA || isRRIF)) && isNonQuebec)">
                        <h3 translate="familyInformation.sectionSpousesDOB"></h3>
                        <div class="form-wrapper">
                            <div class="form-row">
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <app-date-picker id="dateOfBirth" name="dateOfBirth" [showWeekdays]="true"
                                                [isAgeValidation]="true"
                                                [(ngModel)]="familyInformation.spouseDateOfBirth" ngDefaultControl
                                                #dateOfBirthCtrl="ngModel"
                                                (underAgeValidation) = "isUnder18Age($event)">
                                            </app-date-picker>
                                        </div>
                                        <div class="form-field-button">
                                            <app-contextual-help arialabel="familyInformation.ariaLabels.dobLabel"
                                                class="button-wrapper" content="familyInformation.contextualHelp.dob">
                                                <a class="btn-icon icon-large tooltip-show" href="javascript://"
                                                    role="button" aria-label="familyInformation.ariaLabels.dobLabel"
                                                    tooltip="linkButton.moreInformation">
                                                    <img alt="" src="assets/img/info-blue.svg">
                                                </a>
                                                <div class="contextual-help position-left" role="dialog">
                                                    <div class="contextual-help-arrow">
                                                        <div class="corner-cut"></div>
                                                    </div>
                                                    <a class="btn-icon contextual-help-close tooltip-show"
                                                        href="javascript://" role="button"
                                                        aria-label="ariaLabels.btnClose"
                                                        tooltip="ariaLabels.btnClose"><span
                                                            class="icon icon-large close-grey"></span></a>
                                                    <span class="contextual-help-content"
                                                        translate="familyInformation.contextualHelp.dob"></span>
                                                </div>
                                            </app-contextual-help>
                                        </div>
                                    </div>
                                    <div class="error-message" role="alert"
                                        *ngIf="dateOfBirthCtrl?.invalid && dateOfBirthCtrl?.touched || (isFormSubmit && dateOfBirthCtrl?.errors)">
                                        <span *ngIf="dateOfBirthCtrl?.errors?.invalidDateOfBirth"
                                            translate="familyInformation.errors.dobEmpty"></span>
                                        <span *ngIf="isAgeUnder18" translate="withdrawal.errors.SpouseBirthTitleUnderage"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 translate="familyInformation.sectionSpousesSIN"></h3>
                        <div class="form-wrapper">
                            <div class="form-row">
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="spousesSIN" #appSinNumberCtrl="ngModel"
                                                [(ngModel)]="familyInformation.sinNumber" minlength="9" maxlength="9"
                                                name="spousesSIN" required type="text" InputValidator="ct3" appSIN
                                                appSinValidator appSpousalSinValidator
                                                class="ng-untouched ng-pristine ng-invalid">
                                            <label
                                                [ngClass]="{'error-field': appSinNumberCtrl.invalid && appSinNumberCtrl.touched  || (isFormSubmit && appSinNumberCtrl.errors), 'active-label': appSinNumberCtrl.value}"
                                                class="slide-label" for="spousesSIN">
                                                <span class="label-text"
                                                    translate="familyInformation.labels.spouseSinNumber"></span></label>
                                        </div>
                                        <div class="form-field-button">
                                            <app-contextual-help
                                                arialabel="familyInformation.ariaLabels.spouseSinNumber"
                                                class="button-wrapper"
                                                content="familyInformation.contextualHelp.spouseSinNumber">
                                                <a class="btn-icon icon-large tooltip-show" href="javascript://"
                                                    role="button"
                                                    aria-label="familyInformation.ariaLabels.spouseSinNumber"
                                                    tooltip="linkButton.moreInformation">
                                                    <img alt="" src="assets/img/info-blue.svg">
                                                </a>
                                                <div class="contextual-help position-left" role="dialog">
                                                    <div class="contextual-help-arrow">
                                                        <div class="corner-cut"></div>
                                                    </div>
                                                    <a class="btn-icon contextual-help-close tooltip-show"
                                                        href="javascript://" role="button"
                                                        aria-label="ariaLabels.btnClose"
                                                        tooltip="ariaLabels.btnClose"><span
                                                            class="icon icon-large close-grey"></span></a>
                                                    <span class="contextual-help-content"
                                                        translate="familyInformation.contextualHelp.spouseSinNumber"></span>
                                                </div>
                                            </app-contextual-help>
                                        </div>
                                    </div>
                                    <div role="alert" id="firstName-error" class="error-message"
                                        *ngIf="(!appSinNumberCtrl?.errors?.spousalSinNumber) && (appSinNumberCtrl.invalid && appSinNumberCtrl.touched || (isFormSubmit && appSinNumberCtrl.errors))">
                                        <span
                                            [innerHTML]="'familyInformation.errors.spouseSinNumberError' | translate | safeHtml"></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ng-container>

                </section>

                <section *ngIf="familyInformation.isMarried() && isSpousalRRSP" id="marriedSrrspSection">

                    <h2 translate="familyInformation.subtitleSpouseSIN"></h2>

                    <div class="form-wrapper">

                        <div class="form-row">
                            <div class="flex-80 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">

                                        <!-- <app-sin-number id="appSinNumber"
                                                name="appSinNumber"
                                                #appSinNumberCtrl="ngModel"
                                                [isInvalid]="appSinNumberCtrl.invalid && appSinNumberCtrl.touched || (isFormSubmit && appSinNumberCtrl.errors)"
                                                [isSinConflict]="appSinNumberCtrl?.errors?.spousalSinNumber"
                                                [(ngModel)]="familyInformation.sinNumber"
                                                appSinValidator
                                                appSpousalSinValidator
                                                required>
                                </app-sin-number> -->

                                        <input id="appSinNumber" name="appSinNumber" #appSinNumberCtrl="ngModel"
                                            [(ngModel)]="familyInformation.sinNumber" type="tel" minlength="9"
                                            maxlength="9" InputValidator="ct3" appSIN appSinValidator
                                            appSpousalSinValidator
                                            [appErrorAccess]="{id:'appSinNumber-error',  submitted: isFormSubmit }"
                                            required data-ana="mywealth:onboarding:family:spouse:sin" />

                                        <label
                                            [ngClass]="{'error-field': appSinNumberCtrl.invalid && appSinNumberCtrl.touched  || (isFormSubmit && appSinNumberCtrl.errors), 'active-label': appSinNumberCtrl.value}"
                                            class="slide-label" for="appSinNumber">
                                            <span class="label-text"
                                                translate="personalInformation.labels.sinNumber"></span>
                                        </label>

                                    </div>
                                    <div class="form-field-button">
                                        <app-contextual-help class="button-wrapper"
                                            arialabel="familyInformation.ariaLabels.spouseSinNumber"
                                            content="familyInformation.contextualHelp.spouseSinNumber">
                                        </app-contextual-help>
                                    </div>
                                </div>
                                <div role="alert" id="appSinNumber-error" class="error-message"
                                    *ngIf="(!appSinNumberCtrl?.errors?.spousalSinNumber) && (appSinNumberCtrl.invalid && appSinNumberCtrl.touched || (isFormSubmit && appSinNumberCtrl.errors))">
                                    <span
                                        [innerHTML]="'familyInformation.errors.spouseSinNumberError' | translate | safeHtml"></span>
                                </div>
                                <div role="alert" class="error-message"
                                    *ngIf="appSinNumberCtrl?.errors?.spousalSinNumber && ! appSinNumberCtrl?.errors?.sinNumber">
                                    <span
                                        [innerHTML]="'familyInformation.errors.spouseSinNumberNotSame' | translate | safeHtml"></span>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

                <section *ngIf="familyInformation.isMarried()" id="marriedJobStatusSection">

                    <h2 translate="familyInformation.subtitleSpouseEmployment"></h2>

                    <div class="form-wrapper">
                        <div class="form-row">

                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <select id="selectEmploymentStatus"
                                                name="selectEmploymentStatus"
                                                data-ana="mywealth:onboarding:family:job status"
                                                [(ngModel)]="familyInformation.employmentStatus"
                                                #selectEmploymentStatusCtrl="ngModel"
                                                [appErrorAccess]="{id:'selectEmploymentStatus-error',  submitted: isFormSubmit}"
                                                (change)="updAnalytics(familyInformation.employmentStatus)"
                                                required>
                                            <option *ngFor="let status of employmentStatus" [value]="status.code" [selected]="status.selected">{{status.label}}</option>

                                        </select>
                                        <label
                                            [ngClass]="{'error-field': selectEmploymentStatusCtrl.errors && selectEmploymentStatusCtrl.touched || (isFormSubmit && selectEmploymentStatusCtrl.errors), 'active-label': selectEmploymentStatusCtrl.value}"
                                            class="slide-label" for="selectEmploymentStatus">
                                            <span class="label-text"
                                                translate="familyInformation.labels.selectOne"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" id="selectEmploymentStatus-error" class="error-message"
                                    *ngIf="selectEmploymentStatusCtrl.errors && selectEmploymentStatusCtrl.touched || (isFormSubmit && selectEmploymentStatusCtrl.errors)">
                                    <span translate="familyInformation.errors.spouseEmploymentStatus"></span>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div *ngIf="familyInformation.isMarried() && familyInformation.employmentStatus ">

                        <section id="marriedOccupationSection">

                            <h3 translate="familyInformation.subtitleJobDescription"
                                *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED ">
                            </h3>
                            <p translate="familyInformation.subDescriptionJobDescription"
                                *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED && isSpousalRRSP"></p>

                            <div class="form-wrapper"
                                *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED ">
                                <div class="form-row">

                                    <div class="flex-40 form-col">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <select id="selectOccupation" name="selectOccupation"
                                                    data-ana="mywealth:onboarding:family:occupation"
                                                    [(ngModel)]="familyInformation.selectOccupation"
                                                    #selectOccupationCtrl="ngModel"
                                                    [appErrorAccess]="{id:'selectOccupation-error',  submitted: isFormSubmit}"
                                                    required>

                                                    <option
                                                        *ngFor="let occupation of currentOccupation | alphabeticalSort:'label'"
                                                        [value]="occupation.code" [selected]="occupation.selected">
                                                        {{occupation.label}}</option>
                                                </select>
                                                <label
                                                    [ngClass]="{'error-field': selectOccupationCtrl.errors && selectOccupationCtrl.touched || (isFormSubmit && selectOccupationCtrl.errors), 'active-label': selectOccupationCtrl.value}"
                                                    class="slide-label" for="selectOccupation">
                                                    <span class="label-text"
                                                        translate="familyInformation.labels.selectOccupation"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div role="alert" id="selectOccupation-error" class="error-message"
                                            *ngIf="selectOccupationCtrl.errors && selectOccupationCtrl.touched || (isFormSubmit && selectOccupationCtrl.errors)">
                                            <span translate="familyInformation.errors.selectionRequired"></span>

                                        </div>

                                    </div>
                                    <div class="flex-40 form-col">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <input id="jobTitle" type="text" name="jobTitle"
                                                    [(ngModel)]="familyInformation.jobTitle" #jobTitleCtrl="ngModel"
                                                    minlength="0" maxlength="50" InputValidator="ct15" />
                                                <label
                                                    [ngClass]="{'error-field': jobTitleCtrl.errors && jobTitleCtrl.touched || (isFormSubmit && jobTitleCtrl.errors), 'active-label': jobTitleCtrl.value}"
                                                    class="slide-label" for="jobTitle">
                                                    <span class="label-text"
                                                        translate="familyInformation.labels.jobTitle"></span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="form-row">

                                    <div class="flex-40 form-col">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <select id="selectNatureOfBusiness"
                                                    data-ana="mywealth:onboarding:family:industry"
                                                    name="selectNatureOfBusiness"
                                                    [(ngModel)]="familyInformation.selectNatureOfBusiness"
                                                    #selectNatureOfBusinessCtrl="ngModel"
                                                    [appErrorAccess]="{id:'selectNatureOfBusiness-error',  submitted: isFormSubmit}"
                                                    required>

                                                    <option
                                                        *ngFor="let natureOfBusiness of currentNatureOfBusiness | alphabeticalSort:'label'"
                                                        [value]="natureOfBusiness.code"
                                                        [selected]="natureOfBusiness.selected">
                                                        {{natureOfBusiness.label}}
                                                    </option>
                                                </select>
                                                <label
                                                    [ngClass]="{'error-field': selectNatureOfBusinessCtrl.errors && selectNatureOfBusinessCtrl.touched || (isFormSubmit && selectNatureOfBusinessCtrl.errors), 'active-label': selectNatureOfBusinessCtrl.value}"
                                                    class="slide-label" for="selectNatureOfBusiness">
                                                    <span class="label-text"
                                                        translate="employmentDetails.labels.selectNatureOfBusiness"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div role="alert" id="selectNatureOfBusiness-error" class="error-message"
                                            *ngIf="selectNatureOfBusinessCtrl.errors && selectNatureOfBusinessCtrl.touched || (isFormSubmit && selectNatureOfBusinessCtrl.errors)">
                                            <span translate="familyInformation.errors.selectionRequired"></span>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </section>

                        <section id="marriedCompanySection">

                            <h3 translate="familyInformation.subtitleEmployerDescription"
                                *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED ">
                            </h3>

                            <div class="form-wrapper"
                                *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED ">
                                <div class="form-row">

                                    <div class="flex-40 form-col">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <input id="employerName" data-ana="mywealth:onboarding:family:company"
                                                    type="text" name="employerName" minlength="1" maxlength="30"
                                                    [(ngModel)]="familyInformation.employerName"
                                                    #employerNameCtrl="ngModel" InputValidator="ct15"
                                                    [appErrorAccess]="{id:'employerName-error',  submitted: isFormSubmit}"
                                                    (ngModelChange)="updateEmployer(familyInformation.employerName)"
                                                    required />
                                                <label
                                                    [ngClass]="{'error-field': employerNameCtrl.errors && employerNameCtrl.touched || (isFormSubmit && employerNameCtrl.errors), 'active-label': employerNameCtrl.value}"
                                                    class="slide-label" for="employerName">
                                                    <span class="label-text"
                                                        translate="familyInformation.labels.employerName"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div role="alert" id="employerName-error" class="error-message"
                                            *ngIf="employerNameCtrl.errors && employerNameCtrl.touched || (isFormSubmit && employerNameCtrl.errors)">
                                            <span translate="familyInformation.errors.employerBusinessName"></span>

                                        </div>
                                    </div>
                                    <div class="flex-40 form-col" *ngIf="familyInformation.isBMOEmployee">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <select id="bmoGroup" name="bmoGroup"
                                                    [(ngModel)]="familyInformation.bmoGroupOption"
                                                    #bmoGroupCtrl="ngModel"
                                                    [appErrorAccess]="{id:'bmoGroup-error',  submitted: isFormSubmit}"
                                                    required>

                                                    <option
                                                        *ngFor="let bmoGroup of bmoGroupOptions | alphabeticalDescendingSort:'label'"
                                                        [value]="bmoGroup.code">{{bmoGroup.label}}</option>
                                                </select>
                                                <label class="slide-label" for="bmoGroup"
                                                    [ngClass]="{'error-field': bmoGroupCtrl.errors && bmoGroupCtrl.touched || (isFormSubmit && bmoGroupCtrl.errors), 'active-label': bmoGroupCtrl.value}">
                                                    <span class="label-text"
                                                        translate="familyInformation.labels.bmoGroupListName"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div role="alert" id="bmoGroup-error" class="error-message"
                                            *ngIf="bmoGroupCtrl.errors && bmoGroupCtrl.touched || (isFormSubmit && bmoGroupCtrl.errors)">
                                            <span translate="familyInformation.errors.selectionRequired"></span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </section>

                    </div>

                </section>

            </section>
            <!--dependents-->
            <ng-container *ngIf="isSubProductAD">

                <section>
                    <h1 translate="familyInformation.dependentsQuestion"  id="ariaRelationShip">
                    </h1>
                    <div class="form-wrapper form-number">

                        <div class="form-row" role="radiogroup" aria-labelledby="ariaRelationShip"
                            id='parent-relationShip-error'>
                            <div class="flex-elements form-col no-margin">
                                <div class="form-radio-checks radio-buttons"
                                    *ngFor="let dependentsOption of dependentsOptions"
                                    [ngClass]="{'error-field': dependentsCtrl.errors && dependentsCtrl.touched || (isFormSubmit && dependentsCtrl.errors), 'active-label': dependentsCtrl.value}">
                                    <input [attr.id]="'dependents'+dependentsOption.code"
                                        data-ana="mywealth:onboarding:familyInformation:dependents" type="radio"
                                        [name]="'dependents'+dependentsOption.code" #dependentsCtrl="ngModel"
                                        [(ngModel)]="familyInformation.dependents" [value]="dependentsOption.code"
                                        (click) = "showDependentSection(dependentsOption.code)"
                                        [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                        required />
                                    <label class="prefix" [attr.for]="'dependents'+dependentsOption.code"
                                        [translate]="dependentsOption.label"></label>
                                </div>

                            </div>
                            <div class="error-message" id="relationShip-error"
                                *ngIf="(isFormSubmit && dependentsCtrlError)">
                                <span translate="familyInformation.errors.mandatoryDependentsError"></span>
                            </div>
                        </div>
                    </div>
                    <!--How many Dependents-->
                    <ng-container *ngIf="showDependentsCount">
                        <app-number-increment
                            [name]="section"
                            [form]="form"
                            [section]="section + 'Number'"
                            [maxLength]="dependentMaxLength" [minLength]="dependentMinLength"
                            [minValue]="minDependentsCount" [maxValue]="maxDependentsCount"
                            [model]="familyInformation.dependentsCount"
                            (incrementModelChange)="onIncrementModelChange($event)">
                        </app-number-increment>

                    </ng-container>

                </section>
            </ng-container>
            <!--dependents-->
        </section>
        <section>
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater" formName="family"
                [backRoute]="APP_ROUTES.employment_information"></app-form-buttons>
        </section>

    </div>

</form>
