<form>

    <section>

        <div class="container-noMenuView">

            <div class="bmo-container-flex bmo-container-artwork">

                <div class="bmo-artwork-content">

                    <h1 [innerHTML]="'technicalErrorSession.titlePage' | translate | safeHtml"></h1>
                    <p [innerHTML]="'technicalErrorSession.descriptionPage01' | translate | safeHtml"></p>

                    <hr />
                </div>

                <div class="bmo-artwork-img">

                    <img class="tech-error" src="assets/img/artwork-broken-kite.svg" alt="" />

                </div>

            </div>

        </div>

    </section>

</form>
