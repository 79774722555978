<div class="bmo-modal bmo-other-accounts" #otherAccounts role="dialog">

    <form>
        <a role="button"
        id="other-account-modal-id"
        attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
            attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
            class="btn-icon btn-close tooltip-show"
            (click)="hideBmoOtherAccounts()"
            href="javascript://">
            <span class="icon icon-large close-grey"></span>
        </a>
        <div class="modal-content-scroll no-margin content-left">

            <section>

                <h1 translate="modalOtherAccounts.titleModal"></h1>
                <p [innerHTML]="'modalOtherAccounts.titleDescription' | translate | safeHtml"></p>
                <p [innerHTML]="'modalOtherAccounts.section1Title' | translate | safeHtml"></p>
                <ul class="no-dash">
                    <li [innerHTML]="'modalOtherAccounts.section1Link1' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section1Link2' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section1Link3' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section1Link4' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section1Link5' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section1Link6' | translate | safeHtml"></li>
                </ul>

                <hr />

                <p [innerHTML]="'modalOtherAccounts.section2Title' | translate | safeHtml"></p>
                <ul class="no-dash">
                    <li [innerHTML]="'modalOtherAccounts.section2Link1' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section2Link2' | translate | safeHtml"></li>
                </ul>

                <hr />

                <p [innerHTML]="'modalOtherAccounts.section3Title' | translate | safeHtml"></p>
                <ul class="disc">
                    <li [innerHTML]="'modalOtherAccounts.section3Link1' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section3Link2' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section3Link3' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section3Link4' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section3Link5' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section3Link6' | translate | safeHtml"></li>
                    <li [innerHTML]="'modalOtherAccounts.section3Link7' | translate | safeHtml"></li>
                </ul>


                <!--<div class="form-row form-page-buttons content-center">
                    <button class="primary element-center" data-ana="investorline:wealth:onboarding:learn more overlay:lets go"  translate="button.letsGo"
                        (click)="LetsGo()" attr.aria-label="{{ 'button.ariaLabels.letsGo' | translate }}"></button>
                </div>-->

            </section>

        </div>
    </form>
</div>

<!-- FORM to post directly to OLAP -->
<!-- <form class="hidden-element" ngNoForm name="input" #OlAPForm [action]='OLAPFormUrl' method="post">
    <input aria-hidden="true" type="hidden" name="OLAP_ExternalInterface" size="1000" length="1000"
       [value]='OLAPData' />
    <input aria-hidden="true" type="hidden" name="method" size="120" length="120" value="prefill" />
    </form> -->
