import { Injectable } from '@angular/core';
import { Subject , BehaviorSubject} from 'rxjs';
// import { ApplicationService } from "../../shared/service/application.service";
// import { Utils } from "../../shared/utils/utils.class";
// import {  productTypeAD, productTypeSD} from "../../config/app-config.constants";
// import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class CdbOlbService {
  oLBUser: Subject<any> = new Subject<any>();
  systemCriticalError: Subject<any> = new Subject<any>();
  oLBUserInprogressData = new BehaviorSubject<any>('');
  // constructor(private applicationService: ApplicationService , private storage: LocalStorageService) { }
  constructor() {}

  
  cdbOlbUserInfo(response) {
    this.oLBUser.next(response);
  }
  olbInprogressData(response) {
    this.oLBUserInprogressData.next(response);
  }
  emitSystemCriticalError(systemCriticalError){
    this.systemCriticalError.next(systemCriticalError);
  }
  /*goToLoginPage() {
    this.applicationService.hidePageUndloadPopUp$.next(false);
    let locationOrigin = window.location.origin;
    const lang =  Utils.getLanguage();
    let subProduct = Utils.getSubProduct() === productTypeAD ?  productTypeAD : productTypeSD;
    console.log(locationOrigin);
    let env = {
        production: true,
        oauthClientId: 'bil_authn_spa',
        oauthLoginUrl: '/authorize',
        oauthTokenUrl: locationOrigin + '/oauth/token',
        oauthCallbackUrl: locationOrigin + '/wealth/onboard/onlineinvesting/?lang='+lang+'&subProduct='+subProduct+'&dest=CDB'
    };
   
    const state = this.strRandom(40);
    const codeVerifier = this.strRandom(128); 
    const cryptoJS: any = (window as any).CryptoJS;
    this.storage.set('state', state);
    this.storage.set('codeVerifier', codeVerifier);
    const codeVerifierHash = cryptoJS.SHA256(codeVerifier).toString(cryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    const params = [
        'client_id=' + env.oauthClientId,
        'redirect_uri=' + encodeURIComponent(env.oauthCallbackUrl),
        'response_type=code',
        'response_mode=form_post',
        'state=' + state,
      'code_challenge=' + codeChallenge,
        'code_challenge_method=S256',
        'lang=' + lang
    ];
    window.sessionStorage.setItem('InSession', 'false');
    window.location.href = env.oauthLoginUrl + '?' + params.join('&');
  }
  private strRandom(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }*/
}
