<!-- <app-flood-light></app-flood-light> -->

<form #investmentObjectivesForm="ngForm" (ngSubmit)="onSubmit()">

    <div class="container-menuView">

        <section>

            <app-critical-error *ngIf="criticalError"></app-critical-error>

            <!--Invalid total error-->
            <div *ngIf="(invalidTotal && isFormSubmit) || (invalidTotal && isCompleted)" role="alert" class="alert alert-danger">
                <p>
                    <strong translate="investmentObjectives.errors.criticalError"></strong>
                    <br />
                    <span translate="investmentObjectives.errors.criticalErrorValueAddUp"></span>
                </p>
            </div>

            <app-invalid-form #appInvalidForm [hideIncaseOfInvalidTotal]="invalidTotal" [isFormInvalid]="(investmentObjectivesForm.invalid && isFormSubmit)"
            translateTag="investmentObjectives">
            </app-invalid-form>

            <section>

                <h1 translate="investmentObjectives.titleInvestmentObjectives"></h1>
                <p translate="investmentObjectives.descriptionInvestmentObjectives"></p>

                <div class="bmo-container-flex add-margin t40px">

                    <div class="flex-33">

                        <h2 translate="investmentObjectives.labels.incomeSubtitle"></h2>

                        <div class="form-wrapper">
                            <div class="form-row">
                                <div class="flex-fill form-col">
                                    <div class="form-field">

                                        <div class="form-label-group">
                                            <input type="text" id="income" name="income" maxlength="3" minlength="0" [(ngModel)]="accountInvestmentObjectivesObj.income"
                                                #incomeCtrl="ngModel" InputValidator="ct3" (keyup)="calculateTotal()" appFormatInputPercentage
                                                [appErrorAccess]="{id:'income-error',  submitted: isFormSubmit }"
                                                required/>
                                            <label class="slide-label" for="income" [ngClass]="{'error-field':isError(incomeCtrl), 'active-label':incomeCtrl.value !== EMPTY_STRING}">
                                                <span class="label-text" translate="investmentObjectives.labels.income"></span>
                                            </label>
                                        </div>

                                        <div class="form-field-button">
                                            <app-contextual-help class="button-wrapper" arialabel="investmentObjectives.ariaLabels.income" content="investmentObjectives.contextualHelp.income">
                                            </app-contextual-help>
                                        </div>

                                    </div>
                                    <div role="alert" id="income-error" class="error-message" *ngIf="isError(incomeCtrl)">
                                        <span translate="investmentObjectives.errors.incomeValueError"></span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-33">

                        <h2 translate="investmentObjectives.labels.capitalGains"></h2>

                        <div class="form-wrapper">
                            <div class="form-row">
                                <div class="flex-fill form-col">
                                    <div class="form-field">

                                        <div class="form-label-group">
                                            <input type="tel" id="shortTerm" name="shortTerm" maxlength="3" minlength="0" [(ngModel)]="accountInvestmentObjectivesObj.shortTerm"
                                                #shortTermCtrl="ngModel" InputValidator="ct3" (keyup)="calculateTotal()" appFormatInputPercentage
                                                [appErrorAccess]="{id:'shortTerm-error',  submitted: isFormSubmit }"
                                                required/>
                                            <label class="slide-label" for="shortTerm" [ngClass]="{'error-field':isError(shortTermCtrl), 'active-label':shortTermCtrl.value !== EMPTY_STRING}">
                                                <span class="label-text" translate="investmentObjectives.labels.shortTerm"></span>
                                            </label>
                                        </div>

                                        <div class="form-field-button">
                                            <app-contextual-help class="button-wrapper" arialabel="investmentObjectives.ariaLabels.shortTerm" content="investmentObjectives.contextualHelp.shortTerm">
                                            </app-contextual-help>
                                        </div>

                                    </div>
                                    <div role="alert" id="shortTerm-error" class="error-message" *ngIf="isError(shortTermCtrl)">
                                        <span translate="investmentObjectives.errors.shortTermValueError"></span>
                                    </div>

                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex-fill form-col">
                                    <div class="form-field">

                                        <div class="form-label-group">
                                            <input type="text" id="mediumTerm" name="mediumTerm" maxlength="3" minlength="0" [(ngModel)]="accountInvestmentObjectivesObj.mediumTerm"
                                                #mediumTermCtrl="ngModel" InputValidator="ct3" (keyup)="calculateTotal()" appFormatInputPercentage
                                                [appErrorAccess]="{id:'mediumTerm-error',  submitted: isFormSubmit }"
                                                required/>
                                            <label class="slide-label" for="mediumTerm" [ngClass]="{'error-field':isError(mediumTermCtrl), 'active-label':mediumTermCtrl.value !== EMPTY_STRING}">
                                                <span class="label-text" translate="investmentObjectives.labels.mediumTerm"></span>
                                            </label>
                                        </div>

                                        <div class="form-field-button">
                                            <app-contextual-help class="button-wrapper" arialabel="investmentObjectives.ariaLabels.mediumTerm" content="investmentObjectives.contextualHelp.mediumTerm">
                                            </app-contextual-help>
                                        </div>

                                    </div>
                                    <div role="alert" id="mediumTerm-error" class="error-message" *ngIf="isError(mediumTermCtrl)">
                                        <span translate="investmentObjectives.errors.mediumTermValueError"></span>
                                    </div>

                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex-fill form-col">
                                    <div class="form-field">

                                        <div class="form-label-group">
                                            <input type="text" id="longTerm" name="longTerm" maxlength="3" minlength="0" [(ngModel)]="accountInvestmentObjectivesObj.longTerm"
                                                #longTermCtrl="ngModel" InputValidator="ct3" (keyup)="calculateTotal()" appFormatInputPercentage
                                                [appErrorAccess]="{id:'longTerm-error',  submitted: isFormSubmit }"
                                                required/>
                                            <label class="slide-label" for="longTerm" [ngClass]="{'error-field':isError(longTermCtrl), 'active-label':longTermCtrl.value !== EMPTY_STRING}">
                                                <span class="label-text" translate="investmentObjectives.labels.longTerm"></span>
                                            </label>
                                        </div>

                                        <div class="form-field-button">
                                            <app-contextual-help class="button-wrapper" arialabel="investmentObjectives.ariaLabels.longTerm" content="investmentObjectives.contextualHelp.longTerm">
                                            </app-contextual-help>
                                        </div>

                                    </div>
                                    <div role="alert" id="longTerm-error" class="error-message" *ngIf="isError(longTermCtrl)">
                                        <span translate="investmentObjectives.errors.longTermValueError"></span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-33">

                        <app-graph [form]="investmentObjectivesForm" [totalValue]="accountInvestmentObjectivesObj.total" [labelContent] ="'investmentObjectives.ariaLabels.graph'"></app-graph>

                    </div>

                </div>

            </section>

        </section>

        <section>

            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater" formName="investmentObjectives" [backRoute]="APP_ROUTES.investment_knowledge"></app-form-buttons>

        </section>

    </div>

</form>
