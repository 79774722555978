<div class="form-wrapper">
    <div class="form-row form-page-buttons">

        <div class="form-col left-side">

            <button [disabled]="isBackDisabled"
                    data-ana="mywealth:onboarding:account access:back"
                    *ngIf="!hideBackButton"
                    id="backButtonID"
                    class="secondary"
                    type="button"
                    (click)="onBack()"
                    translate="button.back"
                    attr.aria-label="{{ 'button.ariaLabels.back' | translate }}"></button>

        </div>

        <div id="{{formName + 'FooterButtonSection'}}" class="form-col right-side flex-xaxis-center">

            <button *ngIf="(!isEnforceFinishLaterEnabled && showFinishLater) || isCdbOlbUser"
                    data-ana="mywealth:onboarding:account access:finish later"
                    id="finishLaterID"
                    class="tertiary"
                    type="button"
                    [disabled]="disabled && this.buttonLabel === 'button.eSign'"
                    (click)="onFinishLater()"
                    translate="button.finishLater"></button>

            <button *ngIf="this.buttonLabel === 'button.eSign', else simpleButton"
                    id="continueButtonID"
                    [disabled]="disabled"
                    class="primary" type="submit"
                    (click)="onContinue()"
                    [translate]="buttonLabel"
                    [attr.data-ana]="dataAnaValue"
                    attr.aria-label="{{ 'button.ariaLabels.continue' | translate }}"></button>

            <ng-template #simpleButton>
                <button [disabled]="disabled"
                        data-ana="mywealth:onboarding:account access:continue"
                        id="continueButton1ID"
                        class="primary"
                        type="submit"
                        (click)="onContinue()"
                        [translate]="buttonLabel"
                        attr.aria-label="{{applicationSaved ? ('button.ariaLabels.continue' | translate) : ('button.ariaLabels.continueSaveApplication' | translate)}}"></button>
            </ng-template>

        </div>
    </div>
</div>

