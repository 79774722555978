import { ApplicationService } from './../../shared/service/application.service';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, OnChanges } from '@angular/core';
import { FormService } from '../../shared/service/form.service';
import { ActivatedRoute } from '@angular/router';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, GROSS_ANNUAL_INCOME_EN, GROSS_ANNUAL_INCOME_FR, RADIO_TYPES, ACCOUNT_TYPE } from '../../config/app-config.constants';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { Jquery } from "../../shared/utils/jquery.class";
import { Subscription } from 'rxjs';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { CheckboxCardComponent } from '../../shared/components/checkbox-card/checkbox-card.component';

export class FinancialInformation {
    otherSourceOfWealth: any = null;
    sourcesOfWealth: any[] = []; // list of string Wealth
    netWorth: any;
    netLiquidAssets: any = null;
    grossAnnualIncome: any = null;
    annualIncReq: any = null;
    borrowingMoney: any = null;
    saveInvestibleAsset: any = null;
    constructor() {
    }
}
@Component({
    selector: 'app-financial-details',
    templateUrl: './financial-details.component.html'
})

export class FinancialDetailsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    public APP_ROUTES = APP_ROUTES;
    private isCurrentLangEnglish: boolean;
    private isCurrentLangFrench: boolean;
    public financialInformation: FinancialInformation;
    public wealthSources: Array<WealthSource>;
    public isFormSubmit = false;
    public isMargin: Boolean = false;
    public disableFinishLater;
    public showFinishLater;
    appNumber;
    public accountSetup;
    criticalError: boolean;
    public borrowingMoneyCtrlError: boolean = true;
    public url: any;
    public grossAnnualIncomeList: any;
    public isMandatoryProject: boolean = true;
    public showFinancialOther: boolean = false;

    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    public borrowingMoneyOptions: any;
    public backRoute: any;
    public borrowMoneySubscription: Subscription;
    public savingAssetPercentageList: any;
    public RADIO_TYPES: any = RADIO_TYPES;
    public adviceDirect = false;
    public isRif: boolean = false;
    private onExitApplicationPerformSaveSubscriber;
    public isDataRationalizationEnabled: boolean = false;
    public netWorthAmountSubscriber: Subscription;
    public isMultiAccountAndMarginExist: any ;
    public isMultiAccountOpenFlag: any;
    // For accessibility purposes
    @ViewChild('grossAnnualIncomeCtrl', {static: true}) grossAnnualIncomeCtrl: UntypedFormControl;
    @ViewChild('netLiquidAssetsCtrl', {static: true}) netLiquidAssetsCtrl: UntypedFormControl;
    @ViewChild('netWorthCtrl', {static: true}) netWorthCtrl: UntypedFormControl;
    @ViewChild('wealthSourceCtrl', {static: true}) wealthSourceCtrl: UntypedFormControl;
    @ViewChild('otherSourceOfWealthCtrl', {static: true}) otherSourceOfWealthCtrl: UntypedFormControl;
    @ViewChild('financialDetailsForm', {static: true}) financialDetailsForm: NgForm;
    @ViewChild('annualIncomeCtrl', {static: true}) annualIncomeCtrl: UntypedFormControl; //Added Annual Income

    @ViewChild('borrowingMoneyCtrl', {static: false}) borrowingMoneyCtrl: UntypedFormControl;
    constructor(private formService: FormService,
        public titleService: Title,
        private winRef: WindowRefService,
        private applicationService: ApplicationService,
        private route: ActivatedRoute,
        public analytics: AnalyticsService) {

        this.financialInformation = new FinancialInformation();
        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.isMargin = this.applicationService.isMargin();//Utils.isMargin();
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'finFrm') {
                    this.onFinishLater();
                }
            });
            this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
                if (value) {
                    this.onFinishLater(true);
                }
            });
        this.formService.forms.financialInformation.isVisited = true;
        this.formService.setActiveComponent('financialInformation', this);
        this.url = '/' + APP_ROUTES.financial_details;

        if (Utils.getLanguage() == 'fr') {
            this.grossAnnualIncomeList = GROSS_ANNUAL_INCOME_FR;
        } else {
            this.grossAnnualIncomeList = GROSS_ANNUAL_INCOME_EN;
        }
        this.showFinishLater = true;

        if (Utils.isSubProductAD()) {
            this.adviceDirect = true;
        }

    }

    ngAfterViewInit() {

      //  this.analytics.setState('step 9');
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('financialInformation');
        // this.analytics.setPPVID(ANALYTICS.financial_details);

        this.checkFormValidityOnSignOut();

        // this.analytics.setvPageTitle(Utils.getPageTitleFinancialDetails());
        // this.analytics.setOriginalLocation(Utils.getPageTitleFinancialDetails());
        // this.analytics.setvURL(Utils.getvURL());
        if(this.isMandatoryProject && this.adviceDirect){
            this.borrowMoneySubscription = this.borrowingMoneyCtrl.valueChanges.subscribe((res)=>{
                if(res!=null){
                   this.borrowingMoneyCtrlError = false;
                }
             });
        }

    }

    updateGrossAnnualIncome(value) {
        this.financialInformation.grossAnnualIncome = value;
        this.analytics.triggerView('grossAnnualIncome' + value);
    }

    setshowFinancialOther(selectedCheckboxCode){
        if(selectedCheckboxCode==WealthLabel.financialOther){
         this.showFinancialOther = !this.showFinancialOther;
        }
        if(!this.showFinancialOther){
            this.financialInformation.otherSourceOfWealth = null;
        }
        let reasonSelectedDesc;
        let selectedCheckBoxValue = false;
        reasonSelectedDesc =  this.wealthSources.filter(x => x.code == selectedCheckboxCode);
        if (this.financialInformation.sourcesOfWealth.length > 0) {
            if(this.financialInformation.sourcesOfWealth.includes(selectedCheckboxCode)){
                selectedCheckBoxValue = true;
            }else{
                selectedCheckBoxValue = false;
            }
        }else{
            selectedCheckBoxValue = false;
        }
        if (reasonSelectedDesc.length > 0){
            this.analytics.triggerView('SourceOfWealth' + reasonSelectedDesc[0].label.replace(/ /g,'')+selectedCheckBoxValue);
        }
    }


    updTargetSavingAssetPercentage(reasonSelected: any){
        let reasonSelectedLabel;
        reasonSelectedLabel =  this.savingAssetPercentageList.filter(x => x.code == reasonSelected)[0].label;
        let reasonSelectedDesc = reasonSelectedLabel.split('.');
        this.analytics.triggerView('saveInvestibleAsset' + reasonSelectedDesc[reasonSelectedDesc.length - 1]);
    }

    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    // do not initiate financialInformation.isVisited because that is done on the celebration page
    ngOnInit() {
        this.isRif = Utils.isRIF();
        this.isMultiAccountOpenFlag = this.applicationService.getMultiAccountOpenFeatureFlag();

        if (this.isRif && this.adviceDirect){
            setTimeout(() => {
                this.financialInformation.annualIncReq = true;
                document.getElementById('annualIncomeNo').setAttribute('disabled', 'disabled');
                document.getElementById('annualIncomeYes').setAttribute('disabled', 'disabled');
            },0)
        }
        this.borrowingMoneyOptions = [{
            code: true,
            label: "financialDetails.labels.yes"
        },{
            code: false,
            label: "financialDetails.labels.no"
        }]

        this.savingAssetPercentageList = [
            {
                code: '1',
                id: 'saveAsset1',
                description: 'financialDetails.percentSavingAssetOptions.zeroToTwentyfive',
                label: 'financialDetails.percentSavingAssetOptions.zeroToTwentyfivePercent',
                imageUrl: 'assets/img/financialOther1.svg'

            },
            {
                code: '2',
                id: 'saveAsset2',
                description: 'financialDetails.percentSavingAssetOptions.twentyfiveToFifty',
                label: 'financialDetails.percentSavingAssetOptions.twentyfiveToFiftyPercent',
                imageUrl: 'assets/img/financialOther2.svg'
            },
            {
                code: '3',
                id: 'saveAsset3',
                description: 'financialDetails.percentSavingAssetOptions.fiftyToSeventyFive',
                label: 'financialDetails.percentSavingAssetOptions.fiftyToSeventyFivePercent',
                imageUrl: 'assets/img/financialOther3.svg'
            },
            {
                code: '4',
                id: 'saveAsset4',
                description: 'financialDetails.percentSavingAssetOptions.seventyFiveToHundred',
                label: 'financialDetails.percentSavingAssetOptions.seventyFiveToHundredPercent',
                imageUrl: 'assets/img/financialOther4.svg'
            }
        ]


        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        // Set Back route in case of withdrawal or else beneficiary or else family information
        if (Utils.getWithdrawalPageCondition() === true) {
            this.backRoute = APP_ROUTES.withdrawal;
        } else if (Utils.getBeneficiaryPageCondition() === true) {
            this.backRoute = APP_ROUTES.beneficiary;
        } else {
            this.backRoute = APP_ROUTES.family_information;
        }

        this.titleService.setTitle(Utils.getPageTitleFinancialDetails());

        this.applicationService.fetchReferences(this.route);
        if (this.applicationService.appReferences) {
            this.wealthSources = this.applicationService.appReferences.references.wealthSources.map(a => {
              if(a.paEligible === true) {
               return new WealthSource(a.code, a.label)
              }
             } ).filter(Boolean);
        }
        this.formService.setActiveForm(this.financialDetailsForm);
        // already visited form and now visiting back again
        if (this.formService.forms.financialInformation.data != null) {
            this.financialInformation = this.formService.getFormData('financialInformation');
            if(this.financialInformation.sourcesOfWealth.includes((WealthLabel.financialOther).toString())){
             this.showFinancialOther = true;
            }
            else{
                this.showFinancialOther = false;
            }
            /*
            for (const entry of this.financialInformation.sourcesOfWealth) {
             this.wealthSources.filter(a => a.code === entry).reduce(a => a).checked = true;
            }
            */
            // return;
        }

        //console.log("this.wealthSources -", this.wealthSources);

        // first time on financial Information page instantiate the model
        // this.financialInformation = new FinancialInformation();

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });
        this.isDataRationalizationEnabled = this.applicationService.getDataRationalizationFeatureFlag();
        setTimeout(() => {
            this.setGTM();
        }, 200);
        this.netWorthAmountSubscriber = this.applicationService.getNetWorthAmount().subscribe((value) => {
            this.financialInformation.netWorth = value;
            const netWorth = document.getElementById('netWorth') as HTMLInputElement;
            netWorth.focus();
        });

        this.isMultiAccountAndMarginExist = this.applicationService.isMultiAccountAndMarginApp();


    }




    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {

                if (this.financialDetailsForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }

    // if the WealthSource financialOther is checked show the input field and make it required
    /*
    get showFinancialOther() {
        return this.wealthSources && this.wealthSources.filter(x => +x.code === WealthLabel.financialOther).reduce(a => a).checked;
    }
    */
    isError(ctrl: any) {
        return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
    }


    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {

        if (!this.financialDetailsForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.resetComponentObjectAttr();
        if (Utils.getIsOLBUser()  && this.financialDetailsForm.valid) {
            this.formService.forms.financialInformation.isCompleted = true;
        }
        this.formService.setFormData('financialInformation', this.financialInformation);

        // To prepare payload with PI , CI, EI, FI and FD
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);

        if (Utils.isSpousalRRSP()) {
            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.financialInformation.isCompleted) {
            const emptyFinancial = new FinancialInformation();
            this.formService.getPayload().setFinancialInformation(emptyFinancial);
        } else {
            if (this.financialDetailsForm.valid) {
                this.formService.getPayload().setFinancialInformation(this.financialInformation);
            }
        }

        // console.log('payload', this.formService.getPayload());

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });

    }

    saveOnSignOut() {

        this.resetComponentObjectAttr();
        this.formService.forms.financialInformation.isCompleted = true;
        this.formService.setFormData('financialInformation', this.financialInformation);

        // To prepare payload with PI , CI, EI, FI and FD
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);


        if (Utils.isSpousalRRSP()) {
            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.financialInformation.isCompleted) {
            const emptyFinancial = new FinancialInformation();
            this.formService.getPayload().setFinancialInformation(emptyFinancial);
        } else {
            if (this.financialDetailsForm.valid) {
                this.formService.getPayload().setFinancialInformation(this.financialInformation);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;

                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }


    onSubmit() {
        this.isFormSubmit = true;

        // For accessibility purposes, set focus on the invalid element.
        if (!this.financialDetailsForm.valid) {
            this.setFocus();
        }

        if (this.financialDetailsForm.valid) {

            this.resetComponentObjectAttr();
            this.formService.forms.financialInformation.isCompleted = true;
            this.formService.setFormData('financialInformation', this.financialInformation);

            // To prepare payload with PI , CI, EI, FI and FD
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');
            const familyInformation = this.formService.getFormData('familyInformation');
            const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

            this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(familyInformation);
            this.formService.getPayload().setFinancialInformation(this.financialInformation);

            if (Utils.isSpousalRRSP()) {

                this.formService.getPayload().setSpousalRRSP(familyInformation);
            }

            // console.log('payload with PI CI EI FI FD', this.formService.getPayload());

            if (Utils.getIsInSession() === false) {

                if (this.adviceDirect) {

                    this.applicationService.saveAndcontinue(this.financialDetailsForm, APP_ROUTES.investment_knowledge);

                } else {

                    this.applicationService.saveAndcontinue(this.financialDetailsForm, APP_ROUTES.regulatory_disclosures);
                }


            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {

                            if (this.adviceDirect) {

                                this.applicationService.saveAndcontinue(this.financialDetailsForm, APP_ROUTES.investment_knowledge);

                            } else {

                                this.applicationService.saveAndcontinue(this.financialDetailsForm, APP_ROUTES.regulatory_disclosures);
                            }

                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }

        } else {
            // console.log('form is invalid');
        }
    }

    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {
        // setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.financialDetailsForm);
           }, 250)
    }


    resetComponentObjectAttr() {
        // reset otherSourceOfWealth input string if financialOther was unchecked
        if (this.financialInformation.sourcesOfWealth.indexOf(WealthLabel.financialOther.toString()) === -1) {
            this.financialInformation.otherSourceOfWealth = null;
        }
    }

    ngOnDestroy() {

        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('financialInformation', this.financialInformation);
        this.finishLaterSubscriber.unsubscribe();
        if(this.borrowMoneySubscription){
            this.borrowMoneySubscription.unsubscribe();
        }
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
        if(this.netWorthAmountSubscriber) {
            this.netWorthAmountSubscriber.unsubscribe();
        }
    }


    setGTM() {

        const gtmDataLayerForAccountDetails: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleFinancialDetails(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.FINANCIAL_DETAILS,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.financial_details,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);
        // console.log(this.winRef.nativeWindow.dataLayer);

    }
    openCalculator() {
        $('.bmo-net-worth-modal, .modal-overlay').show();
        Jquery.modalFocusTrap('bmo-net-worth-modal');
        Jquery.modalScrollHeight();
    }
}



export class WealthSource {
    code: string;
    label: string;
    imgLink: string;
    id: string;
    checked: boolean;

    constructor(code: any, label: string) {
        this.label = label;
        this.code = code; // matches rdm value
        this.imgLink = 'assets/img/' + WealthLabel[code] + '.svg'; // picture name must match the enum
        this.id = WealthLabel[code]; // string WealthLabel name
        this.checked = false;
    }


}




export enum WealthLabel {
    financialEmployment = 1,
    financialInvestment = 2,
    financialRealEstate = 3,
    financialInheritance = 4,
    financialGift = 5,
    financialOther = 6,
}
