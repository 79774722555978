import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ApplicationService } from "../service/application.service";
import { Utils } from "../utils/utils.class";

@Injectable({
    providedIn: 'root'
})

export class CMSMessageResolver implements Resolve<any> {
    constructor(private applicationService: ApplicationService) {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if(Utils.getCmsMessageCallNotRequired()){
            Utils.setCmsMessageObject(null);
            return of({});
        }
        return this.applicationService.getCMSMessage().pipe(
            tap(response => {
                if (response && Utils.showCMSMessage) {
                    Utils.setCmsMessageObject(response);
                    this.applicationService.cmsParametersExtracted(true);
                } else {
                    Utils.setCmsMessageObject(null);
                }
            }),
            catchError(() => {
                Utils.setCmsMessageObject(null);
                return of({});
            })
        );
    }
}