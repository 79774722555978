import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'socialSecurityNumber'
})
export class SocialSecurityNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {

    if (!value) {
      return '';
    }
    const numbers = value.replace(/ +/g, '').replace('(', '').replace(')', '').replace('-', '');
    const char = {
      3: '-',
      5: '-'
    };
    value = '';
    for (let i = 0; i < numbers.length; i++) {
      value += (char[i] || '') + numbers[i];
    }
    return value;

    // if (value !== undefined && value !== null) {
    //   // replace all non-numbers with an empty string
    //   value = value.replace(/\D/g, '').trim();

    //   // appropriately slice and add dashes, trim and replace all dashes that may exist on the outside
    //   value = (value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5, 9)).trim().replace(/^\-+|\-+$/g, '');
    //   // value = value.replace(/-+/g, '-'); // replaces all dashes if they exist with one dash
    //   return value;
    // }
  }

}
