
import { ApplicationService } from './../service/application.service';
import { Input ,Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appBindingCurrency]'
})
export class BindingCurrencyDirective  {

  @Input() public inputValue : String;

  constructor(private _el: ElementRef, private _application: ApplicationService) { }

  @HostListener('change') ngOnChanges() {
    const currencyFormatCode = (this._application.isCurrentLangEnglish()) ? 'en-CA' : 'fr-CA';
    let numbers;
    if (this._el.nativeElement.textContent) {
      numbers = this.inputValue.toString().replace(/\D+/g, '');

      if (numbers.length < 1) {
        return this._el.nativeElement.textContent = '';
      }

      return this._el.nativeElement.textContent = new Intl.NumberFormat(currencyFormatCode, {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(numbers);
    }

  }
}
