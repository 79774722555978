import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../service/application.service';

import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';

@Injectable()
export class ReferenceResolver implements Resolve<any> {

    constructor(private applicationService: ApplicationService,
        private route: ActivatedRoute,
        private config: ConfigResolverService<any>) { }

    resolve(route: ActivatedRouteSnapshot) {

        return this.applicationService.getReferences(route.queryParams['lang']);
    }
}
