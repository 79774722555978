<div class="bmo-modal ad-works-modal" #showHowADWorks>

    <a role="button"
       attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
       attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
       class="btn-icon btn-close tooltip-show"
       href="javascript://"
       (click)="hideAdWorks()">
        <span class="icon icon-large close-grey"></span>
    </a>

    <div class="form-wrapper no-margin modal-content-scroll">
        <div class="form-row flex-maxis-center">
            <div class="form-col">
                <!--<div [innerHTML]="'accountDetail.contextualHelp.shortSellingMarginAccountMoreInfo' | translate | safeHtml"></div>-->

                <div class="bmo-container-flex">
                    <div class="flex-50">
                        <h3 class="font-bold" translate="setExpectations.newBMOTileTitle6"></h3>
                        <ul class="disc text-left">
                            <li [innerHtml]="'setExpectations.newBMOTileTitle6Point1' | translate | safeHtml"></li>
                            <li [innerHtml]="'setExpectations.newBMOTileTitle6Point2' | translate | safeHtml"></li>
                            <li [innerHtml]="'setExpectations.newBMOTileTitle6Point3' | translate | safeHtml"></li>
                            <li [innerHtml]="'setExpectations.newBMOTileTitle6Point4' | translate | safeHtml"></li>
                        </ul>
                    </div>
                    <div class="flex-50 flex-elements flex-maxis-center">
                        <div class="videoPlay content-center" [innerHTML]="'setExpectations.videoPlay' | translate | safeHtml"></div>
                    </div>
                </div>

                <div class="add-margin t24px">

                    <h3 class="font-bold" translate="setExpectations.newBMOTileTitle7"></h3>
                    <p [innerHtml]="'setExpectations.newBMOTileContent7' | translate | safeHtml"></p>

                    <div class="table-x-scroll">
                        <table class="bmo-table">
                            <thead class="col50">
                                <tr>
                                    <th scope="col">
                                        <span [innerHtml]="'setExpectations.table.tableTitle1' | translate | safeHtml"></span>
                                        <app-contextual-help class="button-wrapper va-middle"
                                                             arialabel="setExpectations.ariaLabels.table"
                                                             content="setExpectations.contexualHelp.table"
                                                             inputDataAna="investorline:wealth:onboarding:tool tip">
                                        </app-contextual-help>
                                    </th>
                                    <th scope="col">
                                        <span [innerHtml]="'setExpectations.table.tableTitle2' | translate | safeHtml"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span [innerHtml]="'setExpectations.table.tableColumn1a' | translate | safeHtml"></span></td>
                                    <td><span [innerHtml]="'setExpectations.table.tableColumn2b' | translate | safeHtml"></span></td>
                                </tr>
                               <!--  <tr> /** As per MWB-13407 Story **/
                                    <td><span [innerHtml]="'setExpectations.table.tableColumn1b' | translate | safeHtml"></span></td>
                                    <td><span [innerHtml]="'setExpectations.table.tableColumn2b' | translate | safeHtml"></span></td>
                                </tr> -->
                                <tr>
                                    <td><span [innerHtml]="'setExpectations.table.tableColumn1c' | translate | safeHtml"></span></td>
                                    <td><span [innerHtml]="'setExpectations.table.tableColumn2c' | translate | safeHtml"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p [innerHtml]="'setExpectations.moreInformation' | translate | safeHtml"></p>

                    <h3 class="font-bold add-margin t24px" translate="setExpectations.newBMOTileTitle8"></h3>

                    <ol class="bmo-container-flex container-expectations">
                        <li class="item-process get-to-know-you flex-33 content-center"
                            [innerHtml]="'setExpectations.newBMOTileTitle8Point1' | translate | safeHtml">
                        </li>
                        <li class="item-process assign-profile flex-33 content-center"
                            [innerHtml]="'setExpectations.newBMOTileTitle8Point2' | translate | safeHtml">
                        </li>
                        <li class="item-process building-profolio flex-33 content-center"
                            [innerHtml]="'setExpectations.newBMOTileTitle8Point3' | translate | safeHtml">
                        </li>
                    </ol>

                </div>

            </div>
        </div>

        <div class="form-row form-page-buttons">
            <button class="primary element-center" type="submit" translate="button.close" (click)="hideAdWorks()"></button>
        </div>

    </div>

</div>
