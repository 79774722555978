<div class="container-menuView">
	<!-- 
	<form #branchAssistedForm="ngForm" (ngSubmit)="branchSetup()">
		<section>
			<section>
				<div class="form-wrapper">
					<label>PayLoad</label><br>
					<textarea cols="100" rows="20" id="payLoad" type="text" name="payLoad"
						[(ngModel)]="branchAssisted.payLoad"></textarea><br>
					<button type="submit">Submit</button>
				</div>
			</section>
		</section>
	</form> -->

	<!--action="https://api.wealth.onboard.secure.channels.dv2.osft.dev.bmogc.net/wealth/onboard/api/session/v1/branchSetup?lang=en"-->

	<!--dit url: https://wealth.dit2.bmo.com/wealth/onboard/api/session/v1/branchSetup?lang=en-->

	<form #branchAssistedForm="ngForm" id="testForm" name="testForm" method="{{formVar.method}}" action="{{formVar.actionUrl}}">
		<section>
			<section>
				<div class="form-wrapper">

					<!--EN/FR button-->
					<button type="primary" (click)="setLangEN()">EN</button>
					<button type="primary" (click)="setLangFR()">FR</button>

					<br>

					<label>PayLoad</label>
					<br>
					<textarea [disabled]="formVar.disablePayload" cols="100" rows="20" id="payLoad" type="text" name="payLoad" [(ngModel)]="branchAssisted.payLoad"></textarea>
					<br>
					<input type="hidden" name="lang" value="{{this.lang}}" *ngIf="formVar.disablePayload">
					<button type="submit" (click)="branchSetup()"><-Existing-> Process Now</button>
					<button type="submit" (click)="transitData()"><-NEW-> Transit Now</button>
				</div>
			</section>
		</section>

	</form>
</div>