import { Jquery } from './../../utils/jquery.class';
import * as $ from 'jquery';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils/utils.class';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ConfigResolverService } from '../../../config/external-config/service/config-resolver.service';
import { ApplicationService } from '../../../shared/service/application.service';
import { Router, NavigationExtras } from '@angular/router';
import { APP_ROUTES, ANALYTICS } from './../../../config/app-config.constants';
import { AnalyticsService } from '../../service/analytics.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html'
}) 

export class SignOutComponent implements OnInit {
  public isCurrentLangEnglish: boolean;
  public isCurrentLangFrench: boolean;
  public lang;
  public config: any;
  public applicationNumber: any;

  public showSignoutPopup: boolean;
  public valid: boolean;

  public signOutToSave: boolean;
  public signOutPerformed: boolean;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private http: HttpClient,
    private analytics: AnalyticsService,
    public router: Router,
    private configService: ConfigResolverService<any>,
    public applicationService: ApplicationService) {

    this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
    this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();


    if (this.isCurrentLangEnglish) {
      this.translate.use('en');
      this.lang = 'en';
    } else if (this.isCurrentLangFrench) {
      this.translate.use('fr');
      this.lang = 'fr';
    }
  }

  ngOnInit() {
  
    this.applicationService.signOutClicked.subscribe((signOutButtonClicked: any) => {
      if (signOutButtonClicked == true) { // Clicked on sign-out button
        this.emitCertainPage();
        Utils.setSignOutClicked(true);
      
        // console.log('overlay opened - signout');
             this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.SIGN_OUT);
      }
    });
  }

showFocus(){
  setTimeout(() => {
    Jquery.modalFocusTrap('bmo-sign-out');
  }, 100); 
}
  getCurrentPage() {
    /**Get current page */
    let url = this.router.url;
    let currentPage = url.split('?')[0];
   
    return currentPage;
  }

  emitCertainPage() {
    let currentPage = this.getCurrentPage();

    /**Emit current page to check validity */
    if (currentPage == "/celebration" || currentPage == "/celebration-review" 
         || currentPage == "/set-expectation" || currentPage =="/application-approval-downgrade") {

      /**get & set application number & show pop-up & set valid = true */
      this.applicationNumber = this.applicationService.applicationNumber;

      if (!this.applicationNumber) {
        this.applicationNumber = Utils.getApplicationNumber();
      }
    
       
      this.showSignoutPopup = true;
      this.valid = true;
    this.showFocus()

    } else {
      this.applicationService.onSignOutCheckValidity.emit("clicked on " + currentPage);

      /**check validity */
      this.checkValidityofCurrentPage();
    }
  }

  checkValidityofCurrentPage() {
    /**Check if the current page is valid */
    this.valid = this.applicationService.validForm;

    /**get & set application number */
    this.applicationNumber = this.applicationService.applicationNumber;

    if (!this.applicationNumber) {
      this.applicationNumber = Utils.getApplicationNumber();
    }

    /**Show pop-up */
    this.signOutPerformed = false;
    this.showSignoutPopup = true;
    this.showFocus()

  }

  signOut() {
    let currentPage = this.getCurrentPage();
    this.signOutToSave = true;
      /**If its valid and if "sign me out" button is clicked, perform save operation */
    if (currentPage == "/celebration" || currentPage == "/celebration-review" || currentPage == "/terms-conditions"
    || currentPage =="/set-expectation" || currentPage =="/application-approval-downgrade") { // For Celebration screen
      this.applicationService.onSignOut();
      this.closeModel();

    } else { //For other screens

      if (this.valid && this.signOutToSave) { /**Valid form */
        this.emitSaveOperation();
      } else { /**Call  Logout API call -- Invalid form*/
        this.applicationService.onSignOut();
        this.closeModel();
      }
    }

  }

  emitSaveOperation() {
    let currentPage = this.getCurrentPage();

    this.applicationService.onSignOutPerformSave.emit("perform save " + currentPage);

    /**on success of save operation, call logout api */
    this.onSaveOpertionSuccess();
  }

  onSaveOpertionSuccess() {
    /**If save operation  is successful, close the modal and re-route to select-account */
    this.applicationService.saveOperationPerformed.subscribe((saveOperationPerformedCheck: any) => {
      if (saveOperationPerformedCheck && !this.signOutPerformed) {
        this.signOutPerformed = true;
        this.applicationService.onSignOut();
        this.closeModel();
      }
    });

  }

  closeModel() {
    this.showSignoutPopup = false;
    Jquery.modalDissableFocusTrap();
    (this.signOutToSave === true) ? this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.SIGN_OUT_COMPLETED) :
      this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.SIGN_OUT);
  }

}
