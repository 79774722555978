export const CustomKeys = {
    ApplicationId: 'AppId',
    CategoryCode: 'CatCode',
    ProductCode: 'ProductCode'
};


// category codes for each ui-state
export const FloodLightConfig = {
    masterURL:
    `https://4227502.fls.doubleclick.net/activityi;src=4227502;type=inves772` +
    `;cat=` + CustomKeys.CategoryCode +
    `;u4=` + CustomKeys.ApplicationId +
    `;u18=` + CustomKeys.ProductCode +
    `;u21=false;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;`,

    customValueKeys: {
        ApplicationId: 'AppId'
    },
    uiStates: {
        

    }
};
