<form>
    
    <div class="celebration content-center" id="celebrationReview-Contianer">

        <div class="celebration-img congratulation-review">
            <img src="assets/img/almost-done.svg" alt="" />
        </div>

        <h1 [translateParams]={name:firstName|translate} translate="celebrationReview.titlePage"></h1>

        <div class="note-white">
            <p translate="celebrationReview.notePage"></p>
        </div>

    </div>

    <div class="form-wrapper">
        <div class="form-wrapper">
            <div class="form-row form-page-buttons">
                <div class="form-col left-side"></div>
                <div class="form-col right-side">
                    <button id="finalCelebrationContinue" class="primary" type="submit" translate="button.continue" (click)="onSubmit()"></button>
                </div>
            </div>
        </div>
    </div>

</form>