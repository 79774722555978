import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import * as uuid from 'uuid';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-branch-assisted',
  templateUrl: './branch-assisted.component.html'
})
export class BranchAssistedComponent implements OnInit {

  public branchAssisted;
  public lang: String = 'en';
  public formVar;

  @ViewChild('branchAssistedForm', {static: true}) branchAssistedForm: NgForm;

  constructor(private applicationService: ApplicationService,
    private http: HttpClient,
    private configService: ConfigResolverService<any>) {
    this.branchAssisted = new BranchAssisted();
    this.resetFormObj();
  }

  ngOnInit() {
  }
  branchSetup() {
    // this.applicationService.branchSetupSave(this.branchAssistedForm.value.payLoad);
    
    this.formVar = {
      actionUrl: `/wealth/onboard/api/session/v1/branchSetup?lang=${this.lang}`,
      method: "post",
      disablePayload: false
    }
    this.submitForm();
  }

  submitForm() {
    setTimeout(()=>{
      (<HTMLFormElement>document.getElementById('testForm')).submit();
      this.resetFormObj();
    }, 10);
  }

  setLangEN() {
    this.lang = 'en';
  }


  setLangFR() {
    this.lang = 'fr';
  }

  constructTransientURl(): string {
    let env = location.host.replace(/[^0-9]/g, '');
    env = env.length === 1 && env || '3';
    let url = this.configService.getConfig().transientData || "";
    if (typeof url !== "string") {
      if (location.host.indexOf(".dit")>=0) {
        url = url.dit;
      } else {
        url = url.sit;
      }
    }
    if (url) {
      url = url.replaceAll("{{env}}", env);
    }
    return url;
  }

  transitData() {
    const transientDataUrl = this.constructTransientURl();
    if(transientDataUrl) {
      this.http.post(transientDataUrl,
        {
          cachedData: this.branchAssisted.payLoad,
          ttl: 600
        },
        {
          headers: new HttpHeaders(
            {
              "x-fapi-financial-id": "001",
              "x-request-id": uuid.v4(),
              "x-app-cat-id": "23796",
              "x-api-key": "bdf55c309703f3805dc69d88b5f4e007",
            }
          )
        }).subscribe((data: TransientData)=>{
          if(data?.cacheDetails?.transactionId){
            this.formVar = {
              actionUrl: `/wealth/onboard/api/session/v1/get/${data.cacheDetails.transactionId}`,
              method: "get",
              disablePayload: true
            }
            this.submitForm();
          } else {
            console.log("transient-data API success no transactionID", data);
            this.resetFormObj();
          }
        },(e)=>{
          console.log("transient-data API failed", e);
          this.resetFormObj();
        })
    }
    
  }
  
  resetFormObj() {
    this.formVar = {
      actionUrl: "",
      method: "post",
      disablePayload: false
    };
  }


}
class BranchAssisted {
  public payLoad;
}

export interface TransientData {
  cacheDetails: {
    transactionId: string;
  },
  result:    {
    code: string;
    status: string;
    messages: string[];
 },
}
