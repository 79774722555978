import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../shared/utils/utils.class';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    providers: [TranslateService]
})
export class DashboardComponent {

    constructor(private router: Router, public translate: TranslateService) {
    }

    setLang(lang) {
        const navParamenter: NavigationExtras = {
            'queryParams': {
                'lang': lang
            }
        };
        document.documentElement.lang = lang ? lang : 'en';
        const url = ['/select-account'];
        this.router.navigate(url, navParamenter);
    }
}
