import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Jquery } from '../../../shared/utils/jquery.class';
import * as $ from 'jquery';
@Component({
    selector: 'app-investor-profile-overlay',
    templateUrl: './investor-profile-overlay.component.html'
})
export class InvestorProfileOverlayComponent implements OnInit, AfterViewInit {

    @Input() dialogData: any;
    @Output() close = new EventEmitter();
    constructor() { }

    ngOnInit() {
    }
    ngAfterViewInit(): void {
        Jquery.modalScrollHeight();
        Jquery.modalFocusTrap('bmo-investment-profiles');
    }

    closeBtn() {
        this.close.emit();
        Jquery.modalDissableFocusTrap('InvestorOverlayLinkID');
    }

}
