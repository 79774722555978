import { AnalyticsService } from './../../shared/service/analytics.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { ApplicationService } from '../../shared/service/application.service';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ANALYTICS, LOCATION_INDEX, APP_ROUTES, ACCOUNT_CODES, RIF_PAYMENT_METHOD } from './../../config/app-config.constants';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { NewReferenceDocumentTypes, OldReferenceDocumentTypes } from '../../../assets/data/ReferenceDetails';
import { Jquery } from '../../shared/utils/jquery.class';

export class DocumentUpload {
    documentA = '';
    documentB = '';
}


@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html'
})
export class DocumentUploadComponent implements OnInit, AfterViewInit {

    public documentUpload: DocumentUpload = new DocumentUpload();

    @ViewChild('documentUploadForm', { static: true }) documentUploadForm: NgForm;
    @ViewChild('fileInputGroupA', { static: true }) fileInputGroupA: ElementRef;
    @ViewChild('fileInputGroupB', { static: true }) fileInputGroupB: ElementRef;
    @ViewChild('fileInputGroupC', { static: true }) fileInputGroupC: ElementRef;


    public isReferenceCallFailed: Boolean = false;
    public disableSubmitDocument: Boolean = true;
    public uploadInProgress: Boolean = false;
    public confirmUpload: Boolean = true;
    public uploadInComplete: Boolean = false;
    public uploadComplete: Boolean = false;
    public uploadTries: any = 0;
    public isFormSubmit: boolean = false;
    public url;

    public docListA: any = [];
    public docListB: any = [];
    public docListC: any = [];

    public fileListA: File;
    public fileListB: File;
    public fileListC: File;

    public listA = {
        name: '',
        size: '',
        type: '',
        error: false,
        removed: false,
        success: false
    };

    public listB = {
        name: '',
        size: '',
        type: '',
        error: false,
        removed: false,
        success: false
    };

    public listC = {
        name: '',
        size: '',
        type: '',
        error: false,
        removed: false,
        success: false
    };

    public documentTypes: any;

    public isCurrentLangEnglish: Boolean = false;
    public isCurrentLangFrench: Boolean = false;
    public isEnglishLang: Boolean = false;
    amlEnabled: boolean;
    public isRIF: boolean = false;
    public nextStepsObject: any;
    public showIdentityUpload: boolean = false;
    public showVoidCheqUpload: boolean = false;
    public bmoRelationship: boolean = false;
    public rifPaymentMethod: any;
    public validListA: boolean = false;
    public validListB: boolean = false;
    public validListC: boolean = false;
    public validListAtype: boolean = false;
    public validListBtype: boolean = false;
    public validListCtype: boolean = false;
    public isBranchReferral: boolean = false;
    public isDataRationalization4Enabled = false;
    public isSkipDocUpload = false;


    constructor(private route: ActivatedRoute, public titleService: Title,
        private translate: TranslateService, private winRef: WindowRefService,
        private configService: ConfigResolverService<any>,
        private applicationService: ApplicationService, public analytics: AnalyticsService) {
        this.documentUpload = new DocumentUpload();

        if (this.isCurrentLangEnglish) {
            translate.use('en');
        } else if (this.isCurrentLangFrench) {
            translate.use('fr');
        }

        this.isEnglishLang = this.translate.currentLang === 'en' ? true : false;
        this.url = '/' + APP_ROUTES.document_upload;
    }

    ngOnInit() {

        this.applicationService.fetchReferences(this.route);
        this.nextStepsObject = Utils.getNextStepsData();
        this.applicationService.pageTitles();
        this.titleService.setTitle(Utils.getPageTitleDocumentUpload());
        // AS PART OF R24 AML2.0. Mocking the data at UI layer.MWB-11758
        // this.documentTypes = this.applicationService.appReferences.references.documentTypes;
        this.amlEnabled = this.configService.getConfig().AML_ENABLED === 'true' ? true : false;
        this.isDataRationalization4Enabled = this.applicationService.getDataRationalization4FeatureFlag();
        if (this.amlEnabled) {
            this.documentTypes = (this.isEnglishLang == true) ? NewReferenceDocumentTypes.EN : NewReferenceDocumentTypes.FR;
        }
        else {
            this.documentTypes = (this.isEnglishLang == true) ? OldReferenceDocumentTypes.EN : OldReferenceDocumentTypes.FR;
        }
        this.createGroup();
        if (this.route.snapshot.data) {
            const application = this.nextStepsObject;
            if (application){
                for (let i in application.accounts) {
                    if(application.accounts[i].accountType === ACCOUNT_CODES.RIF) {
                        this.isRIF = true;
                    }
                }
                this.bmoRelationship = application.bmoRelationship['isApplicantExistingClient'];
                if (Utils.hasOwnProperty(application, 'rifPaymentMethod')) {
                    this.rifPaymentMethod = application.rifPaymentMethod;
                } else {
                    this.rifPaymentMethod = null;
                }

                if (Utils.hasOwnProperty(application, 'isBranchReferral')) {
                    this.isBranchReferral = application.isBranchReferral;
                } else {
                    //  console.log('next step -- ', 'isBranchReferral flag is missing so setting is to false');
                    this.isBranchReferral = false; // if isBranchReferral is missing, making it false by default
                }

                if (this.isRIF) {
                    if(this.isBranchReferral){
                        this.showVoidCheqUpload = true;
                        this.showIdentityUpload = false;
                    }else if (this.bmoRelationship && this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT) {
                        this.showVoidCheqUpload = true;
                        this.showIdentityUpload = false;
                    }else if (!this.bmoRelationship && this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT) {
                        this.showVoidCheqUpload = true;
                        this.showIdentityUpload = true;
                    }else{
                        this.showIdentityUpload = !this.bmoRelationship;
                    }
                } else{
                    if(this.isBranchReferral){
                        this.showVoidCheqUpload = false;
                        this.showIdentityUpload = false;
                    }else {
                        this.showVoidCheqUpload = false;
                        this.showIdentityUpload = !this.bmoRelationship;
                    }
                }

            }
        }

        // this.analytics.setState('step 22');


        setTimeout(() => {
            this.setGTM();
        }, 400);


    }
    ngAfterViewInit() {
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('DocumentUpload');
    }

    ngOnDestroy(){
        if ($('main').next().hasClass('bmo-doc-upload')) {
              $('main').next().detach('.bmo-doc-upload');
        }
    }

    onListAfileSelected(event,updMethod) {
        if (updMethod === 'drag') {
            this.listA.name = event[0].name;
            this.listA.size = event[0].size;
            this.listA.type = event[0].type;
            this.fileListA = event[0];
        } else {
            this.listA.name = event.target.files[0].name;
            this.listA.size = event.target.files[0].size;
            this.listA.type = event.target.files[0].type;
            this.fileListA = event.target.files[0];
            event.srcElement.value = null;
        }
        this.enableDisableSubmitDocButton();
        //20MB = 20971520 Bytes
        //10MB = 10485760 BYTES
        //5MB = 5242880 BYTES
        this.listA.error = Number(this.listA.size) > 20971520 || (this.listA.type != 'application/pdf' &&
        this.listA.type != 'image/tiff' && this.listA.type !='image/png' && this.listA.type != 'image/jpeg') ? true : false;
        this.listA.removed = false;
        this.listA.success = !this.listA.error;
    }
    onListBfileSelected(event,updMethod) {
        if (updMethod === 'drag') {
            this.listB.name = event[0].name;
            this.listB.size = event[0].size;
            this.listB.type = event[0].type;
            this.fileListB = event[0];
        } else {
            this.listB.name = event.target.files[0].name;
            this.listB.size = event.target.files[0].size;
            this.listB.type = event.target.files[0].type;
            this.fileListB = event.target.files[0];
            event.srcElement.value = null;
        }
        this.enableDisableSubmitDocButton();
        this.listB.error = Number(this.listB.size) > 20971520 || (this.listB.type != 'application/pdf' &&
        this.listB.type != 'image/tiff' && this.listB.type !='image/png' && this.listB.type != 'image/jpeg') ? true : false;
        this.listB.removed = false;
        this.listB.success = !this.listB.error;
    }
    onListCfileSelected(event,updMethod) {
        if (updMethod === 'drag') {
            this.listC.name = event[0].name;
            this.listC.size = event[0].size;
            this.listC.type = event[0].type;
            this.fileListC = event[0];
        } else {
            this.listC.name = event.target.files[0].name;
            this.listC.size = event.target.files[0].size;
            this.listC.type = event.target.files[0].type;
            this.fileListC = event.target.files[0];
            event.srcElement.value = null;
        }
        this.enableDisableSubmitDocButton();
        this.listC.error = Number(this.listC.size) > 20971520 || (this.listC.type != 'application/pdf' &&
        this.listC.type != 'image/tiff' && this.listC.type !='image/png' && this.listC.type != 'image/jpeg') ? true : false;
        this.listC.removed = false;
        this.listC.success = !this.listC.error;
    }

    createGroup() {
        for (let types in this.documentTypes) {
            if (this.documentTypes[types].group === 'A') {
                this.docListA.push(this.documentTypes[types])
            } else if (this.documentTypes[types].group === 'B') {
                this.docListB.push(this.documentTypes[types])
            }
        }


    }

    fetchFileName(value: string, type: string) {

    }

    removeFile(type: string) {
        if (type == 'A') {
            this.listA.name = '';
            this.listA.error = false;
            this.listA.removed = true;
            this.listA.success = false;
        } else if (type == 'B'){
            this.listB.name = '';
            this.listB.error = false;
            this.listB.removed = true;
            this.listB.success = false;
        } else if (type == 'C'){
            this.listC.name = '';
            this.listC.error = false;
            this.listC.removed = true;
            this.listC.success = false;
        }
        this.enableDisableSubmitDocButton();
    }

    enableDisableSubmitDocButton() {

        this.validListA = this.listA.name != '' &&
            (this.listA.type === 'application/pdf' || this.listA.type === 'image/tiff' || this.listA.type === 'image/png'
                || this.listA.type === 'image/jpeg') && Number(this.listA.size) < 20971520;
        this.validListB = this.listB.name != '' &&
            (this.listB.type === 'application/pdf' || this.listB.type === 'image/tiff' || this.listB.type === 'image/png'
                || this.listB.type === 'image/jpeg') && Number(this.listB.size) < 20971520;
        this.validListC = this.listC.name != '' &&
                (this.listC.type === 'application/pdf' || this.listC.type === 'image/tiff' || this.listC.type === 'image/png'
                    || this.listC.type === 'image/jpeg') && Number(this.listC.size) < 20971520;

        if (this.showIdentityUpload && this.showVoidCheqUpload) {
            this.disableSubmitDocument = !(this.validListA && this.validListB && this.validListC);
        } else if (this.showVoidCheqUpload) {
            this.disableSubmitDocument = !(this.validListC);
        } else {
            this.disableSubmitDocument = !(this.validListA && this.validListB);
        }

    }

    onCancel() {
        $('.modal-overlay').hide();
        $('.bmo-doc-upload').hide();
        Jquery.modalDissableFocusTrap();
        this.applicationService.continue('next-steps');
    }

    validDocCheck() {
        let isValid = false;

        this.validListAtype = this.listA.name != '' && (this.listA.type === 'application/pdf' || this.listA.type === 'image/tiff' ||
        this.listA.type ==='image/png' || this.listA.type === 'image/jpeg');

        this.validListBtype = this.listB.name != '' && (this.listB.type === 'application/pdf' || this.listB.type === 'image/tiff' ||
        this.listB.type ==='image/png' || this.listB.type === 'image/jpeg');

        this.validListCtype = this.listC.name != '' && (this.listC.type === 'application/pdf' || this.listC.type === 'image/tiff' ||
        this.listC.type ==='image/png' || this.listC.type === 'image/jpeg');
        if (this.showIdentityUpload) {
            if (this.validListAtype) {
                this.listA.error = Number(this.listA.size) > 20971520;
                if (this.validListBtype) {
                    this.listB.error = Number(this.listB.size) > 20971520;
                    if (this.showVoidCheqUpload) {
                        if (this.validListCtype) {
                            this.listC.error = Number(this.listC.size) > 20971520;
                            isValid = true;
                        }
                    } else {
                        isValid = true;
                    }
                }
            }

        } else if (this.showVoidCheqUpload) {
            if (this.validListCtype) {
                this.listC.error = Number(this.listC.size) > 20971520;
                isValid = true;
            }
        }


        this.enableDisableSubmitDocButton();
        return isValid;
    }

    skipDocumentUpload(){
        Utils.setSkipDocUpload(true);
        $('.modal-overlay').show();
        $('.bmo-skip-upload-login').show();
        Jquery.modalFocusTrap('bmo-skip-upload-login');
    }

    submitDocuments() {
        Utils.setSkipDocUpload(false);
        this.isFormSubmit = true;
        if (this.validDocCheck()) {
            $('.modal-overlay').show();
            $('.bmo-doc-upload').show();
            $('.bmo-doc-upload').insertAfter($('main'));
            Jquery.modalFocusTrap('bmo-doc-upload');
        }
    }

    cancelSubmitDocument() {
        $('.modal-overlay').hide();
        $('.bmo-doc-upload').hide();
        Jquery.modalDissableFocusTrap("submitDocument");

        if (this.uploadComplete) {
            this.onCancel();
        }
    }

    convertToBinaryAndPost(file: File, uploadListType: string) {
        this.isReferenceCallFailed = false;
        // if (this.uploadTries < 3) {

            const formData: FormData = new FormData();
            formData.append('file', file);

            this.applicationService.uploadDoc(formData, 'List' + uploadListType,file.type)
                .then((res: any) => {

                    //     const sampleRes = `" {
                    //     'error': {
                    //         'code': 'UPLOAD403',
                    //             'debug': {
                    //             'message': 'some message',
                    //                 'uri': 'someuri'
                    //         },
                    //         'displayCode': 'UPLOAD403'
                    //     }
                    // }" `;

                    // response from bluecoat is in string format so idenfying if there is issue using indexof
                    const errorres = res.indexOf('UPLOAD403');

                    if (errorres !== -1) {

                        this.uploadTries++;
                        this.uploadInProgress = false;
                        //this.confirmUpload = true;
                        this.fileInputGroupA.nativeElement.value = "";
                        this.fileInputGroupB.nativeElement.value = "";
                        this.fileInputGroupC.nativeElement.value = "";
                        this.listA = { name: '', size: '', type: '', error: false, removed: false, success: false };
                        this.listB = { name: '', size: '', type: '', error: false, removed: false, success: false };
                        this.listC = { name: '', size: '', type: '', error: false, removed: false, success: false};
                        this.isReferenceCallFailed = true;
                        this.disableSubmitDocument = true;
                        // resetting dropdown values
                        // this.documentUpload.documentA = '';
                        // this.documentUpload.documentB = '';
                        this.cancelSubmitDocument();
                        // sending to next steps screen after 3rd failure attempt
                        // if (this.uploadTries > 2) {
                            Utils.setIsDocUploadSuccess(false);
                            this.applicationService.setDocumentUploadStatus(false);
                            Utils.setIsDocUploadVisited(true);
                            this.onCancel();
                        // }
                        return;
                    }

                    // if first file upload is virus free continue with second file
                    if (uploadListType === 'A') {
                        this.startUpload("B");
                    }

                    if (uploadListType === 'B') {
                        //$('.modal-overlay').show();
                        if (this.showVoidCheqUpload) {
                            this.startUpload("C");
                        } else {
                            this.uploadInProgress = false;
                            this.uploadComplete = true;
                            setTimeout(() => {
                                Jquery.modalFocusTrap('bmo-doc-upload');
                            }, 2000);
                            Utils.setIsDocUploadSuccess(true);
                            this.applicationService.setDocumentUploadStatus(true);
                            Utils.setIsDocUploadVisited(true);
                        }
                    }

                    if (this.showVoidCheqUpload){
                        if (uploadListType === 'C') {
                            //$('.modal-overlay').show();
                            this.uploadInProgress = false;
                            this.uploadComplete = true;
                            setTimeout(() => {
                                Jquery.modalFocusTrap('bmo-doc-upload');
                            }, 2000);
                            Utils.setIsDocUploadSuccess(true);
                            this.applicationService.setDocumentUploadStatus(true);
                            Utils.setIsDocUploadVisited(true);
                        }
                    }

                })
                .catch(err => {
                    this.uploadTries++;
                    this.uploadInProgress = false;
                    //this.confirmUpload = true;
                    if (this.fileInputGroupA?.nativeElement) {
                        this.fileInputGroupA.nativeElement.value = "";
                    }
                    if (this.fileInputGroupB?.nativeElement) {
                        this.fileInputGroupB.nativeElement.value = "";
                    }
                    if (this.fileInputGroupC?.nativeElement) {
                        this.fileInputGroupC.nativeElement.value = "";
                    }

                    this.isReferenceCallFailed = true;
                    this.disableSubmitDocument = true;
                    // resetting dropdown values
                    // this.documentUpload.documentA = '';
                    // this.documentUpload.documentB = '';
                    //this.cancelSubmitDocument();
                    // sending to next steps screen after 3rd failure attempt
                    // if (this.uploadTries > 2) {
                        this.listA = { name: '', size: '', type: '', error: false, removed: false, success: false };
                        this.listB = { name: '', size: '', type: '', error: false, removed: false, success: false };
                        this.listC = { name: '', size: '', type: '', error: false, removed: false, success: false };
                        Utils.setIsDocUploadSuccess(false);
                        this.applicationService.setDocumentUploadStatus(false);
                        Utils.setIsDocUploadVisited(true);
                        $('.modal-overlay').hide();
                        $('.bmo-doc-upload').hide();
                        Jquery.modalDissableFocusTrap();
                        if (this.uploadComplete) {
                            this.onCancel();
                        }
                    // }
                });
        // }
    }

    startUploadDocs(){

        if (this.showIdentityUpload) {
            this.startUpload('A'); //it will take care A,B,C
        } else if (this.showVoidCheqUpload) {
            this.startUpload('C'); //it will take care only C
        }

    }

    startUpload(uploadListType: string) {
        if (this.validDocCheck()) {
            this.uploadInProgress = true;
            Jquery.modalFocusTrap('bmo-doc-upload');
            this.confirmUpload = false;
            if (uploadListType === 'A') {
                this.convertToBinaryAndPost(this.fileListA, uploadListType)
            } else if (uploadListType === 'B') {
                this.convertToBinaryAndPost(this.fileListB, uploadListType)
            } else if (uploadListType === 'C') {
                this.convertToBinaryAndPost(this.fileListC, uploadListType)
            }

        } else {
            this.cancelSubmitDocument();
        }
    }


    setGTM() {

        const gtmDataLayerForDocUpload: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleCompletion(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.actionComplete,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.document_upload,
            'applicationId': Utils.applicationID,
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.product,
            'netWorth': ''
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForDocUpload);
        // console.log(this.winRef.nativeWindow.dataLayer);

    }

}
