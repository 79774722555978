<div *ngIf="redirection">
    <h1>Redirecting to TYSK page</h1>
</div>
<form #journeyForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="bmo-wrapper" *ngIf="!redirection">

        <div style="width: 350px;margin: auto;">
            <h1>JOURNEY LOGIN</h1>
            <div class="flex-30 form-col">
                <div class="form-field">
                    <div class="form-label-group">
                        <input [(ngModel)]="JourneyECIFId" #JourneyEecifIdCtrl="ngModel" name="ecifId" type="text" required>
                        <label class="slide-label">
                            <span class="label-text">
                                ECIF ID
                            </span>
                        </label>
                    </div>

                </div>
                <div role="alert" id="ecifId-error" class="error-message"
                    *ngIf="(JourneyEecifIdCtrl?.errors && JourneyEecifIdCtrl?.touched) || (isFormSubmit && JourneyEecifIdCtrl.errors)  ">
                    <span>Please enter valid ECIF ID</span>
                </div>
                <br>


                <div class="form-field">
                    <div class="form-label-group">
                        <input [(ngModel)]="loginId" #loginIdCtrl="ngModel"  name="loginID"
                            type="text" required>
                        <label class="slide-label">
                            <span class="label-text">
                                LOGIN ID
                            </span>
                        </label>
                    </div>
                </div>

                <div role="alert" id="ecifId-error" class="error-message"
                    *ngIf="(loginIdCtrl?.errors && loginIdCtrl?.touched) || (isFormSubmit && loginIdCtrl.errors) ">
                    <span>Please enter your Login Id</span>
                </div>

           
            </div>
            <div class="form-col right-side flex-xaxis-center">
                <button type="submit" class="primary">Create new Account</button>
                <button (click)="onCancel()" type="button" class="tertiary">Cancel</button>

            </div>
        </div>
    </div>
</form>
