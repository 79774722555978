import { Injectable } from '@angular/core';

@Injectable()
export class ProgressBarService {

  private progressInfo: any;

  constructor() { }

  public setProgressInfo(progressInfo) {
    this.progressInfo = progressInfo;
  }

  public getProgressInfo() {
    return this.progressInfo;
  }

}
