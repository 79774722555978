import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DocumentTrackerService } from '../../shared/service/document-tracker.service';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { Utils } from '../../shared/utils/utils.class';
import { ApplicationService } from '../../shared/service/application.service';
import { LANGUAGES_TYPE , APP_ROUTES , ANALYTICS} from '../../config/app-config.constants';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';

@Component({
  selector: 'app-branch-esignature',
  templateUrl: './branch-esignature.component.html'
})
export class BranchEsignatureComponent implements OnInit , AfterViewInit {

  public documents;
  public packageID;
  public lang;
  public url;

  constructor(private documentTrackerService: DocumentTrackerService,
    private applicationService: ApplicationService,
    private analytics: AnalyticsService,
    private winRef: WindowRefService, public translateService: TranslateService) { }

  ngOnInit() {
    this.analytics.setEsign();
    this.packageID = this.documentTrackerService.documentPackage.id;
    this.documents = this.documentTrackerService.documentPackage.documents;
    this.lang = Utils.getLanguage();
    this.url = '/' + APP_ROUTES.branch_esignature;
    setTimeout(() => {
      this.setGTM();
  }, 200);
  }
  ngAfterViewInit() {
    this.analytics.setPageState(this.url);
  }

  downloadDocument(document) {
  this.documentTrackerService.downloadDocument(this.packageID, document.id).toPromise()
  .then(response => {
    this.documentTrackerService.openDownloadedDocument(response);
  })
  .catch(e => {
    console.log(e);
  });

  }

  getDocumentLabel(document) {
    return (this.lang === LANGUAGES_TYPE.ENGLISH) ? document.labels[0].value : document.labels[1].value;
  }

  onsubmit() {
    this.applicationService.saveAndcontinue(null, APP_ROUTES.next_steps);

  }
  setGTM()  {

    const gtmData: GTMDataLayer = {
        'event': ANALYTICS.BMOEVENT,
        'vURL': Utils.getvURL(),
        'vPageTitles': Utils.getpageTitleEsignLanding(),
        'category': ANALYTICS.category,
        'action': ANALYTICS.ACTIONS.BRANCH_ESIGN,
        'label': ANALYTICS.label,
        'flow': ANALYTICS.flow,
        'prodName': Utils.accountName,
        'prodNumber': Utils.accountNumber,
        'prodTotalNumber': 1,
        'originalLocation': document.location.protocol + '//' + document.location.hostname +
            document.location.pathname + document.location.search,
        'language': Utils.getLanguage(),
        's_PPvid': ANALYTICS.branch_esign,
        'applicationId': '',
        'signerId': '',
        'packageId': '',
        'assisetdApp': false,
        'customerSource': Utils.getSubProduct(),
        'netWorth': Utils.netWorth
    };

    this.winRef.nativeWindow.dataLayer.push(gtmData);
   // console.log('branch_esign', this.winRef.nativeWindow.dataLayer);

}
}
