import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, fromEvent, from, interval, timer, of, merge } from 'rxjs';
import { bufferTime, distinctUntilChanged, filter, finalize, switchMap, takeUntil, tap, take, map, scan } from 'rxjs/operators';

@Injectable()
export class QualtricsDelayMonitorService {
  public activityEvents$: Observable<any>;
  public timerStart$ = new Subject<boolean>();
  public timeout$ = new Subject<boolean>();
  public idle$: Observable<any>;
  public timer$: Observable<any>;
  public idle: number = 300;
  public timeout: number = 20;
  public isInactivityTimer: boolean;
  public idleSubscription: Subscription;

  constructor() {
    this.activityEvents$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click'),
      fromEvent(document, 'touchstart'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'keyup'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'focus'),
      fromEvent(document, 'mousewheel')

    );

    this.idle$ = from(this.activityEvents$);
  }

  /**
 * Start watching for user idle.
 */
  startWatching() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    // If any of user events is not active for idle-seconds when start timer.
    this.idleSubscription = this.idle$
      .pipe(
        bufferTime(1000), // Starting point of detecting of user's inactivity
        filter(arr => !arr.length && !this.isInactivityTimer),
        tap(() => this.isInactivityTimer = true),
        switchMap(() => interval(1000).pipe(
          takeUntil(
            merge(
              this.activityEvents$,
              timer(this.idle * 1000).pipe(
                tap(() => this.timerStart$.next(true))
              )
            )
          ),
          finalize(() => (this.isInactivityTimer = false))
        )
        )
      )
      .subscribe();
      this.setupTimer(this.timeout);
  }

  public setupTimer(timeout: number) {
    this.timer$ = interval(1000).pipe(
      take(timeout),
      map(() => 1),
      scan((acc, n) => acc + n),
      tap(count => {
        if (count === timeout) {
          this.timeout$.next(true);
        }
      })
    );
  }

  stopWatching() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  onTimerStart(): Observable<number> {
    return this.timerStart$.pipe(
      distinctUntilChanged(),
      switchMap(start => (start ? this.timer$ : of(null)))
    );
  }

  getIdleValue(): any {
    return this.idle;
  }

  /**
 * Set config values.
 * @param config
 */
  setIdleValues(idle: number) {
    if (this.idleSubscription && !this.idleSubscription.closed) {
      //console.error('Call stopWatching() before set config values');
      return;
    }

    if (idle) {
      this.idle = idle;
    }

  }

}
