<div class="form-field" [ngModelGroup]="group">
        <div class="form-label-group">
            <input [id]="id"
                   data-ana="mywealth:onboarding:contact:email"
                   class="no-text-transform"
                   type="email"
                   name="emailAddress"
                   #emailAddressCtrl="ngModel"
                   [(ngModel)]="model[key]"
                   maxlength="40"
                   appEmailFormat
                   pattern="^(?!.*\.{2})[a-zA-Z0-9._-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,3}$"
                   required />
            <label [ngClass]="{'error-field': emailAddressCtrl.errors && emailAddressCtrl.touched  || (isFormSubmit && emailAddressCtrl.errors), 'active-label': emailAddressCtrl.value}"
                   class="slide-label"
                   for="emailAddress">
                <span class="label-text" translate="contactInformation.labels.emailAddress"></span>
            </label>
        </div>
    </div>
    <div role="alert" class="error-message" *ngIf="emailAddressCtrl.errors && emailAddressCtrl.touched || (isFormSubmit && emailAddressCtrl.errors)">
        <span translate="contactInformation.errors.invalidEmail"></span>
    </div>

