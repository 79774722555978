import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { PERCENTAGE } from '../../config/app-config.constants';

@Directive({
  selector: '[appFormatInputPercentage]'
})
export class FormatInputPercentageDirective implements OnInit {

  constructor(private _el: ElementRef) { }

  ngOnInit() {
    Promise.resolve(null).then(() => {
      this.onblur();
    });
  }

  @HostListener('focus', ['$event']) onfocus(event) {
    this._el.nativeElement.value = this._el.nativeElement.value.replace(PERCENTAGE.SIGN, '');
  }

  @HostListener('blur', ['$event'])
  onblur(event?: KeyboardEvent) {

    if (this._el.nativeElement.value && this._el.nativeElement.value.indexOf(PERCENTAGE.SIGN) == -1) {

      if (this._el.nativeElement.value <= 100) {
        this._el.nativeElement.value = parseInt(this._el.nativeElement.value, 10) + PERCENTAGE.SIGN;
      } else {
        this._el.nativeElement.value = 0 + PERCENTAGE.SIGN;
      }

    }

  }

  @HostListener('paste', ['$event'])
  blockPaste(event: KeyboardEvent) {
    event.preventDefault();
  }

}
