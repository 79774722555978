<div class="flex-equal-fixed">
    <a role="button"
       attr.aria-label="{{ 'ariaLabels.datePickerClose' | translate }}"
       attr.tooltip="{{ 'linkButton.close' | translate }}"
       class="btn-icon icon-large tooltip-show"
       href="javascript://"
       (click)="toggleCalendar()">
        <img src="assets/img/close-grey.svg" alt="" />
    </a>
</div>
