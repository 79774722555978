
    <div role="group" [attr.aria-labelledby]="section" [id]="accessErrorId['parentId']" class="form-row">

        <div *ngFor="let item of options; let i = index" [ngModelGroup]="name"
            checkboxValidator
             class="form-radio-checks selection-card square-card"
             [ngClass]="{'error-field': (isError && isFormSubmitted) }">

            <input type="checkbox"
                   id="option{{name}}{{item.code}}"
                   name="option{{name}}{{item.code}}"
                   [attr.data-ana]="dataAna"
                   [(ngModel)]="selectedOptionsObject[item.code]"
                   (change)="onToggle($event)"
                   [appErrorAccess]='accessErrorId'
                   [required]="isRequired"
                   [value]="item.code" />

            <label ngDefaultControl class="small-card" for="option{{name}}{{item.code}}">
                <span class="check"></span>
                <span class="image">
                    <img src="{{imageSource}}{{item.code}}.svg" alt="" />
                </span>
                <span class="description" translate="{{item.label}}"></span>
            </label>

        </div>

        <div role="alert" [id] ="accessErrorId['id']" *ngIf="(isError && isFormSubmitted)" class="error-message">
            <span [translate]="errorMessage"></span>
        </div>

    </div>
