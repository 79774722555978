/*
  Author: Aravind Anantharaman, May 2018
  Use this class to format the validate Input fields and Block Paste Events

  Usage Example : InputValidator="ct1 ct5"

CT1	English Alphabetic	 a-z, A-Z
CT2	French Alphabetic	àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’‘œ«»
CT3	Numeric only	1, 2, 3, 4 etc
CT4	English Alphanumeric	 a-z, A-Z,  1, 2, 3 , 4 etc
CT5	Basic Special Characters  	Space () , hyphen (-), apostrophe(')
CT6	Period 	Period (.)
CT7	Additional Special Characters  	Ampersand (&), comma (,), underscore (_)
CT8	email	@
CT9 appFrEnAlphaNumericSpecialChar
CT10 NumericWithSinglespace
CT11 AlphanumericPeriodWithSingleSpaceDirective
CT12 AlphanumericWithSinglespace
CT15 AlphanumericPeriodWithSpecialCharacterDirective'
CT16 AlphabetsWithSingleSpaceDirective
CT17 AlphaNumericWithSingleSpaceSpecialCharDirective
CT18 LettersOnlyDirective
*/

import { Directive, ElementRef, HostListener, Input, PipeTransform } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormatPhoneNumberPipe } from '../pipes/format-phone-number.pipe';
import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { ZipCodePipe } from '../pipes/zip-code.pipe';
import { SinNumberPipe } from '../pipes/sin-number.pipe';
import { SocialSecurityNumberPipe } from '../pipes/social-security-number.pipe';
import { Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[InputValidator]'
})
export class InputValidatorDirective {

  private regex: RegExp;
  private pattern: String = '';
  private blockPaste: Boolean = false;
  private pipe: PipeTransform;
  private lengthCheck: Boolean = false;
  private lengthCheckForBeneficiary: Boolean = false;
  private nativeEl: HTMLInputElement;

  constructor(private el: NgControl,
    nativeEl: ElementRef,
    private renderer: Renderer2) {

    this.nativeEl = <HTMLInputElement>nativeEl.nativeElement;
  }

  @Input('blockPaste') set BlockPaste(doBlock: boolean) {
    this.blockPaste = doBlock || false;
  }

  @Input('formatPipe') set SetPipe(pipeName: string) {

    // TODO: add missing pipes, if any
    switch (pipeName) {
      case 'phone':
        this.pipe = new FormatPhoneNumberPipe();
        break;
      case 'Capitalize':
        this.pipe = new CapitalizePipe();
        break;
      case 'zip':
        this.pipe = new ZipCodePipe();
        break;
      case 'sin':
        this.pipe = new SinNumberPipe();
        break;
      case 'Ssn':
        this.pipe = new SocialSecurityNumberPipe();
        break;
      default:
        this.pipe = null;
    }
  }


  @Input() set InputValidator(validationType: any) {


    if (validationType.indexOf('ct1') !== -1) {  // If CT1 in input Add Alphabets to the Regular Expression
      this.pattern += 'a-zA-Z';
    }
    if (validationType.indexOf('ct2') !== -1) {  // If CT2 in input Add French Text to the Regular Expression
      // tslint:disable-next-line:quotemark
      this.pattern += "àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’‘œ«»";
    }
    if (validationType.indexOf('ct3') !== -1) { // If CT3 in input Add Numeric  to the Regular Expression
      this.pattern += '0-9';
    }
    if (validationType.indexOf('ct4') !== -1) {// If CT4 in input Add AlphaNumeric  to the Regular Expression
      this.pattern += 'a-zA-Z0-9';
    }
    if (validationType.indexOf('ct5') !== -1) {// If CT5 in input Add space() ' and -  to the Regular Expression
      // tslint:disable-next-line:quotemark
      this.pattern += "\\-\\'\ ";
    }
    if (validationType.indexOf('ct6') !== -1) {// If CT6 in input Add period (.)  to the Regular Expression
      this.pattern += '\.';
    }

    if (validationType.indexOf('ct7') !== -1) {// If CT7 in input Add 	Ampersand (&), comma (,), underscore (_)  to the Regular Expression
      this.pattern += '\&\_\,';
    }
    if (validationType.indexOf('ct8') !== -1) {// If CT8 in input Add @ to the Regular Expression
      this.pattern += '\@\ ';
    }
    // 'AlphanumericWithSpecialCharacter'
    if (validationType.indexOf('ct9') !== -1) {
      this.pattern += 'a-zA-Z0-9\'’‘œ«»àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ.-';
    }
    // 'numericWithSinglespace'
    if (validationType.indexOf('ct10') !== -1) {
      this.pattern += '0-9 ';
    }
    // 'AlphanumericPeriodWithSingleSpaceDirective'
    if (validationType.indexOf('ct11') !== -1) {
      this.pattern += 'A-Za-z0-9-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ.\'’‘œ«» -';
    }
    // 'alphanumericWithSinglespace'
    if (validationType.indexOf('ct12') !== -1) {
      this.pattern += 'a-zA-Z0-9 ';
    }
    if (validationType.indexOf('ct13') !== -1) {
      this.pattern += "A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ0-9\-.'’‘œ«» ";
    }
    if (validationType.indexOf('ct14') !== -1) {
      this.pattern += "a-zA-ZàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ0-9-.'’‘œ«» ";
    }
    // 'AlphanumericPeriodWithSpecialCharacterDirective'
    if (validationType.indexOf('ct15') !== -1) {
      this.pattern += "a-zA-Z0-9'’‘œ«»àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ ,.&_-";
    }
    // 'AlphabetsWithSingleSpaceDirective'
    if (validationType.indexOf('ct16') !== -1) {
      this.pattern += "A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’ -";
    }
    // 'AlphaNumericWithSingleSpaceSpecialChar'
    if (validationType.indexOf('ct17') !== -1) {
      this.pattern += "a-zA-Z0-9'’‘œ«»àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ ,.&_-";
    }
    // 'LettersOnlyDirective'
    if (validationType.indexOf('ct18') !== -1) {
      this.pattern += "A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’";
    }
    // only called for first and last name fields on Personal Info Page.
    if (validationType.indexOf('ct19') !== -1) {
      this.pattern += "A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’ -";
      this.lengthCheck = true;
    }
    // for email format
    if (validationType.indexOf('ct20') !== -1) {
      this.pattern = "a-zA-Z0-9@._-";
    }
    // French Alphabetic, English Alphanumeric, Basic special chars, period (Branch referral)
    if (validationType.indexOf('ct21') !== -1) {
      this.pattern = "a-zA-Z0-9àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇœ«»œ«»àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ";
    }
    // name fields on Branch referral Info Page.
    if (validationType.indexOf('ct22') !== -1) {
      this.pattern += "A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’ -";
    }
    // only called for first and last name fields on Beneficiary Page.
    if (validationType.indexOf('ct23') !== -1) {
      this.pattern += "A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’ -";
      this.lengthCheckForBeneficiary = true;
    }
    // for decimal
    if (validationType.indexOf('ct24') !== -1) {
      this.pattern += "0-9.";
    }
    if (validationType.indexOf('frenchAlphaNumneric') !== -1) {
     this.pattern += "a-zA-Z0-9'àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ’«»‘œ, ";
    }

    this.regex = new RegExp('^[' + this.pattern + ']*$');
  }


  // As the keyboard blocking cannot be handled via keypress or keydown, as these events return incorrect
  // key in the devices, BIL adopts a method of stripping the invalid characters in the input event.
  @HostListener('input', ['$event'])
  keyEvent(event: Event): void {

    const value = <string>this.el.value;
    let newVal: String = '';
    let containsBlockedChar: Boolean = false;

    if (value) {
      value.split('').forEach(char => {
        if (this.regex.test(char)) {
          newVal += char;
        } else {

          // This currently not used, but requied to handle possible errors
          containsBlockedChar = true;
        }
      });

      // replace double space with single space
      newVal = newVal.replace(/\s{2,}/g, ' ');

      // do applicable formatting, if required
      newVal = newVal &&
        this.pipe &&
        this.pipe.transform(newVal) ||
        newVal;

      if (value !== newVal) {
        this.el.control.setValue(newVal);

        // This is requied, since validation will not be triggered after this event,
        // we have to trigger validation for the field
        this.el.control.updateValueAndValidity();

        // Device keyboard loose track of the cursor, when the masking happens. Maksing adds
        // extra characters to the actual input, which causes this issue.
        const newCursorPosition = newVal.length + 1;

        // Setting the cursor position should be done with a mild delay,
        // as we need to give time to DOM to reflect the new value set.
        setTimeout(() => {
          this.nativeEl.selectionStart = newCursorPosition;
          this.nativeEl.selectionEnd = newCursorPosition;
        }, 100);
      }
    }
  }

  @HostListener("blur", ['$event'])
  onBlur() {
    const value = this.el.value;
    let newVal: String = '';
    let containsBlockedChar: Boolean = false;

    //  this.el.control.setValue(newVal,{ emitEvent: false });
    if (value) {
      value.split('').forEach(char => {
        if (this.regex.test(char)) {
          newVal += char;
        } else {

          // This currently not used, but requied to handle possible errors
          containsBlockedChar = true;
        }
      });

      // replace double space with single space
      newVal = newVal.replace(/\s{2,}/g, ' ');

      // do applicable formatting, if required
      newVal = newVal &&
        this.pipe &&
        this.pipe.transform(newVal) ||
        newVal;

      if (newVal !== value) {
        this.el.control.setValue(newVal);

        // This is requied, since validation will not be triggered after this event,
        // we have to trigger validation for the field
        this.el.control.updateValueAndValidity();
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: KeyboardEvent) {

    if (this.blockPaste) {
      event.preventDefault();
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseRightClick(event: MouseEvent) {

    if (this.blockPaste &&
      (event.button === 2)) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    // when ct19 is applied - For personal info page
    if (this.lengthCheck === true) {
      this.regex = new RegExp(/^[A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’ -]*$/);

      // added french regex to dynamically set maxlength to 14 for firstname and lastname fields
      // as per Bug-3470
      const regexFr = new RegExp(/^[àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ]*$/);

      // added english regex to dynamically set maxlength to 30 for firstname and lastname fields.
      const regexEn = new RegExp(/^[A-Za-z'’ -]*$/);

      // if french character is given as input, maxlength will be set to 20
      if (regexFr.test(event.key)) {
        this.renderer.setAttribute(this.nativeEl, 'maxlength', '14');
      } else if (regexEn.test(event.key)) {
        // if no french character is given as input within first 20 characters, maxlength will be set to 30
        if (regexEn.test(this.nativeEl.value)) {
          this.renderer.setAttribute(this.nativeEl, 'maxlength', '30');
        }
      } else {
        this.renderer.setAttribute(this.nativeEl, 'maxlength', '14');
      }

      if (!this.regex.test(event.key) || (this.nativeEl.value.length === 0 && event.key === ' ')) {
        event.preventDefault();
        return;
      }
    }

    // when ct23 is applied - For Beneficiary info page
    if (this.lengthCheckForBeneficiary === true) {
      this.regex = new RegExp(/^[A-Za-zàâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’ -]*$/);

      // added french regex to dynamically set maxlength to 14 for firstname and lastname fields
      // as per Bug-3470
      const regexFr = new RegExp(/^[àâäçéèêëîïôöûùüÿÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ]*$/);

      // added english regex to dynamically set maxlength to 30 for firstname and lastname fields.
      const regexEn = new RegExp(/^[A-Za-z'’ -]*$/);

      // if french character is given as input, maxlength will be set to 20
      if (regexFr.test(event.key)) {
        this.renderer.setAttribute(this.nativeEl, 'maxlength', '14');
      } else if (regexEn.test(event.key)) {
        // if no french character is given as input within first 20 characters, maxlength will be set to 20
        if (regexEn.test(this.nativeEl.value)) {
          this.renderer.setAttribute(this.nativeEl, 'maxlength', '20');
        }
      } else {
        this.renderer.setAttribute(this.nativeEl, 'maxlength', '14');
      }

      if (!this.regex.test(event.key) || (this.nativeEl.value.length === 0 && event.key === ' ')) {
        event.preventDefault();
        return;
      }
    }

  }

}


