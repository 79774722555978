import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ApplicationService } from '../service/application.service';

@Pipe({
    name: 'dateFormat'
})

// Data arriving must come in as 'YYYY-MM-DD'. Result is in 'MMM-DD-YYY' where an abbreviated month is shown
export class DatePipe implements PipeTransform {

    constructor(public applicationService: ApplicationService) { }
  
    transform(value: any, args?: any): any {
        if (!value || value.length !== 10) {
            return;
        }
        const year = value.split('-')[0];
        const month = value.split('-')[1];
        const day = value.split('-')[2];
        if (!this.applicationService.isCurrentLangEnglish()) {
            value = (month !== 'MM' ? moment(month, 'MM').format('MMM').toUpperCase() : 'mm') + '  |  ' + day + '  |  ' + year;
        }
        else {
            value = (month !== 'MM' ? moment(month, 'MM').format('MMM').toUpperCase() : 'MM') + '  |  ' + day + '  |  ' + year;
        }
        return value;
    }

}