import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class GraphComponent implements OnInit {

  @Input() totalValue: number;
  @Input() form: NgForm;
  @Input() labelContent : any;

  public graph = 'graph';

  constructor() { }

  ngOnInit() {
  }


}
