<!-- <app-flood-light></app-flood-light> -->

<form #investorProfileForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">
        <section id="investorProfileMainSection">
            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="investorProfileForm.invalid && isFormSubmit"
                translateTag="investorProfile"></app-invalid-form>

            <!-- <Test Data for Investor Profile> -->

            <section id="investorProfileSection">
                <h1 id="investorProfileTitleDescribe" translate="investorProfile.titleInvestorProfile"></h1>
                <p id="investorProfileDescribe" translate="investorProfile.descriptionInvestorProfile"></p>
                <div class="form-wrapper">

                    <div role="radiogroup" id='parent-investorProfileDescribe-error'
                        aria-labelledby="investorProfileTitleDescribe investorProfileDescribe" class="form-row">
                        <div class="flex-elements form-col">

                            <div class="flex-50 form-radio-checks selection-card flow-card investorProfile"
                                *ngFor="let item of investorProfileArray; let i = index">
                                <input id="{{name}}{{item.code}}" data-ana="mywealth:onboarding:risk:investor profile"
                                    [name]="investorProfile" [value]="item.code" type="radio"
                                    [(ngModel)]="investorProfile.objective" #investorProfileCtrl="ngModel"
                                    [appErrorAccess]="{ id: 'investorProfileDescribe-error', parentId: 'parent-investorProfileDescribe-error' ,submitted: isFormSubmit}"
                                    required />
                                <label class="large-card" for="{{name}}{{item.code}}"
                                    [ngClass]="{'error-field': investorProfileCtrl.errors && investorProfileCtrl.touched
                                    || (isFormSubmit && investorProfileCtrl.errors), 'active-label': investorProfileCtrl.objective}">
                                    <span class="check"></span>
                                    <span class="title no-margin-title" translate="{{item.label}}"></span>
                                    <span class="bmo-container-flex flex-flow-nowrap two-col">
                                        <span class="side-img-xsmall">
                                            <img src="assets/img/investor-profile/balanced.svg" alt="" />
                                        </span>
                                        <span class="side-copy side-graph-details">
                                            <span class="graph-detail">
                                                <span class="graph-color accessible-blue"></span>
                                                <span class="graph-copy">
                                                    <span class="font-medium accessible-blue"
                                                        translate="investorProfile.labels.graphDescription01"></span>&nbsp;
                                                    <span translate="{{item.fixInc}}"></span>
                                                </span>
                                            </span>
                                            <span class="graph-detail">
                                                <span class="graph-color turquoise-light"></span>
                                                <span class="graph-copy">
                                                    <span class="font-medium turquoise-light"
                                                        translate="investorProfile.labels.graphDescription02"></span>&nbsp;
                                                    <span translate="{{item.equiValues}}"></span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                    <span class="description" translate="{{item.description}}"></span>
                                </label>
                            </div>

                            <div role="alert" class="error-message"  id="investorProfileDescribe-error"
                                *ngIf="isFormSubmit && investorProfile.objective==null">
                                <span translate="investorProfile.errors.investorProfileError"></span>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <!-- Hide this entire DIV for the age over 70 option -->
            <section *ngIf="ageEqualSeventyOrGreater" id="manageRiskSection">

                <div class="form-row label-row">

                    <h1 translate="investorProfile.titleManagingRisk"></h1>
                    <p translate="investorProfile.description1ManagingRisk"></p>
                    <p translate="investorProfile.description2ManagingRisk"></p>
                    <div class="note-white add-margin b24px">

                        <div class="form-row">
                            <div class="flex-25 form-col">
                                <p [innerHtml]="'investorProfile.incomeText' | translate | safeHtml" class="content-center">
                                </p>
                                <p [innerHtml]="'investorProfile.incomeTextPercentage' | translate | safeHtml"
                                    class="content-center"></p>
                            </div>
                            <div class="flex-25 form-col">
                                <p [innerHtml]="'investorProfile.balancedText' | translate | safeHtml"
                                    class="content-center"></p>
                                <p [innerHtml]="'investorProfile.balancedTextPercentage' | translate | safeHtml"
                                    class="content-center"></p>
                            </div>
                            <div class="flex-25 form-col">
                                <p [innerHtml]="'investorProfile.growthText' | translate | safeHtml" class="content-center">
                                </p>
                                <p [innerHtml]="'investorProfile.growthTextPercentage' | translate | safeHtml"
                                    class="content-center"></p>
                            </div>
                            <div class="flex-25 form-col">
                                <p [innerHtml]="'investorProfile.aggressiveText' | translate | safeHtml"
                                    class="content-center"></p>
                                <p [innerHtml]="'investorProfile.aggressiveTextPercentage' | translate | safeHtml"
                                    class="content-center"></p>
                            </div>
                        </div>
                    </div>

                    <div class="form-row label-row">

                        <p>
                            <a href="javascript://" id="InvestorOverlayLinkID" translate="investorProfile.labels.investorProfileMoreInfo" (click)="openInvestorOverlay()" role="button" ></a>
                        </p>

                    </div>

                    <h2 class="add-margin t24px" translate="investorProfile.titleSafe"></h2>
                    <div class="form-wrapper">
                        <div class="form-row">
                            <div class="form-col">
                                <div class="form-input-checks check-default">
                                    <input id="waveSafeguard" type="checkbox" name="waveSafeguard" data-ana="mywealth:onboarding:risk:reduce"
                                        [(ngModel)]="investorProfile.riskLevelAcceptedForAgeSeventy"
                                        #riskLevelAcceptedForAgeSeventyCtrl="ngModel" />
                                    <label translate="investorProfile.descriptionSafe" for="waveSafeguard"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </section>
        <section id="investorProfileButtonSection">
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater"
                formName="investorProfile" [backRoute]="APP_ROUTES.riskAndReturn"></app-form-buttons>
        </section>

    </div>

</form>
