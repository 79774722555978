import { Component, OnInit } from '@angular/core';
import { CdbOlbService } from '../../../shared/service/cdb-olb.service';
import { ApplicationService } from '../../../shared/service/application.service';
import { Utils } from '../../../shared/utils/utils.class';
import { PartyService } from '../../../shared/service/party.service';
import { Jquery } from '../../../shared/utils/jquery.class';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { AccountTypeService } from './../../service/account-type.service';
import { SelectAccount } from '../../../sections/select-account/select-account.component';
import { FormService } from '../../../shared/service/form.service';
import { PrefillService } from '../../../shared/service/prefill.service';
import {  ACCOUNT_TYPE, SPOUSAL_RRSP_ACCOUNT, MARGIN, ANALYTICS, APP_ROUTES, RRSP_ACCOUNT} from '../../../config/app-config.constants';
import {  Router, NavigationExtras , ActivatedRoute } from '@angular/router';
import { AnalyticsService } from "../../service/analytics.service";
import { WindowRefService } from '../../service/window-ref.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-in-progress-olb',
  templateUrl: './in-progress-olb.component.html'
})
export class InProgressOlbComponent implements OnInit {
  inprogressData: any = {};
  public selectAccount: SelectAccount = new SelectAccount();
  selectedAccountType = '';
  public applicationObject;
  public APP_ROUTES = APP_ROUTES;
  public systemCriticalError:boolean;
  public url: any;
  public showPage : boolean = false;
  public referenceError : boolean = false;
  public accountTypeArray : any = [];
  constructor(private applicationService: ApplicationService, private router: Router,
              public cdbOlbService : CdbOlbService , private partyService: PartyService ,
              private formService: FormService,private windowRef: WindowRefService,public titleService: Title,
              private prefillService: PrefillService ,private accountTypeService: AccountTypeService,
              private translateService: TranslateService,private analytics: AnalyticsService, private route: ActivatedRoute ) {
      this.url = '/' + APP_ROUTES.olb_inprogress;
     }

  ngOnInit(): void {
    this.referenceError = false;
    this.getOLBUserInprogressData();
    this.applicationService.pageTitles();
    this.titleService.setTitle(Utils.getpageTitleResumeApplication());
    this.applicationService.fetchReferences(this.route);
    if(!this.applicationService.appReferences) {
      this.referenceError = true;
    }
    this.analytics.setApplicationFlow(Utils.isMultiAccountFlow()?ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_MULTI:ANALYTICS.APPLICATION_FLOW.NON_ASSISTED);
    this.analytics.setPageName(ANALYTICS.PAGE_NAME.OLB_INPROGRESS_APPLICATION)
    this.analytics.setPageState(this.url);
    this.getCriticalError();

  }

  getCriticalError() {
    this.cdbOlbService.systemCriticalError.subscribe(data => {
      this.showPage = true;
      this.systemCriticalError = data;
    });
  }

  getOLBUserInprogressData() {
    this.cdbOlbService.oLBUserInprogressData.subscribe(data => {
      if(data) {
      this.showPage = true;
      this.inprogressData = data.workFLowStatusResponse[0];
      Utils.setOLBInprogressWorkflowID(this.inprogressData.workFlowId);
      Utils.setOLBInprogressilNum(this.inprogressData.ilNum);
      this.getAccountName();
       }
    });
  }
  getAccountName() {
    let isSRRSPAccountAdded: boolean = false;
    (this.inprogressData.accountType || []).forEach(account => {
        if(account) {
                if (account == ACCOUNT_TYPE.RRSP && this.inprogressData.spousal === true && !isSRRSPAccountAdded) {
                this.selectAccount.selectedAccount = SPOUSAL_RRSP_ACCOUNT;
                isSRRSPAccountAdded = true;
            } else if (account == ACCOUNT_TYPE.RRSP) {
                this.selectAccount.selectedAccount = RRSP_ACCOUNT;
            }else if(account == ACCOUNT_TYPE.CASH && this.inprogressData.subType.includes('2')) {
                this.selectAccount.selectedAccount = MARGIN;
            } else {
                this.selectAccount.selectedAccount = this.accountTypeService.accountTypeMetaData.find(
                x => x.type == account);
            }

            this.selectedAccountType = this.selectAccount.selectedAccount.description;
            this.translateService.get(this.selectedAccountType).subscribe(
            translation => {
            this.accountTypeService.accountTypeTitle = translation;
            });

            this.accountTypeArray.push(this.selectAccount.selectedAccount)
        }
    })


  }
  onContinue() {
    if(this.referenceError) {
      return;
  }
    this.applicationService.retrieveApplicationOLB(this.inprogressData.workFlowId , this.inprogressData.ilNum).subscribe(
      (response: any) => {

          if (response.status === 200) {
            //  Utils.setIsIlUser(response.body.ilUser);
            const isUserInSession: boolean = JSON.parse(response.headers.get('isinsession'));
              Utils.setIsInSession(isUserInSession);
              Utils.isSessionInitiated = true;
              this.setOlbuserDatas(response.body);
              Utils.setOlbAppRetrieved(true);
              this.resumeApplicationData(response.body);
            this.applicationService.checkHasTradingKnowledge(this.applicationObject.accounts[0].accountFeatures.optionsTrading.knowledge);
              if (response.body.status == 'DataCollection') {
              this.getPrefillOlb(response.body.olapPayload);
              }
          }
      },
      error => {
          // console.log('error', error);
      });

      this.windowRef.nativeWindow._satellite.track('resumeApplication');

  }
      resumeApplicationData(response) {
        this.applicationObject = response;
        this.applicationService.pageTitles();
         this.applicationService.applicationNumber = this.applicationObject.applicationNumber;
        this.applicationService.applicationStatus = this.applicationObject.status;
        this.applicationService.applicationNumber$.next(this.applicationObject.applicationNumber);
        Utils.setApplicationData(this.applicationObject);
        Utils.setApplicationObject(this.applicationObject); // setting at global level


        /**Set sub-product*/
        Utils.setSubProduct(this.applicationObject.applicationSubProduct);
        this.applicationService.setAccountFlow(this.applicationObject.applicationSubProduct);

        Utils.setPrimaryApplicant(this.applicationObject.parties[0]);
        /*sign-out stuff*/
        Utils.setsaveDoneOnSignOut(false);
            /**URL Change for SD/AD */
            let navParamenter: NavigationExtras = {};

            if (Utils.isSubProductAD()) {
                navParamenter = {
                    'queryParams': {
                        'lang': Utils.getLanguage(),
                        'subProduct': Utils.getSubProduct()
                    }
                };
            } else {
                navParamenter = {
                    'queryParams': {
                        'lang': Utils.getLanguage()
                    }
                };
            }

            const _url = ['/select-account/'];

            this.router.navigate(_url, navParamenter);
            if(!Utils.isMultiAccountFlow()) {
                if(this.applicationObject.accounts[0].type) {
                    if (this.applicationObject.accounts[0].type == ACCOUNT_TYPE.RRSP
                        && this.applicationObject.accounts[0].isSpousal === true) {
                        this.selectAccount.selectedAccount = ACCOUNT_TYPE.SRRSP;
                        this.formService.getPayload().setAccountType(this.selectAccount);
                        this.selectAccount.selectedAccount = SPOUSAL_RRSP_ACCOUNT;
                        Utils.setSelectedAccountType(SPOUSAL_RRSP_ACCOUNT.type);
                        this.formService.setFormData('selectAccount', this.selectAccount);
                    } else if(this.applicationObject.accounts[0].type == ACCOUNT_TYPE.CASH && this.applicationObject.accounts[0].subTypes.includes('2')) {
                        this.selectAccount.selectedAccount = ACCOUNT_TYPE.MARGIN;
                        this.formService.getPayload().setAccountType(this.selectAccount);
                        this.selectAccount.selectedAccount = MARGIN;
                        Utils.setSelectedAccountType(MARGIN.type);
                        this.formService.setFormData('selectAccount', this.selectAccount);
                    } else {
                        this.selectAccount.selectedAccount = this.accountTypeService.accountTypeMetaData.find(
                            x => x.type == this.applicationObject.accounts[0].type);
                        Utils.setSelectedAccountType(this.selectAccount.selectedAccount.type);
                        this.formService.setFormData('selectAccount', this.selectAccount);
                    }

                    this.partyService.setAccountTypeOnResumeApplication(this.applicationObject);

                    this.applicationService.selectAccount$.next(this.applicationObject.accounts[0]);

                    this.accountTypeService.setSelectedAccount(this.selectAccount.selectedAccount);
                    this.partyService.onResumeApplicationPrepopulate();
                  } else {
                    this.formService.forms.setExpectation.isCompleted = true;
                    this.formService.forms.setExpectation.isVisited = true;
                  }
            } else {

            let multiAccountApplication : any = [];
            let selectedAccount : any = [];
            for (let i=0; i<this.applicationObject.accounts.length; i++){
                if (this.applicationObject.accounts[i].type == ACCOUNT_TYPE.RRSP
                    && this.applicationObject.accounts[i].isSpousal === true) {
                      this.selectAccount.selectedAccountTypes[i] = ACCOUNT_TYPE.SRRSP;
                      selectedAccount = SPOUSAL_RRSP_ACCOUNT;
                } else if (this.applicationObject.accounts[i].type == ACCOUNT_TYPE.CASH && this.applicationObject.accounts[i].subTypes.includes('2')) {
                     this.selectAccount.selectedAccountTypes[i] = '100544-MARGIN';
                     selectedAccount = MARGIN;
                } else {
                     this.selectAccount.selectedAccountTypes[i] = this.applicationObject.accounts[i].type;
                     selectedAccount = this.accountTypeService.accountTypeMetaData.find(
                        x => x.type == this.applicationObject.accounts[i].type);
                }
                multiAccountApplication.push(selectedAccount);
            }
           this.formService.getPayload().setMultiAccountTypes(this.selectAccount);
           this.formService.setFormData('selectAccount', this.selectAccount);
            this.partyService.setMultiAccountTypeOnResumeApplication(this.applicationObject);
            this.applicationService.selectAccount$.next(this.applicationObject.accounts[0]);
           this.accountTypeService.setMultiSelectedAccount(multiAccountApplication);
           this.partyService.onResumeMultiAccountApplicationPrepopulate();
        }

      }
  setOlbuserDatas(response) {
    this.cdbOlbService.cdbOlbUserInfo(response['parties'][0].personal.identity.legalName.firstName);
    Utils.setApplicationObject(response);

     this.formService.getPayload().setIsApplicantExistingPBClient(response['isApplicantExistingPBClient']);
    //  this.formService.getPayload().setOlbUser(response['olbuser']);
     this.applicationService.setOLbJourneyStatus(response['ilUser'],response['olbuser']);
     this.formService.getPayload().setOLAPPayload(response['olapPayload']); // Adding updated XML to Payload object
            if(response['parties'][0].bmoRelationship) {
                this.formService.getPayload().setPrivateBankingClient(response['parties'][0].bmoRelationship.isApplicantExistingPBClient);
            }
         this.formService.getPayload().setBranchBMORelationship(true); // If olb user  set BMO Relationship to true
          if(response['accounts'] && response['accounts'][0]) {
              this.formService.getPayload().setCardDetails(response['accounts'][0]);
             }

  }
  onDelete() {
    if(this.referenceError) {
        return;
    }
    $('.bmo-cdb-delete-app, .modal-overlay').show();
     Jquery.modalFocusTrap('bmo-cdb-delete-app');
  }
  getPrefillOlb(olapPayload) {
    const payload = JSON.parse(olapPayload);
    this.applicationService.getOlbUserDetails(payload).subscribe((response: any) => {
      if (response?.parties) {
      const obj = {
        dateOfBirth : response.parties[0].personal.identity.dateOfBirth ,
        socialInsuranceNumber : response.parties[0].personal.identity.socialInsuranceNumber
      };
    if (response?.clientAccessId) {
        this.formService.getPayload().setClientAccessID(response.clientAccessId);
    }

      Utils.setPrefillOlbDOBandSIN(obj);
    }
     });
  }
}
