// modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// components
import { DatePickerComponent, DatePickerBackComponent, DatePickerCloseComponent } from './date-picker.component';
import { CdkTrapFocus } from './../../directive/focus-trap.directive';
// pipes
import { DatePipe } from './../../pipes/date.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule
    ],
    declarations: [
        DatePickerComponent,
        DatePickerCloseComponent,
        DatePipe,
        DatePickerBackComponent,
        CdkTrapFocus
    ],
    exports: [
        DatePickerComponent,
        DatePickerBackComponent,
        DatePickerCloseComponent,
        CdkTrapFocus
    ]
})
export class DatePickerModule {
}
