// @angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment.dev';

// component imports
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { PersonalInformationComponent } from './sections/personal-information/personal-information.component';
import { ContactInformationComponent } from './sections/contact-information/contact-information.component';
import { EmploymentInformationComponent } from './sections/employment-information/employment-information.component';
import { ReviewComponent } from './sections/review/review.component';
import { FamilyInformationComponent } from './sections/family-information/family-information.component';
import { SelectAccountComponent } from './sections/select-account/select-account.component';
import { SetExpectationComponent } from './sections/set-expectation/set-expectation.component';
import { CelebrationComponent } from './sections/celebration/celebration.component';
import { FinancialDetailsComponent } from './sections/financial-details/financial-details.component';
import { ConsentsComponent } from './sections/consents/consents.component';
import { RegulatoryDisclosuresComponent } from './sections/regulatory-disclosures/regulatory-disclosures.component';
import { BmoRelationshipComponent } from './sections/bmo-relationship/bmo-relationship.component';
import { AccountPasswordComponent } from './sections/account-password/account-password.component';
//import { AccountAccessComponent } from './sections/account-access/account-access.component';
import { CelebrationReviewComponent } from './sections/celebration-review/celebration-review.component';
import { TermsConditionsComponent } from './sections/terms-conditions/terms-conditions.component';
import { NextBybranchPdfComponent } from './sections/next-bybranch-pdf/next-bybranch-pdf.component';
import { NextBymailPdfComponent } from './sections/next-bymail-pdf/next-bymail-pdf.component';
import { InvestmentKnowledgeComponent } from './sections/investment-knowledge/investment-knowledge.component';
// import { InvestmentObjectivesComponent } from './sections/investment-objectives/investment-objectives.component';
import { InvestorProfileComponent } from './sections/investor-profile/investor-profile.component';
import { BranchAssistedComponent } from './sections/branch-assisted/branch-assisted.component';
import { BranchReferralComponent } from './sections/branch-referral/branch-referral.component';
import { BranchEsignatureComponent } from './sections/branch-esignature/branch-esignature.component';
import { BranchSaveProgressComponent } from './sections/branch-save-progress/branch-save-progress.component';
import { TechnicalErrorComponent } from './sections/technical-error/technical-error.component';
import { BeneficiaryComponent } from './sections/beneficiary/beneficiary.component';
import { RiskAndReturnComponent } from './sections/risk-and-return/risk-and-return.component'

import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { ReferenceResolver } from './shared/resolver/reference.resolver';
import { APP_ROUTES } from './config/app-config.constants';
import { ConfigResolver } from './shared/resolver/config.resolver';
import { SessionResolver } from './shared/resolver/session.resolver';
import { NextStepsComponent } from './sections/next-steps/next-steps.component';
import { RetrieveApplicationResolver } from './shared/resolver/retrieve-application.resolver';
import { AccountTypesResolver } from './shared/resolver/account-types.resolver';

import { DocumentUploadComponent } from './sections/document-upload/document-upload.component';
import { AccountDetailComponent } from './sections/account-detail/account-detail.component';
import { ApplicationApprovalDeclinedComponent } from './sections/application-approval-declined/application-approval-declined.component';
import { ApplicationApprovalDowngradeComponent } from './sections/application-approval-downgrade/application-approval-downgrade.component';
import { NoNavComponent } from './layouts/no-nav/no-nav.component';
import { WithNavComponent } from './layouts/with-nav/with-nav.component';
import { NoFooterComponent } from './layouts/no-footer/no-footer.component';
import { CanActivateGuard } from './shared/guards/can-activate.guard';
import { AccountsResolver } from './shared/resolver/accounts.resolver';
import { MasterResolver } from './shared/resolver/master.resolver';
import { CanBranchActivateGuard } from './shared/guards/can-branch-activate.guard';
import { WithdrawalComponent } from './sections/withdrawal/withdrawal.component';
import { TechnicalErrorSessionComponent } from './sections/technical-error-session/technical-error-session.component';
import { TechnicalErrorBranchurlComponent } from './sections/technical-error-branchurl/technical-error-branchurl.component';
import { SessionActiveGuard } from './shared/guards/session-active.guard';
import { CMSMessageResolver } from './shared/resolver/cmsmessage.resolver';
import { CdbOlbComponent } from './sections/cdb-olb/cdb-olb.component';
import { CanOlbActivateGuard } from './shared/guards/can-olb-activate.guard';
import { InProgressOlbComponent } from './shared/components/in-progress-olb/in-progress-olb.component';
import { JourneyLoginComponent } from './sections/journey-login/journey-login.component'
/**
 * Order of these routes must be kept in the intended order we wish to navigate the application
 * using the back or continue button.
 */
export const applicationRoutes: Routes = [

    {
        path: '',
        component: NoNavComponent,
        resolve:{
            cmsMessage: CMSMessageResolver
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: APP_ROUTES.select_account
            },
            {
                path: 'CDB/login',
                component: CdbOlbComponent
            },
            {
                path: 'journey/login',
                component: JourneyLoginComponent
            },
            {
                path: APP_ROUTES.olb_inprogress,
                 component: InProgressOlbComponent ,
                canActivate: [CanOlbActivateGuard],
                resolve: {
                    reference: MasterResolver
                }

            },
            {
                path: APP_ROUTES.technical_error,
                component: TechnicalErrorComponent,
                data: {
                    progressInfo: {
                        showProgressBar: false,
                        pageName: 'mainNav.technicalError'
                    }
                }
            },
            {
                path: APP_ROUTES.technical_error_session,
                component: TechnicalErrorSessionComponent
            },

            {
                path: APP_ROUTES.technical_error_branchurl,
                component: TechnicalErrorBranchurlComponent
            },
            {
                path: APP_ROUTES.branch_referral,
                component: BranchReferralComponent,
                canActivate: [CanBranchActivateGuard],
                resolve: {
                    reference: MasterResolver,
                },
                data: {
                    progressInfo: {
                        showProgressBar: false,
                        pageName: 'mainNav.branchReferral'
                    }
                }
            },
            {
                path: APP_ROUTES.branch_esignature,
                component: BranchEsignatureComponent,
                canActivate: [CanBranchActivateGuard],
                data: {
                    progressInfo: {
                        showProgressBar: false,
                        pageName: 'mainNav.branchEsignature'
                    }
                }
            },
            {
                path: APP_ROUTES.branch_save_progress,
                component: BranchSaveProgressComponent,
                canActivate: [CanBranchActivateGuard],
                data: {
                    progressInfo: {
                        showProgressBar: false,
                        pageName: 'mainNav.branchSaveProgress'
                    }
                }
            },
       /*     {
                path: APP_ROUTES.select_account,
                component: SelectAccountComponent,
                resolve: {
                    accountTypes: MasterResolver
                },
                data: {
                    progressInfo: {
                        showProgressBar: false,
                        pageName: 'mainNav.accountSelection'
                    }
                }
            }, */
            {
                path: 'branch-assisted',
                component: BranchAssistedComponent,
                canActivate: [CanBranchActivateGuard],
            },
            {
                path: APP_ROUTES.account_detail,
                component: AccountDetailComponent,
                resolve: {
                    reference: ReferenceResolver
                },
                canActivate: [CanActivateGuard]
            },
        /*    {
                path: APP_ROUTES.set_expectation,
                component: SetExpectationComponent,
                data: {
                    progressInfo: {
                        showProgressBar: false,
                        pageName: 'mainNav.setExpectations'
                    }
                }
            }, */
            {
                path: APP_ROUTES.next_steps,
                component: NextStepsComponent,
                resolve: {
                    reference: ReferenceResolver,
                    application: RetrieveApplicationResolver,
                    accountTypes: AccountTypesResolver
                },
                canActivate:[SessionActiveGuard]
            },
            {
                path: APP_ROUTES.document_upload,
                component: DocumentUploadComponent,
                resolve: {
                    reference: ReferenceResolver,
                    //application: RetrieveApplicationResolver
                }
            },
            {
                path: APP_ROUTES.application_approval_downgrade,
                component: ApplicationApprovalDowngradeComponent,
                data: {
                    progressInfo: {
                        showProgressBar: false,
                        pageName: 'mainNav.applicationDowngrade'
                    }
                }
            }
        ]

    },


    {
        path: '',
        component: NoFooterComponent,
        resolve:{
            cmsMessage: CMSMessageResolver
        },
        children: [
           /*{
                path: APP_ROUTES.dashboard,
                component: DashboardComponent
            }, */
            {
                path: APP_ROUTES.next_bybranch_pdf,
                component: NextBybranchPdfComponent,
                resolve: { reference: ReferenceResolver, application: RetrieveApplicationResolver, accountTypes: AccountTypesResolver }
            },
            {
                path: APP_ROUTES.next_bymail_pdf,
                component: NextBymailPdfComponent,
                resolve: { reference: ReferenceResolver, application: RetrieveApplicationResolver, accountTypes: AccountTypesResolver }
            },
        ]
    },

    {
        path: '',
        component: WithNavComponent,
        resolve:{
            cmsMessage: CMSMessageResolver
        },
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: APP_ROUTES.select_account,
                component: SelectAccountComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                resolve: {
                    accountTypes: MasterResolver
                },
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.set_expectation,
                component: SetExpectationComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                resolve: {
                    accountTypes: MasterResolver
                },
                canDeactivate: [CanDeactivateGuard]

            },
            {
                path: APP_ROUTES.personal_information,
                component: PersonalInformationComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.contact_information,
                component: ContactInformationComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.employment_information,
                component: EmploymentInformationComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.family_information,
                component: FamilyInformationComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.beneficiary,
                component: BeneficiaryComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.withdrawal,
                component: WithdrawalComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            // {    // MWB-13833
            //     path: APP_ROUTES.celebration,
            //     component: CelebrationComponent,
            // },
            {
                path: APP_ROUTES.financial_details,
                component: FinancialDetailsComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.investment_knowledge,
                component: InvestmentKnowledgeComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.riskAndReturn,
                canDeactivate: [CanDeactivateGuard],
                component: RiskAndReturnComponent
            },
            {
                path: APP_ROUTES.investor_profile,
                component: InvestorProfileComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.regulatory_disclosures,
                component: RegulatoryDisclosuresComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.consents,
                component: ConsentsComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.bmo_relationship,
                component: BmoRelationshipComponent,
                canDeactivate: [CanDeactivateGuard]
            },

            {
                path: APP_ROUTES.account_password,
                component: AccountPasswordComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.account_access,
                component: AccountPasswordComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.celebration_review,
                component: CelebrationReviewComponent
            },
            {
                path: APP_ROUTES.review,
                component: ReviewComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: APP_ROUTES.terms_conditions,
                canDeactivate: [CanDeactivateGuard],
                component: TermsConditionsComponent
            }
        ]
    },

    {
        path: APP_ROUTES.application_approval_declined,
        component: ApplicationApprovalDeclinedComponent
    }

];

@NgModule({
    imports: [RouterModule.forRoot(applicationRoutes, { useHash: true, enableTracing: false })],
    exports: [RouterModule],
    providers: []
})
export class RoutingModule { }
