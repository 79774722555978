<!-- <app-flood-light></app-flood-light> -->

<form #beneficiaryInformationForm="ngForm" (ngSubmit)="onSubmit()">

    <div class="container-menuView">

        <section id="beneficiaryErrorSection">

            <app-critical-error *ngIf="criticalError"></app-critical-error>

            <!--Invalid total error-->
            <div *ngIf="(invalidTotal && isFormSubmit) || (invalidTotal && formService.forms.beneficiaryInformation.isCompleted)"
                class="alert alert-danger" role="alert">
                <p>
                    <strong translate="investmentObjectives.errors.criticalError"></strong>
                    <br />
                    <span translate="investmentObjectives.errors.criticalErrorValueAddUp"></span>
                </p>
            </div>

            <!-- Error messages-->
            <app-invalid-form #appInvalidForm [hideIncaseOfInvalidTotal]="invalidTotal"
                [isFormInvalid]="beneficiaryInformationForm.invalid && isFormSubmit" translateTag="beneficiary">
            </app-invalid-form>
            <!-- delete message announcement -->
            <div class="aoda-off-screen-element" aria-atomic="true">
                <div id="del-hidden-container" style="display: none;" role="alert" aria-live="assertive">{{ 'ariaLabels.delBeneMessage' | translate }}</div>
            </div>
            <!-- add message announcement -->
            <div class="aoda-off-screen-element" aria-atomic="true">
                <div id="add-hidden-container" style="display: none;" role="alert" aria-live="assertive">{{ 'ariaLabels.addBeneMessage' | translate }} </div>
            </div>
            <div role="radiogroup" aria-labelledby="beneficiaryYesNo">
                <h1 translate="beneficiary.titleWantToAppoint" id="beneficiaryYesNo"></h1>
                <p translate="beneficiary.descriptionWantToAppoint"></p>
    
                <div class="form-wrapper">
                    <div class="form-row">
                        
                        <app-confirmation-yes-no #hasBeneficiariesCtrl="ngModel" id="hasBeneficiaries"
                            dataAna="mywealth:onboarding:beneficiary:appoint"
                            name="hasBeneficiaries" [isFormSubmit]="isFormSubmit" [(ngModel)]="hasBeneficiaries"
                            errorMessage="beneficiary.errors.wantToAppoint" (change)="setHasBeneficiariesValue()"
                            [accessId]="{ id: 'hasBeneficiaries-error', parentId: '',  submitted: isFormSubmit }">
                        </app-confirmation-yes-no>
                    </div>
                </div>
            </div>


        </section>

        <section id="beneficiaryDetailSection" *ngIf="(hasBeneficiaries == 'Yes' && beneficiariesList.length <= maxBeneficiaries)">

            <h1 translate="beneficiary.titleBeneficiary"></h1>
            <div class="alert alert-info" role="alert">
                <p translate="beneficiary.descriptionBeneficiary"></p>
            </div>

            <!--hidden value-->
            <input tabindex="-1" id="total" name="total" type="hidden" value="" graphValueValidator
                [(ngModel)]="totalValue" #totalValueCtrl="ngModel">

            <div *ngFor="let beneficiary of beneficiariesList; let index = index">

                <!--index-->
                <hr *ngIf="(index + 1 > 1)" />

                <!--Delete button & Title-->
                <div class="bmo-container-flex flex-flow-nowrap review-title-section">
                    <div class="flex-col-expand flex-xaxis-center review-title">
                        <h2>
                            <span
                                [innerHTML]="'beneficiary.subtitleDesignatedBeneficiary' | translate | safeHtml"></span>
                            <span>&nbsp;{{index+1}}</span>
                        </h2>
                    </div>
                    <div *ngIf="(index <= maxBeneficiaries)  && index !== 0" class="flex-equal-fixed">
                        <a role="button" attr.aria-label="{{ 'ariaLabels.btnDelete' | translate }} {{'beneficiary.subtitleDesignatedBeneficiary' | translate}} {{index+1}}"
                            attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}" class="btn-icon tooltip-show"
                            (click)="removeSelectedBeneficiary(index)" href="javascript://" id="closeBenefiary{{index+1}}">
                            <span class="icon icon-large trash-blue"></span>
                        </a>
                    </div>
                </div>

                <!--Beneficiary Information Component-->
                <app-beneficiary-information [index]="index" [beneficiariesList]="beneficiariesList"
                    [form]="beneficiaryInformationForm" [isFormSubmit]="isFormSubmit"
                    [disablePercentageField]="disablePercentageField" (invalidTotalValue)="setInvalidTotal($event)">
                </app-beneficiary-information> 

                <!--Add  button-->
                <div *ngIf="( (index == (beneficiariesList.length - 1)) && index < maxBeneficiaries-1)"
                    class="form-row button-row">
                    <a class="btn-icon" href="javascript://" role="button" id="addAnotherBeneficiary" (click)="addAnotherBeneficiary()">
                        <span class="icon icon-large plus-circle-blue"></span>
                        <span class="btn-icon-text" data-ana="mywealth:onboarding:beneficiary:add another" translate="beneficiary.labels.addAnotherBeneficiary"
                        ></span>
                    </a>
                </div>

            </div>

        </section>

        <section id="beneficiaryButtonSection">
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater"
                formName="beneficiary" [backRoute]="APP_ROUTES.family_information"></app-form-buttons>
        </section>

    </div>

</form>

