import { Directive, HostListener, Input, ElementRef, OnInit } from '@angular/core';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { BACKSPACE, TAB, ENTER, SHIFT, END, HOME, LEFT_ARROW, RIGHT_ARROW, DELETE, CTRL} from './../../config/app-config.constants';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Utils } from '../utils/utils.class';
import { AnalyticsService } from '../service/analytics.service';

// this DIRECTIVE checks for the characters below
// CT4-English Alphanumeric (a-z, A-Z,  1, 2, 3 , 4 etc )
// CT5-Basic Special Characters

@Directive({
  selector: '[appEmailFormat]'
})
export class EmailFormatDirective implements OnInit{
public regex = /^[a-zA-Z0-9._@+-]+$/;
  constructor(private el: ElementRef,
    private configService: ConfigResolverService<any>,
    private analyticsService : AnalyticsService,
    private http: HttpClient) { }

  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) { 
    if (this.regex.test(event.key)) {
      return;
    }
    event.preventDefault();
  }

  ngOnInit() {
    if (Utils.everestApiKey === null) {
      const config = this.configService.getConfig();
      const everestApiCall = config.CHANNELS_API.CONTEXT_ROOT + config.EVERESTAPIKEY.EVERESTAPIKEY_URI;
      let header = config.EVERESTAPIKEY.HEADER;
      header = new HttpHeaders(header);
      header['workflow_id'] = localStorage.getItem('appId');
      this.http.get(everestApiCall, { headers: header })
          .toPromise()
          .then(response => {
              Utils.everestApiKey = response["apiKeyValue"];
          })
          .catch(e => {
              Utils.everestApiKey = null;
              this.analyticsService.setapiCallDetails({
                apiCallMade: 'true', apiCallType: "everestApiCall", 
                apiCallResponseCode: e.error?.errorInfo ? e.status?.toString() + "-" + e.error?.errorInfo.errorCode.toString() : e.status?.toString()

            });
          });
  }
  }

}
