
import { environment } from '../../../environments/environment';
import { ACCOUNT_CODES, ACCOUNT_TYPE, CASH, MARGIN, RRIF_ACCOUNT, RRSP_ACCOUNT, SPOUSAL_RRSP_ACCOUNT, TFSA_ACCOUNT } from '../../config/app-config.constants';
import * as moment from 'moment';
export class Utils {
    public static references = null;
    public static isBranchReferral = false;
    public static pageStatus: any;
    public static netWorth = '';
    public static packageID: any;
    public static signerID: any;
    public static product: any;
    public static applicationID: any;
    public static accountName: string;
    public static accountNumber: number;
    private static pageTitleResumeApplication: string;
    private static pageTitleAccountSelection: string;
    private static pageTitleAccountDetails: string;
    private static pageTitleThingsYouShouldKnow: string;
    private static pageTitlePersonalInformation: string;
    private static pageTitleContactInformation: string;
    private static pageTitleEmploymentDetails: string;
    private static pageTitleFamilyInformation: string;
    private static pageTitleBeneficiaryInformation: string;
    private static pageTitleWithdrawalInformation: string;
    private static pageTitleFinancialDetails: string;
    private static pageTitleInvestmentKnowledge: string;
    private static pageTitleInvestmentObjectives: string;
    private static pageTitleRiskAndReturn: string;
    private static pageTitleBranchReferral: string;
    private static pageTitleDocumentUpload: string;
    private static pageTitleInvestorProfile: string;
    private static pageTitleRegulatoryDisclosures: string;
    private static pageTitleConsents: string;
    private static pageTitleBMORelationship: string;
    private static pageTitleAccountPassword: string;
    private static pageTitleFinalReview: string;
    private static pageTitleESignature: string;
    private static pageTitleCompletion: string;
    private static pageTitleMidWayCelebration: string;
    private static pageTitleFinalCelebration: string;
    private static pageTitleSaveForLater: string;
    private static pageTitleEsignLanding: string;
    public static selectedAccount: string;
    public static selectedMultiAccounts: any;
    private static selectedAccountType: any;
    private static uploadSuccessful;
    private static docUploadVisited = false;
    private static subProduct: string;
    private static mboxEdit: string;
    private static mboxDisable: string;
    private static aznCode: string;
    private static lang: string;
    private static userLoggedIn: Boolean;
    private static isInSession: boolean;
    private static isBranchAssisted: boolean;
    private static applicationObject: any;
    private static applicationNumber: any;
    private static primaryApplicant: any;
    public static accountSelected: boolean;
    public static documentPackageStatus: any;
    public static isNavigationDissabled: boolean;
    public static isSessionInitiated = false;
    public static isInFlightApplication = false;
    public static beneficiaryPageCondition: boolean;
    public static withdrawalPageCondition: boolean = false;
    public static enableLoader: boolean = true;
    public static callSelectAccount = false;
    public static backToSelectAccountPage = false;
    private static countriesList: any;
    public static apiKeyValue: any = null;
    public static everestApiKey: any = null;
    public static isBMONetwork: boolean = false;
    public static aliveCheckNetwork: boolean = true;
    public static partyRefId: any;
    public static saveDoneOnSignOut: boolean;
    public static signOutClicked: boolean = false;
    public static resumeApplicationLogin: boolean = false;
    public static resumeApplicationDowngrade: boolean = false;
    public static tncDocName : any;
    public static hasSelectedNonRegAcct: boolean = false;

    public static provinceLabel: any;
    public static documentsPackage: any;
    public static nextStepsData: any;
    public static isSelectAccountClicked: boolean = false;
    public static applicationData: any;
    public static financialInstitutions: any;
    public static showCMSMessage: boolean = true;
    public static appLaunchDateStamp: any;
    private static cmsMessageObject: any;
    private static isOLBUser: boolean = false;
    private static isJourneyUser: boolean = false;
    private static isIlUser: boolean = false;
    private static isLMEUser: boolean = false;
    private static mockOLBUser: boolean = false;
    private static mockJourneyUser: boolean = false;
    private static isNonPilotUser: boolean = false;
    private static showCDBSessionCallError: boolean = false;
    private static OLBUserECIFId : any ;
    private static OLBUserCardNumber : any ;
    private static setExpectationForm: any;
    private static aliveOlbService: boolean = true;
    private static olbAppRetrieved: boolean = false;
    private static prefillOlbPersonalInfo: boolean = true;
    private static OLBInprogressWorkflowID : any ;
    private static OLBInprogressilNum : any ;
    public static backRoute: any;
    private static prefillOlbDOBandSIN: any;
    private static cmsMessageCallNotRequired: any;
    public static skipDocUpload: boolean;
    public static journeyUserLoginId: any;
    public static journeyUserECIFId: any;
    private static isSDOwner: boolean;

    public static setCmsMessageCallNotRequired(value){
        this.cmsMessageCallNotRequired = value;
    }

    public static getCmsMessageCallNotRequired(){
        return this.cmsMessageCallNotRequired;
    }

    public static setAppLaunchDateStamp(){
        this.appLaunchDateStamp = Date.now();
    }

    public static getAppLaunchDateStamp(){
        return this.appLaunchDateStamp;
    }

    public static setFinancialInstitutions(value){
        this.financialInstitutions = value;
    }
    public static getFinancialInstitutions() {
        return this.financialInstitutions;
    }
    public static setIsSelectAccountClicked(value) {
        this.isSelectAccountClicked = value;
    }

    public static getIsSelectAccountClicked() {
        return this.isSelectAccountClicked;
    }

    public static setapplicationID(value) {
        this.applicationID = value;
    }

    public static getapplicationID() {
        return this.applicationID;
    }

    public static setApplicationData(value) {
        this.applicationData = value;
    }

    public static getApplicationData() {
        return this.applicationData;
    }

    public static setDocumentsPackage(value) {
        this.documentsPackage = value;
    }
    public static getDocumentsPackage() {
        return this.documentsPackage;
    }
    public static setNextStepsData(value) {
        this.nextStepsData = value;
    }
    public static getNextStepsData() {
        return this.nextStepsData;
    }
    public static setReferences(value) {
        this.references = value;
    }

    public static getReferences() {
        return this.references;
    }

    public static setsaveDoneOnSignOut(value) {
        this.saveDoneOnSignOut = value;
    }

    public static getsaveDoneOnSignOut() {
        return this.saveDoneOnSignOut;
    }

    public static setIsInSession(value: boolean) {
        this.isInSession = value;
    }

    public static getIsInSession(): boolean {
        return this.isInSession;
    }

    public static setApplicationInflightStatus(inFlightStatus: boolean) {
        this.isInFlightApplication = inFlightStatus;
    }
    public static getApplicationInflightStatus():boolean {
        return this.isInFlightApplication;
    }

    public static setIsBranchAssisted(value: boolean) {
        this.isBranchAssisted = value;
    }

    public static getIsBranchAssisted(): boolean {
        return this.isBranchAssisted;
    }
    public static setShowCDBSessionCallError(value: boolean) {
        this.showCDBSessionCallError = value;
    }

    public static getShowCDBSessionCallError(): boolean {
        return this.showCDBSessionCallError;
    }
    public static setIsOLBUser(value: boolean) {
        this.isOLBUser = value;
    }

    public static getIsOLBUser(): boolean {
        return this.isOLBUser;
    }
    public static setIsJourneyUser(value: boolean) {
        this.isJourneyUser = value;
    }

    public static getIsJourneyUser(): boolean {
        return this.isJourneyUser;
    }

    public static setIsIlUser(value: boolean) {
        this.isIlUser = value;
    }

    public static getIsIlUser(): boolean {
        return this.isIlUser;
    }

    public static setIsLMEUser(value: boolean) {
        this.isLMEUser = value;
    }

    public static getIsLMEUser(): boolean {
        return this.isLMEUser;
    }
    public static setMockJourneyUser(value: boolean) {
        this.mockJourneyUser = value;
    }

    public static getMockJourneyUser(): boolean {
        return this.mockJourneyUser;
    }

    public static setMockOLBUser(value: boolean) {
        this.mockOLBUser = value;
    }

    public static getMockOLBUser(): boolean {
        return this.mockOLBUser;
    }
    public static setIsNonPilotUser(value: boolean) {
        this.isNonPilotUser = value;
    }

    public static getIsNonPilotUser(): boolean {
        return this.isNonPilotUser;
    }
    public static setJourneyUserEcifId(value: any) {
        this.journeyUserECIFId = value;
    }

    public static getJourneyUserEcifId() {
        return this.journeyUserECIFId;
    }
    public static setJourneyUserLoginId(value: any) {
        this.journeyUserLoginId = value;
    }

    public static getJourneyUserLoginId() {
        return this.journeyUserLoginId;
    }

    public static setOLBUserEcifId(value) {
        this.OLBUserECIFId = value;
    }

    public static getOLBUserEcifId() {
        return this.OLBUserECIFId;
    }

    public static setOLBUserCardNumber(value) {
        this.OLBUserCardNumber = value;
    }
    public static getOLBUserCardNumber() {
        return this.OLBUserCardNumber;
    }
    public static setOLBInprogressWorkflowID(value) {
        this.OLBInprogressWorkflowID = value;
    }

    public static getOLBInprogressWorkflowID() {
        return this.OLBInprogressWorkflowID;
    }
    public static setOLBInprogressilNum(value) {
        this.OLBInprogressilNum = value;
    }

    public static getOLBInprogressilNum() {
        return this.OLBInprogressilNum;
    }

    public static setPrefillOlbDOBandSIN(value) {
        this.prefillOlbDOBandSIN = value;
    }

    public static getPrefillOlbDOBandSIN() {
        return this.prefillOlbDOBandSIN;
    }
    public static setAliveOlbService(value: boolean) {
        this.aliveOlbService = value;
    }

    public static getAliveOlbService(): boolean {
        return this.aliveOlbService;
    }
    public static setOlbAppRetrieved(value: boolean) {
        this.olbAppRetrieved = value;
    }

    public static getOlbAppRetrieved(): boolean {
        return this.olbAppRetrieved;
    }
    public static setPrefillOlbPersonalInfo(value: boolean) {
        this.prefillOlbPersonalInfo = value;
    }

    public static getPrefillOlbPersonalInfo(): boolean {
        return this.prefillOlbPersonalInfo;
    }
    public static setExpectationFormData(value) {
        this.setExpectationForm = value;
    }

    public static getExpectationFormData() {
        return this.setExpectationForm;
    }

    public static setExpectationDataFromStorage(value) {
        sessionStorage.setItem('setExpectationObj', JSON.stringify(value));
    }

    public static getExpectationDataFromStorage() {
        const setExpectationDataFromStorage = sessionStorage.getItem('setExpectationObj');
        return setExpectationDataFromStorage ? JSON.parse(setExpectationDataFromStorage) : null;
    }

    public static setPartyRefId(value) {
        this.partyRefId = value;
    }

    public static getPartyRefId() {
        return this.partyRefId;
    }

    public static setSignOutClicked(value) {
        this.signOutClicked = value;
    }

    public static getSignOutClicked() {
        return this.signOutClicked;
    }
    public static setEnableLoader(value) {
        this.enableLoader = value;
    }

    public static getEnableLoader() {
        return this.enableLoader;
    }

    public static getUUID() {
        const rand = window.crypto.getRandomValues(new Uint32Array(1))[0] / Math.pow(2, 32);
        const h = n => {
            // tslint:disable-next-line:no-bitwise
            return (n | 0).toString(16);
        };
        const s = n => {
            // tslint:disable-next-line:no-bitwise
            return h((rand * (1 << (n << 2))) ^ Date.now()).slice(-n);
        };
        return [
            s(4) + s(4), s(4),
            '4' + s(3),                    // UUID version 4
            // tslint:disable-next-line:no-bitwise
            h(8 | (rand * 4)) + s(3), // {8|9|A|B}xxx
            Date.now().toString(16).slice(-10) + s(2) // Use timestamp to avoid collisions
        ].join('-');
    }

    public static setSelectedAccount(account: any) {
        this.selectedAccount = account.id;
        this.selectedAccountType = account.type;
    }

    public static setMultiSelectedAccount(account: any) {
        this.selectedMultiAccounts = account;
    }
    public static getMultiSelectedAccounts() {
        return this.getApplicationData().accounts || [];
    }

    public static setSelectedAccountType(selectedAccountType) {
        this.selectedAccountType = selectedAccountType;
    }

    public static getSelectedAccountType() {
        return this.selectedAccountType;
    }

    public static getTitleOfSelectedAccount(accountType) {
        if (accountType == ACCOUNT_TYPE.RRSP) {
            return 'RRSP';
        } else if (accountType == ACCOUNT_TYPE.TFSA) {
            return 'TFSA';
        } else if (accountType == ACCOUNT_TYPE.CASH) {
            return 'CASH';
        } else if (accountType == ACCOUNT_TYPE.RIF) {
            return 'RIF';
        } else if (accountType == ACCOUNT_TYPE.SRRSP) {
            return 'SRRSP';
        } else if (accountType == ACCOUNT_TYPE.MARGIN) {
            return 'MARGIN';
        } else {
            return null;
        }
    }

    static isRRSP() {
        return this.selectedAccountType == 100391 || this.selectedMultiAccounts?.some((account) => account?.type == 100391);
    }

    static isCash() {
        if(Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow()) {
         return (Utils.selectedMultiAccounts|| []).some((account) => account?.type == '100544');
       } else {
        return (this.selectedAccountType == 100544 || this.selectedAccountType == '100544-MARGIN');
       }
    }

    static isTFSA() {
        return (this.selectedAccountType == 100562 || this.selectedMultiAccounts?.some((account) => account?.type == '100562')) ;
    }

    static isJoint() {
        return this.selectedAccountType == 100545;
    }

    static isRIF() {
        return this.selectedAccountType == 100555 || this.selectedMultiAccounts?.some((account) => account?.type == '100555');
    }
    static isMargin() {
      //  let marginAccount : any = (this.getApplicationData().accounts || []).filter((account:any)=> (account.type =='100544' && account.subTypes[0] =='2'));
       if(Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow()) {
         return (Utils.selectedMultiAccounts || []).some((account) => account?.type == '100544-MARGIN');
       } else {
        return this.selectedAccountType == '100544-MARGIN'; // account type for margin
       }

    }

    static isSpousalRRSP() {
        return this.selectedAccountType == '100391-SPOUSAL' || this.selectedMultiAccounts?.some((account) => account?.type == '100391-SPOUSAL');
    }

    public static getAccountTypeLabel(accountCode, subTypes?, spousalFlag?){
        if(accountCode == ACCOUNT_CODES.TFSA ){
            return TFSA_ACCOUNT.description;
        }
        else if(accountCode == ACCOUNT_CODES.RRSP && spousalFlag === false){
            return RRSP_ACCOUNT.description;
        }
        else if(accountCode == ACCOUNT_CODES.RRSP && spousalFlag === true){
            return SPOUSAL_RRSP_ACCOUNT.description;
        }
        else if(accountCode == ACCOUNT_CODES.RIF){
            return RRIF_ACCOUNT.description;
        }
        else if(accountCode == ACCOUNT_CODES.CASH && subTypes.includes("1")){
            return CASH.description;
        }
        else if(accountCode == ACCOUNT_CODES.CASH && subTypes.includes("2")){
            return MARGIN.description;
        }
    }

    static isMultiAccountFlow() {
        if(!Utils.getIsBranchAssisted() && !Utils.isSubProductAD() && Utils.getCmsMessageObject()["isMultiAccountOpen"]) {
             return true;
        } else {
            return false;
        }
    }

    static isCRATaxResidencyEnabled() {
       return Utils.getCmsMessageObject()?.featureFlags?.['isCRAtaxresidencyEnabled'];
    }

    static isBranchSDMultiAccountFlow() {
        if(Utils.getIsBranchReferral() && !Utils.isSubProductAD() && Utils.getCmsMessageObject()?.featureFlags?.isBranchSDMultiAccountOpen) {
             return true;
        } else {
            return false;
        }
    }

    static isEmploymentDetails() {
        if(Utils.getCmsMessageObject()?.featureFlags?.isEmploymentDetails) {
             return true;
        } else {
            return false;
        }
    }

    static convertYesNoToBoolean(input: string) {
        return (input && input.toUpperCase() === 'YES' ? true : false);
    }
    static log(...args) {
        if (environment.name === 'dev') {
            args.forEach(element => {
                // console.log(element);
            });

        }
    }
    static setIsBranchReferral(value: boolean) {
        this.isBranchReferral = value ? value : false;
    }
    static getIsBranchReferral() {
        return this.isBranchReferral;
    }

    static setPageStatus(value) {
        this.pageStatus = value;
    }

    static getPageStatus() {
        return this.pageStatus;
    }


    static isCurrentLangEnglish() {
        return true;
    }

    static isCurrentLangFrench() {
        return true;
    }
    static setSkipDocUpload(isSkipped: boolean) {
        this.skipDocUpload = isSkipped;
    }

    static getSkipDocUpload() {
        return this.skipDocUpload;
    }
    static setIsDocUploadSuccess(success: boolean) {
        this.uploadSuccessful = success;
    }

    static getIsDocUploadSuccess() {
        return this.uploadSuccessful;
    }
    // added to check if doc upload page is visited to hide doc upload container and related content on next steps pages
    static setIsDocUploadVisited(docUploadVisited: boolean) {
        this.docUploadVisited = docUploadVisited;
    }

    static getIsDocUploadVisited() {
        return this.docUploadVisited;
    }

    static getLabels(data) {

        let selectedOptions = [];
        if (data instanceof Array) {
            data.forEach(element => {

                if (element.checked === true) {
                    selectedOptions.push(element.label);
                }

            });

        }

        return selectedOptions;
    }


    static getCodes(data) {

        let selectedOptions = [];
        if (data instanceof Array) {
            data.forEach(element => {

                if (element.checked === true) {
                    selectedOptions.push(parseInt(element.code)); // backend needs array of numbers
                }

            });

        }

        return selectedOptions;
    }


    /* hasOwnProperty() method will check if given object has given property or not. Works on IE as well. Tested

    Return true if propety is present.
    Return false if property is not present.
    */
    static hasOwnProperty(obj, prop) {
        return Object.prototype.hasOwnProperty.call(obj, prop);
    }

    static getPageTitleAccountSelection() {
        return this.pageTitleAccountSelection;
    }

    static setPageTitleAccountSelection(value) {
        this.pageTitleAccountSelection = value;
    }

    static getpageTitleResumeApplication() {
        return this.pageTitleResumeApplication;
    }

    static setpageTitleResumeApplication(value) {
        this.pageTitleResumeApplication = value;
    }


    static getPageTitleAccountDetails() {
        return this.pageTitleAccountDetails;
    }

    static setPageTitleAccountDetails(value) {
        this.pageTitleAccountDetails = value;
    }

    static getPageTitleThingsYouShouldKnow() {
        return this.pageTitleThingsYouShouldKnow;
    }

    static setPageTitleThingsYouShouldKnow(value) {
        this.pageTitleThingsYouShouldKnow = value;
    }

    static getPageTitlePersonalInformation() {
        return this.pageTitlePersonalInformation;
    }

    static setPageTitlePersonalInformation(value) {
        this.pageTitlePersonalInformation = value;
    }

    static getPageTitleContactInformation() {
        return this.pageTitleContactInformation;
    }

    static setPageTitleContactInformation(value) {
        this.pageTitleContactInformation = value;
    }

    static getPageTitleFamilyInformation() {
        return this.pageTitleFamilyInformation;
    }

    static setPageTitleFamilyInformation(value) {
        this.pageTitleFamilyInformation = value;
    }

    static getPageTitleBeneficiaryInformation() {
        return this.pageTitleBeneficiaryInformation;
    }

    static setPageTitleBeneficiaryInformation(value) {
        this.pageTitleBeneficiaryInformation = value;
    }

    static getPageTitleWithdrawalInformation() {
        return this.pageTitleWithdrawalInformation;
    }

    static setPageTitleWithdrawalInformation(value) {
        this.pageTitleWithdrawalInformation = value;
    }

    static getPageTitleEmploymentDetails() {
        return this.pageTitleEmploymentDetails;
    }

    static setPageTitleEmploymentDetails(value) {
        this.pageTitleEmploymentDetails = value;
    }

    static getPageTitleFinancialDetails() {
        return this.pageTitleFinancialDetails;
    }

    static setPageTitleFinancialDetails(value) {
        this.pageTitleFinancialDetails = value;
    }
    /**get title for Document Upload: MWB-15517 */
    static getPageTitleDocumentUpload() {
        return this.pageTitleDocumentUpload;
    }
    static setPageTitleDocumentUpload(value) {
        this.pageTitleDocumentUpload = value;
    }

    /**get title for investment-knowledge */
    static getPageTitleInvestmentKnowlwedge() {
        return this.pageTitleInvestmentKnowledge;
    }

    static setPageTitleInvestmentKnowlwedge(value) {
        this.pageTitleInvestmentKnowledge = value;
    }

    /**get title for investment-objectives */
    static getPageTitleInvestmentObjectives() {
        return this.pageTitleInvestmentObjectives;
    }

    static setPageTitleInvestmentObjectives(value) {
        this.pageTitleInvestmentObjectives = value;
    }

     /**get title for Risk and Return */
     static getPageTitleRiskAndReturn() {
        return this.pageTitleRiskAndReturn;
    }

    static setPageTitleRiskAndReturn(value) {
        this.pageTitleRiskAndReturn = value;
    }

    /**get title for investor-profile */
    static getPageTitleInvestorProfile() {
        return this.pageTitleInvestorProfile;
    }

    static setPageTitleInvestorProfile(value) {
        this.pageTitleInvestorProfile = value;
    }



    /**get title for branch-referral */
    static getPageTitleBranchReferral() {
        return this.pageTitleBranchReferral;
    }

    static setPageTitleBranchReferral(value) {
        this.pageTitleBranchReferral = value;
    }

    static getPageTitleConsents() {
        return this.pageTitleConsents;
    }

    static setPageTitleConsents(value) {
        this.pageTitleConsents = value;
    }

    static getPageTitleRegulatoryDisclosures() {
        return this.pageTitleRegulatoryDisclosures;
    }

    static setPageTitleRegulatoryDisclosures(value) {
        this.pageTitleRegulatoryDisclosures = value;
    }

    static getPageTitleBMORelationship() {
        return this.pageTitleBMORelationship;
    }

    static setPageTitleBMORelationship(value) {
        this.pageTitleBMORelationship = value;
    }

    static getPageTitleAccountPassword() {
        return this.pageTitleAccountPassword;
    }

    static setPageTitleAccountPassword(value) {
        this.pageTitleAccountPassword = value;
    }

    static getPageTitleFinalReview() {
        return this.pageTitleFinalReview;
    }

    static setPageTitleFinalReview(value) {
        this.pageTitleFinalReview = value;
    }

    static getPageTitleESignature() {
        return this.pageTitleESignature;
    }

    static setPageTitleESignature(value) {
        this.pageTitleESignature = value;
    }

    static getpageTitleEsignLanding() {
        return this.pageTitleEsignLanding;
    }

    static setpageTitleEsignLanding(value) {
        this.pageTitleEsignLanding = value;
    }


    static getPageTitleCompletion() {
        return this.pageTitleCompletion;
    }

    static setPageTitleCompletion(value) {
        this.pageTitleCompletion = value;
    }

    static getPageTitleMidWayCelebration() {
        return this.pageTitleMidWayCelebration;
    }

    static setPageTitleMidWayCelebration(value) {
        this.pageTitleMidWayCelebration = value;
    }


    static getPageTitleFinalCelebration() {
        return this.pageTitleFinalCelebration;
    }

    static setPageTitleFinalCelebration(value) {
        this.pageTitleFinalCelebration = value;
    }

    static getPageTitleSaveForLater() {
        return this.pageTitleSaveForLater;
    }

    static setPageTitleSaveForLater(value) {
        this.pageTitleSaveForLater = value;
    }

    // sub-product
    static getSubProduct() {
        return this.subProduct;
    }

    // sub-product
    static setSubProduct(value) {
        this.subProduct = value;
    }

    // mboxEdit -- for adobe target
    static getMboxEdit() {
        return this.mboxEdit;
    }

    // mboxEdit -- for adobe target
    static setMboxEdit(value) {
        this.mboxEdit = value;
    }

    // mboxDisable -- for adobe target
    static getMboxDisable() {
        return this.mboxDisable;
    }

    // mboxDisable -- for adobe target
    static setMboxDisable(value) {
        this.mboxDisable = value;
    }

    static getAznCode() {
        return this.aznCode;
    }
    static setAznCode(value = null) {
        this.aznCode = value;
    }

    // lang
    static getLanguage() {
        if (this.lang === undefined || this.lang === null) {
            this.lang = 'en';
        }
        return this.lang;
    }

    // lang
    static setLanguage(value) {

        if (value === null || value === undefined) {
            this.lang = 'en';
        } else {
            this.lang = value;
        }
        if(navigator.cookieEnabled)
        sessionStorage.setItem('lang', value);
    }

    // check whether sub product is AD
    static isSubProductAD(): boolean {
        return this.subProduct == 'AD' ? true : false;
    }

    static setApplicationObject(response) {
        this.applicationObject = response;
    }
    static getApplicationObject() {
        return this.applicationObject;
    }

    static hasBeneficiaryDetails() {
        return !!(this.getApplicationObject()?.beneficiaries?.length || this.getApplicationObject()?.pageStatus?.beneficiaryInformation);
    }

    static setCmsMessageObject(response) {
        this.cmsMessageObject = response;
    }
    static getCmsMessageObject() {
        return this.cmsMessageObject;
    }
    static setUserLoggedIn(val) {
        this.userLoggedIn = val;
    }
    static isUserLoggedIn() {
        return true;

    }

    static setCountriesList(list) {
        this.countriesList = list;
    }

    static getCountriesList() {
        return this.countriesList;
    }

    static isUSACitizen(citizenshipList): boolean {
        let result = [];

        result = citizenshipList.filter(function (country) {
            return country == 100001;
        });

        return (result.length == 1) ? true : false;
    }

    static isCitizen(citizenshipList, code): boolean {
        let result = [];

        result = citizenshipList.filter(function (country) {
            return country == code;
        });

        return (result.length == 1) ? true : false;
    }

    static getCountryLabel(code) {
        let label = '';
        this.getCountriesList().filter(function (country) {
            if (country.code == code) {
                label = country.label;
            }
        });

        return label;
    }
    /**
    * @description: Mapping boolean Values to radio button
    * @param: formElement
    * @return: boolean
    */
    static yesOrNoRadioMapper(formElement: any) {
        if (formElement === true) {
            return 'Yes';
        } else if (formElement === false) {
            return 'No';
        } else {
            return null;
        }
    }
    /**
    * @description: Is null or undefined value
    * @param: formElement
    * @return: boolean
    */
    static nullCheck(formElement: any) {
        if (formElement === null || formElement === undefined) {
            return false;
        } else {
            return true;
        }
    }

    /**
    * @description: Is null or undefined value
    * @param: formElement
    * @return: boolean
    */
    static noValueCheck(formElement: any) {
        if (formElement === null || formElement === undefined || formElement === '') {
            return false;
        } else {
            return true;
        }
    }


    static setPrimaryApplicant(party) {
        this.primaryApplicant = party;
    }

    static getPrimaryApplicant() {
        return this.primaryApplicant;
    }

    static setNavigationDissabled(value) {
        this.isNavigationDissabled = value;
    }
    static getNavigationDissabled() {
        return this.isNavigationDissabled;
    }

    public static setdocumentPackageStatus(value) {
        this.documentPackageStatus = value;
    }

    public static getdocumentPackageStatus() {
        return this.documentPackageStatus;
    }

    public static getProvinceLabel(provincesList, provinceCode) {

        const provinceData = provincesList.find(function (obj) {
            return obj.code === provinceCode;
        });

        return provinceData;
    }
    public static dateDifference(dateofBirth: string) {
        let now = moment(new Date()).add(1, 'days'); // todays date
        let duration = moment.duration(now.diff(dateofBirth));
        return duration.years();
    }

    public static getvURL() {

        const url = window.location.href;
        const comLocation = url.indexOf('com') + 3;
        const vURL = url.substr(comLocation);

        return vURL;
    }

    public static checkIfCountryBothCitizenAndTaxResident(citizenshipCode, taxResidencyList) {
        let result = [];

        result = taxResidencyList.filter(function (taxResident) {
            return taxResident.country == citizenshipCode;
        });

        return (result.length == 1) ? true : false;
    }

    public static ageEqualSeventyOrGreater(dateOfBirth) {
        if (this.dateDifference(dateOfBirth) >= 70) {
            return true;
        }
        return false;
    }

    public static setApplicationNumber(value) {
        this.applicationNumber = value;
    }

    public static getApplicationNumber() {
        return this.applicationNumber;
    }

    public static setBeneficiaryPageCondition(value) {
        this.beneficiaryPageCondition = value;
    }

    public static getBeneficiaryPageCondition() {
        return this.beneficiaryPageCondition;
    }

    public static setWithdrawalPageCondition(value) {
        this.withdrawalPageCondition = value;
    }

    public static getWithdrawalPageCondition() {
        return this.withdrawalPageCondition;
    }
    public static passwordVisibilityToggle(id : string) {
        let inputElement : any;
        inputElement = document.getElementById(id);
        if (inputElement.type === "password") {
            inputElement.type = "text";
        } else {
            inputElement.type = "password";
        }

    }
    public static setBackRoute(value) {
        this.backRoute = value;
    }

    public static getBackRoute() {
        return this.backRoute;
    }

    public static setIsBMONetwork(value) {
        this.isBMONetwork = value;
    }

    public static getIsBMONetwork() {
        return this.isBMONetwork;
    }
    public static setAliveCheckNetwork(value) {
        this.aliveCheckNetwork = value;
    }

    public static getAliveCheckNetwork() {
        return this.aliveCheckNetwork;
    }
    public static autoFocusFormFirstField(formName: any) {
        if(formName.invalid)
        {
          // Got focus to the first error field
        let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
          if(invalidFields[1].tabIndex === -1) { // div , app-date-picker , app-radio-card - moving focus to its first child input
           const childInvalidInputs = invalidFields[1].querySelectorAll('input');
            if(childInvalidInputs && childInvalidInputs[0]) {
                childInvalidInputs[0].focus();
            }
          } else {
              if(invalidFields[1].id == 'datePicker') { // date picker input  is valid but class name shows invalid , so
                if(invalidFields[2].localName == 'app-confirmation-yes-no') { // personal info page
                    invalidFields[3].focus();  // moving the focus to next error field
                } else{
                    invalidFields[2].focus();  // moving the focus to next error field
                }

              } else {
                invalidFields[1].focus();  // focus to first error field
              }

          }
        }
    }

    public static Detect_IE() {
        var ua = window.navigator.userAgent;
        return ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0 || false;
    }

    public static getValues(data){
        if(this.Detect_IE()){
            const values = [];
            for(let key in data) {
                if(data.hasOwnProperty(key)) {
                    values.push(data[key]);
                }
            }
            return values;
        }else{
            return Object.values(data);
        }
    }

    static isCIROEnabled() {
        return Utils.getCmsMessageObject()?.featureFlags?.isCIROEnabled;
     }

    public static setIsSDOwner(value: boolean){
        this.isSDOwner = value;
    }

    public static getIsSDOwner(){
        return this.isSDOwner;
    }


}
