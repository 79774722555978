import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {  NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-checkbox-card',
  templateUrl: './checkbox-card.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CheckboxCardComponent implements OnInit  {

  @Input() options: any;
  @Input() optionsType: any;
  @Input() section: any;
  @Input() name: any;
  @Input() imageSource: any;
  @Input() show: any;
  @Input() form: NgForm;
  @Input() errorMessage: any;
  @Input() isError: any;
  @Input() hide: any;
  @Input() selected: any;
  @Input() isFormSubmitted: any;
  @Input() accessErrorId: any;
  @Input() isRequired: Boolean = true;
  @Input() dataAna ?: any;
  @Output() selectedValueEvent= new EventEmitter();

  public selectedAlternativeInvestments: Boolean;
  public validationData: any;
  
  public selectedOptionsObject = {};


  constructor() { }

  ngOnInit() {
    this.setupListOfOptions();
    this.isError = (this.selected.length <= 0) ? true : false;
    this.isRequired = (this.selected.length <= 0 ) ? true : false;

  }
  onToggle(event) {
    let optionCode = event.target.value;

    // if user selects NONE, then deselect all other checkboxes
    this.checkedValue(optionCode);
    this.checkForNoneValue();
    this.isError = (this.selected.length <= 0 ) ? true : false;
    this.isRequired = (this.selected.length <= 0 ) ? true : false;
    this.selectedValueEvent.emit(optionCode);
}
setupListOfOptions() {
  this.selected = this.selected || [];
  this.selectedOptionsObject = [];
  this.selected.forEach((val) => {
      this.selectedOptionsObject[val] = true;
  });
}

 // to add/remove elements
 checkedValue(optionCode) {
  if (this.selectedOptionsObject[optionCode] && this.selected.indexOf(optionCode) <= -1) { // add
      this.selected.push(optionCode);

  } else if (!this.selectedOptionsObject[optionCode] && this.selected.indexOf(optionCode) > -1) {
      this.selected.splice(this.selected.indexOf(optionCode), 1);
  }
}

checkForNoneValue() {

}

}
