import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormService } from '../../shared/service/form.service';
import { ApplicationService } from '../../shared/service/application.service';
import { AccountTypeService } from '../../shared/service/account-type.service';
import { Subject, Subscription } from 'rxjs';
import { APP_ROUTES, MODEL, SPACE } from '../../config/app-config.constants';
import { DropDownMenuComponent } from '../../shared/components/drop-down-menu/drop-down-menu.component'
import { Utils } from '../../shared/utils/utils.class';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { NavigationExtras, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CdbOlbService } from '../../shared/service/cdb-olb.service';
export class ProgressInfo {

    constructor(public totalSteps: number,
        public currentStep: number,
        public showProgressBar: Boolean,
        public pageName: String) {

        this.totalSteps = this.totalSteps || 14;
        this.currentStep = this.currentStep || 1;
        this.showProgressBar = this.showProgressBar || false;
    }

    get progress() {
        return (this.currentStep / this.totalSteps) * 100;
    }
}

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html'
})

export class HeaderComponent implements OnInit {
    public resume;
    public showResume = true;
    public signOut;
    public showSignOut = false;
    public accountType = '';
    public resumeApplication;
    public showNav = false;
    public showHelp = true;
    public showHelpSubscriber: Subscription;
    stepInfo: any;
    clientFirstName: string = "";
    isSubProductAD: any;
    isIEBrowser: boolean;
    isBranchAssisted: boolean;
    public isCookieEnabled: boolean;
    public isCdbOlbUser: boolean = false;
    public showCdbOlbNav: boolean = false;
    public isJourneyUser: boolean = false;
    public isSDOwner: boolean = false;
    backRoute: any;


    constructor(public applicationService: ApplicationService,
        private translateService: TranslateService,
        private windowref: WindowRefService,
        private configService: ConfigResolverService<any>,
        private accountTypeService: AccountTypeService,
        private router: Router,
        public translate: TranslateService,
        private formService : FormService  ,
        public cdbOlbService : CdbOlbService) {
    }

    @Input('stepInfo') set setStepInfo(stepInfo: ProgressInfo) {

        if (stepInfo) {
            this.stepInfo = new ProgressInfo(
                stepInfo.totalSteps,
                stepInfo.currentStep,
                stepInfo.showProgressBar,
                stepInfo.pageName);
        } else {
            this.stepInfo = new ProgressInfo(0, 0, false, '');
        }
    }


    ngOnInit() {
        // this.accountTypeService.getSelectedAccount();
        // this.accountTypeService.accountType.subscribe(data => {
        //     this.accountType = data;
        //     this.translateService.get(this.accountType).subscribe(
        //         translation => {
        //             this.accountTypeService.accountTypeTitle = translation;
        //         });
        // });

        this.isIEBrowser = this.windowref.isIEBrowser();
        this.isCookieEnabled = navigator.cookieEnabled;

        this.isBranchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;

        this.resume = this.applicationService.getResumeLink().subscribe(item => {
            this.showResume = item; // show or hide Resume application link
        });

        this.signOut = this.applicationService.getSignOutLink().subscribe(item => {
            this.showSignOut = item; // show or hide signOut application link
        });
        this.isSubProductAD = Utils.isSubProductAD();
        this.showHelpSubscriber = this.applicationService.showHelp$.subscribe(item => {
            this.showHelp = item;
        })
        this.getOLBUser();
        this.getJourneyUser();
    }

    getOLBUser() {
        this.cdbOlbService.oLBUser.subscribe(firstName => {
            this.isCdbOlbUser = Utils.getIsOLBUser();
            this.clientFirstName = firstName;
        });
    }

    getJourneyUser() {
         this.applicationService.isJourneysUser$.subscribe(firstName => {
            this.isJourneyUser = Utils.getIsJourneyUser();
            this.isSDOwner = Utils.getIsSDOwner();
        });
    }

    cdbOlbNavToggle() {
        this.showCdbOlbNav = true;
    }

    openCdbOlbNav($event) {
      if ($event.code === 'Space') {
        this.showCdbOlbNav = true;
        $event.preventDefault();
      }
    }

   navToggle(): void {
        this.showNav = true;
        this.applicationService.showHowADworks$.next(false)
   }
    showResumeApplication() {
        this.applicationService.openResumeApplicationModel();
        this.resumeApplication = true;
        this.applicationService.showHowADworks$.next(false);
        this.applicationService.resumeAppID = 'resumeApplicationID';
    }
    hideResumeApplication() {
        this.resumeApplication = false;
    }

    showHelpMenu($event) {
        if ($event.code == 'Space') {
            this.showNav = true;
            $event.preventDefault();
        }
    }

    onSignOutClick() {
        this.applicationService.openModel(true,MODEL.signOut);
        this.applicationService.signOutClicked.emit(true);
    }

    // CALL THIS METHOD FOR LANGUAGE CHANGE FUNCTIONALITY
    changeLanguage(lang){
        let navParameter: NavigationExtras = {};
        let _subProductADC = Utils.getSubProduct();

        if (Utils.isSubProductAD()) {
            navParameter = {
                'queryParams': {
                    'lang': lang,
                    'subProduct': _subProductADC
                }
            };
        } else {
            navParameter = {
                'queryParams': {
                    'lang': lang
                }
            };
        }

      Utils.setLanguage(lang);
      this.translate.use(lang);
      this.applicationService.getReferences(lang);
      this.windowref.nativeWindow.OneTrust.changeLanguage(lang);
      this.applicationService.saveAndcontinue(null,APP_ROUTES.set_expectation);
      this.applicationService.setResumeLink(true);
      const promoCode = this.formService.getPayload().accounts[0].promoCode;
      if (promoCode && promoCode !== null && promoCode !== undefined ) {
        this.applicationService.applyPromoCode(lang, promoCode)
        .then((res: any) => {
            Utils.tncDocName = res.termConditionlink;
        })
      }
    }


    get langToggle() {
        return this.router.url.replace('/', '').split('?')[0] === APP_ROUTES.set_expectation;
    }
    onBack() {
        if(this.router.url.replace('/', '').split('?')[0] === APP_ROUTES.select_account) {
            this.backRoute = APP_ROUTES.set_expectation;
         } else {
            this.backRoute = Utils.getBackRoute();
         }
        if (this.formService.getActiveForm().valid || !this.formService.getActiveForm(false).isCompleted) {
            if (this.formService.getActiveComponent() !== undefined &&
                typeof this.formService.getActiveComponent().resetComponentObjectAttr === 'function') {
                this.formService.getActiveComponent().resetComponentObjectAttr();
            }
            if (this.backRoute === APP_ROUTES.select_account) {
            Utils.setIsSelectAccountClicked(true);
            Utils.backToSelectAccountPage = true;
            }
            this.applicationService.saveAndcontinue(null, this.backRoute);
        } else {
            this.formService.getActiveComponent().isFormSubmit = true;
        }
    }
}


