import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    UntypedFormControl,
    ValidatorFn,
    Validator, AbstractControl
} from '@angular/forms';

import { Directive } from '@angular/core';

@Directive({
    selector: '[appPasswordValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PasswordValidator,
            multi: true
        }
    ]
})
export class PasswordValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = this.passwordValidator();
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    passwordValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return {lengthMustBeGreaterThanFive: true,
                    noDigitsPresent: true,
                    noLettersPresent: true,
                    noSpecialCharactersAllowed: true,
                    };
            }
            const errors = {};
            const _password = control.value; // don't care about

            // length must be greater than five
            if (_password.length < 6) {
                errors['lengthMustBeGreaterThanFive'] = true;
            }

            // at least 1 number is required
            if (_password.replace(/\D+/g, '').length < 1) {
                errors['noDigitsPresent'] = true;
            }

            // at least 1 letter is required
            if (!_password.match(/[a-zA-Z]+/g)) {
                errors['noLettersPresent'] = true;
            }

            // no special characters or spaces are allowed
            if (_password.replace(/[a-zA-Z0-9]/g, '').length > 0) {
                errors['noSpecialCharactersAllowed'] = true;
            }
            return errors;
        };
    }
}
