import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Jquery } from '../../utils/jquery.class';
import * as $ from 'jquery';
import { ApplicationService } from '../../service/application.service';
import { AnalyticsService } from '../../service/analytics.service';
import { ANALYTICS } from '../../../config/app-config.constants';

@Component({
  selector: 'app-how-adworks-model',
  templateUrl: './how-adworks-model.component.html'
})
export class HowADWorksModelComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('showHowADWorks', { static: false }) showHowADWorks: ElementRef;

  constructor(
    private applicationService: ApplicationService,
    private analytics: AnalyticsService
  ) { }

  ngAfterViewInit(): void {
    $('.modal-overlay').show();
    $('.ad-works-modal').show();
    Jquery.modalFocusTrap('ad-works-modal');
    Jquery.modalScrollHeight();
    // this.showHowADWorks.nativeElement.focus()
  }

  ngOnInit(): void {
    this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.HOW_AD_WORKS);

  }

  hideAdWorks() {
    $('.ad-works-modal').hide();
    $('.modal-overlay').hide();
    this.applicationService.showHowADworks$.next(false);
  }

  ngOnDestroy(): void {
    Jquery.modalDissableFocusTrap('ad-works-modal');
    this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.HOW_AD_WORKS);
  }

  
  // @HostListener('document:keydown', ['$event'])
  // @HostListener('document:mousedown', ['$event'])
  // onKeyDown(evt: any) {
  //     if (
  //         evt.keyCode === 27 || 
  //         !this.showHowADWorks.nativeElement.contains(evt.target) 
  //     ) {
  //       this.hideAdWorks();
  //     }
  // }
}
