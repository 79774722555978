import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Utils } from '../utils/utils.class';

@Injectable()

export class CanActivateGuard implements CanActivate {
  canActivate() {
    return Utils.accountSelected;
  }
  
}
