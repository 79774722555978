import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FloodLightConfig, CustomKeys } from './flood-light.constants';
import { ApplicationService } from '../../service/application.service';
import { AccountTypeService } from '../../service/account-type.service';
import { ACCOUNT_TYPE } from '../../../config/app-config.constants';

@Component({
    selector: 'app-flood-light',
    templateUrl: './flood-light.component.html'
})
export class FloodLightComponent implements OnInit, AfterViewInit {

    private ordinate: string;
    public show = true;
    public constructedUrl: SafeUrl;

    constructor(private sanitizer: DomSanitizer,
                private route: ActivatedRoute,
                private appService: ApplicationService,
                private accountService: AccountTypeService) { }


    // The ordinate has to be initialized only once per page and this has to remain
    // unique for all the calls that originate out of a particular page, so that google add service
    // can relate to it..
    ngOnInit() {
        this.ordinate = this.getOrdinate();
        this.getUrl(false);
    }


    // Random value that will get attached to ord parameter when the URL is generated
    // for the non "<noscript>" block
    private getOrdinate(): string  {

        const axel: any = Math.random() + '';
        const oridnate: any = axel * 10000000000000;

        return oridnate;
    }


    // As the getUrl is invoked serveral times on the page, sending numerous calls
    // this helps in turning off floodlight calls to two calls a page...
    ngAfterViewInit() {
        setTimeout( () => {
            this.show = false;
        }, 1300);
    }


    // Invoked twice in a page, one with isNoScriptUrl on which produces ord=1? and another with
    // isNoScriptUrl off, which attaches a random number for ord(ordinate) like ord=234342323232.343?
    // return type is SafeUrl, as this is to disable angular's Cross Site Scripting Security bugs (XSS)
    // sanitization..

    // isNoScriptUrl will always be false, as the corresponding code is commented out...
    getUrl(isNoScriptUrl: boolean): void {

        // to hold the final URL to be generated with all parameters to be passed
        let url: string;

        // Retrieve the page and use that to get the settings of
        // that particular page.
        const uiState: string = this.route.snapshot.url &&
                                this.route.snapshot.url.length > 0 &&
                                this.route.snapshot.url[0].path;

        const config = uiState && FloodLightConfig.uiStates[uiState];

        if (config) {

            // First grap the master URL, which contains the static part
            url = FloodLightConfig.masterURL;

            // Category code  ( this is defult for all URLs)
            url = url.replace(CustomKeys.CategoryCode, config.lang[this.appService.getCurrentLanguage()]);

            // Application Id
            // u18=[account Type];  eg u18=TFSA
            // append any user specific values, if any. All pages after next-steps will have to
            let accountType = '';
            if (this.accountService &&
                this.accountService.selectedAccount) {
                    accountType = this.accountService.selectedAccount.id;
            }

            // Application Id = Account Number
            // u4=[AppID];
            // append any user specific values, if any. All pages after next-steps will have to
            let accountNumber = '';
            if (this.appService.appRetrieve &&
                this.appService.appRetrieve.accountNumber) {
                    accountNumber = this.appService.appRetrieve.accountNumber;

                    if (!accountType &&
                        this.appService.appRetrieve.accountType) {
                         accountType = ACCOUNT_TYPE[this.appService.appRetrieve.accountType];
                    }
            }

            url = url.replace(CustomKeys.ProductCode, accountType);
            url = url.replace(CustomKeys.ApplicationId, accountNumber);


            // ordinate (random number or 1)
            // inside <noscript> block, the ordinate should always be 1
            url += 'ord=' + (isNoScriptUrl ? '1' : this.ordinate) + '?';
        }

        // to disable angular's built-in sanitization..
        // bypassSecurityTrustResourceUrl is used.
        this.constructedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
