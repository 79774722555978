import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { ApplicationService } from '../../shared/service/application.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormService } from '../../shared/service/form.service';
import { SUCCESS_RESPONSE, APP_ROUTES, ANALYTICS } from '../../config/app-config.constants';
import { Utils } from '../../shared/utils/utils.class';
import { PrefillService } from '../../shared/service/prefill.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { Title } from '@angular/platform-browser';
export class PasswordObj {
  password = null;
  confirmPassword = null;
}
@Component({
  selector: 'app-branch-save-progress',
  templateUrl: './branch-save-progress.component.html'
})

export class BranchSaveProgressComponent implements OnInit, AfterViewInit {

  public passwordObj = new PasswordObj();

  public isFormSubmit = false;
  public criticalError: Boolean = false;
  private config: any = null;

  public appNumber: any;
  public url;
  public passwordHide: Boolean = true;

  @ViewChild('branchSaveProgressForm', { static: true }) branchSaveProgressForm: NgForm;

  constructor(public applicationService: ApplicationService, public formService: FormService,
    private translate: TranslateService, private http: HttpClient,
    private configService: ConfigResolverService<any>,
    private titleService: Title,
    private analytics: AnalyticsService,
    private winRef: WindowRefService,
    private prefillService: PrefillService) {
    this.url = '/' + APP_ROUTES.branch_save_progress;
  }

  ngOnInit() {
    /**Title */
    this.applicationService.pageTitles();
    this.titleService.setTitle(Utils.getPageTitleSaveForLater());

    this.appNumber = Utils.getApplicationNumber();

    // Hide sign-out button in this page
    this.applicationService.setSignOutLink(false);
    setTimeout(() => {
      this.setGTM();
    }, 200);
  }

  ngAfterViewInit() {
    this.analytics.setPageState(this.url);
  }

  isError(ctrl: any) {
    return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
  }

  setGTM() {

    const gtmDataLayerForAccountDetails: GTMDataLayer = {
      'event': ANALYTICS.BMOEVENT,
      'vURL': Utils.getvURL(),
      'vPageTitles': Utils.getPageTitleSaveForLater(),
      'category': ANALYTICS.category,
      'action': ANALYTICS.ACTIONS.SAVE_LATER,
      'label': ANALYTICS.label,
      'flow': ANALYTICS.flow,
      'prodName': Utils.accountName,
      'prodNumber': Utils.accountNumber,
      'prodTotalNumber': 1,
      'originalLocation': document.location.protocol + '//' + document.location.hostname +
        document.location.pathname + document.location.search,
      'language': Utils.getLanguage(),
      's_PPvid': ANALYTICS.save_later,
      'applicationId': '',
      'signerId': '',
      'packageId': '',
      'assisetdApp': false,
      'customerSource': Utils.getSubProduct(),
      'netWorth': Utils.netWorth
    };

    this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);

  }

  onSaveApplication() {
    this.isFormSubmit = true;

    if (this.branchSaveProgressForm.valid) {

      this.config = this.configService.getConfig();

      // URL
      const accountSetupURL = this.config.CHANNELS_API.CONTEXT_ROOT + this.config.CHANNELS_API.ACCOUNTSETUP_URI;

      // Headers
      let headers = this.config.CHANNELS_API.ACCOUNTSETUP_HEADER;
      // headers['workflow_id'] = localStorage.getItem('appId');
      headers = new HttpHeaders(headers);

      // Payload
      const payload = { password: this.passwordObj.password };

      // HTTP Call
      this.http.post(accountSetupURL, payload, { headers: headers, observe: 'response' })
        .toPromise()
        .then(response => {

          if (response.status === SUCCESS_RESPONSE) {

            const isUserInSession: boolean = JSON.parse(response.headers.get('isinsession'));

            // Set 'IsInSession' value
            if (isUserInSession === true) {
              Utils.setIsInSession(true);
              Utils.isSessionInitiated = true;
            } else if (isUserInSession === false) {
              Utils.setIsInSession(false);
              Utils.isSessionInitiated = false;
            }

            // show sign-out button
            this.applicationService.setSignOutLink(true);  // show sign out as user is logged in now

            // Hide Finish later button
            this.applicationService.setFinishLater(false); // hide finish later now as user is in session

            /**Prefill following pages */

            // PERSONAL
            this.prefillService.personalInfoPrefill();

            // CONTACT
            this.prefillService.contactInfoPrefill();

            // FAMILY
            this.prefillService.FamilyInfoPrefill();

            // Re-route to personal-info page
            this.applicationService.save(this.formService.getPayload())
            .then(res => {
              this.applicationService.saveAndcontinue(this.branchSaveProgressForm, APP_ROUTES.personal_information);
            }).catch(err => {});
          } else {
            this.criticalError = true;
          }

        })
        .catch(e => {
          this.analytics.setapiCallDetails({
            apiCallMade: 'true', apiCallType: "branchSave",
            apiCallResponseCode: e.error?.errorInfo ? e.status?.toString() + "-" + e.error?.errorInfo.errorCode.toString() : e.status?.toString()

          });
          this.criticalError = true;
        });

    }

  }

  togglePasswordVisibility(createPassword: string, confirmPassword: string) {
    this.passwordHide = !this.passwordHide;
    Utils.passwordVisibilityToggle(createPassword);
    Utils.passwordVisibilityToggle(confirmPassword);
  }

}
