import { ElementRef, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})

// usage  <input [value] = "nameDetails.value.firstName | capitalize" />
// if used as "<text | capitalize:{capitalizeOnce:formService.forms.familyInformation.data?false:true}>"
// "formService.forms.familyInformation.data" is used to let pipe know the value is already
// there in the input field and it should be dispalyed without any change when user returning back to page
// if used without argument like "<text  | capitalize>" it will capitalize only the first letter of first word e.g hello test - Hello test
export class CapitalizePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let capitalizeValue: string;

        if (!value) {
            return '';
        }

        if (args) {
            if (args.capitalizeOnce) {
                capitalizeValue = value.charAt(0).toUpperCase() + value.slice(1);
                args.capitalizeOnce = false;
            } else {
                capitalizeValue = value;
            }
        } else {
            capitalizeValue = value.charAt(0).toUpperCase() + value.slice(1);
        }

        return capitalizeValue;
    }

}
