import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from './../../../shared/service/application.service';
import { FormControl, NgForm, ControlContainer } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BENEFICIARY_RELATIONSHIP_DROPDOWN_EN, BENEFICIARY_RELATIONSHIP_DROPDOWN_FR } from '../../../../app/config/app-config.constants';
@Component({
    selector: 'app-beneficiary-information',
    templateUrl: './beneficiary-information.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class BeneficiaryInformationComponent implements OnInit {

    @Input() index: any;
    @Input() beneficiariesList: any;
    @Input() form: NgForm;
    @Input() isFormSubmit: Boolean;

    @Input() disablePercentageField: Boolean;

    @Output() public invalidTotalValue = new EventEmitter<Boolean>();


    public beneficiaryObj: any;

    public relationships: any = '';
    public relationshipStatus = null;

    public isReferenceCallFailed: Boolean = false;

    constructor(private applicationService: ApplicationService,
        private route: ActivatedRoute) {
            console.log('BENE CHILD CONSTRUCTOR FIRST CALLED')
    }

    ngOnInit() {
        console.log('BENE CHILD oninit FIRST CALLED')
        // current beneficiary obj
        this.beneficiaryObj = this.beneficiariesList[this.index];

        // References
        if (this.applicationService.isCurrentLangFrench()) {
            this.relationships = BENEFICIARY_RELATIONSHIP_DROPDOWN_FR;
        } else { // default is English
            this.relationships = BENEFICIARY_RELATIONSHIP_DROPDOWN_EN;
        }
        console.log('BENE CHILD oninit LAST CALLED')
    }

    isError(ctrl: any) {
        return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors)) && (!(ctrl.touched && ctrl.errors.minLengthError));
    }

    setInvalidTotal() {
        let total = 0;

        /**Check if total is 100% else throw error.*/
        for (let i = 0; i < this.beneficiariesList.length; i++) {
            total += parseFloat(this.beneficiariesList[i].entitlementPercentage);
        }

        if (total !== 100) {
            this.invalidTotalValue.emit(true);
        } else {
            this.invalidTotalValue.emit(false);
        }
    }

    updateRelationship(e) {
        this.relationships.forEach(item => {
            if (item.code === e.srcElement.value) {
                this.relationshipStatus = item.label;
            }
        });
    }

}
