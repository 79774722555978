import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigResolverService } from '../../../config/external-config/service/config-resolver.service';
import { ApplicationService } from '../../service/application.service';
import { Jquery } from '../../utils/jquery.class';
import { Utils } from '../../utils/utils.class';

@Component({
  selector: 'app-other-accounts-model',
  templateUrl: './other-accounts-model.component.html'
})
export class OtherAccountsModelComponent implements OnInit, AfterViewInit, OnDestroy {


  // @ViewChild('OlAPForm', { static: true }) OLAPForm;
  @ViewChild('otherAccounts', { static: true }) otherAccounts;

  public OLAPData: String;
  public OLAPFormUrl: String;
  private OLAPUrl_en = '';
  private OLAPUrl_fr = '';
  private config = null;
  branchAssisted: boolean;
  isCurrentLangEnglish: boolean;

  showOtherAccountsModel: boolean;
  otherAccountsModelSubscriber: Subscription;

  constructor(
    private applicationService: ApplicationService,
    private configService: ConfigResolverService<any>,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.config = this.configService.getConfig();
    this.branchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
    this.isCurrentLangEnglish = this.translateService.currentLang === 'en' ? true : false;

    if (this.branchAssisted) {
      const appObject = Utils.getApplicationObject();
      this.OLAPData = (appObject) ? appObject.olapPayload : '';
      this.OLAPData = (this.OLAPData || '').replace('<![CDATA[', '').replace(']]>', '');
    }

    if (this.config !== null) {
      this.OLAPUrl_en = this.config.ILOLAPWEB_EN;
      this.OLAPUrl_fr = this.config.ILOLAPWEB_FR;
      this.OLAPFormUrl = this.config.OLAP_ENDPOINT;
    }

    this.otherAccountsModelSubscriber = this.applicationService.showOtherAccountsModel$.subscribe((value) => {
      this.showOtherAccountsModel = value
      console.log('in otherAccountsModelSubscriber');
    });

  }

  ngAfterViewInit(): void {
    $('.modal-overlay').show();
    $('.bmo-other-accounts').show();
    Jquery.modalFocusTrap('bmo-other-accounts');
    Jquery.modalScrollHeight();
  }

  hideBmoOtherAccounts() {
      setTimeout(() => {
      this.applicationService.showOtherAccountsModel$.next(false);
    }, 250);
    $('#showBmoOtherAccountsID').focus();
  }

  // LetsGo() {

  //   if (this.branchAssisted) {
  //     this.OLAPForm.nativeElement.submit();
  //   } else {

  //     const olapURL = (this.isCurrentLangEnglish) ?
  //       this.OLAPUrl_en
  //       : this.OLAPUrl_fr;
  //     window.open(olapURL, 'self');
  //   }
  // }


  ngOnDestroy(): void {
    $('.modal-overlay').hide();
    $('.bmo-other-accounts').hide();
    Jquery.modalDissableFocusTrap('bmo-other-accounts');
  }

  // @HostListener('document:keydown', ['$event']) 
  @HostListener('document:mousedown', ['$event'])
  onKeyDown(evt: any) {
    if (
      evt.keyCode === 27 ||
      this.showOtherAccountsModel === true &&
      this.otherAccounts &&
      !this.otherAccounts.nativeElement.contains(evt.target)
    ) {
      //this.showNav = false;
      this.hideBmoOtherAccounts();
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyPress(event: KeyboardEvent) {
      if(event.code=="Escape" || event.code == "Enter" || event.code == "Space"){
        this.hideBmoOtherAccounts();
      }
  }
}
