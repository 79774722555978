import { Component, OnInit } from '@angular/core';
import { Jquery } from '../../shared/utils/jquery.class';

@Component({
  selector: 'app-no-nav',
  templateUrl: './no-nav.component.html'
})
export class NoNavComponent implements OnInit {
  isCookieEnabled: boolean;

  constructor() { }

  ngOnInit() {
    this.isCookieEnabled = navigator.cookieEnabled;
  }

  routeChanges() {
    Jquery.run();
  }

  continue(){
   if (navigator.cookieEnabled) {
    window.location.reload();
    }
    
  }

}
