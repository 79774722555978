import { Jquery } from './../../utils/jquery.class';
import { Component, OnInit ,Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login-success-popup',
  templateUrl: './login-success-popup.component.html'
})
export class LoginSuccessPopupComponent implements OnInit {

 @Input() show: any;
 @Output() close = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  closeModel() {
  this.close.emit(null);
   Jquery.modalDissableFocusTrap();
  }

}
