import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-personal-banker-and-financial-planner',
  templateUrl: './personal-banker-and-financial-planner.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class PersonalBankerAndFinancialPlannerComponent implements OnInit {

  @Input() branchReferralObj: any;
  @Input() isFormSubmit: boolean;
  @Input() form: NgForm;

  constructor() { }

  ngOnInit() {
  }

  isError(ctrl: any) {
    return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
  }

}
