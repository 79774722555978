<div class="alert alert-danger" role="alert">
    <p>
        <strong translate="appErrors.criticalErrorDescriptionResumeTitle"></strong><br />
        <span translate="appErrors.criticalErrorDescriptionResume"></span>
        <!-- MWB-12258 & MWB-12257
            <span *ngIf="!isBranchAssisted"> 
            <span translate="appErrors.criticalErrorDescription1"></span>
            <a  href="javascript://" translate="appErrors.criticalErrorDescription2" (click)= "criticalError()"></a>
            <span translate="appErrors.criticalErrorDescription3"></span>
        </span> -->
        <!-- <span *ngIf="isBranchAssisted">
            <span translate="appErrors.criticalErrorDescriptionBA1"></span>
            <a  href="javascript://" translate="appErrors.criticalErrorDescription2" (click)= "criticalError()"></a>
            <span translate="appErrors.criticalErrorDescriptionBA3"></span>
        </span> -->
    </p>
</div>
