import { Component, OnInit, ViewChild } from '@angular/core';
import { APP_ROUTES, URL, RADIO_TYPES, SUCCESS_RESPONSE, ANALYTICS } from '../../config/app-config.constants';
import { Title } from '@angular/platform-browser';
import { FormService } from '../../shared/service/form.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { Utils } from '../../shared/utils/utils.class';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { NgForm, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

export class RiskAndReturn {
  riskTolerance: null;
  riskReturnInvestment: null;
}

@Component({
  selector: 'app-risk-and-return',
  templateUrl: './risk-and-return.component.html'
})
export class RiskAndReturnComponent implements OnInit {

  public party;
  public account;
  public isFormSubmit: boolean = false;
  public APP_ROUTES = APP_ROUTES;
  public isCurrentLangFrench: boolean = false;
  public url: any;
  public isCurrentLangEnglish: boolean = false;
  public criticalError: boolean = false;
  // public isCompleted: boolean = false;
  public showFinishLater: any;
  public references: any;
  public volatilityRiskQuestionOptions: any;
  public riskAndReturn: RiskAndReturn;
  public RADIO_TYPES: any;
  public idealRiskReturnOptions: any;
  private finishLaterSubscriber: Subscription = new Subscription();
  private getFLSubscriber: Subscription = new Subscription();
  public appNumber;
  private onExitApplicationPerformSaveSubscriber;
  @ViewChild('riskAndReturnForm', { static: true }) riskAndReturnForm: NgForm;

  constructor(public formService: FormService,
    private route: ActivatedRoute,
    private titleService: Title,
    private analytics: AnalyticsService,
    private winRef: WindowRefService,
    private applicationService: ApplicationService) {

    this.formService.forms.riskAndReturn.isVisited = true;
    this.formService.setActiveComponent('riskAndReturn', this);

    if (Utils.getLanguage() == 'fr') {
      this.isCurrentLangFrench = true;
    } else {
      this.isCurrentLangEnglish = true;
    }

    this.url = URL.RISK_AND_RETURN;
  }


  ngOnInit(): void {

    if (!Utils.getIsBranchAssisted()) {
      if (Utils.getIsInSession()) {
        this.applicationService.setSignOutLink(true);
        this.showFinishLater = false;
      } else {
        this.applicationService.setSignOutLink(false);
        this.showFinishLater = true;
      }
    } else {
      this.applicationService.setSignOutLink(true);
      this.showFinishLater = false;
    }

    this.riskAndReturn = new RiskAndReturn();
    this.RADIO_TYPES = RADIO_TYPES;

    this.formService.forms.riskAndReturn.isVisited = true;
    this.titleService.setTitle(Utils.getPageTitleRiskAndReturn());

    this.applicationService.fetchReferences(this.route);

    if (this.applicationService.appReferences !== undefined) {
      this.references = this.applicationService.appReferences.references;
    }
    this.volatilityRiskQuestionOptions = [{
      code: '1',
      id: 'volatilityRiskQuiz1',
      label: 'riskAndReturn.volatilityQuizOptions.significantLoss',
      description: 'riskAndReturn.volatilityQuizOptions.significantLossDesc',
      imageUrl: 'assets/img/riskReturn1.svg'
    },
    {
      code: '2',
      id: 'volatilityRiskQuiz2',
      label: 'riskAndReturn.volatilityQuizOptions.littleConcern',
      description: 'riskAndReturn.volatilityQuizOptions.littleConcernDesc',
      imageUrl: 'assets/img/riskReturn3.svg'
    }, {
      code: '3',
      id: 'volatilityRiskQuiz3',
      label: 'riskAndReturn.volatilityQuizOptions.thatsFine',
      description: 'riskAndReturn.volatilityQuizOptions.thatsFineDesc',
      imageUrl: 'assets/img/riskReturn2.svg'
    },
    {
      code: '4',
      id: 'volatilityRiskQuiz4',
      label: 'riskAndReturn.volatilityQuizOptions.thatsGreat',
      description: 'riskAndReturn.volatilityQuizOptions.thatsGreatDesc',
      imageUrl: 'assets/img/riskReturn4.svg'
    }];

    this.idealRiskReturnOptions = [{
      code: '1',
      id: 'idealRiskReturnOption1',
      label: 'riskAndReturn.idealRiskReturnOptions.emphasisLowRisk',
      description: 'riskAndReturn.idealRiskReturnOptions.emphasisLowRiskDesc',
      imageUrl: 'assets/img/riskReturn5.svg'
    },
    {
      code: '2',
      id: 'idealRiskReturnOption2',
      label: 'riskAndReturn.idealRiskReturnOptions.balanceRiskAndReturn',
      description: 'riskAndReturn.idealRiskReturnOptions.balanceRiskAndReturnDesc',
      imageUrl: 'assets/img/riskReturn7.svg'
    },
    {
      code: '3',
      id: 'idealRiskReturnOption3',
      label: 'riskAndReturn.idealRiskReturnOptions.moreEmphasisReturns',
      description: 'riskAndReturn.idealRiskReturnOptions.moreEmphasisReturnsDesc',
      imageUrl: 'assets/img/riskReturn6.svg'
    },
    {
      code: '4',
      id: 'idealRiskReturnOption4',
      label: 'riskAndReturn.idealRiskReturnOptions.focusedOnReturn',
      description: 'riskAndReturn.idealRiskReturnOptions.focusOnReturnDesc',
      imageUrl: 'assets/img/riskReturn8.svg'
    }];

    /**Finish later & Sign-out */
    this.finishLaterSubscriber = this.applicationService.getPartialSave()
      .subscribe(item => {
        if (item === 'investmentObjectivesFrm') {
          this.onFinishLater();
        }
      });
      this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
        if (value) {
            this.onFinishLater(true);
        }
    });
    /*set active form*/
    this.formService.setActiveForm(this.riskAndReturnForm);
    this.formService.forms.riskAndReturn.isVisited = true;
    this.party = this.formService.getPrimaryApplicant();
    this.account = this.formService.getPrimaryAccount();

    /** To perform save operation on signout*/
    this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
      if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
        Utils.setsaveDoneOnSignOut(true);
        this.saveOnSignOut();
      }
    });

    // user has successfully set up account so unsubscribe
    this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
      if (item === false) {
        this.finishLaterSubscriber.unsubscribe();
      }
    });
    // already visited form and now visiting back again
    if (this.formService.forms.riskAndReturn.data != null) {
      this.riskAndReturn = this.formService.getFormData('riskAndReturn');

    } else {
      this.riskAndReturn = new RiskAndReturn();  // coming to form for the first time
    }

    setTimeout(() => {
      this.setGTM();
    }, 200);
  }


  saveOnSignOut() {

    this.formService.forms.riskAndReturn.isCompleted = true;
    this.formService.setFormData('riskAndReturn', this.riskAndReturn);

    const personalInformation = this.formService.getFormData('personalInformation');
    const contactInformation = this.formService.getFormData('contactInformation');
    const employmentInformation = this.formService.getFormData('employmentInformation');
    const familyInformation = this.formService.getFormData('familyInformation');
    const financialInformation = this.formService.getFormData('financialInformation');
    const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
    const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

    this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
    this.formService.getPayload().setPersonalInformation(personalInformation);
    this.formService.getPayload().setContactInformation(contactInformation);
    this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
    this.formService.getPayload().setFamilyInformation(familyInformation);
    this.formService.getPayload().setFinancialInformation(financialInformation);
    this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);

    // save current page if form is valid
    if (!this.formService.forms.riskAndReturn.isCompleted) {
      const emptyRiskAndReturn = new RiskAndReturn();
      this.formService.getPayload().setRiskAndReturn(emptyRiskAndReturn);
    } else {
      if (this.riskAndReturnForm.valid) {
        this.formService.getPayload().setRiskAndReturn(this.riskAndReturn);
      }
    }

    this.applicationService.save(this.formService.getPayload())
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          const saveResponse = res.body;
          this.applicationService.applicationNumber = saveResponse.applicationNumber;
          this.appNumber = saveResponse.applicationNumber;

          this.applicationService.saveOperationPerformed.emit(true);
        } else {
          this.criticalError = true;
        }
      })
      .catch(err => {
        this.criticalError = true; // show error when save fails on top of page
      });
  }


  onFinishLater(isExitModal = false) {

    if (!this.riskAndReturnForm.valid && isExitModal) {
        this.applicationService.setExitApplicationModal(true);
    }
    this.formService.forms.riskAndReturn.isCompleted = true;
    this.formService.setFormData('riskAndReturn', this.riskAndReturn);

    const personalInformation = this.formService.getFormData('personalInformation');
    const contactInformation = this.formService.getFormData('contactInformation');
    const employmentInformation = this.formService.getFormData('employmentInformation');
    const familyInformation = this.formService.getFormData('familyInformation');
    const financialInformation = this.formService.getFormData('financialInformation');
    const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
    const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

    this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
    this.formService.getPayload().setPersonalInformation(personalInformation);
    this.formService.getPayload().setContactInformation(contactInformation);
    this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
    this.formService.getPayload().setFamilyInformation(familyInformation);
    this.formService.getPayload().setFinancialInformation(financialInformation);
    this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);

    // save current page if form is valid
    if (!this.formService.forms.riskAndReturn.isCompleted) {
      const emptyRiskAndReturn = new RiskAndReturn();
      this.formService.getPayload().setRiskAndReturn(emptyRiskAndReturn);
    } else {
      if (this.riskAndReturnForm.valid) {
        this.formService.getPayload().setRiskAndReturn(this.riskAndReturn);
      }
    }

    this.applicationService.save(this.formService.getPayload())
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          const saveResponse = res.body;
          this.applicationService.applicationNumber = saveResponse.applicationNumber;
          this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })
        } else {
          this.criticalError = true;
        }
      })
      .catch(err => {
        this.criticalError = true; // show error when save fails on top of page
      });
  }
  setFocus() {

    // setTimeout() method has been used since DOM needs to wait for its handlers.
    setTimeout(() => {
      Utils.autoFocusFormFirstField(this.riskAndReturnForm);
     }, 250);


  }

  ngOnDestroy() {

    // keep information of the form in service when form/ component is destroyed
    // for later data retrieval
    this.formService.setFormData('riskAndReturn', this.riskAndReturn);
    this.finishLaterSubscriber.unsubscribe();
    this.onExitApplicationPerformSaveSubscriber.unsubscribe();
  }


  ngAfterViewInit() {
    this.checkFormValidityOnSignOut();
    this.analytics.setPageState(this.url);
  }



  /*For signout save operation*/
  checkFormValidityOnSignOut() {
    /**To check validity on signout */
    this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
      if (data == "clicked on " + this.url) {

        if (this.riskAndReturnForm.valid) {
          this.applicationService.validForm = true;
        } else {
          this.applicationService.validForm = false;
        }
      }
    });

  }

  saveAndReroute() {
    if (Utils.isSubProductAD()) { // Go to Investor Profile
      this.applicationService.saveAndcontinue(this.riskAndReturnForm, APP_ROUTES.investor_profile);
    } else {
      this.applicationService.saveAndcontinue(this.riskAndReturnForm, APP_ROUTES.regulatory_disclosures);
    }
  }

  onSubmit() {
    this.isFormSubmit = true;
    if (!this.riskAndReturnForm.valid) {
      this.setFocus();

    } else {

      this.formService.forms.riskAndReturn.isCompleted = true;

      // this.isCompleted = true;
      this.formService.setFormData('riskAndReturn', this.riskAndReturn);
      this.formService.getPayload().setRiskAndReturn(this.riskAndReturn);

      const personalInformation = this.formService.getFormData('personalInformation');
      const contactInformation = this.formService.getFormData('contactInformation');
      const employmentInformation = this.formService.getFormData('employmentInformation');
      const familyInformation = this.formService.getFormData('familyInformation');
      const financialInformation = this.formService.getFormData('financialInformation');
      const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
      const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

      this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
      this.formService.getPayload().setPersonalInformation(personalInformation);
      this.formService.getPayload().setContactInformation(contactInformation);
      this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
      this.formService.getPayload().setFamilyInformation(familyInformation);
      this.formService.getPayload().setFinancialInformation(financialInformation);
      this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);

      // console.log('on submit object  is--', this.formService.getPayload());

      if (Utils.getIsInSession() === false) {
        this.saveAndReroute();
      } else {
        this.applicationService.save(this.formService.getPayload())
          .then((res: any) => {
            if (res.status === SUCCESS_RESPONSE) {
              this.saveAndReroute();
            } else {
              this.criticalError = true;
            }
          })
          .catch(err => {
            this.criticalError = true; // show error when save fails on top of page
          });
      }

    }
  }


  setGTM() {

    const gtmDataLayerForRiskAndReturn: GTMDataLayer = {
      'event': ANALYTICS.BMOEVENT,
      'vURL': Utils.getvURL(),
      'vPageTitles': Utils.getPageTitleRiskAndReturn(),
      'category': ANALYTICS.category,
      'action': ANALYTICS.ACTIONS.RISK_AND_RETURN,
      'label': ANALYTICS.label,
      'flow': ANALYTICS.flow,
      'prodName': Utils.accountName,
      'prodNumber': Utils.accountNumber,
      'prodTotalNumber': 1,
      'originalLocation': document.location.protocol + '//' + document.location.hostname +
        document.location.pathname + document.location.search,
      'language': Utils.getLanguage(),
      's_PPvid': ANALYTICS.risk_and_return,
      'applicationId': '',
      'signerId': '',
      'packageId': '',
      'assisetdApp': false,
      'customerSource': Utils.getSubProduct(),
      'netWorth': Utils.netWorth
    };

    this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForRiskAndReturn);
    // console.log('investment objectives', this.winRef.nativeWindow.dataLayer);

  }

}
