/**
 * @purpose: currency directive
 * @ created:
 * @ last updated by: Hari Kori (24 May, 2018)
 * @created on:
 *
 */

import { ApplicationService } from './../service/application.service';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
@Directive({
  selector: '[appCurrency]'
})
/*
  Author: Dilraj, April 2018
  Use this class to format the currency on 'blur'/'focus out' events depending on if the selected
  language is English or French. Decimals values are not shown.
  English shows e.g. '$999,999', French shows e.g. '999 999 $'
  'OnClick' or 'focus in' events removes all currency formating as required
*/
export class CurrencyDirective implements OnInit {

  constructor(private _el: ElementRef, private _application: ApplicationService) { }

  ngOnInit() {
    Promise.resolve(null).then(() => {
      this.onblur();
    });
  }
  @HostListener('focus', ['$event']) onfocus(event) {
    return this._el.nativeElement.value = this._el.nativeElement.value.replace(/\D+/g, '');
  }

  @HostListener('blur', ['$event'])
  onblur(event?: KeyboardEvent) {
    const currencyFormatCode = (this._application.isCurrentLangEnglish()) ? 'en-CA' : 'fr-CA';
    let numbers;
    if (this._el.nativeElement.textContent) {
      numbers = this._el.nativeElement.textContent.replace(/\D+/g, '');

      if (numbers.length < 1) {
        return this._el.nativeElement.textContent = '';
      }

      return this._el.nativeElement.textContent = new Intl.NumberFormat(currencyFormatCode, {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(numbers);
    }

    if (this._el.nativeElement.value) {
      numbers = this._el.nativeElement.value.replace(/\D+/g, '');
      if (numbers && numbers.length < 1) {
        return this._el.nativeElement.value = '';
      }

      this._el.nativeElement.value = new Intl.NumberFormat(currencyFormatCode, {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(numbers);
    }

  }

  @HostListener('paste', ['$event'])
  blockPaste(event: KeyboardEvent) {
    event.preventDefault();
  }
}
