/**
 * @purpose: Put all Application level constants here.
 * @created By: BMO
 * @created on: 03-21-2018
 *
 */
export const APP_CONFIG = {

    EMPLOYER_NAME_LIST: [
        'bmo',
        'bank of montreal',
        'nesbitt burns',
        'banque de montréal',
        'banque de montreal',
        'Banque de Montréal',
        'Banque du Montréal'],

    // To hold the serviceData to UI mapping
    // for eg. to refer to a code value in dropdown that we get from service.
    UI_CONSTANTS_MAP: {
        BMO_PRODUCTS: {
            debit_card: '1',
            investor_line: '2',
            smartfolio: '3',
            nesbitt_burns: '4'
        }
    }
};

export const PRODUCT = {
    RRSP: {
        name: 'RRSP',
        number: 100391
    },
    CASH: {
        name: 'CASH',
        number: 100562
    },
    TFSA: {
        name: 'TFSA',
        number: 100544
    },
    SRRSP: {
        name: 'SRRSP',
        number: 100391
    },
    MARGIN: {
        name: 'MARGIN',
        number: 100562
    }
};

export const MODEL = {
    signOut: 'signOut',
    otherAccounts: 'OtherAccounts'
}

export const APP_ROUTES = {
    dashboard: 'dashboard',
    select_account: 'select-account',
    set_expectation: 'set-expectation',
    personal_information: 'personal-information',
    contact_information: 'contact-information',
    employment_information: 'employment-information',
    family_information: 'family-information',
    celebration: 'celebration',
    financial_details: 'financial-details',
    regulatory_disclosures: 'regulatory-disclosures',
    consents: 'consents',
    bmo_relationship: 'bmo-relationship',
    account_password: 'account-password',
    account_access: 'account-access',
    celebration_review: 'celebration-review',
    review: 'review',
    terms_conditions: 'terms-conditions',
    next_steps: 'next-steps',
    next_bybranch_pdf: 'next-bybranch-pdf',
    next_bymail_pdf: 'next-bymail-pdf',
    document_upload: 'document-upload',
    account_detail: 'account-detail',
    application_approval_declined: 'application-approval-declined',
    application_approval_downgrade: 'application-approval-downgrade',
    investment_knowledge: 'investment-details',
    investment_objectives: 'investment-objectives',
    investor_profile: 'investor-profile',
    branch_referral: 'branch-referral',
    branch_assisted: 'branch-assisted',
    branch_esignature: 'branch-esignature',
    branch_save_progress: 'branch-save-progress',
    technical_error: 'technical-error',
    beneficiary: 'beneficiary',
    riskAndReturn: "risk-and-return",
    withdrawal: 'withdrawal',
    olb_inprogress: 'olb-inprogress',
    technical_error_branchurl: 'technical-error-branchurl',
    technical_error_session: 'technical-error-session'
};

export const dataCollectionPages = [
    'personal-information',
    'contact-information',
    'employment-information',
    'family-information',
    'financial-details',
    'regulatory-disclosures',
    'consents',
    'bmo-relationship',
    'account-access',
    'review',
    'terms-conditions',
    'withdrawal',
    'investment-details',
    'investor-profile',
    'risk-and-return'
];

export const specialBypassEmail = 'noemail@bmo.com';

export const cmsPrefixUrl = '/content/bil/messages_';
export const cmsSuffixUrl = '-CA.json';

export const EMPTY_STRING = '';
export const LOGIN_SUCCESS = 'login_success';
export const CDB = 'CDB';
export const BILJ = 'BILJ';
export const JOURNEY_CLIENT_ID = 'mywealth_authn_spa';
export const OLB_CDB_CLIENT_ID = 'bil_authn_spa';
export const AUTHORIZE_URL = '/aac/sps/oauth/oauth20/authorize';
export const OLB_AUTHORIZE_URL = '/authorize';
export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;

export const SHIFT = 16;
export const CTRL = 17;
export const ALT = 18;

export const ESCAPE = 27;
export const SPACE = 32;
export const END = 35;
export const HOME = 36;
export const LEFT_ARROW = 37;
export const UP_ARROW = 38;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 40;

export const DELETE = 46;
export const PERIOD = 190;
export const BMO_InvestorLine_Inc = 'BMO InvestorLine Inc.';
export const MARITAL_STATUS = {
    MARRIED: '100002',
    COMMON_LAW: '100003'
}
export const EMPLOYMENTSTATUS = {
    FULLTIME: '100000',
    PARTTIME: '100001',
    SELFEMPLOYED: '100002',
    CASUALCONTRACT: '100005',
    SEASONAL: '100006',
    NOTPRESENTLYEMPLOYED: '100003',
    RETIRED: '100004',
    OCCUPATION: {
        HOMEMAKER: '35',
        STUDENT: '50',
        UNEMPLOYED: '61'
    },
    COUNTRY: {
        CANADA: '100000',
        USA: '100001'
    },
    NEXTSCREEN: 'family-information'
};

export const GROSS_ANNUAL_INCOME_EN = [
    { code: '24999', label: '$0 - $24,999' },
    { code: '49999', label: '$25,000 - $49,999' },
    { code: '99999', label: '$50,000 - $99,999' },
    { code: '199999', label: '$100,000 - $199,999' },
    { code: '209999', label: '$200,000 and above' }
];

export const GROSS_ANNUAL_INCOME_FR = [
    { code: '24999', label: '0 $  - 24 999 $' },
    { code: '49999', label: '25 000 $  - 49 999 $' },
    { code: '99999', label: '50 000 $  - 99 999 $' },
    { code: '199999', label: '100 000 $ - 199 999 $' },
    { code: '209999', label: '200 000 $ et plus' }
];

export const CANADA = {
    code: '100000',
    label: 'Canada' // same in French or English translation
};

export const PROVINCES_WHERE_AGE19_IS_MAJORITY = [
    '102',   // "British Columbia"
    '106',   // "Northwest Territories",
    '104',   // "New Brunswick",
    '105',   // "Newfoundland",
    '107',   // "Nova Scotia",
    '113',   // "Nunavut",
    '112'];  // "Yukon Territory"

export const COUNTRY_CODE = {
    CANADA: '100000',
    USA: '100001'
};

export const USA_LABEL = "USA";

export enum ACCOUNT_TYPE {
    RRSP = 100391,
    TFSA = 100562,
    CASH = 100544,
    RIF = 100555,
    SRRSP = '100391-SPOUSAL',
    MARGIN = '100544-MARGIN',
    MARGINACCOUNT = 100544
}

export const LANGUAGES = {
    ENGLISH: '100',
    FRENCH: '100001'
};

export const SECURITYHOLDER_MATERIAL_TYPES = {
    ProxiesAnualReports: 'ProxiesAnualReports',
    Proxies: 'Proxies'
};

export const TAX_DOCUMENT_TYPES = {
    Electronic: 'electronic',
    ElectronicAndMail: 'electronicMail'
};

export const STATUS_CODE_UNAUTHORIZED_CODES = [
    'CS2145',
    'CS2009',
    'CS2021'
]

export const STATUS_CODE_NOTFOUND_CODES = [
    'CS2142',
    'CS4000',
    'CS4014',
    'CS4015'

]

export const STATUS_CODE_FORBIDDEN_CODES = [
    'CS2143',
    'CS2144',
    'CS2062'
]

export const MARGIN_EN = 'MARGIN';
export const MARGIN_FR = 'MARGE';
export const SRRSP_EN = 'SPOUSAL-RRSP';
export const SRRSP_FR = 'CONJUGALE-REER';
export const CASH_EN = "Individual";
export const CASH_FR = "individuel";
export const RRIF_EN = "RRIF";
export const RRIF_FR = "FERR";
export const AD = 'AdviceDirect';
export const SD = 'SelfDirect';
export const productTypeAD = 'AD';
export const productTypeSD = 'SD';
export const SUCCESS_RESPONSE = 200;
export const FORBIDDEN = 403;
export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const STATUS_CODE_NOTFOUND = '10000';
export const STATUS_CODE_FORBIDDEN = '10003';
export const STATUS_CODE_UNAUTHORIZED = '10003'
export const LANGUAGE_ENGLISH = 'en';
export const LANGUAGE_FRENCH = 'fr';
export const QUEBEC = '110';
export const MIN_DEPENDENTS = 1;
export const MAX_DEPENDENTS = 99;
export const DEPENDENTS_MIN_LENGTH = 1;
export const DEPENDENTS_MAX_LENGTH = 2;

export const IL_INVESTMENT_PROFILE = [
    { code: "D", description: "investmentKnowledge.labels.knowledgeNoneDescription" },
    { code: "A", description: "investmentKnowledge.labels.knowledgeLimitedDescription" },
    { code: "B", description: "investmentKnowledge.labels.knowledgeGoodDescription" },
    { code: "C", description: "investmentKnowledge.labels.knowledgeExcellentDescription" }
]
export const IL_Investment_TimeHorizon = [
    { code: "1", description: "investmentKnowledge.labels.ilInvestmentTimeHorizonLessthanYear" },
    { code: "2", description: "investmentKnowledge.labels.ilInvestmentTimeHorizon1to3Years" },
    { code: "3", description: "investmentKnowledge.labels.ilInvestmentTimeHorizon3to5Years" },
    { code: "4", description: "investmentKnowledge.labels.ilInvestmentTimeHorizon5to10Years" },
    { code: "5", description: "investmentKnowledge.labels.ilInvestmentTimeHorizon10plusyears" }
]
export const INVESTMENT_PROFILE = [
    { code: "N", description: "investorProfile.labels.riskLowMediumDescription", fixInc: "investorProfile.labels.incomeFixed", equiValues: "investorProfile.labels.incomeEquities" },
    { code: "L", description: "investorProfile.labels.riskMediumDescription", fixInc: "investorProfile.labels.BalancedFixed", equiValues: "investorProfile.labels.BalancedEquities" },
    { code: "G", description: "investorProfile.labels.riskMediumHighDescription", fixInc: "investorProfile.labels.GrowthFixed", equiValues: "investorProfile.labels.GrowthEquities" },
    { code: "H", description: "investorProfile.labels.riskHighDescription", fixInc: "investorProfile.labels.AggressiveFixed", equiValues: "investorProfile.labels.AggressiveEquities" }
]

export const applicationDowngrage = 412;
export const BMO_INSTITUTION_CODE = '0001';
export const BMO_INSTITUTION_NAME = 'BANK OF MONTREAL';
export const APPLICATION_STATUS = {
    DataCollection: 'DataCollection',
    DocumentCollection: 'DocumentCollection',
    Completed: 'Completed'
};

export const DOCUMENT_STATUS = {
    Completed: 'Completed',
    Error: 'Error',
    ESignPending: 'ESignPending'
};

export const DOCUMENT_TYPE = {
    CUSTOMER_PACKAGE: 'CustomerPackage',
    BENEFICIARY: 'Beneficiary'
};

export enum CreditDecisionType {
    ILREVIEW, ILACCEPT, ILDECLNE, ILDWNGRD
}

export const decisionType = {
    ILREVIEW: 'ILREVIEW',
    ILACCEPT: 'ILACCEPT',
    ILDECLNE: 'ILDECLNE',
    ILDWNGRD: 'ILDWNGRD'
}
export const DOWNGRADE_DECISIONS = {
    accept_downgrade: 'ACCEPT_DOWNGRADE',
    cancel_downgrade: 'CANCEL_DOWNGRADE',
    process_otheraccounts: 'PROCESS_OTHERACCOUNTS'
};
export const ANALYTICS = {
    BMOVPageView: 'BMOVPageView',
    BMOEVENT: 'BMOEvent',
    category: 'Online Investing',
    actionStart: 'App Start',
    actionComplete: 'App Complete',
    label: 'Investorline',
    flow: 'Investorline',
    branch_referral: 'Investorline:wealth:onboarding:application:branch referral',
    select_account: 'Investorline:wealth: onboarding: application: select account',
    account_detail: 'Investorline:wealth: onboarding: application: account-detail',
    set_expectation: 'Investorline:wealth: onboarding: application: set-expectation',
    save_later: 'Investorline:wealth: onboarding: application: save later',
    personal_information: 'Investorline:wealth: onboarding: application: personal-information',
    contact_information: 'Investorline:wealth: onboarding: application: contact-information',
    employment_information: 'Investorline:wealth: onboarding: application: employment-information',
    family_information: 'Investorline:wealth: onboarding: application: family-information',
    beneficiary_information: 'Investorline:wealth: onboarding: application: beneficiary-information',
    withdrawal: 'Investorline:wealth: onboarding: application: withdrawal',
    celebration: 'Investorline:wealth: onboarding: application: celebration',
    financial_details: 'Investorline:wealth: onboarding: application: financial-details',
    regulatory_disclosures: 'Investorline:wealth: onboarding: application: regulatory-disclosures',
    consents: 'Investorline:wealth: onboarding: application: consents',
    bmo_relationship: 'Investorline:wealth: onboarding: application: bmo-relationship',
    account_password: 'Investorline:wealth: onboarding: application: account-password',
    account_access: 'Investorline:wealth:  onboarding: application: account-access',
    celebration_review: 'Investorline:wealth: onboarding: application: celebration-review',
    review: 'Investorline:wealth: onboarding: application: review',
    terms_conditions: 'Investorline:wealth: onboarding: application: terms-conditions',
    branch_esign: 'Investorline:wealth: onboarding: application: branch-esign',
    next_steps: 'Investorline:wealth: onboarding: application: next-steps',
    next_bybranch_pdf: 'Investorline:wealth: onboarding: application: next-bybranch-pdf',
    next_bymail_pdf: 'Investorline:wealth: onboarding: application: next-bymail-pdf',
    document_upload: 'Investorline:wealth: onboarding: application: document-upload',
    application_approval_declined: 'Investorline:wealth: onboarding: application: application-approval-declined',
    application_approval_downgrade: 'Investorline:wealth: onboarding: application: application-approval-downgrade',
    investment_knowledge: 'investorline:wealth: onboarding: application: investment-knowledge',
    investment_objectives: 'investorline:wealth: onboarding: application: investment-objectives',
    risk_and_return: 'investorline:wealth: onboarding: application: risk-and-return',
    investor_profile: 'investorline:wealth: onboarding: application: investor-profile',

    ACTIONS: {
        BRANCH_REFERRAL: 'Branch Referral',
        SELECT_ACCOUNT: 'Select Account',
        ACCOUNT_DETAILS: 'Account Detail',
        SET_EXPECTATIONS: 'Set Expectation',
        SAVE_LATER: 'Save Later',
        PERSONAL_INFO: 'Personal Information',
        CONTACT_INFO: 'Contact Information',
        EMPLOYMENT_INFO: 'Employment Information',
        FAMILY_INFO: 'Family Information',
        BENEFICIARY_INFO: 'Beneficiary Information',
        WITHDRAWAL: 'Withdrawal',
        FINANCIAL_DETAILS: 'Financial Details',
        INVESTMENT_KNOWLEDGE: 'Investment Knowledge',
        INVESTMENT_OBJECTIVES: 'Investment Objectives',
        RISK_AND_RETURN: 'Risk and Return',
        INVESTOR_PROFILE: 'Investor Profile',
        REGULATORY_DISCLOUSRES: 'Regulatory Disclosures',
        CONSENTS_DETAILS: 'Consents',
        BMO_RELATIONSHIP: 'BMO Relationship',
        ACCOUNT_PASSWORD: 'Account Password',
        REVIEW_DETAILS: 'Review',
        TERMS_CONDITIONS: 'Terms and Conditions',
        BRANCH_ESIGN: 'Branch Esign',
        NEXT_STEP: 'Application Submitted',
        CELEBRATION: 'Mid-Way Celebration',
        CELEBRATION_REVIEW: 'Final Celebration'
    },

    MODEL: {
        OVERLAY_OPENED: {
            SAVE_FOR_LATER: 'overlay opened - save for later',
            SAVE_FOR_LATER_COMPLETED: 'overlay opened - save for later complete',
            SIGN_OUT: 'overlay opened - signout',
            RESUME_APPLICATION: 'overlay opened - resume',
            RESUME_APPLICATION_COMPLETED: 'overlay opened - resume complete',
            HOW_AD_WORKS: 'overlay opened',
            CDB_OVERLAY_OPENED: 'mywealth:bmo digital banking client verify:open'
        },
        OVERLAY_CLOSED: {
            SAVE_FOR_LATER: 'overlay closed - save for later',
            SAVE_FOR_LATER_COMPLETED: 'overlay closed - save for later complete',
            SIGN_OUT: 'overlay closed - signout',
            SIGN_OUT_COMPLETED: 'overlay closed - signout complete',
            RESUME_APPLICATION: 'overlay closed - resume',
            RESUME_APPLICATION_COMPLETED: 'overlay closed - resume complete',
            HOW_AD_WORKS: 'overlay closed',
            CDB_OVERLAY_CLOSED: 'mywealth:bmo digital banking client verify:close'

        },

    },
    PAGE_NAME: {
        BRANCH_REFERRAL: 'Investorline:wealth:onboarding:application:branch referral',
        SELECT_ACCOUNT: 'Investorline:wealth:onboarding:application:select account',
        ACCOUNT_DETAILS: 'Investorline:wealth:onboarding:application:account features',
        SET_EXPECTATIONS: 'Investorline:wealth:onboarding:application:expectations',
        PERSONAL_INFO: 'Investorline:wealth:onboarding:application:personal info',
        CONTACT_INFO: 'Investorline:wealth:onboarding:application:contact info',
        EMPLOYMENT_INFO: 'Investorline:wealth:onboarding:application:employment info',
        FAMILY_INFO: 'Investorline:wealth:onboarding:application:family info',
        BENEFICIARY: 'Investorline:wealth:onboarding:application:beneficiary',
        WITHDRAWALS: 'Investorline:wealth:onboarding:application:withdrawals',
        HALF_WAY_INFO: ' Investorline:wealth:onboarding:application:half-way info',
        FINANCIAL_DETAILS: 'Investorline:wealth:onboarding:application:financial details info',
        INVESTMENT_KNOWLEDGE: 'Investorline:wealth:onboarding:application:investment:knowledge',
        INVESTMENT_OBJECTIVES: 'Investorline:wealth:onboarding:application:investment:objectives',
        RISK_AND_RETURN: 'investorline:wealth: onboarding: application: risk-and-return',
        INVESTOR_PROFILE: 'Investorline:wealth:onboarding:application:investment:profile',
        REGULATORY_DISCLOUSRES: 'Investorline:wealth:onboarding:application:regulatory disclosures',
        CONSENTS_DETAILS: 'Investorline:wealth:onboarding:application:consents',
        BMO_RELATIONSHIP: 'Investorline:wealth:onboarding:application:bmo relationship',
        ACCOUNT_PASSWORD: 'Investorline:wealth:onboarding:application:temporary password',
        ACCOUNT_ACCESS: 'Investorline:wealth:onboarding:application:account access',
        REVIEW_DETAILS: 'Investorline:wealth:onboarding:application:review',
        THANK_YOU: 'Investorline:wealth:onboarding:application:thank you',
        TERMS_CONDITIONS: 'Investorline:wealth:onboarding:application:terms conditions',
        APPLICATION_DOWNGRADE: 'Investorline:wealth:onboarding:application:margin:downgrade',
        NEXT_STEP: 'Investorline:wealth:onboarding:application:next steps',
        DOCUMENT_UPLOAD: 'Investorline:wealth:onboarding:application:document upload',
        SAVE_LATER: 'Investorline:wealth:onboarding:application:save for later',
        ESIGN_LANDING: 'Investorline:wealth:onboarding:application:esign',
        TECHNICAL_ERROR_SESSION: 'Investorline:wealth:onboarding:application:error page',
        OLB_INPROGRESS_APPLICATION: 'Investorline:wealth:onboarding:application:resume application',
        RESUME_APP_OVERLAY: 'Investorline:wealth:onboarding:application:overlay:resume'

    },
    APPLICATION_FLOW: {
        NON_ASSISTED_MULTI: 'unassisted multiple accounts',
        NON_ASSISTED_CDB_MULTI: 'unassisted cdb multiple accounts',
        MULTIASSISTED: 'assisted multiple accounts',
        ASSISTED: 'assisted',
        NON_ASSISTED: 'unassisted',
        NON_ASSISTED_CDB: 'unassisted cdb'
    }
};


export const ACCOUNT_NAME_EN = {
    TFSA: 'TFSA',
    CASH: 'CASH',
    SRRSP: 'SRRSP',
    RRSP: 'RRSP',
    MARGIN: 'MARGIN',
    RIF: 'RIF'
};
export const ACCOUNT_TYPE_ID = {
    '100562': 'TFSA',
    '100391': 'RRSP',
    '100555': 'RRIF',
    '100544': 'CASH',
    '100544-MARGIN': 'MARGIN',
    '100391-SPOUSAL': 'SpousalRRSP'
};

export const ACCOUNT_CODES = {
    TFSA: '100562',
    CASH: '100544',
    SRRSP: '100391-SPOUSAL',
    RRSP: '100391',
    MARGIN: '',
    RIF: '100555'
};

export const TFSA_ACCOUNT = {
    type: '100562',
    id: 'TFSA',
    title: 'selectAccount.accounts.TFSATitle',
    description: 'selectAccount.accounts.TFSADescription',
    dataAna: 'mywealth:select account:tfsa'
};

export const RRSP_ACCOUNT = {
    type: '100391',
    id: 'RRSP',
    title: 'selectAccount.accounts.RSPTitle',
    description: 'selectAccount.accounts.RSPDescription',
    dataAna: 'mywealth:select account:rrsp'
};

export const SPOUSAL_RRSP_ACCOUNT = {
    type: '100391-SPOUSAL',
    id: 'SpousalRRSP',
    title: 'selectAccount.accounts.SpousalRRSPTitle',
    description: 'selectAccount.accounts.SpousalRRSPDescription',
    dataAna: 'mywealth:select account:spousalrrsp',
};

export const RRIF_ACCOUNT = {
    type: '100555',
    id: 'RRIF',
    title: 'selectAccount.accounts.RRIFTitle',
    description: 'selectAccount.accounts.RRIFDescription',
    dataAna: 'mywealth:select account:rrif',
};

export const MARGIN = {
    type: '100544-MARGIN',
    id: 'Margin',
    isChecked: false,
    title: 'selectAccount.accounts.MARGINTitle',
    description: 'selectAccount.accounts.MARGINDescription',
    dataAna: 'mywealth:select account:margin',
}

export const CASH = {
    type: '100544',
    id: 'CASH',
    isChecked: false,
    title: 'selectAccount.accounts.CASHTitle',
    description: 'selectAccount.accounts.CASHDescription',
    dataAna: 'mywealth:select account:cash'
}

export const CASH_MARGIN = {
    type: 'CASH-MARGIN',
    id: 'cashMargin',
    isChecked: false,
    title: 'selectAccount.accounts.CASHMARGINTitle',
    description: '',
    dataAna: 'mywealth:select account:cashmargin',
    productNameEn: '',
    productNameFr: ''
}

export const RADIO_TYPES = {
    SMALL: 'small',
    LARGE: 'large'
};

export const INVESTMENT_KNOWLEDGE_TYPES = {
    NONE: 'D',
    LIMITED: 'A',
    GOOD: 'B',
    EXCELLENT: 'C'
};
export const INVESTMENT_KNOWLEDGE_LEVEL = {
    None: 'D',
    Limited: 'A',
    Knowledgeable: 'F',
    Expert: 'G'
};
export const INVESTMENT_TYPES = {
    BONDS: '1',
    MUTUAL_FUNDS: '3',
    STOCKS: '5',
    SHORT_SALE: '6',
    OPTIONS: '2',
    ALTERNATIVE: '7',
    COMMODITIES: '4',
};
export const INVESTMENT_TIME = {
    YEARS_0_2: '1',
    YEARS_3_5: '2',
    YEARS_6_9: '3',
    YEARS_10_15: '4',
    YEARS_16: '5',
};
export const INVESTMENT_WITHDRAW = {
    YEARS_0_2: '1',
    YEARS_2_5: '2',
    YEARS_6_9: '3',
    YEARS_10_15: '4',
    YEARS_16: '5',

};

export const PERCENTAGE = {
    SIGN: '%'
};

export const BMO_TECHNICAL_ERROR_OLAP_URL = {
    EN: 'https://www.secure.bmoinvestorline.com/ILOLAPWeb/olap/DisplayApplyOnline.jsp?refresh=true&language=E&id=beforeyoustart&omtrRef=https://www.bmoinvestorline.com/&icid=bu-US15598IL4-INBMO14',
    FR: ' https://www.secure.bmoinvestorline.com/ILOLAPWeb/olap/DisplayApplyOnline.jsp?refresh=true&language=F&id=beforeyoustart&omtrRef=https://www.bmoinvestorline.com/&icid=bu-US15598IL4-INBMO17'
};

export const URL = {
    INVESTMENT_KNOWLEDGE: '/investment-details',
    INVESTMENT_OBJECTIVES: '/investment-objectives',
    RISK_AND_RETURN: '/risk-and-return',
    INVESTOR_PROFILE: '/investor-profile'
};

export const PAGE_TITLES = {
    selectAccountEN: 'Online Investment Application | InvestorLine | BMO',
    selectAccountFR: 'BMO Ligne d\'action - Demande d\'ouverture de compte en ligne -',
    thingsYouShouldKnowEN: 'Online Investment Application | Things you should know | BMO',
    thingsYouShouldKnowFR: 'Demande d’ouverture de compte Ligne d’action | Ce que vous devriez savoir | BMO',
};


export let LOCATION_INDEX = {
    gtm: {
        index: 4,
        property: 'dataLayer'
    }
};

export const YES_NO_VALUES = {
    YES: 'Yes',
    NO: 'No'
};

export const INVESTOR_PROFILE = {
    high: '4',
    mediumToHigh: '3',
    medium: '2',
    low: '1'

};

export const PROGRESS_BAR_TOTAL_STEPS = {
    AD: 15,
    SD: 12
};

export const QUALTRICS_CONST = {
    POST_TRANSACTION_SURVEY: 'posttrans',
    ABANDOMENT_SURVEY: 'abandoment',
    QUALTRICS_COOKIE: 'qsi_cookie'
}

export const LANGUAGES_TYPE = {

    ENGLISH: 'en',
    FRENCH: 'fr'
};

export const BMO_INVESTORLINE_LINK = {
    EN: 'https://www.bmoinvestorline.com/ILClientWeb/login/DisplayLogin.jsp?refresh=true&lang=E',
    FR: 'https://www.bmoinvestorline.com/ILClientWeb/login/DisplayLogin.jsp?refresh=true&lang=F'
};
export const BRANCH_REFERRAL_ROLE = {
    BRANCH_MANAGER: '101',
    FINANCIAL_PLANNER: '102',
    BRANCH_MANAGER_FINANCIAL_PLANNER: '103',

};
export const BMOFAQAD = {
    en: 'https://www.bmo.com/main/personal/investments/online-investing/investorline/advicedirect/faqs/',
    fr: 'https://www.bmo.com/principal/particuliers/investissements/placements-en-ligne/ligne-d-action/conseildirect/faq/'
}

export const BMO_CONTACT_YOU_AD = {
    en: 'https://www.bmo.com/main/personal/investments/online-investing/investorline/advicedirect/let-us-contact-you/',
    fr: 'https://www.bmo.com/principal/particuliers/investissements/placements-en-ligne/ligne-d-action/conseildirect/demandez-quon-communique-avec-vous/'
}
export const BMO_FAQ_SD = {
    en: 'https://www.bmo.com/main/personal/investments/online-investing/investorline/self-directed/faqs/',
    fr: 'https://www.bmo.com/principal/particuliers/investissements/placements-en-ligne/ligne-d-action/placements-autogeres/faq/'
}

export const BMO_CONTACT_YOU_SD = {
    en: 'https://www.bmo.com/main/personal/investments/online-investing/investorline/self-directed/let-us-contact-you/',
    fr: 'https://www.bmo.com/principal/particuliers/investissements/placements-en-ligne/ligne-d-action/placements-autogeres/demandez-quon-communique-avec-vous/'
}
export enum State {
    yes = 'Yes',
    no = 'No',
    yesFrench = 'Oui',
    noFrench = 'Non'
}
export const BENEFICIARY_RELATIONSHIP_DROPDOWN_EN = [
    {
        code: '01',
        label: 'Aunt'
    },
    {
        code: '02',
        label: 'Brother'
    },
    {
        code: '03',
        label: 'Child'
    },
    {
        code: '04',
        label: 'Cousin'
    },
    {
        code: '05',
        label: 'Grandchild'
    },
    {
        code: '06',
        label: 'Grandparent'
    },
    {
        code: '07',
        label: 'In-law'
    },
    {
        code: '08',
        label: 'Nephew'
    },
    {
        code: '09',
        label: 'Niece'
    },
    {
        code: '10',
        label: 'Parent'
    },
    {
        code: '11',
        label: 'Sister'
    },
    {
        code: '12',
        label: 'Spouse'
    },
    {
        code: '13',
        label: 'Uncle'
    },
    {
        code: '14',
        label: 'Other'
    },
];

// TODO
export const BENEFICIARY_RELATIONSHIP_DROPDOWN_FR = [
    {
        code: '01',
        label: 'Tante'
    },
    {
        code: '02',
        label: 'Frère'
    },
    {
        code: '03',
        label: 'Enfant'
    },
    {
        code: '04',
        label: 'Cousin ou cousine'
    },
    {
        code: '05',
        label: 'Petit-fils ou petite-fille'
    },
    {
        code: '06',
        label: 'Grands-parents'
    },
    {
        code: '07',
        label: 'Beau-père ou belle-mère'
    },
    {
        code: '08',
        label: 'Neveu'
    },
    {
        code: '09',
        label: 'Nièce'
    },
    {
        code: '10',
        label: 'Parent'
    },
    {
        code: '11',
        label: 'Sœur'
    },
    {
        code: '12',
        label: 'Conjoint ou conjointe'
    },
    {
        code: '13',
        label: 'Oncle'
    },
    {
        code: '14',
        label: 'Autre'
    },
];

export const WITHDRAWAL_FREQUENCY_DROPDOWN_FR = [
    {
        code: '1',
        label: 'Mensuellement'
    },
    {
        code: '2',
        label: 'Trimestriellement'
    },
    {
        code: '3',
        label: 'Semestriellement'
    },
    {
        code: '4',
        label: 'Annuellement'
    }
]


export const WITHDRAWAL_FREQUENCY_DROPDOWN_EN = [
    {
        code: '1',
        label: 'Monthly'
    },
    {
        code: '2',
        label: 'Quarterly'
    },
    {
        code: '3',
        label: 'Semi-annual'
    },
    {
        code: '4',
        label: 'Annual'
    }
]

export const WITHDRAWAL_START_MONTH_DROPDOWN_FR = [
    {
        code: '1',
        label: 'Janvier'
    },
    {
        code: '2',
        label: 'Février'
    },
    {
        code: '3',
        label: 'Mars'
    },
    {
        code: '4',
        label: 'Avril'
    },
    {
        code: '5',
        label: 'Mai'
    },
    {
        code: '6',
        label: 'Juin'
    },
    {
        code: '7',
        label: 'Juillet'
    },
    {
        code: '8',
        label: 'Août'
    },
    {
        code: '9',
        label: 'Septembre'
    },
    {
        code: '10',
        label: 'Octobre'
    },
    {
        code: '11',
        label: 'Novembre'
    },
    {
        code: '12',
        label: 'Décembre'
    }
]



export const WITHDRAWAL_START_MONTH_DROPDOWN_EN = [
    {
        code: '1',
        label: 'January'
    },
    {
        code: '2',
        label: 'February'
    },
    {
        code: '3',
        label: 'March'
    },
    {
        code: '4',
        label: 'April'
    },
    {
        code: '5',
        label: 'May'
    },
    {
        code: '6',
        label: 'June'
    },
    {
        code: '7',
        label: 'July'
    },
    {
        code: '8',
        label: 'August'
    },
    {
        code: '9',
        label: 'September'
    },
    {
        code: '10',
        label: 'October'
    },
    {
        code: '11',
        label: 'November'
    },
    {
        code: '12',
        label: 'December'
    }
]

export const RIF_PAYMENT_METHOD = {
    BMOEFT: 'BMOEFT',
    NBMOEFT: 'NBMOEFT',
    TFR: 'TFR'
}
export const DOC_UPLOAD_STATUS = {
    COMPLETED: 'Completed',
    PENDING: 'Pending',
    NULL_VALUE: null
}
export const INTERNAL = 'INTERNAL';

export const ADDRESS_REGEX = {
    poBox: /^((?!P[\.\s]*O[\.\s]*Box|post office box).)*$/i,
    rrAddress:{
    EN: /^((?!R[\.\s]*R[\.\s]|Rural Route).)*$/,
    FR: /^((?!R[\.\s]*R[\.\s]|Route Rurale).)*$/
    }
}

export const CONTEXTUAL_FOCUSOUT_DROPDOWN_CLOSE = [
    'jobTitle',
    'selectEmploymentStatus',
    'employerName',
    'selectNatureOfBusiness',
    'selectPreviousOccupation',
    'backButtonID'
];
export const RSM_REFERRAL_ROLE = {
    RSM_FP_IFS_PRIVATE_BANKER: '105',
    PERSONAL_BANKER: '106'
};
export const SCREENER_QUESTION_OPTION_CODES = {
    SQ1: {
        A: 'SQ1A',
        B: 'SQ1B',
        C: 'SQ1C'
    },
    SQ2: {
        A: 'SQ2A',
        B: 'SQ2B',
        C: 'SQ2C',
        D: 'SQ2D'
    }
}

