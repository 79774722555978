<h3 translate="beneficiary.sectionWhatsName"></h3>
<div class="form-wrapper">

    <div class="form-row">

        <div class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">

                    <input id="firstName{{index}}" data-ana="mywealth:onboarding:beneficiary:first name" name="firstName{{index}}" type="text" minlength="2"
                        appMinLengthValidator minLengthVal='2' [(ngModel)]="beneficiaryObj.firstName"
                        #firstNameCtrl="ngModel" required InputValidator="ct23" />

                    <label [ngClass]="{'error-field': isError(firstNameCtrl), 'active-label': firstNameCtrl.value}"
                        class="slide-label" for="firstName{{index}}">
                        <span class="label-text" translate="beneficiary.labels.firstName"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="firstName-error" class="error-message" *ngIf="isError(firstNameCtrl)">
                <span translate="beneficiary.errors.firstName"></span>
            </div>
            <div role="alert" id="firstName-error" class="error-message"
                *ngIf="(firstNameCtrl.touched && firstNameCtrl?.errors?.minLengthError)">
                <span translate="beneficiary.errors.firstName2chars"></span>
            </div>
        </div>

        <div class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">

                    <input id="lastName{{index}}"  data-ana="mywealth:onboarding:beneficiary:last name" name="lastName{{index}}" type="text" minlength="2"
                        appMinLengthValidator minLengthVal='2' [(ngModel)]="beneficiaryObj.lastName"
                        #lastnameCtrl="ngModel" required InputValidator="ct23" />

                    <label [ngClass]="{'error-field': isError(lastnameCtrl), 'active-label': lastnameCtrl.value}"
                        class="slide-label" for="lastName{{index}}">
                        <span class="label-text" translate="beneficiary.labels.lastName"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="lastName-error" class="error-message" *ngIf="isError(lastnameCtrl)">
                <span translate="beneficiary.errors.lastName"></span>
            </div>
            <div role="alert" id="firstName-error" class="error-message"
                *ngIf="(lastnameCtrl.touched && lastnameCtrl?.errors?.minLengthError)">
                <span translate="beneficiary.errors.lastName2chars"></span>
            </div>
        </div>

    </div>

</div>

<h3 translate="beneficiary.sectionWhatsDOB"></h3>
<div class="form-wrapper">
    <div class="form-row">

        <div class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">

                    <!--DOB-->
                    <app-date-picker id="dateOfBirth{{index}}" name="dateOfBirth{{index}}" [showWeekdays]="true"   [isAgeValidation]="false"
                        [(ngModel)]="beneficiaryObj.dateOfBirth" ngDefaultControl #dateOfBirthCtrl="ngModel">
                    </app-date-picker>
                </div>
                <div class="form-field-button">
                    <app-contextual-help id="tooltipDOB" class="button-wrapper" arialabel="beneficiary.ariaLabels.dobLabel"
                        content="beneficiary.contextualHelp.datePicker" inputDataAna="wealth:onboarding:tool tip:date of birth">
                    </app-contextual-help>
                </div>
            </div>

            <div role="alert" class="error-message"
                *ngIf="dateOfBirthCtrl?.invalid && dateOfBirthCtrl?.touched || (isFormSubmit && dateOfBirthCtrl?.errors)">
                <span *ngIf="dateOfBirthCtrl?.errors?.invalidDateOfBirth"
                    translate="beneficiary.errors.dobEmpty"></span>
                <span *ngIf="dateOfBirthCtrl?.errors?.invalidAgeUnderEighteen"
                    translate="beneficiary.errors.dobEmpty"></span>
                <span *ngIf="dateOfBirthCtrl?.errors?.invalidNotUnder72" translate="beneficiary.errors.dobEmpty"></span>
                <span *ngIf="dateOfBirthCtrl?.errors?.notAgeOfMajority" translate="beneficiary.errors.dobEmpty"></span>
            </div>

        </div>
    </div>
</div>

<h3 translate="beneficiary.sectionWhatsRelationshipTo"></h3>
<div class="form-wrapper">
    <div class="form-row">
        <div class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">

                    <!-- DROP DOWN-->
                    <select id="relationshipTo{{index}}" name="relationshipTo{{index}}" data-ana="mywealth:onboarding:beneficiary:relationship"
                        (change)=updateRelationship($event) [(ngModel)]="beneficiaryObj.relationshipToPlanHolder"
                        #selectRelationshipStatusCtrl="ngModel" required>

                        <option *ngFor="let relationship of relationships" [value]="relationship.label"
                            [selected]="relationship.selected" [attr.data-ana]="">
                            {{relationship.label}}
                        </option>
                    </select>

                    <label
                        [ngClass]="{'error-field': isError(selectRelationshipStatusCtrl), 'active-label': selectRelationshipStatusCtrl.value}"
                        class="slide-label" for="relationshipTo{{index}}">
                        <span class="label-text" translate="beneficiary.labels.relationshipTo"></span>
                    </label>

                </div>
            </div>

            <div role="alert" id="selectRelationshipStatus-error" class="error-message"
                *ngIf="isError(selectRelationshipStatusCtrl)">
                <span translate="beneficiary.errors.relationshipTo"></span>
            </div>
        </div>
    </div>
</div>

<h3 translate="beneficiary.sectionWhatsPercentageEntitlement"></h3>
<p translate="beneficiary.sublineWhatsPercentageEntitlement"></p>
<div class="form-wrapper">
    <div class="form-row">

        <div class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">

                    <input type="text" id="percentageEntitlement{{index}}" name="percentageEntitlement{{index}}"
                        maxlength="5" minlength="0" [(ngModel)]="beneficiaryObj.entitlementPercentage" data-ana="mywealth:onboarding:beneficiary:percentage"
                        [readonly]="(disablePercentageField)" #entitlementPercentageCtrl="ngModel" InputValidator="ct24"
                        (keyup)="setInvalidTotal()" required />

                    <label class="slide-label" for="percentageEntitlement{{index}}"
                        [ngClass]="{'error-field':isError(entitlementPercentageCtrl), 'active-label':entitlementPercentageCtrl.value}">
                        <span class="label-text" translate="beneficiary.labels.percentageEntitlement"></span>
                    </label>

                </div>
            </div>

            <div role="alert" id="percentageEntitlement-error" class="error-message"
                *ngIf="isError(entitlementPercentageCtrl)">
                <span translate="beneficiary.errors.percentageEntitlement"></span>
            </div>

        </div>

    </div>
</div>