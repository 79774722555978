export const NewReferenceDocumentTypes = {

    'EN': [{
        "code": "542",
        "label": "Credit card statement",
        "group": "B",
        "lob": "BIL"
    },{
        "code": "381",
        "label": "Bank statement",
        "group": "B",
        "lob": "BIL"
    }, {
        "code": "547",
        "label": "Loan account statement",
        "group": "B",
        "lob": "BIL"
    }, {
        "code": "557",
        "label": "Utility bill (water, electricity or telecommunications)",
        "group": "A",
        "lob": "BIL"
    },{
        "code": "546",
        "label": "Registered investment account statement (TFSA, RRSP, RRIF)",
        "group": "A",
        "lob": "BIL"
    },  {
        "code": "032",
        "label": "Government issued photo ID (excluding provincial health cards)",
        "group": "A",
        "lob": "BIL"
    }],

    'FR': [{
        "code": "557",
        "label": "Facture de service public (eau, électricité ou télécommunications)",
        "group": "A",
        "lob": "BIL"
    }, {
        "code": "546",
        "label": "Relevé de compte de placement enregistré (CELI, REER, FERR)",
        "group": "A",
        "lob": "BIL"
    }, {
        "code": "032",
        "label": "Pièce d'identité avec photo délivrée par un gouvernement (à l’exclusion de la carte d'assurance maladie provinciale)",
        "group": "A",
        "lob": "BIL"
    },{
        "code": "542",
        "label": "Relevé de carte de crédit",
        "group": "B",
        "lob": "BIL"
    },{
        "code": "381",
        "label": "Relevé bancaire",
        "group": "B",
        "lob": "BIL"
    },  {
        "code": "547",
        "label": "Relevé de compte de prêt",
        "group": "B",
        "lob": "BIL"
    }]

}

export const OldReferenceDocumentTypes = {

    'EN': [{
        "code": "381",
        "label": "Bank statement",
        "group": "B",
        "lob": "BIL"
    },
    {
        "code": "542",
        "label": "Credit card statement",
        "group": "B",
        "lob": "BIL"
    },
    {
        "code": "546",
        "label": "Investment account statement",
        "group": "A",
        "lob": "BIL"
    },
    {
        "code": "547",
        "label": "Loan account statement (e.g. mortgage, car loan, etc.)",
        "group": "B",
        "lob": "BIL"
    },
    {
        "code": "554",
        "label": "T4 statement",
        "group": "A",
        "lob": "BIL"
    },
    {
        "code": "557",
        "label": "Utility bill (your name must be indicated)",
        "group": "A",
        "lob": "BIL"
    }],

    'FR': [{
        "code": "381",
        "label": "Relevé bancaire",
        "group": "B",
        "lob": "BIL"
    }, {
        "code": "542",
        "label": "Relevé de carte de crédit",
        "group": "B",
        "lob": "BIL"
    }, {
        "code": "546",
        "label": "Relevé de compte de placement",
        "group": "A",
        "lob": "BIL"
    }, {
        "code": "547",
        "label": "Relevé de compte de prêt (p. ex., prêt hypothécaire, prêt automobile, etc.)",
        "group": "B",
        "lob": "BIL"
    }, {
        "code": "554",
        "label": "Feuillet T4",
        "group": "A",
        "lob": "BIL"
    }, {
        "code": "557",
        "label": "Facture d'un service public (votre nom doit y figurer)",
        "group": "A",
        "lob": "BIL"
    }]
}