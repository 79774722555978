import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-email-address',
  templateUrl: './email-address.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class EmailAddressComponent {

    @Input() group;
    @Input() model;
    @Input() key;
    @Input() id;
    @Input() isFormSubmit;

  constructor() { }

}
