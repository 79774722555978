import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Utils } from '../utils/utils.class';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';

@Injectable()

export class CanBranchActivateGuard implements CanActivate {

  constructor(private configService: ConfigResolverService<any>, private router: Router) {}

  canActivate() {

    if (this.configService.getConfig().BRANCH_ASSISTED === 'true') {
      return true;
    } else {
      this.router.navigate(['set-expectation'], { queryParams: { lang: 'en' } });
    }
    
  }
  
}
