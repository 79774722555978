<!-- Save for later - first time, creating password and follow up message -->
<!-- --- -->

<div role="dialog" class="bmo-modal bmo-save-for-later" aria-labelledby="ariaAccountSetupTitle">


    <a role="button"
       attr.aria-label="{{!success ? ('modalSaveForLater.ariaLabels.closeSaveForLater' | translate) : ('ariaLabels.btnClose' | translate)}}"
       attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
       class="btn-icon btn-close tooltip-show"
       href="javascript://"
       (click)="onClose()">
        <span class="icon icon-large close-grey"></span>
    </a>

    <section *ngIf="!success">

        <form #accSetupFrom="ngForm" (ngSubmit)="createPassword()" novalidate>

            <div class="modal-content-scroll no-margin content-left">
              
              <app-critical-error *ngIf="criticalError"></app-critical-error>

                <div role="alert" class="alert alert-danger" *ngIf="isFormSubmit && !accountSetupForm.valid">
                    <p>
                        <strong translate="accountDetail.errors.selectionError"></strong><br />
                        <span translate="accountDetail.errors.selectionErrorDescription"></span>
                    </p>
                </div>

                <div class="flex-elements flex-col justify-space-between">

                    <section>

                        <h1 id="ariaAccountSetupTitle" *ngIf="!isEnforceFinishLaterEnabled" translate="modalSaveForLater.title1Modal"></h1>
                        <h1 id="ariaAccountSetupTitle" *ngIf="isEnforceFinishLaterEnabled" translate="modalSaveForLater.title3Modal"></h1>

                        <div role="alert" class="alert alert-info" *ngIf="isEnforceFinishLaterEnabled">
                            <p translate="modalSaveForLater.section1Content03"></p>
                        </div>

                        <p>
                            <span [ngClass]="{'font-bold': !isEnforceFinishLaterEnabled}" translate="modalSaveForLater.section1Content02"></span>
                            <span [ngClass]="{'font-bold': isEnforceFinishLaterEnabled}">{{ applicationNumber }}</span>
                            <a  *ngIf="isEnforceFinishLaterEnabled" [copy-clipboard]="applicationNumber" id="copyAccountNumber" role="button" class="btn-icon" [ngClass]="{'tooltip-show': showTooltip}" (click)="copyApplicationNumber(true)" (mouseout)="copyApplicationNumber(false)" (focusout)="copyApplicationNumber(false)"
                                href="javascript://" attr.tooltip="{{ 'linkButton.copied' | translate }}"
                                attr.aria-label="{{ 'modalSaveForLater.ariaLabels.copyApplicationNumber' | translate }}">
                                <span class="icon icon-large copy-blue" aria-hidden="true"></span>
                                <span class="btn-icon-text" translate="linkButton.copy"></span>
                            </a>
                            <span class="off-screen" *ngIf="showTooltip" role="alert" translate="modalSaveForLater.ariaLabels.copiedApplicationNumber"></span>
                        </p>

                        <p *ngIf="!isEnforceFinishLaterEnabled" translate="modalSaveForLater.section1Content01"></p>

                        <div class="form-wrapper">
                            <div class="form-row">

                                <div class="flex-fill form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="createPwd" name="password" type="password" maxlength="8"
                                                   [(ngModel)]="accountSetup.password" #accSetupPwdCtrl="ngModel"
                                                   InputValidator='ct4' appAccSetupPasswordValidator required
                                                   aria-describedby="passwordCheck1 passwordCheck2" />
                                            <label class="slide-label" for="createPwd"
                                                   [ngClass]="{'error-field':isError(accSetupPwdCtrl), 'active-label':accSetupPwdCtrl.value}">
                                                <span class="label-text"
                                                      translate="modalSaveForLater.labels.createPassword"></span>
                                            </label>
                                            <a role="button" *ngIf="(accountSetup?.password?.length > 0 || accountSetup?.confirmPassword?.length > 0);else disabledEye" 
                                                (click)="togglePasswordVisibility('createPwd' , 'confirmPassword')"
                                               class="btn-icon reveal-password"
                                               attr.aria-label="{{'accountPassword.ariaLabels.showPassword' | translate}}"
                                               attr.aria-pressed="{{passwordHide == true ? 'false' : 'true'}}"
                                               href="javascript://">
                                               <span  [class]="passwordHide == true ? 'icon icon-large password-hide-blue' : 'icon icon-large password-show-blue'"></span>
                                            </a>
                                            <ng-template #disabledEye>
                                            <a role="button"  aria-disabled="true"
                                               class="disabled btn-icon reveal-password"
                                               attr.aria-label="{{'accountPassword.ariaLabels.disabledShowPassword' | translate}}"
                                               href="javascript://">
                                               <span [class]="passwordHide == true ? 'icon icon-large password-hide-grey' : 'icon icon-large password-show-grey'"></span>
                                            </a>
                                        </ng-template>   
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" *ngIf="isError(accSetupPwdCtrl)">
                                        <span translate="modalSaveForLater.errors.passwordFormat"></span>
                                    </div>
                                    <div class="password-checks">
                                        <div class="bmo-container-flex">
                                            <div id="passwordCheck1" class="flex-40">
                                                <span class="icon-inline icon-error"
                                                      [ngClass]="accSetupPwdCtrl?.errors?.lengthMustBeGreaterThanSeven ? 'icon-error' : 'icon-check'"
                                                      translate="modalSaveForLater.labels.maxChar"></span>
                                                <span class="icon-inline icon-error"
                                                      [ngClass]="accSetupPwdCtrl?.errors?.noDigitsPresent ? 'icon-error' : 'icon-check'"
                                                      translate="modalSaveForLater.labels.minNumber"></span>
                                                <span class="icon-inline icon-error"
                                                      [ngClass]="accSetupPwdCtrl?.errors?.noLowerCasePresent ? 'icon-error' : 'icon-check'"
                                                      translate="modalSaveForLater.labels.minLowCase"></span>
                                            </div>
                                            <div id="passwordCheck2" class="flex-40">
                                                <span class="icon-inline icon-error"
                                                      [ngClass]="accSetupPwdCtrl?.errors?.noUpperCasePresent ? 'icon-error' : 'icon-check'"
                                                      translate="modalSaveForLater.labels.minUpCase"></span>
                                                <span class="icon-inline icon-error"
                                                      [ngClass]="accSetupPwdCtrl?.errors?.noSpecialCharactersAllowed ? 'icon-error' : 'icon-check'"
                                                      translate="modalSaveForLater.labels.noSpecialChar">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-row">

                                <div class="flex-fill form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="confirmPassword" name="confirmPassword" type="password"
                                                   aria-describedby="confirmPassword"
                                                   [(ngModel)]="accountSetup.confirmPassword"
                                                   #accSetupConfirmPwdCtrl="ngModel" maxlength="8"
                                                   [appEqualTo]="accSetupPwdCtrl" InputValidator='ct4' required />
                                            <label class="slide-label" for="confirmPassword" [ngClass]="{'error-field':isError(accSetupConfirmPwdCtrl),
                                            'active-label':accSetupConfirmPwdCtrl.value}">
                                                <span class="label-text"
                                                      translate="modalSaveForLater.labels.confirmPassword"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" *ngIf="isError(accSetupConfirmPwdCtrl)">
                                        <span translate="modalSaveForLater.errors.passwordMatch"></span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>

                    <section>

                        <div class="form-row form-page-buttons flex-maxis-center">

                            <div class="form-col left-side">
                                <button class="secondary" type="button" translate="button.close" 
                                        attr.aria-label="{{ 'modalSaveForLater.ariaLabels.closeSaveForLater' | translate }}"
                                        (click)="onClose()">
                                </button>
                            </div>
                            <div class="form-col right-side">
                                <button class="primary" type="submit" translate="{{isEnforceFinishLaterEnabled ? ('button.saveAndContinue') : ('button.saveApplication') }}"
                                        attr.aria-label="{{ 'modalSaveForLater.ariaLabels.saveAndContinue' | translate }}">
                                </button>
                            </div>

                        </div>

                    </section>

                </div>

            </div>

        </form>

    </section>

    <!-- Show this when the password has successfuly been created -->
    <!-- --- -->

    <section *ngIf="success">
        <div class="modal-content-scroll no-margin content-left">
        <div class="flex-maxis-center content-center">

            <img class="artwork-header" src="assets/img/artwork-locked-screen.svg" alt="" />

            <h1 id="ariaAccountSetupTitle" translate="modalSaveForLater.title2Modal"></h1>

            <p>
                <span *ngIf="!isEnforceFinishLaterEnabled" [innerHTML]="'modalSaveForLater.section3Content01' | translate | safeHtml"></span>
                <span *ngIf="isEnforceFinishLaterEnabled" [innerHTML]="'modalSaveForLater.section3Content03' | translate | safeHtml"></span>
                <span [ngClass]="{'font-bold': isEnforceFinishLaterEnabled}">{{applicationNumber}}</span>
                <a  *ngIf="isEnforceFinishLaterEnabled" [copy-clipboard]="applicationNumber" id="copyAccountNumber" role="button" class="btn-icon" [ngClass]="{'tooltip-show': showTooltip}" (click)="copyApplicationNumber(true)" (mouseout)="copyApplicationNumber(false)" (focusout)="copyApplicationNumber(false)"
                    href="javascript://" attr.tooltip="{{ 'Copied!' }}"
                    attr.aria-label="{{ 'modalSaveForLater.ariaLabels.copyApplicationNumber' | translate }}">
                    <span class="icon icon-large copy-blue" aria-hidden="true"></span>
                    <span class="btn-icon-text" translate="linkButton.copy"></span>
                </a>
                <span class="off-screen" *ngIf="showTooltip" role="alert" translate="modalSaveForLater.ariaLabels.copiedApplicationNumber"></span>
            </p>

            <p [innerHTML]="'modalSaveForLater.section3Content02' | translate | safeHtml"></p>

        </div>

        <div class="form-wrapper no-margin">

            <div class="form-row form-page-buttons">
                <button class="primary element-center" type="submit" translate="button.close" (click)="onClose()"></button>
            </div>

        </div>
        </div>

    </section>

</div>

<div class="modal-overlay-component"></div>
