import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Utils } from '../../shared/utils/utils.class';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { CASH_EN, CASH_FR, MARGIN_EN, MARGIN_FR, RRIF_EN, RRIF_FR, SRRSP_EN, SRRSP_FR } from './../../config/app-config.constants';
import { AnalyticsService } from './analytics.service';
@Injectable()
export class AccountTypeService {

    accountType: Subject<any> = new Subject<any>();
    accountTypeTitle: string;
    config: any;
    accountTypeMetaData: any;
    selectedAccount: any;

    constructor(private http: HttpClient,
        private analyticsService : AnalyticsService,
        private configService: ConfigResolverService<any>) {

        this.accountTypeMetaData = [
            {
                type: '100391',
                id: 'RRSP',
                title: 'selectAccount.accounts.RSPTitle',
                description: 'selectAccount.accounts.RSPDescription',
                dataAna: 'mywealth:select account:rrsp'
            },
            {
                type: '100544',
                id: 'CASH',
                title: 'selectAccount.accounts.CASHTitle',
                description: 'selectAccount.accounts.CASHDescription',
                dataAna: 'mywealth:select account:cash'
            },
            {
                type: '100562',
                id: 'TFSA',
                title: 'selectAccount.accounts.TFSATitle',
                description: 'selectAccount.accounts.TFSADescription',
                dataAna: 'mywealth:select account:tfsa'
            },
            {
                type: '100545',
                id: 'Joint',
                title: 'selectAccount.accounts.TFSATitle',
                description: 'selectAccount.accounts.TFSADescription',
                dataAna: 'mywealth:select account:joint'
            },
            {
                type: '100555',
                id: 'RIF',
                title: 'selectAccount.accounts.RRIFTitle',
                description: 'selectAccount.accounts.RRIFDescription',
                dataAna: 'mywealth:select account:rif'
            }
        ];

    }

   
    getAccounts() {
  
        return this.http.get('assets/data/product.config.json')
            .toPromise()
            .then((response: any) => {

                const ret = [];

                for (const index in response) {
                    const accountType = response[index];
                    const accountTypeMetaData = this.accountTypeMetaData.find(x => x.type === accountType.type);
                    Object.assign(accountType, accountTypeMetaData);
                    // Utils.log('account type ', accountType); // this should have id, title, description
                    if (accountType.id === 'RIF' || accountType.id === 'Joint' || accountType.id === 'CASH') {
                        continue;
                    }
                    ret.push(accountType);

                }
                ret.push(
                    {
                        type: '100391-SPOUSAL',
                        id: 'SpousalRRSP',
                        title: 'selectAccount.accounts.SpousalRRSPTitle',
                        description: 'selectAccount.accounts.SpousalRRSPDescription',
                        dataAna: 'mywealth:select account:spousalrrsp',
                        productNameEn: SRRSP_EN,
                        productNameFr: SRRSP_FR
                    },
                    {
                        type: '100555',
                        id: 'RRIF',
                        title: 'selectAccount.accounts.RRIFTitle',
                        description: 'selectAccount.accounts.RRIFDescription',
                        dataAna: 'mywealth:select account:rrif',
                        productNameEn: RRIF_EN,
                        productNameFr: RRIF_FR
                    },
                    {
                        type: '100544-MARGIN',
                        id: 'MARGIN',
                        title: 'selectAccount.accounts.MARGINTitle',
                        description: 'selectAccount.accounts.MARGINDescription',
                        dataAna: 'mywealth:select account:margin',
                        productNameEn: MARGIN_EN,
                        productNameFr: MARGIN_FR
                    },
                    {
                        type: '100544',
                        id: 'CASH',
                        title: 'selectAccount.accounts.CASHTitle',
                        description: 'selectAccount.accounts.CASHDescription',
                        dataAna: 'mywealth:select account:cash',
                        productNameEn: CASH_EN,
                        productNameFr: CASH_FR,
                    }
                    // {
                    //     id: 'other',
                    //     type: 'other',
                    //     title: 'selectAccount.accounts.otherTitle',
                    //     description: 'selectAccount.accounts.otherDescription',
                    //     dataAna: 'mywealth:select account:other'
                    // }
                    );
                return ret;
            })
            .catch(e => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: 'true', apiCallType: "getAccounts" , 
                    apiCallResponseCode: e.error?.errorInfo ? e.status?.toString() + "-" + e.error?.errorInfo.errorCode.toString() : e.status?.toString()

                });
                // remove this call from mock when real service is working.
                /*return this.http.get('assets/data/accounts.mock.json')
                    .toPromise()
                    .then((response: any) => {
                        const ret = [];
                        for (const index in response) {
                            const accountType = response[index];
                            const accountTypeMetaData = this.accountTypeMetaData.find(x => x.type === accountType.type);
                            Object.assign(accountType, accountTypeMetaData);
                            if (accountType.id === 'RIF' || accountType.id === 'Joint') {
                                continue;
                            }
                            ret.push(accountType);
                        }
                        ret.push({
                            id: 'other',
                            title: 'selectAccount.accounts.otherTitle',
                            description: 'selectAccount.accounts.otherDescription',
                            dataAna: 'mywealth:select account:other'
                        });
                        return ret;
                    })
                    .catch(err => {
                    });*/

            });

    }
    /*
    getAccounts() {
        this.config = this.configService.getConfig();
        const productEligibilitiesURI = this.config.CHANNELS_API.CONTEXT_ROOT_MICROSERVICE + this.config.CHANNELS_API.PRODUCT_ELIGIBILITIES_URI;
        let header = this.config.CHANNELS_API.PRODUCT_ELIGIBILITIES_HEADER;
        header['X-BMO-REQUEST-ID'] = '' + Utils.getPartyRefId();
        // Utils.log('productEligibilitiesURI header: ' + header);
        header = new HttpHeaders(header);
        return this.http.get(productEligibilitiesURI, { headers: header })
            .toPromise()
            .then((response: any) => {

                const ret = [];

                for (const index in response) {
                    const accountType = response[index];
                    const accountTypeMetaData = this.accountTypeMetaData.find(x => x.type === accountType.type);
                    Object.assign(accountType, accountTypeMetaData);
                    // Utils.log('account type ', accountType); // this should have id, title, description
                    if (accountType.id === 'RIF' || accountType.id === 'Joint') {
                        continue;
                    }
                    ret.push(accountType);

                }
                ret.push(
                    // {
                    //     type: '100544-MARGIN',
                    //     id: 'MARGIN',
                    //     title: 'selectAccount.accounts.MARGINTitle',
                    //     description: 'selectAccount.accounts.MARGINDescription',
                    //     dataAna: 'mywealth:select account:margin',
                    //     productNameEn: MARGIN_EN,
                    //     productNameFr: MARGIN_FR
                    // },
                    {
                        type: '100391-SPOUSAL',
                        id: 'SpousalRRSP',
                        title: 'selectAccount.accounts.SpousalRRSPTitle',
                        description: 'selectAccount.accounts.SpousalRRSPDescription',
                        dataAna: 'mywealth:select account:spousalrrsp',
                        productNameEn: SRRSP_EN,
                        productNameFr: SRRSP_FR
                    },
                    {
                        id: 'other',
                        type: 'other',
                        title: 'selectAccount.accounts.otherTitle',
                        description: 'selectAccount.accounts.otherDescription',
                        dataAna: 'mywealth:select account:other'
                    });
                return ret;
            })
            .catch(e => {

                // remove this call from mock when real service is working.
              // comment  return this.http.get('assets/data/accounts.mock.json')
                    .toPromise()
                    .then((response: any) => {
                        const ret = [];
                        for (const index in response) {
                            const accountType = response[index];
                            const accountTypeMetaData = this.accountTypeMetaData.find(x => x.type === accountType.type);
                            Object.assign(accountType, accountTypeMetaData);
                            if (accountType.id === 'RIF' || accountType.id === 'Joint') {
                                continue;
                            }
                            ret.push(accountType);
                        }
                        ret.push({
                            id: 'other',
                            title: 'selectAccount.accounts.otherTitle',
                            description: 'selectAccount.accounts.otherDescription',
                            dataAna: 'mywealth:select account:other'
                        });
                        return ret;
                    })
                    .catch(err => {
                    }); //comment

            });

    }
    */

    setSelectedAccount(selectedAcc) {

        this.selectedAccount = selectedAcc;

        Utils.setSelectedAccount(this.selectedAccount);
        //this.accountType.next(this.selectedAccount.title);
        this.accountType.next(this.selectedAccount);

    }
    setMultiSelectedAccount(selectedAcc) {

        this.selectedAccount = selectedAcc;

        Utils.setMultiSelectedAccount(this.selectedAccount);
        //this.accountType.next(this.selectedAccount.title);
        this.accountType.next(this.selectedAccount);
    }

    getSelectedAccount() {

        return this.selectedAccount;
    }

    isRRSP() {
        return this.selectedAccount.type === 100391;
    }

    isCash() {
        return this.selectedAccount.type === 100544;
    }

    isTFSA() {
        return this.selectedAccount.type === 100562;
    }

    isJoint() {
        return this.selectedAccount.type === 100545;
    }

    isRIF() {
        return this.selectedAccount.type === 100555;
    }

    isSpousalRRSP() {
        return this.selectedAccount.type === '100391-SPOUSAL';
    }
}
