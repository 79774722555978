import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS } from '@angular/forms';

const noop = () => {};

/**
 * Example of how to use it:
 *
<app-sin-number
    id="whatever"
    name="appSinNumber"
    #appSinNumberCtrl="ngModel"
    [(ngModel)]="personalInformation.sinNumber2"
    [isInvalid]="appSinNumberCtrl.invalid && appSinNumberCtrl.touched || (isFormSubmit && appSinNumberCtrl.errors)"
    appSinValidator
    required>
</app-sin-number>

 * Created by Dilraj 2018 ;)
 */
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SinNumberComponent),
    multi: true
};

@Component({
    selector: 'app-sin-number',
    templateUrl: './sin.component.html',
    providers: [
      { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SinNumberComponent), multi: true }
    ],
})
export class SinNumberComponent implements OnInit, ControlValueAccessor {
    @Input() id: string;
    @Input() name: string;

    @Input() isInvalid: Boolean;
    @Input() isSinConflict :Boolean;

    constructor() {
    }

    ngOnInit() {
    }


    // The following code is to bind the sin 'value' to the ngModel in <app-sin-number [(ngModel)]="model.sinNumber"> :
    // The internal data model
    public innerValue: any = '';

    // Placeholders for the callbacks which are later provided
    // by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
    propagateChange: any = () => {};
    validateFn: any = () => {};

    // get accessor
    get value(): any {
        return this.innerValue;
    }

    // set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
        this.propagateChange(v);
    }

    // From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn) {
      this.propagateChange = fn;
    }

    // From ControlValueAccessor interface. Blur event trigged when   (blur)="onTouched($event)"
    onTouched(event: Event) {
      this.onTouchedCallback();
    }

    registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
    }
}
