import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhoneNumber'
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    const numbers = value.replace(/ +/g, '').replace('(', '').replace(')', '').replace('-', '');
    const char = {
      0: '(',
      3: ') ',
      6: '-'
    };
    value = '';
    for (let i = 0; i < numbers.length; i++) {
      value += (char[i] || '')  + numbers[i];
    }
    return value;
  }

}
