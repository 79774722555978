export class Jquery {
    private static noInteractiveElementsInModal: string = null;
    /**
     * run all jquery code
     */
    public static run() {

        $(document).ready(function () {



            // --- Disable default behaviour on links that are empty and marked with a "#"
            // ---------------------------------------------------------------------------
            $('a').each(function () {

                if ($(this).attr('href') == '#') {

                    $(this).click(function (e) {
                        e.preventDefault();
                    });

                }
            });



            // --- Form fields label toggle
            // ---------------------------------------------------------------------------
            $('input, select').each(function (index) {

                $('input, select').on('focus', function () {
                    $(this).next('label').addClass('active-label');
                });

                $('input, select').on('blur', function () {
                    if (($(this).val() !== '') && ($(this).val() !== null)) {
                        $(this).next('label').addClass('active-label');
                    } else {
                        $(this).next('label').removeClass('active-label');
                    }
                });

            });


            
            // --- Remove the "Skip to body" link if side navigation is not present
            // ---------------------------------------------------------------------------
            function showHideSkiplinks() {
                if (($('#main-nav').length === 0) || ($(window).outerWidth() < 1008)) {
                    $('.skipLinks').hide();
                } else {
                    $('.skipLinks').show();
                }
            }
            showHideSkiplinks();



            // --- Show/Hide the hamburger menu (and dependent elements) on pages where navigation is not present
            // ---------------------------------------------------------------------------
            function showHideHamburger() {
                if ($(window).outerWidth() > 1008) {

                    const button = $('#btn-progress img');

                    $('#main-nav').removeAttr('style class aria-hidden aria-controls');
                    $('#btn-progress').hide();
                    $('#btn-progress').removeAttr('aria-expanded');
                    $('.nav-modal-overlay').removeAttr('style');

                    if (button.hasClass('menu-up')) {
                        button.removeClass('menu-up').addClass('menu-down');
                        Jquery.modalDissableFocusTrapMenuUp();
                    }


                } else {
                    $('#main-nav').attr('aria-hidden', 'true');
                    $('#btn-progress').show();
                    $('#btn-progress').attr('aria-expanded', 'false').attr('aria-controls', 'main-nav');
                }
            }
            showHideHamburger();



            // --- Close menu when a link inside the menu is clicked and window size is smaller than 1008px
            // ---------------------------------------------------------------------------
            function closeSideNav() {
                if ($(window).outerWidth() < 1008) {
                    $('.main-navigation li a').unbind().click(function () {
                        $('#main-nav').removeAttr('style class');
                        $('.nav-modal-overlay').removeAttr('style');
                        const button = $('#btn-progress img');
                        if (button.hasClass('menu-up')) {
                            button.removeClass('menu-up').addClass('menu-down');
                            Jquery.modalDissableFocusTrapMenuUp();
                        }
                    });
                }
            }
            closeSideNav();

           // --- Close menu when back arrow is clicked and window size is smaller than 1008px
            // ---------------------------------------------------------------------------
            function closeSideNavOnClickBack() {
                if ($(window).outerWidth() < 1008) {
                    $('.progress-mobile .progress-back a').unbind().click(function () {
                        $('#main-nav').removeAttr('style class');
                        $('.nav-modal-overlay').removeAttr('style');
                        const button = $('#btn-progress img');
                        if (button.hasClass('menu-up')) {
                            button.removeClass('menu-up').addClass('menu-down');
                            Jquery.modalDissableFocusTrapMenuUp();
                        }
                    });
                }
            }
            closeSideNavOnClickBack();


            Jquery.modalScrollHeight();
            function hideHamburgerNoMenu() {
                if ($('#main-nav').length === 0) {
                    $('#btn-progress').hide();
                }
            }
            hideHamburgerNoMenu();



            // --- Nav main - hamburger button toggle
            // ---------------------------------------------------------------------------
            $('#btn-progress').unbind().click(function (e) {

                const button = $('#btn-progress img');

                if (button.hasClass('menu-down')) {
                    button.removeClass('menu-down').addClass('menu-up');
                    $('#btn-progress').attr('aria-expanded', 'true');
                    $('#main-nav').attr('aria-hidden', 'false');
                    Jquery.modalFocusTrapMenuDown();
                } else if (button.hasClass('menu-up')) {
                    button.removeClass('menu-up').addClass('menu-down');
                    $('#btn-progress').attr('aria-expanded', 'false');
                    $('#main-nav').attr('aria-hidden', 'true');
                    Jquery.modalDissableFocusTrapMenuUp();
                } else {
                    button.addClass('menu-down');
                    $('#btn-progress').attr('aria-expanded', 'false');
                    $('#main-nav').attr('aria-hidden', 'true');
                    Jquery.modalDissableFocusTrapMenuUp();
                }

                $('#main-nav').slideToggle('medium', function () {
                    $('#main-nav').toggleClass('show');
                });

                $('.nav-modal-overlay').fadeToggle(200);

                e.preventDefault();

            });



            // --- When resizing the window...
            // ---------------------------------------------------------------------------
            $(window).resize(function () {
                showHideHamburger();
                Jquery.modalScrollHeight();
                hideHamburgerNoMenu();
                closeSideNav();
                showHideSkiplinks();
            });


            Jquery.moreInformation();

        });
    }



    public static moreInformation() {
        // --- More information - show/hide of more information sections
        // ---
        $('.more-info-wrapper').each(function (index) {
            const $this = $(this);

            $this.find('.btn-more-info-show').on('click', function () {
                // console.log('here');
                $this.find('.btn-more-info-show').attr('aria-expanded', 'true');
                $this.find('.more-info-content').attr('aria-hidden', 'false');


                $this.find('.btn-more-info-show').attr('aria-expanded', 'true').fadeOut(100, function () {
                    $this.find('.more-info-content').slideDown(200);
                    $this.find('.more-info-content').prepend('<span class="aoda-off-screen-element">.</span>');
                    $this.find('.more-info-content .aoda-off-screen-element').attr('tabindex', '0');
                    $this.find('.more-info-content .aoda-off-screen-element').focus();
                    $this.find('.more-info-content .aoda-off-screen-element').removeAttr('tabindex');
                    $this.find('.btn-more-info-close').fadeIn(200);
                });
            });
            $this.find('.btn-more-info-close').on("click",function () {
                $this.find('.btn-more-info-close').fadeOut();
                $this.find('.btn-more-info-show').attr('aria-expanded', 'false');
                $this.find('.more-info-content').attr('aria-hidden', 'true');
                $this.find('.more-info-content').slideUp(200, function () {
                    $this.find('.btn-more-info-show').fadeIn(200);
                    $this.find('.btn-more-info-show').attr('aria-expanded', 'false');
                    $this.find('.more-info-content .aoda-off-screen-element').remove();
                    // $this.find('.btn-more-info-show').focus();
                });
            });
        });
    }

    // --- Calling scroll height separate - in case you need to wait for content to display, before calculating the scroll height.
    // ---------------------------------------------------------------------------
    public static modalScrollHeight() {
        const modalHeight = $('.bmo-modal').height();
        if ($(window).outerWidth() > 600) {
            $('.modal-content-scroll').height(modalHeight);
        } else {
            $('.modal-content-scroll').height(modalHeight - 20);
        }
    }



    // --- Focus trap on modal - keeping the keyboard users inside the modal, disable body scroll and hide body content for voice over users
    // --- Ideally, this should be called when the modal and its contents are visible
    // --- There is a time out of 100 miliseconds inside the function, by that time the first element should be visible to get focus
    // ---------------------------------------------------------------------------

    public static modalFocusTrap(name?) {

        var comp = name ? "." + name : '.bmo-modal';

        // - Getting all active elements from inside the modal and putting them in an array
        var arrayModalHtmlElements = $(comp).find('select, input, textarea, button, a');
        var focusElements=arrayModalHtmlElements;
        if (arrayModalHtmlElements.length === 0) {
            $(comp).attr('tabindex', -1);
            this.noInteractiveElementsInModal = comp;
        }
        var firstElement = focusElements.first();
        var lastElement = focusElements.last();

        $('header').attr('aria-hidden', 'true');
        $('nav').attr('aria-hidden', 'true');
        $('main').attr('aria-hidden', 'true');
        $('iframe').attr('aria-hidden', 'true');
        $('footer').attr('aria-hidden', 'true');
        $('body').css('overflow', 'hidden');

        //Find all interactive elements inside <header>, <nav>, <main>, <iframe> and <footer> and set tabindex="-1"
        $('header, nav, main, footer').find('select, input, textarea, button, a').attr('tabindex', '-1');

        // - Needs a delay in order to make sure first element is fully visible before focusing on it
        setTimeout(() => {

            if (this.noInteractiveElementsInModal) {
                $(this.noInteractiveElementsInModal).focus();
                $(document).on('keydown', this.noInteractiveElementsInModal, (e) => {
                    if (e.originalEvent.key === 'Tab') {
                        e.preventDefault();
                    }
                });
            } else {
                firstElement.focus();

                // - Redirect 'first shift + tab' to last element in the array
                firstElement.on('keydown', function (e) {

                    if ((e.originalEvent.which === 9) && e.shiftKey) {
                        e.preventDefault();
                        lastElement.focus();
                    }

                });

                // - Redirect 'last tab' to first element in the array
                lastElement.on('keydown', function (e) {

                    if ((e.originalEvent.which === 9) && !e.shiftKey) {
                        e.preventDefault();
                        firstElement.focus();
                    }
                });
            }
        }, 100);
    }

    public static modalFocusTrapCookie(name?) {

        var comp = name ? "." + name : '.bmo-modal';

        // - Getting all active elements from inside the modal and putting them in an array
        var arrayModalHtmlElements = $(comp).find('select, input, textarea, button, a');
        var focusElements=arrayModalHtmlElements;
        var firstElement = focusElements.first();
        var lastElement = focusElements.last();

        $('header').attr('aria-hidden', 'true');
        $('nav').attr('aria-hidden', 'true');
        $('main').attr('aria-hidden', 'true');
        $('iframe').attr('aria-hidden', 'true');
        $('footer').attr('aria-hidden', 'true');
        $('body').css('overflow', 'hidden');

        //Find all interactive elements inside <header>, <nav>, <main>, <iframe> and <footer> and set tabindex="-1"
        $('header, nav, main, footer').find('select, input, textarea, button, a').attr('tabindex', '-1');

        // - Needs a delay in order to make sure first element is fully visible before focusing on it
        setTimeout(function timeoutFunction() {

            lastElement.focus();

            // - redirect to last element for tab and shift + tab
            firstElement.on('keydown', function (e) {

                if ((e.originalEvent.which === 9)) {
                    e.preventDefault();
                    lastElement.focus();
                }

            });

            // - redirect to first element for tab and shift + tab
            lastElement.on('keydown', function (e) {

                if ((e.originalEvent.which === 9) ) {
                    e.preventDefault();
                    firstElement.focus();
                }
            });


        }, 100);

    }

    public static modalFocusTrapMenuDown(name?) {

        var comp = name ? "." + name : '.bmo-modal';

        // - Getting all active elements from inside the modal and putting them in an array
        var arrayModalHtmlElements = $(comp).find('select, input, textarea, button, a');
        var focusElements=arrayModalHtmlElements;
        var firstElement = focusElements.first();
        var lastElement = focusElements.last();

        $('main').attr('aria-hidden', 'true');
        $('footer').attr('aria-hidden', 'true');
        $('body').css('overflow', 'hidden');
        $('#headerAccountName').attr('aria-hidden', 'true');

        //Find all interactive elements inside <main> and <footer> and set tabindex="-1"
        $('main, footer').find('select, input, textarea, button, a').attr('tabindex', '-1');

        // - Needs a delay in order to make sure first element is fully visible before focusing on it
        setTimeout(function timeoutFunction() {

            firstElement.focus();

            // - Redirect 'first shift + tab' to last element in the array
            firstElement.on('keydown', function (e) {

                if ((e.originalEvent.which === 9) && e.shiftKey) {
                    e.preventDefault();
                    lastElement.focus();
                }

            });

            // - Redirect 'last tab' to first element in the array
            lastElement.on('keydown', function (e) {

                if ((e.originalEvent.which === 9) && !e.shiftKey) {
                    e.preventDefault();
                    firstElement.focus();
                }
            });


        }, 100);

    }

    // --- Remove the focus trap, re-enable body scroll and make body content visible for voice over users
    // --- Call this function on any close button that is present on the modal!
    // ---------------------------------------------------------------------------

    public static modalDissableFocusTrap(exitElementID?) {

        //Find all interactive elements inside <header>, <nav>, <main>, <iframe> and <footer> and remove the tabindex="-1"
        $('header, nav, main, footer').find('select, input, textarea, button, a').removeAttr('tabindex');

        $('header').removeAttr('aria-hidden');
        $('nav').removeAttr('aria-hidden');
        $('main').removeAttr('aria-hidden');
        $('iframe').removeAttr('aria-hidden');
        $('footer').removeAttr('aria-hidden');
        $('body').removeAttr('style');
        if (this.noInteractiveElementsInModal) {
            $(document).off('keydown', this.noInteractiveElementsInModal);
            $(this.noInteractiveElementsInModal).removeAttr('tabindex');
            this.noInteractiveElementsInModal = null;
        }
        let elementID = '#' + exitElementID;
        $(elementID).focus();

    }

    public static modalDissableFocusTrapMenuUp(exitElementID?) {

        //Find all interactive elements inside <header>, <main> and <footer> and remove the tabindex="-1"
        $('main, footer').find('select, input, textarea, button, a').removeAttr('tabindex');

        $('main').removeAttr('aria-hidden');
        $('footer').removeAttr('aria-hidden');
        $('body').removeAttr('style');
        $('#headerAccountName').removeAttr('aria-hidden');
        let elementID = '#' + exitElementID;
        $(elementID).focus();

    }



}
