
<div class="container-smallNoMenu margin-bottom" #branchPDFContent id="branchPDFContent">

    <p>

        <!-- <span *ngIf="!isOptions && !isProAccount && amlFlag">
            <span [innerHTML]="'nextByBranchPDF.accountNum' | translate | safeHtml" *ngIf="!isOptions && !isProAccount && amlFlag"></span>
            <strong *ngIf="!isOptions && !isProAccount && amlFlag">{{ accountNo }}</strong>
        </span><br /> -->

        <span [innerHTML]="'nextByBranchPDF.applicationNum' | translate | safeHtml"></span>
        <strong>{{ applicationNumber }}</strong><br />

        <span [innerHTML]="'nextByBranchPDF.applicantName' | translate | safeHtml"></span>
        <strong>{{ applicantName }}</strong>

    </p>

    <hr />

    <!-- Show All: Step 1-->
    <h1 translate="nextByBranchPDF.titleSection" class="add-margin t24px b24px"></h1>
    <h2 translate="nextByBranchPDF.descriptionStep1"  class="add-margin t32px b24px"></h2>
    <p class="add-margin b24px" [innerHTML]="'nextByBranchPDF.description01' | translate | safeHtml"></p>

    <h3 translate="nextByBranchPDF.description02"></h3>

    <ol class="decimal">
        <li translate="nextByBranchPDF.ol01Item01"></li>
        <li translate="nextByBranchPDF.ol01Item02"></li>
        <li translate="nextByBranchPDF.ol01Item03"></li>
        <li translate="nextByBranchPDF.ol01Item04"></li>
        <li translate="nextByBranchPDF.ol01Item05"></li>
    </ol>


        <h4 translate="nextByBranchPDF.description03"></h4>

        <div class="form-wrapper no-margin">
            <div class="form-row">
                <div class="flex-33">

                    <ul class="disc">
                        <li translate="nextByBranchPDF.ul01Item01"></li>
                        <li translate="nextByBranchPDF.ul01Item02"></li>
                        <li translate="nextByBranchPDF.ul01Item03"></li>
                        <li translate="nextByBranchPDF.ul01Item04"></li>
                        <li translate="nextByBranchPDF.ul01Item05"></li>
                        <li translate="nextByBranchPDF.ul01Item06"></li>
                        <li translate="nextByBranchPDF.ul01Item07"></li>
                    </ul>

                </div>
                <div class="flex-33">

                    <ul class="disc">
                        <li translate="nextByBranchPDF.ul02Item01"></li>
                        <li translate="nextByBranchPDF.ul02Item02"></li>
                        <li translate="nextByBranchPDF.ul02Item03"></li>
                        <li translate="nextByBranchPDF.ul02Item04"></li>
                        <li translate="nextByBranchPDF.ul02Item05"></li>
                        <li translate="nextByBranchPDF.ul02Item06"></li>
                    </ul>

                </div>
                <div class="flex-33">

                    <ul class="disc">
                        <li translate="nextByBranchPDF.ul03Item01"></li>
                        <li translate="nextByBranchPDF.ul03Item02"></li>
                        <li translate="nextByBranchPDF.ul03Item03"></li>
                        <li translate="nextByBranchPDF.ul03Item05"></li>
                        <li translate="nextByBranchPDF.ul03Item06"></li>
                    </ul>

                </div>
            </div>
        </div>
    <!--End Show All-->

    <!--Show RRIF-->
    <h3 translate="nextByMailPDF.titleSection_RRIF"  *ngIf="isRIF"></h3>
    <p [innerHTML]="'nextByMailPDF.description01_RRIF' | translate | safeHtml"  *ngIf="isRIF"></p>

    <h4 translate="nextByMailPDF.description02_RRIF"  *ngIf="isRIF"></h4>

    <ul class="disc"  *ngIf="isRIF">
        <li [innerHTML]="'nextByMailPDF.ul01Item01_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item02_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item03_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item04_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item05_RRIF' | translate | safeHtml"></li>
    </ul>


    <h4 translate="nextByMailPDF.description03_RRIF" *ngIf="isRIF" ></h4>
    <ul class="disc" *ngIf="isRIF">
        <li [innerHTML]="'nextByMailPDF.ul02Item01_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul02Item02_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul02Item03_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul02Item04_RRIF' | translate | safeHtml"></li>
    </ul>
    <!--End  RRIF-->


    <!--Show Pro Account-->
    <!--Step 2: Pro Only -->

    <span *ngIf="isProAccount">
        <h2 translate="nextByBranchPDF.descriptionStep2Pro"></h2>
        <p *ngIf="!isCIROEnabled" [innerHTML]="'nextByBranchPDF.description04' | translate | safeHtml"></p>
        <p *ngIf="isCIROEnabled" [innerHTML]="'nextByBranchPDF.description04_ciro' | translate | safeHtml"></p>
    </span>

    <div class="alert alert-print-note" *ngIf="isProAccount">

        <h2 translate="nextByBranchPDF.description05"></h2>
        <p translate="nextByBranchPDF.description06"></p>

        <h2 translate="nextByBranchPDF.description07"></h2>
        <p  translate="nextByBranchPDF.description08"></p>

        <h2 translate="nextByBranchPDF.description09"></h2>

        <ol class="decimal">
            <li translate="nextByBranchPDF.ol01ProItem01"></li>
            <li translate="nextByBranchPDF.ol01ProItem02"></li>
            <li translate="nextByBranchPDF.ol01ProItem03"></li>
        </ol>

    </div>

    <div class="form-row form-page-buttons hide-print">
        <div class="form-col left-side">
            <button class="primary" type="submit" onclick="window.print()" translate="button.print"></button>
        </div>
    </div>

</div>

