<form *ngIf="showPage">

    <div class="container-menuView">
        <app-critical-error *ngIf="systemCriticalError || referenceError"></app-critical-error>
        <section id="inProgressApplicationMainSection">

            <h1 [translateParams]={firstName:inprogressData.firstName} translate="inProgressApplication.heading"></h1>
            <p class="add-margin b40px" [innerHtml]="'inProgressApplication.subline01' | translate | safeHtml"></p>

            <div class="bmo-card-wrapper" id="inProgressApplicationDetailsSection">
                <div class="card-tile">
                    <!-- <h2 *ngIf="!isMultiAccountFlow" class="card-header" [innerHTML]="selectedAccountType | translate | safeHtml" ></h2> -->
                    <h2  class="card-header">
                        <ng-container *ngFor="let accountType of (accountTypeArray || []); index as index">
                            <span [innerHTML]="accountType.description | translate | safeHtml" ></span>
                        <span *ngIf="index < (accountTypeArray || []).length-1">&nbsp;|&nbsp;</span>
                        </ng-container>

                    </h2>
                    <p>
                        <span [innerHtml]="'inProgressApplication.labels.applicationNumber' | translate | safeHtml"></span><strong>{{inprogressData.ilNum}}</strong>
                        <br><span [innerHtml]="'inProgressApplication.labels.applicationDate' | translate | safeHtml"></span><strong>
                            {{'datePicker.ariaLabels.dpMonths.m'+((inprogressData.lastUpdatedTime | date: 'M') - 1) | translate }} {{inprogressData.lastUpdatedTime | date: 'dd, yyyy' }}</strong>

                    </p>
                </div>
            </div>

            <div class="form-wrapper" id="inProgressApplicationDeleteSection">
                <div class="form-row form-page-buttons">
                    <div class="form-col left-side">
                        <button (click)="onDelete()"
                            data-ana="mywealth:onboarding:no delete"
                            class="secondary"
                            type="button"
                            translate="inProgressApplication.labels.delete"
                            attr.aria-label="{{ 'inProgressApplication.ariaLabels.delete' | translate }}"></button>
                    </div>
                    <div class="form-col right-side">
                        <button (click)="onContinue()"
                            data-ana="mywealth:onboarding:yes continue"
                            class="primary"
                            type="button"
                            translate="inProgressApplication.labels.continue"
                            attr.aria-label="{{ 'inProgressApplication.ariaLabels.continue' | translate }}"></button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</form>
   <div *ngIf="!showPage" class="modal-overlay-component"></div>
