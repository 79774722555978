import { Component, OnInit, HostListener, ViewChild, ElementRef, Input, Output, EventEmitter ,AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { APPLICATION_STATUS, BMOFAQAD, BMO_CONTACT_YOU_AD, BMO_CONTACT_YOU_SD, BMO_FAQ_SD, dataCollectionPages } from '../../../config/app-config.constants';
import { ApplicationService } from '../../service/application.service';
import { Utils } from '../../utils/utils.class';



@Component({
    selector: 'app-drop-down-menu',
    templateUrl: 'drop-down-menu.component.html'
})
export class DropDownMenuComponent implements OnInit , AfterViewInit {

    @ViewChild('dropdownMenu', { static: false }) dropdownMenu: ElementRef;
    @Input() showNav;
    @Input() showCdbOlbNav;
    @Output() hideNav: EventEmitter<any> = new EventEmitter();
    @Output() hideCdbOlbNav: EventEmitter<any> = new EventEmitter();
    public isSubProductAD;
    @Input() clientFirstName: string = "";

    constructor(
        private translate: TranslateService,
        private route: ActivatedRoute,
        private applicationService: ApplicationService) {


    }

    ngOnInit() {
        this.route.queryParams.subscribe(ele => {
            this.isSubProductAD = ele.subProduct ? true : false;
        })
       
    }
   ngAfterViewInit() {
    if(this.showNav) {
        if(this.isSubProductAD) {
            document.getElementById('hideNav').focus();
        } else {
            document.getElementById('hideHelp').focus();
        }
    }
    if(this.showCdbOlbNav) {
        document.getElementById('hideCdbOlbAccount').focus();
    }
   }
    // Help Navigation header


    showHelpNav() {
        this.showNav = true;
        //document.getElementById('helpApplicationShowID').setAttribute('aria-expanded','true');
        //document.getElementById('helpApplicationNavID').setAttribute('aria-hidden','false');
    }

    hideHelpNav() {
        document.getElementById('helpApplicationShowID').focus();
        setTimeout(() => {
            this.hideNav.emit();
            this.showNav = false;
        }, 500);
        //document.getElementById('helpApplicationShowID').blur();
        //document.getElementById('helpApplicationShowID').setAttribute('aria-expanded','false');
        //document.getElementById('helpApplicationNavID').setAttribute('aria-hidden','true');
    }

    showAdWorks() {
        this.applicationService.showHowADworks$.next(true);
        this.hideNav.emit();
    }

    openFaq() {

        if (this.isSubProductAD) {
            window.open(BMOFAQAD[this.translate.currentLang], '_blank')
        }
        else {
            window.open(BMO_FAQ_SD[this.translate.currentLang], '_blank')
        }
        this.hideNav.emit();
    }

    openLetUsContactYou() {
        if (this.isSubProductAD) {
            window.open(BMO_CONTACT_YOU_AD[this.translate.currentLang], '_blank')
        }
        else {
            window.open(BMO_CONTACT_YOU_SD[this.translate.currentLang], '_blank')
        }
        this.hideNav.emit();
    }


    hideContactUsEnabled() {
        this.hideNav.emit();
        this.applicationService.showContactUsModel$.next(true);
    }

    @HostListener('document:keydown', ['$event'])
    @HostListener('document:mousedown', ['$event'])
    onKeyDown(evt: any) {
        if (
            evt.keyCode === 27 || 
            this.showNav === true &&
            this.dropdownMenu &&
            (!this.dropdownMenu.nativeElement.contains(evt.target)  &&  evt.keyCode !== 9)
        ) {
            //this.showNav = false;
            this.hideHelpNav();
        }
        if (
            evt.keyCode === 27 || 
            this.showCdbOlbNav === true &&
            this.dropdownMenu &&
            (!this.dropdownMenu.nativeElement.contains(evt.target)  &&  evt.keyCode !== 9)
        ) {
            if(evt.code === 'Space') {
                evt.preventDefault();
            } else {
                this.closeCdbOlbNav();
            }
           
        }
    }

    closeCdbOlbNav() {
        document.getElementById('cdbOlbAccountShowID').focus();
        setTimeout(() => {
            this.hideCdbOlbNav.emit();
            this.showCdbOlbNav = false;
        }, 500);
    }
    tabCloseCdbOlbNav() {
        this.hideCdbOlbNav.emit();
        this.showCdbOlbNav = false;
    }
    tabCloseNav(){
        this.hideNav.emit();
        this.showNav = false;
    }
    onExitApplication() {
       this.closeCdbOlbNav();
       if(dataCollectionPages.indexOf(this.applicationService.applicationPageName) >= 0) {
            this.applicationService.onExitApplicationPerformSave.emit(true);
       } else {
            this.applicationService.setExitApplicationModal(true);
       }
    }
    exitApplicationKey($event) {
        if ($event.code === 'Space') {
            this.onExitApplication();
            $event.preventDefault();
        }
    }
    closeCdbOlbNavKey($event) {
        if ($event.shiftKey && $event.code === 'Tab') {
            this.hideCdbOlbNav.emit();
            this.showCdbOlbNav = false;
         }
    }
}
