<!-- <app-flood-light></app-flood-light> -->

<!-- <div class="alert alert-danger" *ngIf="criticalError">
    <p>
        <strong translate="finalReview.errors.criticalError"></strong>
        <br />
        <span translate="finalReview.errors.criticalErrorDescription"></span>
    </p>
</div> -->
<app-critical-error *ngIf="criticalError"></app-critical-error>

<form (submit)="onSubmit()">

    <h1 translate="finalReview.titleSection"></h1>
    <p translate="finalReview.titleDescription"></p>

    <!-- Branch referral section-->
    <div *ngIf="BILAssisted" class="bmo-card-wrapper review-branch-referral">
        <div class="card-tile">

            <div class="bmo-container-flex flex-flow-nowrap review-title-section">
                <div class="flex-col-expand flex-xaxis-center review-title">
                    <h2 translate="finalReview.titleSectionBranchReferral"></h2>
                </div>
                <!-- removed for now. It may be introduced later
                    <div class="flex-equal-fixed review-button">
                    <a href="javascript://" [routerLink]="['/'+ APP_ROUTES.branch_referral]"[queryParams]="queryParams" data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit" aria-label="finalReview.ariaLabels.editBranchReferral"></a>
                </div> -->
            </div>

            <ul class="bmo-container-flex review-labels-section">
                <li>
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.lineOfBusiness' | translate | safeHtml"></span>
                    <span class="value-field" *ngIf="data('branchReferral', 'investmentPlatform') === productTypeAD"
                        translate="branchReferral.labels.adviceDirect"></span>
                    <span class="value-field" *ngIf="data('branchReferral', 'investmentPlatform') === productTypeSD"
                        translate="branchReferral.labels.selfDirect"></span>
                </li>
                <li>
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.designation' | translate | safeHtml"></span>
                    <span
                        class="value-field">{{getLabelFromReferences('branchReferral', 'branchPersonnel', reference.branchReferralDesignation)}}</span>
                </li>
            </ul>

            <ul class="bmo-container-flex review-labels-section">
                <li *ngIf="branchReferral.branchPersonnel === '101'">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.name' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'fsmName')}}</span>
                </li>
                <li *ngIf="branchReferral.branchPersonnel === '103'">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.fsmName' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'fsmName')}}</span>
                </li>
                <li *ngIf="branchReferral.branchPersonnel === '101' || branchReferral.branchPersonnel === '103'">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.ein' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'EIN')}}</span>
                </li>
                <li *ngIf="branchReferral.branchPersonnel === '103'">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.fsmPhone' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'fsmPhone')}}</span>
                </li>
                <li *ngIf="branchReferral.branchPersonnel === '101'">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.phone' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'fsmPhone')}}</span>
                </li>
                <li>
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.transit' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'transit')}}</span>
                </li>
            </ul>

            <ul class="bmo-container-flex review-labels-section">
                <li *ngIf="branchReferral.branchPersonnel === '102' || branchReferral.branchPersonnel === '103' ">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.fpName' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'fpName')}}</span>
                </li>
                <li *ngIf="branchReferral.branchPersonnel === '102' || branchReferral.branchPersonnel === '103'">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.fpCode' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'FPCode')}}</span>
                </li>
                <li *ngIf="branchReferral.branchPersonnel === '102' || branchReferral.branchPersonnel === '103'">
                    <span class="label-field"
                        [innerHTML]="'finalReview.labels.branchReferral.fpPhone' | translate | safeHtml"></span>
                    <span class="value-field">{{data('branchReferral', 'fpPhone')}}</span>
                </li>
            </ul>

        </div>
    </div>
    <!-- END - Branch referral section-->
        <!-- <div class="alert alert-check"
        *ngIf="(selectAccount.needOptionsTrading === 'Yes') || isPromocode">

        <p class="font-bold" translate="finalReview.labels.accountDetail.infoNote01"></p>

        <ul class="ul-dash no-margin">
            <li *ngIf="selectAccount.needShortSelling === 'Yes'">
                <span translate="finalReview.labels.accountDetail.labelShortSelling"></span>
            </li>

            <li *ngIf="selectAccount.needOptionsTrading === 'Yes'">

                <span translate="finalReview.labels.accountDetail.labelOptionstrading"></span>

                <ul *ngIf="selectAccount.needOptionsTrading === 'Yes'">
                    <li>
                        <span translate="finalReview.labels.accountDetail.lablelNumberYears"></span>
                        <span class="font-bold">{{data('selectAccount', 'yearsOfExperience')}}</span>
                    </li>
                    <li>
                        <span translate="finalReview.labels.accountDetail.labelOptionsTradingKnowledge"></span>
                        <span
                            class="font-bold">{{getLabelFromReferences('selectAccount', 'tradingKnowledgeLevel', reference.optionsKnowledge)}}</span>

                    </li>
                    <li>
                        <span translate="finalReview.labels.accountDetail.labelTypesOptions"></span>
                        <span
                            class="font-bold">{{getLabels(data('selectAccount', 'typesOfOptionsTradedBefore'))}}</span>

                    </li>
                    <li>
                        <span translate="finalReview.labels.accountDetail.labelOptionsSelected"></span>
                        <span class="font-bold">{{getLabels(data('selectAccount', 'typesOfOptionsTradedNow'))}}</span>
                    </li>

                </ul>

            </li>
            <li *ngIf="isPromocode && (isCashAccount || isRRSPAccount || isSpousalRRSP || isTFSAAccount)">
                <span translate="finalReview.labels.accountDetail.labelPromoCode"></span>
                <span class="font-bold">{{data('selectAccount', 'promocode')}}</span>
            </li>

        </ul>

    </div> -->
    <!-- Account Selection information section -->
        <div class="bmo-container-flex flex-flow-nowrap review-title-section">
            <div class="flex-col-expand flex-xaxis-center review-title">
                <h2 translate="finalReview.titleSectionAccountSelection"></h2>
            </div>
            <div class="flex-equal-fixed review-button">
                <a href="javascript://" [routerLink]="['/select-account']" [queryParams]="queryParams"
                    data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                    attr.aria-label="{{ 'finalReview.ariaLabels.editAccountSelection' | translate }}" (click)="setIsSelectAccountClicked()"></a>
            </div>
        </div>
        <ul id="accountSelectionInformationSection" class="bmo-container-flex review-labels-section" *ngIf="selfDirected && (selectAccount.needOptionsTrading === 'Yes')">

            <li id="accountSelectionInformationShortSelling" *ngIf="selfDirected && isMargin">
                <span class="label-field" translate="finalReview.labels.accountSelection.shortSelling"></span>
                <span class="value-field" *ngIf="selfDirected && (selectAccount.needShortSelling === 'Yes')" translate="finalReview.labels.yes"></span>
                <span class="value-field" *ngIf="selfDirected && (selectAccount.needShortSelling === 'No')" translate="finalReview.labels.no"></span>
            </li>
            <ng-container *ngIf="optionTradingMultiAccountEligible ; else notMultiAccountOpen"> <!-- option trading multi eligible acccount -->
                <ng-container *ngIf="selectAccount.optionsTradingForAllAccounts ; else optionsTradingSpecificAccounts"> <!-- option trading for all accounts checkbox checked -->
            <li *ngFor="let accountCode of selectAccount.selectedAccountTypes; let idx = index" [id]="'accountSelectionInformationOptions'+accountTypeId[accountCode]">
                <span  class="label-field" ></span>
                <p class="label-field">
                    <span [innerHTML]="'finalReview.labels.accountSelection.optionsFor' | translate | safeHtml"></span>
                    <span [innerHtml]="'finalReview.labels.accountTypesForOptions.individualOptions'+accountTypeId[accountCode] | translate | safeHtml"></span>
                </p>
                <span *ngIf="accountCode != CASH_CODE" class="value-field" translate="finalReview.labels.yes"></span>
                <span *ngIf="accountCode == CASH_CODE" class="value-field" translate="finalReview.labels.accountSelection.notEligible"></span>
            </li>
        </ng-container>
        <ng-template #optionsTradingSpecificAccounts> <!-- option trading for all accounts checkbox unchecked -->
            <li *ngFor="let accountCode of selectAccount.selectedAccountTypes; let idx = index" [id]="'accountSelectionInformationOptions'+accountTypeId[accountCode]">
                <p class="label-field">
                    <span [innerHTML]="'finalReview.labels.accountSelection.optionsFor' | translate | safeHtml"></span>
                    <span [innerHtml]="'finalReview.labels.accountTypesForOptions.individualOptions'+accountTypeId[accountCode] | translate | safeHtml"></span>
                </p>
                <span *ngIf="accountCode != CASH_CODE && selectAccount.optionTradingMulti[idx] == 'Yes' " class="value-field" translate="finalReview.labels.yes"></span>
                <span *ngIf="accountCode != CASH_CODE && selectAccount.optionTradingMulti[idx] == 'No' " class="value-field" translate="finalReview.labels.no"></span>
                <span *ngIf="accountCode == CASH_CODE" class="value-field" translate="finalReview.labels.accountSelection.notEligible"></span>
            </li>
        </ng-template>
        </ng-container>
         <ng-template #notMultiAccountOpen> <!-- single eligible acccount or feature flag disabled -->
            <li id="accountSelectionInformationOptions">
                <span class="label-field" translate="finalReview.labels.accountSelection.options"></span>
                <span class="value-field" translate="finalReview.labels.yes"></span>
            </li>
        </ng-template>
        <ng-container *ngIf="!optionTradingMultiAccountEligible || selectAccount.optionsTradingForAllAccounts || selectAccount.optionTradingMulti?.includes('Yes')">
            <li *ngIf="!isSTPOptionsEnabled" id="accountSelectionInformationOptionsYears">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsYears"></span>
                <span class="value-field">{{data('selectAccount', 'yearsOfExperience')}}</span>
            </li>

            <li *ngIf="selectAccount.hasTradingKnowledge && isSTPOptionsEnabled" id="accountSelectionInformationOptionsYears">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsYears"></span>
                <span class="value-field">{{getLabelFromReferencesData('selectAccount', 'yearsOfExperience', reference.pwobTradingExperience)}}</span>
            </li>

            <li id="accountSelectionInformationOptionsKnowledge">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsKnowledge"></span>
                <span class="value-field">{{getLabelFromReferences('selectAccount', 'tradingKnowledgeLevel', reference.optionsKnowledge)}}</span>
            </li>

            <li *ngIf = "!isSTPOptionsEnabled" id="accountSelectionInformationOptionsHistory">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsHistory"></span>
                <span class="value-field">{{getLabels(data('selectAccount', 'typesOfOptionsTradedBefore'))}}</span>
            </li>

            <li *ngIf="!isSTPOptionsEnabled" id="accountSelectionInformationOptionsFuture">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsFuture"></span>
                <span class="value-field">{{getLabels(data('selectAccount', 'typesOfOptionsTradedNow'))}}</span>
            </li>

            <li *ngIf="!optionTradingMultiAccountEligible && selectAccount.hasTradingKnowledge && isSTPOptionsEnabled" id="accountSelectionInformationOptionsFuture">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsFuture"></span>
                <span class="value-field">{{getLabels(data('selectAccount', 'typesOfOptionsTradedNow'))}}</span>
            </li>
            <li *ngIf="optionTradingMultiAccountEligible && selectAccount.hasTradingKnowledge && isSTPOptionsEnabled" id="accountSelectionInformationOptionsFuture">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsFuture"></span>
                <span class="value-field">{{getLabelsTrading(data('selectAccount', 'typesOfOptionsTradedNow'))}}</span>
            </li>
            <li id="accountSelectionInformationOptionsPromo">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsPromo"></span>
                <span *ngIf="isPromocode" class="value-field">{{data('selectAccount', 'promocode')}}</span>
                <span *ngIf="!isPromocode" class="value-field" translate="finalReview.labels.none"></span>
            </li>
        </ng-container>
        </ul>
        <ul id="optionsTradingPromoSection" class="bmo-container-flex review-labels-section" *ngIf="selfDirected && (selectAccount.needOptionsTrading === 'No')">

            <li *ngIf="selfDirected && isMargin" id="optionsTradingPromoShortSelling">
                <span class="label-field" translate="finalReview.labels.accountSelection.shortSelling"></span>
                <span class="value-field" *ngIf="selfDirected && (selectAccount.needShortSelling === 'Yes')" translate="finalReview.labels.yes"></span>
                <span class="value-field" *ngIf="selfDirected && (selectAccount.needShortSelling === 'No')" translate="finalReview.labels.no"></span>
            </li>
            <li  id="optionsTradingPromoOptions">
                <span class="label-field" translate="finalReview.labels.accountSelection.options"></span>
                <span class="value-field" translate="finalReview.labels.no"></span>
            </li>

            <li  id="optionsTradingPromoOptionsPromo">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsPromo"></span>
                <span *ngIf="isPromocode" class="value-field">{{data('selectAccount', 'promocode')}}</span>
                <span *ngIf="!isPromocode" class="value-field" translate="finalReview.labels.none"></span>
            </li>
        </ul>
        <ul id="promoCodeSection" class="bmo-container-flex review-labels-section" *ngIf="adviceDirect || !selectAccount.needOptionsTrading">
            <li id="promoCodeOptionsPromo">
                <span class="label-field" translate="finalReview.labels.accountSelection.optionsPromo"></span>
                <span *ngIf="isPromocode" class="value-field">{{data('selectAccount', 'promocode')}}</span>
                <span *ngIf="!isPromocode" class="value-field" translate="finalReview.labels.none"></span>
            </li>
        </ul>
        <ul class="bmo-container-flex review-labels-section" *ngIf="isCsaCfrAttorneyEnabled && setExpectation && setExpectation.isBmoRightForMe !== ''">
            <li id="accountSelectionInformationPOAIsILRightForYou">
                <span class="label-field" translate="finalReview.labels.accountSelection.poaIsILRightForYou"></span>
                <span class="value-field" translate="button.no"></span>
            </li>
            <li id="accountSelectionInformationUsedProductService">
                <span class="label-field" translate="finalReview.labels.accountSelection.usedProductService"></span>
                <span class="value-field" translate="{{getSQ1Response()}}"></span>
            </li>
            <li id="accountSelectionInformationRequireInvestmentAdvice">
                <span *ngIf="selfDirected" class="label-field" translate="finalReview.labels.accountSelection.requireInvestmentAdviceSD"></span>
                <span *ngIf="adviceDirect" class="label-field" translate="finalReview.labels.accountSelection.requireInvestmentAdviceAD"></span>
                <span class="value-field" translate="{{getSQ2Response()}}"></span>
            </li>
        </ul>
        <ul id="referralDetailsSection" class="bmo-container-flex review-labels-section" *ngIf="enableRSM && isBMONetwork && selectAccount.isBmoStaff === 'Yes'">
            <li id="referralDetailsOptionsPartner">
                <span class="label-field" translate="finalReview.labels.accountSelection.referralPartner"></span>
                <span class="value-field">
                    {{getLabelFromReferences('selectAccount', 'BmoStaffReferrer', reference.pwobRsmDesignation)}}
                </span>
            </li>
            <li id="referralDetailsOptionsReferralCode"  *ngIf="selectAccount.BmoStaffReferrer == rsmReferalRole.RSM_FP_IFS_PRIVATE_BANKER">
                <span class="label-field" translate="finalReview.labels.accountSelection.referralCode"></span>
                <span class="value-field"> {{data('selectAccount', 'referralCode')}}</span>
            </li>
            <li id="referralDetailsOptionsTransitNumber">
                <span class="label-field" translate="finalReview.labels.accountSelection.transitNumber"></span>
                <span class="value-field"> {{data('selectAccount', 'transit')}}</span>
            </li>
            <li id="referralDetailsOptionsPartner"  *ngIf="selectAccount.isAdditionalReferral === 'Yes' || selectAccount.BmoStaffReferrer == rsmReferalRole.PERSONAL_BANKER">
                <span class="label-field" translate="finalReview.labels.accountSelection.staffReferralEIN"></span>
                <span class="value-field"> {{data('selectAccount', 'EIN')}}</span>
            </li>
        </ul>

    <!-- END Account Selection information section -->
    <!-- Personal information section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionPersonalInformation"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/personal-information']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editPersonalInformation' | translate }}"></a>
        </div>
    </div>

    <ul id="personalInformationSection" class="bmo-container-flex review-labels-section">
        <li id="personalInformationApplicantName" class="force-minwidth">
            <span class="label-field" translate="finalReview.labels.applicantName"></span>
            <span class="value-field">
                 <span> {{data('personalInformation', 'firstname')}} </span>
                <!--MWB-13839 Remove Prefix Fields Data Rationalization
                     <span *ngIf="data('personalInformation', 'prefixLabel') !== 'NA'">{{data('personalInformation', 'prefixLabel')}}</span>
                {{data('personalInformation', 'firstname')}}     -->
                <span *ngIf="!isValueNull(data('personalInformation', 'initial'))"> {{data('personalInformation', 'initial')}} </span>
                <span> {{data('personalInformation', 'lastname')}} </span>
            </span>
        </li>
        <li id="personalInformationApplicantPreferredName" *ngIf="data('personalInformation', 'likeToUsePreferredName') && isAMLFunctionalityEnabled">
            <span class="label-field" translate="finalReview.labels.applicantPreferredName"></span>
            <span class="value-field">
                <span> {{data('personalInformation', 'preferredFirstName')}} </span>
                <span *ngIf="!isValueNull(data('personalInformation', 'preferredMiddleName'))"> {{data('personalInformation', 'preferredMiddleName')}} </span>
                <span> {{data('personalInformation', 'preferredLastName')}} </span>
            </span>
        </li>
        <li id="personalInformationDOB">
            <span class="label-field" translate="finalReview.labels.DOB"></span>
            <span class="value-field">
                <!--September 30, 1980-->
                {{moment(data('personalInformation', 'dateOfBirth')).format("MMMM D, YYYY")}}
            </span>
        </li>
        <li id="personalInformationSin">
            <span class="label-field" [innerHTML]="'finalReview.labels.sin' | translate | safeHtml"></span>
            <span class="value-field">{{data('personalInformation', 'sinNumber').split("-").join(" ")}}</span>
        </li>
        <li id="personalInformationCitizenship" *ngIf="data('personalInformation', 'citizenship')?.trim()">
            <span class="label-field" translate="finalReview.labels.citizenship"></span>
            <span class="value-field">
                {{data('personalInformation', 'citizenshipLabel')}}
            </span>
        </li>
        <li id="canadianTaxResidency" *ngIf="(isCashAccount || isMargin) && data('personalInformation', 'isCanadaTaxResidency') && isCaaTaxResidencyCertificateEnabled">
            <span class="label-field" translate="finalReview.labels.canadianTaxResidency"></span>
            <span class="value-field">{{mapCanadaTaxResidencyValue(data('personalInformation','isCanadaTaxResidency'))}}</span>
        </li>

        <li id="usTaxResidency" *ngIf="(isCashAccount || isMargin) && (data('personalInformation', 'usResidentCheckBox') || data('personalInformation', 'usTaxResidentCheckBox')) && isCaaTaxResidencyCertificateEnabled">
            <span class="label-field" translate="finalReview.labels.cctCitizenship"></span>
            <span class="value-field">{{ usCitizenshipTaxResidencyString }}</span>
        </li>
        <li id="personalInformationAlternateCitizenship" *ngIf="(isRRSPAccount || isSpousalRRSP || isRRIF) && data('personalInformation', 'alternateCitizenshipLabelRRSP') && !isCashAccount && !isMargin && !isTFSAAccount">
            <span class="label-field" translate="finalReview.labels.citizenship"></span>
            <span class="value-field">
                {{data('personalInformation', 'alternateCitizenshipLabelRRSP')}}
            </span>
        </li>
        <!--Can Only get here if TFSA/Cash:-->
        <li id="personalInformationCctCitizenship"
            *ngFor="let countryCitizenshipTaxResidency of getCountriesNew">
            <span class="label-field" translate="finalReview.labels.cctCitizenship"></span>
            <span class="value-field">{{countryCitizenshipTaxResidency.countryCitizenshipTaxResidencyString}}</span>
        </li>
        <li id="personalInformationEmail">
            <span class="label-field" translate="finalReview.labels.email"></span>
            <span class="value-field">{{data('personalInformation', 'emailAddress')}}</span>
        </li>

    </ul>
    <!-- END - Personal information section -->
    <!-- Contact information section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionContactInformation"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/contact-information']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editContactInformation' | translate }}"></a>
        </div>
    </div>

    <ul id="contactInformationSection" class="bmo-container-flex review-labels-section">
        <li id="contactInformationPhoneNumber">
            <span *ngIf="isDataRationalizationEnabled" class="label-field" translate="finalReview.labels.branchReferral.phone"></span>
            <span *ngIf="!isDataRationalizationEnabled && data('contactInformation', 'phoneType')==='contactInformation.labels.phoneMobile'"
                class="label-field" translate="finalReview.labels.phoneMobile"></span>
            <span *ngIf="!isDataRationalizationEnabled && data('contactInformation', 'phoneType')==='contactInformation.labels.phoneHome'"
                class="label-field" translate="finalReview.labels.phoneHome"></span>
            <span *ngIf="!isDataRationalizationEnabled && data('contactInformation', 'phoneType')==='contactInformation.labels.phoneWork'"
                class="label-field" translate="finalReview.labels.phoneWork"></span>
            <span class="value-field">{{data('contactInformation', 'phoneNumber')}}</span>
        </li>
        <li id="contactInformationPhoneExtension"
        *ngIf="(!isDataRationalizationEnabled && (data('contactInformation', 'phoneType')==='contactInformation.labels.phoneWork')) &&
        (data('contactInformation', 'phoneExtension')!='')
        && (data('contactInformation', 'phoneExtension')!== null)
        && (data('contactInformation', 'phoneExtension')!== undefined) ||
       (isDataRationalizationEnabled && (data('contactInformation', 'phoneExtension')!='')
        && (data('contactInformation', 'phoneExtension')!== null)
        && (data('contactInformation', 'phoneExtension')!== undefined)) ">
            <span class="label-field" translate="contactInformation.labels.phoneExtension"></span>
            <span class="value-field">{{data('contactInformation', 'phoneExtension')}}</span>
        </li>

        <li id="contactInformationAddress">
            <span class="label-field" translate="finalReview.labels.address"></span>
            <span class="value-field">
                <!--104 Canada Dr, St John's, Newfoundland, A1E 2M8-->
                {{data('contactInformation', 'unit')}}
                <span *ngIf="data('contactInformation', 'unit')">-</span>
                {{data('contactInformation', 'streetNumberName')}}, {{data('contactInformation', 'city')}}, {{ data('contactInformation',
                'provinceLabel') }}, {{data('contactInformation', 'postalCode') | formatPostalCode}}
            </span>
        </li>
        <li id="contactInformationMailing" *ngIf="data('contactInformation', 'sameMailingAddress') === 'No'">
            <span class="label-field" translate="finalReview.labels.mailing"></span>
            <span class="value-field">
                <!--P.O. Box 13, St John's, Newfoundland, A1E 2MB-->
                {{data('contactInformation', 'diffUnit')}}
                <span *ngIf="data('contactInformation', 'diffUnit')">-</span>
                {{data('contactInformation', 'diffStreetNumberName')}}, {{data('contactInformation', 'diffCity')}}, {{ data('contactInformation',
                'diffProvinceLabel') }}, {{data('contactInformation', 'diffPostalCode') | formatPostalCode}}
            </span>
        </li>
    </ul>
    <!-- TCP section start -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSubSectionTcp"></h2>
        </div>
    </div>
    <ng-container *ngIf="!data('contactInformation', 'trustedContactPerson').isTrustedPerson">
        <ul id="TCPSection" class="bmo-container-flex review-labels-section">
            <li id="TCPSectionNo">
                <span class="value-field"  translate="finalReview.labels.no"></span>
            </li>

        </ul>
    </ng-container>
    <ng-container *ngIf="data('contactInformation', 'trustedContactPerson').isTrustedPerson">
        <ul id="reviewTcpSection" class="bmo-container-flex review-labels-section">
            <li id="reviewTcpName">
                <span class="label-field" translate="finalReview.labels.reviewTcp.name"></span>

                <span class="value-field">
                    {{data('contactInformation', 'trustedContactPerson').firstname}}
                    <span *ngIf="data('contactInformation', 'trustedContactPerson').initial">{{(data('contactInformation', 'trustedContactPerson').initial).toUpperCase()}}</span>
                    {{data('contactInformation', 'trustedContactPerson').lastname}}
                </span>

            </li>

            <li id="reviewTcpCountry" *ngIf="data('contactInformation', 'trustedContactPerson').country">
                <span class="label-field" translate="finalReview.labels.reviewTcp.country"></span>
                <span class="value-field"  appCodeToLabel [model]="data('contactInformation', 'trustedContactPerson').country" [map]="reference.countries"></span>
            </li>
            <li id="reviewTcpAddress" *ngIf="isTcpAdrressAvailable()">
                <span class="label-field" translate="finalReview.labels.reviewTcp.address"></span>
                <span class="value-field"  >
                    <span *ngIf="data('contactInformation', 'trustedContactPerson').unit">{{data('contactInformation', 'trustedContactPerson').unit}}</span>
                    <span *ngIf="data('contactInformation', 'trustedContactPerson').unit && data('contactInformation', 'trustedContactPerson').streetNumberName">&nbsp;-</span>
                    <span *ngIf="data('contactInformation', 'trustedContactPerson').streetNumberName">{{data('contactInformation', 'trustedContactPerson').streetNumberName}}</span>
                    <span *ngIf="(data('contactInformation', 'trustedContactPerson').unit || data('contactInformation', 'trustedContactPerson').streetNumberName) && data('contactInformation', 'trustedContactPerson').city">, </span>
                    <span *ngIf="data('contactInformation', 'trustedContactPerson').city">{{data('contactInformation', 'trustedContactPerson').city}}</span>
                    <span *ngIf="(data('contactInformation', 'trustedContactPerson').unit || data('contactInformation', 'trustedContactPerson').streetNumberName || data('contactInformation', 'trustedContactPerson').city) && data('contactInformation', 'trustedContactPerson').province">, </span>
                    <span appCodeToLabel [model]="data('contactInformation', 'trustedContactPerson').province" [map]="getCountryProvince(data('contactInformation', 'trustedContactPerson').country)" *ngIf="data('contactInformation', 'trustedContactPerson').province"></span >
                    <span *ngIf="(data('contactInformation', 'trustedContactPerson').unit || data('contactInformation', 'trustedContactPerson').streetNumberName || data('contactInformation', 'trustedContactPerson').city || data('contactInformation', 'trustedContactPerson').province) && data('contactInformation', 'trustedContactPerson').postalCode">, </span>
                    <span *ngIf="data('contactInformation', 'trustedContactPerson').postalCode">{{data('contactInformation', 'trustedContactPerson').postalCode}}</span>

                </span>

            </li>
            <li id="reviewTcpEmail" *ngIf="data('contactInformation', 'trustedContactPerson').emailAddress">
                <span class="label-field" translate="finalReview.labels.reviewTcp.email"></span>
                <span class="value-field" >{{data('contactInformation', 'trustedContactPerson').emailAddress}}</span>
            </li>
            <li id="reviewTcpPhoneNumberHome" *ngIf="data('contactInformation', 'trustedContactPerson').phoneNumberHome">
                <span class="label-field" translate="finalReview.labels.reviewTcp.phoneNumberHome"></span>
                <span class="value-field" >{{data('contactInformation', 'trustedContactPerson').phoneNumberHome}}</span>
            </li>
             <li id="reviewTcpPhoneNumberOther" *ngIf="data('contactInformation', 'trustedContactPerson').phoneNumberOther">
                <span class="label-field" translate="finalReview.labels.reviewTcp.phoneNumberOther"></span>
                <span class="value-field" >{{data('contactInformation', 'trustedContactPerson').phoneNumberOther}}</span>
            </li>
            <li id="reviewTcpRelationShip">
                <span class="label-field" translate="finalReview.labels.reviewTcp.relationShip"></span>
                <span class="value-field" >{{data('contactInformation', 'trustedContactPerson').tcpRelationShip}}</span>
            </li>
        </ul>
    </ng-container>

    <!-- TCP section End -->
    <!-- END - Contact information section -->
    <!-- Employment information section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionEmployement"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/employment-information']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editEmploymentDetails' | translate }}"></a>
        </div>
    </div>

    <ul id="employmentInformationSection" class="bmo-container-flex review-labels-section">
        <li id="employmentInformationEmployment">
            <span class="label-field" translate="finalReview.labels.employment"></span>
            <span class="value-field">{{employmentInfoDropDownData.jobStatusLabel}}</span>
        </li>
       
        <li id="employmentInformationPreviousOccupation" *ngIf="(employmentInformation && (employmentInformation.employmentStatus === RETIRED && !isEmploymentDetailsRetired)) || (employmentInformation && (employmentInformation.employmentStatus === RETIRED && (isEmploymentDetailsRetired && employmentInformation.selectPreviousOccupation)))">
            <span class="label-field" translate="finalReview.labels.previousOccupation"></span>
            <span class="value-field">{{employmentInfoDropDownData.prevOccupationLabel}}</span>
        </li>
        <li id="employmentInformationBusiness"
            *ngIf="(employmentInformation && (employmentInformation.employmentStatus === RETIRED && !isEmploymentDetailsRetired) && employmentInfoDropDownData?.natureOfBusinessLabel?.length > 0 )|| employmentInformation && (employmentInformation.employmentStatus === RETIRED && isEmploymentDetailsRetired && employmentInformation.selectNatureOfBusiness)">
            <span class="label-field" translate="finalReview.labels.business"></span>
            <span class="value-field">{{employmentInfoDropDownData.natureOfBusinessLabel}}</span>
        </li>
        <li id="employmentInformationOccupation"
            *ngIf="employmentInformation && employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED">
            <span class="label-field" translate="finalReview.labels.occupation"></span>
            <span class="value-field">{{employmentInfoDropDownData.occupationLabel}}</span>
        </li>
        <li id="employmentInformationJob"
            *ngIf="employmentInformation && employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED && data('employmentInformation', 'jobTitle')">
            <span class="label-field" translate="finalReview.labels.job"></span>
            <span class="value-field">{{data('employmentInformation', 'jobTitle')}}</span>
        </li>
        <li id="employmentInformationBusiness"
            *ngIf="employmentInformation && employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED">
            <span class="label-field" translate="finalReview.labels.business"></span>
            <span class="value-field">{{employmentInfoDropDownData.natureOfBusinessLabel}}</span>
        </li>
        <li id="employmentInformationEmployer"
            *ngIf="employmentInformation && employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED">
            <span class="label-field" translate="finalReview.labels.employer"></span>
            <span class="value-field">{{data('employmentInformation', 'employerName')}}</span>
        </li>
        <li id="employmentInformationBmoGroupName"
            *ngIf="employmentInformation && employmentInformation.bmoGroupOption !== null && employmentInformation.bmoGroupOption !== undefined">
            <span class="label-field" translate="finalReview.labels.bmoGroupName"></span>
            <span class="value-field">{{employmentInfoDropDownData.bmoGroupOption}}</span>
        </li>
        <!-- commented for MWB-13883 -->
        <li id="employmentInformationEmployerAddress"
            *ngIf="employmentInformation  && employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED && isAMLFunctionalityEnabled && employmentInfoDropDownData?.countryLabel">
            <span class="label-field" translate="finalReview.labels.employerAddress"></span>
            <span class="value-field">
                <span
                    *ngIf="data('employmentInformation', 'unit') != ''&& data('employmentInformation', 'unit')!== null && data('employmentInformation', 'unit')!== undefined">{{data('employmentInformation', 'unit') + "-" }}</span>
                {{data('employmentInformation', 'streetNumberName')}}, {{data('employmentInformation', 'city')}},
                <span *ngIf="data('employmentInformation', 'selectCountry') ===  COUNTRYCANADA">
                    {{employmentInfoDropDownData.provinceLabel}},
                    {{data('employmentInformation', 'postalCode') | formatPostalCode}},
                    {{employmentInfoDropDownData.countryLabel}}
                </span>
                <span *ngIf="data('employmentInformation', 'selectCountry') ===  COUNTRYUSA">
                    {{employmentInfoDropDownData.stateLabel}}, {{data('employmentInformation', 'zipCode')}},
                    {{employmentInfoDropDownData.countryLabel}}
                </span>
                <span
                    *ngIf="data('employmentInformation', 'selectCountry') !==  COUNTRYCANADA && data('employmentInformation', 'selectCountry') !==  COUNTRYUSA">
                    {{employmentInfoDropDownData.countryLabel}}
                </span>
            </span>
        </li>
    </ul>
    <!-- END - Employment information section -->
    <!-- Family information section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionfamilyInformation"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/family-information']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editFamilyInformation' | translate }}"></a>
        </div>
    </div>

    <ul id="familyInfoDropDownDataSection" class="bmo-container-flex review-labels-section">
        <li id="familyInformationMaritalStatus" *ngIf="familyInformation">
            <span class="label-field" translate="finalReview.labels.maritalStatus"></span>
            <span *ngIf="!isSpousalRRSP" class="value-field">{{familyInfoDropDownData.maritalStatusLabel}}</span>
            <span *ngIf="isSpousalRRSP" class="value-field">{{familyInfoDropDownData.spousalMaritalStatusLabel}}</span>
        </li>

    </ul>

    <ul id="familyInformationDetailsSection" class="bmo-container-flex review-labels-section" *ngIf="familyInformation && familyInformation.isMarried()">
        <li  id="familyInformationSpouseName" >
            <span class="label-field" translate="finalReview.labels.spouseName"></span>
            <span class="value-field">
                {{data('familyInformation', 'firstName')}} {{data('familyInformation', 'initial').toUpperCase() }} {{ data('familyInformation',
                'lastName') }}
            </span>
        </li>
        <li  id="familyInformationSpouseSIN" *ngIf="isSpousalRRSP">
            <span class="label-field" [innerHTML]="'finalReview.labels.spouseSIN' | translate | safeHtml"></span>
            <span class="value-field">{{data('familyInformation', 'sinNumber') | sinNumber}}</span>
        </li>
        <li  id="familyInformationSpouseEmploymentStatus">
            <span class="label-field" translate="finalReview.labels.spouseEmploymentStatus"></span>
            <span class="value-field">{{familyInfoDropDownData.jobStatusLabel}}</span>
        </li>
        <li  id="familyInformationSpouseOccupation"
            *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED">
            <span class="label-field" translate="finalReview.labels.spouseOccupation"></span>
            <span class="value-field">{{familyInfoDropDownData.occupationLabel}}</span>
        </li>
        <li  id="familyInformationSpouseJobTitle"
            *ngIf="data('familyInformation', 'jobTitle')  && familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED">
            <span class="label-field" translate="finalReview.labels.spouseJobTitle"></span>
            <span class="value-field">{{data('familyInformation', 'jobTitle')}}</span>
        </li>
        <li  id="familyInformationSpouseNatureOfBusiness"
            *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED">
            <span class="label-field" translate="finalReview.labels.spouseNatureOfBusiness"></span>
            <span class="value-field">{{familyInfoDropDownData.natureOfBusinessLabel}}</span>
        </li>
        <li  id="familyInformationSpouseEmployerBusinessName"
            *ngIf="familyInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && familyInformation.employmentStatus !== RETIRED">
            <span class="label-field" translate="finalReview.labels.spouseEmployerBusinessName"></span>
            <span class="value-field">{{data('familyInformation', 'employerName')}}</span>
        </li>
        <li  id="familyInformationBmoGroupName" *ngIf="familyInformation.bmoGroupOption !== null && familyInformation.bmoGroupOption !== undefined">
            <span class="label-field" translate="finalReview.labels.bmoGroupName"></span>
            <span class="value-field">{{familyInfoDropDownData.bmoGroupOption}}</span>
        </li>
        <li  id="familyInformationAppointSpouseHolder"
            *ngIf="familyInformation.hasSuccessorAnnuitant!=null && data('contactInformation', 'provinceLabel') !== 'Quebec'">
            <span class="label-field" translate="finalReview.labels.familyInformation.appointSpouseHolder"></span>
            <span *ngIf=" familyInformation.hasSuccessorAnnuitant === true" class="value-field"
                translate="finalReview.labels.yes"></span>
            <span *ngIf=" familyInformation.hasSuccessorAnnuitant === false" class="value-field"
                translate="finalReview.labels.no"></span>
        </li>
        <li  id="familyInformationSin"
            *ngIf="familyInformation.hasSuccessorAnnuitant=== true && data('contactInformation', 'provinceLabel') !== 'Quebec'">
            <span class="label-field" translate="finalReview.labels.familyInformation.sin"></span>
            <span class="value-field">{{data('familyInformation', 'sinNumber') | sinNumber}}</span>
        </li>
        <li  id="familyInformationDob"
            *ngIf="familyInformation.hasSuccessorAnnuitant=== true && data('contactInformation', 'provinceLabel') !== 'Quebec'">
            <span class="label-field" translate="finalReview.labels.familyInformation.dob"></span>
            <span class="value-field">
                {{moment(familyInformation.spouseDateOfBirth).format("MMMM D, YYYY")}}
            </span>
            <!--<span class="value-field">{{familyInformation.spouseDateOfBirth}}</span>-->
        </li>
    </ul>
    <ul id="isSpousalRRSPSection" class="bmo-container-flex review-labels-section" *ngIf="familyInformation && !familyInformation.isMarried()">
        <li  id="familyInformationSpouseSinNumber" *ngIf="isSpousalRRSP">
            <span class="label-field" [innerHTML]="'finalReview.labels.spouseSIN' | translate | safeHtml"></span>
            <span class="value-field">{{data('familyInformation', 'sinNumber') | sinNumber}}</span>
        </li>
        <li  id="familyInformationDependantsCount" *ngIf="data('familyInformation', 'dependents')">
            <span class="label-field" translate="finalReview.labels.dependants"></span>
            <span class="value-field">{{data('familyInformation', 'dependentsCount')}}</span>
        </li>
        <li   id="familyInformationDependants" *ngIf="!isValueNull(data('familyInformation', 'dependents')) && !data('familyInformation', 'dependents')">
            <span class="label-field" translate="finalReview.labels.dependants"></span>
            <span class="value-field" translate="finalReview.labels.no"></span>
        </li>

    </ul>
    <!-- END - Family information section -->
    <!-- beneficiary -->
    <div class="review-labels-section" *ngIf="beneficiaryInformation!=null && isNonQuebec && hasBeneficiaries === true">
        <div class="bmo-container-flex flex-flow-nowrap review-title-section">
            <div class="flex-col-expand flex-xaxis-center review-title">
                <h2 translate="finalReview.titleSectionBeneficiary"></h2>
            </div>
            <div class="flex-equal-fixed review-button">
                <a href="javascript://" [routerLink]="['/beneficiary']" [queryParams]="queryParams"
                    data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                    attr.aria-label="{{ 'finalReview.ariaLabels.beneficiary' | translate }}"></a>
            </div>
        </div>
        <ng-container *ngFor="let beneficiaryInfo of beneficiaryInformation; let index = index">
            <h3>
                <span [innerHTML]="'beneficiary.subtitleDesignatedBeneficiary' | translate | safeHtml"></span>
                <span>{{index + 1}}</span>
            </h3>

            <ul id="beneficiaryInfoSection" class="bmo-container-flex">
                <li id="beneficiaryInfoName">
                    <span class="label-field" translate="finalReview.labels.beneficiary.name"></span>
                    <span class="value-field">{{beneficiaryInfo.firstName+" "}} {{beneficiaryInfo.lastName}}</span>
                </li>

                <li id="beneficiaryInfoDob">
                    <span class="label-field" translate="finalReview.labels.beneficiary.dob"></span>
                    <span class="value-field">
                        <!--September 30, 1980-->
                        {{moment(beneficiaryInfo.dateOfBirth).format("MMMM D, YYYY")}}
                    </span>
                    <!--<span class="value-field">{{beneficiaryInfo.dateOfBirth}}</span>-->
                </li>
                <li id="beneficiaryInfoRelationshipTo">
                    <span class="label-field" translate="finalReview.labels.beneficiary.relationshipTo"></span>

                    <span class="value-field"> {{beneficiaryInfo.relationshipToPlanHolder}}</span>
                </li>
                <li id="beneficiaryInfoPercentageEntitlement">
                    <span class="label-field" translate="finalReview.labels.beneficiary.percentageEntitlement"></span>
                    <span class="value-field">{{beneficiaryInfo.entitlementPercentage}}</span>
                </li>
            </ul>
        </ng-container>
    </div>
    <!-- Withdrawals section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section" *ngIf="isRRIF">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSubSectionWithdrawal"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://"  translate="finalReview.labels.edit" [routerLink]="['/withdrawal']" [queryParams]="queryParams"
            data-ana="mywealth:onboarding:review:edit" attr.aria-label="{{ 'finalReview.ariaLabels.editWithdrawal' | translate }}"></a>
        </div>
    </div>

    <ul id="WithdrawalsSection" class="bmo-container-flex review-labels-section" *ngIf="isRRIF">
        <li id="WithdrawalsAmount">
            <span class="label-field" translate="finalReview.labels.withdrawals.amount"></span>
            <span class="value-field" *ngIf="withdrawal?.amountType==='1'" translate="withdrawal.labels.minAnnualAmmount"></span>
            <span class="value-field" *ngIf="withdrawal?.amountType==='2'" appCurrency>{{withdrawal?.amount}}</span>
        </li>
        <li id="WithdrawalsAmountPaid" *ngIf="withdrawal?.amountType==='2'">
            <span class="label-field" translate="finalReview.labels.withdrawals.amountPaid"></span>
            <span class="value-field" >{{withdrawalFormData.greaterThanMinimumAmountType}}</span>
        </li>
        <li id="WithdrawalSpouseAge" *ngIf="withdrawal?.isSpouseAgeUsed !== null && (familyInformation.maritalStatus === '100002' || familyInformation.maritalStatus === '100003')">
            <span class="label-field" translate="finalReview.labels.withdrawals.withdrawalSpouseAge"></span>
            <span class="value-field" *ngIf="withdrawal?.isSpouseAgeUsed === 'true'" translate="withdrawal.labels.yes"></span>
            <span class="value-field" *ngIf="withdrawal?.isSpouseAgeUsed === 'false'" translate="withdrawal.labels.no"></span>
        </li>
        <li id="WithdrawalsSpouseDOB" *ngIf="withdrawal?.spouseDateOfBirth !== null && withdrawal?.isSpouseAgeUsed === 'true'">
            <span class="label-field" translate="finalReview.labels.withdrawals.spouseDOB"></span>
            <span class="value-field">{{moment(withdrawal?.spouseDateOfBirth).format("MMMM D, YYYY")}}</span>
        </li>
        <li id="WithdrawalsFrequency">
            <span class="label-field" translate="finalReview.labels.withdrawals.frequency"></span>
            <span class="value-field">{{withdrawalFormData.frequency}}</span>
        </li>
        <li id="WithdrawalsStartMonth">
            <span class="label-field" translate="finalReview.labels.withdrawals.startMonth"></span>
            <span class="value-field">{{withdrawalFormData.startMonth}}</span>
        </li>
        <li id="WithdrawalsTimeOfMonth">
            <span class="label-field" translate="finalReview.labels.withdrawals.timeOfMonth"></span>
            <span class="value-field">{{withdrawalFormData.timeOfMonth}}</span>
        </li>
        <li id="WithdrawalsDeposit">
            <span class="label-field" translate="finalReview.labels.withdrawals.deposit"></span>
            <span class="value-field" *ngIf="withdrawal?.method === RIF_PAYMENT_METHOD.BMOEFT" translate="withdrawal.labels.personal"></span>
            <span class="value-field" *ngIf="withdrawal?.method === RIF_PAYMENT_METHOD.NBMOEFT" translate="withdrawal.labels.personal-other"></span>
            <span class="value-field" *ngIf="withdrawal?.method === RIF_PAYMENT_METHOD.TFR" translate="withdrawal.labels.investorLine"></span>
        </li>

        <li id="WithdrawalsTransitNumber" *ngIf="withdrawal?.method !== RIF_PAYMENT_METHOD.TFR">
            <span class="label-field" translate="finalReview.labels.withdrawals.transitNumber"></span>
            <span class="value-field" >{{withdrawal?.transitCode}}</span>
        </li>
        <li id="WithdrawalsInstitution" *ngIf="withdrawal?.method === RIF_PAYMENT_METHOD.NBMOEFT">
            <span class="label-field" translate="finalReview.labels.withdrawals.institution"></span>
            <span class="value-field" >{{withdrawal?.institutionCode}}</span>
        </li>
        <li id="WithdrawalsAccountNumber">
            <span class="label-field" translate="finalReview.labels.withdrawals.accountNumber"></span>
            <span class="value-field" >{{withdrawal?.transferAccountNumber}}</span>
        </li>
        <li id="WithdrawalsRegistration" *ngIf="withdrawal?.method === RIF_PAYMENT_METHOD.NBMOEFT">
            <span class="label-field" translate="finalReview.labels.withdrawals.registration"></span>
            <span class="value-field" *ngIf="withdrawal?.isJointAccount === 'true' || withdrawal?.isJointAccount === true" translate="withdrawal.labels.joint"></span>
            <span class="value-field" *ngIf="withdrawal?.isJointAccount === 'false'|| withdrawal?.isJointAccount === false" translate="withdrawal.labels.single"></span>
        </li>

        <!-- <li *ngIf="withdrawal.method === RIF_PAYMENT_METHOD.NBMOEFT">
            <span class="label-field" translate="finalReview.labels.withdrawals.confirm"></span>
            <span class="value-field" *ngIf="withdrawal.validAccount === 'true' || withdrawal.validAccount === true" translate="withdrawal.labels.yes"></span>
            <span class="value-field" *ngIf="withdrawal.validAccount === 'false'|| withdrawal.validAccount === false" translate="withdrawal.labels.no"></span>
            </li> -->
    </ul>
    <!-- END - Withdrawals section -->
    <!-- Financial information section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionFinancial"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/financial-details']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editFinancialDetails' | translate }}"></a>
        </div>
    </div>

    <ul id="financialDetailsSection" class="bmo-container-flex review-labels-section">
        <li id="financialDetailsGross">
            <span class="label-field" translate="finalReview.labels.gross"></span>
            <span class="value-field" *ngIf=" 24999 == data('financialInformation', 'grossAnnualIncome')"
                translate="financialDetails.labels.range0to25"></span>
            <span class="value-field" *ngIf=" 49999 == data('financialInformation', 'grossAnnualIncome')"
                translate="financialDetails.labels.range25to50"></span>
            <span class="value-field" *ngIf=" 99999 == data('financialInformation', 'grossAnnualIncome')"
                translate="financialDetails.labels.range50to100"></span>
            <span class="value-field" *ngIf=" 199999 == data('financialInformation', 'grossAnnualIncome')"
                translate="financialDetails.labels.range100to200"></span>
            <span class="value-field" *ngIf=" 209999 == data('financialInformation', 'grossAnnualIncome')"
                translate="financialDetails.labels.range200up"></span>
        </li>
        <li id="financialDetailsNetLiquidAssets" *ngIf="data('financialInformation', 'netLiquidAssets')!== null &&  isMargin">
            <span class="label-field" translate="finalReview.labels.netLiquidAssets"></span>
            <span class="value-field" appCurrency>{{data('financialInformation', 'netLiquidAssets')}}</span>
        </li>
        <li id="financialDetailsTotalWorth">
            <span class="label-field" translate="finalReview.labels.totalWorth"></span>
            <span class="value-field" appCurrency>{{data('financialInformation', 'netWorth')}}</span>
        </li>
        <li id="financialDetailsSourceWealth">
            <span class="label-field" translate="finalReview.labels.sourceWealth"></span>
            <span class="value-field">{{parseWealthSources}}</span>
        </li>
        <li id="financialDetailsSourceWealthOther" *ngIf=" data('financialInformation', 'otherSourceOfWealth') ">
            <span class="label-field" translate="finalReview.labels.sourceWealthOther"></span>
            <span class="value-field">{{data('financialInformation', 'otherSourceOfWealth')}}</span>
        </li>
        <li id="financialDetailsBorrowingMoneyQues" *ngIf="adviceDirect">
            <span class="label-field" translate="finalReview.labels.borrowingMoneyQues"></span>
            <span *ngIf="data('financialInformation', 'borrowingMoney') === true" class="value-field"
                translate="finalReview.labels.yes"></span>
            <span *ngIf="data('financialInformation', 'borrowingMoney') === false" class="value-field"
                translate="finalReview.labels.no"></span>
        </li>
        <li id="financialDetailsSourceAnnualIncomeQues" *ngIf="adviceDirect">
            <span class="label-field" translate="finalReview.labels.sourceAnnualIncomeQues"></span>
            <span *ngIf="data('financialInformation', 'annualIncReq') === true" class="value-field"
                translate="finalReview.labels.yes"></span>
            <span *ngIf="data('financialInformation', 'annualIncReq') === false" class="value-field"
                translate="finalReview.labels.no"></span>
        </li>
        <li id="financialDetailsTotalAssetsQues" *ngIf="adviceDirect">
            <span class="label-field" translate="finalReview.labels.totalAssetsQues"></span>
            <span class="value-field">{{ parseInvestAssetPercentage }}</span>
        </li>
    </ul>
    <!-- END - Financial information section -->
    <!-- Investment knowledge section -->
    <div *ngIf="investmentKnowledge && adviceDirect" class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionInvestmentKnowledge"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/'+ APP_ROUTES.investment_knowledge]"
                translate="finalReview.labels.edit" attr.aria-label="{{ 'finalReview.ariaLabels.editInvestmentKnowledge' | translate }}"
                [queryParams]="queryParams" data-ana="mywealth:onboarding:review:edit"></a>

        </div>
    </div>

    <ul id="investmentKnowledgeSection" *ngIf="investmentKnowledge && adviceDirect" class="bmo-container-flex review-labels-section">
        <li id="investmentKnowledgeIlInvestmentKnowledge">
            <span class="label-field" translate="finalReview.labels.ilInvestmentKnowledge"></span>
            <span
                class="value-field">{{getLabelFromReferences('investmentKnowledge', 'ilInvestmentKnowledge', reference.investmentKnowledge)}}</span>
        </li>
        <li id="investmentKnowledgeInvestmentExperience" *ngIf="investmentKnowledge.ilInvestmentKnowledge!== null
                   && investmentKnowledge.ilInvestmentKnowledge!== undefined
                   && investmentKnowledge.ilInvestmentKnowledge!== INVESTMENT_KNOWLEDGE_TYPES.NONE">
            <span class="label-field" translate="finalReview.labels.investmentExperience"></span>
            <span
                class="value-field">{{getLabelFromReferences('investmentKnowledge', 'pastExperience', reference.investmentExperience)}}</span>
        </li>
        <li id="investmentKnowledgeIlInvestmentTimeHorizon">
            <span class="label-field" translate="finalReview.labels.ilInvestmentTimeHorizon"></span>
            <span
                class="value-field">{{getLabelFromReferences('investmentKnowledge', 'ilInvestmentTimeHorizon', reference.ilInvestmentTimeHorizon)}}</span>
        </li>
        <!-- As per BIL Story Instead of Deleting i am commenting. Because for any future use https://jira.bmogc.net/browse/MWB-12547 -->
        <!-- <li>
            <span class="label-field" translate="finalReview.labels.withdrawType"></span>
            <span
                class="value-field">{{getLabelFromReferences('investmentKnowledge', 'investmentTimeHorizonForWithDraw', reference.ilInvestmentTimeHorizonForWithDraw)}}</span>
        </li> -->
    </ul>
    <!-- END - Investment knowledge section -->

    <!-- Investment objectives section is replaced by Risk and Return -->

    <div *ngIf="riskAndReturn && adviceDirect" class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionInvestmentObjectives"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/'+ APP_ROUTES.riskAndReturn]" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editInvestmentObjectives' | translate }}"></a>
        </div>
    </div>
     <ul id="riskAndReturnSection" *ngIf="riskAndReturn && adviceDirect" class="bmo-container-flex review-labels-section">
        <li id="riskAndReturnTitle" *ngIf="data('riskAndReturn', 'riskTolerance')">
            <span class="label-field" translate="riskAndReturn.titleRiskAndReturn"></span>
            <span class="value-field" translate="riskAndReturn.volatilityQuizOptions.significantLoss" *ngIf="data('riskAndReturn', 'riskTolerance') === '1'"></span>
            <span class="value-field" translate="riskAndReturn.volatilityQuizOptions.littleConcern" *ngIf="data('riskAndReturn', 'riskTolerance') === '2'"></span>
            <span class="value-field" translate="riskAndReturn.volatilityQuizOptions.thatsFine" *ngIf="data('riskAndReturn', 'riskTolerance') === '3'"></span>
            <span class="value-field" translate="riskAndReturn.volatilityQuizOptions.thatsGreat" *ngIf="data('riskAndReturn', 'riskTolerance') === '4'"></span>
        </li>


        <li id="riskAndReturnObjective" *ngIf="data('riskAndReturn', 'riskReturnInvestment')">
            <span class="label-field" translate="riskAndReturn.idealRiskReturnObjective"></span>
            <span class="value-field" translate="riskAndReturn.idealRiskReturnOptions.emphasisLowRisk" *ngIf="data('riskAndReturn', 'riskReturnInvestment') === '1'"></span>
            <span class="value-field" translate="riskAndReturn.idealRiskReturnOptions.balanceRiskAndReturn" *ngIf="data('riskAndReturn', 'riskReturnInvestment') === '2'"></span>
            <span class="value-field" translate="riskAndReturn.idealRiskReturnOptions.moreEmphasisReturns" *ngIf="data('riskAndReturn', 'riskReturnInvestment') === '3'"></span>
            <span class="value-field" translate="riskAndReturn.idealRiskReturnOptions.focusedOnReturn"  *ngIf="data('riskAndReturn', 'riskReturnInvestment') === '4'"></span>
        </li>

    </ul>
    <!-- End of Risk and Return -->

    <!-- As per BIL Story Instead of Deleting i am commenting. Becasue for any future use https://jira.bmogc.net/browse/MWB-12547 -->
    <!-- <ul *ngIf="investmentObjectives && adviceDirect" class="bmo-container-flex review-labels-section">
        <li>
            <span class="label-field" translate="finalReview.labels.income"></span>
            <span class="value-field"> {{data('investmentObjectives', 'income')}}</span>
        </li>
        <li>
            <span class="label-field" translate="finalReview.labels.shortTerm"></span>
            <span class="value-field">{{data('investmentObjectives', 'shortTerm')}}</span>
        </li>
        <li>
            <span class="label-field" translate="finalReview.labels.mediumTerm"></span>
            <span class="value-field">{{data('investmentObjectives', 'mediumTerm')}}</span>
        </li>
        <li>
            <span class="label-field" translate="finalReview.labels.longTerm"></span>
            <span class="value-field">{{data('investmentObjectives', 'longTerm')}}</span>
        </li>
    </ul> -->
    <!-- END - Investment objectives section -->
    <!-- Investor profile section -->
    <div *ngIf="investorProfile && adviceDirect" class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionInvestorProfile"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/'+ APP_ROUTES.investor_profile]" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editInvestorProfile' | translate }}"></a>
        </div>
    </div>

    <ul id="investorProfileSection" *ngIf="investorProfile && adviceDirect" class="bmo-container-flex review-labels-section">
        <li id="investorProfile">
            <span class="label-field" translate="finalReview.labels.investorProfile"></span>
            <span
                class="value-field">{{getLabelFromReferences('investorProfile', 'objective', reference.ilInvestmentProfile)}}</span>
        </li>
        <!-- As per BIL Story Instead of Deleting i am commenting. Becasue for any future use https://jira.bmogc.net/browse/MWB-12547 -->
        <!-- <li>
            <span class="label-field" translate="finalReview.labels.toleranceRisk"></span>
            <span
                class="value-field">{{getLabelFromReferences('investorProfile', 'ilRiskTolerance', reference.ilRiskTolerance)}}</span>
        </li> -->
        <li id="investorProfileReduceRisk" *ngIf="investorProfile.riskLevelAcceptedForAgeSeventy !=  null">
            <span class="label-field" translate="finalReview.labels.reduceRisk"></span>
            <span class="value-field"
                *ngIf="investorProfile.riskLevelAcceptedForAgeSeventy === true"
                translate="regulatoryDisclosures.labels.yes"></span>
            <span class="value-field"
                *ngIf="investorProfile.riskLevelAcceptedForAgeSeventy === false"
                translate="regulatoryDisclosures.labels.no"></span>
        </li>
    </ul>
    <!-- END - Investor profile section -->
    <!-- Regulatory information section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSectionRegulatory"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/regulatory-disclosures']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editRegulatoryDisclosures' | translate }}"></a>
        </div>
    </div>

    <ul id="regulatoryDisclosuresSection" class="bmo-container-flex review-labels-section"
    *ngIf="regulatoryDisclosures.intendedUseOfAccount && (adviceDirect || (selfDirected && (isCashAccount || isMargin)))">
        <li id="intendedUseOfAccount">
            <span *ngIf="!isMultiAccountOpenFlow && !isBranchSDMultiAccountOpenFlow" class="label-field" translate="finalReview.labels.intendedUseOfAccount"></span>
            <span *ngIf="isMultiAccountOpenFlow || isBranchSDMultiAccountOpenFlow" class="label-field" translate="finalReview.labels.intendedUseOfAccountNonReg"></span>
            <span
                class="value-field">{{getLabelFromReferences('regulatoryDisclosures', 'intendedUseOfAccount', reference.accountUses )}}</span>
        </li>
    </ul>

    <!-- IIROC sub-section -->
    <div class="bmo-container-flex review-title-section title-subsection">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h3 *ngIf="isCIROEnabled" translate="finalReview.titleSubSectionCIRO"></h3>
            <h3 *ngIf="isNewContentR5Enabled && !isCIROEnabled" translate="finalReview.titleSubSectionInvestmentIndustryRelationships"></h3>
        </div>
    </div>

    <ul id="iirocSubSection" class="bmo-container-flex review-labels-section" *ngIf="data('regulatoryDisclosures', 'relationShip') === 'No'">
        <li id="iirocSubNo">
            <span class="value-field" translate="regulatoryDisclosures.labels.no"></span>
        </li>
    </ul>

    <ul id="relationshipmemberSection" class="bmo-container-flex review-labels-section"
        *ngIf="data('regulatoryDisclosures', 'relationShip') === 'Yes'">
        <li id="relationshipMember">
            <span class="label-field" translate="finalReview.labels.member"></span>
            <span class="value-field">{{data('regulatoryDisclosures', 'memberFromLabel')}}</span>
        </li>
    </ul>

    <!-- Insider sub-section -->
    <div class="bmo-container-flex review-title-section title-subsection">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h3 translate="finalReview.titleSubSectionInsider"></h3>
        </div>
    </div>

    <ul id="insiderRelationshipSection" class="bmo-container-flex review-labels-section"
        *ngIf="regulatoryDisclosures && regulatoryDisclosures.insiderRelationships">
        <li id="insiderRelationshipNo" *ngIf="data('regulatoryDisclosures', 'insiderRelationship') === 'No'">
            <span class="value-field" translate="regulatoryDisclosures.labels.no"></span>
        </li>
        <li id="insiderRelationshipMember" *ngFor="let insider of regulatoryDisclosures.insiderRelationships">
            <span class="label-field" translate="finalReview.labels.member"></span>
            <span class="value-field">{{insider.companyName}} / {{insider.companyTradingSymbol}}</span>
        </li>
    </ul>

    <!-- Reporting sub-section -->
    <!-- MWB-15087 - Removing Reporting Insider Field -->
   <ng-container *ngIf = "isDataRationalization4Enabled !== true">
    <div class="bmo-container-flex review-title-section title-subsection"
        *ngIf="data('regulatoryDisclosures', 'insiderRelationship') === 'Yes'">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h3 translate="finalReview.titleSubSectionReporting"></h3>
        </div>
    </div>

    <ul id="spouseInsiderSection" class="bmo-container-flex review-labels-section"
        *ngIf="regulatoryDisclosures && regulatoryDisclosures.spouseInsiderRelationships && data('regulatoryDisclosures', 'insiderRelationship') === 'Yes'">
        <li id="spouseInsiderNo" *ngIf="data('regulatoryDisclosures', 'spouseInsiderRelationship') === 'No'">
            <span class="value-field" translate="regulatoryDisclosures.labels.no"></span>
        </li>
        <li id="spouseInsiderMember" *ngFor="let spouseInsider of regulatoryDisclosures.spouseInsiderRelationships">
            <span class="label-field" translate="finalReview.labels.member"></span>
            <span class="value-field">
                {{spouseInsider.companyName}} / {{spouseInsider.companyTradingSymbol}}
            </span>
        </li>
    </ul>
   </ng-container>

    <!-- MWB-15356 - Consolated Significant  and Controlling (Prior R4) -->
   <ng-container *ngIf="!isDataRationalization4Enabled">
    <!-- Significant other sub-section -->
    <div class="bmo-container-flex review-title-section title-subsection">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h3 translate="finalReview.titleSubSectionSignificant"></h3>
        </div>
    </div>

    <ul id="shareholderRelationshipSection" class="bmo-container-flex review-labels-section"
        *ngIf="regulatoryDisclosures && regulatoryDisclosures.shareholderRelationships">
        <li  id="shareholderRelationshipsNo" *ngIf="data('regulatoryDisclosures', 'shareholderRelationship') === 'No'">
            <span class="value-field" translate="regulatoryDisclosures.labels.no"></span>
        </li>
        <li id="shareholderRelationships" *ngFor="let ssr of regulatoryDisclosures.shareholderRelationships">
            <span class="label-field" translate="finalReview.labels.member"></span>
            <span class="value-field">
                {{ssr.companyName}} / {{ssr.companyTradingSymbol}}
            </span>
        </li>
    </ul>

    <!-- Control sub-section -->
    <div class="bmo-container-flex review-title-section title-subsection">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h3 translate="finalReview.titleSubSectionControl"></h3>
        </div>
    </div>

    <ul id="controlRelationshipSection" class="bmo-container-flex review-labels-section"
        *ngIf="regulatoryDisclosures && regulatoryDisclosures.controlRelationships">
        <li id="controlRelationshipNo" *ngIf="data('regulatoryDisclosures', 'controlRelationship') === 'No'">
            <span class="value-field" translate="regulatoryDisclosures.labels.no"></span>
        </li>
        <li id="controlRelationshipMember" *ngFor="let controlRelation of regulatoryDisclosures.controlRelationships">
            <span class="label-field" translate="finalReview.labels.member"></span>
            <span class="value-field">
                {{controlRelation.companyName}} / {{controlRelation.companyTradingSymbol}}
            </span>
        </li>
    </ul>
   </ng-container>

   <!-- MWB-15356 - Consolidated Significant  and Controlling (R4) -->
    <ng-container *ngIf="isDataRationalization4Enabled">
        <!-- Significant Control other sub-section -->
        <div class="bmo-container-flex review-title-section title-subsection">
            <div class="flex-col-expand flex-xaxis-center review-title">
                <h3 *ngIf="!isNewContentR5Enabled" translate="finalReview.titleSubSectionSignificantControl"></h3>
                <h3 *ngIf="isNewContentR5Enabled" translate="finalReview.titleSubSectionControlShareholderRelationships"></h3>
            </div>
        </div>

        <ul id="shareholderRelationshipSection" class="bmo-container-flex review-labels-section"
            *ngIf="regulatoryDisclosures && regulatoryDisclosures.shareholderRelationships">
            <li  id="shareholderRelationshipsNo" *ngIf="data('regulatoryDisclosures', 'shareholderRelationship') === 'No'">
                <span class="value-field" translate="regulatoryDisclosures.labels.no"></span>
            </li>
            <li id="shareholderRelationships" *ngFor="let ssr of regulatoryDisclosures.shareholderRelationships">
                <span class="label-field" translate="finalReview.labels.member"></span>
                <span class="value-field">
                    {{ssr.companyName}} / {{ssr.companyTradingSymbol}}
                </span>
            </li>
        </ul>
    </ng-container>

    <!-- Governmental sub-section -->
    <ng-container *ngIf = "isDataRationalization4Enabled !== true">
    <div class="bmo-container-flex review-title-section title-subsection">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h3 translate="finalReview.titleSubSectionGovernmental"></h3>
        </div>
    </div>

    <ul id="governmentalRelationshipSection" class="bmo-container-flex review-labels-section">
        <li id="governmentalRelationshipNo" *ngIf="data('regulatoryDisclosures', 'governmentalRelationship') === 'No'">
            <span class="value-field" translate="regulatoryDisclosures.labels.no"></span>
        </li>
        <li id="governmentalRelationshipLocation" *ngIf="data('regulatoryDisclosures', 'governmentalRelationship') === 'Yes'">
            <span class="label-field" translate="finalReview.labels.location"></span>
            <span *ngIf="data('regulatoryDisclosures', 'governmentRelationPosition') ==='Foreign'" class="value-field"
                translate="regulatoryDisclosures.labels.foreign"></span>
            <span *ngIf="data('regulatoryDisclosures', 'governmentRelationPosition') ==='Domestic'" class="value-field"
                translate="regulatoryDisclosures.labels.domestic"></span>
        </li>
        <li id="governmentalRelationshipForeignTitle"
            *ngIf="data('regulatoryDisclosures', 'governmentalRelationship') === 'Yes' && data('regulatoryDisclosures', 'governmentRelationPosition') ==='Foreign'">
            <span class="label-field" translate="finalReview.labels.foreignTitle"></span>
            <span class="value-field">{{data('regulatoryDisclosures', 'selectForeignTitle')}}</span>
        </li>
        <li id="governmentalRelationshipDomesticTitle"
            *ngIf="data('regulatoryDisclosures', 'governmentalRelationship') === 'Yes' && data('regulatoryDisclosures', 'governmentRelationPosition') ==='Domestic'">
            <span class="label-field" translate="finalReview.labels.domesticTitle"></span>
            <span class="value-field">{{regulatoryDropDownData.domesticTitle}}</span>
        </li>

    </ul>
  </ng-container>
    <!-- END - Regulatory information section -->
    <!-- Consents section -->
    <div class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSubSectionConsents"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/consents']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editConsents' | translate }}"></a>
        </div>
    </div>

    <ul id="consentsSection" class="bmo-container-flex review-labels-section">
        <li id="consentsSharing">
            <span class="label-field" translate="finalReview.labels.sharing"></span>
            <span class="value-field" translate="{{getConsentLabel('consents', 'personalInfo')}}"></span>
            <!-- <span class="value-field" *ngIf="data('consents', 'personalInfo') === 'No'"  translate="consents.labels.noConsent"></span> -->
        </li>
        <li id="consentsDirect">
            <span class="label-field" translate="finalReview.labels.direct"></span>
            <span class="value-field" translate="{{getConsentLabel('consents', 'marketingPref')}}"></span>
        </li>
        <li id="consentsDisclosure">
            <span class="label-field" translate="finalReview.labels.disclosure"></span>
            <span class="value-field" translate="{{getConsentLabel('consents', 'shareholder')}}"></span>
        </li>
        <li id="consentsSecurityholder">
            <span class="label-field" translate="finalReview.labels.securityholder"></span>
            <span class="value-field" translate="{{getConsentLabel('consents', 'securityholder')}}"></span>
        </li>
        <li id="consentsOnlyProxies"
            *ngIf="data('consents', 'securityholder') === true &&  data('consents', 'securityholderOption') === 'Proxies'">
            <span class="label-field" translate="finalReview.labels.onlyProxies"></span>
            <span class="value-field" translate="{{getConsentLabel('consents', 'securityholderOption')}}"></span>
        </li>
        <li id="consentsProxies"
            *ngIf="data('consents', 'securityholder') === true &&  data('consents', 'securityholderOption') === 'ProxiesAnualReports'">
            <span class="label-field" translate="finalReview.labels.proxies"></span>
            <span class="value-field" translate="{{getConsentLabel('consents', 'securityholderOption')}}"></span>
        </li>
        <li id="consentsETaxFormat">
            <span class="label-field" translate="finalReview.labels.eTaxFormat"></span>
            <span class="value-field" translate="{{getConsentLabel('consents', 'taxDocuments')}}"></span>
        </li>
    </ul>
    <!-- END - Consents section -->
    <!-- BMO relationship section -->
    <ng-container *ngIf="((!BILAssisted) || (BILAssisted && !adviceDirect)
                          || (BILAssisted && adviceDirect && !(isRRSPAccount || isSpousalRRSP || isTFSAAccount || isRRIF))) && !isOlbCdbUser && !isJourneyUser">
        <div class="bmo-container-flex flex-flow-nowrap review-title-section">
            <div class="flex-col-expand flex-xaxis-center review-title">
                <h2 translate="finalReview.titleSubSectionBMORelationship"></h2>
            </div>
            <div class="flex-equal-fixed review-button">
                <a href="javascript://" [routerLink]="['/bmo-relationship']" [queryParams]="queryParams"
                    data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                    attr.aria-label="{{ 'finalReview.ariaLabels.editBMORelationship' | translate }}"></a>
            </div>
        </div>

        <ul id="bmoRelationshipSection" class="bmo-container-flex review-labels-section">
            <!-- Show for Non Branch Referal users-->
            <li id="accountLinkConsent" *ngIf="isCeightSixEnabled && (isMargin || isCashAccount)">
                <span class="label-field" translate="bmoRelationship.consentToAccountLink"></span>
                <span *ngIf="data('bmoRelationship', 'consentToAccountLink')" class="value-field"
                    translate="finalReview.labels.yesBMORelationship"></span>
            </li>
            <li id="bmoRelationshipYesNo"
                *ngIf="(isRRSPAccount || isSpousalRRSP || isTFSAAccount || isRRIF) && (BILAssisted === false) && (data('bmoRelationship', 'bmoRelationshipClient') !== null) && !isCashAccount && !isMargin">
                <span class="label-field" translate="finalReview.labels.BMORelationship"></span>
                <span *ngIf=" data('bmoRelationship', 'bmoRelationshipClient') === 'No'" class="value-field"
                    translate="finalReview.labels.noBMORelationship"></span>
                <span *ngIf=" data('bmoRelationship', 'bmoRelationshipClient') === 'Yes'" class="value-field"
                    translate="finalReview.labels.yesBMORelationship"></span>
            </li>
            <!--<li *ngIf="(isRRSPAccount || isSpousalRRSP || isTFSAAccount) && data('bmoRelationship', 'bmoRelationshipClient') === 'Yes'">
            <li
                *ngIf="(isRRSPAccount || isSpousalRRSP || isTFSAAccount) && data('bmoRelationship', 'bmoRelationshipClient') === 'Yes'">
                <span class="label-field" translate="finalReview.labels.BMOEntity"></span>
                <span class="value-field">{{data('bmoRelationship', 'bmoProductLabel')}}</span>
            </li> -->
            <li id="bmoRelationshipAccountNumber" *ngIf="(isCashAccount || isMargin) && data('bmoRelationship', 'hasBMODebitCard') === 'Yes'">
                <span class="label-field" translate="finalReview.labels.accountNumber"></span>
                <span class="value-field">{{data('bmoRelationship', 'debitCardNumber')}}</span>
            </li>
            <li id="bmoRelationshipCardNumber" *ngIf="(isCashAccount || isMargin) && data('bmoRelationship', 'hasBMODebitCard') === 'Yes' &&
                        data('bmoRelationship', 'bmoProductCode')!==''">
                <span *ngIf="data('bmoRelationship', 'bmoProductCode') === UI_CONSTANTS.BMO_PRODUCTS.debit_card"
                    class="label-field" translate="finalReview.labels.cardNumber"></span>
                <span
                    *ngIf="(isCashAccount || isMargin) && data('bmoRelationship', 'bmoProductCode') != UI_CONSTANTS.BMO_PRODUCTS.debit_card"
                    class="label-field"></span>
                <span class="value-field">{{data('bmoRelationship', 'accountNumber')}}</span>
            </li>
            <li id="bmoRelationshipNewDebitCard" *ngIf="(isCashAccount || isMargin) && data('bmoRelationship', 'hasBMODebitCard') === 'No'">
                <span class="label-field" translate="finalReview.labels.newDebitCard"></span>
                <span *ngIf="data('bmoRelationship', 'newDebitCardYesNo') === 'Yes'" class="value-field"
                    translate="bmoRelationship.labels.newDebitCardYes"></span>
                <span *ngIf="data('bmoRelationship', 'newDebitCardYesNo') === 'No'" class="value-field"
                    translate="bmoRelationship.labels.newDebitCardNo"></span>
            </li>
            <!-- <li
                *ngIf="isCashAccount && data('bmoRelationship', 'hasBMODebitCard') !== null && data('bmoRelationship', 'accessUS') == 'Yes'">
                <span class="label-field" translate="finalReview.labels.accountLink"></span>
                <span class="value-field" translate="bmoRelationship.labels.yes"></span>
            </li> -->
            <!-- Show for Non Branch Referal users-->
            <li id="bmoRelationshipYesNoSS"
                *ngIf="(isCashAccount || isMargin) && data('bmoRelationship', 'hasBMODebitCard') === 'No' &&  data('bmoRelationship', 'isApplicantExistingClient') !== null && BILAssisted === false">
                <span class="label-field" translate="finalReview.labels.BMORelationship"></span>
                <span *ngIf=" data('bmoRelationship', 'isApplicantExistingClient') === 'No'" class="value-field"
                    translate="finalReview.labels.noBMORelationship"></span>
                <span *ngIf=" data('bmoRelationship', 'isApplicantExistingClient') === 'Yes'" class="value-field"
                    translate="finalReview.labels.yesBMORelationship"></span>
            </li>

            <li id="bmoRelationshipAccountLink2"
                *ngIf="(isCashAccount || isMargin) && data('bmoRelationship', 'hasBMODebitCard') !== null && data('bmoRelationship', 'accessUS') == 'No'">
                <span class="label-field" translate="finalReview.labels.accountLink"></span>
                <span class="value-field" translate="bmoRelationship.labels.no"></span>
            </li>

			<li id="bmoRelationshipPrivateBanking"
                *ngIf="(BranchAssisted && !isSubProductAD)">
                <span class="label-field" translate="finalReview.labels.privateBanking"></span>
                <span *ngIf=" data('bmoRelationship', 'isApplicantExistingPBClient') === 'No' || data('bmoRelationship', 'isApplicantExistingPBClient') === false " class="value-field"
                translate="finalReview.labels.noBMORelationship"></span>
                <span *ngIf=" data('bmoRelationship', 'isApplicantExistingPBClient') === 'Yes' || data('bmoRelationship', 'isApplicantExistingPBClient') === true " class="value-field"
                translate="finalReview.labels.yesBMORelationship"></span>
            </li>

            <!--<li *ngIf="isCashAccount && data('bmoRelationship', 'hasBMODebitCard') === 'No' && data('bmoRelationship', 'isApplicantExistingClient') === 'Yes' && data('bmoRelationship', 'bmoProductLabel') != ''">
            <li
                *ngIf="isCashAccount && data('bmoRelationship', 'hasBMODebitCard') !== null && data('bmoRelationship', 'accessUS') == 'No'">
                <span class="label-field" translate="finalReview.labels.accountLink"></span>
                <span class="value-field" translate="bmoRelationship.labels.no"></span>
            </li>
            <li
                *ngIf="isCashAccount && data('bmoRelationship', 'hasBMODebitCard') === 'No' && data('bmoRelationship', 'isApplicantExistingClient') === 'Yes' && data('bmoRelationship', 'bmoProductLabel') != ''">
                <span class="label-field" translate="finalReview.labels.BMOEntity"></span>
                <span class="value-field">{{data('bmoRelationship', 'bmoProductLabel')}}</span>
            </li>-->
        </ul>
    </ng-container>
    <!-- END - BMO relationship section -->
    <!-- Password section -->
    <div *ngIf="!isOlbCdbUser && !isJourneyUser"class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSubSectionAccountPassword"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/account-password']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editAccountPassword' | translate }}"></a>
        </div>
    </div>

    <div *ngIf="isOlbCdbUser || (isJourneyUser && (isCashAccount|| isMargin))"class="bmo-container-flex flex-flow-nowrap review-title-section">
        <div class="flex-col-expand flex-xaxis-center review-title">
            <h2 translate="finalReview.titleSubSectionAccountAccess"></h2>
        </div>
        <div class="flex-equal-fixed review-button">
            <a href="javascript://" [routerLink]="['/account-access']" [queryParams]="queryParams"
                data-ana="mywealth:onboarding:review:edit" translate="finalReview.labels.edit"
                attr.aria-label="{{ 'finalReview.ariaLabels.editAccountAccess' | translate }}"></a>
        </div>
    </div>

    <ul id="passwordSection" class="bmo-container-flex review-labels-section" >
        <li id="passwordSuccess" *ngIf="!isJourneyUser && data('accountPassword', 'password')">
            <span class="label-field" translate="finalReview.labels.passwordSuccess"></span>
        </li>

        <li id="consentAccountLink" *ngIf="(isOlbCdbUser || (isJourneyUser && (isCashAccount || isMargin))) && data('accountPassword', 'consentToAccountLink')">
            <span class="label-field" translate="finalReview.labels.consentAccountLink"></span>
            <span class="value-field" translate="finalReview.labels.yes"></span>
         </li>
        <li id="lastFourDigits" *ngIf="(isOlbCdbUser || (isJourneyUser && (isCashAccount || isMargin))) && data('accountPassword', 'hasBMODebitCard') ===  'Yes'">
            <span class="label-field" translate="finalReview.labels.accountNumber"></span>
            <span class="value-field">{{data('accountPassword', 'debitCardNumber')}}</span>
         </li>
         <li id="accessFundsDebitCard" *ngIf="showAccessFundsDebitCard">
            <span class="label-field" translate="finalReview.labels.accessFundsDebitCard"></span>
            <span class="value-field" translate="finalReview.labels.no"></span>
        </li>
        <li id="olbNewDebitCard" *ngIf="(isOlbCdbUser || (isJourneyUser && (isCashAccount || isMargin)))  && (data('accountPassword', 'newDebitCardYesNo') ===  'Yes' || data('accountPassword', 'newDebitCardYesNo') === 'No' )">
            <span class="label-field" translate="finalReview.labels.newDebitCard"></span>
            <span class="value-field" *ngIf="data('accountPassword', 'newDebitCardYesNo') ===  'Yes'" translate="finalReview.labels.yes"></span>
            <span class="value-field" *ngIf="data('accountPassword', 'newDebitCardYesNo') ===  'No'" translate="finalReview.labels.no"></span>
        </li>
    </ul>
    <!-- END - Password section -->

    <div id="submitButtonSection" class="form-wrapper">
        <app-form-buttons [showFinishLater]="showFinishLater" formName="reviewFrm"
            [backRoute]="(isJourneyUser && isNonRegisteredAccount) ? APP_ROUTES.account_access : (isJourneyUser && !isNonRegisteredAccount) ? APP_ROUTES.consents : APP_ROUTES.account_password"></app-form-buttons>
    </div>

</form>



<!--
<div>
    <button (click)="clear(); showAppData()">get</button>
    <button [disabled]="disableButton" (click)="createApplication()">Save</button>

    <button [disabled]="disableDocButton" (click)="submitRequest()">Generate doc</button>
    <div *ngIf="applicationData">

       <b> Application data:</b>
        <p>First Name: {{applicationData.parties[0].personal.identity.legalName.firstName}}</p>
        <p>Last Name: {{applicationData.parties[0].personal.identity.legalName.lastName}}</p>
        <p>Date of Birth: {{applicationData.parties[0].personal.identity.dateOfBirth}}</p>
    </div>
</div>

<div *ngIf="saveAppResponse">
   <b> New workflow id: </b>
    <p>{{saveAppResponse.applicationId}}</p>
</div>

<div *ngIf="docGenerateStatus">
    <p>Status: {{docGenerateStatus.status}}</p>

</div>

<div *ngIf="retrieveDocuments">
    <b> Document list:</b>
 <ul>
    <li *ngFor="let document of retrieveDocuments.documentPackages">
      <span>{{document.labels[0].value}} </span>
         <button (click)="printDocument(document.id)">PDF</button>
    </li>
  </ul>
</div>
-->
