<!-- <app-flood-light></app-flood-light> -->

<!--
@ Created
@updated: Abhinav Jain last updated on (4 June,2018)
 -->

<form #bmoRelationshipForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section id="bmoRelationshipContainerSection">

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="bmoRelationshipForm.invalid && isFormSubmit"
                              translateTag="bmoRelationship"></app-invalid-form>

                            <section *ngIf="isCeightSixEnabled && (isCashAccount || isMargin)">

                                <h1 translate="bmoRelationship.consentToAccountLink"></h1>
                                <div class="alert alert-info" role="alert">
                                    <p *ngIf="!isSubProductAD"
                                       [innerHtml]="'bmoRelationship.description1AccoutLink' | translate | safeHtml"></p>
                                    <p *ngIf="isSubProductAD"
                                       [innerHtml]="'bmoRelationship.ad_description1AccoutLink' | translate | safeHtml"></p>
                                </div>

                                <div class="form-wrapper add-margin t28px">
                                    <div class="form-row">
                                        <div class="form-col">
                                            <div class="form-input-checks check-default">
                                                <input id="accountLinkConsent"
                                                type="checkbox"
                                                name="accountLinkConsent"
                                                [(ngModel)]="bmoRelationship.consentToAccountLink"
                                                #accountLinkConsentCntrl="ngModel"
                                                required role="alert"/>
                                                <label for="accountLinkConsent"
                                                       translate="bmoRelationship.labels.accountLinkConsent"></label>
                                            </div>
                                            <div class="error-message" *ngIf="accountLinkConsentCntrl.errors && accountLinkConsentCntrl.touched || (isFormSubmit && accountLinkConsentCntrl.errors)">
                                                <span translate="bmoRelationship.errors.consentToAccountLink"></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>

            <section *ngIf="(isCashAccount || isMargin)" id="hasBMODebitCardSection">

                <h1 id="ariaBmoClient" translate="bmoRelationship.titleBmoDebitCard"></h1>
                <p translate="bmoRelationship.descriptionBmoDebitCard"></p>

                <div class="form-wrapper">

                    <div role="radiogroup" aria-labelledby="ariaBmoClient" id='parent-bmoDebitCard-error' class="form-row">
                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoDebitCardCtrl.errors && bmoDebitCardCtrl.touched || (isFormSubmit && bmoDebitCardCtrl.errors), 'active-label': bmoDebitCardCtrl.value}">
                                <input id="debitCardYes" data-ana="mywealth:onboarding:bmo relationship:debit:yes"
                                       type="radio" name="debitCard" value="Yes"
                                       (change)="debitCardToggle(bmoRelationship.hasBMODebitCard)"
                                       [(ngModel)]="bmoRelationship.hasBMODebitCard" #bmoDebitCardCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'bmoDebitCard-error', parentId: 'parent-bmoDebitCard-error', submitted: isFormSubmit}"
                                       required />
                                <label class="prefix" for="debitCardYes" translate="bmoRelationship.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoDebitCardCtrl.errors && bmoDebitCardCtrl.touched || (isFormSubmit && bmoDebitCardCtrl.errors), 'active-label': bmoDebitCardCtrl.value}">
                                <input id="debitCardNo" data-ana="mywealth:onboarding:bmo relationship:debit:no"
                                       type="radio" name="debitCard" value="No"
                                       (change)="debitCardToggle(bmoRelationship.hasBMODebitCard)"
                                       [(ngModel)]="bmoRelationship.hasBMODebitCard" #bmoDebitCardCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'bmoDebitCard-error', parentId: 'parent-bmoDebitCard-error', submitted: isFormSubmit}"
                                       required />
                                <label class="prefix" for="debitCardNo" translate="bmoRelationship.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="bmoDebitCard-error"
                                 *ngIf="bmoDebitCardCtrl.errors && bmoDebitCardCtrl.touched || (isFormSubmit && bmoDebitCardCtrl.errors)">
                                <span translate="bmoRelationship.errors.selectionRequiredNoBMODebitCard"></span>
                            </div>
                        </div>

                    </div>

                    <!--  -->
                    <!-- Show this DIV if user selects YES (Cash) for "Do you have a BMO debit card?"-->
                    <!--  -->
                    <div *ngIf="bmoRelationship.hasBMODebitCard === 'Yes'">

                        <div class="form-row">

                            <div class="alert alert-check" role="alert">
                                <p translate="bmoRelationship.debitCardNote"></p>
                            </div>

                        </div>
                        <div class="form-row">

                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="cardLastFourDigits"
                                               data-ana="mywealth:onboarding:bmo relationship:debit:card info"
                                               name="cardLastFourDigits" [(ngModel)]="bmoRelationship.debitCardNumber"
                                               #debitCardNumberCtrl="ngModel" type="tel" InputValidator="ct3" minlength="4"
                                               [appErrorAccess]="{ id: 'debitCardNumber-error', submitted: isFormSubmit}"
                                               maxlength="4" required />
                                        <label [ngClass]="{'error-field': debitCardNumberCtrl.errors && debitCardNumberCtrl.touched || (isFormSubmit && debitCardNumberCtrl.errors), 'active-label': debitCardNumberCtrl.value}"
                                               class="slide-label" for="cardLastFourDigits">
                                            <span class="label-text"
                                                  translate="bmoRelationship.labels.cardLastFourDigits"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" class="error-message" id="debitCardNumber-error"
                                     *ngIf="debitCardNumberCtrl.errors && debitCardNumberCtrl.touched || (isFormSubmit && debitCardNumberCtrl.errors)">
                                    <span translate="bmoRelationship.errors.enterLast4Digits"></span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div *ngIf="bmoRelationship.hasBMODebitCard === 'No'">

                        <div class="form-row label-row">

                            <p id="newDebitCardYesNo" translate="bmoRelationship.newDebitCardNote"></p>

                        </div>
                        <div role="radiogroup" aria-labelledby="newDebitCardYesNo" id='parent-bmoDebitCardLink-error' class="form-row">

                            <div class="form-col">
                                <div class="form-radio-checks radio-default">
                                    <input id="newDebitCardYes"
                                           data-ana="mywealth:onboarding:bmo relationship:debit:send card:yes" type="radio"
                                           name="newDebitCard" value="Yes" [(ngModel)]="bmoRelationship.newDebitCardYesNo"
                                           [appErrorAccess]="{ id: 'bmoDebitCardLink-error', parentId: 'parent-bmoDebitCardLink-error', submitted: isFormSubmit}"
                                           #bmoDebitCardLinkCtrl="ngModel"
                                           (click)="analytics.triggerView('SendNewDebitCardYes')" required />
                                    <label class="prefix" for="newDebitCardYes"
                                           translate="bmoRelationship.labels.newDebitCardYes"></label>
                                </div>
                                <div class="form-radio-checks radio-default">
                                    <input id="newDebitCardNo"
                                           data-ana="mywealth:onboarding:bmo relationship:debit:send card:no" type="radio"
                                           name="newDebitCard" value="No" [(ngModel)]="bmoRelationship.newDebitCardYesNo"
                                           [appErrorAccess]="{ id: 'bmoDebitCardLink-error', parentId: 'parent-bmoDebitCardLink-error', submitted: isFormSubmit}"
                                           #bmoDebitCardLinkCtrl="ngModel"
                                           (click)="analytics.triggerView('SendNewDebitCardNo')" required />
                                    <label class="prefix" for="newDebitCardNo"
                                           translate="bmoRelationship.labels.newDebitCardNo"></label>
                                </div>
                                <div role="alert" class="error-message" id="bmoDebitCardLink-error"
                                     *ngIf="bmoDebitCardLinkCtrl.errors && bmoDebitCardLinkCtrl.touched || (isFormSubmit && bmoDebitCardLinkCtrl.errors)">
                                    <span translate="bmoRelationship.errors.selectionRequiredBMODebitCard"></span>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>
            <!--  -->
            <!-- Show this DIV if user selects NO (Cash) for "Do you have a BMO debit card?"-->
            <!--  -->
            <section *ngIf="bmoRelationship.hasBMODebitCard === 'No' && BILAssisted === false" id="noBMODebitCardSection">

                <h1 id="title1BmoRelationship" translate="bmoRelationship.title1BmoRelationship"></h1>

                <div class="form-wrapper">

                    <div role="radiogroup" aria-labelledby="title1BmoRelationship" id='parent-bmoRelationships-cash-error' class="form-row">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoRelationshipsCtrl.errors && bmoRelationshipsCtrl.touched || (isFormSubmit && bmoRelationshipsCtrl.errors), 'active-label': bmoRelationshipsCtrl.value}">
                                <input id="yesBmoRelationship" type="radio" name="bmoRelationshipClient"
                                       [(ngModel)]="bmoRelationship.isApplicantExistingClient"
                                       [appErrorAccess]="{ id: 'bmoRelationships-cash-error', parentId: 'parent-bmoRelationships-cash-error', submitted: isFormSubmit}"
                                       #bmoRelationshipsCtrl="ngModel" value="Yes"
                                       (click)="analytics.triggerView('isApplicantExistingClientYes')" required />
                                <label class="prefix" for="yesBmoRelationship"
                                       translate="bmoRelationship.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoRelationshipsCtrl.errors && bmoRelationshipsCtrl.touched || (isFormSubmit && bmoRelationshipsCtrl.errors), 'active-label': bmoRelationshipsCtrl.value}">
                                <input id="noBmoRelationship" type="radio" name="bmoRelationshipClient"
                                       [(ngModel)]="bmoRelationship.isApplicantExistingClient"
                                       [appErrorAccess]="{ id: 'bmoRelationships-cash-error', parentId: 'parent-bmoRelationships-cash-error', submitted: isFormSubmit}"
                                       #bmoRelationshipsCtrl="ngModel" value="No"
                                       (click)="analytics.triggerView('isApplicantExistingClientNo')" required />
                                <label class="prefix" for="noBmoRelationship"
                                       translate="bmoRelationship.labels.no"></label>

                            </div>
                            <div class="error-message" id="bmoRelationships-cash-error"
                                 *ngIf="bmoRelationshipsCtrl.errors && bmoRelationshipsCtrl.touched || (isFormSubmit && bmoRelationshipsCtrl.errors)">
                                <span translate="bmoRelationship.errors.selectBmoRelationship"></span>
                            </div>
                        </div>

                    </div>
                    <!-- Commented as of MWB-11008--BMO Relationship Page-Do not show Line of Business Drop down -->
                    <!-- <div *ngIf="bmoRelationship.isApplicantExistingClient === 'Yes'">

                        <div class="form-row">

                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <select id="selectBmoRelationshipCash"
                                            data-ana="mywealth:onboarding:bmo relationship:type"
                                            name="selectBmoRelationshipCash"
                                            (change)="fetchProductLabel($event.target.value)"
                                            [(ngModel)]="bmoRelationship.bmoProduct"
                                            #selectBmoRelationshipCtrl="ngModel" required>

                                            <option *ngFor="let product of bmoProducts" [value]="product.code"
                                                [selected]="product.selected">
                                                {{product.label}}
                                            </option>
                                        </select>
                                        <label
                                            [ngClass]="{'error-field': selectBmoRelationshipCtrl.errors && selectBmoRelationshipCtrl.touched || (isFormSubmit && selectBmoRelationshipCtrl.errors), 'active-label': selectBmoRelationshipCtrl.value}"
                                            class="slide-label" for="selectBmoRelationshipCash">
                                            <span class="label-text"
                                                translate="bmoRelationship.labels.bmoEntity"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" class="error-message"
                                    *ngIf="selectBmoRelationshipCtrl.errors && selectBmoRelationshipCtrl.touched || (isFormSubmit && selectBmoRelationshipCtrl.errors)">
                                    <span translate="bmoRelationship.errors.selectBMOLineOfBusiness"></span>
                                </div>
                            </div>

                        </div>

                    </div> -->

                </div>

            </section>


            <!--  -->
            <!-- HIDE this DIV for Cash"-->
            <!--  -->
            <section *ngIf="BILAssisted === false  && (isRRSPAccount || isTFSAAccount || isSpousalRRSP || isRRIF) && !isCashAccount && !isMargin" id="bmoRelationshipClientSection">

                <h1 id="title1BmoRelationship" translate="bmoRelationship.title1BmoRelationship"></h1>

                <div class="form-wrapper">

                    <div role="radiogroup" aria-labelledby="title1BmoRelationship" id='parent-bmoRelationships-error' class="form-row">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoRelationshipsCtrl.errors && bmoRelationshipsCtrl.touched || (isFormSubmit && bmoRelationshipsCtrl.errors), 'active-label': bmoRelationshipsCtrl.value}">
                                <input id="yesBmoRelationship" data-ana="mywealth:onboarding:bmo relationship:yes"
                                       type="radio" name="bmoRelationshipClient"
                                       [appErrorAccess]="{ id: 'bmoRelationships-error', parentId: 'parent-bmoRelationships-error', submitted: isFormSubmit}"
                                       [(ngModel)]="bmoRelationship.bmoRelationshipClient" #bmoRelationshipsCtrl="ngModel"
                                       value="Yes"
                                       (click)="analytics.triggerView('bmoRelationshipClientYes')" required />
                                <label class="prefix" for="yesBmoRelationship"
                                       translate="bmoRelationship.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoRelationshipsCtrl.errors && bmoRelationshipsCtrl.touched || (isFormSubmit && bmoRelationshipsCtrl.errors), 'active-label': bmoRelationshipsCtrl.value}">
                                <input id="noBmoRelationship" data-ana="mywealth:onboarding:bmo relationship:no"
                                       type="radio" name="bmoRelationshipClient"
                                       [(ngModel)]="bmoRelationship.bmoRelationshipClient" #bmoRelationshipsCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'bmoRelationships-error', parentId: 'parent-bmoRelationships-error', submitted: isFormSubmit}"
                                       value="No"
                                       (click)="analytics.triggerView('bmoRelationshipClientNo')" required />
                                <label class="prefix" for="noBmoRelationship"
                                       translate="bmoRelationship.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="bmoRelationships-error"
                                 *ngIf="bmoRelationshipsCtrl.errors && bmoRelationshipsCtrl.touched || (isFormSubmit && bmoRelationshipsCtrl.errors)">
                                <span translate="bmoRelationship.errors.selectBmoRelationship"></span>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="bmoRelationship.bmoRelationshipClient === 'Yes'">
                        <!-- Commented as of MWB-11008--BMO Relationship Page-Do not show Line of Business Drop down -->
                        <!--  <div class="form-row">

                              <div class="flex-70 form-col">
                                  <div class="form-field">
                                      <div class="form-label-group">
                                          <select id="selectBmoRelationship" name="selectBmoRelationship"
                                              data-ana="mywealth:onboarding:bmo relationship:type"
                                              (change)="fetchProductLabel($event.target.value)"
                                              [(ngModel)]="bmoRelationship.bmoProduct"
                                              #selectBmoRelationshipCtrl="ngModel" required>

                                              <option *ngFor="let product of bmoProducts" [value]="product.code"
                                                  [selected]="product.selected">
                                                  {{product.label}}
                                              </option>
                                          </select>
                                          <label
                                              [ngClass]="{'error-field': selectBmoRelationshipCtrl.errors && selectBmoRelationshipCtrl.touched || (isFormSubmit && selectBmoRelationshipCtrl.errors), 'active-label': selectBmoRelationshipCtrl.value}"
                                              class="slide-label" for="selectBmoRelationship">
                                              <span class="label-text"
                                                  translate="bmoRelationship.labels.bmoEntity"></span>
                                          </label>
                                      </div>
                                  </div>
                                  <div role="alert" class="error-message"
                                      *ngIf="selectBmoRelationshipCtrl.errors && selectBmoRelationshipCtrl.touched || (isFormSubmit && selectBmoRelationshipCtrl.errors)">
                                      <span translate="bmoRelationship.errors.selectBMOLineOfBusiness"></span>
                                  </div>

                              </div>

                          </div> -->
                        <div class="form-row"
                             *ngIf="(isCashAccount || isMargin) && bmoRelationship.bmoProduct === UI_CONSTANTS.BMO_PRODUCTS.debit_card && bmoRelationship.bmoProduct">

                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="cardNumber" name="cardNumber"
                                               [(ngModel)]="bmoRelationship.cardNumber" #cardNumberCtrl="ngModel"
                                               type="tel" InputValidator="ct3" minlength="16" maxlength="16"
                                               [appErrorAccess]="{ id: 'cardNumber-error', submitted: isFormSubmit}"
                                               required />
                                        <label [ngClass]="{'error-field': cardNumberCtrl.errors && cardNumberCtrl.touched || (isFormSubmit && cardNumberCtrl.errors), 'active-label': cardNumberCtrl.value}"
                                               class="slide-label" for="cardNumber">
                                            <span class="label-text"
                                                  translate="bmoRelationship.labels.cardNumber"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" class="error-message" id="cardNumber-error"
                                     *ngIf="cardNumberCtrl.errors && cardNumberCtrl.touched || (isFormSubmit && cardNumberCtrl.errors)">
                                    <span translate="bmoRelationship.errors.enterCardNumber"></span>
                                </div>
                            </div>

                        </div>
                        <div class="form-row"
                             *ngIf="(isCashAccount || isMargin) && bmoRelationship.bmoProduct != UI_CONSTANTS.BMO_PRODUCTS.debit_card && bmoRelationship.bmoProduct">

                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="accountNumber" name="accountNumber" type="tel"
                                               [(ngModel)]="bmoRelationship.accountNumber" #accountNumberCtrl="ngModel"
                                               [appErrorAccess]="{ id: 'accountNumber-error', submitted: isFormSubmit}"
                                               InputValidator="ct3" minlength="8" maxlength="8" required />
                                        <label [ngClass]="{'error-field': accountNumberCtrl.errors && accountNumberCtrl.touched || (isFormSubmit && accountNumberCtrl.errors), 'active-label': accountNumberCtrl.value}"
                                               class="slide-label" for="accountNumber">
                                            <span class="label-text"
                                                  translate="bmoRelationship.labels.accountNumber"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" class="error-message" id="accountNumber-error"
                                     *ngIf="accountNumberCtrl.errors && accountNumberCtrl.touched || (isFormSubmit && accountNumberCtrl.errors)">
                                    <span translate="bmoRelationship.errors.enterAccountNumber"></span>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <!--  -->
            <!-- Section for Easy Access to US dollars -->
            <!--  -->
            <!-- <section *ngIf="(isCashAccount || isMargin)">

                <h1 id="titleEasyAccessUs" translate="bmoRelationship.titleEasyAccessUs"></h1>
                <p translate="bmoRelationship.descriptionEasyAccessUs"></p>

                <div class="form-wrapper">

                    <div role="radiogroup" aria-labelledby="titleEasyAccessUs" id='parent-bmoAccessUS-error' class="form-row">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoAccessUSCtrl.errors && bmoAccessUSCtrl.touched || (isFormSubmit && bmoAccessUSCtrl.errors), 'active-label': bmoAccessUSCtrl.value}">
                                <input id="yesEasyAccessUs" type="radio" name="easyAccessUs" value="Yes"
                                       data-ana="mywealth:onboarding:us link:yes" [(ngModel)]="bmoRelationship.accessUS"
                                       [appErrorAccess]="{ id: 'bmoAccessUS-error', parentId: 'parent-bmoAccessUS-error', submitted: isFormSubmit}"
                                       #bmoAccessUSCtrl="ngModel"
                                       (click)="analytics.triggerView('yesEasyAccessUs')" required />
                                <label class="prefix" for="yesEasyAccessUs"
                                       translate="bmoRelationship.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': bmoAccessUSCtrl.errors && bmoAccessUSCtrl.touched || (isFormSubmit && bmoAccessUSCtrl.errors), 'active-label': bmoAccessUSCtrl.value}">
                                <input id="noEasyAccessUs" type="radio" name="easyAccessUs" value="No"
                                       data-ana="mywealth:onboarding:us link:no" [(ngModel)]="bmoRelationship.accessUS"
                                       [appErrorAccess]="{ id: 'bmoAccessUS-error', parentId: 'parent-bmoAccessUS-error', submitted: isFormSubmit}"
                                       #bmoAccessUSCtrl="ngModel"
                                       (click)="analytics.triggerView('noEasyAccessUs')"  required />
                                <label class="prefix" for="noEasyAccessUs"
                                       translate="bmoRelationship.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="bmoAccessUS-error"
                                 *ngIf="bmoAccessUSCtrl.errors && bmoAccessUSCtrl.touched || (isFormSubmit && bmoAccessUSCtrl.errors)">
                                <span translate="bmoRelationship.errors.selectionRequiredUSA"></span>
                            </div>

                        </div>

                    </div>


                </div>

            </section> -->

            <!-- MWB-11911 & 11913  -->
            <!-- Section for Private Bank Client SD Users & Branch Assisted -->
            <!--  -->
            <ng-container>
                <section *ngIf="(BranchAssisted && !isSubProductAD)" id="privateBankingSection">
                    <h1 id="titlePrivateBanking" translate="bmoRelationship.titlePrivateBanking"></h1>
                    <div class="form-wrapper">
                        <div role="radiogroup" aria-labelledby="titlePrivateBanking" id='parent-privateBanking-error'
                             class="form-row">
                            <div class="flex-elements form-col">
                                <div class="form-radio-checks radio-buttons"
                                     [ngClass]="{'error-field': privateBankingCtrl.errors && privateBankingCtrl.touched || (isFormSubmit && privateBankingCtrl.errors), 'active-label': privateBankingCtrl.value}">
                                    <input id="yesprivateBanking"
                                           type="radio"
                                           name="privateBanking"
                                           data-ana="investorline:wealth:onboarding:bmo relationship:pb or nesbitt:yes"
                                           value="Yes"
                                           [(ngModel)]="bmoRelationship.isApplicantExistingPBClient"
                                           [appErrorAccess]="{ id: 'privateBanking-error', parentId: 'parent-privateBanking-error', submitted: isFormSubmit}"
                                           #privateBankingCtrl="ngModel"
                                           (click)="analytics.triggerView('isApplicantExistingPBClientYes')" required />
                                    <label class="prefix" for="yesprivateBanking" translate="bmoRelationship.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons"
                                     [ngClass]="{'error-field': privateBankingCtrl.errors && privateBankingCtrl.touched || (isFormSubmit && privateBankingCtrl.errors), 'active-label': privateBankingCtrl.value}">
                                    <input id="noprivateBanking"
                                           type="radio"
                                           name="privateBanking"
                                           data-ana="investorline:wealth:onboarding:bmo relationship:pb or nesbitt:no"
                                           value="No"
                                           [(ngModel)]="bmoRelationship.isApplicantExistingPBClient"
                                           [appErrorAccess]="{ id: 'privateBanking-error', parentId: 'parent-privateBanking-error', submitted: isFormSubmit}"
                                           #privateBankingCtrl="ngModel"
                                           (click)="analytics.triggerView('isApplicantExistingPBClientNo')" required />
                                    <label class="prefix" for="noprivateBanking" translate="bmoRelationship.labels.no"></label>
                                </div>
                                <div role="alert" class="error-message" id="privateBanking-error"
                                     *ngIf="privateBankingCtrl.errors && privateBankingCtrl.touched || (isFormSubmit && privateBankingCtrl.errors)">
                                    <span translate="bmoRelationship.errors.selectionRequired"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container>

        </section>

        <section id="formSubmitButtons">
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater" formName="BRFrm"
                              [backRoute]="APP_ROUTES.consents"></app-form-buttons>
        </section>

        <!-- <pre>

            form
            {{ bmoRelationshipForm.value | json }}

            Object
            {{ bmoRelationship | json  }}

        </pre> -->

    </div>

</form>


