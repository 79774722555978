<input id="datePicker" type="text" class="bg-icon bg-icon-calendar" [readonly]="readonly" [attr.disabled]="(disabled)" (click)="toggleCalendar()"
       autocomplete="off" maxlength="14" minlength="14" fromControlName="date" #date placeholder={{placeholder}} [ngModel]="dateValue | dateFormat"
       (blur)="onTouched($event)" attr.aria-label="{{ 'personalInformation.ariaLabels.datePicker' | translate }}" />
<label class="slide-label hidden" for="datePicker" aria-hidden="true">{{'personalInformation.titleBirthday' | translate}}</label>

<div role="dialog" #datePickerPopup class="modal-dialog modal-small date-picker" *ngIf="showCalendar" [cdkTrapFocus] cdkTrapFocusNextItem="1,-1">
    <div class="bmo-container-flex">
        <!-- year display -->
        <div class="date-picker-year" *ngIf="showYearCalendar">

            <div class="date-picker-header">
                <div class="bmo-container-flex">

                    <h1 class="flex-col-expand" translate="datePicker.selectAYear"></h1>
                    <app-date-picker-close-button (notify)='onNotify($event)'></app-date-picker-close-button>

                </div>
            </div>

            <div class="date-picker-nav-selection">
                <div class="bmo-container-flex">

                    <div class="flex-equal-fixed">
                        <a role="button"
                           attr.aria-label="{{ 'ariaLabels.datePickerNavLeft' | translate }}"
                           attr.tooltip="{{ 'linkButton.previous' | translate }}"
                           class="btn-icon icon-large tooltip-show"
                           href="javascript://"
                           (click)=previousDecade()>
                            <img src="assets/img/chevron-left-blue.svg" alt="" />
                        </a>
                    </div>
                    <div class="flex-col-expand">
                        {{decade}}
                    </div>
                    <div class="flex-equal-fixed">
                        <a role="button"
                           attr.aria-label="{{ 'ariaLabels.datePickerNavRight' | translate }}"
                           attr.tooltip="{{ 'linkButton.next' | translate }}"
                           class="btn-icon icon-large tooltip-show"
                           href="javascript://"
                           (click)=nextDecade()>
                            <img src="assets/img/chevron-right-blue.svg" alt="" />
                        </a>
                    </div>

                </div>
            </div>

            <div class="date-picker-body">

                <ul class="bmo-container-flex">
                    <li class="flex-equal-fixed" *ngFor="let year of yearArr">
                        <a role="button" [tabindex]="unselectedYear(year) ? -1 : 0" href="javascript://" [ngClass]="{'disabled': unselectedYear(year), 'selected': pickedYearToHighlight(year)}"
                           (click)="chosenYear(year)">{{year}}</a>
                    </li>
                </ul>

            </div>

        </div>
        <!-- month display -->
        <div class="date-picker-month" *ngIf="showMonthsCalendar">

            <div class="date-picker-header">
                <div class="bmo-container-flex">

                    <app-date-picker-back-button (notify)='onNotify($event)'></app-date-picker-back-button>
                    <h1 class="flex-col-expand" translate="datePicker.selectAMonth"></h1>
                    <app-date-picker-close-button (notify)='onNotify($event)'></app-date-picker-close-button>

                </div>
            </div>

            <div class="date-picker-body">

                <ul class="bmo-container-flex">
                    <li class="flex-equal-fixed" *ngFor="let month of monthsArr; let i = index">
                        <a role="button" attr.aria-label="{{ 'datePicker.ariaLabels.dpMonths.m'+i  | translate }}" [tabindex]="unselectedMonth(i) ? -1 : 0"
                           href="javascript://" [ngClass]="{'disabled': unselectedMonth(i), 'selected': pickedMonthToHighlight(i)}"
                           (click)="chosenMonth(i)">{{month}}</a>
                    </li>
                </ul>

            </div>

        </div>
        <!-- day display -->
        <div class="date-picker-day" *ngIf="showDaysCalendar">

            <div class="date-picker-header">
                <div class="bmo-container-flex">

                    <app-date-picker-back-button (notify)='onNotify($event)' [showDaysCalendar]="true">
                    </app-date-picker-back-button>
                    <h1 class="flex-col-expand" translate="datePicker.selectADay"></h1>
                    <app-date-picker-close-button (notify)='onNotify($event)'></app-date-picker-close-button>

                </div>
            </div>

            <div class="date-picker-nav-selection scroll-month">
                <div class="bmo-container-flex">

                    <h2 class="flex-col-expand">
                        {{monthYear}}
                    </h2>

                </div>
            </div>

            <div *ngIf="showWeekDayNames" class="date-picker-body date-picker-body-days">
                <div class="bmo-container-flex">
                    <table class="date-picker-dates">
                        <caption class="aoda-off-screen-element" translate="datePicker.dpTableCaptionDays"></caption>
                        <thead>
                            <tr>
                                <th>
                                    <span aria-hidden="true" translate="datePicker.dpDaySun"></span>
                                    <span class="off-screen" translate="datePicker.ariaLabels.dpDays.d0"></span>
                                </th>
                                <th>
                                    <span aria-hidden="true" translate="datePicker.dpDayMon"></span>
                                    <span class="off-screen" translate="datePicker.ariaLabels.dpDays.d1"></span>
                                </th>
                                <th>
                                    <span aria-hidden="true" translate="datePicker.dpDayTue"></span>
                                    <span class="off-screen" translate="datePicker.ariaLabels.dpDays.d2"></span>
                                </th>
                                <th>
                                    <span aria-hidden="true" translate="datePicker.dpDayWed"></span>
                                    <span class="off-screen" translate="datePicker.ariaLabels.dpDays.d3"></span>
                                </th>
                                <th>
                                    <span aria-hidden="true" translate="datePicker.dpDayThu"></span>
                                    <span class="off-screen" translate="datePicker.ariaLabels.dpDays.d4"></span>
                                </th>
                                <th>
                                    <span aria-hidden="true" translate="datePicker.dpDayFri"></span>
                                    <span class="off-screen" translate="datePicker.ariaLabels.dpDays.d5"></span>
                                </th>
                                <th>
                                    <span aria-hidden="true" translate="datePicker.dpDaySat"></span>
                                    <span class="off-screen" translate="datePicker.ariaLabels.dpDays.d6"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td *ngFor="let day of daysArr.slice(0,7)">
                                    <a *ngIf="unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'disabled': 'true', 'selected':pickedDayToHighlight(day)}"
                                        aria-hidden="true"  tabindex="-1"  (click)="chosenDay(day)">{{day?.date()}}</a>
                                    <a *ngIf="!unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'selected':pickedDayToHighlight(day)}"
                                        (click)="chosenDay(day)">{{day?.date()}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let day of daysArr.slice(7,14)">
                                    <a *ngIf="unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'disabled': 'true', 'selected':pickedDayToHighlight(day)}"
                                    aria-hidden="true"  tabindex="-1"  (click)="chosenDay(day)">{{day?.date()}}</a>
                                <a *ngIf="!unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'selected':pickedDayToHighlight(day)}"                                  
                                  (click)="chosenDay(day)">{{day?.date()}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let day of daysArr.slice(14,21)">
                                    <a *ngIf="unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'disabled': 'true', 'selected':pickedDayToHighlight(day)}"
                                    aria-hidden="true"  tabindex="-1"  (click)="chosenDay(day)">{{day?.date()}}</a>
                                <a *ngIf="!unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'selected':pickedDayToHighlight(day)}"                                  
                                  (click)="chosenDay(day)">{{day?.date()}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let day of daysArr.slice(21,28)">
                                    <a *ngIf="unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'disabled': 'true', 'selected':pickedDayToHighlight(day)}"
                                    aria-hidden="true"  tabindex="-1"  (click)="chosenDay(day)">{{day?.date()}}</a>
                                <a *ngIf="!unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'selected':pickedDayToHighlight(day)}"                                  
                                  (click)="chosenDay(day)">{{day?.date()}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let day of daysArr.slice(28,35)">
                                    <a *ngIf="unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'disabled': 'true', 'selected':pickedDayToHighlight(day)}"
                                        aria-hidden="true"  tabindex="-1"  (click)="chosenDay(day)">{{day?.date()}}</a>
                                    <a *ngIf="!unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'selected':pickedDayToHighlight(day)}"                                  
                                      (click)="chosenDay(day)">{{day?.date()}}</a>
                                </td>
                            </tr>
                            <tr *ngIf="daysArr.length>35">
                                <td *ngFor="let day of daysArr.slice(35,42)">
                                    <a *ngIf="unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'disabled': 'true', 'selected':pickedDayToHighlight(day)}"
                                        aria-hidden="true"  tabindex="-1"  (click)="chosenDay(day)">{{day?.date()}}</a>
                                    <a *ngIf="!unselectedDay(day)"  role="button"  href="javascript://" [ngClass]="{'selected':pickedDayToHighlight(day)}"                                  
                                      (click)="chosenDay(day)">{{day?.date()}}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </div>
</div>
