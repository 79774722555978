import { Component, OnInit } from '@angular/core';
import { Jquery } from '../../shared/utils/jquery.class';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../shared/utils/utils.class';
import { ProgressBarService } from '../../shared/service/progress-bar.service';
import { APP_ROUTES, PROGRESS_BAR_TOTAL_STEPS } from './../../config/app-config.constants';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
@Component({
  selector: 'app-with-nav',
  templateUrl: './with-nav.component.html'
})
export class WithNavComponent implements OnInit {

  public progressInfoData: any = {};

  public isSubProductAD: boolean;
  public isIEBrowser: boolean;
  public url: any;
  isBranchAssisted: boolean;
  public isCookieEnabled: boolean;

  constructor(private route: ActivatedRoute, public router: Router,
    private configService: ConfigResolverService<any>,
     private progressBar: ProgressBarService, private windowref: WindowRefService) { }

  ngOnInit() {
    this.isCookieEnabled = navigator.cookieEnabled;
    this.isIEBrowser = this.windowref.isIEBrowser();
    this.isBranchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
  }

  routeChanges() {
    this.isSubProductAD = Utils.isSubProductAD();
    this.url = this.router.url;
    if(Utils.getIsBranchAssisted()) {
      this.progressBar.setProgressInfo(this.setInfoBranch());
    } else {
      this.progressBar.setProgressInfo(this.setInfo());
    }
    

    Jquery.run();
  }
  continue(){
    if (navigator.cookieEnabled) {
      window.location.reload();
    }
  }

  setInfo() {
    this.url = this.url.replace('/', '').split('?')[0];

    switch (this.url) {

      // Select-account
      case APP_ROUTES.select_account:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 1,
          showProgressBar: true,
          pageName: 'mainNav.selectAccount'
        };

        break;

      // Personal-information
      case APP_ROUTES.personal_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 2,
          showProgressBar: true,
          pageName: 'mainNav.personalInformation'
        };

        break;

      // Contact-information
      case APP_ROUTES.contact_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 3,
          showProgressBar: true,
          pageName: 'mainNav.contactInformation'
        };

        break;

      // Employment-information
      case APP_ROUTES.employment_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 4,
          showProgressBar: true,
          pageName: 'mainNav.employmentInformation'
        };

        break;

      // Family-information
      case APP_ROUTES.family_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 5,
          showProgressBar: true,
          pageName: 'mainNav.familyInformation'
        };

        break;

      // Celebration
      // case APP_ROUTES.celebration:  //  MWB-13833

      //   this.progressInfoData = {
      //     totalSteps: (this.isSubProductAD) ? PROGRESS_BAR_TOTAL_STEPS.AD : PROGRESS_BAR_TOTAL_STEPS.SD,
      //     currentStep: 4,
      //     showProgressBar: false,
      //     pageName: 'mainNav.financialInformation' // no corresponding translation
      //   };

      //   break;
      // withdrawal
      case APP_ROUTES.withdrawal:

        this.progressInfoData = {
          totalSteps:  (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 6,
          showProgressBar: true,
          pageName: 'mainNav.withdrawal'
        };

        break;
      // Financial-details
      case APP_ROUTES.financial_details:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep:  this.totalAndCurrentstepsSelf(6),
          showProgressBar: true,
          pageName: 'mainNav.financialInformation'
        };

        break;

      // Investment Knowledge
      case APP_ROUTES.investment_knowledge:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(7) : this.totalAndCurrentstepsSelf(6),
          showProgressBar: true,
          pageName: 'mainNav.investmentKnowledge'
        };

        break;

      // Investment Objectives
      case APP_ROUTES.riskAndReturn:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(8) : this.totalAndCurrentstepsSelf(6),
          showProgressBar: true,
          pageName: 'mainNav.riskAndReturn'
        };

        break;

      // Investor Profile
      case APP_ROUTES.investor_profile:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(9) : this.totalAndCurrentstepsSelf(6),
          showProgressBar: true,
          pageName: 'mainNav.investorProfile'
        };

        break;

      // Regulatory Disclosures
      case APP_ROUTES.regulatory_disclosures:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(10) : this.totalAndCurrentstepsSelf(7),
          showProgressBar: true,
          pageName: 'mainNav.regulatory'
        };

        break;

      // Consents
      case APP_ROUTES.consents:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(11) : this.totalAndCurrentstepsSelf(8),
          showProgressBar: true,
          pageName: 'mainNav.consents'
        };

        break;

      // Bmo-relationship
      case APP_ROUTES.bmo_relationship:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(12) : this.totalAndCurrentstepsSelf(9),
          showProgressBar: true,
          pageName: 'mainNav.bmoRelationship'
        };

        break;

      // Account-password
      case APP_ROUTES.account_password:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(13) : this.totalAndCurrentstepsSelf(10),
          showProgressBar: true,
          pageName: 'mainNav.accountPassword'
        };

        break;

      // Review
      case APP_ROUTES.review:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(14) : this.totalAndCurrentstepsSelf(11),
          showProgressBar: true,
          pageName: 'mainNav.review'
        };

        break;

      // Celebration-review
      case APP_ROUTES.celebration_review:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(14) : this.totalAndCurrentstepsSelf(11),
          showProgressBar: true,
          pageName: 'mainNav.termsConditions'
        };

        break;

      // T&C
      case APP_ROUTES.terms_conditions:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(15) : this.totalAndCurrentstepsSelf(12),
          showProgressBar: true,
          pageName: 'mainNav.termsConditions'
        };

        break;
      // default
      default:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsSelf(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 0,
          showProgressBar: false,
          pageName: ''
        };

        break;

    }

    return this.progressInfoData;
  }
  totalAndCurrentstepsSelf(step) {
    if(Utils.isRIF()) {  
      return step + 1;   //  withdrawl
  } else  {
      return step;  
  }
  }
  setInfoBranch() {
    this.url = this.url.replace('/', '').split('?')[0];

    switch (this.url) {

      // Select-account
      case APP_ROUTES.select_account:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 1,
          showProgressBar: true,
          pageName: 'mainNav.selectAccount'
        };

        break;

      // Personal-information
      case APP_ROUTES.personal_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 2,
          showProgressBar: true,
          pageName: 'mainNav.personalInformation'
        };

        break;

      // Contact-information
      case APP_ROUTES.contact_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 3,
          showProgressBar: true,
          pageName: 'mainNav.contactInformation'
        };

        break;

      // Employment-information
      case APP_ROUTES.employment_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 4,
          showProgressBar: true,
          pageName: 'mainNav.employmentInformation'
        };

        break;

      // Family-information
      case APP_ROUTES.family_information:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 5,
          showProgressBar: true,
          pageName: 'mainNav.familyInformation'
        };

        break;

      // Celebration
      // case APP_ROUTES.celebration:  //  MWB-13833

      //   this.progressInfoData = {
      //     totalSteps: (this.isSubProductAD) ? PROGRESS_BAR_TOTAL_STEPS.AD : PROGRESS_BAR_TOTAL_STEPS.SD,
      //     currentStep: 4,
      //     showProgressBar: false,
      //     pageName: 'mainNav.financialInformation' // no corresponding translation
      //   };

      //   break;
      // Beneficiary details
      case APP_ROUTES.beneficiary:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 6,
          showProgressBar: true,
          pageName: 'mainNav.beneficiary'
        };

        break;
              // withdrawal
      case APP_ROUTES.withdrawal:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? 7 : 6,
          showProgressBar: true,
          pageName: 'mainNav.withdrawal'
        };

        break;
      // Financial-details
      case APP_ROUTES.financial_details:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: this.totalAndCurrentstepsBranch(6),
          showProgressBar: true,
          pageName: 'mainNav.financialInformation'
        };

        break;

      // Investment Knowledge
      case APP_ROUTES.investment_knowledge:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(7) : this.totalAndCurrentstepsBranch(6),
          showProgressBar: true,
          pageName: 'mainNav.investmentKnowledge'
        };

        break;

      // Investment Objectives
      case APP_ROUTES.riskAndReturn:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(8) : this.totalAndCurrentstepsBranch(6),
          showProgressBar: true,
          pageName: 'mainNav.riskAndReturn'
        };

        break;

      // Investor Profile
      case APP_ROUTES.investor_profile:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(9) : this.totalAndCurrentstepsBranch(6),
          showProgressBar: true,
          pageName: 'mainNav.investorProfile'
        };

        break;

      // Regulatory Disclosures
      case APP_ROUTES.regulatory_disclosures:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(10) : this.totalAndCurrentstepsBranch(7),
          showProgressBar: true,
          pageName: 'mainNav.regulatory'
        };

        break;

      // Consents
      case APP_ROUTES.consents:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(11) : this.totalAndCurrentstepsBranch(8),
          showProgressBar: true,
          pageName: 'mainNav.consents'
        };

        break;

      // Bmo-relationship
      case APP_ROUTES.bmo_relationship:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(12) : this.totalAndCurrentstepsBranch(9),
          showProgressBar: true,
          pageName: 'mainNav.bmoRelationship'
        };

        break;

      // Account-password
      case APP_ROUTES.account_password:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(13) : this.totalAndCurrentstepsBranch(10),
          showProgressBar: true,
          pageName: 'mainNav.accountPassword'
        };

        break;

      // Review
      case APP_ROUTES.review:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(14) : this.totalAndCurrentstepsBranch(11),
          showProgressBar: true,
          pageName: 'mainNav.review'
        };

        break;

      // Celebration-review
      case APP_ROUTES.celebration_review:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ?  this.totalAndCurrentstepsBranch(14) : this.totalAndCurrentstepsBranch(11),
          showProgressBar: true,
          pageName: 'mainNav.termsConditions'
        };

        break;

      // T&C
      case APP_ROUTES.terms_conditions:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: (this.isSubProductAD) ?  this.totalAndCurrentstepsBranch(15) : this.totalAndCurrentstepsBranch(12),
          showProgressBar: true,
          pageName: 'mainNav.termsConditions'
        };

        break;
      // default
      default:

        this.progressInfoData = {
          totalSteps: (this.isSubProductAD) ? this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.AD) : this.totalAndCurrentstepsBranch(PROGRESS_BAR_TOTAL_STEPS.SD),
          currentStep: 0,
          showProgressBar: false,
          pageName: ''
        };

        break;

    }

    return this.progressInfoData;
  }
  totalAndCurrentstepsBranch(step) {
    if((Utils.isCash() || Utils.isMargin()) && !Utils.isRIF()) {
      return step;
    } else if (Utils.isRIF()) {  
      return (this.isSubProductAD) ? step + 2 :  step + 1;   // beneficiary and withdrawl
    } else  {
        return (this.isSubProductAD) ? step + 1 :  step;  // beneficiary
    }
  }

}
