import { Directive, ElementRef, HostListener, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Jquery } from '../utils/jquery.class';
import * as $ from 'jquery';

@Directive({
    selector: '[appFocusTrapModal]'
})
export class FocusTrapModalDirective implements OnInit, AfterViewInit, OnDestroy {

    focusElements;
    firstElement;
    lastElement;
    elements;

    constructor(private el: ElementRef) { }

    ngOnInit() {

        Jquery.modalFocusTrap();

    }

    ngAfterViewInit(): void {

        // console.log('active now', $(document.activeElement));

        // this.elements = Jquery.modalFocusTrap();
        // console.log('first  ', this.firstElement =  this.elements[0]);
        // console.log('last  ', this.lastElement = this.elements[1]);


        //   // - Redirect first shift+tab to last element in the array
        //   this.firstElement.on('keydown', function (e) {
        //     console.log('on first ele');

        //     if ((e.which === 9 && e.shiftKey)) {
        //         console.log('e.which: ', e.which);
        //         e.preventDefault();
        //         this.lastElement.focus();
        //     }
        // });

        // // - Redirect last tab to first element in the array
        // this.lastElement.on('keydown', function (e) {

        //     console.log('on last ele');

        //     if ((e.which === 9 && !e.shiftKey)) {
        //         console.log('last e.which: ', e.which);
        //         e.preventDefault();
        //         this.firstElement.focus();
        //     }
        // });
    }

    @HostListener('keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {


         // - Redirect first shift+tab to last element in the array

        //  if ((event.keyCode === 9)) {
        //     console.log('e.which: ', event.which);
        //     event.preventDefault();
        //     // this.lastElement.focus();
        // }

    }

    ngOnDestroy() {
        Jquery.modalDissableFocusTrap();
    }


}
