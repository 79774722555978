<!-- <app-flood-light></app-flood-light> -->

<form #accountPasswordForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div id="accountAccessSection" class="container-menuView">
        <section class="genericSectionForError">
            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="accountPasswordForm.invalid && isFormSubmit"
                translateTag="accountPassword"></app-invalid-form>
            <section section id="AccountPasswordSection" *ngIf="!(isJourneyUser && isNonRegisteredAccount)">
                <div id="accountAccessHeaderSection" accountAccess>
                    <h1 translate="accountPassword.titleAccountPassword"></h1>

                    <p *ngIf="(isSubProductAD || branchAssisted || !isMultiAccountOpen) && !this.branchSDMultiAccount"
                        [innerHTML]="'accountPassword.descriptionAccountPassword' | translate | safeHtml"></p>
                    <p *ngIf="(!isSubProductAD && !branchAssisted && isMultiAccountOpen) || this.branchSDMultiAccount"
                        [innerHTML]="'accountPassword.descriptionAccountPasswordMAO' | translate | safeHtml"></p>
                </div>

                <div id="accountAccessPasswordSection" class="form-wrapper">
                    <div class="form-row">

                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div id="accountAccessCreatePasswordSection" class="form-label-group">

                                    <input id="createPassword" aria-describedby="passwordCheck"
                                        data-ana="mywealth:onboarding:create password" name="password" type="password"
                                        maxlength="6" [(ngModel)]="accountPassword.password" #accountPasswordCtrl="ngModel"
                                        InputValidator='ct4' appPasswordValidator required
                                        (onkeypress)="trackPasswordCheck()" />
                                    <label class="slide-label" for="createPassword"
                                        [ngClass]="{'error-field':isError(accountPasswordCtrl), 'active-label':accountPasswordCtrl.value}">
                                        <span class="label-text" translate="accountPassword.labels.createPassword"></span>
                                    </label>
                                    <a role="button"
                                        *ngIf="(accountPassword?.password?.length > 0 || accountPassword?.confirmPassword?.length > 0 );else disabledEyeIcon"
                                        (click)="togglePasswordVisibility('createPassword' , 'confirmPassword')"
                                        class="btn-icon reveal-password"
                                        attr.aria-label="{{'accountPassword.ariaLabels.showPassword' | translate}}"
                                        attr.aria-pressed="{{passwordHide == true ? 'false' : 'true'}}"
                                        href="javascript://">
                                        <span
                                            [class]="passwordHide == true ? 'icon icon-large password-hide-blue' : 'icon icon-large password-show-blue'"></span>
                                    </a>
                                    <ng-template #disabledEyeIcon>
                                        <a role="button" aria-disabled="true" class="disabled btn-icon reveal-password"
                                            attr.aria-label="{{'accountPassword.ariaLabels.disabledShowPassword' | translate}}"
                                            href="javascript://">
                                            <span
                                                [class]="passwordHide == true ? 'icon icon-large password-hide-grey' : 'icon icon-large password-show-grey'"></span>
                                        </a>
                                    </ng-template>
                                </div>
                            </div>

                            <div role="alert" id="accountPassword-error" class="error-message"
                                *ngIf="isError(accountPasswordCtrl)">
                                <span translate="accountPassword.errors.passwordFormat"></span>
                            </div>
                            <div class="off-screen" id="passwordCheck">
                                <p translate="accountPassword.labels.passwordMust"></p>
                                <ul>
                                    <li translate="accountPassword.labels.maxChar"></li>
                                    <li translate="accountPassword.labels.minLetter"></li>
                                    <li translate="accountPassword.labels.minNumber"></li>
                                    <li translate="accountPassword.labels.noSpecialChar"></li>
                                </ul>
                            </div>
                            <div id="accountAccessPasswordCheckSection" class="password-checks" aria-hidden="true">
                                <p translate="accountPassword.labels.passwordMust"></p>
                                <div id="accountAccessPasswordCheckListSection" class="bmo-container-flex">
                                    <div class="flex-40">
                                        <span class='icon-inline'
                                            [ngClass]="accountPasswordCtrl?.errors?.lengthMustBeGreaterThanFive ? 'icon-error' : 'icon-check'"
                                            translate="accountPassword.labels.maxChar"></span>
                                        <span class='icon-inline'
                                            [ngClass]="accountPasswordCtrl?.errors?.noLettersPresent ? 'icon-error' : 'icon-check'"
                                            translate="accountPassword.labels.minLetter"></span>
                                    </div>
                                    <div class="flex-40">
                                        <span class='icon-inline'
                                            [ngClass]="accountPasswordCtrl?.errors?.noDigitsPresent ? 'icon-error' : 'icon-check'"
                                            translate="accountPassword.labels.minNumber"></span>
                                        <span class='icon-inline'
                                            [ngClass]="accountPasswordCtrl?.errors?.noSpecialCharactersAllowed ? 'icon-error' : 'icon-check'"
                                            translate="accountPassword.labels.noSpecialChar"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-row">

                        <div class="flex-70 form-col no-margin">
                            <div class="form-field">
                                <div id="accountAccessConfirmPasswordSection" class="form-label-group">
                                    <input id="confirmPassword" aria-describedby="confirmPassword"
                                        data-ana="mywealth:onboarding:confirm password" type="password"
                                        name="confirmPassword" [(ngModel)]="accountPassword.confirmPassword"
                                        #accountConfirmPasswordCtrl="ngModel" maxlength="6"
                                        [appEqualTo]="accountPasswordCtrl" InputValidator='ct4' required />
                                    <label class="slide-label" for="confirmPassword"
                                        [ngClass]="{'error-field':isError(accountConfirmPasswordCtrl),
                                                                                            'active-label':accountConfirmPasswordCtrl.value}">
                                        <span class="label-text" translate="accountPassword.labels.confirmPassword"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="accountConfirmPassword-error" class="error-message"
                                *ngIf="isError(accountConfirmPasswordCtrl)">
                                <span translate="accountPassword.errors.passwordMatch"></span>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <section *ngIf="(isCdbOlbUser || isJourneyUser) && isNonRegisteredAccount">
                <section id="accountAccessConsentLinkSection" class="add-margin t32px">

                    <h1 translate="accountAccess.titleAccountLink"></h1>
                    <div class="alert alert-info" role="alert">
                        <p *ngIf="subProduct == 'SD' "
                            [innerHtml]="'accountAccess.description1AccoutLink' | translate | safeHtml"></p>
                        <p *ngIf="subProduct == 'AD' "
                            [innerHtml]="'accountAccess.ad_description1AccoutLink' | translate | safeHtml"></p>
                    </div>

                    <div class="form-wrapper add-margin t28px">
                        <div class="form-row">
                            <div id="accountAccessAccountLinkConsentSection" class="form-col">
                                <div class="form-input-checks check-default"
                                    [ngClass]="{'error-field': accountAccessAccountLinkConsentCntrl.errors && accountAccessAccountLinkConsentCntrl.touched || (isFormSubmit && accountAccessAccountLinkConsentCntrl.errors)}">
                                    <input id="accountAccessAccountLinkConsent" type="checkbox"
                                        name="accountAccessAccountLinkConsent"
                                        [(ngModel)]="accountPassword.consentToAccountLink"
                                        #accountAccessAccountLinkConsentCntrl="ngModel"
                                        data-ana="investorline:wealth:onboarding:account link:consent" required />
                                    <label for="accountAccessAccountLinkConsent"
                                        translate="accountAccess.labels.accountLinkConsent"></label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" *ngIf="accountAccessAccountLinkConsentCntrl.errors && accountAccessAccountLinkConsentCntrl.touched ||
                                                            (isFormSubmit && accountAccessAccountLinkConsentCntrl.errors)">
                                <span translate="accountAccess.errors.selectConsentAccountLink"></span>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="add-margin t32px" id="accountAccessDebitCardSection" section
                    *ngIf="isCdbOlbUser && debitCardLogin && !isJourneyUser">
                    <h1 *ngIf="!isMultiAccountFlow || selectedMultiAccount?.length <= 1" id="ariaAccessFundsDebit"
                        [translateParams]={debitCardNum:debitCardNumbers[0]} translate="accountAccess.titleAccessFunds">
                    </h1>
                    <h1 *ngIf="isMultiAccountFlow && selectedMultiAccount?.length > 1" id="ariaAccessFundsDebit"
                        [translateParams]={debitCardNum:debitCardNumbers[0]} translate="accountAccess.titleAccessFundsMAO">
                    </h1>
                    <p *ngIf="!isMultiAccountFlow || selectedMultiAccount?.length <= 1"
                        [innerHTML]="'accountAccess.descriptionAccessFunds01' | translate | safeHtml"></p>
                    <p *ngIf="isMultiAccountFlow && selectedMultiAccount?.length > 1"
                        [innerHTML]="'accountAccess.descriptionAccessFunds01MAO' | translate | safeHtml"></p>

                    <div class="form-wrapper">
                        <div class="form-row" role="radiogroup" aria-labelledby="ariaAccessFundsDebit"
                            id="parent-accessFundsDebit-error">

                            <div id="accountAccessDebitCardButtonSectionOne" class="flex-elements form-col no-margin">
                                <div class="form-radio-checks radio-buttons"
                                    [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                    <input id="yesAccessFundsDebit" type="radio" name="accessFundsDebit"
                                        data-ana="investorline:wealth:onboarding:debit card:yes" value="Yes"
                                        [appErrorAccess]="{ id: 'accessFundsDebit-error', parentId: 'parent-accessFundsDebit-error', submitted: isFormSubmit}"
                                        [(ngModel)]="accountPassword.hasBMODebitCard" #accessFundsCtrl="ngModel" required />
                                    <label class="prefix" for="yesAccessFundsDebit"
                                        attr.aria-label="{{'accountAccess.ariaLabels.accessFundsYes' | translate}}"
                                        translate="accountAccess.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons"
                                    [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                    <input id="noAccessFundsDebit" type="radio" name="accessFundsDebit" value="No"
                                        [appErrorAccess]="{ id: 'accessFundsDebit-error', parentId: 'parent-accessFundsDebit-error', submitted: isFormSubmit}"
                                        data-ana="investorline:wealth:onboarding:debit card:no"
                                        [(ngModel)]="accountPassword.hasBMODebitCard" #accessFundsCtrl="ngModel" required />
                                    <label class="prefix" for="noAccessFundsDebit"
                                        attr.aria-label="{{'accountAccess.ariaLabels.accessFundsNo' | translate}}"
                                        translate="accountAccess.labels.no"></label>
                                </div>
                                <div id="accessFundsDebit-error"
                                    *ngIf="accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors)"
                                    role="alert" class="error-message">
                                    <span [translateParams]={debitCardNum:debitCardNumbers[0]}
                                        translate="accountAccess.errors.accessFundsCardError"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section *ngIf="creditCardLogin || isJourneyUser">
                    <section id="accountAccessOneDebitCardSection" *ngIf="debitCardNumbers?.length === 1 ">
                        <h1 *ngIf="!isMultiAccountFlow || selectedMultiAccount?.length <= 1" id="ariaAccessFundsCredit"
                            [translateParams]={debitCardNum:debitCardNumbers[0]} translate="accountAccess.titleAccessFunds">
                        </h1>
                        <h1 *ngIf="isMultiAccountFlow && selectedMultiAccount?.length > 1" id="ariaAccessFundsCredit"
                            [translateParams]={debitCardNum:debitCardNumbers[0]} translate="accountAccess.titleAccessFundsMAO">
                        </h1>
                        <p *ngIf="!isMultiAccountFlow || selectedMultiAccount?.length <= 1"
                            [innerHTML]="'accountAccess.descriptionAccessFunds01' | translate | safeHtml"></p>
                        <p *ngIf="isMultiAccountFlow && selectedMultiAccount?.length > 1"
                            [innerHTML]="'accountAccess.descriptionAccessFunds01MAO' | translate | safeHtml"></p>

                        <div class="form-wrapper">
                            <div class="form-row" role="radiogroup" aria-labelledby="ariaAccessFundsCredit"
                                id="parent-accessFundsCredit-error">

                                <div id="accountAccessDebitCardButtonSectionTwo" class="flex-elements form-col no-margin">
                                    <div class="form-radio-checks radio-buttons"
                                        [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                        <input id="yesAccessFundsCredit" type="radio" name="accessFundsCredit" value="Yes"
                                            [appErrorAccess]="{ id: 'accessFundsCredit-error', parentId: 'parent-accessFundsCredit-error', submitted: isFormSubmit}"
                                            [(ngModel)]="accountPassword.hasBMODebitCard" #accessFundsCtrl="ngModel" required />
                                        <label class="prefix" for="yesAccessFundsCredit"
                                            attr.aria-label="{{'accountAccess.ariaLabels.accessFundsYes' | translate}}"
                                            translate="accountAccess.labels.yes"></label>
                                    </div>
                                    <div class="form-radio-checks radio-buttons"
                                        [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                        <input id="noAccessFundsCredit" type="radio" name="accessFundsCredit" value="No"
                                            [appErrorAccess]="{ id: 'accessFundsCredit-error', parentId: 'parent-accessFundsCredit-error', submitted: isFormSubmit}"
                                            [(ngModel)]="accountPassword.hasBMODebitCard" #accessFundsCtrl="ngModel" required />
                                        <label class="prefix" for="noAccessFundsCredit"
                                            attr.aria-label="{{'accountAccess.ariaLabels.accessFundsNo' | translate}}"
                                            translate="accountAccess.labels.no"></label>
                                    </div>
                                    <div id="accessFundsCredit-error"
                                        *ngIf="accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors)"
                                        role="alert" class="error-message">
                                        <span [translateParams]={debitCardNum:debitCardNumbers[0]}
                                            translate="accountAccess.errors.accessFundsCardError"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="accountAccessMoreThanOneDebitCardSection" *ngIf="debitCardNumbers?.length > 1 ">
                        <h1 *ngIf="!isMultiAccountFlow || selectedMultiAccount?.length <= 1" id="ariaAccessFundsMultipleCards"
                            translate="accountAccess.titleAccessFundsMultipleCards"></h1>
                        <h1 *ngIf="isMultiAccountFlow && selectedMultiAccount?.length > 1" id="ariaAccessFundsMultipleCards"
                            translate="accountAccess.titleAccessFundsMultipleCardsMAO"></h1>
                        <p [innerHTML]="'accountAccess.descriptionAccessFunds02' | translate | safeHtml"></p>

                        <div class="form-wrapper">
                            <div class="form-row" role="radiogroup" aria-labelledby="ariaAccessFundsMultipleCards"
                                id="parent-accessFundsMultipleCards-error">

                                <div id="accountAccessDebitCardButtonSectionThree" class="flex-elements form-col no-margin">
                                    <div class="form-radio-checks radio-buttons"
                                        [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                        <input id="yesAccessFundsMultiple" type="radio" name="accessFundsMultiple" value="Yes"
                                            [appErrorAccess]="{ id: 'accessFundsMultipleCards-error', parentId: 'parent-accessFundsMultipleCards-error', submitted: isFormSubmit}"
                                            [(ngModel)]="accountPassword.hasBMODebitCard" #accessFundsCtrl="ngModel" required />
                                        <label class="prefix" for="yesAccessFundsMultiple"
                                            attr.aria-label="{{'accountAccess.ariaLabels.accessFundsMultipleYes' | translate}}"
                                            translate="accountAccess.labels.yes"></label>
                                    </div>
                                    <div class="form-radio-checks radio-buttons"
                                        [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                        <input id="noAccessFundsMultiple" type="radio" name="accessFundsMultiple" value="No"
                                            [appErrorAccess]="{ id: 'accessFundsMultipleCards-error', parentId: 'parent-accessFundsMultipleCards-error', submitted: isFormSubmit}"
                                            [(ngModel)]="accountPassword.hasBMODebitCard"
                                            (click)="accountPassword.debitCardNumber = null" #accessFundsCtrl="ngModel"
                                            required />
                                        <label class="prefix" for="noAccessFundsMultiple"
                                            attr.aria-label="{{'accountAccess.ariaLabels.accessFundsMultipleNo' | translate}}"
                                            translate="accountAccess.labels.no"></label>
                                    </div>
                                    <div id="accessFundsMultipleCards-error"
                                        *ngIf="accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors)"
                                        role="alert" class="error-message">
                                        <span translate="accountAccess.errors.multipleCardsError"></span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="accountPassword.hasBMODebitCard == 'Yes'" class="form-row">

                                <div class="flex-40 form-col">
                                    <div class="form-field">
                                        <div id="accountAccessSelectDebitCardSection" class="form-label-group">
                                            <select id="selectDebitCard" name="selectDebitCard"
                                                [(ngModel)]="accountPassword.debitCardNumber" #selectDebitCardCtrl="ngModel"
                                                [appErrorAccess]="{ id: 'selectDebitCard-error' ,submitted: isFormSubmit}"
                                                attr.aria-label="{{'accountAccess.ariaLabels.selectCard' | translate}}"
                                                required>

                                                <option *ngFor="let debitcardNumber of debitCardNumbers"
                                                    [translateParams]={cardNumber:debitcardNumber} [value]="debitcardNumber"
                                                    translate="accountAccess.labels.selectCardOption"></option>
                                            </select>
                                            <label class="slide-label" for="selectDebitCard"
                                                [ngClass]="{'error-field': selectDebitCardCtrl.errors && selectDebitCardCtrl.touched || (isFormSubmit && selectDebitCardCtrl.errors), 'active-label': selectDebitCardCtrl.value}">
                                                <span class="label-text" translate="accountAccess.labels.selectCard"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div *ngIf="selectDebitCardCtrl.errors && selectDebitCardCtrl.touched || (isFormSubmit && selectDebitCardCtrl.errors)"
                                        role="alert" class="error-message" id="selectDebitCard-error">
                                        <span translate="accountAccess.errors.selectMultipleCardsError"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="accountAccessNoDebitCardSection" *ngIf="debitCardNumbers?.length === 0 && !isJourneyUser">
                        <h1 id="ariaNewBMODebitCard" translate="accountAccess.titleNewBMODebitCard"></h1>
                        <p [innerHTML]="'accountAccess.descriptionNewBMODebitCard01' | translate | safeHtml"></p>

                        <div class="form-wrapper">
                            <div class="form-row" role="radiogroup" aria-labelledby="ariaNewBMODebitCard"
                                id='parent-newBmoDebitCard-error'>

                                <div id="accountAccessDebitCardButtonSectionFour" class="flex-elements form-col no-margin">
                                    <div class="form-radio-checks radio-buttons"
                                        [ngClass]="{'error-field': newDebitCardCtrl.errors && newDebitCardCtrl.touched || (isFormSubmit && newDebitCardCtrl.errors), 'active-label': newDebitCardCtrl.value}">
                                        <input id="yesNewDebitCard" type="radio"
                                            data-ana="Investorline:wealth:onboarding:us link:yes" name="newDebitCard"
                                            value="Yes" [(ngModel)]="accountPassword.newDebitCardYesNo"
                                            #newDebitCardCtrl="ngModel"
                                            [appErrorAccess]="{ id: 'newBmoDebitCard-error', parentId: 'parent-newBmoDebitCard-error', submitted: isFormSubmit}"
                                            required />
                                        <label class="prefix" for="yesNewDebitCard"
                                            attr.aria-label="{{'accountAccess.ariaLabels.newDebitCardYes' | translate}}"
                                            translate="accountAccess.labels.yes"></label>
                                    </div>
                                    <div class="form-radio-checks radio-buttons"
                                        [ngClass]="{'error-field': newDebitCardCtrl.errors && newDebitCardCtrl.touched || (isFormSubmit && newDebitCardCtrl.errors), 'active-label': newDebitCardCtrl.value}">
                                        <input id="noNewDebitCard" type="radio"
                                            data-ana="Investorline:wealth:onboarding:us link:no" name="newDebitCard" value="No"
                                            [(ngModel)]="accountPassword.newDebitCardYesNo" #newDebitCardCtrl="ngModel"
                                            [appErrorAccess]="{ id: 'newBmoDebitCard-error', parentId: 'parent-newBmoDebitCard-error', submitted: isFormSubmit}"
                                            required />
                                        <label class="prefix" for="noNewDebitCard"
                                            attr.aria-label="{{'accountAccess.ariaLabels.newDebitCardNo' | translate}}"
                                            translate="accountAccess.labels.no"></label>
                                    </div>
                                    <div id="newBmoDebitCard-error"
                                        *ngIf="newDebitCardCtrl.errors && newDebitCardCtrl.touched || (isFormSubmit && newDebitCardCtrl.errors)"
                                        role="alert" class="error-message">
                                        <span translate="accountAccess.errors.newDebitCardError"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section *ngIf="debitCardNumbers?.length === 0 && isJourneyUser" id="hasBMODebitCardSection">

                        <h1 id="ariaBmoClient" translate="bmoRelationship.titleBmoDebitCard"></h1>
                        <p translate="bmoRelationship.descriptionBmoDebitCard"></p>
        
                        <div class="form-wrapper">
        
                            <div role="radiogroup" aria-labelledby="ariaBmoClient" id='parent-bmoDebitCard-error' class="form-row">
                                <div class="flex-elements form-col">
                                    <div class="form-radio-checks radio-buttons"
                                         [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                        <input id="debitCardYes"
                                               type="radio" name="debitCard" value="Yes"
                                               (change)="resetDebitOptions()"
                                               [(ngModel)]="accountPassword.hasBMODebitCard" #accessFundsCtrl="ngModel"
                                               [appErrorAccess]="{ id: 'bmoDebitCard-error', parentId: 'parent-bmoDebitCard-error', submitted: isFormSubmit}"
                                               required />
                                        <label class="prefix" for="debitCardYes" attr.aria-label="{{'accountAccess.ariaLabels.accessFundsYes' | translate}}"
                                        translate="bmoRelationship.labels.yes"></label>
                                    </div>
                                    <div class="form-radio-checks radio-buttons"
                                         [ngClass]="{'error-field': accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors), 'active-label': accessFundsCtrl.value}">
                                        <input id="debitCardNo"
                                               type="radio" name="debitCard" value="No"
                                               (change)="resetDebitOptions()"
                                               [(ngModel)]="accountPassword.hasBMODebitCard" #accessFundsCtrl="ngModel"
                                               [appErrorAccess]="{ id: 'bmoDebitCard-error', parentId: 'parent-bmoDebitCard-error', submitted: isFormSubmit}"
                                               required />
                                        <label class="prefix" for="debitCardNo"
                                            attr.aria-label="{{'accountAccess.ariaLabels.accessFundsNo' | translate}}"
                                            translate="bmoRelationship.labels.no"></label>
                                    </div>
                                    <div role="alert" class="error-message" id="bmoDebitCard-error"
                                         *ngIf="accessFundsCtrl.errors && accessFundsCtrl.touched || (isFormSubmit && accessFundsCtrl.errors)">
                                        <span translate="bmoRelationship.errors.selectionRequiredNoBMODebitCard"></span>
                                    </div>
                                </div>
        
                            </div>
        
                            <!--  -->
                            <!-- Show this DIV if user selects YES (Cash) for "Do you have a BMO debit card?"-->
                            <!--  -->
                            <div *ngIf="accountPassword.hasBMODebitCard === 'Yes'">
        
                                <div class="form-row">
        
                                    <div class="alert alert-check" role="alert">
                                        <p translate="bmoRelationship.debitCardNote"></p>
                                    </div>
        
                                </div>
                                <div class="form-row">
        
                                    <div class="flex-70 form-col">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <input id="cardLastFourDigits"
                                                       name="cardLastFourDigits" [(ngModel)]="accountPassword.debitCardNumber"
                                                       #debitCardNumberCtrl="ngModel" type="tel" InputValidator="ct3" minlength="4"
                                                       [appErrorAccess]="{ id: 'debitCardNumber-error', submitted: isFormSubmit}"
                                                       maxlength="4" required />
                                                <label [ngClass]="{'error-field': debitCardNumberCtrl.errors && debitCardNumberCtrl.touched || (isFormSubmit && debitCardNumberCtrl.errors), 'active-label': debitCardNumberCtrl.value}"
                                                       class="slide-label" for="cardLastFourDigits">
                                                    <span class="label-text"
                                                          translate="bmoRelationship.labels.cardLastFourDigits"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div role="alert" class="error-message" id="debitCardNumber-error"
                                             *ngIf="debitCardNumberCtrl.errors && debitCardNumberCtrl.touched || (isFormSubmit && debitCardNumberCtrl.errors)">
                                            <span translate="bmoRelationship.errors.enterLast4Digits"></span>
                                        </div>
                                    </div>
        
                                </div>
        
                            </div>
        
                            <div *ngIf="accountPassword.hasBMODebitCard === 'No'">
        
                                <div class="form-row label-row">
        
                                    <p id="newDebitCardYesNo" translate="bmoRelationship.newDebitCardNote"></p>
        
                                </div>
                                <div role="radiogroup" aria-labelledby="newDebitCardYesNo" id='parent-bmoDebitCardLink-error' class="form-row">
        
                                    <div class="form-col">
                                        <div class="form-radio-checks radio-default">
                                            <input id="newDebitCardYes"
                                                   type="radio"
                                                   name="newDebitCard" value="Yes"
                                                    [(ngModel)]="accountPassword.newDebitCardYesNo"
                                                   [appErrorAccess]="{ id: 'bmoDebitCardLink-error', parentId: 'parent-bmoDebitCardLink-error', submitted: isFormSubmit}"
                                                   #newDebitCardCtrl="ngModel" required />
                                            <label class="prefix" for="newDebitCardYes"
                                                    attr.aria-label="{{'accountAccess.ariaLabels.newDebitCardYes' | translate}}"
                                                   translate="bmoRelationship.labels.newDebitCardYes"></label>
                                        </div>
                                        <div class="form-radio-checks radio-default">
                                            <input id="newDebitCardNo"
                                                    type="radio"
                                                   name="newDebitCard" value="No" [(ngModel)]="accountPassword.newDebitCardYesNo"
                                                   [appErrorAccess]="{ id: 'bmoDebitCardLink-error', parentId: 'parent-bmoDebitCardLink-error', submitted: isFormSubmit}"
                                                   #newDebitCardCtrl="ngModel" required />
                                            <label class="prefix" for="newDebitCardNo"
                                                    attr.aria-label="{{'accountAccess.ariaLabels.newDebitCardNo' | translate}}"
                                                   translate="bmoRelationship.labels.newDebitCardNo"></label>
                                        </div>
                                        <div role="alert" class="error-message" id="bmoDebitCardLink-error"
                                             *ngIf="newDebitCardCtrl.errors && newDebitCardCtrl.touched || (isFormSubmit && newDebitCardCtrl.errors)">
                                            <span translate="bmoRelationship.errors.selectionRequiredBMODebitCard"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        </section>
        <section section id="AccountPasswordButtonSection">
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater"
                formName="acctPwdFrm" [backRoute]="backRoute"></app-form-buttons>
        </section>

    </div>

</form>
