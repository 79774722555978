<!-- <app-flood-light></app-flood-light> -->

<form #accountDetailsForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <!-- {{ accountDetailsForm.valid }}
    {{ accountDetailsForm.value | json }}  -->
    <div class="container-noMenuView">

        <section>

            <div role="alert" class="alert alert-danger" *ngIf="isReferenceCallFailed && !accountDetailsForm.valid">
                <p *ngIf="promoServiceFail">
                    <strong translate="accountDetail.errors.criticalError"></strong><br />
                    <span translate="accountDetail.errors.criticalErrorDescription"></span>
                </p>
                <p *ngIf="!promoServiceFail && !accountDetailsForm.valid">
                    <strong translate="accountDetail.errors.selectionError"></strong><br />
                    <span translate="accountDetail.errors.selectionErrorDescription"></span>
                </p>
            </div>

            <section *ngIf="isMargin">

                <h1 translate="accountDetail.titleSectionCreditCheck"></h1>
                <p translate="accountDetail.descriptionCreditCheck01"></p>

                <div class="form-wrapper">
                    <div class="form-row">
                        <div class="form-col">

                            <div class="form-input-checks check-default" [ngClass]="{'error-field': authorizeCreditCheckCtrl.errors && isFormSubmit}">
                                <input id="creditCheck"
                                       type="checkbox"
                                       name="creditCheck"
                                       [(ngModel)]="accountDetail.creditCheck"
                                       #authorizeCreditCheckCtrl="ngModel"
                                       [required]="isMargin" />
                                <label for="creditCheck" translate="accountDetail.labels.authorizeCreditCheck"></label>
                            </div>
                            <div class="error-message" *ngIf="authorizeCreditCheckCtrl.errors && authorizeCreditCheckCtrl.touched || (isFormSubmit && authorizeCreditCheckCtrl.errors)">
                                <span translate="accountDetail.errors.selectionError"></span>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section *ngIf="(isRRSP || isSRRSP || isTfsa || isMargin) && !isSubProductAD">

                <h1 translate="accountDetail.titleSectionFeatures"></h1>

                <section *ngIf="isMargin">

                    <p id="ariaOptionTrading" [innerHTML]="'accountDetail.descriptionFeatures01' | translate | safeHtml"></p>
                    <div class="more-info-wrapper">
                        <a role="button" class="btn-icon btn-more-info-show" href="javascript://">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>
                        <div class="more-info-content">
                            <div [innerHTML]="'accountDetail.contextualHelp.shortSellingMarginAccountMoreInfo' | translate | safeHtml"></div>
                            <p>
                                <a role="button" class="btn-icon btn-more-info-close" href="javascript://">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="form-wrapper">
                        <div role="radiogroup" aria-labelledby="ariaOptionTrading" class="form-row">

                            <div class="flex-elements form-col no-margin">
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': needShortSellingCtrl.errors && needShortSellingCtrl.touched || (isFormSubmit && needShortSellingCtrl.errors), 'active-label': needShortSellingCtrl.value}">
                                    <input id="yesShortSellingMargin"
                                           type="radio"
                                           name="shortSellingMargin"
                                           value="Yes"
                                           [(ngModel)]="accountDetail.needShortSelling"
                                           #needShortSellingCtrl="ngModel"
                                           [required]="isMargin" />
                                    <label class="prefix" for="yesShortSellingMargin" translate="accountDetail.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': needShortSellingCtrl.errors && needShortSellingCtrl.touched || (isFormSubmit && needShortSellingCtrl.errors), 'active-label': needShortSellingCtrl.value}">
                                    <input id="noShortSellingMargin"
                                           type="radio"
                                           name="shortSellingMargin"
                                           value="No"
                                           [(ngModel)]="accountDetail.needShortSelling"
                                           #needShortSellingCtrl="ngModel"
                                           [required]="isMargin" />
                                    <label class="prefix" for="noShortSellingMargin" translate="accountDetail.labels.no"></label>
                                </div>
                            </div>
                            <div class="error-message" *ngIf="needShortSellingCtrl.errors && needShortSellingCtrl.touched || (isFormSubmit && needShortSellingCtrl.errors)">
                                <span translate="accountDetail.errors.selectionError"></span>
                            </div>

                        </div>
                    </div>

                </section>

                <section>

                    <p id="ariaRelationShip" [innerHTML]="'accountDetail.descriptionFeatures02' | translate | safeHtml"></p>

                    <div class="more-info-wrapper">
                        <a role="button" class="btn-icon btn-more-info-show" href="javascript://"
                        data-ana="Investorline:wealth:onboarding:options:learn more"
                        attr.aria-label="{{ 'accountDetail.ariaLabels.learnMoreOpenOptionTrading' | translate }}">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>
                        <div class="more-info-content">
                            <div [innerHTML]="'accountDetail.contextualHelp.optionTradingMoreInfo' | translate | safeHtml"></div>
                            <p>
                                <a role="button" class="btn-icon btn-more-info-close" href="javascript://" attr.aria-label="{{ 'accountDetail.ariaLabels.learnMoreCloseOptionTrading' | translate }}">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="form-wrapper">
                        <div class="form-row" role="radiogroup" aria-labelledby="ariaRelationShip" id='parent-relationShip-error'>

                            <div class="flex-elements form-col no-margin">
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors), 'active-label': relationShipCtrl.value}">
                                    <input id="yesOptionTrading"
                                           type="radio"
                                           name="optionTrading"
                                           value="Yes"
                                           [(ngModel)]="accountDetail.needOptionsTrading"
                                           #relationShipCtrl="ngModel"
                                           (change)="onNeedOptionsTradingSelected()"
                                           [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                           required
                                           data-ana="Investorline:wealth:onboarding:options:yes" />
                                    <label class="prefix" for="yesOptionTrading" translate="accountDetail.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors), 'active-label': relationShipCtrl.value}">
                                    <input id="noOptionTrading"
                                           type="radio"
                                           name="optionTrading"
                                           value="No"
                                           [(ngModel)]="accountDetail.needOptionsTrading"
                                           #relationShipCtrl="ngModel"
                                           (change)="onNeedOptionsTradingSelected()"
                                           [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                           required
                                           data-ana="Investorline:wealth:onboarding:options:no" />
                                    <label class="prefix" for="noOptionTrading" translate="accountDetail.labels.no"></label>
                                </div>
                            </div>
                            <div class="error-message" id="relationShip-error" *ngIf="relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors)">
                                <span translate="accountDetail.errors.optionTradingError"></span>
                            </div>

                        </div>
                    </div>

                </section>

                <section *ngIf="accountDetail.needOptionsTrading === 'Yes'">

                    <p [innerHTML]="'accountDetail.descriptionFeatures03' | translate | safeHtml"></p>

                    <div class="form-wrapper">
                        <div class="form-row">
                            <div class="flex-25 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="numberOfYears"
                                               type="tel"
                                               name="yearsOfExperience"
                                               [(ngModel)]="accountDetail.yearsOfExperience"
                                               #yearsOfExperienceCtrl="ngModel"
                                               InputValidator="ct3"
                                               minlength="1"
                                               maxlength="2"
                                               [appErrorAccess]="{id:'yearsOfExperience-error',  submitted: isFormSubmit }"
                                               required
                                               data-ana="Investorline:wealth:onboarding:options:years" />
                                        <label class="slide-label" for="numberOfYears" [ngClass]="{'error-field':yearsOfExperienceCtrl.invalid && yearsOfExperienceCtrl.touched  || (isFormSubmit && yearsOfExperienceCtrl.errors) ,'active-label': yearsOfExperienceCtrl.value}">
                                            <span class="label-text" translate="accountDetail.labels.numberOfYears"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="error-message" id="yearsOfExperience-error" *ngIf="yearsOfExperienceCtrl.errors && yearsOfExperienceCtrl.touched || (isFormSubmit && yearsOfExperienceCtrl.errors)">
                                    <span translate="accountDetail.errors.invalidNumberOfYears"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section *ngIf="accountDetail.needOptionsTrading === 'Yes'">

                    <p [innerHTML]="'accountDetail.descriptionFeatures04' | translate | safeHtml"></p>

                    <div class="form-wrapper">
                        <div class="form-row">
                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <select id="tradingKnowledge"
                                                name="tradingKnowledgeLevel"
                                                [(ngModel)]="accountDetail.tradingKnowledgeLevel"
                                                #tradingKnowledgeLevelCtrl="ngModel"
                                                [appErrorAccess]="{id:'tradingKnowledgeLevel-error',  submitted: isFormSubmit}"
                                                required>
                                            <option *ngFor="let type of listOfOptionsTradingKnowledge"
                                                    [value]="type.code"
                                                    [selected]="type.selected"
                                                    [attr.data-ana]="'Investorline:wealth:onboarding:options:' + type.label">
                                                {{type.label}}
                                            </option>
                                        </select>
                                        <label [ngClass]="{'error-field': tradingKnowledgeLevelCtrl.errors && tradingKnowledgeLevelCtrl.touched || (isFormSubmit && tradingKnowledgeLevelCtrl.errors), 'active-label': tradingKnowledgeLevelCtrl.value}" class="slide-label" for="tradingKnowledge">
                                            <span class="label-text" translate="accountDetail.labels.tradingKnowledge"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="error-message" id="tradingKnowledgeLevel-error" *ngIf="tradingKnowledgeLevelCtrl?.errors && tradingKnowledgeLevelCtrl?.touched || (isFormSubmit && tradingKnowledgeLevelCtrl?.errors)">
                                    <span translate="accountDetail.errors.optionTradionKnowledgeError"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section *ngIf="accountDetail.needOptionsTrading === 'Yes'">

                    <p id="ariaTradeHistory" [innerHTML]="'accountDetail.descriptionFeatures05' | translate | safeHtml"></p>

                    <div class="form-wrapper">
                        <div class="form-row" role="group" aria-labelledby="ariaTradeHistory" id="parent-typesOfOptionsTradedBefore-error">
                            <div class="form-col" ngModelGroup="typesOfOptionsTradedBefore"
                                 #typesOfOptionsTradedBeforeCtrl="ngModelGroup" checkboxValidator>

                                <div class="form-input-checks check-default" *ngFor="let i of optionsTypeExperiences"
                                     [ngClass]="{'error-field': typesOfOptionsTradedBeforeCtrl.errors && isFormSubmit}">

                                    <input [id]="'OB'+i.code"
                                           type="checkbox"
                                           [name]="i.code"
                                           [value]="i.code"
                                           [disabled]="((i.code=== '3' && disableNeverTraded) || (i.code !== '3' && !disableNeverTraded && disableNeverTraded !== null))"
                                           [(ngModel)]="i.checked"
                                           [appErrorAccess]="{ id: 'typesOfOptionsTradedBefore-error', parentId: 'parent-typesOfOptionsTradedBefore-error' ,submitted: isFormSubmit}"
                                           (change)="getOptionsOfTradedBefore()"
                                           [attr.data-ana]="'Investorline:wealth:onboarding:options:' + i.label" />
                                    <label [for]="'OB'+i.code"> {{i.label}}</label>
                                </div>

                                <div class="error-message" id="typesOfOptionsTradedBefore-error" *ngIf="typesOfOptionsTradedBeforeCtrl.errors && typesOfOptionsTradedBeforeCtrl.touched || (isFormSubmit && typesOfOptionsTradedBeforeCtrl.errors)">
                                    <span translate="accountDetail.errors.typeOptionPrevError"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section *ngIf="accountDetail.needOptionsTrading === 'Yes'">

                    <p id="ariaTradeNowOptions" [innerHTML]="'accountDetail.descriptionFeatures06' | translate | safeHtml"></p>

                    <div class="form-wrapper">
                        <div class="form-row" role="group" aria-labelledby="ariaTradeNowOptions" id="parent-optionTypes-error">
                            <div class="form-col"
                                 ngModelGroup="optionTypes"
                                 #optionTypesCtrl="ngModelGroup" checkboxValidator>

                                <div class="form-input-checks check-default" *ngFor="let i of optionTypes" [ngClass]="{'error-field': optionTypesCtrl.errors && isFormSubmit}">

                                    <input [id]="'OT'+i.code"
                                           type="checkbox"
                                           [name]="i.code"
                                           [value]="i.code"
                                           [(ngModel)]="i.checked"
                                           [appErrorAccess]="{ id: 'optionTypes-error', parentId: 'parent-optionTypes-error',submitted: isFormSubmit}"
                                           (change)="getOptionTypes()"
                                           [attr.data-ana]="'Investorline:wealth:onboarding:options:' + i.label" />
                                    <label [for]="'OT'+i.code"> {{i.label}}</label>
                                </div>

                                <div class="error-message" id="optionTypes-error" *ngIf="optionTypesCtrl.errors && optionTypesCtrl.touched || (isFormSubmit && optionTypesCtrl.errors)">
                                    <span translate="accountDetail.errors.typeOptionNextError"></span>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

            </section>

            <div class="bmo-card-wrapper promo-code" *ngIf="isRRSP || isSRRSP || isCash || isMargin || isTfsa">
                <div class="card-tile">

                    <h2 translate="accountDetail.titleGetRewarded"></h2>
                    <p translate="accountDetail.descriptionGetRewarded"></p>

                    <div class="form-wrapper no-margin">
                        <div class="form-row">

                            <div class="flex-40 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="promoCode"
                                               type="text"
                                               name="applyPromocode"
                                               [(ngModel)]="accountDetail.promocode"
                                               #promocodeCtrl="ngModel"
                                               InputValidator="ct4"
                                               [blockPaste]="true"
                                               maxlength="14"
                                               data-ana="Investorline:wealth:onboarding:enter code"
                                               [value]="promocodeCtrl.value" (input)="onPromoCodeChange($event.target.value)">
                                        <label class="slide-label content-left" for="promoCode"
                                               [ngClass]="{'active-label': promocodeCtrl.value}">
                                            <span class="label-text" translate="accountDetail.labels.promoCode"></span>
                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="isCodeApplied && !promoApplied" role="alert" class="error-message">
                                    <span translate="accountDetail.errors.invalidCode"></span>
                                </div>
                                <div *ngIf="isCodeApplied && promoApplied" role="alert" class="success-message">
                                    <span translate="accountDetail.success.validCode"></span>
                                </div>
                            </div>
                            <div class="flex-10 form-col field-button">
                                <button class="tertiary" data-ana="Investorline:wealth:onboarding:apply code" type="button" (click)="promoCodeApplied()">
                                    <span translate="accountDetail.labels.promoButton"></span>
                                </button>
                            </div>

                        </div>
                        <div class="form-row" *ngIf="isCodeApplied && promoApplied">
                            <div class="form-col">
                                <div class="form-input-checks check-default">
                                    <input id="promoTerms"
                                           type="checkbox"
                                           name="promoTerms"
                                           [(ngModel)]="accountDetail.tncChecked"
                                           #tncCtrl="ngModel"
                                           (change)="onTNCChecked($event.target.checked)" />
                                    <label for="promoTerms" [innerHTML]="('accountDetail.labels.acknowledgeTC01' | translate).replace('[TNCDOCNAME]', accountDetail.tncDocName)"></label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

<!--
            <section *ngIf = "!isSubProductAD">

                <h1 translate="accountDetail.titleSectionReferFriend"></h1>

                <div class="bmo-card-wrapper recommend-friend">
                    <div class="card-tile">

                        <p [innerHTML]="'accountDetail.descriptionReferFriend' | translate | safeHtml"></p>

                    </div>
                </div>
            </section>
        -->

        </section>

        <section>
            <app-form-buttons [disabled]="disableContinue" [backRoute]="APP_ROUTES.select_account"></app-form-buttons>
        </section>

    </div>

</form>
