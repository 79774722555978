<!-- <app-flood-light></app-flood-light> -->

<form #termsConditionsForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section id="termsConditionsSection">

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="termsConditionsForm.invalid && isFormSubmit" translateTag="termsConditions"></app-invalid-form>

            <div class="alert alert-danger" role="alert" *ngIf="tryAgainError">
                <p>
                    <strong translate="termsConditions.errors.criticalError"></strong><br>
                    <span translate="termsConditions.errors.tryAgainError"></span>
                </p>
            </div>

            <h1 translate="termsConditions.titleSectionTerms"></h1>

            <p *ngIf="!isSubProductAD && (!enableCommissionAndFees || showOldContent)" [innerHtml]="'termsConditions.descriptionSubsectionStepOne' | translate | safeHtml"></p>
            <p *ngIf="isSubProductAD && (!enableCommissionAndFees || showOldContent)" [innerHtml]="'termsConditions.ad_descriptionSubsectionStepOne' | translate | safeHtml"></p>

            <p *ngIf="!isSubProductAD && (enableCommissionAndFees && !showOldContent)" [innerHtml]="'termsConditions.descriptionSubsectionStepOne02' | translate | safeHtml"></p>
            <p *ngIf="isSubProductAD && (enableCommissionAndFees && !showOldContent)" [innerHtml]="'termsConditions.ad_descriptionSubsectionStepOne02' | translate | safeHtml"></p>

            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-col">
                        <div class="form-input-checks check-default">
                            <input id="iAcceptTermsConditions" type="checkbox" [value]="termsConditions.acceptStep1"
                                   name="iAcceptTermsConditions" [(ngModel)]="termsConditions.acceptStep1"
                                   #acceptStep1Ctrl="ngModel" required data-ana="mywealth:terms and conditions – step 1" />

                            <label *ngIf="(!enableCommissionAndFees || showOldContent)" for="iAcceptTermsConditions" translate="termsConditions.labels.acknowledge"></label>

                            <label *ngIf="!isSubProductAD && (enableCommissionAndFees && !showOldContent)" for="iAcceptTermsConditions" translate="termsConditions.labels.sd_acknowledge"></label>
                            <label *ngIf="isSubProductAD && (enableCommissionAndFees && !showOldContent)" for="iAcceptTermsConditions" translate="termsConditions.labels.ad_acknowledge"></label>
                        </div>

                    </div>
                </div>
            </div>

            <h2 translate="termsConditions.titleSubsectionStepTwo"></h2>
            <p [innerHtml]="'termsConditions.descriptionSubsectionStepTwo' | translate | safeHtml"></p>

            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-col">
                        <div class="form-input-checks check-default">
                            <input id="iAcceptTwoStep" type="checkbox" [value]="termsConditions.acceptStep2"
                                   name="iAcceptTwoStep" [(ngModel)]="termsConditions.acceptStep2"
                                   #acceptStep2Ctrl="ngModel" required data-ana="mywealth:terms and conditions – step 2" />
                            <label [innerHtml]="'termsConditions.labels.acknowledge2'| translate | safeHtml"
                                   for="iAcceptTwoStep"></label>
                        </div>
                    </div>
                </div>
            </div>

            <h2 translate="termsConditions.titleSubsectionStepThree"></h2>
            <!-- depending upon type of product - self direct / advice direct this description would change -->
            <p *ngIf="!isSubProductAD"
               [innerHtml]="'termsConditions.description1SubsectionStepThree' | translate | safeHtml"></p>
            <p *ngIf="isSubProductAD"
               [innerHtml]="'termsConditions.ad_description1SubsectionStepThree' | translate | safeHtml"></p>

               <ng-container *ngIf="isDataRationalization4Enabled == true && (isMargin||isCashAccount||isTFSAAccount)">
                <h2 class="add-margin t48px" translate="termsConditions.titleSignUSTaxForms"></h2>
                <p *ngIf="!isMaoApp" translate="termsConditions.descriptionSignUSTaxForms"></p>
                <p *ngIf="isMaoApp && !isSubProductAD" translate="termsConditions.descriptionSignUSTaxFormsMAO"></p>
            </ng-container>
            <section *ngIf="isEnvLocalOrDit">
            <div class="form-field" *ngIf="isMargin"> 
                <div class="form-label-group">
                    <select id="decisionType" name="decisionType" [(ngModel)]="decisionType" #decisionTypeCtrl="ngModel">
                        <option *ngFor="let status of ['ILREVIEW', 'ILACCEPT', 'ILDECLNE', 'ILDWNGRD']" [value]="status">
                            {{status}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-field" > 
                <div class="form-label-group">
                    AMLFlag Select
                    <select id="amlFlagSelection" name="amlFlagSelection" [(ngModel)]="amlFlagSelected" (change)="amlFlagSelect()">
                        <option *ngFor="let amlFlagSelection of [true, false]" [value]="amlFlagSelection">
                            {{amlFlagSelection}}
                        </option>
                    </select>
                </div>
            </div>
        </section>
 
        </section>

        <section id="termsConditionsButtonSection">
            <app-form-buttons [appInvalidForm]="appInvalidForm" [disabled]="termsConditionsForm.invalid || isclicked" [showFinishLater]="showFinishLater"  formName="tncFrm"
               [buttonLabel]="'button.eSign'"  [backRoute]="APP_ROUTES.review"></app-form-buttons>
        </section>

    </div>

    <div class="form-wrapper">

    </div>

</form>
