<div *ngIf="branchReferralObj.branchPersonnel == '103'" class="bmo-container-flex bmo-container-docupload">

    <div class="flex-50">

        <!--FSM-->
        <p class="font-bold no-margin" [innerHtml]="'branchReferral.labels.fsmCol'| translate | safeHtml"></p>

        <div class="form-wrapper">

            <!--FSM-Name-->
            <div class="form-row">
                <div class="flex-fill form-col">
                    <div class="form-field">
                        <div class="form-label-group">
                            <input id="fsmName" name="fsmName" type="text" minlength="2" maxlength="50"
                                InputValidator="ct22" [(ngModel)]="branchReferralObj.fsmName" #fsmNameCtrl="ngModel"
                                [appErrorAccess]="{id:'fsmName-error',  submitted: isFormSubmit}" required />
                            <label class="slide-label" for="fsmName"
                                [ngClass]="{'error-field':isError(fsmNameCtrl), 'active-label':fsmNameCtrl.value}">
                                <span class="label-text" translate="branchReferral.labels.name"></span>
                            </label>
                        </div>
                    </div>
                    <div role="alert" id="fsmName-error" class="error-message" *ngIf="isError(fsmNameCtrl)">
                        <span [innerHtml]="'branchReferral.errors.nameError'| translate | safeHtml"></span>
                    </div>
                </div>
            </div>

            <!--FSM-EIN#-->
            <div class="form-row">
                <div class="flex-fill form-col">
                    <div class="form-field">
                        <div class="form-label-group">
                            <input id="fsmEIN" name="fsmEIN" type="text" minlength="9" maxlength="9"
                                InputValidator="ct21" [(ngModel)]="branchReferralObj.EIN" #fsmEINCtrl="ngModel"
                                [appErrorAccess]="{id:'fsmEIN-error',  submitted: isFormSubmit}" required />
                            <label class="slide-label" for="fsmEIN"
                                [ngClass]="{'error-field':isError(fsmEINCtrl), 'active-label':fsmEINCtrl.value}">
                                <span class="label-text"
                                    [innerHtml]="'branchReferral.labels.ein'| translate | safeHtml"></span>
                            </label>
                        </div>
                    </div>
                    <div role="alert" id="fsmEIN-error" class="error-message" *ngIf="isError(fsmEINCtrl)">
                        <span [innerHtml]="'branchReferral.errors.einError'| translate | safeHtml"></span>
                    </div>
                </div>
            </div>

            <!--FSM-Phone-->
            <div class="form-row">
                <div class="flex-fill form-col">
                    <div class="form-field">
                        <div class="form-label-group">
                            <input id="fsmPhone" name="fsmPhone" appPhoneNumber type="tel" minlength="10" maxlength="10"
                                InputValidator="ct3" [(ngModel)]="branchReferralObj.fsmPhone" #fsmPhoneCtrl="ngModel"
                                [appErrorAccess]="{id:'fsmPhone-error',  submitted: isFormSubmit}" required />
                            <label class="slide-label" for="fsmPhone"
                                [ngClass]="{'error-field':isError(fsmPhoneCtrl), 'active-label':fsmPhoneCtrl.value}">
                                <span class="label-text" translate="branchReferral.labels.phone"></span>
                            </label>
                        </div>
                    </div>
                    <div role="alert" id="fsmPhone-error" class="error-message" *ngIf="isError(fsmPhoneCtrl)">
                        <span [innerHtml]="'branchReferral.errors.phoneNumberError'| translate | safeHtml"></span>
                    </div>
                </div>
            </div>

            <!--FSM-Transit-->
            <div class="form-row">
                <div class="flex-fill form-col">
                    <div class="form-field">
                        <div class="form-label-group">
                            <input id="fsmTransit" name="fsmTransit" type="text" minlength="4" maxlength="5"
                                InputValidator="ct21" [(ngModel)]="branchReferralObj.transit" #fsmTransitCtrl="ngModel"
                                [appErrorAccess]="{id:'fsmTransit-error',  submitted: isFormSubmit}" required />
                            <label class="slide-label" for="fsmTransit"
                                [ngClass]="{'error-field':isError(fsmTransitCtrl), 'active-label':fsmTransitCtrl.value}">
                                <span class="label-text" translate="branchReferral.labels.transit"></span>
                            </label>
                        </div>
                    </div>
                    <div role="alert" id="fsmTransit-error" class="error-message" *ngIf="isError(fsmTransitCtrl)">
                        <span [innerHtml]="'branchReferral.errors.transitError'| translate | safeHtml"></span>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="flex-50">

        <!--FP-->
        <p class="font-bold no-margin" [innerHtml]="'branchReferral.labels.fpCol'| translate | safeHtml"></p>

        <div class="form-wrapper">

            <!--FP-name-->
            <div class="form-row">
                <div class="flex-fill form-col">
                    <div class="form-field">
                        <div class="form-label-group">
                            <input id="fpName" name="fpName" type="text" minlength="2" maxlength="50"
                                InputValidator="ct22" [(ngModel)]="branchReferralObj.fpName" #fpNameCtrl="ngModel"
                                [appErrorAccess]="{id:'fpName-error',  submitted: isFormSubmit}" required />
                            <label class="slide-label" for="fpName"
                                [ngClass]="{'error-field':isError(fpNameCtrl), 'active-label':fpNameCtrl.value}">
                                <span class="label-text" translate="branchReferral.labels.name"></span>
                            </label>
                        </div>
                    </div>
                    <div role="alert" id="fpName-error" class="error-message" *ngIf="isError(fpNameCtrl)">
                        <span [innerHtml]="'branchReferral.errors.nameError'| translate | safeHtml"></span>
                    </div>
                </div>
            </div>

            <!--FP Code-->
            <div class="form-row">
                <div class="flex-fill form-col">
                    <div class="form-field">
                        <div class="form-label-group">
                            <input id="fpColCode" name="fpColCode" type="text" minlength="3" maxlength="3"
                                InputValidator="ct4" [(ngModel)]="branchReferralObj.FPCode" #fpColCodeCtrl="ngModel"
                                [appErrorAccess]="{id:'fpColCode-error',  submitted: isFormSubmit}" required />
                            <label class="slide-label" for="fpColCode"
                                [ngClass]="{'error-field':isError(fpColCodeCtrl), 'active-label':fpColCodeCtrl.value}">
                                <span class="label-text"
                                    [innerHtml]="'branchReferral.labels.fpCode'| translate | safeHtml"></span>
                            </label>
                        </div>
                    </div>
                    <div role="alert" id="fpColCode-error" class="error-message" *ngIf="isError(fpColCodeCtrl)">
                        <span [innerHtml]="'branchReferral.errors.fpCodeError'| translate | safeHtml"></span>
                    </div>
                </div>
            </div>

            <!--FP-Phone-->
            <div class="form-row">
                <div class="flex-fill form-col">
                    <div class="form-field">
                        <div class="form-label-group">
                            <input id="fpPhone" name="fpPhone" appPhoneNumber type="tel" minlength="10" maxlength="10"
                                InputValidator="ct3" [(ngModel)]="branchReferralObj.fpPhone" #fpPhoneCtrl="ngModel"
                                [appErrorAccess]="{id:'fpPhone-error',  submitted: isFormSubmit}" required />
                            <label class="slide-label" for="fpPhone"
                                [ngClass]="{'error-field':isError(fpPhoneCtrl), 'active-label':fpPhoneCtrl.value}">
                                <span class="label-text" translate="branchReferral.labels.phone"></span>
                            </label>
                        </div>
                    </div>
                    <div role="alert" id="fpPhone-error" class="error-message" *ngIf="isError(fpPhoneCtrl)">
                        <span [innerHtml]="'branchReferral.errors.phoneNumberError'| translate | safeHtml"></span>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>