<div class="form-field" [ngModelGroup]="group">
  <div class="form-label-group">
      <input id="id"
              name="phoneNumber"
              type="text"
              maxlength="14" 
              minlength="14"
              InputValidator="ct3"
              formatPipe="phone"
              [(ngModel)]="model[key]"
              #phoneNumberCtrl="ngModel"
              [value]="phoneNumberCtrl.value"
              required />
      <label class="slide-label" for="phoneNumber" [ngClass]="{'error-field': phoneNumberCtrl.errors && phoneNumberCtrl.touched || (isFormSubmit && phoneNumberCtrl.errors),
'active-label': phoneNumberCtrl.value}">
          <span class="label-text" translate="contactInformation.labels.phoneNumber"></span>
      </label>
  </div>
  <div role="alert" class="error-message" *ngIf="phoneNumberCtrl.errors && phoneNumberCtrl.touched || (isFormSubmit && phoneNumberCtrl.errors)">
    <span translate="contactInformation.errors.phoneNumber"></span>
</div>

</div>