import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { retry, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormService } from './form.service';
import { Utils } from '../../shared/utils/utils.class';
import { LoaderService } from '../../shared/service/loader.service';

@Injectable()
export class DocumentTrackerService {

    public documentPackage: any;
    constructor(private http: HttpClient,
        private configService: ConfigResolverService<any>,
        private formService: FormService,
        private loaderService: LoaderService) {
    }

    getSigingUrl(packageId: string, signerId: string): Observable<any> {
        // let param = new HttpParams();
        // param = param.append('signerId', signerId);
        // param = param.append('signerRole', 'Applicant');

        // console.log('param', param);

        const header = this.configService.getConfig().DOCUMENTTRACKER_EISIGN_API.HEADER;
        // header['X-BMO-REQUEST-ID'] =  '23796-' + Utils.getUUID();

        if (this.formService.forms.personalInformation.data) {
            header['first_name'] = this.formService.forms.personalInformation.data.firstname;
            header['last_name'] = this.formService.forms.personalInformation.data.lastname;
            header['email_address'] = this.formService.forms.personalInformation.data.emailAddress;
        }

        const httpParams = {
            signerId: signerId,
            signerRole: 'Applicant'
        };


        const httpOptions = {
            headers: new HttpHeaders(header),
            params: httpParams
        };
        const contextRoot = this.configService.getConfig().DOCUMENTTRACKER_EISIGN_API.CONTEXT_ROOT;
        const esignUri = this.configService.getConfig().DOCUMENTTRACKER_EISIGN_API.ESIGN_URI;
        const serviceUrl = contextRoot.concat(esignUri.replace('{packageid}', packageId));

        return this.http.post(serviceUrl, null, httpOptions).pipe(
            retry(3), // retry a failed request up to 3 times
        );
    }

    //creditCheckCall(appId: string): Observable<any> { //uncomment it after dit testing
     creditCheckCall(appId: string ,decisionType:any): Observable<any> {  //comment it after dit testing
        const config = this.configService.getConfig();
        const docGenUrl = config.CHANNELS_API.CONTEXT_ROOT + config.CHANNELS_API.CREDIT_CHECK_URL.replace('{appId}', appId);
        let header = config.CHANNELS_API.GENERATE_DOCUMENT_HEADER;

        if (Utils.getIsBranchReferral() === true) {
            header['branch_assisted'] = 'true';
        }
        header['workflow_id'] = appId;
        //comment below code after dit testing
         if(decisionType){
            header['decisionType'] = decisionType;
        }

        /***************************************/
        header = new HttpHeaders(header);
        return this.http.post(docGenUrl, {}, { headers: header, observe: 'response' });
    }

    generateDocuments(appId: string): Observable<any> {
        const config = this.configService.getConfig();
        const docGenUrl = config.CHANNELS_API.CONTEXT_ROOT + config.CHANNELS_API.GENERATE_DOCUMENT_URI.replace('{appId}', appId);
        let header = config.CHANNELS_API.GENERATE_DOCUMENT_HEADER;

        if (Utils.getIsBranchReferral() === true) {
            header['branch_assisted'] = 'true';
        }
        header['workflow_id'] = appId;
        header = new HttpHeaders(header);
        return this.http.post(docGenUrl, {}, { headers: header, responseType: 'text', observe: 'response' });
    }

    generateSpecificDocuments(appId: string, documentName: string): Observable<any> {
        const config = this.configService.getConfig();
        const docGenUrl =
        config.CHANNELS_API.CONTEXT_ROOT + config.CHANNELS_API.GENERATE_SPECIFIC_DOCUMENT_URI.replace('{documentName}', documentName);
        let header = config.CHANNELS_API.GENERATE_SPECIFIC_DOCUMENT_HEADER;

        if (Utils.getIsBranchReferral() === true) {
            header['branch_assisted'] = 'true';
        }
        header['workflow_id'] = appId;
        header = new HttpHeaders(header);
        return this.http.post(docGenUrl, {}, { headers: header, responseType: 'text', observe: 'response' }) || of([]);
    }


    /*retrieveDocumentPackage(appId: string) {
        const head = new HttpHeaders({
            'iv-user': 'QAIA001@pcdqa.nesbittburnsqa.ca',
            'iv-groups': 'PWOB_BIL_USERS',
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.get(this.endPoints.retrieveDocument.replace(/:workflowId/, appId), {headers: head});
    }

    printDocument(appId: string, documentId: any) {
        const head = new HttpHeaders({
            'iv-user': 'QAIA001@pcdqa.nesbittburnsqa.ca',
            'iv-groups': 'PWOB_BIL_USERS',
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post(this.endPoints.printDoc.replace(/:workflowId/, appId)
            .replace(/:documentPackageId/, documentId), {}, {headers: head, responseType: 'arraybuffer'});

    }*/

    downloadDocument(packageId: any, docmentId: any): Observable<any> {
        const config = this.configService.getConfig();
        const docOpenURL = config.CHANNELS_API.CONTEXT_ROOT +
            ((config.CHANNELS_API.OPEN_DOCUMENT_URI.replace('{packageID}', packageId).replace('{documentID}', docmentId)));


        let header = config.CHANNELS_API.OPEN_DOCUMENT_HEADER;
        header['workflow_id'] = Utils.getapplicationID();

        header = new HttpHeaders(header);
        // return this.http.get(docOpenURL, {}, { headers: header, responseType: 'arraybuffer' });
        return this.http.get(docOpenURL, { headers: header, responseType: 'arraybuffer' });

        // replace('{packageid}', packageId)
        // .replace('{documentId}', docmentId);
        // return doctGenUrl;
        // let header = config.CHANNELS_API.GENERATE_DOCUMENT_HEADER;
        // header['workflow_id'] = appId;
        // header = new HttpHeaders(header);
        // return this.http.post(docGenUrl, {}, { headers: header, responseType: 'text', observe: 'response' });
    }

    openDownloadedDocument(response) {
        const file = new Blob([response], { type: 'application/pdf' });
        const nav = (window.navigator as any);
        if (nav && nav.msSaveOrOpenBlob) { // for IE11
            nav.msSaveOrOpenBlob(file);
        } else {
            const fileURL: string = URL.createObjectURL(file);
            window.open(fileURL);
        }

    }

    openDownloadedCAADocument(response, openWindow) {
        const file = new Blob([response], { type: 'application/pdf' });
        const nav = (window.navigator as any);
        if (nav.navigator && nav.msSaveOrOpenBlob) { // for IE11
           nav.msSaveOrOpenBlob(file);
        } else {
            const fileURL: string = URL.createObjectURL(file);
            // window.open(fileURL);
            openWindow.location.href = fileURL;
        }

    }

}
