import { Component, OnInit } from '@angular/core';
import { ConfigResolverService } from '../../../config/external-config/service/config-resolver.service';
import { ApplicationService } from '../../service/application.service';

@Component({
  selector: 'app-critical-error',
  templateUrl: './critical-error.component.html'
})
export class CriticalErrorComponent implements OnInit {
  isBranchAssisted: boolean;

  constructor(private configService: ConfigResolverService<any>,
    private applicationService: ApplicationService) { }

  ngOnInit() {
    this.isBranchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
  }

  
  criticalError() {
    this.applicationService.hidePageUndloadPopUp$.next(false);
    window.location.reload();
  } 
}
