import { APP_ROUTES, ANALYTICS, LOCATION_INDEX } from './../../config/app-config.constants';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormService } from '../../shared/service/form.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Title } from '@angular/platform-browser';
import { Utils } from "../../shared/utils/utils.class";
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';

@Component({
    selector: 'app-celebration-review',
    templateUrl: './celebration-review.component.html'
})
export class CelebrationReviewComponent implements OnInit, AfterViewInit {

    private isFormSubmit: boolean;
    public firstName: String;
    private isCurrentLangEnglish: boolean;
    private isCurrentLangFrench: boolean;
    public showFinishLater;
    public url;
    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        private winRef: WindowRefService,
        private formService: FormService,
        private analytics: AnalyticsService) {
        this.isFormSubmit = false;
        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.url = '/' + APP_ROUTES.celebration_review;
    }

    ngOnInit() {

        this.titleService.setTitle(Utils.getPageTitleFinalCelebration());

        Promise.resolve(null).then(() => {
            this.formService.forms.termsConditions.isVisited = true;
            const _data = this.formService.forms.personalInformation.data;
            if (this.isCurrentLangFrench) {
                this.firstName = (_data ? (_data.preferredFirstName ? _data.preferredFirstName :
                    (_data.firstname ? _data.firstname : '')) : '') + '.'; // changes ! to . as per MWB-10296
            } else {
                this.firstName = (_data ? (_data.preferredFirstName ? _data.preferredFirstName :
                    (_data.firstname ? _data.firstname : '')) : '') + '!';
            }


        });

        // save for later
        this.showFinishLater = true;

        setTimeout(() => {
            this.setGTM();
        }, 200);

    }

    ngAfterViewInit() {
        // this.analytics.setState('step 15');
         this.analytics.setPageState(this.url);
         this.analytics.triggerView('FinalCelebration');

        // this.analytics.setPPVID(ANALYTICS.celebration_review);
        // this.analytics.setvPageTitle(Utils.getPageTitleFinalCelebration());
        // this.analytics.setOriginalLocation(Utils.getPageTitleFinalCelebration());
        // this.analytics.setvURL(Utils.getvURL());
    }

    onSubmit() {
        this.isFormSubmit = true;
        // this javascript based navigation to avoid application restart on edge browser
        if (Utils.isSubProductAD()) {
            window.location.href = window.location.href.split('#/')[0].concat('#/' + APP_ROUTES.terms_conditions + '?lang=')
                .concat(this.applicationService.getCurrentLanguage()).concat('&subProduct=').concat(Utils.getSubProduct());
        }
        else {
            window.location.href = window.location.href.split('#/')[0].concat('#/' + APP_ROUTES.terms_conditions + '?lang=')
                .concat(this.applicationService.getCurrentLanguage());
        }

        if(Utils.getMboxDisable() && Utils.getMboxEdit()){
            window.location.href = window.location.href.concat('&mboxEdit=').concat(Utils.getMboxEdit())
                                    .concat('&mboxDisable=').concat(Utils.getMboxDisable());
        }
    }

    setGTM() {

        const gtmDataLayerForCelebrationReview: GTMDataLayer =  {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleFinalCelebration(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.CELEBRATION_REVIEW,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.celebration_review,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForCelebrationReview);
        // console.log(this.winRef.nativeWindow.dataLayer);

    }

}
