<div class="skipLinks"  (focusin)="showNav = false">
    <a href="#bilBody" class="skip-to-body" translate="ariaLabels.skipLinkContent"></a>
</div>

<div class="header-container-flex">
    <div>
        <span id="logoBmoLarge" class="logo-bmo-large" [innerHTML]="'header.logoImg' | translate | safeHtml"></span>
        <span id="logoBmoSmall" class="logo-bmo-small" [innerHTML]="'header.logoImgSmall' | translate | safeHtml"></span>
    </div>
    <div class="right-menu-header" *ngIf="((!isIEBrowser && isCookieEnabled )|| isBranchAssisted) ">
        <ul id="menuHelpLogin">
            <!-- Help link -->
            <!-- --- -->
            <li *ngIf="showHelp">
                <a role="button"
                id="helpApplicationShowID"
                [attr.aria-expanded]="showNav"
                class="btn-icon"
                aria-controls="helpApplicationNavID"
                href="javascript://"
                (click)="navToggle()"
                (keypress)="showHelpMenu($event)"
                attr.aria-label="{{ 'header.labels.help' | translate }}">
                    <span class="icon icon-large info-blue"></span>
                    <span class="btn-icon-text" translate="linkButton.help"></span>
                </a>
                <app-drop-down-menu class="drop-down-menu-wrap" [attr.aria-hidden]="!showNav" [showNav]="showNav"
                    (hideNav)="showNav = false" *ngIf="showNav" tabindex="-1"></app-drop-down-menu>
            </li>
            <!-- CDB Account link -->
            <!-- --- -->
            <li *ngIf="(isCdbOlbUser || isJourneyUser ) && clientFirstName">
                <a role="button"
                id="cdbOlbAccountShowID"
                [attr.aria-expanded]="showCdbOlbNav"
                class="btn-icon"
                (click)="cdbOlbNavToggle()"
                (keypress)="openCdbOlbNav($event)"
                aria-controls="cdbOlbAccountNavID"
                href="javascript://"
                attr.aria-label="{{clientFirstName}}{{ 'header.labels.account' | translate }}">
                    <span class="icon icon-large profile-blue"></span>
                    <span class="truncate btn-icon-text">{{clientFirstName}}</span>
                </a>
                <app-drop-down-menu class="drop-down-menu-wrap" [attr.aria-hidden]="!showCdbOlbNav" [showCdbOlbNav]="showCdbOlbNav"
                (hideCdbOlbNav)="showCdbOlbNav = false" *ngIf="showCdbOlbNav" tabindex="-1" [clientFirstName]="clientFirstName"></app-drop-down-menu>
            </li>



            <!-- Resume link -->
            <!-- --- -->
            <li *ngIf="!isCdbOlbUser && !isJourneyUser && showResume && !showSignOut">
                <a id="resumeApplicationID"  role="button" class="btn-icon" href="javascript://" (click)="showResumeApplication()">
                    <span class="icon icon-large resume-blue" aria-hidden="true"></span>
                    <span class="btn-icon-text" [innerHtml]="'linkButton.resumeApp' | translate | safeHtml"></span>
                </a>
            </li>
            <!-- Language Dropdown -->
            <!-- --- -->
            <li *ngIf="langToggle">
                <a id="languageDropDown" role="button" class="btn-icon" href="javascript://"  *ngIf="applicationService.getCurrentLanguage() === 'fr' && !isBranchAssisted" (click)="changeLanguage('en')" attr.aria-label="{{ 'header.labels.language' | translate }}">
                    <span class="icon icon-large language-blue" aria-hidden="true"></span>
                    <span class="btn-icon-text" translate="linkButton.language"></span>
                </a>
                <a id="languageDropDown" role="button" class="btn-icon" href="javascript://"  *ngIf="applicationService.getCurrentLanguage() === 'en' && !isBranchAssisted" (click)="changeLanguage('fr')" attr.aria-label="{{ 'header.labels.language' | translate }}">
                    <span class="icon icon-large language-blue" aria-hidden="true"></span>
                    <span class="btn-icon-text" translate="linkButton.language"></span>
                </a>
            </li>
            <!-- Sign out application link -->
            <!-- --- -->
            <li *ngIf="showSignOut && !isCdbOlbUser && !isJourneyUser">
                <a id="signOutApplication" role="button" class="btn-icon" href="javascript://" (click)="onSignOutClick()" >
                    <span class="icon icon-large signout-blue" aria-hidden="true"></span>
                    <span class="btn-icon-text" translate="linkButton.signOut"></span>
                </a>
            </li>
        </ul>
    </div>


</div>

<div class="progress-mobile">
    <div class="progress-back"
     *ngIf="stepInfo.showProgressBar && ( (isBranchAssisted && stepInfo.currentStep != 1) || (!isBranchAssisted))">
        <a (click)="onBack()" role="button"
            class="btn-icon"
            href="javascript://"
            attr.aria-label="{{ 'button.ariaLabels.back' | translate }}">
            <span class="icon icon-large back-blue"></span>
        </a>
    </div>

    <div class="flex-col-expand">
        <div class="progress-page flex-elements">
            <div class="page-info flex-col-expand">
                <span class="page-number" *ngIf="stepInfo.showProgressBar">
                    {{stepInfo.currentStep}}
                    <span translate="header.labels.of"></span> {{stepInfo.totalSteps}}
                </span>
                <span class="page-number" *ngIf="stepInfo.showProgressBar">&nbsp;-&nbsp;</span>
                <span class="page-name">{{stepInfo.pageName | translate}}</span>
            </div>
            <div class="page-select flex-equal-fixed">
                <a id="btn-progress"
                class="btn-icon icon-large tooltip-show"
                role="button"
                attr.aria-label="{{ 'mainNav.ariaLabels.hamburgerMenu' | translate }}"
                attr.tooltip="{{ 'linkButton.navigation' | translate }}"
                href="javascript://">
                    <img class="menu-down" src="assets/img/chevron-down-blue.svg" alt="" />
                </a>
            </div>
        </div>
        <div class="progress-bar">
            <div class="progress-green-fill" [style.width.%]="stepInfo.progress" *ngIf="stepInfo.showProgressBar">
                <div class="progress-dot"></div>
            </div>
            <div class="progress-grey-fill"></div>
        </div>
    </div>
</div>
