import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'sanitize'
})


export class SanitizePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {

    }

    transform(securityContext: any, value: any): any {
        return this.sanitizer.sanitize(securityContext || SecurityContext.HTML, value);

    }
}