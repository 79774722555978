 import {
    NG_VALIDATORS,
    UntypedFormControl,
    ValidatorFn,
    Validator,
    AbstractControl,
} from '@angular/forms';
import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { Utils } from '../utils/utils.class';
import { ApplicationService } from '../service/application.service';
import { ADDRESS_REGEX } from '../../config/app-config.constants';
import { AddressAutoCompleteDirective } from '../directive/address-auto-complete.directive';

@Directive({
    selector: '[blockRRAddress][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: RRAddressValidator,
            multi: true
        }
    ]
})
export class RRAddressValidator implements Validator {
    validator: ValidatorFn;

    @Input()
    public blockRRAddress: any;

    constructor(public el: ElementRef,
        public applicationService : ApplicationService) {
        this.validator = this.rrAddressValidator();
    }

    @HostListener('keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        const currentPosition = this.el.nativeElement.selectionStart;
        const temp = this.el.nativeElement.value.slice(0, currentPosition).concat(event.key)
            .concat(this.el.nativeElement.value.slice(currentPosition));
        if (temp !== temp.replace(/\s+/g, ' ')) {
            event.preventDefault();
        }
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    rrAddressValidator(): ValidatorFn {
        if(this.applicationService.isCurrentLangEnglish()){
            var pattern = ADDRESS_REGEX.rrAddress.EN;  
        }
        else{
            var pattern = ADDRESS_REGEX.rrAddress.FR;  
        }
        return (control: AbstractControl) => {
            if (this.blockRRAddress === 'false') {
                return null;
            } else if (this.blockRRAddress === 'true') {
                if (pattern.test(control.value)) {
                    return null;
                } else {
                    return {
                        invalidAddress: true
                    };
                }
            }
        };
    }
}
