import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Jquery } from '../../utils/jquery.class';
import * as $ from 'jquery';
import { ApplicationService } from '../../service/application.service';

@Component({
  selector: 'app-contact-us-model',
  templateUrl: './contact-us-model.component.html'
})
export class ContactUsModelComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private applicationService: ApplicationService
  ) { }



  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    $('.modal-overlay').show();
    $('.contact-us-modal').show();
    Jquery.modalFocusTrap('contact-us-modal');
    Jquery.modalScrollHeight();

  }

  hideContactUsEnabled() {
    this.applicationService.showContactUsModel$.next(false)
  }


  ngOnDestroy(): void {
    $('.contact-us-modal').hide();
    $('.modal-overlay').hide();
    Jquery.modalDissableFocusTrap('contact-us-modal');
  }
}
