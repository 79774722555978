
import { Directive, Output, EventEmitter, HostBinding, HostListener, ElementRef } from '@angular/core';
import { Subject, fromEvent} from 'rxjs';
import { takeUntil,map } from 'rxjs/operators';

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {

  public actualBackGround: any;
  public actualOpacity: any;
  destroySubscribers: Subject<boolean> = new Subject<boolean>();
  dragOverEvent = fromEvent(this.elementRef.nativeElement,'dragover');
  dragLeaveEvent = fromEvent(this.elementRef.nativeElement,'dragleave');
  dropEvent = fromEvent(this.elementRef.nativeElement,'drop');
  @Output() onDocDropped = new EventEmitter<any>();

  constructor(private elementRef: ElementRef) {
  }
  

  ngOnInit() {
    this.actualBackGround = this.elementRef.nativeElement.style.backgroundColor;
    this.actualOpacity = this.elementRef.nativeElement.style.opacity;
    
    this.dragOverEvent.pipe(takeUntil(this.destroySubscribers)).subscribe(event => {
      let event1 = event as DragEvent;
      event1.preventDefault();
      event1.stopPropagation();
      this.elementRef.nativeElement.style.backgroundColor = '#9ecbec';
      this.elementRef.nativeElement.style.opacity = '0.8';
    });

    this.dragLeaveEvent.pipe(takeUntil(this.destroySubscribers)).subscribe(event => {
      let event1 = event as DragEvent;
      event1.preventDefault();
      event1.stopPropagation();
      this.elementRef.nativeElement.style.backgroundColor = this.actualBackGround;
      this.elementRef.nativeElement.style.opacity = this.actualOpacity;
    });

    this.dropEvent.pipe(takeUntil(this.destroySubscribers)).subscribe(event => {
      let event1 = event as DragEvent;
      event1.preventDefault();
      event1.stopPropagation();
      this.elementRef.nativeElement.style.backgroundColor = this.actualBackGround;
      this.elementRef.nativeElement.style.opacity = this.actualOpacity;
      let docs = event1.dataTransfer.files;
      if (docs.length > 0) {
        this.onDocDropped.emit(docs);
      }
    });
  }

  ngOnDestroy(){
    this.destroySubscribers.next(true);
    this.destroySubscribers.unsubscribe();

  }

  

}
