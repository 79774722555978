<!--showModal-->
<div *ngIf="showModal" class="bmo-modal bmo-cookie-enabled" role="dialog">
    <form>
        <a role="button"
           attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
           attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
           class="btn-icon btn-close tooltip-show"
           (click)="hideCookieEnabled()" href="javascript://">
            <span class="icon icon-large close-grey"
            id="closeCookieModel"></span>
        </a>
        <div class="modal-content no-margin">

            <section *ngIf="isCookieEnabled" class="bmo-container-flex">
                <div class="flex-80">
                    <h1 translate="modalCookieEnabled.title2Modal"></h1>
                    <p [innerHtml]="'modalCookieEnabled.section2Content' | translate | safeHtml"></p>
                </div>

                <div class="flex-20">
                    <div class="form-row form-page-buttons content-center">
                        <button class="primary element-center" translate="button.continue" (click)="hideCookieEnabled()" attr.aria-label="{{ 'button.ariaLabels.continue' | translate }}"></button>
                    </div>
                </div>
            </section>
        </div>
    </form>
</div>
<div *ngIf="showModal"class="modal-overlay-component"></div>