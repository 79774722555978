
/**
 * @purpose: Family information
 * @ created: Abhinav Jain
 * @ last updated by: Hari Kori(27 April, 2018)
 */

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { FormService } from '../../shared/service/form.service';
import * as $ from 'jquery';
import { APP_CONFIG, EMPLOYMENTSTATUS, APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, QUEBEC, MIN_DEPENDENTS, MAX_DEPENDENTS, DEPENDENTS_MAX_LENGTH,DEPENDENTS_MIN_LENGTH } from '../../config/app-config.constants';
import { EmploymentInfoService } from '../../shared/service/employment-info.service';
import { inject } from '@angular/core/testing';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { some, includes, isNil } from 'lodash';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { Jquery } from './../../shared/utils/jquery.class';



export class FamilyInformation {
    selectEmploymentStatus = null;
    employmentStatus = null;
    maritalStatus = null;
    spousalMaritalStatus = null;
    firstName = null;
    initial = '';
    lastName = null;
    jobTitle = null;
    employerName = null;
    selectOccupation = null;
    selectNatureOfBusiness = null;
    bmoGroupOption = null;
    isBMOEmployee = null;
    sinNumber: any = '';
    spouseDateOfBirth: any = '';
    hasSuccessorAnnuitant = null;
    dependents = null;
    dependentsCount = null;
    public isMarried(): boolean {
        return ((this.maritalStatus &&
            this.maritalStatus === '100002' ||
            this.maritalStatus === '100003') ||
            (this.spousalMaritalStatus &&
                this.spousalMaritalStatus === '100002' ||
                this.spousalMaritalStatus === '100003')

        ) ? true : false;
    }
}

@Component({
    selector: 'app-family-information',
    templateUrl: './family-information.component.html',
    providers: [EmploymentInfoService]
})
export class FamilyInformationComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @ViewChild('familyInformationForm', { static: true }) familyInformationForm: NgForm;

    // For accessibility purposes
    @ViewChild('selectMartialStatusCtrl', { static: true }) selectMartialStatusCtrl: UntypedFormControl;
    @ViewChild('spousalMaritalStatusCtrl', { static: true }) spousalMaritalStatusCtrl: UntypedFormControl;
    @ViewChild('firstNameCtrl', { static: true }) firstNameCtrl: UntypedFormControl;
    @ViewChild('lastNameCtrl', { static: true }) lastNameCtrl: UntypedFormControl;
    @ViewChild('dateOfBirthCtrl', { static: true }) dateOfBirthCtrl: UntypedFormControl;
    @ViewChild('appSinNumberCtrl', { static: true }) appSinNumberCtrl: UntypedFormControl;
    @ViewChild('selectEmploymentStatusCtrl', { static: true }) selectEmploymentStatusCtrl: UntypedFormControl;
    @ViewChild('selectOccupationCtrl', { static: true }) selectOccupationCtrl: UntypedFormControl;
    @ViewChild('jobTitleCtrl', { static: true }) jobTitleCtrl: UntypedFormControl;
    @ViewChild('selectNatureOfBusinessCtrl', { static: true }) selectNatureOfBusinessCtrl: UntypedFormControl;
    @ViewChild('employerNameCtrl', { static: true }) employerNameCtrl: UntypedFormControl;
    @ViewChild('bmoGroupCtrl', { static: true }) bmoGroupCtrl: UntypedFormControl;
    @ViewChild('dependentsCtrl', { static: false }) dependentsCtrl: UntypedFormControl;
    @ViewChild('dependentsCountCtrl', { static: false }) dependentsCountCtrl: UntypedFormControl;

    public dependentsOptions: any;
    public dependentsCtrlError: boolean = true;
    public dependentsSubscription: Subscription;
    public showDependentsCount: boolean = false;
    public APP_ROUTES = APP_ROUTES;
    public familyInformation: FamilyInformation = new FamilyInformation();
    private isCurrentLangEnglish: boolean;
    private isCurrentLangFrench: boolean;
    public employmentStatus: any;
    public maritalStatus: any;
    public maritalStatusValue: any = '';
    public isFormSubmit: boolean;
    public applicant: any;
    public currentOccupation: any;
    public isBmoEmployee: boolean;
    public currentNatureOfBusiness: any;
    public showBMOGroupField: boolean;
    public bmoGroupOptions: any;
    public NOTPRESENTLYEMPLOYED: string;
    public RETIRED: string;
    public isReferenceCallFailed: Boolean = false;
    public isSpousalRRSP: Boolean = false;
    public isTFSA: Boolean = false;
    public isRRIF : Boolean = false;
    public disableFinishLater;
    public showFinishLater;
    appNumber;
    public accountSetup;
    criticalError: boolean;
    public isNonQuebec = false;
    public minDependentsCount;
    public maxDependentsCount;
    public dependentMinLength;
    public dependentMaxLength;
    public url: any;
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    public isSubProductAD: boolean = false;
    public BILAssisted :Boolean = false;
    private onExitApplicationPerformSaveSubscriber;
    public isAgeUnder18:boolean = false;
    public branchSDMultiAccount: boolean = false;

    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        public formService: FormService,
        private empInfoService: EmploymentInfoService,
        private winRef: WindowRefService,
        private analytics: AnalyticsService,
        private route: ActivatedRoute,
    ) {

        this.familyInformation = new FamilyInformation();
        this.isFormSubmit = false;
        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();

        this.employmentStatus = this.empInfoService.getEmploymentStatusOptions();
        this.currentOccupation = this.empInfoService.getOccupationOptions();
        this.currentOccupation = this.currentOccupation
            .filter((item) => item.expired === undefined || item.expired === false);
        this.currentNatureOfBusiness = this.empInfoService.getNatureOfBusinessOptions();
        this.bmoGroupOptions = this.empInfoService.getBMOGroupOptions();

        this.NOTPRESENTLYEMPLOYED = EMPLOYMENTSTATUS.NOTPRESENTLYEMPLOYED;
        this.RETIRED = EMPLOYMENTSTATUS.RETIRED;
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'family') {
                    this.onFinishLater();
                }
            });
            this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
                if (value) {
                    this.onFinishLater(true);
                }
            });
        this.formService.forms.familyInformation.isVisited = true;
        // setting this component as active for data retention
        this.formService.setActiveComponent('familyInformation', this);

        // save for later
        this.showFinishLater = true;
        // this.disableFinishLater = Utils.getIsInSession() === false ? false : true;

        this.url = '/' + APP_ROUTES.family_information;

        setTimeout(() => {
            this.setGTM();
        }, 200);
    }

    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }


    ngAfterViewInit() {
        // this.analytics.setState('step 7');
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('FamilyInformation');
        this.checkFormValidityOnSignOut();

        this.dependentsSubscription = this.dependentsCtrl?.valueChanges.subscribe((res) => {

            if(res!=null){
             this.dependentsCtrlError = false;
            }

            if (res) {
                this.showDependentsCount = true;
                if(!(this.familyInformation.dependentsCount)){
                    this.familyInformation.dependentsCount = 1;
                }
            }
            else {
                this.showDependentsCount = false;
                this.familyInformation.dependentsCount = null;
            }

        });

    }

    ngOnInit() {
        this.isSubProductAD = Utils.isSubProductAD();
        this.minDependentsCount = MIN_DEPENDENTS;
        this.maxDependentsCount = MAX_DEPENDENTS;
        this.dependentMinLength = DEPENDENTS_MIN_LENGTH;
        this.dependentMaxLength = DEPENDENTS_MAX_LENGTH;
        this.dependentsOptions = [{
            code: true,
            label: "financialDetails.labels.yes"
        },{
            code: false,
            label: "financialDetails.labels.no"
        }]
        // For branch-assisted
        this.BILAssisted = Utils.getIsBranchAssisted();
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        this.isNonQuebec = this.formService.getPayload().parties[0].personal.residence.primaryAddress.province !== QUEBEC;
        this.branchSDMultiAccount = Utils.isBranchSDMultiAccountFlow();

        this.titleService.setTitle(Utils.getPageTitleFamilyInformation());

        this.applicationService.fetchReferences(this.route);
        this.isSpousalRRSP = Utils.isSpousalRRSP();
        this.isTFSA = Utils.isTFSA();
        this.isRRIF = Utils.isRIF();
        if (this.applicationService.appReferences !== undefined) {
            if (this.applicationService.appReferences.isReferenceCallFailed) {
                this.isReferenceCallFailed = true;
            } else {
                this.maritalStatus = this.applicationService.appReferences.references.maritalStatuses;
            }
        }

        if (this.formService.forms.familyInformation.data != null) {
            this.familyInformation = this.formService.getFormData('familyInformation');
            this.familyInformation.isBMOEmployee = isNil(this.familyInformation.employerName) ? false : true;
            // already visited form and now visiting back again
        } else {
            this.familyInformation = new FamilyInformation();  // coming to form for the first time
        }
        this.showBMOGroupField = this.familyInformation.bmoGroupOption ? true : false;
        this.updateEmployer(this.familyInformation.employerName);

        this.formService.setActiveForm(this.familyInformationForm);

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully setup account so unsubscribe()
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == 'clicked on ' + this.url) {
                if (this.familyInformationForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }

    updAnalytics(employmentStatus: any){
        let employmentStatusDesc;
        employmentStatusDesc =  this.employmentStatus.filter(x => x.code == employmentStatus);
        this.analytics.triggerView('SpouseEmploymentStatus' + employmentStatusDesc[0].label);
    }

    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {
        if (!this.familyInformationForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }
        this.resetComponentObjectAttr();
        if (Utils.getIsOLBUser()  && this.familyInformationForm.valid) {
            this.formService.forms.familyInformation.isCompleted = true;
        }
        this.formService.setFormData('familyInformation', this.familyInformation);

        // To prepare payload with PI , CI, EI, FI
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);

        // save current page if form is valid
        if (!this.formService.forms.familyInformation.isCompleted) {
            const emptyFamily = new FamilyInformation();
            this.formService.getPayload().setFamilyInformation(emptyFamily);

            if (Utils.isSpousalRRSP()) {
                this.formService.getPayload().setSpousalRRSP(emptyFamily);
            }
        } else {
            if (this.familyInformationForm.valid) {
                this.formService.getPayload().setFamilyInformation(this.familyInformation);

                if (Utils.isSpousalRRSP()) {
                    this.formService.getPayload().setSpousalRRSP(this.familyInformation);
                }
            }
        }

        // console.log('payload with PI CI EI FI ', this.formService.getPayload());

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });



    }

    saveOnSignOut() {
        this.resetComponentObjectAttr();
        this.formService.forms.familyInformation.isCompleted = true;
        this.formService.setFormData('familyInformation', this.familyInformation);

        // To prepare payload with PI , CI, EI, FI
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);

        // save current page if form is valid
        if (Utils.getIsBranchReferral()) {
            if (this.familyInformationForm.valid) {
                this.formService.getPayload().setFamilyInformation(this.familyInformation);

                if (Utils.isSpousalRRSP()) {
                    this.formService.getPayload().setSpousalRRSP(this.familyInformation);
                }
            }
        } else {
            if (!this.formService.forms.familyInformation.isCompleted) {
                const emptyFamily = new FamilyInformation();
                this.formService.getPayload().setFamilyInformation(emptyFamily);

                if (Utils.isSpousalRRSP()) {
                    this.formService.getPayload().setSpousalRRSP(emptyFamily);
                }
            } else {
                if (this.familyInformationForm.valid) {
                    this.formService.getPayload().setFamilyInformation(this.familyInformation);

                    if (Utils.isSpousalRRSP()) {
                        this.formService.getPayload().setSpousalRRSP(this.familyInformation);
                    }
                }
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }

    onSubmit() {

        this.isFormSubmit = true;

        // for testing
        // Utils.setIsInSession(true);

        // For accessibility purposes, set focus on the invalid element.
        if (!this.familyInformationForm.valid) {
            this.setFocus();
        }

        if (this.familyInformationForm.valid) {

            this.resetComponentObjectAttr();
            this.formService.forms.familyInformation.isCompleted = true;
            this.formService.setFormData('familyInformation', this.familyInformation);

            // To prepare payload with PI , CI, EI, FI
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');

            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(this.familyInformation);
            if (Utils.isSpousalRRSP()) {
                this.formService.getPayload().setSpousalRRSP(this.familyInformation);
            }

            if (Utils.getIsInSession() === false) {

                if (!this.formService.forms.celebrationInformation.isVisited) {
                    if (Utils.getIsBranchReferral() === false) {

                        if (Utils.getBeneficiaryPageCondition() === true) {
                            this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.beneficiary);
                        } else if(Utils.getWithdrawalPageCondition() === true){
                            this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.withdrawal);
                        }
                        else {
                            this.formService.forms.celebrationInformation.isVisited = true;
                            this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.celebration);
                        }

                        return;
                    }
                }

                if (Utils.getBeneficiaryPageCondition() === true) {
                    this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.beneficiary);
                } else if(Utils.getWithdrawalPageCondition() === true){
                    this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.withdrawal);
                } else {
                    this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.financial_details);
                }

                return;

            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {

                            if (Utils.getIsBranchReferral() === false) {
                                if (!this.formService.forms.celebrationInformation.isVisited) {

                                    if (Utils.getBeneficiaryPageCondition() === true) {
                                        this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.beneficiary);
                                    } else if(Utils.getWithdrawalPageCondition() === true){
                                        this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.withdrawal);
                                    } else {
                                        this.formService.forms.celebrationInformation.isVisited = true;
                                        this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.celebration);
                                    }

                                    return;
                                }
                            }
                            if (Utils.getBeneficiaryPageCondition() === true) {
                                this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.beneficiary);
                            } else if(Utils.getWithdrawalPageCondition() === true){
                                this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.withdrawal);
                            } else {
                                this.applicationService.saveAndcontinue(this.familyInformation, APP_ROUTES.financial_details);
                            }
                            return;

                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }

        } else {
            // console.log('form not valid');
        }
    }

    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {

        // setTimeout() method has been used since DOM needs to wait for its handlers.

        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.familyInformationForm);
           }, 250);

    }

    resetComponentObjectAttr() {

        if (this.familyInformation.employmentStatus === this.RETIRED ||
            this.familyInformation.employmentStatus === this.NOTPRESENTLYEMPLOYED) {
            this.familyInformation.selectOccupation = null;
            this.familyInformation.jobTitle = null;
            this.familyInformation.selectNatureOfBusiness = null;
            this.familyInformation.bmoGroupOption = null;
            this.familyInformation.employerName = null;
        }
        if (this.BILAssisted && (this.familyInformation.hasSuccessorAnnuitant === null || this.familyInformation.hasSuccessorAnnuitant === false)
            && this.isNonQuebec && ((this.isTFSA || this.isRRIF) && !this.isSpousalRRSP)) {
            this.familyInformation.spouseDateOfBirth = '';
            this.familyInformation.sinNumber = '';
        }

    }

    ngOnDestroy() {
        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('familyInformation', this.familyInformation);
        const withdrawal = this.formService.getFormData('withdrawal');
        if (!this.familyInformation.isMarried() && withdrawal) {
            withdrawal.isSpouseAgeUsed = null;
            withdrawal.spouseDateOfBirth = null;
            this.formService.setFormData('withdrawal', withdrawal);
        }
        this.finishLaterSubscriber.unsubscribe();
        if(this.dependentsSubscription){
            this.dependentsSubscription.unsubscribe();
        }
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }
    updateMaritalStatus(e) {
        this.maritalStatus.forEach(item => {
            if (item.code === e.srcElement.value) {
                this.maritalStatusValue = item.label;
            }
        });
        this.analytics.triggerView('MaritalStatus' + this.maritalStatusValue);
        // time out for contextual help on change
        setTimeout(() => {
            Jquery.moreInformation();
        }, 100)
    }

    updateEmployer(employerBusinessName) {

        /*const normalizedEmployer = employerBusinessName ? employerBusinessName.toLowerCase().trim() : '';
          this.isBmoEmployee = some(APP_CONFIG.EMPLOYER_NAME_LIST, (el) => includes(normalizedEmployer, el));
           if (!this.isBmoEmployee) {
               this.familyInformation.bmoGroupOption = null;
          }

          // if user enters more than one space consecutively, below line will reduce spaces to one
          if(this.familyInformation.employerName !== undefined ){
            this.familyInformation.employerName = this.familyInformation.employerName.trim().replace(/  +/g, ' ');
          }*/

        this.familyInformation.employerName = employerBusinessName;
        if (this.familyInformation.employerName !== undefined && this.familyInformation.employerName !== null) {
            const employerNameListArr: Array<string> = APP_CONFIG.EMPLOYER_NAME_LIST;
            this.familyInformation.employerName = this.familyInformation.employerName.trim().replace(/  +/g, ' ');
            this.familyInformation.isBMOEmployee = false;
            for (const employerName of employerNameListArr) {
                if (this.familyInformation.employerName.toLowerCase().indexOf(employerName.toLowerCase().trim()) > -1) {
                    this.familyInformation.isBMOEmployee = true;
                    break;
                }
            }
            if (!this.familyInformation.isBMOEmployee) {
                this.familyInformation.bmoGroupOption = null;
            }
        }
    }

    setGTM() {

        const gtmDataLayerForAccountDetails: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleFamilyInformation(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.FAMILY_INFO,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.family_information,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);
        // console.log('family', this.winRef.nativeWindow.dataLayer);

    }

    showDependentSection(res: any) { //added to address IE mode for Branch customer connect
        if (res != null) {
            this.dependentsCtrlError = false;
        }

        if (res) {
            this.showDependentsCount = true;
            if (!(this.familyInformation.dependentsCount)) {
                this.familyInformation.dependentsCount = 1;
            }
        }
        else {
            this.showDependentsCount = false;
            this.familyInformation.dependentsCount = null;
        }
    }
    incrementDependents() {
        if (this.familyInformation.dependentsCount < MAX_DEPENDENTS) {
            this.familyInformation.dependentsCount += 1;
        }
    }

    decrementDependents() {
        if (this.familyInformation.dependentsCount > MIN_DEPENDENTS) {
            this.familyInformation.dependentsCount -= 1;
        }
        else if(this.familyInformation.dependentsCount === MIN_DEPENDENTS){
          this.familyInformation.dependents = false;
        }
    }

    onIncrementModelChange(event) {
        this.familyInformation.dependentsCount = event;
      if (!this.familyInformation.dependentsCount) {
        this.familyInformation.dependents = false;
      }
    }

    isUnder18Age(value:any) {
        this.isAgeUnder18 = value?.invalidAgeUnderEighteen || false;

      }
}

