import { Component, OnInit, Input, ElementRef, ViewChild, Renderer2 } from '@angular/core';

/**
 * Created by Dilraj
 * Sibling Component Communication by passing a reference.
 */

@Component({
  selector: 'app-invalid-form',
  templateUrl: './invalid-form.component.html'
})
export class InvalidFormComponent implements OnInit {

  private focusContent: ElementRef;
  private reSubmit: boolean;
  private visitedOnceAlready: Boolean = true;

  @Input() isFormInvalid: Boolean;
  @Input() translateTag: String;
  @Input() hideIncaseOfInvalidTotal?: Boolean;

  // when the view is initialized then it will set the focus the first time
  @ViewChild('focusContent', {static: true}) set content(focusContent: ElementRef) {
    this.focusContent = focusContent;

    // only focused upon after hitting continue the first time on an invalid form
    if (this.focusContent && this.focusContent.nativeElement && this.visitedOnceAlready) {
      this.focusContent.nativeElement.focus();
      this.visitedOnceAlready = false;
    }
  }
  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    // default to personalInformation if nothing specified
    this.translateTag = (this.translateTag) ? this.translateTag : 'personalInformation';

    if (!this.hideIncaseOfInvalidTotal) {
      this.hideIncaseOfInvalidTotal = false;
    }
  }

  get getIsFormInvalid() {
    return this.isFormInvalid;
  }

  // be sure to pass a template reference in to whatever component that may use it
  // currently it's being used in form-buttons.component.ts
  focusAgain() {
    if (this.focusContent && this.focusContent.nativeElement) {
      this.focusContent.nativeElement.focus();
    }
  }
}
