import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Directive({
    selector: '[appCodeToLabel][model][map]',
    providers: [TranslatePipe]
})
export class CodeToLabelDirective implements OnInit {

    @Input() model: any;
    @Input() map: any;

    constructor(public element: ElementRef, public render: Renderer2, public translate:TranslatePipe) { }


    ngOnInit() {
        let codeMap;

        try {
            codeMap = this.map;
        } catch (e) {
            codeMap = [];
        }

        let getLabel = (code) => {
            let label = codeMap.filter((val) => { return (val.code || '').toString() === (code || '').toString(); });

            return label.length ? this.translate.transform(label[0].label.toString()) : "";
        };

        this.render.setProperty(this.element.nativeElement, 'innerHTML', getLabel(this.model));
    }
}
