import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { WindowRefService } from "../../shared/service/window-ref.service";
import { APP_ROUTES } from "../../config/app-config.constants";
import { ConfigResolverService } from "../../config/external-config/service/config-resolver.service";
import { ApplicationService } from "../../shared/service/application.service";
import { Utils } from "../../shared/utils/utils.class";
import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit {
    public isSubProductAD: string;
    isBranchAssisted: string;
    isEnglishLang: string;
    pageName: string;
    isIEBrowser: boolean;
    isBranchAssistedFlow: boolean;
    public isCookieEnabled: boolean;
    footer: any;
    isCIROEnabled: boolean;

    constructor(
        public applicationService: ApplicationService,
        private configService: ConfigResolverService<any>,
        @Inject(DOCUMENT) private document: Document,
        private translateService: TranslateService,
        private windowref: WindowRefService,
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.isCookieEnabled = navigator.cookieEnabled;
        this.route.queryParams.subscribe((ele) => {
            this.isEnglishLang = ele.lang
                ? ele.lang === "en"
                    ? "EN"
                    : "FR"
                : Utils.getLanguage() === "en"
                ? "EN"
                : "FR";
        });

        this.footer = Utils.getCmsMessageObject()?.footer;

        this.translateService.onLangChange.subscribe((res) => {
            if (res != undefined && res != null) {
                this.isEnglishLang = res.lang === "en" ? "EN" : "FR";
            }

            this.applicationService.getCMSMessage(true).subscribe(resp => {
                this.footer = resp?.footer;
                Utils.setCmsMessageObject(resp);
            })
        });

        this.applicationService.setSubproduct$.subscribe((res) => {
            if (res != "" && res != undefined && res != null) {
                this.isSubProductAD = res === "AD" ? "AD" : "SD";
            }
        });

        this.isIEBrowser = this.windowref.isIEBrowser();
        this.isBranchAssistedFlow = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;

        this.TextChangeOnRouteChange();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.TextChangeOnRouteChange(event.url);
            }
        });

        this.isCIROEnabled = Utils.isCIROEnabled();
    }

    TextChangeOnRouteChange(url?: string) {
        this.isSubProductAD = Utils.getSubProduct() === "AD" ? "AD" : "SD";
        this.isBranchAssisted =
            this.configService.getConfig().BRANCH_ASSISTED === "true"
                ? "BA"
                : "SS";
        let routePath = url
            ? url.split("/")[1].split("?")[0]
            : window.location.href.split("#/").length === 2
            ? window.location.href.split("#/")[1].split("?")[0]
            : "";

        this.applicationService.applicationPageName = routePath;
        if(this.isEnglishLang!==Utils.getLanguage().toUpperCase()){
            this.isEnglishLang = Utils.getLanguage().toUpperCase();
        }
        switch (routePath) {
            case APP_ROUTES.set_expectation:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "TK";
                break;
            case APP_ROUTES.select_account:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "AS";
                break;
            case APP_ROUTES.personal_information:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "PI";
                break;
            case APP_ROUTES.contact_information:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "CI";
                break;
            case APP_ROUTES.employment_information:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "ED";
                break;
            case APP_ROUTES.family_information:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "FI";
                break;
            // case APP_ROUTES.celebration:   //  MWB-13833
            //     this.pageName =
            //         this.isBranchAssisted +
            //         "-" +
            //         this.isSubProductAD +
            //         "-" +
            //         this.isEnglishLang +
            //         "-" +
            //         "HN";
            //     break;
            case APP_ROUTES.financial_details:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "FD";
                break;
            case APP_ROUTES.regulatory_disclosures:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "RD";
                break;
            case APP_ROUTES.consents:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "C";
                break;
            case APP_ROUTES.bmo_relationship:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "BR";
                break;
            case APP_ROUTES.account_password:
            case APP_ROUTES.account_access:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "AP";
                break;
            case APP_ROUTES.celebration_review:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "FN";
                break;
            case APP_ROUTES.review:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "FR";
                break;
            case APP_ROUTES.terms_conditions:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "TC";
                break;
            case APP_ROUTES.next_steps:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "NS-AP";
                break;
            case APP_ROUTES.next_bybranch_pdf:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "BR";
                break;
            case APP_ROUTES.next_bymail_pdf:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "ML";
                break;
            case APP_ROUTES.document_upload:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "DU";
                break;
            case APP_ROUTES.account_detail:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "AF";
                break;
            case APP_ROUTES.application_approval_declined:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "NS-AP";
                break;
            case APP_ROUTES.application_approval_downgrade:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "DG";
                break;
            case APP_ROUTES.investment_knowledge:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "IK";
                break;
            case APP_ROUTES.riskAndReturn:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "IO";
                break;
            case APP_ROUTES.investor_profile:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "IP";
                break;
            case APP_ROUTES.branch_referral:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "BR";
                break;
            case APP_ROUTES.branch_esignature:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "TS";
                break;
            case APP_ROUTES.branch_save_progress:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "FL";
                break;
            case APP_ROUTES.technical_error:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "TE";
                break;
            case APP_ROUTES.technical_error_branchurl:
                    this.pageName =
                        this.isBranchAssisted +
                        "-" +
                        this.isSubProductAD +
                        "-" +
                        this.isEnglishLang +
                        "-" +
                        "TE_C/P";
                break;
            case APP_ROUTES.technical_error_session:
                    this.pageName =
                        this.isBranchAssisted +
                        "-" +
                        this.isSubProductAD +
                        "-" +
                        this.isEnglishLang +
                        "-" +
                        "TE_MT";
                break;
            case APP_ROUTES.beneficiary:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "DB";
                break;
            case APP_ROUTES.withdrawal:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "WD";
                break;
            case APP_ROUTES.olb_inprogress:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "RA";
                break;
            default:
                this.pageName =
                    this.isBranchAssisted +
                    "-" +
                    this.isSubProductAD +
                    "-" +
                    this.isEnglishLang +
                    "-" +
                    "";
                break;
        }
        this.cd.detectChanges();
    }
}
