import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../service/application.service';
import { AccountTypeService } from '../service/account-type.service';
import { Utils } from '../utils/utils.class';
import { AccountsResolver } from './accounts.resolver';
import { SessionResolver } from './session.resolver';
import { APP_ROUTES } from './../../config/app-config.constants';
import { ReferenceResolver } from './reference.resolver';
import { PrefillService } from '../../shared/service/prefill.service';
import { ConfigResolverService } from './../../config/external-config/service/config-resolver.service';

@Injectable()
export class MasterResolver implements Resolve<any> {


    constructor(private applicationService: ApplicationService,
        private route: ActivatedRoute,
        protected sessionResolver: SessionResolver,
        private prefillService: PrefillService,
        protected accountsResolver: AccountsResolver,
        protected refrenceResolver: ReferenceResolver,
        private configService: ConfigResolverService<any>) { }
    /*
    The Master resolver is ressolved at Bank referal page and Selact acccont page
    If the Session is already created , This resolver will not resolve session  ressolver
    If the Route is Select account page - This will  resolve  accounts Resolver
    */


    async resolve(route: ActivatedRouteSnapshot) {
        // console.log('Utils.isSessionInitiated', Utils.isSessionInitiated);
        try {
            var inSession = window.sessionStorage.getItem('InSession');
        }
        catch (e) {
            inSession = 'false';
        }
        console.log(inSession);
        console.log(Utils.getIsOLBUser() , Utils.getIsNonPilotUser());
        //delay setup call for 500 ms to accomodate multiple tab communication
        //so that setup call can be restricted for technical error session
        if(Utils.getIsOLBUser()) {
            if(!Utils.getMockOLBUser()) {
                await this.refrenceResolver.resolve(route);
            }
            setTimeout(async () => {
                if (Utils.isSessionInitiated === false && inSession !== 'true') {  // if session is not created  already
                   if ( route.url[0].path === APP_ROUTES.olb_inprogress ) { 
                        //await this.refrenceResolver.resolve(route); // Returns refrence
                        window.sessionStorage.setItem('InSession', 'true');
                     }
                }
                
            },750);
        } else if(this.configService.getConfig().BRANCH_ASSISTED !== "true"){
            if (Utils.getIsNonPilotUser() && Utils.isSessionInitiated === false && inSession !== 'true' && 
                                                         route.url[0].path === APP_ROUTES.select_account) { 
                    await this.refrenceResolver.resolve(route); // Returns refrence
            }
            setTimeout(async () => {
                if (Utils.isSessionInitiated === false && inSession !== 'true') {  // if session is not created  already
                  
                    await this.sessionResolver.resolve(route);
                 
                    if (!Utils.getIsNonPilotUser() && (route.url[0].path === APP_ROUTES.branch_referral || (route.url[0].path === APP_ROUTES.select_account ))) { 
                        await this.refrenceResolver.resolve(route); // Returns refrence
        
                        // console.log('isbranch assisteed value in master resolver--', Utils.getIsBranchAssisted());
        
                    }
        
                }
                
            },750);
   
        }else {
            if (Utils.isSessionInitiated === false && inSession !== 'true') {  // if session is not created  already
                await this.sessionResolver.resolve(route);
    
                if (route.url[0].path === APP_ROUTES.branch_referral|| (route.url[0].path === APP_ROUTES.select_account )) { 
                    await this.refrenceResolver.resolve(route); // Returns refrence
    
                    // console.log('isbranch assisteed value in master resolver--', Utils.getIsBranchAssisted());
    
                }
    
            }
            
        }
        return (route.url[0].path === APP_ROUTES.select_account) ? // if route is  select_account call product eligliblity
                await this.accountsResolver.resolve(route) : null;

    }
}
