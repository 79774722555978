import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BACKSPACE, TAB, ENTER, SHIFT, END, HOME, LEFT_ARROW, RIGHT_ARROW, DELETE, CTRL, SPACE } from './../../config/app-config.constants';
import { Utils } from '../utils/utils.class';

@Directive({
    selector: '[appPhoneNumber]'
})
export class PhoneNumberDirective implements OnInit {

    constructor(private el: ElementRef) { }

    ngOnInit() {
        Promise.resolve(null).then(() => {
          this.onblur();
        });
      }

    @HostListener('blur', ['$event'])
    onblur(event?: MouseEvent) {


        let value = this.el.nativeElement.value;
        let numbers;
        let char;

        if (this.el.nativeElement.value) {
        numbers = value.replace(/ +/g, '').replace('(', '').replace(')', '').replace('-', '');
        char = {
            0: '(',
            3: ') ',
            6: '-'
        };
        value = '';
        for (let i = 0; i < numbers.length; i++) {
            value += (char[i] || '') + numbers[i];
        }

        this.el.nativeElement.value = value;
    }
    }

    @HostListener('focus', ['$event'])
    onfocus(event: MouseEvent) {


        this.el.nativeElement.value = this.el.nativeElement.value.replace(/ +/g, '').replace('(', '').replace(')', '').replace('-', '');
    }


    @HostListener('paste', ['$event'])
    blockPaste(event: KeyboardEvent) {
        event.preventDefault();
    }

    @HostListener('mousedown', ['$event'])
    onmspointerdown(event: MouseEvent) {
        if (event.button === 2) {
            event.preventDefault();
        }
    }
}
