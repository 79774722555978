import { Component, OnInit , ViewChild } from '@angular/core';
import { Utils } from "../../shared/utils/utils.class";
import { ApplicationService } from "../../shared/service/application.service";
import { FormService } from "../../shared/service/form.service";
import { APP_ROUTES , productTypeAD } from "../../config/app-config.constants";
import { CdbOlbService } from '../../shared/service/cdb-olb.service';
import { NgForm, FormControl } from '@angular/forms';
import {Router} from "@angular/router";
@Component({
  selector: 'app-cdb-olb',
  templateUrl: './cdb-olb.component.html',
  styleUrls: ['./cdb-olb.component.css']
})
export class CdbOlbComponent implements OnInit {
  @ViewChild('cdbOlbForm', {static: true}) cdbOlbForm: NgForm;
  OLBUserECIFId: any;
  cardNumber : any;
  isFormSubmit = false;
  constructor(private router: Router , 
    private formService: FormService,
    private applicationService: ApplicationService , 
    private cdbOlbService: CdbOlbService) { }

  ngOnInit(): void {
  }
  onCancel() {
    this.gotoSelectAccount();
  }
  onSubmit() {
    
    this.isFormSubmit = true;
    if (this.cdbOlbForm.valid) {
    Utils.setIsOLBUser(this.OLBUserECIFId ? true : false);
    Utils.setMockOLBUser(true);
    Utils.setOLBUserEcifId(this.OLBUserECIFId);
    Utils.setOLBUserCardNumber(this.cardNumber);
    const queryParams = {
      lang: Utils.getLanguage()
    };
    let subProduct = Utils.getSubProduct();
    if (subProduct === productTypeAD) {
      (queryParams as any).subProduct = productTypeAD;
    }
    this.router.navigate(['/' + APP_ROUTES.olb_inprogress], {
      queryParams
    });
    }
  }
  gotoSelectAccount() {
    this.formService.forms.setExpectation.isCompleted = true;
    this.formService.forms.setExpectation.isVisited = true;
    Utils.setIsSelectAccountClicked(true);
    this.applicationService.saveAndcontinue(Utils.getExpectationFormData(), APP_ROUTES.select_account);
  }
}
