<div role="dialog" class="bmo-modal bmo-trap bmo-investment-profiles">

    <a role="button"
       href="javascript://"
       attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
       attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
       class="btn-icon btn-close tooltip-show"
       (click)="closeBtn()"
       id="investor-profile-close">
        <span class="icon icon-large close-grey"></span>
    </a>
    <div class="modal-content-scroll no-margin content-left">

        <section>

            <h1 translate="investorProfile.labels.investorProfile"></h1>

            <h2 translate="investorProfile.labels.income"></h2>
            <p translate="investorProfile.labels.incomeModalDescription01"></p>
            <p translate="investorProfile.labels.incomeModalDescription02"></p>
            <p translate="investorProfile.labels.incomeModalDescription03"></p>
            <p translate="investorProfile.labels.incomeModalDescription04"></p>

            <h2 translate="investorProfile.labels.balanced"></h2>
            <p translate="investorProfile.labels.balancedModalDescription01"></p>
            <p translate="investorProfile.labels.balancedModalDescription02"></p>
            <p translate="investorProfile.labels.balancedModalDescription03"></p>
            <p translate="investorProfile.labels.balancedModalDescription04"></p>

            <h2 translate="investorProfile.labels.growth"></h2>
            <p translate="investorProfile.labels.growthModalDescription01"></p>
            <p translate="investorProfile.labels.growthModalDescription02"></p>
            <p translate="investorProfile.labels.growthModalDescription03"></p>
            <p translate="investorProfile.labels.growthModalDescription04"></p>

            <h2 translate="investorProfile.labels.aggressiveGrowth"></h2>
            <p translate="investorProfile.labels.aggressiveGrowthModalDescription01"></p>
            <p translate="investorProfile.labels.aggressiveGrowthModalDescription02"></p>
            <p translate="investorProfile.labels.aggressiveGrowthModalDescription03"></p>
            <p translate="investorProfile.labels.aggressiveGrowthModalDescription04"></p>

            <div class="form-row form-page-buttons content-center">
                <button class="primary element-center" type="button" translate="button.close" (click)="closeBtn()"></button>
            </div>

        </section>

    </div>

</div>

<div class="modal-overlay-component"></div>
