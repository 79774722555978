import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { SelectedCountry } from './../../sections/personal-information/personal-information.component';
import { Utils } from '../../shared/utils/utils.class';

@Injectable({
  providedIn: 'root'
})

export class CheckForPartialSaveService {

  public primaryApplicant: any;
  public primaryAccount: any;

  /**Set Primary Account */
  setPrimaryApplicant(applicationObject) {
    this.primaryApplicant = applicationObject.parties[0];

    return this.primaryApplicant;
  }

  /**Set Primary Account */
  setPrimaryAccount(applicationObject) {
    this.primaryAccount = applicationObject.accounts[0];

    return this.primaryAccount;
  }

  constructor() {
  }


  /**CHECK WHETHER BMO RELATIONSHIP IS COMPLETE OR NOT */
  checkForBMORelationshipComplete() {

    /**BMO Account Number */
    if (Utils.isRRSP() || Utils.isTFSA() || Utils.isSpousalRRSP()) {

      /**Applicant Existing Client */
      if (!Utils.nullCheck(this.primaryApplicant.bmoRelationship.isApplicantExistingClient)) {
        return false;
      } else {
        if (this.primaryApplicant.bmoRelationship.isApplicantExistingClient) { // isApplicantExistingClient = True
          /**BMO Product */
          if (!Utils.nullCheck(this.primaryApplicant.bmoRelationship.bmoProduct)) {
            return false;
          }

          /**BMO Account Number */
          if (!Utils.nullCheck(this.primaryApplicant.bmoRelationship.bmoAccountNumber)) {
            return false;
          }

          /**BMO Debit Card */
          if (!Utils.nullCheck(this.primaryApplicant.bmoRelationship.hasBMODebitCard)) {
            return false;

          } else { // IF BMO DEBIT CARD value is not null
            if (this.primaryApplicant.bmoRelationship.hasBMODebitCard) {// TRUE
              if (!Utils.nullCheck(this.primaryApplicant.bmoRelationship.bmoAccountNumber)) {
                return false;
              }
            } else { // FALSE
              if (!Utils.nullCheck(this.primaryApplicant.bmoRelationship.sendNewBMODebitCard)) {
                return false;
              }
            }

          }

        } else { // isApplicantExistingClient = False
          return true;
        }
      }

      /**CASH ACCOUNT-- ACCESS US */
      if (Utils.isCash()) {

        /**Applicant Existing Client */
        if (!Utils.nullCheck(this.primaryApplicant.bmoRelationship.isApplicantExistingClient)) {
          return false;
        } else {
          if (this.primaryApplicant.bmoRelationship.isApplicantExistingClient) { // isApplicantExistingClient = True)
            if (!Utils.nullCheck(this.primaryAccount.usDollarAccountLinkFlag)) {
              return false;
            }
          } else { // isApplicantExistingClient = False
            return true;
          }

        }

      }
    }

    return true;
  }

}
