<div *ngIf="enablePostTransSurvey || enableAbandomentSurvey" role="dialog" class="bmo-modal bmo-feedback" id="qualtricsDialog">
    <a role="button" attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
        attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}" class="btn-icon btn-close tooltip-show" href="javascript://"
        (click)="dismissSurvey()">
        <span class="icon icon-large close-grey"></span>
    </a>
    <!-- post Transaction survey -->
    <span *ngIf="enablePostTransSurvey && queryParamsAnalytics" [innerHTML]="'qualtrics.postTransactionSurvey' | translate:queryParamsAnalytics | safeHtml"></span>
    <!-- <iframe src="https://feedback.bmo.com/jfe/form/SV_87bVPS2UEI25j5s" style="width: 100%; height: 500px"></iframe> -->
    <!-- Site Abandonment/Exit Intent -->
    <span *ngIf="enableAbandomentSurvey && queryParamsAnalytics" [innerHTML]="'qualtrics.abandonmentSurvey' | translate:queryParamsAnalytics | safeHtml"></span>
    <!-- <iframe src="https://feedback.bmo.com/jfe/form/SV_9Qy9SsPszlKZEyy" class="bmo-survey" frameBorder="0"></iframe> -->
</div>

<div *ngIf="enablePostTransSurvey || enableAbandomentSurvey" class="modal-overlay-component"></div>

