<div class="flex-equal-fixed">
    <a role="button"
       attr.aria-label="{{ (showDaysCalendar ? 'ariaLabels.datePickerNavBackMonth' : 'ariaLabels.datePickerNavBackYear') | translate }}"
       attr.tooltip="{{ 'linkButton.back' | translate }}"
       class="btn-icon icon-large tooltip-show"
       href="javascript://"
       (click)="goBack()">
        <img src="assets/img/back-blue.svg" alt="" />
    </a>
</div>
