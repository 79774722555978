import { Component, OnInit } from '@angular/core';
import { Jquery } from '../../shared/utils/jquery.class';

@Component({
  selector: 'app-no-footer',
  templateUrl: './no-footer.component.html'
})
export class NoFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  routeChanges(){
    Jquery.run();
  }

}
