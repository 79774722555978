import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from './components/date-picker/date-picker.module';
import { HeadingComponent } from './components/heading/heading.component';
import { FormButtonsComponent } from './components/form-buttons/form-buttons.component';
import { SinNumberPipe } from './pipes/sin-number.pipe';
import { DatePipe } from './pipes/date.pipe';
import { MinLengthValidator } from './validators/min-length.validator';
import { SinValidator } from './validators/sin.validator';
import { SpousalSinValidator } from './validators/spousal-sin.validator';
import { PostalCodeValidator } from './validators/postal-code.validator';
import { POBoxValidator } from './validators/POBox.validator';
import { ZipCodeValidator } from './validators/zip-code.validator';
import { EmailValidator } from './validators/email.validator';
import { UnitValidator } from './validators/unit.validator';
import { ContextualHelpComponent } from './components/contextual-help/contextual-help.component';
import { CheckboxValidator } from './validators/checkbox.validator';
import { GraphValueValidator } from './validators/graph-value.validator';
import { AddressAutoCompleteDirective } from './directive/address-auto-complete.directive';
import {CodeToLabelDirective } from './directive/code-to-label.directive'
import { CurrencyDirective } from './directive/currency.directive';
import { ContextualSearchDirective } from './directive/contextual-search.directive';
import { PasswordValidator } from './validators/password.validator';
import { AccSetupPasswordValidator } from './validators/accSetpassword.validator';
import { AccountSetupPasswordValidator } from './validators/app-pwd.validator';
import { AddressValidator } from './validators/address-max-length.validator';
import { ConfirmEqualValidatorDirective } from './directive/confirm-equal-validator.directive';
import { ConfirmationYesNoComponent } from './components/confirmation-yes-no/confirmation-yes-no.component';
import { FloodLightComponent } from './components/flood-light/flood-light.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SinNumberComponent } from './components/sin/sin.component';
import { InputValidatorDirective } from './directive/input-validator.directive';
import { NetWorthComponent } from './components/net-worth/net-worth.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { SocialSecurityNumberPipe } from './pipes/social-security-number.pipe';
import { EmailAddressComponent } from './components/email-address/email-address.component';
import { PhoneNumberDirective } from './directive/phone-number.directive';
import { SINDirective } from './directive/sin.directive';
import { SSNDirective } from './directive/ssn.directive';
import { ResumeApplicationComponent } from './components/resume-application/resume-application.component';
import { AccountSetupComponent } from './components/account-setup/account-setup.component';
import { LoginSuccessPopupComponent } from './components/login-success-popup/login-success-popup.component';
import { CriticalErrorComponent } from './components/critical-error/critical-error.component';
import { AccountNameComponent } from './components/account-name/account-name.component';
import { RadioCardComponent } from './components/radio-card/radio-card.component';
import { CheckboxCardComponent } from './components/checkbox-card/checkbox-card.component';
import { GraphComponent } from './components/graph/graph.component';
import { FormatInputPercentageDirective } from './directive/format-input-percentage.directive';
import { InvestorProfileOverlayComponent } from './components/investor-profile-overlay/investor-profile-overlay.component';
import { FocusTrapModalDirective } from './directive/focus-trap-modal.directive';
import { PersonalBankerOrFinancialPlannerComponent } from './components/personal-banker-or-financial-planner/personal-banker-or-financial-planner.component';
import { PersonalBankerAndFinancialPlannerComponent } from './components/personal-banker-and-financial-planner/personal-banker-and-financial-planner.component';
import { ErrorAccessDirective } from './directive/error-access.directive';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { CookieModalComponent } from './components/cookie-modal/cookie-modal.component';
import { DropDownMenuComponent } from './components/drop-down-menu/drop-down-menu.component';
import { HowADWorksModelComponent } from './components/how-adworks-model/how-adworks-model.component';
import { ContactUsModelComponent } from './components/contact-us-model/contact-us-model.component';
import { OtherAccountsModelComponent } from './components/other-accounts-model/other-accounts-model.component';
import { PcdInputValidatorDirective } from './validators/pcd-input-number.validator';
import {NumberIncrementComponent} from './components/number-increment/number-increment.component'
import { DragDropDirective } from './directive/drag-drop.directive';
import { RRAddressValidator } from './validators/blockRRAddress.validator';
import { QualtricsComponent } from './components/qualtrics/qualtrics.component';
import { QualtricsDelayMonitorService } from './service/qualtrics-delay-monitor.service';
import { InProgressOlbComponent } from './components/in-progress-olb/in-progress-olb.component';
import { BindingCurrencyDirective } from './directive/binding-currency.directive';
import { CopyClipboardDirective } from './directive/copy-clipboard.directive';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        FormsModule
    ],
    providers: [QualtricsDelayMonitorService],
    declarations: [
        MinLengthValidator,
        HeadingComponent,
        FormButtonsComponent,
        SinNumberComponent,
        SinNumberPipe,
        SinValidator,
        SpousalSinValidator,
        PostalCodeValidator,
        POBoxValidator,
        ZipCodeValidator,
        EmailValidator,
        UnitValidator,
        ContextualHelpComponent,
        CheckboxValidator,
        GraphValueValidator,
        CurrencyDirective,
        AddressAutoCompleteDirective,
        ContextualSearchDirective,
        InputValidatorDirective,
        PasswordValidator,
        AccSetupPasswordValidator,
        AccountSetupPasswordValidator,
        AddressValidator,
        ConfirmEqualValidatorDirective,
        ConfirmationYesNoComponent,
        FloodLightComponent,
        SafeHtmlPipe,
        SanitizePipe,
        NetWorthComponent,
        PhoneNumberComponent,
        SocialSecurityNumberPipe,
        EmailAddressComponent,
        PhoneNumberDirective,
        SINDirective,
        SSNDirective,
        ResumeApplicationComponent,
        AccountSetupComponent,
        LoginSuccessPopupComponent,
        CriticalErrorComponent,
        AccountNameComponent,
        RadioCardComponent,
        CheckboxCardComponent,
        GraphComponent,
        FormatInputPercentageDirective,
        InvestorProfileOverlayComponent,
        FocusTrapModalDirective,
        PersonalBankerOrFinancialPlannerComponent,
        PersonalBankerAndFinancialPlannerComponent,
        ErrorAccessDirective,
        CookieModalComponent,
        DropDownMenuComponent,
        HowADWorksModelComponent,
        ContactUsModelComponent,
        OtherAccountsModelComponent,
        PcdInputValidatorDirective,
        NumberIncrementComponent,
        CodeToLabelDirective,
        DragDropDirective,
        RRAddressValidator,
        QualtricsComponent,
        InProgressOlbComponent,
        BindingCurrencyDirective,
        CopyClipboardDirective
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FormButtonsComponent,
        TranslateModule,
        DatePickerModule,
        PasswordValidator,
        AccSetupPasswordValidator,
        AccountSetupPasswordValidator,
        AddressValidator,
        SinNumberComponent,
        SinNumberPipe,
        SinValidator,
        SpousalSinValidator,
        PostalCodeValidator,
        POBoxValidator,
        ZipCodeValidator,
        EmailValidator,
        UnitValidator,
        MinLengthValidator,
        ContextualHelpComponent,
        CheckboxValidator,
        GraphValueValidator,
        CurrencyDirective,
        AddressAutoCompleteDirective,
        ContextualSearchDirective,
        ConfirmEqualValidatorDirective,
        ConfirmationYesNoComponent,
        FloodLightComponent,
        SafeHtmlPipe,
        SanitizePipe,
        InputValidatorDirective,
        NetWorthComponent,
        PhoneNumberComponent,
        SocialSecurityNumberPipe,
        EmailAddressComponent,
        PhoneNumberDirective,
        SINDirective,
        SSNDirective,
        ResumeApplicationComponent,
        AccountSetupComponent,
        CriticalErrorComponent,
        LoginSuccessPopupComponent,
        AccountNameComponent,
        RadioCardComponent,
        CheckboxCardComponent,
        GraphComponent,
        FormatInputPercentageDirective,
        FocusTrapModalDirective,
        PersonalBankerOrFinancialPlannerComponent,
        PersonalBankerAndFinancialPlannerComponent,
        ErrorAccessDirective,
        CookieModalComponent,
        HowADWorksModelComponent,
        ContactUsModelComponent,
        OtherAccountsModelComponent,
        DropDownMenuComponent,
        PcdInputValidatorDirective,
        NumberIncrementComponent,
        CodeToLabelDirective,
        DragDropDirective,
        RRAddressValidator,
        QualtricsComponent,
        BindingCurrencyDirective,
        CopyClipboardDirective
    ]
})
export class SharedModule {
}
