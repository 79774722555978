
// use like [appErrorAccess]="{id: '', parentId: '', submitted: form.submitted}" on any input field and pass unique id as id in directive which is same as error block with input and parentId use when you have radiobutton or select.
// Example on select account page. Please have a look, parentId is optional

import { Directive, Input, ElementRef, Renderer2, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription, of, merge } from 'rxjs';

interface ErrorAcess {
  id: string,
  submitted: boolean,
  parentId?: string
}
@Directive({
  selector: '[appErrorAccess][ngModel]',
  host: {
    '(blur)': 'onBlur($event)'
  }
})

export class ErrorAccessDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input() appErrorAccess: ErrorAcess;
  private subscription: Subscription;

  constructor(
    public control: NgControl, public renderer: Renderer2, public elementRef: ElementRef) { }

  ngOnChanges() {
    if (this.appErrorAccess && this.appErrorAccess['submitted']) {
      this.updateState();
    }
  }

  ngAfterViewInit() {
    this.subscription = merge(of(this.control.status), this.control.statusChanges)
      .subscribe(() => this.updateState());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onBlur(event: Event) {
    this.updateState();
  }

  private updateState() {
    const parent = this.appErrorAccess['parentId'] ? true : false;
    let ele: any;
    if (parent && document.getElementById(this.appErrorAccess['parentId']) && document.getElementById(this.appErrorAccess['parentId'])['parentNode'] !== null) {
      ele = this.renderer.selectRootElement('#' + this.appErrorAccess['parentId'], true);
      const attr = ele.hasAttribute('aria-labelledby');
      const attrVal = ele.getAttribute('aria-labelledby');
      if (!attr) {
        console.log("please set aria-labelledby in case of parentId on parent div of input tag");
      } else if (attr && !attrVal.includes(this.appErrorAccess['id'])) {
        if ((this.control.invalid && this.appErrorAccess['submitted']) || (this.control.invalid && this.control.touched)) {
          const newVal = attrVal + ' ' + this.appErrorAccess['id'];
          this.renderer.setAttribute(ele, 'aria-labelledby', newVal);
        }
      } else if (attr && attrVal.includes(this.appErrorAccess['id'])) {
        if (this.control.valid) {
          this.renderer.setAttribute(ele, 'aria-labelledby', attrVal.split(' ')[0]);
        }
      }
    } else {
      ele = this.elementRef.nativeElement;
      if (this.control.valid && !this.appErrorAccess['hardCodeAriaDescribedById']) {
        this.renderer.removeAttribute(ele, 'aria-describedby');
      } else if ((this.control.invalid && this.appErrorAccess['submitted']) || (this.control.invalid && this.control.touched)) {

        if (this.appErrorAccess['hardCodeAriaDescribedById']) {
          const accessId: any = this.appErrorAccess['hardCodeAriaDescribedById'] + ' ' + this.appErrorAccess['id'];
          this.renderer.setAttribute(ele, 'aria-describedby', accessId);
        } else {
          this.renderer.setAttribute(ele, 'aria-describedby', this.appErrorAccess['id']);
        }


      }
      else  if (this.control.valid  &&  this.appErrorAccess['hardCodeAriaDescribedById']) {
        this.renderer.removeAttribute(ele,  'aria-describedby');
        this.renderer.setAttribute(ele,  'aria-describedby',  this.appErrorAccess['hardCodeAriaDescribedById'] );
      }
    }

  }
}
