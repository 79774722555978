<form>
    
    <div class="container-menuView">

        <section id="celebrationCongSection">

            <div class="celebration content-center">

                <div class="celebration-img congratulation"></div>

                <h1> {{ 'celebration.titlePage' | translate }} {{firstName}} </h1>
                <h2 translate="celebration.subtitlePage"></h2>

                <div class="note-white">
                    <p translate="celebration.notePage"></p>
                </div>
            </div>

        </section>

        <section id="celebrationContinueSection">

            <div class="form-wrapper">
                <div class="form-wrapper">
                    <div class="form-row form-page-buttons">
                        <div class="form-col left-side"></div>
                        <div class="form-col right-side">
                            <button id="celebrationContinue" class="primary" type="submit" translate="button.continue" (click)="onSubmit()"></button>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>

</form>