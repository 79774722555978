<ng-container *ngIf="templateType=== RADIO_TYPES.LARGE">
    <div role="radiogroup" [attr.aria-labelledby]="section" [id]="accessErrorId['parentId']" class="form-row">

        <div id="section{{name}}{{item.code}}" *ngFor="let item of options; index as i" class="flex-50 form-radio-checks selection-card flow-card " [ngClass]="{'error-field': isError && form.submitted}">

            <input type="radio"
                   id="option{{name}}{{item.code}}"
                   [attr.aria-label]="(item.label | translate) +' '+ (item.description | translate)"
                   [name]="name"
                   [attr.name]="name"
                   required
                   [attr.data-ana]="dataAna"
                   (change)="fn(item.code)"
                   [value]="item.code"
                   [appErrorAccess]='accessErrorId'
                   [(ngModel)]="value" />

            <label class="large-card" for="option{{name}}{{ item.code}}">
                <span class="check"></span>
                <span class="bmo-container-flex flex-flow-wrap two-col">
                    <span *ngIf="!isFullImageUrl" class="side-img-small" aria-hidden="true"><img src="{{imageSource}}{{item.code}}.svg" alt="" /></span>
                     <span *ngIf="isFullImageUrl" class="side-img-small" aria-hidden="true"><img src="{{item.imageUrl}}" alt="" /></span>
                     <span class="title" translate="{{item.label}}"></span>
                        <span class="description" translate="{{item.description}}"></span>
                </span>
            </label>

        </div>

        <div role="alert" [id] ="accessErrorId['id']" *ngIf="isError && form.submitted" class="error-message">
            <span [translate]="errorMessage"></span>
        </div>

    </div>
</ng-container>

<ng-container *ngIf="templateType === RADIO_TYPES.SMALL">
    <div role="group" [attr.aria-labelledby]="section" [id]="accessErrorId['parentId']" class="form-row">

        <div id="section{{name}}{{item.code}}" *ngFor="let item of options; let i = index" class="form-radio-checks selection-card square-card " [ngClass]="{'error-field': isError && form.submitted}">

            <input type="radio"
                   id="option{{name}}{{item.code}}"
                   [attr.aria-label]="item.label | translate"
                   [name]="name"
                   [attr.name]="name"
                   required
                   [attr.data-ana]="dataAna"
                   #item.code=ngModel
                   (change)="fn(item.code)"
                   [value]="item.code"
                   [appErrorAccess]='accessErrorId'
                   [(ngModel)]="value" />

            <label class="small-card" for="option{{name}}{{item.code}}">
                <span class="check"></span>
                <span class="image" aria-hidden="true">
                    <img src="{{imageSource}}{{item.code}}.svg" alt="" />
                </span>
                <span class="description" translate="{{item.label}}"></span>
                <ng-container *ngIf="hasDescription">
                <span class="description" translate="{{item.description}}"></span>
                </ng-container>
            </label>

        </div>

        <div role="alert" [id] ="accessErrorId['id']" *ngIf="isError && form.submitted" class="error-message">
            <span [translate]="errorMessage"></span>
        </div>

    </div>
</ng-container>


