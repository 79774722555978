/**
 * @purpose: Unit validation.
 * @created: BMO/Richie Kharade
 * @updated:
 * @created on: 07-09-2018
 *
 */

import { NG_VALIDATORS, UntypedFormControl, ValidatorFn, Validator, AbstractControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[appUnitValidator][ngModel]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: UnitValidator,
        multi: true
    }]
})
export class UnitValidator implements Validator {
    validator: ValidatorFn;
    constructor() {
        this.validator = this.unitValidator();
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    unitValidator(): ValidatorFn {
        const unitPattern = new RegExp (/(^\d+\D*)|(^\D+$)/);
        return (control: AbstractControl) => {
            if (unitPattern.test(control.value)) {
                return null;
            }else if(control.value === '') {
                return null;
            }else{
                return { unitCode: { invalidUnitCode: true } };
            }
        };
    }
}