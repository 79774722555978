<form>

    <div class="container-noMenuView">
       
        <section>

            <div *ngIf="" role="alert" class="alert alert-danger">
                <p>
                    <strong translate="appErrors.criticalError"></strong><br />
                    <span translate="appErrors.criticalErrorDescription"></span>
                </p>
            </div>

            <section class="content-center">

                <img class="add-margin b24px" src="assets/img/artwork-branch-esign.svg" alt="" />

                <h1 translate="branchEsignature.titlePage"></h1>
                <p translate="branchEsignature.descriptionPage01"></p>
                <p translate="branchEsignature.descriptionPage02"></p>

                <div class="note-white content-left">

                    <p translate="branchEsignature.descriptionNote01"></p>

                    <ul class="disc">
                        <li translate="branchEsignature.ul01Item01"></li>
                        <li translate="branchEsignature.ul01Item02"></li>
                        <li translate="branchEsignature.ul01Item03"></li>
                    </ul>
                    
                    <ul>
                            <li *ngFor="let document of documents;index as index"> 
                                <a class="pdf" 
                                   href="javascript://"
                                   [attr.data-ana]="'mywealth:onboarding:esign:' + document.labels[0].value"
                                   [attr.aria-label]="getDocumentLabel(document) + ' ' + translateService.instant('ariaLabels.pdfDocument')"
                                   (click)="downloadDocument(document)">  {{getDocumentLabel(document)}} 
                                 </a>
                            </li>
                    </ul>
                </div>
                
            </section>

        </section>

        <section>

            <div class="form-wrapper">
                <div class="form-row form-page-buttons">
                    <div class="form-col left-side"></div>
                    <div class="form-col right-side">
                        <button class="primary" type="submit" (click)="onsubmit()" translate="button.continue"></button>
                    </div>
                </div>
            </div>

        </section>

    </div>

</form>
