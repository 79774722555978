<div class="footer-left flex-col-expand">
    <ul>
        <li [innerHTML]="footer.liElement01 | safeHtml"></li>
        <li [innerHTML]="footer.liElement02 | safeHtml"></li>
        <li [innerHTML]="footer.liElement03 | safeHtml"></li>
        <li [innerHTML]="footer.liElement04 | safeHtml"></li>
        <li *ngIf="!isSubProductAD" [innerHTML]="footer.liElement05 | safeHtml"></li>
        <li *ngIf="isSubProductAD" [innerHTML]="footer.liElement06 | safeHtml"></li>
        <li [innerHTML]="footer.liElement07 | safeHtml"></li>
    </ul>
    <div class="page-id" *ngIf="((!isIEBrowser && isCookieEnabled) || isBranchAssistedFlow)">
        <span [innerHTML]="footer.pageID | safeHtml"></span>
        <strong>{{pageName}}</strong>
    </div>
</div>

<div class="footer-right flex-equal-fixed" [innerHTML]=" (isCIROEnabled ? footer.ciroImg : footer.iirocImg) | safeHtml"></div>

