import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { Utils } from '../utils/utils.class';
import { PersonalInformation } from './../../sections/personal-information/personal-information.component';
import { ADDRESS_REGEX, CANADA, COUNTRY_CODE, State } from '../../config/app-config.constants';
import { SelectedCountry } from './../../sections/personal-information/personal-information.component';
import { FormService } from '../../shared/service/form.service';
import { FamilyInformation } from './../../sections/family-information/family-information.component';
import { ContactInformation } from '../../sections/contact-information/contact-information.component';
import { BranchReferral } from '../../sections/branch-referral/branch-referral.component';
import { EmploymentInformation } from '../../sections/employment-information/employment-information.component';



@Injectable({
    providedIn: 'root'
})

export class PrefillService {

    public appObject: any;
    public primaryApplicant: any;
    public primaryAccount: any;
    public spouse: any;
    public branchReferralObj: any;

    public branchReferral: BranchReferral;
    public personalInformation: PersonalInformation;
    public contactInformation: ContactInformation;
    public familyInformation: FamilyInformation;

    constructor(public applicationService: ApplicationService,
        public formService: FormService) {
    }

    /**Get Primary Account's Spouse */
    getSpouse(appObject) {
        this.spouse = (this.primaryApplicant.personal.identity.maritalStatus === '100002' ||
            this.primaryApplicant.personal.identity.maritalStatus === '100003') ? appObject.parties[1] : null;
        return this.spouse;
    }

    branchReferralPrefill() {
        this.appObject = Utils.getApplicationObject();
        this.primaryApplicant = this.appObject.parties[0];

        this.branchReferralObj = this.appObject.branchReferral;

        if (this.appObject) {

            Utils.setPartyRefId(this.primaryApplicant.partyRefId);
            this.formService.setPartyRefIdInPayload(this.primaryApplicant.partyRefId);

            /**INITIALIZE BRANCH REFERRAL OBJECT */
            this.branchReferral = new BranchReferral();

            /**PREFILL */
            this.branchReferral = this.getBranchReferralInfo(this.branchReferral);

            // console.log('branchReferral obj after prefill--', this.branchReferral);

            /**SET FORM DATA*/
            this.formService.setFormData('branchReferral', this.branchReferral);

            // console.log('branchreferral form data--', this.formService.getFormData('branchReferral'));


            /**Payload */
            this.formService.getPayload().setBranchReferral(this.branchReferral);
        }
    }

    personalInfoPrefill() {
        this.appObject = Utils.getApplicationObject();
        /* this.appObject = {
            'applicationLob': 'il',
            'applicationId': '3158ffcd-c967-4dd6-ab8c-39fc4e461db8',
            'iaCode': null,
            'applicationType': null,
            'status': 'DataCollection',
            'branchCode': null,
            'accounts': [
                {
                    'type': '100562',
                }
            ],
            'parties': [
                {
                    'partyRefId': 'a9a225e8-fa71-4b5b-aa52-6b710be8c8dc',
                    'spousePartyRefId': '96762e6c-3d96-4256-be80-6b710fbbefe0',
                    'roles': [
                        'PRIMARY_APPLICANT'
                    ],
                    'personal': {
                        'identity': {
                            'primaryEmailAddress': 'mayu@gm.com',
                            'socialInsuranceNumber': '999999998',
                            'dateOfBirth': '1989-10-24',
                            'maritalStatus': '100002',
                            'hasDependents': false,
                            'preferredLanguage': '100',
                            'hasPreferredName': false,
                            'legalName': {
                                'title': '100000~100004',
                                'firstName': 'Mayur',
                                'middleName': 'E',
                                'lastName': 'Kode'
                            },
                            'preferredName': {},
                            'citizenships': [
                                '100002',
                                '100001'
                            ],
                        },
                        'residence': {
                            'hasAlternateAddress': true,
                            'primaryAddress': {
                                'unitNumber': '123',
                                'streetAddress': '12-3025 Av Sainte-Catherine',
                                'postalCode': 'J2S8A9',
                                'city': 'Saint-Hyacinthe',
                                'province': '110',
                                'country': '100000'
                            },
                            'previousAddress': {},
                            'primaryPhone': {
                                'phoneNumber': ''
                            },
                            'secondaryPhone': {
                                'phoneNumber': '1231231231',
                                'mobile': true
                            },
                            'alternateAddress': {
                                'unitNumber': '321',
                                'streetAddress': '3153 Anderson Rd',
                                'postalCode': 'V0B1M1',
                                'city': 'Fernie',
                                'province': '102',
                                'country': '100000'
                            }
                        },
                        'employment': {
                            'isBmoEmployee': false,
                            'employmentStatus': '100000',
                            'employerBusinessName': 'Mov siern',
                            'occupation': '03',
                            'natureOfBusiness': '100084',
                            'primaryBusinessPhone': {
                                'phoneNumber': '',
                                'phoneExtension': ''
                            },
                            'jobTitle': 'fwe sere',
                            'employmentAddress': {
                                'unitNumber': 'few',
                                'streetAddress': '428103 8th Concession A',
                                'postalCode': 'N0C1C0',
                                'city': 'Feversham',
                                'province': '108',
                                'country': '100000'
                            }
                        },
                        'hasTaxResidency': false,
                        'residencyForTax': [
                            {
                                'province': null,
                                'country': '100000',
                                'taxIdentificationNumber': null,
                                'taxIdentificationNumberMissingReason': null,
                                'taxIdentificationNumberMissingReasonDescription': null,
                                'haveNoTaxIdentificationNumber': null
                            }
                        ]
                    },
                },
            ],
            'applicationSubProduct': 'SD'
        };*/

        /**Set party ref id */


        // SET COUNTRIES FROM REFERENCES
        if (this.applicationService.appReferences.references.countries) {
            Utils.setCountriesList(this.applicationService.appReferences.references.countries);
        }

        //  console.log('appObject', this.appObject);

        if (this.appObject) {
            this.primaryApplicant = this.appObject.parties[0];

            Utils.setPartyRefId(this.primaryApplicant.partyRefId);
            this.formService.setPartyRefIdInPayload(this.primaryApplicant.partyRefId);

            /**INITIALIZE PERSONAL INFO OBJECT */
            this.personalInformation = new PersonalInformation();

            /**PREFILL */
            this.personalInformation = this.getPersonalInfo(this.personalInformation);

            /**SET FORM DATA*/
            this.formService.setFormData('personalInformation', this.personalInformation);

            /**Payload */
            this.formService.getPayload().setPersonalInformation(this.personalInformation);
        }

    }

    contactInfoPrefill() {
        this.appObject = Utils.getApplicationObject();
        // console.log('appObject', this.appObject);

        if (this.appObject) {
            this.primaryApplicant = this.appObject.parties[0];

            Utils.setPartyRefId(this.primaryApplicant.partyRefId);
            this.formService.setPartyRefIdInPayload(this.primaryApplicant.partyRefId);

            /**INITIALIZE CONTACT INFO OBJECT */
            this.contactInformation = new ContactInformation();

            /**PREFILL */
            this.contactInformation = this.getContactInfo(this.contactInformation);

            /**SET FORM DATA*/
            this.formService.setFormData('contactInformation', this.contactInformation);

            /**Payload */
            this.formService.getPayload().setContactInformation(this.contactInformation);
        }
    }

    FamilyInfoPrefill() {

        this.appObject = Utils.getApplicationObject();

        if (this.appObject) {

            this.primaryApplicant = this.appObject.parties[0];

            Utils.setPartyRefId(this.primaryApplicant.partyRefId);
            this.formService.setPartyRefIdInPayload(this.primaryApplicant.partyRefId);

            /**INITIALIZE Family INFO OBJECT */
            this.familyInformation = new FamilyInformation();

            /**PREFILL */
            const spouseParty = this.getSpouse(this.appObject);
            this.familyInformation = this.getFamilyInfo(this.familyInformation, spouseParty);

            // console.log('family info obj from prefill--', this.familyInformation);

            /**SET FORM DATA*/
            this.formService.setFormData('familyInformation', this.familyInformation);

            /**Payload */
            this.formService.getPayload().setFamilyInformation(this.familyInformation);
        }
    }

    getContactInfo(contactInformation) {

        if (Utils.getIsOLBUser() && this.applicationService.getDataRationalizationFeatureFlag() === false) {
            // Phone number
            if (this.primaryApplicant.personal.residence && this.primaryApplicant.personal.residence.secondaryPhone &&
                Utils.nullCheck(this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber) && this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber !== '') { // MOBILE NUMBER
                contactInformation.phoneType = 'contactInformation.labels.phoneMobile';
                contactInformation.phoneNumber = this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber;
            } else { // PHONE NUMBER OR WORK
                if (this.primaryApplicant.personal.residence && this.primaryApplicant.personal.residence.primaryPhone &&
                    Utils.nullCheck(this.primaryApplicant.personal.residence.primaryPhone.phoneNumber) && this.primaryApplicant.personal.residence.primaryPhone.phoneNumber !== '') { // PHONE NUMBER
                    contactInformation.phoneType = 'contactInformation.labels.phoneHome';
                    contactInformation.phoneNumber = this.primaryApplicant.personal.residence.primaryPhone.phoneNumber;
                } else if (this.primaryApplicant.personal.employment && this.primaryApplicant.personal.employment.primaryBusinessPhone &&
                    Utils.nullCheck(this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneNumber) && this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneNumber !== '') { // WORK NUMBER
                    contactInformation.phoneType = 'contactInformation.labels.phoneWork';
                    contactInformation.phoneNumber = this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneNumber;
                    contactInformation.phoneExtension = this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneExtension;
                } else if (this.primaryApplicant.personal.residence && this.primaryApplicant.personal.residence.otherPhone &&
                    Utils.nullCheck(this.primaryApplicant.personal.residence.otherPhone.phoneNumber) && this.primaryApplicant.personal.residence.otherPhone.phoneNumber !== '') { // OTHER NUMBER
                    contactInformation.phoneNumber = this.primaryApplicant.personal.residence.otherPhone.phoneNumber;
                }
            }
        } else {
            if (this.applicationService.getDataRationalizationFeatureFlag() === false && this.primaryApplicant.personal.residence && this.primaryApplicant.personal.residence.primaryPhone) {
                contactInformation.phoneNumber = this.primaryApplicant.personal.residence.primaryPhone.phoneNumber || '';
                contactInformation.phoneExtension = this.primaryApplicant.personal.residence.primaryPhone.phoneExtension || '';
            }
        }

        if (this.applicationService.getDataRationalizationFeatureFlag() === true && this.primaryApplicant.personal.residence && this.primaryApplicant.personal.residence.secondaryPhone) {
            contactInformation.phoneNumber = this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber || '';
            contactInformation.phoneExtension = this.primaryApplicant.personal.residence.secondaryPhone.phoneExtension || '';
        }

        const primaryAddress = this.primaryApplicant.personal.residence.primaryAddress;
        const language = this.applicationService.getCurrentLanguage()?.toUpperCase();
        const addressLabel = primaryAddress.unitNumber + '' + primaryAddress.streetAddress + '' + primaryAddress.city + '' + primaryAddress.province + '' + primaryAddress.postalCode;
        if (this.primaryApplicant.personal.residence?.primaryAddress?.country === COUNTRY_CODE.CANADA
            && (Utils.getIsOLBUser() && ADDRESS_REGEX.poBox.test(addressLabel) && ADDRESS_REGEX.rrAddress[language].test(addressLabel) || !Utils.getIsOLBUser())
        ) {
            contactInformation.unit = this.primaryApplicant.personal.residence.primaryAddress.unitNumber || '';
            contactInformation.streetNumberName = this.primaryApplicant.personal.residence.primaryAddress.streetAddress || '';
            contactInformation.city = this.primaryApplicant.personal.residence.primaryAddress.city || '';
            contactInformation.province = this.primaryApplicant.personal.residence.primaryAddress.province || '';
            contactInformation.postalCode = this.primaryApplicant.personal.residence.primaryAddress.postalCode || '';
        }

        return contactInformation;
    }

    getEmployemntInfo() {
        this.appObject = Utils.getApplicationObject();
        if (this.appObject) {
            this.primaryApplicant = this.appObject.parties[0];

            Utils.setPartyRefId(this.primaryApplicant.partyRefId);
            this.formService.setPartyRefIdInPayload(this.primaryApplicant.partyRefId);

            /**INITIALIZE EMPLOYMENT INFO OBJECT */
            const employmentInformation = new EmploymentInformation();

            /**PREFILL */
            const empData = this.primaryApplicant?.personal?.employment || {};
            employmentInformation.bmoGroupOption = empData.bmoGroup;
            employmentInformation.employerName = empData.employerBusinessName;
            employmentInformation.employmentStatus = empData.employmentStatus;
            employmentInformation.isBMOEmployee = empData.isBmoEmployee;
            employmentInformation.jobTitle = empData.jobTitle;
            employmentInformation.selectNatureOfBusiness = empData.natureOfBusiness;
            employmentInformation.selectOccupation = empData.occupation;
            employmentInformation.selectPreviousOccupation = empData.occupation;

            /**SET FORM DATA*/
            this.formService.setFormData('employmentInformation', employmentInformation);

            /**Payload */
            this.formService.getPayload().setEmploymentInformation(employmentInformation, this.formService.getFormData('contactInformation'));
        }
    }

    getFamilyInfo(familyInformation, spouse) {
        if (this.primaryApplicant.personal.identity) {
            familyInformation.maritalStatus = this.primaryApplicant.personal.identity.maritalStatus || '';

            /**In case of SpousalRRSP */
            if (Utils.isSpousalRRSP()) {
                if (familyInformation.isMarried()) {
                    familyInformation.spousalMaritalStatus = this.primaryApplicant.personal.identity.maritalStatus;
                }
            }
        }
        return familyInformation;

    }

    getBranchReferralInfo(branchReferral) {
        branchReferral.investmentPlatform = this.appObject.applicationSubProduct ? this.appObject.applicationSubProduct : null;

        branchReferral.isBranchReferral = this.branchReferralObj.isBranchReferral ? this.branchReferralObj.isBranchReferral : null;
        branchReferral.branchPersonnel = this.branchReferralObj.designationCode ? this.branchReferralObj.designationCode : null;
        branchReferral.fpName = this.branchReferralObj.fpName ? this.branchReferralObj.fpName : null;
        branchReferral.fsmName = this.branchReferralObj.fsmName ? this.branchReferralObj.fsmName : null;
        branchReferral.EIN = this.branchReferralObj.ein ? this.branchReferralObj.ein : null;
        branchReferral.FPCode = this.branchReferralObj.fpCode ? this.branchReferralObj.fpCode : null;
        branchReferral.fpPhone = this.branchReferralObj.fpPhone ? this.branchReferralObj.fpPhone : null;
        branchReferral.fsmPhone = this.branchReferralObj.fsmPhone ? this.branchReferralObj.fsmPhone : null;
        branchReferral.transit = this.branchReferralObj.transit ? this.branchReferralObj.transit : null;

        return branchReferral;
    }

    getPersonalInfo(personalInformation) {
        if (this.primaryApplicant.personal.identity) {

            if (this.primaryApplicant.personal.identity.legalName) {
                personalInformation.firstname = this.primaryApplicant.personal.identity.legalName.firstName || '';
                personalInformation.initial = this.primaryApplicant.personal.identity.legalName.middleName || '';
                personalInformation.lastname = this.primaryApplicant.personal.identity.legalName.lastName || '';
            }

            personalInformation.sinNumber = this.primaryApplicant.personal.identity.socialInsuranceNumber || '';
            personalInformation.dateOfBirth = this.primaryApplicant.personal.identity.dateOfBirth || '';
            personalInformation.emailAddress = this.primaryApplicant.personal.identity.primaryEmailAddress || '';
            if (Utils.getIsOLBUser() || Utils.getIsJourneyUser()) {
                personalInformation.dateOfBirth = '';
                personalInformation.sinNumber = '';
            }
            if (this.primaryApplicant.personal.identity.citizenships && (Array.isArray(this.primaryApplicant.personal.identity.citizenships))) {
                if (this.primaryApplicant.personal.identity.citizenships.length >= 1) {
                    if ((Utils.getIsOLBUser() || Utils.getIsJourneyUser()) || Utils.getIsBranchAssisted()) {
                        const isCanadian = this.primaryApplicant.personal.identity.citizenships.indexOf(CANADA.code) >= 0;
                        if (!isCanadian) {// if not a canadian citizen
                            personalInformation.citizenship = '';
                            personalInformation.citizenshipLabel = '';
                        } else {
                            personalInformation.citizenship = CANADA.code;
                            personalInformation.citizenshipLabel = CANADA.label;
                        }

                        /**In case of TFSA/CASH/MARGIN */
                        if (Utils.isTFSA() || Utils.isCash() || Utils.isMargin()) {
                            this.setTFSACashAlternateCitizenship(personalInformation);
                        }
                        /**In case of RRSP/SRRSP/RIF */
                        else if (Utils.isRRSP() || Utils.isSpousalRRSP() || Utils.isRIF()) {
                            this.setRRSPAlternateCitizenship(personalInformation);
                        }

                    } else {
                        const isCanadian = this.primaryApplicant.personal.identity.citizenships[0] === '100000' ? true : false;

                        if (!isCanadian) {// if not a canadian citizen
                            personalInformation.citizenship = '';
                            personalInformation.citizenshipLabel = '';
                        } else {
                            personalInformation.citizenship = this.primaryApplicant.personal.identity.citizenships[0];
                            personalInformation.citizenshipLabel = (this.primaryApplicant.personal.identity.citizenships[0] === CANADA.code)
                                ? CANADA.label :
                                Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[0]);
                        }

                        /**In case of TFSA/Cash */
                        if (Utils.isTFSA() || Utils.isCash() || Utils.isMargin()) {
                            this.setTFSACashAlternateCitizenship(personalInformation);
                        }
                        /**In case of RRSP */
                        else if ((Utils.isRRSP() || Utils.isSpousalRRSP())) {
                            this.setRRSPAlternateCitizenship(personalInformation);
                        }
                    }

                    if (personalInformation.selectedCountries && personalInformation.selectedCountries.length > 1) {
                        let countryCodes: any = [];
                        let countries: any = [];
                        personalInformation.selectedCountries.forEach((country) => {
                            if (countryCodes.indexOf(country.code) == -1) {
                                countries.push(country);
                                countryCodes.push(country.code);
                            }
                        });
                        personalInformation.selectedCountries = countries;
                    }
                }
            }
        }

        return personalInformation;
    }

    setTFSACashAlternateCitizenship(personalInformation) {

        let citizenships = [];
        let current_index;

        citizenships = this.primaryApplicant.personal.identity.citizenships;

        if (!Utils.isCRATaxResidencyEnabled() || (Utils.isCRATaxResidencyEnabled() && !(Utils.isCash() || Utils.isMargin()))) {

            if (this.primaryApplicant.personal.identity.citizenships.length > 1) {
                personalInformation.otherCitizenshipOrTaxResidency = 'Yes';
            } else {
                personalInformation.otherCitizenshipOrTaxResidency = 'No';
            }
        }


        /**Citizenship countries*/
        for (let i = 0; i < citizenships.length; i++) {

            if (this.primaryApplicant.personal.identity.citizenships[i] !== '100000' ) {

                if (Utils.isCRATaxResidencyEnabled() && (Utils.isCash() || Utils.isMargin()) && this.primaryApplicant.personal.identity.citizenships[i] !== '100001') {
                    (personalInformation.selectedCitizenCountries || []).push(
                        {
                            id: "citizenCountry" + personalInformation.citizenCountryIndex++,
                            code: citizenships[i],
                        })

                } else {
                    personalInformation.selectedCountries.push(
                        new SelectedCountry(personalInformation.countryIndex++, this.applicationService.getCAUSExcludedContryList())
                    );



                    current_index = (personalInformation.selectedCountries.length - 1);

                    // Check if its a valid country, then set isCitizen/code value.
                    const filteredCountry = this.applicationService.getCAUSExcludedContryList().filter
                        (x => x.code === citizenships[i]);

                    if (filteredCountry.length > 0) { // valid country
                        personalInformation.selectedCountries[current_index].isCitizen = true;
                        personalInformation.selectedCountries[current_index].code = citizenships[i];
                    } else { // invalid country
                        personalInformation.selectedCountries[current_index].isCitizen = false;
                        personalInformation.selectedCountries[current_index].code = '';
                    }

                    // exception for USA
                    if (this.primaryApplicant.personal.identity.citizenships[i] === '100001') {/**If USA, then SSN */
                        personalInformation.selectedCountries[current_index].ssn = '';
                        personalInformation.selectedCountries[current_index].isTaxResident = true;

                    }
                }
            }
        }
        if (this.applicationService.getCaaTaxResidencyCertificateFlag() && (Utils.isCash() || Utils.isMargin())) {
            // set otherCitizenshipOrTaxResidency flag
            const filteredCitizenships = [];
            (this.primaryApplicant.personal.identity.citizenships || []).forEach((citizenship) => {
                if (citizenship !== COUNTRY_CODE.CANADA && citizenship !== COUNTRY_CODE.USA) {
                    filteredCitizenships.push(citizenship);
                }
            })
            if (Utils.isCRATaxResidencyEnabled()) {
                personalInformation.otherCitizenShip = filteredCitizenships.length > 0 ? State.yes : State.no;
                if ((this.primaryApplicant?.personal?.residencyForTax || []).length > 1) {
                    personalInformation.otherCitizenshipOrTaxResidency = State.yes;
                } else {
                    personalInformation.otherCitizenshipOrTaxResidency = State.no;
                }
            } else {
                personalInformation.otherCitizenshipOrTaxResidency = filteredCitizenships.length > 0 ? State.yes : State.no;
            }

            // set Us fields
            let taxArray: any;
            if ((this.primaryApplicant?.personal?.residencyForTax || []).length > 0) {
                taxArray = (this.primaryApplicant?.personal?.residencyForTax || []).find(x => { if (x.country === COUNTRY_CODE.USA) { return true } else { return false } });
            }


            if (this.primaryApplicant.personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) > -1 || (taxArray && taxArray?.country === COUNTRY_CODE.USA)) {

                personalInformation.usSINNumber = taxArray?.taxIdentificationNumber;
                // as we dont have flags coming , need to use citizenship array and residencyForTaxArray
                personalInformation.usCitizenship = (taxArray && taxArray?.country === COUNTRY_CODE.USA || this.primaryApplicant.personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) > -1) ? State.yes : State.no;

                personalInformation.usResidentCheckBox = this.primaryApplicant.personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) > -1
                personalInformation.usTaxResidentCheckBox = taxArray?.country === COUNTRY_CODE.USA;
            } else {
                personalInformation.usCitizenship = State.no;
                personalInformation.usResidentCheckBox = false;
                personalInformation.usTaxResidentCheckBox = false;
                // personalInformation.usResidentCheckBox = false;
            }
            // set isCanadianTaxResidency flag
            let canadaTaxcode: any;
            if ((this.primaryApplicant?.personal?.residencyForTax || []).length > 0) {
                canadaTaxcode = this.primaryApplicant.personal.residencyForTax.find(x => { if (x.country === COUNTRY_CODE.CANADA) { return true } else { return false } });
            }


            if (canadaTaxcode && canadaTaxcode.country === COUNTRY_CODE.CANADA) {
                personalInformation.isCanadaTaxResidency = State.yes;
            } else if (canadaTaxcode != undefined && canadaTaxcode != null && canadaTaxcode != '') {
                personalInformation.isCanadaTaxResidency = State.no;
            }
            // Remove USA from selected Countries , if the flag is turned on , cash || margin
            personalInformation.selectedCountries = (personalInformation?.selectedCountries || []).filter(x => x.code !== COUNTRY_CODE.USA);
        }

        /**SET 'alternateCitizenshipLabelTFSACash'*/
        personalInformation.alternateCitizenshipLabelTFSACash = personalInformation.selectedCountries
            .map(x => x.countryCitizenshipTaxResidencyString);
    }

    setRRSPAlternateCitizenship(personalInformation) {

        const canadian_Citizen = personalInformation.citizenship === '100000' ? true : false;

        if (canadian_Citizen) { // If Canadian Citizen
            if (this.primaryApplicant.personal.identity.citizenships.length > 1) {
                personalInformation.otherCitizenshipOrTaxResidency = 'Yes';

                /**Alternate citizenship */
                personalInformation.selectedCountries.push(
                    new SelectedCountry(personalInformation.countryIndex++, Utils.getCountriesList())
                );
              if(this.primaryApplicant.personal.identity.citizenships.length === 2 && this.primaryApplicant.personal.identity.citizenships[1] === '100000' && 
                         this.primaryApplicant.personal.identity.citizenships[0] !== '100000'  ) { // MWB-17955
                        // Check if its a valid country, then set isCitizen/code value.
                        const filteredCountry = Utils.getCountriesList().filter
                        (x => x.code === this.primaryApplicant.personal.identity.citizenships[0]);

                        if (filteredCountry.length > 0) { // valid country
                        personalInformation.selectedCountries[0].code = this.primaryApplicant.personal.identity.citizenships[0];
                        } else { // invalid country
                        personalInformation.selectedCountries[0].code = '';
                        }

                        personalInformation.alternateCitizenship = personalInformation.selectedCountries[0].code;
                        personalInformation.alternateCitizenshipLabelRRSP =
                        Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[0]);
              } else {
                // Check if its a valid country, then set isCitizen/code value.
                const filteredCountry = Utils.getCountriesList().filter
                    (x => x.code === this.primaryApplicant.personal.identity.citizenships[1]);

                if (filteredCountry.length > 0) { // valid country
                    personalInformation.selectedCountries[0].code = this.primaryApplicant.personal.identity.citizenships[1];
                } else { // invalid country
                    personalInformation.selectedCountries[0].code = '';
                }

                personalInformation.alternateCitizenship = personalInformation.selectedCountries[0].code;
                personalInformation.alternateCitizenshipLabelRRSP =
                    Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[1]);
                }
            } else {
                personalInformation.otherCitizenshipOrTaxResidency = 'No';
            }

        } else {// If not Canadian Citizen

            if (this.primaryApplicant.personal.identity.citizenships.length > 0) {

                for (let i = 0; i < this.primaryApplicant.personal.identity.citizenships.length; i++) {
                    personalInformation.otherCitizenshipOrTaxResidency = 'Yes';

                    /**Alternate citizenship */
                    personalInformation.selectedCountries.push(
                        new SelectedCountry(personalInformation.countryIndex++, Utils.getCountriesList())
                    );

                    // Check if its a valid country, then set isCitizen/code value.
                    const filteredCountry = Utils.getCountriesList().filter
                        (x => x.code === this.primaryApplicant.personal.identity.citizenships[i]);

                    if (filteredCountry.length > 0) { // valid country
                        personalInformation.selectedCountries[i].code = this.primaryApplicant.personal.identity.citizenships[i];
                    } else { // invalid country
                        personalInformation.selectedCountries[i].code = '';
                    }
                }

                this.personalInformation.citizenship = this.personalInformation.selectedCountries[0].code;
                this.personalInformation.citizenshipLabel = Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[0]);

                // if RRSP has two countries
                if (this.personalInformation.selectedCountries.length >= 2) {
                    this.personalInformation.alternateCitizenship = this.personalInformation.selectedCountries[1].code;
                    this.personalInformation.alternateCitizenshipLabelRRSP =
                        Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[1]);
                }

            } else {
                personalInformation.otherCitizenshipOrTaxResidency = 'No';
            }

        }

    }

}

