import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-contextual-help',
    templateUrl: './contextual-help.component.html',
    providers: [
        TranslateService
    ]
})
export class ContextualHelpComponent implements OnInit {

    @Input() content: string;
    @Input() arialabel: string;
    @Input() inputDataAna: string;
    @Input() changeContent : string;
    @Input() aria_labelledby? : string;
    constructor(
        private translate: TranslateService
    ) { }

    ngOnInit() {
        $(document).ready(function () {

            let $tooltipIdNum = 0;

            // --- Contextual help - show, hide, moving tooltip from right to left and changing its view to modal under 600px
            // ---
            function tooltip($this, $aTrigger, $tooltipIdNum) {

                let $windowWidth = $(window).width();
                let $thisWidth = $this.width();
                let $thisHeight = $this.height();
                let $parentOffset = $aTrigger.offset().left + 80;
                let $finalWidth = $parentOffset + $thisWidth;

                let $thisContent = $this.find('.contextual-help-content');
                let $thisLink = $this.find('.contextual-help-content a');
                //let $trigger = $divParent.find('.tooltip-show');

                $this.attr('id', 'panelTooltip' + $tooltipIdNum);
                $aTrigger.attr('aria-expanded', 'false');
                $aTrigger.attr('aria-controls', 'panelTooltip' + $tooltipIdNum);

                if ($windowWidth > 600) {

                    $this.removeClass('contextual-help-modal').addClass('contextual-help position-right');

                    $('body > .contextual-help-modal').fadeToggle(200, function () {
                        $(this).remove();
                    });

                    $('.contextual-help-overlay').removeAttr('style');
                    $('.modal-overlay').removeClass('contextual-help-overlay');
                    $('body').removeAttr('class');

                    if ($finalWidth > $windowWidth) {
                        $this.removeClass('position-right').addClass('position-left');
                    } else {
                        $this.removeClass('position-left').addClass('position-right');
                    }

                    if ($thisHeight > 350) {
                        $thisContent.addClass('desk-maxHeight');
                    }

                    $aTrigger.unbind().click(function (e) {

                        $(this).attr('aria-expanded', 'true');

                        $this.removeAttr('style');
                        $this.fadeToggle(200);

                        $this.find('a.contextual-help-close').focus();

                        e.stopPropagation();

                    });

                    $('.contextual-help-close').unbind().click(function (e) {

                        $(this).parent().removeAttr('style');
                        $(this).parent().prev('a').attr('aria-expanded', 'false');
                        $(this).parent().prev('a').focus();

                        e.stopPropagation();
                    });

                    $(document).on('mouseup keyup', function (e) {

                        if ($this.is(':visible')
                            && $this.has(e.target).length === 0
                            && !$this.is(e.target)
                            && !$aTrigger.is(e.target)
                            && !$thisContent.is(e.target)
                            && $thisContent.has(e.target).length === 0) {

                            $this.fadeOut(200);
                            $this.prev('a').focus();
                            $this.prev('a').attr('aria-expanded', 'false');

                        } else if ($this.is(':visible') && e.which == 32) {
                            $this.fadeOut(200);
                            $this.prev('a').focus();
                            $this.prev('a').attr('aria-expanded', 'false');
                        }

                    });

                } else {

                    $('.contextual-help').fadeOut(200, function () {
                        $(this).removeClass('contextual-help position-right position-left').addClass('contextual-help-modal');
                    });

                    if ($thisHeight > 300) {
                        $thisContent.addClass('modal-maxHeight');
                    }

                    $aTrigger.unbind().click(function () {

                        $(this).attr('aria-expanded', 'true');

                        $this.clone().appendTo('body');
                        $('body > .contextual-help-modal').fadeToggle(200);
                        $('body').toggleClass('hidden');
                        $('.modal-overlay').addClass('contextual-help-overlay');
                        $('.contextual-help-overlay').fadeToggle(200);
                        $('.contextual-help-close').focus();

                        $('.contextual-help-close').unbind().click(function () {

                            $('body > .contextual-help-modal').fadeToggle(200, function () {
                                $(this).remove();
                            });

                            $('.contextual-help-overlay').fadeToggle(200, function () {
                                $('.modal-overlay').removeAttr('style');
                                $('.modal-overlay').removeClass('contextual-help-overlay');
                            });

                            $('body').removeAttr('class');

                            $this.prev('a').focus();
                            $this.prev('a').attr('aria-expanded', 'false');

                        });

                    });

                }
            }

            // --- Contextual help - calling the 'tooltip()' on each '.contextual-help' icon on the page
            // ---
            $('.contextual-help').each(function (index) {

                let $this = $(this);
                let $aTrigger = $(this).prev('a.tooltip-show');

                //$tooltipIdNum++

                tooltip($this, $aTrigger, (index)+1);

                $(window).on("resize", function () {
                    tooltip($this, $aTrigger, (index)+1);
                });

            });

        });
    }
}
