import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormService } from '../../shared/service/form.service';
import { RADIO_TYPES, INVESTMENT_KNOWLEDGE_TYPES, INVESTMENT_WITHDRAW, INVESTMENT_TIME, INVESTMENT_TYPES, APP_ROUTES, SUCCESS_RESPONSE, URL, ANALYTICS, IL_INVESTMENT_PROFILE, IL_Investment_TimeHorizon} from '../../config/app-config.constants';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../../shared/utils/utils.class';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Subscription } from 'rxjs';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { WindowRefService } from '../../shared/service/window-ref.service';
//import { Payload } from 'src/app/shared/service/payload.class';

@Component({
    selector: 'app-investment-knowledge',
    templateUrl: './investment-knowledge.component.html'
})

export class InvestmentKnowledgeComponent implements OnInit, OnDestroy, AfterViewInit {

    public APP_ROUTES = APP_ROUTES;
    public party;
    public account;
    public criticalError: boolean;
    public investmentKnowledge: InvestmentKnowledge = new InvestmentKnowledge();
    public INVESTMENT_KNOWLEDGE_TYPES: any = INVESTMENT_KNOWLEDGE_TYPES;
    public RADIO_TYPES: any = RADIO_TYPES;
    public isFormSubmit: boolean = false;
    public lang: any;
    public appNumber;
    public accountSetup;
    public showFinishLater: boolean;
    public url;
    public ilInvestmentProfile;
    public isCurrentLangEnglish;
    public isCurrentLangFrench;
    public ilInvestmentTimeHorizon;
    public investmentExperience;
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    private onExitApplicationPerformSaveSubscriber;
    public investmentKnoelwdgeData = {
        ilInvestmentTimeHorizonForWithDraw: [
            { code: "1", label: "investmentKnowledge.labels.time02" },
            { code: "2", label: "investmentKnowledge.labels.time13" },
            { code: "3", label: "investmentKnowledge.labels.time35" },
            { code: "4", label: "investmentKnowledge.labels.time510" },
            { code: "5", label: "investmentKnowledge.labels.time10plus" }
        ],
        ilInvestmentTimeHorizon: [
            { code: "1", label: "investmentKnowledge.labels.time02" },
            { code: "2", label: "investmentKnowledge.labels.time13" },
            { code: "3", label: "investmentKnowledge.labels.time35" },
            { code: "4", label: "investmentKnowledge.labels.time510" },
            { code: "5", label: "investmentKnowledge.labels.time10plus" }
        ],
    }


    @ViewChild('investmentKnowledgeForm', {static: true}) investmentKnowledgeForm: NgForm;
    constructor(
        public applicationService: ApplicationService,
        public formService: FormService,
        public titleService: Title,
        private winRef: WindowRefService,
        private route: ActivatedRoute,
        private analytics: AnalyticsService
    ) {
        this.formService.forms.investmentKnowledge.isVisited = true;
        this.formService.setActiveComponent('investmentKnowledge', this);
        this.url = URL.INVESTMENT_KNOWLEDGE;

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();

        if (this.isCurrentLangEnglish) {
            this.lang = 'en';
        } else if (this.isCurrentLangFrench) {
            this.lang = 'fr';
        }
    }


    ngAfterViewInit() {

        this.checkFormValidityOnSignOut();
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('InvestmentDetails');
    }


    ngOnInit() {

        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        this.titleService.setTitle(Utils.getPageTitleInvestmentKnowlwedge());

        this.formService.setActiveForm(this.investmentKnowledgeForm);
        this.party = this.formService.getPrimaryApplicant();
        this.account = this.formService.getPrimaryAccount();

        this.applicationService.getReferences(this.lang).then(({references}: any) => {
            if(references){
                this.ilInvestmentProfile = this.mapDescriptionMessage(references.investmentKnowledge,IL_INVESTMENT_PROFILE);
                this.ilInvestmentTimeHorizon = this.mapDescriptionMessage(references.ilInvestmentTimeHorizon,IL_Investment_TimeHorizon);
                this.investmentExperience = (references.investmentExperience).filter
                (item => item.code !== '8'); // Remove option  None from Reference
            }
        });

        if (this.formService.forms.investmentKnowledge.data != null) {
            this.investmentKnowledge = this.formService.getFormData('investmentKnowledge');

        }


        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'investmentKnowledge') {
                    this.onFinishLater();
                }
            });
            this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
                if (value) {
                    this.onFinishLater(true);
                }
            });

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        setTimeout(() => {
            this.setGTM();
        }, 200);
    }

    investmentKnowledgetriggerView() {
        this.analytics.triggerView('investmentKnowledge ' + this.ilInvestmentProfile.filter(profile =>
            profile.code == this.investmentKnowledge.ilInvestmentKnowledge)[0].label);
    }

    // For Accessibilty purpose, setFocus() method was created.

    setFocus() {

        //setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.investmentKnowledgeForm);
           }, 250);

    }


    resetComponentObjectAttr() {
        this.investmentKnowledge.pastExperience =
            (this.investmentKnowledge.ilInvestmentKnowledge != INVESTMENT_KNOWLEDGE_TYPES.NONE)
                ? this.investmentKnowledge.pastExperience : [];

    }

    saveOnSignOut() {

        this.resetComponentObjectAttr();
        this.formService.forms.investmentKnowledge.isCompleted = true;
        this.formService.setFormData('investmentKnowledge', this.investmentKnowledge);

         // To prepare payload with PI , CI, EI, FI and FD
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);

        // save current page if form is valid
        if (!this.formService.forms.investmentKnowledge.isCompleted) {
            const emptyinvestmentKnowledge = new InvestmentKnowledge();
            this.formService.getPayload().setInvestmentKnowledge(emptyinvestmentKnowledge);
        } else {
            if (this.investmentKnowledgeForm.valid) {
                this.formService.getPayload().setInvestmentKnowledge(this.investmentKnowledge);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;

                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true;
            });
    }


    onSubmit() {
        this.criticalError = false;
        this.isFormSubmit = true;

        if (this.investmentKnowledgeForm.valid) {
            /**Add data to payload */
            this.resetComponentObjectAttr();
            this.formService.setFormData('investmentKnowledge', this.investmentKnowledge);
            this.formService.forms.investmentKnowledge.isCompleted = true;
            this.formService.getPayload().setInvestmentKnowledge(this.investmentKnowledge);

            // To prepare payload with PI , CI, EI, FI and FD
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');
            const familyInformation = this.formService.getFormData('familyInformation');
            const financialInformation = this.formService.getFormData('financialInformation');
            const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

            this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(familyInformation);
            this.formService.getPayload().setFinancialInformation(financialInformation);

            if (Utils.getIsInSession() === false) {

                if (Utils.isSubProductAD()) {

                    this.applicationService.saveAndcontinue(this.investmentKnowledgeForm, APP_ROUTES.riskAndReturn);

                } else {

                    this.applicationService.saveAndcontinue(this.investmentKnowledgeForm, APP_ROUTES.regulatory_disclosures);
                }


            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        if (res.status === SUCCESS_RESPONSE) {

                            if (Utils.isSubProductAD()) {

                                this.applicationService.saveAndcontinue(this.investmentKnowledgeForm, APP_ROUTES.riskAndReturn);

                            } else {

                                this.applicationService.saveAndcontinue(this.investmentKnowledgeForm, APP_ROUTES.regulatory_disclosures);
                            }

                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }

        } else {
            this.setFocus();
        }
    }

    onFinishLater(isExitModal = false) {

        if (!this.investmentKnowledgeForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.resetComponentObjectAttr();
        this.formService.forms.investmentKnowledge.isCompleted = true;
        this.formService.setFormData('investmentKnowledge', this.investmentKnowledge);

        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);

        // save current page if form is valid
        if (!this.formService.forms.investmentKnowledge.isCompleted) {
            const emptyinvestmentKnowledge = new InvestmentKnowledge();
            this.formService.getPayload().setInvestmentKnowledge(emptyinvestmentKnowledge);
        } else {
            if (this.investmentKnowledgeForm.valid) {
                this.formService.getPayload().setInvestmentKnowledge(this.investmentKnowledge);
            }
        }

        // console.log('payload', this.formService.getPayload());

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true;
            });

    }

    hideAccountSetup() {
        this.accountSetup = false;
    }


    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {

                if (this.investmentKnowledgeForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }


    ngOnDestroy() {

        this.formService.setFormData('investmentKnowledge', this.investmentKnowledge);

        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    mapDescriptionMessage(referenceList, constantList) {

        (referenceList || []).forEach((refInvestment) => {
            constantList.forEach(constInvestment=> {
                if(refInvestment.code === constInvestment.code) {
                    refInvestment.description = constInvestment.description;
                }
            });
        });
        return referenceList;
    }

    setGTM() {

        const gtmDataLayerForInvestmentKnowledge: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleInvestmentKnowlwedge(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.INVESTMENT_KNOWLEDGE,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.investment_knowledge,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForInvestmentKnowledge);
        // console.log('investment know', this.winRef.nativeWindow.dataLayer);

    }

}
export class InvestmentKnowledge {
    public ilInvestmentKnowledge: string = "";
    public ilInvestmentTimeHorizon: any = "";
    public investmentTimeHorizonForWithDraw: any = "";
    public pastExperience: any = [];
}
