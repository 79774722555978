<!-- <app-flood-light></app-flood-light> -->

<form #investmentKnowledgeForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section id="investmentKnowledgeMainSection">

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="investmentKnowledgeForm.invalid && isFormSubmit" translateTag="investmentKnowledge"></app-invalid-form>

            <section id="investmentKnowledgeSection">

                <h1 id="investmentKnowledgeDescribe" translate="investmentKnowledge.heading" ></h1>
                <div class="more-info-wrapper add-margin t12px b24px">

                    <a role="button" id="bmoInvestmentDetailsTitleOpen" aria-controls="bmoInvestmentDetailsOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'investmentKnowledge.ariaLabels.heading' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="bmoInvestmentDetailsOpen">
                        <p [innerHTML]="'investmentKnowledge.titleInvestmentDetails' | translate"></p>
                        <p>
                            <a role="button" id="bmoInvestmentDetailsTitleClose" aria-controls="bmoInvestmentDetailsTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>

                <div class="form-wrapper">
                <app-radio-card [options]="ilInvestmentProfile"
                                [form]="investmentKnowledgeForm"
                                [section]="'investmentKnowledgeDescribe'"
                                [name]="'investmentKnowledge'"
                                [imageSource]="'assets/img/investment-knowledge/'"
                                ngDefaultControl
                                [dataAna]="'mywealth:onboarding:risk:knowledge'"
                                [isError]="false"
                                [accessErrorId]="{ id: 'investmentKnowledge-error', parentId: 'parent-investmentKnowledge-error',  submitted: isFormSubmit }"
                                [errorMessage]="'investmentKnowledge.errors.investmentKnowledgeError'"
                                [templateType]="RADIO_TYPES.LARGE"
                                [(ngModel)]="investmentKnowledge.ilInvestmentKnowledge"
                                (change)="investmentKnowledgetriggerView()"
                                >
                </app-radio-card>
                </div>

            </section>

            <section id="investmentExperienceSection" *ngIf="investmentKnowledge.ilInvestmentKnowledge!=='' && investmentKnowledge.ilInvestmentKnowledge !==INVESTMENT_KNOWLEDGE_TYPES.NONE">

                <h1 id="investmentTypesDescribeGroup" translate="investmentKnowledge.titleInvestFunds" ></h1>
                <p translate="investmentKnowledge.titleInvestmentKnowledgeDescription" id="investmentTypesDescribe"></p>
                <div class="form-wrapper">
                    <div role="group" aria-labelledby="investmentTypesDescribeGroup" class="form-row add-margin t24px"
                    >
                        <app-checkbox-card [options]="investmentExperience"
                                        [form]="investmentKnowledgeForm"
                                        [section]="'investmentTypesDescribe'"
                                        [name]="'investmentOptions'"
                                        ngDefaultControl
                                        [dataAna]="'mywealth:onboarding:risk:experience'"
                                        [imageSource]="'assets/img/fund-choice/'"
                                        [isFormSubmitted]='isFormSubmit'
                                        [selected]="investmentKnowledge.pastExperience"
                                        [accessErrorId]="{ id: 'investmentOptions-error', parentId: 'parent-investmentOptions-error',  submitted: isFormSubmit }"
                                        [errorMessage]="'investmentKnowledge.errors.investmentOptionsError'"
                                        >
                        </app-checkbox-card>
                    </div>
                </div>

            </section>

            <div [hidden]="investmentKnowledge.ilInvestmentKnowledge ==''">

                <section id="investmentTimeSection">

                    <h1 id="investmentTimeDescribe" translate="investmentKnowledge.titleHowLongInvestFunds"></h1>
                    <div class="more-info-wrapper add-margin t12px b24px">

                        <a role="button" id="bmoInvestmentTimehorizonTitleOpen" aria-controls="bmoInvestmentTimehorizonOpen"
                            aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                            attr.aria-label="{{ 'investmentKnowledge.ariaLabels.titleHowLongInvestFunds' | translate }}">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>

                        <div class="more-info-content new-bmo-card-content" id="bmoInvestmentTimehorizonOpen">
                            <p [innerHTML]="'investmentKnowledge.titleInvestmentTimehorizon' | translate"></p>
                            <p>
                                <a role="button" id="bmoInvestmentTimehorizonTitleClose" aria-controls="bmoInvestmentTimehorizonTitleClose"
                                    aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                    attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>

                        </div>

                    </div>
                    <div  class="form-wrapper">
                    <app-radio-card [options]="ilInvestmentTimeHorizon"
                                    [form]="investmentKnowledgeForm"
                                    [section]="'investmentTimeDescribe'"
                                    [name]="'investmentDuration'"
                                    [dataAna]="'mywealth:onboarding:risk:investment time'"
                                    [imageSource]="'assets/img/invest-duration/'"
                                    ngDefaultControl
                                    [accessErrorId]="{ id: 'investmentDuration-error', parentId: 'parent-investmentDuration-error',  submitted: isFormSubmit }"
                                    [errorMessage]="'investmentKnowledge.errors.withdrawTypeError'"
                                    [templateType]="RADIO_TYPES.SMALL"
                                    [(ngModel)]="investmentKnowledge.ilInvestmentTimeHorizon"
                                    [hasDescription]="false"
                                    >
                    </app-radio-card>
                    </div>

                </section>

                <!-- Not Deleted because it may be useful for any future use. Commenting the below code as per Mandatory BIL Project.
                     As per Story : https://jira.bmogc.net/browse/MWB-12536 -->

                <!-- <section>

                    <h1 id="withdrawTypeDescribe" translate="investmentKnowledge.titleWithdrawOptions"></h1>
                    <div  class="form-wrapper">
                    <app-radio-card [options]="investmentKnoelwdgeData.ilInvestmentTimeHorizonForWithDraw"
                                    [form]="investmentKnowledgeForm"
                                    ngDefaultControl
                                    [dataAna]="'mywealth:onboarding:risk:withdraw'"
                                    [section]="'withdrawTypeDescribe'"
                                    [name]="'withdrawType'"
                                    [imageSource]="'assets/img/withdraw/moneyBag'"
                                    [accessErrorId]="{ id: 'withdrawType-error', parentId: 'parent-withdrawType-error',  submitted: isFormSubmit }"
                                    [errorMessage]="'investmentKnowledge.errors.withdrawTypeError'"
                                    [templateType]="RADIO_TYPES.SMALL"
                                    [(ngModel)]="investmentKnowledge.investmentTimeHorizonForWithDraw">
                    </app-radio-card>
                     </div>

                </section> -->

            </div>

        </section>

        <section id="investmentKnowledgeButtonSection">

            <app-form-buttons  [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater"
                formName="investmentKnowledge" [backRoute]="APP_ROUTES.financial_details"></app-form-buttons>

        </section>

    </div>

</form>
