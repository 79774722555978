<div class="bmo-modal contact-us-modal">

    <a role="button"
       attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
       attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
       class="btn-icon btn-close tooltip-show"
       href="javascript://"
       (click)="hideContactUsEnabled()">
       <span class="icon icon-large close-grey"></span>
    </a>

    <div class="form-wrapper no-margin">
        <div class="form-row flex-maxis-center content-center">
            <img class="artwork-header" src="assets/img/artwork-specialist.svg"  />
        </div>
        <div class="form-row flex-maxis-center content-center">
            <div class="form-col">
                <h2 translate="contactUsModal.title"></h2>
                <p [innerHtml]="'contactUsModal.description1Title' | translate | safeHtml"></p>
                <p [innerHtml]="'contactUsModal.description2Content' | translate | safeHtml"></p>                
            </div>
        </div>
        <div class="form-row form-page-buttons">
            <button class="primary element-center" type="submit" translate="button.close" (click)="hideContactUsEnabled()"></button>
        </div>
    </div>

</div>