import { PartyService } from './../../shared/service/party.service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { ApplicationService } from '../../shared/service/application.service';
import { FormService } from '../../shared/service/form.service';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../../shared/utils/utils.class';
import {
  APP_ROUTES, SUCCESS_RESPONSE, URL, productTypeAD, productTypeSD, BRANCH_REFERRAL_ROLE,
  ANALYTICS
} from '../../config/app-config.constants';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { Subscription } from 'rxjs';

export class BranchReferral {
  investmentPlatform: any = null; //

  isBranchReferral: boolean = null; // isBranchReferral
  branchPersonnel: string = null; // designationCode
  fpName: string = null; // fpName
  fsmName: string = null; // fsmName
  EIN: string = null; // ein
  FPCode: string = null; // fpCode
  fpPhone: string = null; // fpPhone
  fsmPhone: string = null; // fsmPhone
  transit: string = null; // transit

  checkCertify: boolean = null;
}

@Component({
  selector: 'app-branch-referral',
  templateUrl: './branch-referral.component.html'
})

export class BranchReferralComponent implements OnInit, OnDestroy, AfterViewInit {

  public APP_ROUTES = APP_ROUTES;
  public branchReferralObj: BranchReferral;
  public isFormSubmit: Boolean = false;

  public branchPersonnelList: any;
  public isReferenceCallFailed: Boolean = false;
  public appObject: any;
  public url;

  // For accessibility purposes
  @ViewChild('investmentPlatformCtrl', {static: true}) investmentPlatformCtrl: UntypedFormControl;
  @ViewChild('selectBranchPersonnelCtrl', {static: true}) selectBranchPersonnelCtrl: UntypedFormControl;

  @ViewChild('nameCtrl', {static: true}) nameCtrl: UntypedFormControl;
  @ViewChild('EINCtrl', {static: true}) EINCtrl: UntypedFormControl;
  @ViewChild('FPCodeCtrl', {static: true}) FPCodeCtrl: UntypedFormControl;
  @ViewChild('phoneCtrl', {static: true}) phoneCtrl: UntypedFormControl;
  @ViewChild('TransitCtrl', {static: true}) TransitCtrl: UntypedFormControl;

  @ViewChild('fsmNameCtrl', {static: true}) fsmNameCtrl: UntypedFormControl;
  @ViewChild('fsmEINCtrl', {static: true}) fsmEINCtrl: UntypedFormControl;
  @ViewChild('fsmPhoneCtrl', {static: true}) fsmPhoneCtrl: UntypedFormControl;
  @ViewChild('fsmTransitCtrl', {static: true}) fsmTransitCtrl: UntypedFormControl;

  @ViewChild('fpNameCtrl', {static: true}) fpNameCtrl: UntypedFormControl;
  @ViewChild('fpColCodeCtrl', {static: true}) fpColCodeCtrl: UntypedFormControl;
  @ViewChild('fpPhoneCtrl', {static: true}) fpPhoneCtrl: UntypedFormControl;

  @ViewChild('branchReferralForm', {static: true}) branchReferralForm: NgForm;
  cmsMessage: any;
  cmsMessageTitle: any;
  cmsParamExtSubscription: Subscription;


  constructor(private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private titleService: Title,
    private winRef: WindowRefService,
    private formService: FormService,
    private partyService: PartyService) {

    this.branchReferralObj = new BranchReferral();
    this.formService.forms.branchReferral.isVisited = true;
    this.formService.setActiveComponent('branchReferral', this);
    this.url = '/' + APP_ROUTES.branch_referral;
  }

  async ngOnInit() {

    if (Utils.getIsBranchAssisted() === true) { // In case of branch-assisted

      // Call Retrieve Application
      await this.applicationService.retrieveApplicationOnRefresh().subscribe(
        (response: any) => {
          if (response.status === 200) {
            Utils.setApplicationObject(response.body);
            this.appObject = Utils.getApplicationObject();

            const applicationNumber = Utils.getApplicationObject().applicationNumber;
            Utils.setApplicationNumber(applicationNumber);

            this.prefillBranchReferral();
          }
        },
        error => { });
    }

    this.applicationService.setResumeLink(true);

    /**Title */
    this.titleService.setTitle(Utils.getPageTitleBranchReferral());

    /**GET BRANCH PERSONNEL DESIGNATION DROP DOWN VALUES FROM RDM */
    this.applicationService.fetchReferences(this.route);
    if (this.applicationService.appReferences !== undefined) {
      if (this.applicationService.appReferences?.isReferenceCallFailed) {
        this.isReferenceCallFailed = true;
      } else {
        this.branchPersonnelList = this.applicationService.appReferences.references.branchReferralDesignation;
      }
    }

    /** REMOVE 'BMO Nesbitt Burns' FROM THE LIST*/
    this.branchPersonnelList.forEach((item, index) => {
      if (item.code === '4') {
        this.branchPersonnelList.splice(index, 1);
      }
    });

    /*set active form*/
    this.formService.setActiveForm(this.branchReferralForm);

    /**Get Form Data */
    // already visited form and now visiting back again (Pre=population)
    if (this.formService.forms.branchReferral.data != null) {
      this.branchReferralObj = this.formService.getFormData('branchReferral');
    }

    this.cmsParamExtSubscription = this.applicationService.cmsParametersExt$.subscribe(params => {
      //console.log(Utils.showCMSMessage)
      //console.log(Utils.getCmsMessageObject())
      if (Utils.showCMSMessage && Utils.getCmsMessageObject()) {
        // this.cmsMessage = Utils.getCmsMessageObject()['selfServe']['messageBody'];
        // this.cmsMessageTitle = Utils.getCmsMessageObject()['selfServe']['messageTitle'];
        this.cmsMessage = Utils.getCmsMessageObject()['branchAssisted']['messageBody'];
        this.cmsMessageTitle = Utils.getCmsMessageObject()['branchAssisted']['messageTitle'];
      }
    })

    // this.applicationService.getCMSMessage().then(response => {
    //   if (response && Utils.showCMSMessage) {
    //     this.cmsMessage = response['branchAssisted']['messageBody'];
    //     this.cmsMessageTitle = response['branchAssisted']['messageTitle'];
    //   }
    // });

    setTimeout(() => {
      this.setGTM();
    }, 200);
  }

  prefillBranchReferral() {

    const branchReferralData = this.appObject.branchReferral;

    this.branchReferralObj.investmentPlatform = this.appObject.applicationSubProduct ? this.appObject.applicationSubProduct : null;
    this.branchReferralObj.isBranchReferral = branchReferralData.isBranchReferral ? branchReferralData.isBranchReferral : null;
    this.branchReferralObj.fpName = branchReferralData.fpName ? branchReferralData.fpName : null;
    this.branchReferralObj.fsmName = branchReferralData.fsmName ? branchReferralData.fsmName : null;
    this.branchReferralObj.EIN = branchReferralData.ein ? branchReferralData.ein : null;

    this.branchReferralObj.FPCode = null;
    this.branchReferralObj.branchPersonnel = '';  // No prefill for role
    // this.branchReferralObj.FPCode = branchReferralData.fpCode ? branchReferralData.fpCode : null;
    this.branchReferralObj.fpPhone = branchReferralData.fpPhone ? branchReferralData.fpPhone : null;
    this.branchReferralObj.fsmPhone = branchReferralData.fsmPhone ? branchReferralData.fsmPhone : null;
    this.branchReferralObj.transit = branchReferralData.transit ? branchReferralData.transit : null;

    // For drop-down value selection - NO PREFILL FOR BRANCH-REFERRAL DROPDOWN
    /*if (this.checkForFSM() && this.CheckForFP()) {
      this.branchReferralObj.branchPersonnel = '103';
    } else {
      if (this.CheckForFP()) {
        this.branchReferralObj.branchPersonnel = '102';
      } else if (this.branchReferralObj.fsmName) {
        this.branchReferralObj.branchPersonnel = '101';
      }
    }*/

    this.formService.setFormData('branchReferral', this.branchReferralObj);
    this.formService.getPayload().setBranchReferral(this.branchReferralObj);
    this.formService.getPayload().setBranchBMORelationship(true); // If Branch Assisted  set BMO Relationship to true
  }

  checkForFSM() {
    if (this.branchReferralObj.fsmName || this.branchReferralObj.EIN || this.branchReferralObj.fsmPhone) {
      return true;
    }
    return false;
  }

  CheckForFP() {
    if (this.branchReferralObj.fpName || this.branchReferralObj.fpPhone) {
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
    this.analytics.setApplicationFlow(Utils.isBranchSDMultiAccountFlow()?ANALYTICS.APPLICATION_FLOW.MULTIASSISTED:ANALYTICS.APPLICATION_FLOW.ASSISTED);
    this.analytics.setPageState(this.url);

  }

  resetComponentObjectAttr() {
  }

  onCloseCMS() {
    Utils.showCMSMessage = false;
    this.cmsMessage = false;
  }

  ngOnDestroy() {
    this.cmsParamExtSubscription.unsubscribe();
    // keep information of the form in service when form/ component is destroyed
    // for later data retrieval
    // this.formService.setFormData('branchReferral', this.branchReferralObj);
  }

  isError(ctrl: any) {
    return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
  }

  updateBranchPersonnelDesignation(e) {
    this.branchPersonnelList.forEach(item => {
      if (item.code === e.srcElement.value) {
        this.branchReferralObj.branchPersonnel = item.code;
      }
    });
  }

  onSubmit() {
    this.isFormSubmit = true;

    if (!this.branchReferralForm.valid) {
      this.setFocus();

    } else {
      this.clearFields();
      const  updatedOLAPXML = this.mapOLAPxml();
      if (this.appObject && this.appObject.olapPayload && updatedOLAPXML) {
        this.appObject.olapPayload = updatedOLAPXML;
        Utils.setApplicationObject(this.appObject); // Adding updated XML to Application Object
        this.formService.getPayload().setOLAPPayload(updatedOLAPXML); // Adding updated XML to Payload object
      }

      this.formService.forms.branchReferral.isCompleted = true;
      this.formService.setFormData('branchReferral', this.branchReferralObj);
      this.formService.getPayload().setBranchReferral(this.branchReferralObj);

      // Set subproduct
      if (this.branchReferralObj.investmentPlatform === productTypeAD) {
        Utils.setSubProduct(productTypeAD);
      } else {
        Utils.setSubProduct(productTypeSD);
      }
      this.applicationService.save(this.formService.getPayload())
        .then(res => {
          this.applicationService.saveAndcontinue(null, APP_ROUTES.select_account);
        })
        .catch(err => {
        });
    }
  }

  mapOLAPxml() // Map Updated Branch referral  Information
  {
    const appObject = Utils.getApplicationObject();
    let OLAPData = (appObject) ? appObject.olapPayload : '';
    // console.log(OLAPData);
    if(OLAPData){
    OLAPData = OLAPData.replace(/<FSMBranchMgrName>[\s\S]*?<\/FSMBranchMgrName>/,
      '<FSMBranchMgrName>' + this.branchReferralObj.fsmName + '<\/FSMBranchMgrName>');
    OLAPData = OLAPData.replace(/<FSMBranchMgrEINNumber>[\s\S]*?<\/FSMBranchMgrEINNumber>/,
      '<FSMBranchMgrEINNumber>' + this.branchReferralObj.EIN + '<\/FSMBranchMgrEINNumber>');
    OLAPData = OLAPData.replace(/<FSMBranchMgrTelephone>[\s\S]*?<\/FSMBranchMgrTelephone>/,
      '<FSMBranchMgrTelephone>' + this.branchReferralObj.fsmPhone + '<\/FSMBranchMgrTelephone>');
    OLAPData = OLAPData.replace(/<FSMBranchMgrTransitNumber>[\s\S]*?<\/FSMBranchMgrTransitNumber>/,
      '<FSMBranchMgrTransitNumber>' + this.branchReferralObj.transit + '<\/FSMBranchMgrTransitNumber>');
    OLAPData = OLAPData.replace(/<FPName>[\s\S]*?<\/FPName>/,
      '<FPName>' + this.branchReferralObj.fpName + '<\/FPName>');
    OLAPData = OLAPData.replace(/<FPTelephone>[\s\S]*?<\/FPTelephone>/,
      '<FPTelephone>' + this.branchReferralObj.fpPhone + '<\/FPTelephone>');
    OLAPData = OLAPData.replace(/<TransitNumber>[\s\S]*?<\/TransitNumber>/,
      '<TransitNumber>' + this.branchReferralObj.FPCode + '<\/TransitNumber>');
    }

    return OLAPData;

  }

  // For Accessibilty purpose, setFocus() method was created.
  setFocus() {

    // setTimeout() method has been used since DOM needs to wait for its handlers.
    setTimeout(() => {

      if (this.investmentPlatformCtrl.errors) {
        if (Utils.isSubProductAD()) {
          document.getElementById('selfPlatform').focus();
        } else {
          document.getElementById('advicePlatform').focus();
        }

        // personal-or-financial
      } else if (this.nameCtrl.errors) {
        document.getElementById('name').focus();

      } else if (this.EINCtrl.errors) {
        document.getElementById('ein').focus();

      } else if (this.FPCodeCtrl.errors) {
        document.getElementById('fpCode').focus();

      } else if (this.phoneCtrl.errors) {
        document.getElementById('phone').focus();

      } else if (this.TransitCtrl.errors) {
        document.getElementById('transit').focus();

        // personal-and-financial
      } else if (this.fsmNameCtrl.errors) {
        document.getElementById('fsmName').focus();

      } else if (this.fsmEINCtrl.errors) {
        document.getElementById('fsmEIN').focus();

      } else if (this.fsmPhoneCtrl.errors) {
        document.getElementById('fsmPhone').focus();

      } else if (this.fsmTransitCtrl.errors) {
        document.getElementById('fsmTransit').focus();

      } else if (this.fpNameCtrl.errors) {
        document.getElementById('fpName').focus();

      } else if (this.fpColCodeCtrl.errors) {
        document.getElementById('fpColCode').focus();

      } else if (this.fpPhoneCtrl.errors) {
        document.getElementById('fpPhone').focus();

      }

    }, 250);

  }

  clearFields() {
    if (this.branchReferralObj.branchPersonnel === BRANCH_REFERRAL_ROLE.BRANCH_MANAGER) { // clear FP info
      this.branchReferralObj.FPCode = '';
      this.branchReferralObj.fpPhone = '';
      this.branchReferralObj.fpName = '';
    }

    if (this.branchReferralObj.branchPersonnel === BRANCH_REFERRAL_ROLE.FINANCIAL_PLANNER) { // clear BM info
      this.branchReferralObj.fsmName = '';
      this.branchReferralObj.fsmPhone = '';
      this.branchReferralObj.EIN = '';
    }
  }

  setGTM() {

    const gtmData: GTMDataLayer = {
      'event': ANALYTICS.BMOEVENT,
      'vURL': Utils.getvURL(),
      'vPageTitles': Utils.getPageTitleBranchReferral(),
      'category': ANALYTICS.category,
      'action': ANALYTICS.ACTIONS.BRANCH_REFERRAL,
      'label': ANALYTICS.label,
      'flow': ANALYTICS.flow,
      'prodName': Utils.accountName,
      'prodNumber': Utils.accountNumber,
      'prodTotalNumber': 1,
      'originalLocation': document.location.protocol + '//' + document.location.hostname +
        document.location.pathname + document.location.search,
      'language': Utils.getLanguage(),
      's_PPvid': ANALYTICS.branch_referral,
      'applicationId': '',
      'signerId': '',
      'packageId': '',
      'assisetdApp': false,
      'customerSource': Utils.getSubProduct(),
      'netWorth': Utils.netWorth
    };

    this.winRef.nativeWindow.dataLayer.push(gtmData);

  }


}
