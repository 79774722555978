/**
 * @purpose: bmo-relationship
 * @ created: Abhinav Jain
 * @ last updated by: Abhinav Jain (4 June, 2018)
 */

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, OnChanges } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { FormService } from '../../shared/service/form.service';
import { EmploymentInfoService } from '../../shared/service/employment-info.service';
import { APP_ROUTES, APP_CONFIG, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX } from '../../config/app-config.constants';
import { inject } from '@angular/core/testing';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { some, includes } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';


export class BmoRelationship {
    bmoRelationship = null;
    bmoRelationshipClient = null;
    cardNumber = null;
    accountNumber = null;
    bmoProduct = null;
    bmoProductLabel = null;
    bmoProductCode = null;
    hasBMODebitCard = null;
    debitCardNumber = null;
    newDebitCardYesNo = null;
    isApplicantExistingClient = null;  //  MWB-7050
    accessUS = null;
    isApplicantExistingPBClient = null;
    consentToAccountLink=null;
}


@Component({
    selector: 'app-bmo-relationship',
    templateUrl: './bmo-relationship.component.html',
    providers: [EmploymentInfoService]
})
export class BmoRelationshipComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    public APP_ROUTES = APP_ROUTES;
    @ViewChild('bmoRelationshipForm', { static: true }) bmoRelationshipForm: NgForm;

    // For accessibility purposes
    @ViewChild('bmoDebitCardCtrl', { static: true }) bmoDebitCardCtrl: UntypedFormControl;
    @ViewChild('debitCardNumberCtrl', { static: true }) debitCardNumberCtrl: UntypedFormControl;
    @ViewChild('bmoDebitCardLinkCtrl', { static: true }) bmoDebitCardLinkCtrl: UntypedFormControl;
    @ViewChild('bmoRelationshipsCtrl', { static: true }) bmoRelationshipsCtrl: UntypedFormControl;
    @ViewChild('selectBmoRelationshipCtrl', { static: true }) selectBmoRelationshipCtrl: UntypedFormControl;
    @ViewChild('cardNumberCtrl', { static: true }) cardNumberCtrl: UntypedFormControl;
    @ViewChild('accountNumberCtrl', { static: true }) accountNumberCtrl: UntypedFormControl;
    @ViewChild('bmoAccessUSCtrl', { static: true }) bmoAccessUSCtrl: UntypedFormControl;
    @ViewChild('privateBankingCtrl', { static: true }) privateBankingCtrl: UntypedFormControl;
    @ViewChild('accountLinkConsentCntrl',{static:true}) accountLinkConsentCntrl: UntypedFormControl;


    public bmoRelationship: BmoRelationship = new BmoRelationship();
    public isFormSubmit: boolean;
    public BILAssisted = false;
    public BranchAssisted: boolean;
    public isCashAccount: Boolean = false;
    public isRRSPAccount: Boolean = false;
    public isTFSAAccount: Boolean = false;
    public isSpousalRRSP: Boolean = false;
    public isMargin: Boolean = false;
    public isRRIF: Boolean = false;
    public isSubProductAD: Boolean = null;
    public bmoProducts: any;
    public UI_CONSTANTS: any; // so that it can be used in template
    public disableFinishLater;
    public showFinishLater;
    appNumber;
    public accountSetup;
    criticalError: boolean;
    public url: any;
    public isCeightSixEnabled: boolean = false;

    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    private onExitApplicationPerformSaveSubscriber;

    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        private winRef: WindowRefService,
        public formService: FormService,
        private empInfoService: EmploymentInfoService,
        private route: ActivatedRoute,
        public analytics: AnalyticsService
    ) {

        this.bmoRelationship = new BmoRelationship();
        this.isFormSubmit = false;
        this.formService.forms.bmoRelationship.isVisited = true;
        /** To perform save operation on finish later*/
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'BRFrm') {
                    this.onFinishLater();
                }
            });
            this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
                if (value) {
                    this.onFinishLater(true);
                }
            });
        this.formService.setActiveComponent('bmoRelationship', this);
        this.UI_CONSTANTS = APP_CONFIG.UI_CONSTANTS_MAP;


        this.url = '/' + APP_ROUTES.bmo_relationship;
    }

    ngAfterViewInit() {

        if(Utils.getIsBranchAssisted() === true && Utils.isCash() != true) {

            setTimeout(() => {
                if (this.formService.forms.bmoRelationship.data != null && this.formService.forms.bmoRelationship.data.isApplicantExistingPBClient != null
                    && this.formService.forms.bmoRelationship.isVisited === true) {
                    this.bmoRelationship.isApplicantExistingPBClient =( this.bmoRelationship.isApplicantExistingPBClient == true || this.bmoRelationship.isApplicantExistingPBClient == 'Yes') ? 'Yes' : 'No'
                }

            }, 600);

        }

        // this.analytics.setState('step 12');
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('BMORelationship');
        this.checkFormValidityOnSignOut();
    }

    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    ngOnInit() {
        this.isCeightSixEnabled = this.applicationService.getC86FeatureFlag();
        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }
        this.isSubProductAD = Utils.isSubProductAD();
        this.BranchAssisted = Utils.getIsBranchAssisted();
        this.BILAssisted = Utils.getIsBranchReferral();
        this.titleService.setTitle(Utils.getPageTitleBMORelationship());

        this.applicationService.fetchReferences(this.route);

        if (this.applicationService.appReferences !== undefined) {
            this.bmoProducts = this.applicationService.appReferences.references.bmoClientRelationshipType;
        }

        if (this.formService.forms.bmoRelationship.data != null) {
            // already visited form and now visiting back again
            this.bmoRelationship = this.formService.getFormData('bmoRelationship');


            // credit card number should not be retained, when navigated into, using back or
            // continue button...  but only when staying in the form and changing the dropdown values.
            // to the backend always accountnumber is sent, even if credit card, the same field is used.
            if (this.bmoRelationship.bmoProduct &&
                this.bmoRelationship.bmoProduct === this.UI_CONSTANTS.BMO_PRODUCTS.debit_card) {
                this.bmoRelationship.cardNumber = this.bmoRelationship.accountNumber;
                this.bmoRelationship.accountNumber = '';
            } else {
                this.bmoRelationship.cardNumber = '';
            }
        } else {
            this.bmoRelationship = new BmoRelationship();  // coming to form for the first time
        }
        this.isMargin =this.applicationService.isMargin();
        this.isCashAccount = this.applicationService.isCashAccount();
        // setting this to understand current user selected account
        if (Utils.selectedMultiAccounts){
            Utils.selectedMultiAccounts.forEach(accounts => {
                const multiAccount = accounts;
                if(multiAccount.type == "100391") {
                    this.isRRSPAccount=true;
                }else if(multiAccount.type == "100562") {
                    this.isTFSAAccount =  true;
                }else if(multiAccount.type == "100391-SPOUSAL") {
                    this.isSpousalRRSP = true;
                } else if(multiAccount.type == "100555"){
                    this.isRRIF = true;
                }

            })
        } else {
           // this.isCashAccount = (Utils.isCash() || Utils.isMargin()) ? true : false;
            this.isRRSPAccount = Utils.isRRSP() ? true : false;
            this.isTFSAAccount = Utils.isTFSA() ? true : false;
            this.isSpousalRRSP = Utils.isSpousalRRSP() ? true : false;
           // this.isMargin = Utils.isMargin() ? true : false;
            this.isRRIF = Utils.isRIF() ? true : false;
        }

        if (this.isCashAccount) {
            this.filterProductsForCashAccount();
        }
        this.formService.setActiveForm(this.bmoRelationshipForm);

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        setTimeout(() => {
            this.setGTM();
        }, 200);

    }


    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {

                if (this.bmoRelationshipForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }

    // filtering entity names for Cash account as it doesnt include BMO Debit Card/Bank Card
    filterProductsForCashAccount() {
        for (const item of this.bmoProducts) {
            if (item.code === '1') {
                this.bmoProducts.splice(0, 1);
            }
        }
    }

    fetchProductLabel(value) {
        for (const item of this.bmoProducts) {
            if (item.code === value) {
                this.bmoRelationship.bmoProductLabel = item.label;
                this.bmoRelationship.bmoProductCode = item.code;
            }
        }
    }


    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {

        // console.log('onFinishLater: ');
        if (!this.bmoRelationshipForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.formatDataModel();
        if (this.bmoRelationshipForm.valid) {

            this.formService.forms.bmoRelationship.isCompleted = true;
            this.formService.setFormData('bmoRelationship', this.bmoRelationship);
        }


        // To prepare payload with PI , CI, EI, FI , FD RD, Cons and BR
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
        const consents = this.formService.getFormData('consents');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
        this.formService.getPayload().setConsents(consents);
        this.setPrivateBankingDetails();


        if (Utils.isSpousalRRSP()) {

            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.bmoRelationship.isCompleted) {
            const emptyBmoRelationship = new BmoRelationship();
            this.formService.getPayload().setBMORelationship(emptyBmoRelationship);
        } else {

            if (this.bmoRelationshipForm.valid) {
                if ((Utils.getIsBranchReferral() === true && (Utils.isCash() === true || Utils.isMargin() === true )) || (Utils.getIsBranchReferral() === false)) {
                    this.formService.getPayload().setBMORelationship(this.bmoRelationship);
                }
            }
        }


        // console.log('payload', this.formService.getPayload());

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });

    }

    saveOnSignOut() {
        this.formatDataModel();
        this.formService.forms.bmoRelationship.isCompleted = true;
        this.formService.setFormData('bmoRelationship', this.bmoRelationship);


        // To prepare payload with PI , CI, EI, FI , FD RD, Cons and BR
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
        const consents = this.formService.getFormData('consents');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
        this.formService.getPayload().setConsents(consents);
        this.setPrivateBankingDetails();


        if (Utils.isSpousalRRSP()) {

            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.bmoRelationship.isCompleted) {
            const emptyBmoRelationship = new BmoRelationship();
            this.formService.getPayload().setBMORelationship(emptyBmoRelationship);
        } else {
            if (this.bmoRelationshipForm.valid) {
                if ((Utils.getIsBranchReferral() === true &&  (Utils.isCash() === true || Utils.isMargin() === true )) || (Utils.getIsBranchReferral() === false)) {
                    this.formService.getPayload().setBMORelationship(this.bmoRelationship);
                }
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }

    debitCardToggle(model) {
        this.analytics.triggerView('hasBMODebitCard' + model);
        if (this.isCashAccount == true && model == 'No' && this.bmoRelationship.bmoProduct === APP_CONFIG.UI_CONSTANTS_MAP.BMO_PRODUCTS.debit_card) {
            this.bmoRelationship.bmoProduct = null;
        }
    }



    setPrivateBankingDetails() {
        if (this.BranchAssisted && !this.isSubProductAD) {
            this.formService.getPayload().setPrivateBankingClient(this.bmoRelationship.isApplicantExistingPBClient);
        }
        else {
            this.formService.getPayload().setPrivateBankingClient(null);
        }

    }


    onSubmit() {

        this.isFormSubmit = true;

        // For accessibility purposes, set focus on the invalid element.
        if (!this.bmoRelationshipForm.valid) {
            this.setFocus();
        }

        // validate the bmoRelationshipfields here
        if (this.bmoRelationshipForm.valid) {

            this.formatDataModel();
            this.formService.forms.bmoRelationship.isCompleted = true;
            this.formService.setFormData('bmoRelationship', this.bmoRelationship);


            // To prepare payload with PI , CI, EI, FI , FD RD, Cons and BR
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');
            const familyInformation = this.formService.getFormData('familyInformation');
            const financialInformation = this.formService.getFormData('financialInformation');
            const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
            const consents = this.formService.getFormData('consents');

            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(familyInformation);
            this.formService.getPayload().setFinancialInformation(financialInformation);
            this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
            this.formService.getPayload().setConsents(consents);
            this.setPrivateBankingDetails();

            if ((Utils.getIsBranchReferral() === true &&  (Utils.isCash() === true || Utils.isMargin() === true )) || (Utils.getIsBranchReferral() === false)) {
                this.formService.getPayload().setBMORelationship(this.bmoRelationship);
            }

            if (Utils.isSpousalRRSP()) {

                this.formService.getPayload().setSpousalRRSP(familyInformation);
            }

            if (Utils.getIsInSession() === false) {

                this.applicationService.saveAndcontinue(this.bmoRelationship, APP_ROUTES.account_password);

            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {

                            this.applicationService.saveAndcontinue(this.bmoRelationship, APP_ROUTES.account_password);

                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });

            }

        } else {
            // console.log('form is invalid');
        }
    }

    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {

        // setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.bmoRelationshipForm);
           }, 250)

    }


    formatDataModel() {
        if (!Utils.isCash()) {
            if (this.bmoRelationship.bmoRelationshipClient === 'No') {
                this.bmoRelationship = new BmoRelationship(); 
                this.bmoRelationship.bmoRelationshipClient = 'No';
                this.bmoRelationship.cardNumber = null;
            } else if (this.bmoRelationship.bmoProduct === APP_CONFIG.UI_CONSTANTS_MAP.BMO_PRODUCTS.debit_card) {
                // always to the backend, we only send the accountNumber
                // when the form is submitted only the details submitted to backend needs to be retained
                // for eg, we should NOT retain both card number and account number, when the user navigates
                // back after submitting...
                this.bmoRelationship.accountNumber = this.bmoRelationship.cardNumber;
            }
        }       
    }

    ngOnDestroy() {

        if (this.bmoRelationship.hasBMODebitCard === 'No') {
            this.bmoRelationship.debitCardNumber = null;
        }

        // Even when we use "back" button the formatDataModel(cardNumber to accountNumber swap) has to be
        // taken care.. this may be required until we implement service call on each page..to get payload.
        this.formatDataModel();

        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('bmoRelationship', this.bmoRelationship);

        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    setGTM() {

        const gtmDataLayerForBMO: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleBMORelationship(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.BMO_RELATIONSHIP,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.bmo_relationship,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForBMO);
        // console.log(this.winRef.nativeWindow.dataLayer);

    }

}

