import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ApplicationService } from "../../shared/service/application.service";
import { Utils } from "../../shared/utils/utils.class";
import { FormService } from "../../shared/service/form.service";
import { APP_ROUTES } from "../../config/app-config.constants";
import { CdbOlbService } from './../../shared/service/cdb-olb.service';
import { SetExpectationObject } from '../../sections/set-expectation/set-expectation.component';
@Injectable({
  providedIn: 'root'
})
export class CanOlbActivateGuard implements CanActivate {
  public setExpectation: any;

  constructor(private applicationService: ApplicationService , public cdbOlbService : CdbOlbService, private formService: FormService){}

  canActivate() {

     this.applicationService.olbInprogress().subscribe((data: any) => {
     // Utils.setIsOLBUser(true);
     this.applicationService.setResumeLink(false);
        if(data?.workFLowStatusResponse && data.workFLowStatusResponse[0]?.ilNum ) {
            this.cdbOlbService.olbInprogressData(data);
            return true;
        } else {
            if(Utils.getIsLMEUser()) {
                this.applicationService.gotoSetExpectationOrSelectAccount(APP_ROUTES.set_expectation , Utils.getLanguage() ,  Utils.getSubProduct());
            } else if(this.applicationService.getCsaCfrAttorneyFeatureFlag() === true) {
                this.setExpectation = new SetExpectationObject();
                this.setExpectation = this.formService.getPayload()?.screenerQuestions;
                if(this.setExpectation?.isBmoRightForMe !== undefined && this.setExpectation?.isBmoRightForMe !== null && this.setExpectation?.isBmoRightForMe !== ''){
                    this.gotoSelectAccount();
                } else {
                    this.applicationService.gotoSetExpectationOrSelectAccount(APP_ROUTES.set_expectation , Utils.getLanguage() ,  Utils.getSubProduct());
                }
            } else {
                this.gotoSelectAccount();
            }
        }
     }, error => {
       this.cdbOlbService.emitSystemCriticalError(true);
      });

    return true;
 }
    gotoSelectAccount() {
      this.setExpectation = new SetExpectationObject();
      this.setExpectation = this.formService.getPayload()?.screenerQuestions;
      if(this.setExpectation?.isBmoRightForMe !== undefined && this.setExpectation?.isBmoRightForMe !== null && this.setExpectation?.isBmoRightForMe !== ''){
          this.formService.forms.setExpectation.isCompleted = true;
          this.formService.forms.setExpectation.isVisited = true;
      }
      Utils.setIsSelectAccountClicked(true);
      this.applicationService.saveAndcontinue(Utils.getExpectationFormData(), APP_ROUTES.select_account);
    }

  }
