
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigResolverInterface } from './config-resolver.interface';
import { AnalyticsService } from '../../../shared/service/analytics.service';

@Injectable()
export class ConfigResolverService<T> implements ConfigResolverInterface<T> {

    config: T;

    constructor(private http: HttpClient,
        private analyticsService : AnalyticsService) {
    }

    setConfig(url: string) {
        return this.http.get(url)
            .toPromise()
            .then(response => {
                console.log('SET CONFIG BEFORE RETURN')
                this.config = <T>response;
                return this.config;
            })
            .catch(e => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: 'true', apiCallType: "setConfig", 
                    apiCallResponseCode: e.error?.errorInfo ? e.status?.toString() + "-" + e.error?.errorInfo.errorCode.toString() : e.status?.toString()

                });
                return observableThrowError(e);
            });
    }

    getConfig(): T {
        if ( this.config ) {
            return this.config;
        }
        // throw Error('Cannot fetch application configuration');
        return null;
    }

}
