import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'alphabeticalSort'
})


export class AlphabeticalSortPipe implements PipeTransform {

 transform(array: any, args: string): any {
    array.sort((a: any, b: any) => {
      if (a[args] < b[args]) {
        return -1;
      } else if (a[args] > b[args]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}
// considering an array of objects being passed to this pipe and sorting will be done based any of the property of the pipe.
//  this.currentOccupation = [
//      {code: 'LM', label: 'Lightbulb maker' },
//   { code: 'CR', label: 'Coffee roaster' },
///   ];
