import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PhoneNumberComponent {

  @Input() group: string;
  @Input() model;
  @Input() key;
  @Input() id;
  @Input() isFormSubmit;

  constructor() { }

}
