<!-- SHOW this DIV for Personal/Senior Banker and Branch Manager OR FP -->
<!-- --- -->
<div *ngIf="branchReferralObj.branchPersonnel == '101' || branchReferralObj.branchPersonnel == '102'"
    class="form-wrapper">

    <div class="form-row">

        <!-- FSM Name-->
        <div *ngIf="branchReferralObj.branchPersonnel == '101'" class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">
                    <input id="fsmName" name="fsmName" type="text" minlength="2"
                        data-ana="mywealth:onboarding:branch referral:name" maxlength="50" InputValidator="ct22"
                        [(ngModel)]="branchReferralObj.fsmName" #fsmNameCtrl="ngModel"
                        [appErrorAccess]="{id:'fsmName-error',  submitted: isFormSubmit}" required />
                    <label class="slide-label" for="fsmName"
                        [ngClass]="{'error-field':isError(fsmNameCtrl), 'active-label':fsmNameCtrl.value}">
                        <span class="label-text" translate="branchReferral.labels.name"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="fsmName-error" class="error-message" *ngIf="isError(fsmNameCtrl)">
                <span [innerHtml]="'branchReferral.errors.nameError'| translate | safeHtml"></span>
            </div>
        </div>

        <!-- FP Name-->
        <div *ngIf="branchReferralObj.branchPersonnel == '102'" class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">
                    <input id="fpName" name="fpName" type="text" minlength="2"
                        data-ana="mywealth:onboarding:branch referral:name" maxlength="50" InputValidator="ct22"
                        [(ngModel)]="branchReferralObj.fpName" #fpNameCtrl="ngModel"
                        [appErrorAccess]="{id:'fpName-error',  submitted: isFormSubmit}" required />
                    <label class="slide-label" for="fpName"
                        [ngClass]="{'error-field':isError(fpNameCtrl), 'active-label':fpNameCtrl.value}">
                        <span class="label-text" translate="branchReferral.labels.name"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="fpName-error" class="error-message" *ngIf="isError(fpNameCtrl)">
                <span [innerHtml]="'branchReferral.errors.nameError'| translate | safeHtml"></span>
            </div>
        </div>

        <!-- Show this Column for EIN -->
        <!-- --- -->
        <div *ngIf="branchReferralObj.branchPersonnel == '101'" class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">
                    <input id="ein" name="ein" type="text" minlength="9"
                        data-ana=" mywealth:onboarding:branch referral:ein" maxlength="9" InputValidator="ct21"
                        [(ngModel)]="branchReferralObj.EIN" #EINCtrl="ngModel"
                        [appErrorAccess]="{id:'ein-error',  submitted: isFormSubmit}" required />
                    <label class="slide-label" for="ein"
                        [ngClass]="{'error-field':isError(EINCtrl), 'active-label':EINCtrl.value}">
                        <span class="label-text" [innerHtml]="'branchReferral.labels.ein'| translate | safeHtml"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="ein-error" class="error-message" *ngIf="isError(EINCtrl)">
                <span [innerHtml]="'branchReferral.errors.einError'| translate | safeHtml"></span>
            </div>
        </div>

        <!-- Show this Column for FP code -->
        <!-- --- -->
        <div *ngIf="branchReferralObj.branchPersonnel == '102'" class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">
                    <input id="fpCode" name="fpCode" type="text" minlength="3" maxlength="3" InputValidator="ct4"
                        [(ngModel)]="branchReferralObj.FPCode" #FPCodeCtrl="ngModel"
                        [appErrorAccess]="{id:'fpCode-error',  submitted: isFormSubmit}" required />
                    <label class="slide-label" for="fpCode"
                        [ngClass]="{'error-field':isError(FPCodeCtrl), 'active-label':FPCodeCtrl.value}">
                        <span class="label-text"
                            [innerHtml]="'branchReferral.labels.fpCode'| translate | safeHtml"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="fpCode-error" class="error-message" *ngIf="isError(FPCodeCtrl)">
                <span [innerHtml]="'branchReferral.errors.fpCodeError'| translate | safeHtml"></span>
            </div>
        </div>

    </div>

    <div class="form-row">

        <!-- FSM Phone-->
        <div *ngIf="branchReferralObj.branchPersonnel == '101'" class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">
                    <input id="fsmPhone" name="fsmPhone" appPhoneNumber type="tel" minlength="10"
                        data-ana="mywealth:onboarding:branch referral:phone" maxlength="10" InputValidator="ct3"
                        [(ngModel)]="branchReferralObj.fsmPhone" #fsmPhoneCtrl="ngModel"
                        [appErrorAccess]="{id:'fsmPhone-error',  submitted: isFormSubmit}" required />
                    <label class="slide-label" for="fsmPhone"
                        [ngClass]="{'error-field':isError(fsmPhoneCtrl), 'active-label':fsmPhoneCtrl.value}">
                        <span class="label-text" translate="branchReferral.labels.phone"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="fsmPhone-error" class="error-message" *ngIf="isError(fsmPhoneCtrl)">
                <span [innerHtml]="'branchReferral.errors.phoneNumberError'| translate | safeHtml"></span>
            </div>
        </div>

        <!-- FP Phone-->
        <div *ngIf="branchReferralObj.branchPersonnel == '102'" class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">
                    <input id="fpPhone" name="fpPhone" appPhoneNumber type="tel" minlength="10" maxlength="10"
                        data-ana="mywealth:onboarding:branch referral:phone" InputValidator="ct3"
                        [(ngModel)]="branchReferralObj.fpPhone" #fpPhoneCtrl="ngModel"
                        [appErrorAccess]="{id:'fpPhone-error',  submitted: isFormSubmit}" required />
                    <label class="slide-label" for="fpPhone"
                        [ngClass]="{'error-field':isError(fpPhoneCtrl), 'active-label':fpPhoneCtrl.value}">
                        <span class="label-text" translate="branchReferral.labels.phone"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="fpPhone-error" class="error-message" *ngIf="isError(fpPhoneCtrl)">
                <span [innerHtml]="'branchReferral.errors.phoneNumberError'| translate | safeHtml"></span>
            </div>
        </div>

        <!--Transit-->
        <div class="flex-50 form-col">
            <div class="form-field">
                <div class="form-label-group">
                    <input id="transit" name="transit" type="text" minlength="4" maxlength="5" InputValidator="ct21"
                        [(ngModel)]="branchReferralObj.transit" #TransitCtrl="ngModel"
                        [appErrorAccess]="{id:'transit-error',  submitted: isFormSubmit}" required />
                    <label class="slide-label" for="transit"
                        [ngClass]="{'error-field':isError(TransitCtrl), 'active-label':TransitCtrl.value}">
                        <span class="label-text" translate="branchReferral.labels.transit"></span>
                    </label>
                </div>
            </div>
            <div role="alert" id="transit-error" class="error-message" *ngIf="isError(TransitCtrl)">
                <span [innerHtml]="'branchReferral.errors.transitError'| translate | safeHtml"></span>
            </div>
        </div>
    </div>

</div>