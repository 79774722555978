import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from './../../shared/service/form.service';
import { Component, OnInit } from '@angular/core';
import { Utils } from '../../shared/utils/utils.class';
import { LANGUAGE_ENGLISH, LANGUAGE_FRENCH, BMO_TECHNICAL_ERROR_OLAP_URL } from '../../config/app-config.constants';
import { ApplicationService } from './../../shared/service/application.service';
@Component({
    selector: 'app-technical-error',
    templateUrl: './technical-error.component.html'
})

export class TechnicalErrorComponent implements OnInit {
    public lang: string = null;

    constructor(private formService: FormService,
        private translate: TranslateService,
        public titleService: Title,
        private applicationService: ApplicationService

    ) {
        this.lang = Utils.getLanguage();
        if (this.lang !== undefined || this.lang !== null) {
            this.translate.use(this.lang);
        } else {
            this.translate.use(LANGUAGE_ENGLISH);
        }
    }

    ngOnInit() {
        this.applicationService.setResumeLink(false);
        this.applicationService.showHelpOnHeaders(false);
    }
    onNavigateSelfDirectAdviceDirect() {
        if (this.lang === LANGUAGE_ENGLISH) {
            window.open(BMO_TECHNICAL_ERROR_OLAP_URL.EN, '_blank');
        } else if (this.lang === LANGUAGE_FRENCH) {
            window.open(BMO_TECHNICAL_ERROR_OLAP_URL.FR, '_blank');
        }
    }
}
