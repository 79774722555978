import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_ROUTES, ANALYTICS } from '../../config/app-config.constants';
import { Utils } from '../../shared/utils/utils.class';
import { isObject } from 'lodash';

@Injectable()
export class AnalyticsService {
    _windowRef: any;
    public currentStep: any;
    public currentPage: any;

    constructor(private windowRef: WindowRefService) {
    }

    
    nativeWindow() {
      return window;
    }

    setProductName(productName) {
        if (Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow()){
            this.windowRef.nativeWindow.BMODATA.productName = productName;
        }else {
            this.windowRef.nativeWindow.BMODATA.productName = [];
            this.windowRef.nativeWindow.BMODATA.productName.push(productName);
        }
    }

    setProductTotalNumber(productTotalNumber){
        this.windowRef.nativeWindow.BMODATA.productTotalNumber = productTotalNumber;
    }

    setDowngradeStatus(downgradeStatus) {
        this.windowRef.nativeWindow.BMODATA.downgradeStatus = downgradeStatus;
    }

    setCancelDowngrade() {
        this.satelliteTrack('cancelDowngrade');
    }

    setAcceptDowngrade() {
        this.satelliteTrack('eSign');
    }

    setApplicationFlow(applicationFlow) {
      this.windowRef.nativeWindow.BMODATA.applicationFlow = applicationFlow;
    }

    setPageName(pageName) {
        this.windowRef.nativeWindow.BMODATA.pageName  = pageName ;
    }

    setProductNumber(productNumber) {
        if (Utils.isMultiAccountFlow() ||Utils.isBranchSDMultiAccountFlow()){
            this.windowRef.nativeWindow.BMODATA.productNumber = productNumber;
        } else{
            this.windowRef.nativeWindow.BMODATA.productNumber = [];
            this.windowRef.nativeWindow.BMODATA.productNumber.push(productNumber);
        }
    }

    setState(state) {
        if (this.windowRef.nativeWindow.BMODATA) {
            this.windowRef.nativeWindow.BMODATA.state = state;
            this.satelliteTrack('pageLoad');
        }
    }

    setCustomerType(type) {
        if (this.windowRef.nativeWindow.BMODATA) {
            this.windowRef.nativeWindow.BMODATA.customerType = type;
        }
    }
    
    setTrackingCode(icid) {
        if (this.windowRef.nativeWindow.BMODATA) {
            this.windowRef.nativeWindow.BMODATA.icid = icid;
        }
    }

    setApplicationID(id) {
        if (this.windowRef.nativeWindow.BMODATA) {
            this.windowRef.nativeWindow.BMODATA.applicationID = id;
        }
    }
    // MWB-10100
    setCustomerSource(customerSource) {
        // customerSource is string defining where customer came from
        if (this.windowRef.nativeWindow.BMODATA) {
            this.windowRef.nativeWindow.BMODATA.customerSource = customerSource;
        }
    }

    setEsign() {
        this.satelliteTrack('eSign');
    }

    satelliteTrack(name: string){
        this.windowRef.nativeWindow?._satellite?.track(name);
    }


    setOpenOverlay(event) {
        this.satelliteTrack('overlayOpened');
        this.windowRef.nativeWindow.BMODATA.overlayOpened = event;
    }

    setCloseOverlay(event) {
        this.satelliteTrack('closeOverlay');
        this.windowRef.nativeWindow.BMODATA.closeOverlay = event;
    }

    setapiCallDetails(event) {
        let eventObj:any =new Object;
        eventObj = event;
        if (isObject(event)) {
            eventObj.trackingEnabled = null;
            const msgObj = Utils.getCmsMessageObject();
            eventObj.trackingEnabled = !!(msgObj?.trackingEnabled);
            event = eventObj;
        }
        this.windowRef.nativeWindow.BMODATA.apiCallDetails = [];
        this.windowRef.nativeWindow.BMODATA.apiCallDetails = event;
    }


    setPageState(route) {

        if (route.charAt(0) === '/') {
            route = route.slice(1);
        }

		this.setapiCallDetails({apiCallMade: 'false', apiCallType: '', apiCallResponseCode: '' });
      
        switch (route) {
            case APP_ROUTES.branch_referral: {this.currentStep = ''; this.setPageName(ANALYTICS.PAGE_NAME.BRANCH_REFERRAL);  break; }
            case APP_ROUTES.select_account: {this.currentStep = '1' ; this.setPageName(ANALYTICS.PAGE_NAME.SELECT_ACCOUNT);  break; }
            case APP_ROUTES.olb_inprogress: { this.currentStep = '1'; this.setPageName(ANALYTICS.PAGE_NAME.OLB_INPROGRESS_APPLICATION);  break; }
            case APP_ROUTES.account_detail: {this.currentStep = '2' ; this.setPageName(ANALYTICS.PAGE_NAME.ACCOUNT_DETAILS);  break; }
            case APP_ROUTES.branch_save_progress: {this.currentStep = '' ; this.setPageName(ANALYTICS.PAGE_NAME.SAVE_LATER);  break; }
            case APP_ROUTES.set_expectation: {this.currentStep = '3' ; this.setPageName(ANALYTICS.PAGE_NAME.SET_EXPECTATIONS);  break; }
            case APP_ROUTES.personal_information: {this.currentStep = '4' ; this.setPageName(ANALYTICS.PAGE_NAME.PERSONAL_INFO);  break; }
            case APP_ROUTES.contact_information: {this.currentStep = '5'; this.setPageName(ANALYTICS.PAGE_NAME.CONTACT_INFO);  break; }
            case APP_ROUTES.employment_information: {this.currentStep = '6'; this.setPageName(ANALYTICS.PAGE_NAME.EMPLOYMENT_INFO);  break; }
            case APP_ROUTES.family_information: {this.currentStep = '7'; this.setPageName(ANALYTICS.PAGE_NAME.FAMILY_INFO);  break; }
            case APP_ROUTES.beneficiary: {this.currentStep = ''; this.setPageName(ANALYTICS.PAGE_NAME.BENEFICIARY);  break; }
            case APP_ROUTES.withdrawal: {this.currentStep = ''; this.setPageName(ANALYTICS.PAGE_NAME.WITHDRAWALS);  break; }
            // case APP_ROUTES.celebration: {this.currentStep = '8'; this.setPageName(ANALYTICS.PAGE_NAME.HALF_WAY_INFO);  break; } //  MWB-13833
            case APP_ROUTES.financial_details: {this.currentStep = '9'; this.setPageName(ANALYTICS.PAGE_NAME.FINANCIAL_DETAILS);  break; }
            case APP_ROUTES.investment_knowledge: {this.currentStep = '9a'; this.setPageName(ANALYTICS.PAGE_NAME.INVESTMENT_KNOWLEDGE);  break; }
            case APP_ROUTES.riskAndReturn:{ this.currentStep = '9b'; this.setPageName(ANALYTICS.PAGE_NAME.RISK_AND_RETURN);  break; }
            case APP_ROUTES.investor_profile: { this.currentStep = '9c'; this.setPageName(ANALYTICS.PAGE_NAME.INVESTOR_PROFILE);  break; }
            case APP_ROUTES.regulatory_disclosures: { this.currentStep = '10'; this.setPageName(ANALYTICS.PAGE_NAME.REGULATORY_DISCLOUSRES);  break; }
            case APP_ROUTES.consents: { this.currentStep = '11'; this.setPageName(ANALYTICS.PAGE_NAME.CONSENTS_DETAILS);  break; }
            case APP_ROUTES.bmo_relationship: { this.currentStep = '12'; this.setPageName(ANALYTICS.PAGE_NAME.BMO_RELATIONSHIP);  break; }
            case APP_ROUTES.account_password: { this.currentStep = '12'; this.setPageName(ANALYTICS.PAGE_NAME.ACCOUNT_PASSWORD);  break; }
            case APP_ROUTES.account_access: { this.currentStep = '13'; this.setPageName(ANALYTICS.PAGE_NAME.ACCOUNT_ACCESS);  break; }
            case APP_ROUTES.review: { this.currentStep = '14'; this.setPageName(ANALYTICS.PAGE_NAME.REVIEW_DETAILS);  break; }
            case APP_ROUTES.celebration_review: { this.currentStep = '15'; this.setPageName(ANALYTICS.PAGE_NAME.THANK_YOU);  break; }
            case APP_ROUTES.terms_conditions: { this.currentStep = '16'; this.setPageName(ANALYTICS.PAGE_NAME.TERMS_CONDITIONS);  break; }
            case APP_ROUTES.application_approval_downgrade: { this.currentStep = '16a'; this.setPageName(ANALYTICS.PAGE_NAME.APPLICATION_DOWNGRADE);  break; }
            case APP_ROUTES.branch_esignature: { this.currentStep = ''; this.setPageName(ANALYTICS.PAGE_NAME.ESIGN_LANDING);  break; }
            case APP_ROUTES.next_steps: { this.currentStep = '17'; this.setPageName(ANALYTICS.PAGE_NAME.NEXT_STEP);  break; }
            case APP_ROUTES.document_upload: { this.currentStep = '22'; this.setPageName(ANALYTICS.PAGE_NAME.DOCUMENT_UPLOAD);  break; }
            case APP_ROUTES.technical_error_session: { this.currentStep = ''; this.setPageName(ANALYTICS.PAGE_NAME.TECHNICAL_ERROR_SESSION);  break; }      
        }

        if((window as any).s){
            this.setState('step ' + this.currentStep);
        }else {
            setTimeout(() => {
                this.setState('step ' + this.currentStep);
            }, 2000);
        }
    }

    triggerView(triggerName) {
        this._windowRef = this.nativeWindow();
        // console.log('win::', this._windowRef);
        let tN = triggerName;
        if (/_/.test(triggerName)) {
          tN = triggerName.replace(/_/g, '-');
        }
        const trigger = 'persTarget' + tN;
        // console.log('adobeTarget,triggerName,trigger', triggerName, tN, trigger);
        if (
          typeof this._windowRef['adobe'] !== 'undefined' &&
          this._windowRef['adobe'].target &&
          typeof this._windowRef['adobe'].target.triggerView === 'function'
        ) {
          this._windowRef['adobe'].target.triggerView(trigger);
          if (typeof this._windowRef[trigger] === 'undefined') {
            this._windowRef[trigger] = true;
          }
        }
        //for testing purpose added the below console. As they requested
        //console.log("Trigger view Name" , triggerName)
      }





}
