<!-- <app-flood-light></app-flood-light> -->

<form #regulatoryDisclosuresForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section id="regulatoryDisclosuresMainSection">

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="regulatoryDisclosuresForm.invalid && isFormSubmit" translateTag="regulatoryDisclosures"></app-invalid-form>

            <div role="alert" class="alert alert-danger" *ngIf="isReferenceCallFailed">
                <p>
                    <strong translate="finalReview.errors.criticalError"></strong><br />
                    <span translate="finalReview.errors.criticalErrorDescription"></span>
                </p>
            </div>

            <div class="aoda-off-screen-element" aria-atomic="true">
                <div id="deleteButton" style="display: none;" role="alert" aria-live="polite">{{companyName}} {{
                    'regulatoryDisclosures.ariaLabels.deleteButton' | translate }}</div>
                <div id="emptyDeleteButton" style="display: none;" role="alert" aria-live="polite">{{
                    'regulatoryDisclosures.ariaLabels.emptyDeleteButton' | translate }}</div>
                <div id="addInsideRelationships" style="display: none;" role="alert" aria-live="polite">{{
                    'regulatoryDisclosures.ariaLabels.addInsideRelationships' | translate }} </div>
                <div id="addInsideRelationshipsSpouse" style="display: none;" role="alert" aria-live="polite">{{
                    'regulatoryDisclosures.ariaLabels.addInsideRelationshipsSpouse' | translate }} </div>
                <div id="addsignificantShareholderRelationships" style="display: none;" role="alert" aria-live="polite">{{
                    'regulatoryDisclosures.ariaLabels.addsignificantShareholderRelationships' | translate }} </div>
                <div id="addControlRelationship" style="display: none;" role="alert" aria-live="polite">{{
                    'regulatoryDisclosures.ariaLabels.addControlRelationship' | translate }} </div>
            </div>

            <section *ngIf="(adviceDirect || (selfDirected && (isCash || isMargin)))" id="intendedUseOfAccountSection">

                <h1 *ngIf="!isMultiAccountOpenFlow && !isBranchSDMultiAccountOpenFlow" translate="regulatoryDisclosures.titleSectionIntendedUseOfAccount"></h1>
                <p *ngIf="!isMultiAccountOpenFlow && !isBranchSDMultiAccountOpenFlow" translate="regulatoryDisclosures.descriptionSectionIntendedUseOfAccount"></p>
                <h1 *ngIf="isMultiAccountOpenFlow || isBranchSDMultiAccountOpenFlow" translate="regulatoryDisclosures.titleSectionIntendedUseOfAccountMAO"></h1>
                <p *ngIf="isMultiAccountOpenFlow || isBranchSDMultiAccountOpenFlow" translate="regulatoryDisclosures.descriptionSectionIntendedUseOfAccountMAO"></p>

                <div class="form-wrapper">
                    <div class="form-row">
                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <select name="selectIntendedUseOfAccount" id="selectIntendedUseOfAccount" data-ana="mywealth:onboarding:regulatory:account use"
                                            (change)="fetchAccountCode($event.target.value)" [(ngModel)]="regulatoryDisclosure.intendedUseOfAccount"
                                            #selectIntendedUseOfAccountCtrl="ngModel"  [appErrorAccess]="{ id: 'selectIntendedUseOfAccount-error' , submitted: isFormSubmit}"
                                            required>

                                        <option *ngFor="let intendedAccount of intendedAccounts" [value]="intendedAccount.code" [selected]="intendedAccount.code">
                                            {{intendedAccount.label}}
                                        </option>
                                    </select>
                                    <label class="slide-label" for="selectIntendedUseOfAccount" [ngClass]="{'error-field': selectIntendedUseOfAccountCtrl.errors && selectIntendedUseOfAccountCtrl.touched || (isFormSubmit && selectIntendedUseOfAccountCtrl.errors), 'active-label': selectIntendedUseOfAccountCtrl.value}">
                                        <span class="label-text" translate="regulatoryDisclosures.labels.intendedUseOfAccount"></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div role="alert" class="error-message"  id="selectIntendedUseOfAccount-error"  *ngIf="selectIntendedUseOfAccountCtrl.errors && selectIntendedUseOfAccountCtrl.touched || (isFormSubmit && selectIntendedUseOfAccountCtrl.errors)">
                            <span translate="regulatoryDisclosures.errors.selectIntendedAccountUse"></span>
                        </div>
                    </div>
                </div>

            </section>

            <section id="investmentSection">

                <h1 *ngIf="!isNewContentR5Enabled" translate="regulatoryDisclosures.titleSectionInvestment"></h1>
                <h1 *ngIf="isNewContentR5Enabled" translate="regulatoryDisclosures.titleSectionInvestment01"></h1>
                <p *ngIf="!isNewContentR5Enabled"  class="add-margin b48px"translate="regulatoryDisclosures.descriptionSectionInvestment"></p>
                <p *ngIf="isNewContentR5Enabled" class="add-margin b48px" translate="regulatoryDisclosures.descriptionSectionInvestment01"></p>

                <div class="header-contextual-help">
                    <h2 *ngIf="!isNewContentR5Enabled" class="display-inline" translate="regulatoryDisclosures.titleSubsectionRelationships"></h2>
                    <h2 *ngIf="isNewContentR5Enabled" class="display-inline" translate="regulatoryDisclosures.titleSubsectionRelationships01"></h2>

                    <!-- R5 <app-contextual-help id="SubsectionRelationshipsToolTip"class="button-wrapper va-middle" content="{{!isNewContentR5Enabled ? ('regulatoryDisclosures.contextualHelp.relationships') : ('regulatoryDisclosures.contextualHelp.investmentIndustryRelationships')}}"
                    arialabel="regulatoryDisclosures.ariaLabels.relationshipsLabel" inputDataAna="wealth:onboarding:tool tip:SubsectionRelationships">
                    </app-contextual-help> -->

                    <app-contextual-help id="SubsectionRelationshipsToolTip" class="button-wrapper va-middle" content="{{!isCIROEnabled ? ('regulatoryDisclosures.contextualHelp.investmentIndustryRelationships01') : ('regulatoryDisclosures.contextualHelp.investmentIndustryRelationships')}}"
                    arialabel="regulatoryDisclosures.ariaLabels.relationshipsLabel" inputDataAna="wealth:onboarding:tool tip:SubsectionRelationships">
                    </app-contextual-help>
                </div>

                <p *ngIf="!isNewContentR5Enabled" id="ariaRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionRelationship' | translate"></p>
                <p *ngIf="isCIROEnabled" id="ariaRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionRelationship' | translate"></p>
                <p *ngIf="!isCIROEnabled" id="ariaRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionRelationship01' | translate"></p>


                <div class="form-wrapper">
                    <div role="radiogroup" aria-labelledby="ariaRelationships" id='parent-ariaRelationships-error' class="form-row">

                        <div class="flex-elements form-col"    >

                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors), 'active-label': relationShipCtrl.value}">
                                <input id="yesRelationships" type="radio" name="radioRelationships" data-ana="mywealth:onboarding:regulatory:relationships"
                                       value="Yes" [(ngModel)]="regulatoryDisclosure.relationShip" #relationShipCtrl="ngModel" (click)="analytics.triggerView('RelationshipsYes')"
                                        [appErrorAccess]="{ id:'ariaRelationships-error', parentId: 'parent-ariaRelationships-error' , submitted: isFormSubmit}" required />
                                <label class="prefix" for="yesRelationships" translate="regulatoryDisclosures.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors), 'active-label': relationShipCtrl.value}">
                                <input id="noRelationships" type="radio" name="radioRelationships" data-ana="mywealth:onboarding:regulatory:relationships"
                                       value="No" [(ngModel)]="regulatoryDisclosure.relationShip" #relationShipCtrl="ngModel" (click)="analytics.triggerView('RelationshipsNo')"
                                       [appErrorAccess]="{ id: 'ariaRelationships-error', parentId: 'parent-ariaRelationships-error' , submitted: isFormSubmit}" required />
                                <label class="prefix" for="noRelationships" translate="regulatoryDisclosures.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="ariaRelationships-error" *ngIf="relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors)">
                                <span *ngIf="isCIROEnabled" translate="regulatoryDisclosures.errors.relationshipsSelectError"></span>
                                <span *ngIf="!isCIROEnabled" translate="regulatoryDisclosures.errors.relationshipsSelectError01"></span>
                            </div>
                        </div>

                    </div>
                    <div class="form-row" *ngIf="relationShipCtrl.value =='Yes'">

                        <div class="flex-40 form-col">
                            <div class="form-field">
                                <div class="form-label-group"  >
                                    <select id="selectMemberFirm"  [appErrorAccess]="{ id: 'selectMemberFirm-error' , submitted: isFormSubmit}" name="selectMemberFirm" [(ngModel)]="regulatoryDisclosure.memberFromLabel" #selectMemberFirmCtrl="ngModel"
                                            (change)="fetchMemberCode($event.target.value)" required>

                                        <option *ngFor="let members of memberFirm" [value]="applicationService.isCurrentLangFrench() ? members.label : members.code"
                                                [selected]="members.selected">
                                            {{members.label}}
                                        </option>
                                    </select>
                                    <label [ngClass]="{'error-field': selectMemberFirmCtrl.errors && selectMemberFirmCtrl.touched || (isFormSubmit && selectMemberFirmCtrl.errors), 'active-label': selectMemberFirmCtrl.value}"
                                           class="slide-label" for="selectMemberFirm">
                                        <span class="label-text" translate="regulatoryDisclosures.labels.selectMemberFirm"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message"  id="selectMemberFirm-error"  *ngIf="selectMemberFirmCtrl.errors && selectMemberFirmCtrl.touched || (isFormSubmit && selectMemberFirmCtrl.errors)">
                                <span *ngIf="isCIROEnabled" translate="regulatoryDisclosures.errors.relashionshipsMemberFirmError"></span>
                                <span *ngIf="!isCIROEnabled" translate="regulatoryDisclosures.errors.relashionshipsMemberFirmError01"></span>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="header-contextual-help">
                    <h2 class="display-inline" translate="regulatoryDisclosures.titleSubsectionInsiderRelationships"></h2>

                    <p *ngIf="!isNewContentR5Enabled" id="ariaInsiderRelationship" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionInsiderRelationship' | translate"></p>
                    <p *ngIf="isNewContentR5Enabled" id="ariaInsiderRelationship" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionInsiderRelationship01' | translate"></p>

                    <div class="more-info-wrapper">
                        <a role="button" id="ariaInsiderRelationshipsLearnMoreOpen"
                            aria-controls="ariaInsiderRelationshipsControl"
                            aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                            attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreOpenInsiderRelationship' | translate }}">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>
                        <div class="more-info-content" id="ariaInsiderRelationshipsControl">
                            <div [innerHTML]="'regulatoryDisclosures.contextualHelp.insideRelationships' | translate"></div>
                            <p>
                                <a role="button" id="ariaInsiderRelationshipsLearnMoreClose" aria-controls="ariaInsiderRelationshipsControl" aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreCloseInsiderRelationship' | translate }}">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-wrapper">

                    <div role="radiogroup" aria-labelledby="ariaInsiderRelationship"   id='parent-ariaInsiderRelationship-error' class="form-row">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': insiderRelationShipCtrl.errors && insiderRelationShipCtrl.touched || (isFormSubmit && insiderRelationShipCtrl.errors), 'active-label': insiderRelationShipCtrl.value}">
                                <input id="yesInsiderRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:insider" name="radioInsiderRelationship"
                                       value="Yes" [(ngModel)]="regulatoryDisclosure.insiderRelationship" #insiderRelationShipCtrl="ngModel" (click)="analytics.triggerView('InsiderRelationShipYes')"
                                       [appErrorAccess]="{ id: 'ariaInsiderRelationship-error', parentId: 'parent-ariaInsiderRelationship-error', submitted: isFormSubmit}"  required />
                                <label class="prefix" for="yesInsiderRelationships" translate="regulatoryDisclosures.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': insiderRelationShipCtrl.errors && insiderRelationShipCtrl.touched || (isFormSubmit && insiderRelationShipCtrl.errors), 'active-label': insiderRelationShipCtrl.value}">
                                <input id="noInsiderRelationships" type="radio" name="radioInsiderRelationship" data-ana="mywealth:onboarding:regulatory:insider"
                                       value="No" [(ngModel)]="regulatoryDisclosure.insiderRelationship" #insiderRelationShipCtrl="ngModel" (click)="analytics.triggerView('InsiderRelationShipNo')"
                                       [appErrorAccess]="{ id: 'ariaInsiderRelationship-error', parentId: 'parent-ariaInsiderRelationship-error', submitted: isFormSubmit}" required />
                                <label class="prefix" for="noInsiderRelationships" translate="regulatoryDisclosures.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id='ariaInsiderRelationship-error' *ngIf="insiderRelationShipCtrl.errors && insiderRelationShipCtrl.touched || (isFormSubmit && insiderRelationShipCtrl.errors)">
                                <span translate="regulatoryDisclosures.errors.insiderRelationshipsSelectError"></span>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="insiderRelationShipCtrl.value =='Yes'">

                        <div class="form-row" *ngFor="let insiderApplicant of insiderRelationArr">

                            <div class="flex-40 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input [name]="['companyNameIR']+insiderApplicant" [attr.id]="['companyNameIR']+insiderApplicant" type="text" [(ngModel)]="regulatoryDisclosure['companyNameIR'+insiderApplicant]"
                                               #companyNameIRCtrl="ngModel" [value]="regulatoryDisclosure['companyNameIR'+insiderApplicant]"
                                               appContextualSearch [contextualSearch]="{id: ['companySymbolIR']+insiderApplicant, type:'name'}"
                                               (input)="setCustomError('name', 'IR', insiderApplicant)" (compSymbolValue)="updateCompanySymbol($event, 'IR', insiderApplicant)"
                                               (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'IR', insiderApplicant)" autocomplete="off"
                                               [appErrorAccess]="{ id: ['companyNameIR']+insiderApplicant+['-error'], submitted: isFormSubmit}" required />
                                        <label [ngClass]="{'error-field': companyNameIRCtrl.errors && companyNameIRCtrl.touched || (isFormSubmit && companyNameIRCtrl.errors), 'active-label': companyNameIRCtrl.value}"
                                               class="slide-label" [attr.for]="['companyNameIR']+insiderApplicant">
                                            <span class="label-text" translate="regulatoryDisclosures.labels.companyName"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" class="error-message" 	[id]="['companyNameIR']+insiderApplicant+['-error']" *ngIf="companyNameIRCtrl.errors && companyNameIRCtrl.touched && !companyNameIRCtrl.errors.inValidCompanyName  || (isFormSubmit && companyNameIRCtrl.errors)  && !companyNameIRCtrl.errors.inValidCompanyName">
                                    <span translate="regulatoryDisclosures.errors.companyNameRequired"></span>
                                </div>
                                <div role="alert" class="error-message" 	[id]="['companyNameIR']+insiderApplicant+['-error']" *ngIf="companyNameIRCtrl.errors && companyNameIRCtrl.errors.inValidCompanyName && companyNameIRCtrl.value">
                                    <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                </div>

                            </div>
                            <div class="flex-40 form-col">
                                <div class="form-field">
                                    <div class="form-label-group" >
                                        <input [name]="['companySymbolIR']+insiderApplicant" [attr.id]="['companySymbolIR']+insiderApplicant" type="text" [(ngModel)]="regulatoryDisclosure['companySymbolIR'+insiderApplicant]"
                                               #companySymbolIRCtrl="ngModel" [value]="regulatoryDisclosure['companySymbolIR'+insiderApplicant]"
                                               appContextualSearch (input)="setCustomError('symbol','IR', insiderApplicant)" [contextualSearch]="{id:['companyNameIR']+insiderApplicant, type:'symbol'}"
                                               (compSymbolValue)="updateCompanySymbol($event, 'IR', insiderApplicant)" (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'IR', insiderApplicant)"
                                               autocomplete="off"  [appErrorAccess]="{ id: ['companySymbolIR']+insiderApplicant+['-error'], submitted: isFormSubmit}" required />
                                        <label [ngClass]="{'error-field': companySymbolIRCtrl.errors && companySymbolIRCtrl.touched || (isFormSubmit && companySymbolIRCtrl.errors), 'active-label': companySymbolIRCtrl.value}"
                                               class="slide-label" [attr.for]="['companySymbolIR']+insiderApplicant">
                                            <span class="label-text" translate="regulatoryDisclosures.labels.companySymbol"></span>
                                        </label>
                                    </div>
                                    <div class="form-field-button" *ngIf="insiderApplicant>0">
                                        <a role="button"
                                        id="insiderApplicantRemoveBtn{{insiderApplicant+1}}"
                                           attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.trashButton' | translate }} {{companyNameIRCtrl.value}}"
                                           attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}"
                                           class="btn-icon tooltip-show"
                                           href="javascript://"
                                           (click)="removeInsiderRelationship(insiderApplicant, companySymbolIRCtrl.value)">
                                            <span class="icon icon-large trash-blue"></span>
                                        </a>
                                    </div>
                                </div>

                                <div class="error-message" [id]="['companySymbolIR']+insiderApplicant+['-error']" *ngIf="companySymbolIRCtrl.errors && companySymbolIRCtrl.touched && !companySymbolIRCtrl.errors.inValidCompanySymbol || (isFormSubmit && companySymbolIRCtrl.errors) && !companySymbolIRCtrl.errors.inValidCompanySymbol">
                                    <span translate="regulatoryDisclosures.errors.tickerSymbolRequired"></span>
                                </div>
                                <div class="error-message" [id]="['companySymbolIR']+insiderApplicant+['-error']" *ngIf="companySymbolIRCtrl.errors && companySymbolIRCtrl.errors.inValidCompanySymbol && companySymbolIRCtrl.value && companySymbolIRCtrl.errors.inValidCompanySymbol">
                                    <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                </div>
                            </div>

                        </div>
                        <div class="form-row button-row" *ngIf="insiderRelationArr.length < maxCompanyNameAndSymbol">

                            <a role="button" id="insiderRelationshipAddMoreButton" class="btn-icon" href="javascript://" (click)="addInsiderRelationship()" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.addInsiderRelationshipLabel' | translate }}">
                                <span class="icon icon-large plus-circle-blue"></span>
                                <span class="btn-icon-text" translate="regulatoryDisclosures.labels.addMore"></span>
                            </a>

                        </div>

                    </div>
                </div>

                 <!--MWB-15086 Remove Reporter Insider Question -->
                <div *ngIf="insiderRelationShipCtrl.value =='Yes' && isDataRationalization4Enabled !== true">

                    <div class="header-contextual-help">
                        <p>
                            <span id="ariaInsiderRelationshipSpouse" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionInsiderRelationshipSpouse' | translate"></span>
                            <app-contextual-help id="SubsectionInsiderRelationshipSpouseToolTip" class="button-wrapper va-middle" content="regulatoryDisclosures.contextualHelp.insiderRelationshipSpouse"
                                                 arialabel="regulatoryDisclosures.ariaLabels.insiderRelationshipSpouseLabel"
                                                 inputDataAna="wealth:onboarding:tool tip:SubsectionInsiderRelationshipSpouse">
                            </app-contextual-help>
                        </p>
                    </div>

                    <div class="form-wrapper">

                        <div role="radiogroup" aria-labelledby="ariaInsiderRelationshipSpouse"   id='parent-yesInsiderRelationshipsSpouse-error' class="form-row">

                            <div class="flex-elements form-col" >
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': spouseInsiderRelationshipCtrl.errors && spouseInsiderRelationshipCtrl.touched || (isFormSubmit && spouseInsiderRelationshipCtrl.errors), 'active-label': spouseInsiderRelationshipCtrl.value}">
                                    <input id="yesInsiderRelationshipsSpouse" type="radio" name="insiderRelationshipSpouse" value="Yes" [(ngModel)]="regulatoryDisclosure.spouseInsiderRelationship"
                                           (change)="verifySpouseInsiderRelationArr($event)" #spouseInsiderRelationshipCtrl="ngModel" (click)="analytics.triggerView('SpouseInsiderRelationshipYes')"
                                           [appErrorAccess]="{ id: 'yesInsiderRelationshipsSpouse-error', parentId: 'parent-yesInsiderRelationshipsSpouse-error', submitted: isFormSubmit}" required />
                                    <label class="prefix" for="yesInsiderRelationshipsSpouse" translate="regulatoryDisclosures.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': spouseInsiderRelationshipCtrl.errors && spouseInsiderRelationshipCtrl.touched || (isFormSubmit && spouseInsiderRelationshipCtrl.errors), 'active-label': spouseInsiderRelationshipCtrl.value}">
                                    <input id="noInsiderRelationshipsSpouse" type="radio" name="insiderRelationshipSpouse" value="No" [(ngModel)]="regulatoryDisclosure.spouseInsiderRelationship"
                                    (change)="verifySpouseInsiderRelationArr($event)" #spouseInsiderRelationshipCtrl="ngModel"  (click)="analytics.triggerView('SpouseInsiderRelationshipNo')"
                                    [appErrorAccess]="{ id: 'yesInsiderRelationshipsSpouse-error', parentId: 'parent-yesInsiderRelationshipsSpouse-error', submitted: isFormSubmit}" required />
                                    <label class="prefix" for="noInsiderRelationshipsSpouse" translate="regulatoryDisclosures.labels.no"></label>
                                </div>
                                <div role="alert" class="error-message" id="yesInsiderRelationshipsSpouse-error" *ngIf="spouseInsiderRelationshipCtrl.errors && spouseInsiderRelationshipCtrl.touched || (isFormSubmit && spouseInsiderRelationshipCtrl.errors)">
                                    <span translate="regulatoryDisclosures.errors.reportingInsideSelectError"></span>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="spouseInsiderRelationshipCtrl.value =='Yes' ">

                            <div class="form-row" *ngFor="let spouseApplicant of spouseInsiderRelationArr">

                                <div class="flex-40 form-col">
                                    <div class="form-field" >
                                        <div class="form-label-group">
                                            <input [name]="['companyNameIRS']+spouseApplicant"
                                            [attr.id]="['companyNameIRS']+spouseApplicant"
                                            type="text"
                                            [(ngModel)]="regulatoryDisclosure['companyNameIRS'+spouseApplicant]"
                                            #companyNameIRSCtrl="ngModel" [value]="regulatoryDisclosure['companyNameIRS'+spouseApplicant]"
                                                   appContextualSearch (input)="setCustomError('name','IRS', spouseApplicant)" [contextualSearch]="{id: ['companySymbolIRS']+spouseApplicant, type:'name'}"
                                                   (compSymbolValue)="updateCompanySymbol($event, 'IRS', spouseApplicant)" (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'IRS', spouseApplicant)"
                                                   autocomplete="off"   [appErrorAccess]="{ id: ['companyNameIRS']+spouseApplicant+['-error'], submitted: isFormSubmit}"required />
                                            <label [ngClass]="{'error-field': companyNameIRSCtrl.errors && companyNameIRSCtrl.touched || (isFormSubmit && companyNameIRSCtrl.errors), 'active-label': companyNameIRSCtrl.value}"
                                                   class="slide-label" [attr.for]="['companyNameIRS']+spouseApplicant">
                                                <span class="label-text" translate="regulatoryDisclosures.labels.companyName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companyNameIRS']+spouseApplicant+['-error']" *ngIf="companyNameIRSCtrl.errors && companyNameIRSCtrl.touched && !companyNameIRSCtrl.errors.inValidCompanyName || (isFormSubmit && companyNameIRSCtrl.errors) && !companyNameIRSCtrl.errors.inValidCompanyName">
                                        <span translate="regulatoryDisclosures.errors.companyNameRequired"></span>
                                    </div>
                                    <div role="alert" class="error-message"  [id]="['companyNameIRS']+spouseApplicant+['-error']" *ngIf="companyNameIRSCtrl.errors && companyNameIRSCtrl.value && companyNameIRSCtrl.errors.inValidCompanyName">
                                        <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                    </div>
                                </div>
                                <div class="flex-40 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group"  >
                                            <input [name]="['companySymbolIRS']+spouseApplicant" [attr.id]="['companySymbolIRS']+spouseApplicant" type="text" [(ngModel)]="regulatoryDisclosure['companySymbolIRS'+spouseApplicant]"
                                                   #companySymbolIRSCtrl="ngModel" [value]="regulatoryDisclosure['companySymbolIRS'+spouseApplicant]"
                                                   appContextualSearch (input)="setCustomError('symbol','IRS', spouseApplicant)"
                                                   [contextualSearch]="{id: ['companyNameIRS']+spouseApplicant, type:'symbol'}"
                                                   (compSymbolValue)="updateCompanySymbol($event, 'IRS', spouseApplicant)" (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'IRS', spouseApplicant)"
                                                   autocomplete="off"   [appErrorAccess]="{ id: ['companySymbolIRS']+spouseApplicant+['-error'], submitted: isFormSubmit}" required />
                                            <label [ngClass]="{'error-field': companySymbolIRSCtrl.errors && companySymbolIRSCtrl.touched || (isFormSubmit && companySymbolIRSCtrl.errors), 'active-label': companySymbolIRSCtrl.value}"
                                                   class="slide-label" [attr.for]="['companySymbolIRS']+spouseApplicant">
                                                <span class="label-text" translate="regulatoryDisclosures.labels.companySymbol"></span>
                                            </label>
                                        </div>
                                        <div class="form-field-button" *ngIf="spouseApplicant>0">
                                            <a role="button"
                                               attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.trashButton' | translate }} {{companyNameIRSCtrl.value}}"
                                               id="spouseApplicantRemoveBtn{{spouseApplicant+1}}"
                                               attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}"
                                               class="btn-icon tooltip-show"
                                               href="javascript://"
                                               (click)="removeSpouseInsiderRelationship(spouseApplicant, companySymbolIRSCtrl.value)">
                                                <span class="icon icon-large trash-blue"></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companySymbolIRS']+spouseApplicant+['-error']" *ngIf="companySymbolIRSCtrl.errors && companySymbolIRSCtrl.touched && !companySymbolIRSCtrl.errors.inValidCompanySymbol  || (isFormSubmit && companySymbolIRSCtrl.errors) && !companySymbolIRSCtrl.errors.inValidCompanySymbol">
                                        <span translate="regulatoryDisclosures.errors.tickerSymbolRequired"></span>
                                    </div>
                                    <div role="alert" class="error-message"  [id]="['companySymbolIRS']+spouseApplicant+['-error']" *ngIf="companySymbolIRSCtrl.errors && companySymbolIRSCtrl.errors.inValidCompanySymbol && companySymbolIRSCtrl.value">
                                        <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="form-row button-row" *ngIf="spouseInsiderRelationArr.length < maxCompanyNameAndSymbol">

                                <a role="button" id="spouseInsiderRelationshipAddMoreButton" class="btn-icon" href="javascript://" (click)="addSpouseInsiderRelationship()" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.addSpouseInsiderRelationshipLabel' | translate }}">
                                    <span class="icon icon-large plus-circle-blue"></span>
                                    <span class="btn-icon-text" translate="regulatoryDisclosures.labels.addMore"></span>
                                </a>

                            </div>

                        </div>

                    </div>

                </div>
            <!-- MWB-15356 - Consolated Significant  and Controlling (Prior R4) -->
                <ng-container *ngIf="!isDataRationalization4Enabled">
                    <h2 class="display-inline" translate="regulatoryDisclosures.titleSubsectionSignificantShareRelationships"></h2>
                    <p id="ariaSignificantShareRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionSignificantShareRelationships' | translate"></p>

                    <div class="more-info-wrapper">
                        <a role="button" id="ariaSignificantShareRelationshipsLearnMoreOpen"
                            aria-controls="ariaSignificantShareRelationshipsControl"
                            aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                            attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreOpenShareholderRelationships' | translate }}">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>
                        <div class="more-info-content" id="ariaSignificantShareRelationshipsControl">
                            <div [innerHTML]="'regulatoryDisclosures.contextualHelp.significantShareholderRelationships' | translate"></div>
                            <p>
                                <a role="button" id="ariaSignificantShareRelationshipsLearnMoreClose" aria-controls="ariaSignificantShareRelationshipsControl" aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreCloseShareholderRelationships' | translate }}">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="form-wrapper">

                        <div class="form-row">

                            <div role="radiogroup" aria-labelledby="ariaSignificantShareRelationships" id='parent-ariaSignificantShareRelationships-error' class="flex-elements form-col">
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': shareHolderRelationShipCtrl.errors && shareHolderRelationShipCtrl.touched || (isFormSubmit && shareHolderRelationShipCtrl.errors), 'active-label': shareHolderRelationShipCtrl.value}">
                                    <input id="yesSignificantShareRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:shareholder" name="radioSignificantShareRelationships"
                                            value="Yes" [(ngModel)]="regulatoryDisclosure.shareholderRelationship" #shareHolderRelationShipCtrl="ngModel" (click)="analytics.triggerView('ShareHolderRelationShipYes')"
                                            [appErrorAccess]="{ id: 'ariaSignificantShareRelationships-error', parentId: 'parent-ariaSignificantShareRelationships-error', submitted: isFormSubmit}" required />
                                    <label class="prefix" for="yesSignificantShareRelationships" translate="regulatoryDisclosures.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': shareHolderRelationShipCtrl.errors && shareHolderRelationShipCtrl.touched || (isFormSubmit && shareHolderRelationShipCtrl.errors), 'active-label': shareHolderRelationShipCtrl.value}">
                                    <input id="noSignificantShareRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:shareholder" name="radioSignificantShareRelationships"
                                            value="No" [(ngModel)]="regulatoryDisclosure.shareholderRelationship" #shareHolderRelationShipCtrl="ngModel" (click)="analytics.triggerView('ShareHolderRelationShipNo')"
                                            [appErrorAccess]="{ id: 'ariaSignificantShareRelationships-error', parentId: 'parent-ariaSignificantShareRelationships-error' , submitted: isFormSubmit}" required />
                                    <label class="prefix" for="noSignificantShareRelationships" translate="regulatoryDisclosures.labels.no"></label>
                                </div>
                                <div role="alert" class="error-message" id="ariaSignificantShareRelationships-error" *ngIf="shareHolderRelationShipCtrl.errors && shareHolderRelationShipCtrl.touched || (isFormSubmit && shareHolderRelationShipCtrl.errors)">
                                    <span translate="regulatoryDisclosures.errors.significantShareholderSelectError"></span>
                                </div>

                            </div>

                        </div>

                        <div *ngIf="shareHolderRelationShipCtrl.value=='Yes'">

                            <div class="form-row" *ngFor="let shareHolderApplicant of shareHolderRelationArr" >

                                <div class="flex-40 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group" >
                                            <input [name]="['companyNameSSR']+shareHolderApplicant" [attr.id]="['companyNameSSR']+shareHolderApplicant" type="text" [(ngModel)]="regulatoryDisclosure['companyNameSSR'+shareHolderApplicant]"
                                                    #companyNameSSRCtrl="ngModel" [value]="regulatoryDisclosure['companyNameSSR'+shareHolderApplicant]"
                                                    appContextualSearch (input)="setCustomError('name', 'SSR', shareHolderApplicant)"
                                                    [contextualSearch]="{id:['companySymbolSSR']+shareHolderApplicant,type:'name'}" (compSymbolValue)="updateCompanySymbol($event, 'SSR', shareHolderApplicant)"
                                                    (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'SSR', shareHolderApplicant)"
                                                    autocomplete="off" [appErrorAccess]="{ id: ['companyNameSSR']+shareHolderApplicant+['-error'], submitted: isFormSubmit}" required />
                                            <label [ngClass]="{'error-field': companyNameSSRCtrl.errors && companyNameSSRCtrl.touched || (isFormSubmit && companyNameSSRCtrl.errors), 'active-label': companyNameSSRCtrl.value}"
                                                    class="slide-label" [attr.for]="['companyNameSSR']+shareHolderApplicant">
                                                <span class="label-text" translate="regulatoryDisclosures.labels.companyName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companyNameSSR']+shareHolderApplicant+['-error']" *ngIf="companyNameSSRCtrl.errors && companyNameSSRCtrl.touched && !companyNameSSRCtrl.errors.inValidCompanyName || (isFormSubmit && companyNameSSRCtrl.errors) && !companyNameSSRCtrl.errors.inValidCompanyName">
                                        <span translate="regulatoryDisclosures.errors.companyNameRequired"></span>
                                    </div>
                                    <div role="alert" class="error-message" [id] ="['companyNameSSR']+shareHolderApplicant+['-error']" *ngIf="companyNameSSRCtrl.errors && companyNameSSRCtrl.errors.inValidCompanyName && companyNameSSRCtrl.value">
                                        <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                    </div>
                                </div>
                                <div class="flex-40 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input [name]="['companySymbolSSR']+shareHolderApplicant" [attr.id]="['companySymbolSSR']+shareHolderApplicant" type="text"
                                                    [(ngModel)]="regulatoryDisclosure['companySymbolSSR'+shareHolderApplicant]" #companySymbolSSRCtrl="ngModel"
                                                    [value]="regulatoryDisclosure['companySymbolSSR'+shareHolderApplicant]" appContextualSearch
                                                    (input)="setCustomError('symbol','SSR', shareHolderApplicant)" [contextualSearch]="{id:['companyNameSSR']+shareHolderApplicant, type:'symbol'}"
                                                    (compSymbolValue)="updateCompanySymbol($event, 'SSR', shareHolderApplicant)" (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'SSR', shareHolderApplicant)"
                                                    autocomplete="off"  [appErrorAccess]="{ id:['companySymbolSSR']+shareHolderApplicant+['-error'], submitted: isFormSubmit}" required />
                                            <label [ngClass]="{'error-field': companySymbolSSRCtrl.errors && companySymbolSSRCtrl.touched || (isFormSubmit && companySymbolSSRCtrl.errors), 'active-label': companySymbolSSRCtrl.value}"
                                                    class="slide-label" [attr.for]="['companySymbolSSR']+shareHolderApplicant">
                                                <span class="label-text" translate="regulatoryDisclosures.labels.companySymbol"></span>
                                            </label>
                                        </div>
                                        <div class="form-field-button" *ngIf="shareHolderApplicant>0">
                                            <a role="button"
                                                attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.trashButton' | translate }} {{companyNameSSRCtrl.value}}"
                                                id="shareHolderApplicantRemoveBtn{{shareHolderApplicant+1}}"
                                                attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}"
                                                class="btn-icon tooltip-show"
                                                href="javascript://"
                                                (click)="removeShareholderRelationship(shareHolderApplicant, companySymbolSSRCtrl.value)">
                                                <span class="icon icon-large trash-blue"></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companySymbolSSR']+shareHolderApplicant+['-error']" *ngIf="companySymbolSSRCtrl.errors && companySymbolSSRCtrl.touched  &&!companySymbolSSRCtrl.errors.inValidCompanySymbol  || (isFormSubmit && companySymbolSSRCtrl.errors) && !companySymbolSSRCtrl.errors.inValidCompanySymbol">
                                        <span translate="regulatoryDisclosures.errors.tickerSymbolRequired"></span>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companySymbolSSR']+shareHolderApplicant+['-error']" *ngIf="companySymbolSSRCtrl.errors && companySymbolSSRCtrl.errors.inValidCompanySymbol && companySymbolSSRCtrl.value">
                                        <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="form-row button-row" *ngIf="shareHolderRelationArr.length < maxCompanyNameAndSymbol">

                                <a role="button" id="shareholderRelationshipAddMoreButton" class="btn-icon" href="javascript://" (click)="addShareholderRelationship()" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.addShareholderRelationshipLabel' | translate }}" >
                                    <span class="icon icon-large plus-circle-blue"></span>
                                    <span class="btn-icon-text" translate="regulatoryDisclosures.labels.addMore"></span>
                                </a>

                            </div>

                        </div>

                    </div>

                    <h2 class="display-inline" translate="regulatoryDisclosures.titleSubsectionControlRelationships"></h2>
                    <p id="ariaControlRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionControlRelationships' | translate"></p>

                    <div class="more-info-wrapper">
                        <a role="button"  aria-controls="ariaControlRelationshipsControl" aria-expanded="false"
                        class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreOpenControlRelationships' | translate }}"
                        id="learnMoreOpenControlRelationships">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>
                        <div class="more-info-content" id="ariaControlRelationshipsControl"   >
                            <div [innerHTML]="'regulatoryDisclosures.contextualHelp.controlRelationships' | translate"></div>
                            <p>
                                <a role="button" id="ariaControlRelationshipsLearnMoreCloseIcon" aria-controls="ariaControlRelationshipsLearnMoreCloseIcon" aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreCloseControlRelationships' | translate }}">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="form-wrapper">

                        <div role="radiogroup" aria-labelledby="ariaControlRelationships" id='parent-ariaControlRelationships-error'  class="form-row">

                            <div class="flex-elements form-col">
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': controlRelationshipCtrl.errors && controlRelationshipCtrl.touched || (isFormSubmit && controlRelationshipCtrl.errors), 'active-label': controlRelationshipCtrl.value}">
                                    <input id="yesControlRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:control" name="radioControlRelationships"
                                            value="Yes" [(ngModel)]="regulatoryDisclosure.controlRelationship" #controlRelationshipCtrl="ngModel"  (click)="analytics.triggerView('ControlRelationshipYes')"
                                            [appErrorAccess]="{ id: 'ariaControlRelationships-error', parentId: 'parent-ariaControlRelationships-error'}" required />
                                    <label class="prefix" for="yesControlRelationships" translate="regulatoryDisclosures.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': controlRelationshipCtrl.errors && controlRelationshipCtrl.touched || (isFormSubmit && controlRelationshipCtrl.errors), 'active-label': controlRelationshipCtrl.value}">
                                    <input id="noControlRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:control" name="radioControlRelationships"
                                            value="No" [(ngModel)]="regulatoryDisclosure.controlRelationship" #controlRelationshipCtrl="ngModel" (click)="analytics.triggerView('ControlRelationshipNo')"
                                            [appErrorAccess]="{ id: 'ariaControlRelationships-error', parentId: 'parent-ariaControlRelationships-error', submitted: isFormSubmit}" required />
                                    <label class="prefix" for="noControlRelationships" translate="regulatoryDisclosures.labels.no"></label>
                                </div>
                                <div role="alert" class="error-message" id="ariaControlRelationships-error" *ngIf="controlRelationshipCtrl.errors && controlRelationshipCtrl.touched || (isFormSubmit && controlRelationshipCtrl.errors)">
                                    <span translate="regulatoryDisclosures.errors.controlRelationshipSelectError"></span>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="controlRelationshipCtrl.value=='Yes'">

                            <div class="form-row" *ngFor="let controlApplicant of controlRelationArr" >

                                <div class="flex-40 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input [name]="['companyNameCR']+controlApplicant" [attr.id]="['companyNameCR']+controlApplicant" type="text" [(ngModel)]="regulatoryDisclosure['companyNameCR'+controlApplicant]"
                                                    #companyNameCRCtrl="ngModel" [value]="regulatoryDisclosure['companyNameCR'+controlApplicant]"
                                                    appContextualSearch (input)="setCustomError('name','CR', controlApplicant)" [contextualSearch]="{id:['companySymbolCR']+controlApplicant, type:'name'}"
                                                    (compSymbolValue)="updateCompanySymbol($event, 'CR', controlApplicant)" (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'CR', controlApplicant)"
                                                    autocomplete="off"  [appErrorAccess]="{ id:['companyNameCR']+controlApplicant+['-error'], submitted: isFormSubmit}" required />
                                            <label [ngClass]="{'error-field': companyNameCRCtrl.errors && companyNameCRCtrl.touched || (isFormSubmit && companyNameCRCtrl.errors), 'active-label': companyNameCRCtrl.value}"
                                                    class="slide-label" [attr.for]="['companyNameCR']+controlApplicant">
                                                <span class="label-text" translate="regulatoryDisclosures.labels.companyName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companyNameCR']+controlApplicant+['-error']" *ngIf="companyNameCRCtrl.errors && companyNameCRCtrl.touched &&  !companyNameCRCtrl.errors.inValidCompanyName || (isFormSubmit && companyNameCRCtrl.errors) && !companyNameCRCtrl.errors.inValidCompanyName">
                                        <span translate="regulatoryDisclosures.errors.companyNameRequired"></span>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companyNameCR']+controlApplicant+['-error']" *ngIf="companyNameCRCtrl.errors && companyNameCRCtrl.errors.inValidCompanyName">
                                        <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                    </div>
                                </div>
                                <div class="flex-40 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group" >
                                            <input [name]="['companySymbolCR']+controlApplicant" [attr.id]="['companySymbolCR']+controlApplicant" type="text" [(ngModel)]="regulatoryDisclosure['companySymbolCR'+controlApplicant]"
                                                    #companySymbolCRCtrl="ngModel" [value]="regulatoryDisclosure['companySymbolCR'+controlApplicant]"
                                                    appContextualSearch (input)="setCustomError('symbol','CR', controlApplicant)" [contextualSearch]="{id:['companyNameCR']+controlApplicant, type:'symbol'}"
                                                    (compSymbolValue)="updateCompanySymbol($event, 'CR', controlApplicant)" (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'CR', controlApplicant)"
                                                    autocomplete="off" [appErrorAccess]="{ id: ['companySymbolCR']+controlApplicant+['-error'], submitted: isFormSubmit}"  required />
                                            <label [ngClass]="{'error-field': companySymbolCRCtrl.errors && companySymbolCRCtrl.touched || (isFormSubmit && companySymbolCRCtrl.errors), 'active-label': companySymbolCRCtrl.value}"
                                                    class="slide-label" [attr.for]="['companySymbolCR']+controlApplicant">
                                                <span class="label-text" translate="regulatoryDisclosures.labels.companySymbol"></span>
                                            </label>
                                        </div>
                                        <div class="form-field-button" *ngIf="controlApplicant>0">
                                            <a role="button"
                                                id="controlApplicantRemoveBtn{{controlApplicant+1}}"
                                                attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.trashButton' | translate }} {{companyNameCRCtrl.value}}"
                                                attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}"
                                                class="btn-icon tooltip-show"
                                                href="javascript://"
                                                (click)="removeControlRelationship(controlApplicant, companySymbolCRCtrl.value)">
                                                <span class="icon icon-large trash-blue"></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companySymbolCR']+controlApplicant+['-error']" *ngIf="companySymbolCRCtrl.errors && companySymbolCRCtrl.touched && !companySymbolCRCtrl.errors.inValidCompanySymbol || (isFormSubmit && companySymbolCRCtrl.errors) && !companySymbolCRCtrl.errors.inValidCompanySymbol">
                                        <span translate="regulatoryDisclosures.errors.tickerSymbolRequired"></span>
                                    </div>
                                    <div role="alert" class="error-message" [id]="['companySymbolCR']+controlApplicant+['-error']" *ngIf="companySymbolCRCtrl.errors && companySymbolCRCtrl.errors.inValidCompanySymbol && companySymbolCRCtrl.value">
                                        <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="form-row button-row" *ngIf="controlRelationArr.length < maxCompanyNameAndSymbol">

                                <a role="button" id="controlRelationshipAddMoreButton" class="btn-icon" href="javascript://" (click)="addControlRelationship()" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.addControlRelationshipLabel' | translate }}">
                                    <span class="icon icon-large plus-circle-blue"></span>
                                    <span class="btn-icon-text" translate="regulatoryDisclosures.labels.addMore"></span>
                                </a>

                            </div>

                        </div>
                    </div>
                </ng-container>

            <!-- MWB-15356 - Consolated Significant  and Controlling (R4) -->
            <ng-container *ngIf="isDataRationalization4Enabled">
                <h2 class="display-inline" translate="regulatoryDisclosures.titleSubsectionSignificantControlShareRelationships"></h2>
                <p *ngIf="!isNewContentR5Enabled" id="ariaSignificantShareRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionSignificantControlShareRelationships' | translate"></p>
                <p *ngIf="isNewContentR5Enabled" id="ariaSignificantShareRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionSignificantControlShareRelationships01' | translate"></p>

                <div class="more-info-wrapper">
                    <a role="button" id="ariaSignificantShareRelationshipsLearnMoreOpen"
                        aria-controls="ariaSignificantShareRelationshipsControl"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreOpenShareholderControlRelationships' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>
                    <div class="more-info-content" id="ariaSignificantShareRelationshipsControl">
                        <div [innerHTML]="'regulatoryDisclosures.contextualHelp.significantShareholderControlRelationships' | translate"></div>
                        <p>
                            <a role="button" id="ariaSignificantShareRelationshipsLearnMoreClose" aria-controls="ariaSignificantShareRelationshipsControl" aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreCloseShareholderRelationships' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>
                    </div>
                </div>

                <div class="form-wrapper">

                    <div class="form-row">

                        <div role="radiogroup" aria-labelledby="ariaSignificantShareRelationships" id='parent-ariaSignificantShareRelationships-error' class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': shareHolderRelationShipCtrl.errors && shareHolderRelationShipCtrl.touched || (isFormSubmit && shareHolderRelationShipCtrl.errors), 'active-label': shareHolderRelationShipCtrl.value}">
                                <input id="yesSignificantShareRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:shareholder" name="radioSignificantShareRelationships"
                                        value="Yes" [(ngModel)]="regulatoryDisclosure.shareholderRelationship" #shareHolderRelationShipCtrl="ngModel" (click)="analytics.triggerView('ShareHolderRelationShipYes')"
                                        [appErrorAccess]="{ id: 'ariaSignificantShareRelationships-error', parentId: 'parent-ariaSignificantShareRelationships-error', submitted: isFormSubmit}" required />
                                <label class="prefix" for="yesSignificantShareRelationships" translate="regulatoryDisclosures.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': shareHolderRelationShipCtrl.errors && shareHolderRelationShipCtrl.touched || (isFormSubmit && shareHolderRelationShipCtrl.errors), 'active-label': shareHolderRelationShipCtrl.value}">
                                <input id="noSignificantShareRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:shareholder" name="radioSignificantShareRelationships"
                                        value="No" [(ngModel)]="regulatoryDisclosure.shareholderRelationship" #shareHolderRelationShipCtrl="ngModel" (click)="analytics.triggerView('ShareHolderRelationShipNo')"
                                        [appErrorAccess]="{ id: 'ariaSignificantShareRelationships-error', parentId: 'parent-ariaSignificantShareRelationships-error' , submitted: isFormSubmit}" required />
                                <label class="prefix" for="noSignificantShareRelationships" translate="regulatoryDisclosures.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="ariaSignificantShareRelationships-error" *ngIf="shareHolderRelationShipCtrl.errors && shareHolderRelationShipCtrl.touched || (isFormSubmit && shareHolderRelationShipCtrl.errors)">
                                <span translate="regulatoryDisclosures.errors.significantShareholderControlSelectError"></span>
                            </div>

                        </div>

                    </div>

                    <div *ngIf="shareHolderRelationShipCtrl.value=='Yes'">

                        <div class="form-row" *ngFor="let shareHolderApplicant of shareHolderRelationArr" >

                            <div class="flex-40 form-col">
                                <div class="form-field">
                                    <div class="form-label-group" >
                                        <input [name]="['companyNameSSR']+shareHolderApplicant" [attr.id]="['companyNameSSR']+shareHolderApplicant" type="text" [(ngModel)]="regulatoryDisclosure['companyNameSSR'+shareHolderApplicant]"
                                                #companyNameSSRCtrl="ngModel" [value]="regulatoryDisclosure['companyNameSSR'+shareHolderApplicant]"
                                                appContextualSearch (input)="setCustomError('name', 'SSR', shareHolderApplicant)"
                                                [contextualSearch]="{id:['companySymbolSSR']+shareHolderApplicant,type:'name'}" (compSymbolValue)="updateCompanySymbol($event, 'SSR', shareHolderApplicant)"
                                                (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'SSR', shareHolderApplicant)"
                                                autocomplete="off" [appErrorAccess]="{ id: ['companyNameSSR']+shareHolderApplicant+['-error'], submitted: isFormSubmit}" required />
                                        <label [ngClass]="{'error-field': companyNameSSRCtrl.errors && companyNameSSRCtrl.touched || (isFormSubmit && companyNameSSRCtrl.errors), 'active-label': companyNameSSRCtrl.value}"
                                                class="slide-label" [attr.for]="['companyNameSSR']+shareHolderApplicant">
                                            <span class="label-text" translate="regulatoryDisclosures.labels.companyName"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" class="error-message" [id]="['companyNameSSR']+shareHolderApplicant+['-error']" *ngIf="companyNameSSRCtrl.errors && companyNameSSRCtrl.touched && !companyNameSSRCtrl.errors.inValidCompanyName || (isFormSubmit && companyNameSSRCtrl.errors) && !companyNameSSRCtrl.errors.inValidCompanyName">
                                    <span translate="regulatoryDisclosures.errors.companyNameRequired"></span>
                                </div>
                                <div role="alert" class="error-message" [id] ="['companyNameSSR']+shareHolderApplicant+['-error']" *ngIf="companyNameSSRCtrl.errors && companyNameSSRCtrl.errors.inValidCompanyName && companyNameSSRCtrl.value">
                                    <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                </div>
                            </div>
                            <div class="flex-40 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input [name]="['companySymbolSSR']+shareHolderApplicant" [attr.id]="['companySymbolSSR']+shareHolderApplicant" type="text"
                                                [(ngModel)]="regulatoryDisclosure['companySymbolSSR'+shareHolderApplicant]" #companySymbolSSRCtrl="ngModel"
                                                [value]="regulatoryDisclosure['companySymbolSSR'+shareHolderApplicant]" appContextualSearch
                                                (input)="setCustomError('symbol','SSR', shareHolderApplicant)" [contextualSearch]="{id:['companyNameSSR']+shareHolderApplicant, type:'symbol'}"
                                                (compSymbolValue)="updateCompanySymbol($event, 'SSR', shareHolderApplicant)" (comSymbolBlurEvent)="companySymbolBlurEvent($event, 'SSR', shareHolderApplicant)"
                                                autocomplete="off"  [appErrorAccess]="{ id:['companySymbolSSR']+shareHolderApplicant+['-error'], submitted: isFormSubmit}" required />
                                        <label [ngClass]="{'error-field': companySymbolSSRCtrl.errors && companySymbolSSRCtrl.touched || (isFormSubmit && companySymbolSSRCtrl.errors), 'active-label': companySymbolSSRCtrl.value}"
                                                class="slide-label" [attr.for]="['companySymbolSSR']+shareHolderApplicant">
                                            <span class="label-text" translate="regulatoryDisclosures.labels.companySymbol"></span>
                                        </label>
                                    </div>
                                    <div class="form-field-button" *ngIf="shareHolderApplicant>0">
                                        <a role="button"
                                            attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.trashButton' | translate }} {{companyNameSSRCtrl.value}}"
                                            id="shareHolderApplicantRemoveBtn{{shareHolderApplicant+1}}"
                                            attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}"
                                            class="btn-icon tooltip-show"
                                            href="javascript://"
                                            (click)="removeShareholderRelationship(shareHolderApplicant, companySymbolSSRCtrl.value)">
                                            <span class="icon icon-large trash-blue"></span>
                                        </a>
                                    </div>
                                </div>
                                <div role="alert" class="error-message" [id]="['companySymbolSSR']+shareHolderApplicant+['-error']" *ngIf="companySymbolSSRCtrl.errors && companySymbolSSRCtrl.touched  &&!companySymbolSSRCtrl.errors.inValidCompanySymbol  || (isFormSubmit && companySymbolSSRCtrl.errors) && !companySymbolSSRCtrl.errors.inValidCompanySymbol">
                                    <span translate="regulatoryDisclosures.errors.tickerSymbolRequired"></span>
                                </div>
                                <div role="alert" class="error-message" [id]="['companySymbolSSR']+shareHolderApplicant+['-error']" *ngIf="companySymbolSSRCtrl.errors && companySymbolSSRCtrl.errors.inValidCompanySymbol && companySymbolSSRCtrl.value">
                                    <span translate="regulatoryDisclosures.errors.companyNameSymbolInvalid"></span>
                                </div>
                            </div>

                        </div>
                        <div class="form-row button-row" *ngIf="shareHolderRelationArr.length < maxCompanyNameAndSymbol">

                            <a role="button" id="shareholderRelationshipAddMoreButton" class="btn-icon" href="javascript://" (click)="addShareholderRelationship()" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.addShareholderRelationshipLabel' | translate }}" >
                                <span class="icon icon-large plus-circle-blue"></span>
                                <span class="btn-icon-text" translate="regulatoryDisclosures.labels.addMore"></span>
                            </a>

                        </div>

                    </div>

                </div>
            </ng-container>

            <!-- MWB-15335 - Remove Politically Exposed Person Questions -->
              <ng-container *ngIf = "isDataRationalization4Enabled !== true">
                <h2 id="ariaPoliticalExposedType" class="display-inline" translate="regulatoryDisclosures.titleSubsectionGovernmentalRelationships"></h2>
                <p id="ariaGovernmentalRelationships" [innerHTML]="'regulatoryDisclosures.descriptionSubsectionGovernmentalRelationships' | translate"></p>

                <div class="more-info-wrapper">
                    <a role="button" aria-controls="ariaPoliticalExposedTypeControl" aria-expanded="false"
                        class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreOpenGovernmentRelationships' | translate }}"
                        id="learnMoreOpenGovernmentRelationships">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>
                    <div class="more-info-content" id="ariaPoliticalExposedTypeControl">
                        <div [innerHTML]="'regulatoryDisclosures.contextualHelp.governmentRelationships' | translate"></div>
                        <p>
                            <a role="button"  id="ariaPoliticalExposedTypeLearnMoreClose" aria-controls="ariaPoliticalExposedTypeLearnMoreClose" aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://" attr.aria-label="{{ 'regulatoryDisclosures.ariaLabels.learnMoreCloseGovernmentRelationships' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>
                    </div>
                </div>

                <div class="form-wrapper">

                    <div role="radiogroup" aria-labelledby="ariaGovernmentalRelationships" id='parent-ariaGovernmentalRelationships-error' class="form-row">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': governmentalRelationshipCtrl.errors && governmentalRelationshipCtrl.touched || (isFormSubmit && governmentalRelationshipCtrl.errors), 'active-label': governmentalRelationshipCtrl.value}">
                                <input id="yesGovernmentalRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:government" name="radioGovernmentalRelationships"
                                       value="Yes" [(ngModel)]="regulatoryDisclosure.governmentalRelationship" #governmentalRelationshipCtrl="ngModel" (click)="analytics.triggerView('GovernmentalRelationshipYes')"
                                       [appErrorAccess]="{ id: 'ariaGovernmentalRelationships-error', parentId: 'parent-ariaGovernmentalRelationships-error', submitted: isFormSubmit}" required />
                                <label class="prefix" for="yesGovernmentalRelationships" translate="regulatoryDisclosures.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': governmentalRelationshipCtrl.errors && governmentalRelationshipCtrl.touched || (isFormSubmit && governmentalRelationshipCtrl.errors), 'active-label': governmentalRelationshipCtrl.value}">
                                <input id="noGovernmentalRelationships" type="radio" data-ana="mywealth:onboarding:regulatory:government" name="radioGovernmentalRelationships"
                                       value="No" [(ngModel)]="regulatoryDisclosure.governmentalRelationship" #governmentalRelationshipCtrl="ngModel" (click)="analytics.triggerView('GovernmentalRelationshipNo')"
                                       [appErrorAccess]="{ id: 'ariaGovernmentalRelationships-error', parentId: 'parent-ariaGovernmentalRelationships-error', submitted: isFormSubmit}" required />
                                <label class="prefix" for="noGovernmentalRelationships" translate="regulatoryDisclosures.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="ariaGovernmentalRelationships-error" *ngIf="governmentalRelationshipCtrl.errors && governmentalRelationshipCtrl.touched || (isFormSubmit && governmentalRelationshipCtrl.errors)">
                                <span translate="regulatoryDisclosures.errors.politicalExposedSelectError"></span>
                            </div>
                        </div>

                    </div>
                    <div class="form-row" *ngIf="governmentalRelationshipCtrl.value=='Yes'">

                        <p id="pepType" [innerHTML]="'regulatoryDisclosures.labels.pepType' | translate">

                    </div>
                    <div role="radiogroup" aria-labelledby="pepType" id="parent-pepType-error" class="form-row" *ngIf="governmentalRelationshipCtrl.value=='Yes'">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': grPositionCtrl.errors && grPositionCtrl.touched || (isFormSubmit && grPositionCtrl.errors), 'active-label': grPositionCtrl.value}">
                                <input id="domestic" type="radio" name="radioGRPosition" value="Domestic" [(ngModel)]="regulatoryDisclosure.governmentRelationPosition"
                                (click)="analytics.triggerView('GovernmentRelationPositionDomestic')"
                                       #grPositionCtrl="ngModel" [appErrorAccess]="{ id: 'pepType-error', parentId: 'parent-pepType-error', submitted: isFormSubmit}" required />
                                <label class="prefix" for="domestic" translate="regulatoryDisclosures.labels.domestic"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': grPositionCtrl.errors && grPositionCtrl.touched || (isFormSubmit && grPositionCtrl.errors), 'active-label': grPositionCtrl.value}">
                                <input id="foreign" type="radio" name="radioGRPosition" value="Foreign" [(ngModel)]="regulatoryDisclosure.governmentRelationPosition"
                                (click)="analytics.triggerView('GovernmentRelationPositionForeign')"
                                       #grPositionCtrl="ngModel"  [appErrorAccess]="{ id: 'pepType-error', parentId: 'parent-pepType-error', submitted: isFormSubmit}" required />
                                <label class="prefix" for="foreign" translate="regulatoryDisclosures.labels.foreign"></label>
                            </div>
                            <div role="alert" class="error-message" id="pepType-error" *ngIf="grPositionCtrl.errors && grPositionCtrl.touched || (isFormSubmit && grPositionCtrl.errors)">
                                <span translate="regulatoryDisclosures.errors.politicalExposedTypeSelectError"></span>
                            </div>
                        </div>

                    </div>

                    <div class="form-row" *ngIf="governmentalRelationshipCtrl.value=='Yes' && regulatoryDisclosure.governmentRelationPosition =='Domestic'">

                        <div class="flex-40 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <select id="selectDomesticTitle"  name="selectDomesticTitle" [(ngModel)]="regulatoryDisclosure.selectDomesticTitle" #selectDomesticTitleCtrl="ngModel"
                                    [appErrorAccess]="{ id: 'selectDomesticTitle-error', submitted: isFormSubmit}" required>

                                        <option *ngFor="let occupation of occupationPepOptions | alphabeticalSort:'label'" [value]="occupation.code" [selected]="occupation.selected">
                                            {{occupation.label}}
                                        </option>
                                    </select>
                                    <label [ngClass]="{'error-field': selectDomesticTitleCtrl.errors && selectDomesticTitleCtrl.touched || (isFormSubmit && selectDomesticTitleCtrl.errors), 'active-label': selectDomesticTitleCtrl.value}"
                                           class="slide-label" for="selectDomesticTitle">
                                        <span class="label-text" translate="regulatoryDisclosures.labels.domesticTitle"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="selectDomesticTitle-error" class="error-message" *ngIf="selectDomesticTitleCtrl.errors && selectDomesticTitleCtrl.touched || (isFormSubmit && selectDomesticTitleCtrl.errors)">
                                <span translate="regulatoryDisclosures.errors.domesticPositionSelectError"></span>
                            </div>
                        </div>

                    </div>
                    <div class="form-row" *ngIf="governmentalRelationshipCtrl.value=='Yes' && regulatoryDisclosure.governmentRelationPosition =='Foreign'">

                        <div class="flex-40 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="foreignTitle" name="foreignTitle" type="text" [(ngModel)]="regulatoryDisclosure.selectForeignTitle" #selectForeignTitleCtrl="ngModel"
                                           InputValidator="ct17" maxlength="40" [appErrorAccess]="{ id: 'foreignTitle-error', submitted: isFormSubmit}" required />
                                    <label [ngClass]="{'error-field': selectForeignTitleCtrl.errors && selectForeignTitleCtrl.touched || (isFormSubmit && selectForeignTitleCtrl.errors), 'active-label': selectForeignTitleCtrl.value}"
                                           class="slide-label" for="foreignTitle">
                                        <span class="label-text" translate="regulatoryDisclosures.labels.foreignTitle"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="foreignTitle-error" *ngIf="selectForeignTitleCtrl.errors && selectForeignTitleCtrl.touched || (isFormSubmit && selectForeignTitleCtrl.errors)">
                                <span translate="regulatoryDisclosures.errors.foreignPep"></span>
                            </div>
                        </div>

                    </div>

                </div>
            </ng-container>
            </section>

        </section>

        <section *ngIf="selfDirected" id="regulatoryDisclosureSdButtonSection">
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater" formName="regulatory" [backRoute]="APP_ROUTES.financial_details"></app-form-buttons>
        </section>

        <section *ngIf="adviceDirect" id="regulatoryDisclosureAdButtonSection">
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater" formName="regulatory" [backRoute]="APP_ROUTES.investor_profile"></app-form-buttons>
        </section>

    </div>

</form>

