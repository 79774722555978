<form>

    <section>

        <div class="container-noMenuView">

            <div class="bmo-container-flex bmo-container-artwork">

                <div class="bmo-artwork-content">

                    <h1 [innerHTML]="'technicalError.titlePage' | translate | safeHtml"></h1>
                    <p [innerHTML]="'technicalError.descriptionPage01' | translate | safeHtml"></p>

                    <hr />

                    <div class="form-wrapper">
                        <div class="form-row form-page-buttons justify-start no-padding">
                            <div class="form-col left-side-dual">
                                <button class="secondary no-text-transform" type="button" translate="button.selfDirected" (click)="onNavigateSelfDirectAdviceDirect()"></button>
                                <button class="secondary no-text-transform" type="button" translate="button.adviceDirect" (click)="onNavigateSelfDirectAdviceDirect()"></button>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="bmo-artwork-img">

                    <img class="tech-error" src="assets/img/artwork-broken-kite.svg" alt="" />

                </div>

            </div>

        </div>

    </section>

</form>
