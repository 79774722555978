import { Pipe, PipeTransform } from '@angular/core';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Pipe({
  name: 'zipCode'
})
export class ZipCodePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const original = value;
    if (!value || value.length === 0) {
        return;
    }
    value = value.toString();
    value = value.replace(/[^0-9]/g, '');
    if (value.length > 11) {
        value = value.slice(0, 11);
    }
    if (value.length > 5) {
        value = value.slice(0, 5) + '-' + value.slice(5);
    }
    return value;
  }

}
