<!-- <app-flood-light></app-flood-light> -->

<form #financialDetailsForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section id="financialErrorSection">

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="financialDetailsForm.invalid && isFormSubmit"
                translateTag="financialDetails"></app-invalid-form>

            <section id="annualIncomeSection">

                <h1 translate="financialDetails.titleAnualIncome" id="ariaTitleAnualIncome"></h1>

                <div class="more-info-wrapper add-margin t16px b12px">

                    <a role="button" id="bmoGrossTitleDescribeTitleOpen" aria-controls="bmoGrossTitleDescribeOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'financialDetails.ariaLabels.titleAnualIncome' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="bmoGrossTitleDescribeOpen">
                        <p [innerHTML]="'financialDetails.descriptionAnualIncome' | translate"></p>
                        <p>
                            <a role="button" id="bmoGrossTitleDescribeTitleClose" aria-controls="bmoGrossTitleDescribeTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>

                <div *ngIf="isRif" class="alert alert-info" role="alert">
                    <p translate="financialDetails.grossIncomeRIFAlertDescription"></p>
                </div>
                <div class="form-wrapper">
                    <div class="form-row">

                        <div class="flex-60 form-col">
                            <div class="form-field">
                                <div class="form-label-group">

                                  <select id="grossAnnualIncome" name="grossAnnualIncome"
                                        data-ana="mywealth:onboarding:financial:income"
                                        [(ngModel)]="financialInformation.grossAnnualIncome"
                                        (change)="updateGrossAnnualIncome($event.target.value)"
                                        #grossAnnualIncomeCtrl="ngModel"
                                        [appErrorAccess]="{id:'grossAnnualIncome-error',  submitted: isFormSubmit}"
                                        required aria-labelledby="ariaTitleAnualIncome">
                                        <!--upper range of the labels are not inclusive. e.g. 0 to 25, is 0 to 24,999, or [0-25)-->
                                        <!--To fix issue on IE (MWB-10681)-->
                                        <option *ngFor="let grossIncome of grossAnnualIncomeList"
                                            [value]="grossIncome.code" [selected]="grossIncome.selected">
                                            {{grossIncome.label}}
                                        </option>
                                    </select>
                                    <label class="slide-label" for="grossAnnualIncome"
                                        [ngClass]="{'error-field':isError(grossAnnualIncomeCtrl), 'active-label':grossAnnualIncomeCtrl.value}">
                                        <span class="label-text"
                                            translate="financialDetails.labels.grossAnnualIncome"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="grossAnnualIncome-error" class="error-message"
                                *ngIf="isError(grossAnnualIncomeCtrl)">
                                <span translate="financialDetails.errors.mustSelectGrossAnnualIncome"></span>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

            <!-- SHOW this for Margin Account" -->
            <section *ngIf="isMargin || (isMultiAccountAndMarginExist && !adviceDirect && isMultiAccountOpenFlag)" id="netLiquidSection">

                <h1 translate="financialDetails.titleNetLiquidAssets"></h1>

                <div class="more-info-wrapper add-margin t16px b12px">

                    <a role="button" id="bmoNetWorthTitleDescribeTitleOpen" aria-controls="bmoNetWorthTitleDescribeOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'financialDetails.ariaLabels.titleNetLiquidAssets' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="bmoNetWorthTitleDescribeOpen">
                        <p [innerHTML]="'financialDetails.descriptionNetLiquidAssets1' | translate"></p>
                        <p>
                            <a role="button" id="bmoNetWorthTitleDescribeTitleClose" aria-controls="bmoNetWorthTitleDescribeTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>
                <!-- <p [innerHTML]="'financialDetails.descriptionNetLiquidAssets2' | translate"></p> -->

                <div class="form-wrapper">
                    <div class="form-row">

                        <div class="flex-60 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="netLiquidAssets" type="tel" name="netLiquidAssets"
                                        [(ngModel)]="financialInformation.netLiquidAssets"
                                        #netLiquidAssetsCtrl="ngModel" maxlength="9" appCurrency InputValidator="ct3"
                                        [required]="isMargin || (isMultiAccountAndMarginExist && !adviceDirect && isMultiAccountOpenFlag)"
                                        (change)="analytics.triggerView('netLiquidAssets' + financialInformation.netLiquidAssets)" />
                                    <label class="slide-label" for="netLiquidAssets"
                                        [ngClass]="{'error-field':isError(netLiquidAssetsCtrl), 'active-label':netLiquidAssetsCtrl.value==0 ? true : netLiquidAssetsCtrl.value}">
                                        <span class="label-text"
                                            translate="financialDetails.labels.netLiquidAssets"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" *ngIf="isError(netLiquidAssetsCtrl)">
                                <span translate="financialDetails.errors.mustEnterApproximateValue"></span>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

            <section id="netWorthSection">

                <h1 translate="financialDetails.titleNetWorth" id="ariaTitleNetWorth"></h1>
                <div class="more-info-wrapper add-margin t16px b24px">

                    <a role="button" id="bmoNetWorthTwoTitleDescribeTitleOpen" aria-controls="bmoNetWorthTwoTitleDescribeOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'financialDetails.ariaLabels.titleNetWorth' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="bmoNetWorthTwoTitleDescribeOpen">
                        <p [innerHTML]="'financialDetails.descriptionNetWorth1' | translate"></p>
                        <p>
                            <a role="button" id="bmoNetWorthTwoTitleDescribeTitleClose" aria-controls="bmoNetWorthTwoTitleDescribeTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>

                <p [innerHTML]="'financialDetails.descriptionNetWorth2' | translate"></p>

                <div class="form-wrapper">
                    <div class="form-row">

                        <div class="flex-60 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="netWorth" type="tel" data-ana="mywealth:onboarding:financial:net worth"
                                        name="netWorth" [(ngModel)]="financialInformation.netWorth"
                                        #netWorthCtrl="ngModel" maxlength="9" appCurrency InputValidator="ct3"
                                        [appErrorAccess]="{id:'netWorth-error',  submitted: isFormSubmit}" required
                                        (change)="analytics.triggerView('netWorth' + financialInformation.netWorth)"
                                        aria-labelledby="ariaTitleNetWorth"/>
                                    <label class="slide-label" for="netWorth"
                                        [ngClass]="{'error-field':isError(netWorthCtrl), 'active-label':netWorthCtrl.value==0 ? true : netWorthCtrl.value}">
                                        <span class="label-text" translate="financialDetails.labels.netWorth"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="netWorth-error" class="error-message" *ngIf="isError(netWorthCtrl)">
                                <span translate="financialDetails.errors.mustEnterValue"></span>
                            </div>
                            <!-- <app-net-worth group="netWorth" [model]="financialInformation" key="netWorth" id="netWorth" [isFormSubmit]="isFormSubmit"></app-net-worth> -->
                        </div>
                        <div *ngIf="isDataRationalizationEnabled" class="flex-40 form-col flex-elements flex-xaxis-center">
                            <span [innerHTML]="'financialDetails.labels.calculatorOr' | translate | safeHtml">or &nbsp;</span>
                            <a (click)="openCalculator()" id="openCalculatorButton" role="button" href="javascript://" class="btn-icon" attr.aria-label="{{ 'financialDetails.ariaLabels.calculatorOpen' | translate }}">
                                <span class="icon icon-large calculator-blue"></span>
                                <span translate="financialDetails.labels.calculatorOpen" class="btn-icon-text"></span>
                            </a>
                        </div>

                    </div>
                </div>

            </section>

            <section id="sourceWealthSection">

                <h1 id="ariaSelectSourceWealth" translate="financialDetails.titleSourceWealth" id="fianancialDetailsSourcesOfWealth"></h1>
                <p [innerHTML]="'financialDetails.descriptionSourceWealth' | translate" ></p>

                <div class="form-wrapper">

                    <div role="group" aria-labelledby="ariaSelectSourceWealth" class="form-row add-margin t24px"
                        id='parent-wealthSource-error'>

                        <app-checkbox-card [options]="wealthSources"
                        (selectedValueEvent)="setshowFinancialOther($event)"
                        [form]="financialDetailsForm"
                        [section]="'fianancialDetailsSourcesOfWealth'"
                        [name]="'wealthSourceInput'"
                        ngDefaultControl
                        [dataAna]="'mywealth:onboarding:financialDetails:sourcesOfWealth'"
                        [imageSource]="'assets/img/fund-choice/'"
                        [isFormSubmitted]='isFormSubmit'
                        [selected]="financialInformation.sourcesOfWealth"
                        [accessErrorId]="{ id: 'wealthSource-error', parentId: 'parent-wealthSourceOptions-error',  submitted: isFormSubmit }"
                        [errorMessage]="'financialDetails.errors.mustSelectSourceOfWealth'">
                        </app-checkbox-card>

                    </div>

                    <!-- This row should only show if "showFinancialOther" is selected from the above checks -->
                    <div class="form-row" *ngIf="showFinancialOther">

                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="financialOther" minlength="1" maxlength="30" name="financialOther"
                                        [(ngModel)]="financialInformation.otherSourceOfWealth"
                                        #otherSourceOfWealthCtrl="ngModel" InputValidator="ct2 ct12" type="text"
                                        [appErrorAccess]="{id:'otherSourceOfWealth-error',  submitted: isFormSubmit}"
                                        [required]="showFinancialOther"
                                        (change)="analytics.triggerView('otherSourceOfWealth' + financialInformation.otherSourceOfWealth)"/>
                                    <label class="slide-label" for="financialOther"
                                        [ngClass]="{'error-field': isError(otherSourceOfWealthCtrl), 'active-label': otherSourceOfWealthCtrl.value}">
                                        <span class="label-text"
                                            translate="financialDetails.labels.financialOtherField"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id='otherSourceOfWealth-error' class="error-message"
                                *ngIf="isError(otherSourceOfWealthCtrl)">
                                <span translate="financialDetails.errors.mustEnterValue"></span>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section *ngIf="isMandatoryProject && adviceDirect" id="saveAssetOptionSection">
                <h1 translate="financialDetails.percentIvestableQuestion" id="saveAssetOption"></h1>


                <div class="more-info-wrapper add-margin t16px b24px">

                    <a role="button" id="bmoPercentIvestableTitleOpen" aria-controls="bmoPercentIvestableDescribeOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'financialDetails.ariaLabels.percentIvestableQuestion' | translate }}">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="bmoPercentIvestableDescribeOpen">

                        <p [innerHTML]="'financialDetails.percentIvestableDescription' | translate"></p>
                        <p>
                            <a role="button" id="bmoPercentIvestableTitleClose" aria-controls="bmoPercentIvestableTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>

                <div class="form-wrapper">
                        <app-radio-card
                        [options]="savingAssetPercentageList"
                        [form]="financialDetailsForm"
                        [section]="'saveAssetOption'"
                        [name]="'saveAssetOption'"
                        isFullImageUrl="true"
                        ngDefaultControl
                        [dataAna]="'mywealth:onboarding:financial:source'"
                        [isError]="false"
                        [accessErrorId]="{ id: 'saveAsset-error', parentId: 'parent-saveInvestibleAsset-error',  submitted: isFormSubmit }"
                        [errorMessage]="'financialDetails.errors.saveInvestibleAsset'"
                        [templateType]="RADIO_TYPES.LARGE"
                        [(ngModel)]="financialInformation.saveInvestibleAsset"
                        (change)="updTargetSavingAssetPercentage(financialInformation.saveInvestibleAsset)"
                        >
                        </app-radio-card>

                </div>

                <ng-container>
                    <section class="add-margin t24px" id="borrowMoneySection">
                        <h1 translate="financialDetails.borrowingMoneyQuestion" id="ariaRelationShip">
                        </h1>
                        <div class="form-wrapper">
                            <div class="form-row" role="radiogroup" aria-labelledby="ariaRelationShip"
                                id='parent-relationShip-error'>
                                <div class="flex-elements form-col no-margin">
                                    <div class="form-radio-checks radio-buttons"
                                        *ngFor="let borrowingMoneyOption of borrowingMoneyOptions"
                                        [ngClass]="{'error-field': borrowingMoneyCtrl.errors && borrowingMoneyCtrl.touched || (isFormSubmit && borrowingMoneyCtrl.errors), 'active-label': borrowingMoneyCtrl.value}">
                                        <input [attr.id]="'borrowingMoney'+borrowingMoneyOption.code"
                                            data-ana="mywealth:onboarding:financialDetails:borrowingMoney"
                                            type="radio"
                                            name="purposeInvesting"
                                            #borrowingMoneyCtrl="ngModel"
                                            [(ngModel)]="financialInformation.borrowingMoney"
                                            [value]="borrowingMoneyOption.code"
                                            [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                            required
                                            (change)="analytics.triggerView(borrowingMoneyOption.code ? 'borrowingMoney' + 'Yes' : 'borrowingMoney' + 'No')"/>
                                        <label
                                            class="prefix"
                                            [attr.for]="'borrowingMoney'+borrowingMoneyOption.code"
                                            [translate]="borrowingMoneyOption.label">
                                        </label>

                                    </div>
                                    <div class="error-message" id="relationShip-error" role="alert"
                                    *ngIf="isFormSubmit && borrowingMoneyCtrlError">
                                    <span translate="financialDetails.errors.mandatoryBorrowingMoney"></span>
                                </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </ng-container>
            </section>

                <!-- Annual Income Required Questions -->

                <section *ngIf="adviceDirect" class="add-margin t48px" id="accountAnnualIncomeSection">
                    <h1 id="ariaAnnualIncome"
                        translate="financialDetails.titleAccountAnnualIncome"
                        attr.aria-label="{{ 'financialDetails.ariaLabels.annualIncome' | translate }}"></h1>

                        <div class="more-info-wrapper add-margin t16px b24px">

                            <a role="button" id="bmoAccountAnnualIncomeTitleOpen" aria-controls="bmoAccountAnnualIncomeTitleDescribeOpen"
                                aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                                attr.aria-label="{{ 'financialDetails.ariaLabels.titleAccountAnnualIncome' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                                <span class="icon icon-large chevron-down-blue"></span>
                            </a>

                            <div class="more-info-content new-bmo-card-content" id="bmoAccountAnnualIncomeTitleDescribeOpen">

                                <p translate="financialDetails.descriptionAccountAnnualIncome" ></p>
                                <p>
                                    <a role="button" id="bmoAccountAnnualIncomeTitleClose" aria-controls="bmoAccountAnnualIncomeTitleClose"
                                        aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                        attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                        <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                        <span class="icon icon-large chevron-up-blue"></span>
                                    </a>
                                </p>

                            </div>

                        </div>

                    <div class="form-wrapper">

                        <div role="radiogroup" aria-labelledby="ariaAnnualIncome" id='parent-annualIncome-error'
                            class="form-row">

                            <div class="flex-elements form-col no-margin">
                                <div class="form-radio-checks radio-buttons"
                                [ngClass]="{'error-field': annualIncomeCtrl.errors && annualIncomeCtrl.touched || (isFormSubmit && annualIncomeCtrl.errors)}">
                                <input id="annualIncomeYes"
                                data-ana="mywealth:onboarding:financial:annualIncReq"
                                    name="annualIncReq"
                                    type="radio"
                                    [(ngModel)]="financialInformation.annualIncReq"
                                    #annualIncomeCtrl="ngModel"
                                    [appErrorAccess]="{ id: 'annualIncome-error', parentId: 'parent-annualIncome-error', submitted: isFormSubmit}"
                                    required [value]="true"
                                    (change)="analytics.triggerView('annualIncReq' + annualIncomeCtrl.value)"/>
                                <label class="prefix" for="annualIncomeYes"
                                    translate="financialDetails.labels.yesAccountIncome"></label>
                            </div>
                                <div class="form-radio-checks radio-buttons"
                                    [ngClass]="{'error-field': annualIncomeCtrl.errors && annualIncomeCtrl.touched || (isFormSubmit && annualIncomeCtrl.errors)}">
                                    <input id="annualIncomeNo"
                                    data-ana="mywealth:onboarding:financial:annualIncReq"
                                        name="annualIncReq"
                                        type="radio"
                                        [(ngModel)]="financialInformation.annualIncReq"
                                        #annualIncomeCtrl="ngModel"
                                        [appErrorAccess]="{ id: 'annualIncome-error', parentId: 'parent-annualIncome-error', submitted: isFormSubmit}"
                                        required [value]="false"
                                        (change)="analytics.triggerView('annualIncReq' + annualIncomeCtrl.value)"/>
                                    <label class="prefix" for="annualIncomeNo"
                                        translate="financialDetails.labels.noAccountIncome"></label>
                                </div>

                                <div role="alert" class="error-message" id="annualIncome-error"
                                    *ngIf="annualIncomeCtrl.errors && annualIncomeCtrl.touched || (isFormSubmit && annualIncomeCtrl.errors)">
                                    <span translate="financialDetails.errors.selectannualIncome"></span>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

            <section id="financialButtonSection">
                <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater"
                    formName="finFrm" [backRoute]="backRoute"></app-form-buttons>
            </section>
        </section>
    </div>

</form>
