<!-- <app-flood-light></app-flood-light> -->

<!--
@created:
@updated: Aravind Anantharaman last updated on (24 April,2018)
 -->


<form #consentsForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section id="consents-container">

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="consentsForm.invalid && isFormSubmit" translateTag="consents"></app-invalid-form>

            <section id="SectionPrivacy">

                <h1 translate="consents.titleSectionPrivacy"></h1>

                <h2 id="ariaPersonalInfo" translate="consents.titleSubsectionPersonal"></h2>
                <p translate="consents.descriptionSubsectionPersonal" *ngIf="isDataRationalizationEnabled"></p>
                <p translate="consents.descriptionSubsectionPersonalRevert" *ngIf="!isDataRationalizationEnabled"></p> <!--MWB-14898:Revert-->

                <div class="more-info-wrapper">
                    <a role="button" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'consents.ariaLabels.learnMoreOpenPersonalInfo' | translate }}"
                        id="learnMoreOpenPersonalInfo"
                       aria-controls="moreInfoOpenPersonalInfo" aria-expanded="false"  >
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>
                    <div class="more-info-content" id="moreInfoOpenPersonalInfo">
                        <div [innerHTML]="'consents.contextualHelp.sharing' | translate"></div>
                        <p>
                            <a role="button" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'consents.ariaLabels.learnMoreClosePersonalInfo' | translate }}"
                                aria-controls="moreInfoOpenPersonalInfoClose" id="moreInfoOpenPersonalInfoClose"
                                aria-expanded="true">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>
                    </div>
                </div>

                <div class="form-wrapper">
                    <div role="radiogroup" aria-labelledby="ariaPersonalInfo" id='parent-personalInfo-error' class="form-row">

                        <div class="flex-elements form-col no-margin" *ngIf="isDataRationalizationEnabled">
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': personalInfoCtrl.errors && personalInfoCtrl.touched || (isFormSubmit && personalInfoCtrl.errors)}">

                                <input id="personalInfoYes"
                                       data-ana="mywealth:onboarding:personal:true"
                                       name="personalInfo"
                                       type="radio"
                                       [(ngModel)]="consents.personalInfo"
                                       #personalInfoCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'personalInfo-error', parentId: 'parent-personalInfo-error', submitted: isFormSubmit}"
                                       required
                                       [value]="true" />
                                <label class="prefix" for="personalInfoYes" translate="consents.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': personalInfoCtrl.errors && personalInfoCtrl.touched || (isFormSubmit && personalInfoCtrl.errors)}">
                                <input id="personalInfoNo"
                                       data-ana="mywealth:onboarding:personal:false"
                                       name="personalInfo"
                                       type="radio"
                                       [(ngModel)]="consents.personalInfo"
                                       #personalInfoCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'personalInfo-error', parentId: 'parent-personalInfo-error', submitted: isFormSubmit}"
                                       required
                                       [value]="false" />
                                    <label class="prefix" for="personalInfoNo" translate="consents.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="personalInfo-error" *ngIf="personalInfoCtrl.errors && personalInfoCtrl.touched || (isFormSubmit && personalInfoCtrl.errors)">
                                <span translate="consents.errors.selectPersonalInfo"></span>
                            </div>
                        </div>

                        <!---------------
                            MWB-14898:Revert
                        ------------------->
                        <div class="form-col" *ngIf="!isDataRationalizationEnabled">
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': personalInfoCtrl.errors && personalInfoCtrl.touched || (isFormSubmit && personalInfoCtrl.errors)}">

                                <input id="personalInfoYes"
                                       data-ana="mywealth:onboarding:personal:true"
                                       name="personalInfo"
                                       type="radio"
                                       [(ngModel)]="consents.personalInfo"
                                       #personalInfoCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'personalInfo-error', parentId: 'parent-personalInfo-error', submitted: isFormSubmit}"
                                       required
                                       [value]="true" />
                                <label class="prefix" for="personalInfoYes" translate="consents.labels.consent"></label>

                            </div>
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': personalInfoCtrl.errors && personalInfoCtrl.touched || (isFormSubmit && personalInfoCtrl.errors)}">
                                <input id="personalInfoNo"
                                       data-ana="mywealth:onboarding:personal:false"
                                       name="personalInfo"
                                       type="radio"
                                       [(ngModel)]="consents.personalInfo"
                                       #personalInfoCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'personalInfo-error', parentId: 'parent-personalInfo-error', submitted: isFormSubmit}"
                                       required
                                       [value]="false" />
                                <label class="prefix" for="personalInfoNo" translate="consents.labels.noConsent"></label>

                            </div>
                            <div role="alert" class="error-message" id="personalInfo-error" *ngIf="personalInfoCtrl.errors && personalInfoCtrl.touched || (isFormSubmit && personalInfoCtrl.errors)">
                                <span translate="consents.errors.selectPersonalInfo"></span>
                            </div>
                        </div>


                    </div>
                </div>

                <h2 id="ariaMarketing" translate="consents.titleSubsectionMarketing"></h2>
                <p translate="consents.descriptionSubsectionMarketing" *ngIf="isDataRationalizationEnabled"></p>
                <p translate="consents.descriptionSubsectionMarketingRevert" *ngIf="!isDataRationalizationEnabled"></p> <!--MWB-14898:Revert-->

                <div class="more-info-wrapper">
                    <a role="button" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'consents.ariaLabels.learnMoreOpenDirectMarketing' | translate }}"
                        id="learnMoreOpenDirectMarketing"
                        aria-controls="moreInfodirectMarketing" aria-expanded="false">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                    </a>
                    <div class="more-info-content" id="moreInfodirectMarketing">
                        <div [innerHTML]="'consents.contextualHelp.directMarketing' | translate"></div>
                        <p>
                            <a role="button" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'consents.ariaLabels.learnMoreCloseDirectMarketing' | translate }}"
                                id="learnMoreCloseDirectMarketing"
                                aria-controls="learnMoreCloseDirectMarketing" aria-expanded="true">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>
                    </div>
                </div>

                <div class="form-wrapper">
                    <div role="radiogroup" aria-labelledby="ariaMarketing" id='parent-marketingPref-error' class="form-row">

                        <div class="flex-elements form-col no-margin" *ngIf="isDataRationalizationEnabled">
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': marketingPrefCtrl.errors && marketingPrefCtrl.touched || (isFormSubmit && marketingPrefCtrl.errors)}">
                                <input id="marketingPrefYes"
                                       data-ana="mywealth:onboarding:marketing:true"
                                       type="radio"
                                       [(ngModel)]="consents.marketingPref"
                                       #marketingPrefCtrl="ngModel"
                                       name="marketingPref"
                                       [appErrorAccess]="{ id: 'marketingPref-error', parentId: 'parent-marketingPref-error', submitted: isFormSubmit}"
                                       required
                                       [value]="true" />
                                 <label class="prefix" for="marketingPrefYes" translate="consents.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': marketingPrefCtrl.errors && marketingPrefCtrl.touched || (isFormSubmit && marketingPrefCtrl.errors)}">
                                <input id="marketingPrefNo"
                                       data-ana="mywealth:onboarding:marketing:false"
                                       type="radio"
                                       [(ngModel)]="consents.marketingPref"
                                       #marketingPrefCtrl="ngModel"
                                       name="marketingPref"
                                       [appErrorAccess]="{ id: 'marketingPref-error', parentId: 'parent-marketingPref-error', submitted: isFormSubmit}"
                                       required
                                       [value]="false" />

                                <label class="prefix" for="marketingPrefNo"  translate="consents.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="marketingPref-error" *ngIf="marketingPrefCtrl.errors && marketingPrefCtrl.touched || (isFormSubmit && marketingPrefCtrl.errors)">
                                <span translate="consents.errors.selectMarketingMaterials"></span>
                            </div>
                        </div>

                         <!---------------
                            MWB-14898:Revert
                        ------------------->
                        <div class="form-col" *ngIf="!isDataRationalizationEnabled">
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': marketingPrefCtrl.errors && marketingPrefCtrl.touched || (isFormSubmit && marketingPrefCtrl.errors)}">
                                <input id="marketingPrefYes"
                                       data-ana="mywealth:onboarding:marketing:true"
                                       type="radio"
                                       [(ngModel)]="consents.marketingPref"
                                       #marketingPrefCtrl="ngModel"
                                       name="marketingPref"
                                       [appErrorAccess]="{ id: 'marketingPref-error', parentId: 'parent-marketingPref-error', submitted: isFormSubmit}"
                                       required
                                       [value]="true" />
                                <label class="prefix" for="marketingPrefYes"  translate="consents.labels.consent"></label>
                            </div>
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': marketingPrefCtrl.errors && marketingPrefCtrl.touched || (isFormSubmit && marketingPrefCtrl.errors)}">
                                <input id="marketingPrefNo"
                                       data-ana="mywealth:onboarding:marketing:false"
                                       type="radio"
                                       [(ngModel)]="consents.marketingPref"
                                       #marketingPrefCtrl="ngModel"
                                       name="marketingPref"
                                       [appErrorAccess]="{ id: 'marketingPref-error', parentId: 'parent-marketingPref-error', submitted: isFormSubmit}"
                                       required
                                       [value]="false" />
                                <label class="prefix" for="marketingPrefNo"  translate="consents.labels.noConsent"></label>
                            </div>
                            <div role="alert" class="error-message" id="marketingPref-error" *ngIf="marketingPrefCtrl.errors && marketingPrefCtrl.touched || (isFormSubmit && marketingPrefCtrl.errors)">
                                <span translate="consents.errors.selectMarketingMaterials"></span>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="SectionShareholder">

                <h1 translate="consents.titleSectionShareholder"></h1>

                <h2 id="ariaOwnerInfo" translate="consents.titleSubsectionShareholder"></h2>
                <p translate="consents.descriptionShareholder" *ngIf="isDataRationalizationEnabled"></p>
                <p translate="consents.descriptionShareholderRevert" *ngIf="!isDataRationalizationEnabled"></p> <!--MWB-14898:Revert-->

                <div class="more-info-wrapper">
                    <a role="button" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'consents.ariaLabels.learnMoreOpenBeneficialOwner' | translate }}"
                       aria-controls="moreInfoDisclosureControls" aria-expanded="false">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>
                    <div class="more-info-content" id="moreInfoDisclosureControls">
                        <div [innerHTML]="'consents.contextualHelp.disclosure' | translate"></div>
                        <p>
                            <a role="button" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'consents.ariaLabels.learnMoreCloseBeneficialOwner' | translate }}"
                                id="learnMoreCloseBeneficialOwner"
                               aria-controls="learnMoreCloseBeneficialOwner" aria-expanded="true">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>
                    </div>
                </div>

                <div class="form-wrapper">
                    <div role="radiogroup" aria-labelledby="ariaOwnerInfo" id='parent-shareholder-error' class="form-row">

                        <div class="flex-elements form-col no-margin" *ngIf="isDataRationalizationEnabled">
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': shareholderCtrl.errors && shareholderCtrl.touched || (isFormSubmit && shareholderCtrl.errors)}">
                                <input id="shareholderYes"
                                       data-ana="mywealth:onboarding:owner:true"
                                       type="radio"
                                       [(ngModel)]="consents.shareholder"
                                       #shareholderCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'shareholder-error', parentId: 'parent-shareholder-error', submitted: isFormSubmit}"
                                       name="shareholder"
                                       required
                                       [value]="true" />

                                <label class="prefix" for="shareholderYes" translate="consents.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': shareholderCtrl.errors && shareholderCtrl.touched || (isFormSubmit && shareholderCtrl.errors)}">
                                <input id="shareholderNo"
                                       data-ana="mywealth:onboarding:owner:false"
                                       type="radio"
                                       [(ngModel)]="consents.shareholder"
                                       #shareholderCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'shareholder-error', parentId: 'parent-shareholder-error', submitted: isFormSubmit}"
                                       name="shareholder"
                                       required
                                       [value]="false" />
                                <label class="prefix" for="shareholderNo"  translate="consents.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="shareholder-error" *ngIf="shareholderCtrl.errors && shareholderCtrl.touched || (isFormSubmit && shareholderCtrl.errors)">
                                <span translate="consents.errors.selectBeneficialOwner"></span>
                            </div>
                        </div>

                        <!---------------
                            MWB-14898:Revert
                        ------------------->
                        <div class="form-col" *ngIf="!isDataRationalizationEnabled">
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': shareholderCtrl.errors && shareholderCtrl.touched || (isFormSubmit && shareholderCtrl.errors)}">
                                <input id="shareholderYes"
                                       data-ana="mywealth:onboarding:owner:true"
                                       type="radio"
                                       [(ngModel)]="consents.shareholder"
                                       #shareholderCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'shareholder-error', parentId: 'parent-shareholder-error', submitted: isFormSubmit}"
                                       name="shareholder"
                                       required
                                       [value]="true" />
                                <label class="prefix" for="shareholderYes" translate="consents.labels.consent"></label>
                            </div>
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': shareholderCtrl.errors && shareholderCtrl.touched || (isFormSubmit && shareholderCtrl.errors)}">
                                <input id="shareholderNo"
                                       data-ana="mywealth:onboarding:owner:false"
                                       type="radio"
                                       [(ngModel)]="consents.shareholder"
                                       #shareholderCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'shareholder-error', parentId: 'parent-shareholder-error', submitted: isFormSubmit}"
                                       name="shareholder"
                                       required
                                       [value]="false" />
                                <label class="prefix" for="shareholderNo" translate="consents.labels.noConsent"></label>
                            </div>
                            <div role="alert" class="error-message" id="shareholder-error" *ngIf="shareholderCtrl.errors && shareholderCtrl.touched || (isFormSubmit && shareholderCtrl.errors)">
                                <span translate="consents.errors.selectBeneficialOwner"></span>
                            </div>
                        </div>

                    </div>

                </div>

                <h2 id="ariaSecurity" translate="consents.titleSubsectionSecurityholder"></h2>
                <p translate="consents.descriptionSecurityholder"  *ngIf="isDataRationalizationEnabled"></p>
                <p translate="consents.descriptionSecurityholderRevert" *ngIf="!isDataRationalizationEnabled"></p> <!--MWB-14898:Revert-->

                <div class="more-info-wrapper">
                    <a role="button" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'consents.ariaLabels.learnMoreOpenSecurityholder' | translate }}"
                        aria-controls="moreInfosecurityHolderControls" aria-expanded="false">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>
                    <div class="more-info-content" id="moreInfosecurityHolderControls">
                        <div [innerHTML]="'consents.contextualHelp.securityHolder' | translate"></div>
                        <p>
                            <a role="button" class="btn-icon btn-more-info-close" href="javascript://"
                            attr.aria-label="{{ 'consents.ariaLabels.learnMoreCloseSecurityholder' | translate }}"
                            id="learnMoreCloseSecurityholder" aria-controls="learnMoreCloseSecurityholder" aria-expanded="true">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>
                    </div>
                </div>

                <div class="form-wrapper ">
                    <div role="radiogroup" aria-labelledby="ariaSecurity" id= 'parent-securityholder-error' class="form-row">

                        <div class="flex-elements form-col no-margin" *ngIf="isDataRationalizationEnabled">

                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': securityholderCtrl.errors && securityholderCtrl.touched || (isFormSubmit && securityholderCtrl.errors)}">
                                <input id="securityholderYes"
                                       data-ana="mywealth:onboarding:shareholder:true"
                                       type="radio"
                                       [(ngModel)]="consents.securityholder"
                                       #securityholderCtrl="ngModel"
                                       name="securityholder"
                                       [appErrorAccess]="{ id: 'securityholder-error', parentId: 'parent-securityholder-error', submitted: isFormSubmit}"
                                       required
                                       [value]="true"
                                       (click)="analytics.triggerView('securityholderYes')"/>

                                <label id="ariaReceiveMaterials" class="prefix" for="securityholderYes" translate="consents.labels.yes"></label>
                            </div>

                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': securityholderCtrl.errors && securityholderCtrl.touched || (isFormSubmit && securityholderCtrl.errors)}">
                                <input id="securityholderNo"
                                       type="radio"
                                       name="securityholder"
                                       data-ana="mywealth:onboarding:shareholder:false"
                                       [(ngModel)]="consents.securityholder"
                                       [appErrorAccess]="{ id: 'securityholder-error', parentId: 'parent-securityholder-error', submitted: isFormSubmit}"
                                       required
                                       #securityholderCtrl="ngModel"
                                       [value]="false"
                                       (click)="analytics.triggerView('securityholderNo')"/>

                                <label class="prefix" for="securityholderNo" translate="consents.labels.no"></label>
                            </div>
                            <div role="alert" class="error-message" id="securityholder-error" *ngIf="securityholderCtrl.errors && securityholderCtrl.touched || (isFormSubmit && securityholderCtrl.errors)">
                                <span translate="consents.errors.selectSecurityholder"></span>
                            </div>
                        </div>
                        <div class="form-wrapper" *ngIf="isDataRationalizationEnabled">
                            <div role="radiogroup" aria-labelledby="ariaReceiveMaterials" id='parent-securityholderOption-error' class="form-radio-checks-l2" *ngIf="consents.securityholder === true">
                                <div class="form-radio-checks radio-default"
                                     [ngClass]="{'error-field': securityholderOptionCtrl.errors && securityholderOptionCtrl.touched || (isFormSubmit && securityholderOptionCtrl.errors)}">
                                    <input id="securityholderOptionAllYes"
                                           type="radio"
                                           name="securityholderOption"
                                           [(ngModel)]="consents.securityholderOption"
                                           #securityholderOptionCtrl="ngModel"
                                           [appErrorAccess]="{ id: 'securityholderOption-error', parentId: 'parent-securityholderOption-error', submitted: isFormSubmit}"
                                           required
                                           value="ProxiesAnualReports" />
                                    <label class="prefix" for="securityholderOptionAllYes" translate="consents.labels.proxiesAnualReports"></label>
                                </div>
                                <div class="form-radio-checks radio-default"
                                     [ngClass]="{'error-field': securityholderOptionCtrl.errors && securityholderOptionCtrl.touched || (isFormSubmit && securityholderOptionCtrl.errors)}">
                                    <input id="securityholderOptionAllNo"
                                           type="radio"
                                           [(ngModel)]="consents.securityholderOption"
                                           #securityholderOptionCtrl="ngModel"
                                           name="securityholderOption"
                                           [appErrorAccess]="{ id: 'securityholderOption-error', parentId: 'parent-securityholderOption-error', submitted: isFormSubmit}"
                                           required
                                           value="Proxies" />
                                    <label class="prefix" for="securityholderOptionAllNo" translate="consents.labels.onlyProxies"></label>
                                </div>
                                <div role="alert" class="error-message"  id="securityholderOption-error" *ngIf="securityholderOptionCtrl.errors && securityholderOptionCtrl.touched || (isFormSubmit && securityholderOptionCtrl.errors)">
                                    <span translate="consents.errors.selectSecurityholder"></span>
                                </div>
                            </div>
                        </div>

                        <!---------------
                            MWB-14898:Revert
                        ------------------->

                        <div class="form-col" *ngIf="!isDataRationalizationEnabled">

                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': securityholderCtrl.errors && securityholderCtrl.touched || (isFormSubmit && securityholderCtrl.errors)}">
                                <input id="securityholderYes"
                                       data-ana="mywealth:onboarding:shareholder:true"
                                       type="radio"
                                       [(ngModel)]="consents.securityholder"
                                       #securityholderCtrl="ngModel"
                                       name="securityholder"
                                       [appErrorAccess]="{ id: 'securityholder-error', parentId: 'parent-securityholder-error', submitted: isFormSubmit}"
                                       required
                                       [value]="true"
                                       (click)="analytics.triggerView('securityholderYes')"/>

                                <label id="ariaReceiveMaterials" class="prefix" for="securityholderYes" translate="consents.labels.consent"></label>
                            </div>

                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': securityholderCtrl.errors && securityholderCtrl.touched || (isFormSubmit && securityholderCtrl.errors)}">
                                <input id="securityholderNo"
                                       type="radio"
                                       name="securityholder"
                                       data-ana="mywealth:onboarding:shareholder:false"
                                       [(ngModel)]="consents.securityholder"
                                       [appErrorAccess]="{ id: 'securityholder-error', parentId: 'parent-securityholder-error', submitted: isFormSubmit}"
                                       required
                                       #securityholderCtrl="ngModel"
                                       [value]="false"
                                       (click)="analytics.triggerView('securityholderNo')"/>

                                <label class="prefix" for="securityholderNo" translate="consents.labels.noSecurityholderMaterials"></label>
                            </div>
                            <div role="radiogroup" aria-labelledby="ariaReceiveMaterials" id='parent-securityholderOption-error' class="form-radio-checks-l2" *ngIf="consents.securityholder === true">
                                <div class="form-radio-checks radio-default"
                                     [ngClass]="{'error-field': securityholderOptionCtrl.errors && securityholderOptionCtrl.touched || (isFormSubmit && securityholderOptionCtrl.errors)}">
                                    <input id="securityholderOptionAllYes"
                                           type="radio"
                                           name="securityholderOption"
                                           [(ngModel)]="consents.securityholderOption"
                                           #securityholderOptionCtrl="ngModel"
                                           [appErrorAccess]="{ id: 'securityholderOption-error', parentId: 'parent-securityholderOption-error', submitted: isFormSubmit}"
                                           required
                                           value="ProxiesAnualReports" />
                                    <label class="prefix" for="securityholderOptionAllYes" translate="consents.labels.proxiesAnualReports"></label>
                                </div>
                                <div class="form-radio-checks radio-default"
                                     [ngClass]="{'error-field': securityholderOptionCtrl.errors && securityholderOptionCtrl.touched || (isFormSubmit && securityholderOptionCtrl.errors)}">
                                    <input id="securityholderOptionAllNo"
                                           type="radio"
                                           [(ngModel)]="consents.securityholderOption"
                                           #securityholderOptionCtrl="ngModel"
                                           name="securityholderOption"
                                           [appErrorAccess]="{ id: 'securityholderOption-error', parentId: 'parent-securityholderOption-error', submitted: isFormSubmit}"
                                           required
                                           value="Proxies" />
                                    <label class="prefix" for="securityholderOptionAllNo" translate="consents.labels.onlyProxies"></label>
                                </div>
                                <div role="alert" class="error-message"  id="securityholderOption-error" *ngIf="securityholderOptionCtrl.errors && securityholderOptionCtrl.touched || (isFormSubmit && securityholderOptionCtrl.errors)">
                                    <span translate="consents.errors.selectSecurityholder"></span>
                                </div>
                            </div>

                            <div role="alert" class="error-message" id="securityholder-error" *ngIf="securityholderCtrl.errors && securityholderCtrl.touched || (isFormSubmit && securityholderCtrl.errors)">
                                <span translate="consents.errors.selectSecurityholder"></span>
                            </div>

                        </div>
                    </div>
                </div>


            </section>

            <section id="SectionTaxDocuments">

                <h1 id="ariaTaxDocuments" translate="consents.titleTaxDocuments"></h1>
                <p translate="consents.description1TaxDocuments"></p>
                <p translate="consents.description2TaxDocuments"></p>

                <div class="form-wrapper">
                    <div role="radiogroup" aria-labelledby="ariaTaxDocuments" id= 'parent-taxDocument-error' class="form-row">

                        <div class="form-col">
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': taxDocumentCtrl.errors && taxDocumentCtrl.touched || (isFormSubmit && taxDocumentCtrl.errors)}">
                                <input id="taxDocsElectronic"
                                       data-ana="mywealth:onboarding:tax:electronic"
                                       type="radio"
                                       name="taxDocs"
                                       [(ngModel)]="consents.taxDocuments"
                                       [appErrorAccess]="{ id: 'taxDocument-error', parentId: 'parent-taxDocument-error', submitted: isFormSubmit}"
                                       #taxDocumentCtrl="ngModel"
                                       required
                                       value="electronic" />
                                <label class="prefix" for="taxDocsElectronic" translate="consents.labels.taxDocsElectronic"></label>
                            </div>
                            <div class="form-radio-checks radio-default"
                                 [ngClass]="{'error-field': taxDocumentCtrl.errors && taxDocumentCtrl.touched || (isFormSubmit && taxDocumentCtrl.errors)}">
                                <input id="taxDocsElectronicMail"
                                       data-ana="mywealth:onboarding:tax:electronicmail"
                                       type="radio"
                                       name="taxDocs"
                                       [(ngModel)]="consents.taxDocuments"
                                       [appErrorAccess]="{ id: 'taxDocument-error', parentId: 'parent-taxDocument-error', submitted: isFormSubmit}"
                                       #taxDocumentCtrl="ngModel"
                                       required
                                       value="electronicMail" />
                                <label class="prefix" for="taxDocsElectronicMail" translate="consents.labels.taxDocsElectronicMail"></label>
                            </div>

                            <div role="alert" class="error-message"  id="taxDocument-error" *ngIf="taxDocumentCtrl.errors && taxDocumentCtrl.touched || (isFormSubmit && taxDocumentCtrl.errors)">
                                <span translate="consents.errors.selectTaxDocuments"></span>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section id="SubsectionEdocuments">
                <h1 translate="consents.titleSubsectionEdocuments"></h1>
                <p translate="consents.descriptionSubsectionEdocuments"></p>
            </section>

        </section>

        <section id="formButtons">
            <app-form-buttons [appInvalidForm]="appInvalidForm"  [showFinishLater]="showFinishLater"
                              formName="consentsfrm" [backRoute]="APP_ROUTES.regulatory_disclosures"></app-form-buttons>
        </section>

    </div>

</form>

