<!-- <app-flood-light></app-flood-light> -->

<form #contactInformationForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section>

            <app-invalid-form #appInvalidForm [isFormInvalid]="contactInformationForm.invalid && isFormSubmit"
                translateTag="contactInformation"></app-invalid-form>
            <app-critical-error *ngIf="criticalError"></app-critical-error>

            <section id="phoneNumberSection">

                <h1 translate="contactInformation.titlePhoneNumbers"></h1>

                <p id="ariaPhoneNumbers" translate="contactInformation.descriptionPhoneNumbers"></p>

                <div class="form-wrapper">

                    <div *ngIf="!isDataRationalizationEnabled" role="radiogroup" id='parent-phoneType-error' aria-labelledby="ariaPhoneNumbers"
                        class="form-row">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" *ngFor="let phoneType of phoneTypeArray" [ngClass]="{'error-field': !contactInformation.phoneType && isFormSubmit }">
                                <input [attr.id]="phoneType" data-ana="mywealth:onboarding:contact:phone" type="radio" [name]="phoneType" #phoneNumberTypeCtrl="ngModel"
                                    [(ngModel)]="contactInformation.phoneType" [value]="phoneType"  
                                     [appErrorAccess]="{ id: 'phoneType-error', parentId: 'parent-phoneType-error' ,submitted: isFormSubmit}" required 
                                     (change)="analytics.triggerView(phoneType)" />
                                <label class="prefix" [attr.for]="phoneType" [translate]="phoneType"></label>
                            </div>
                            <div role="alert" class="error-message" id="phoneType-error"
                                *ngIf="!contactInformation.phoneType && isFormSubmit ">
                                <span translate="contactInformation.errors.selectPhoneType"></span>
                            </div>
                        </div>

                    </div>

                    <div class="form-row">

                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="phoneNumber" name="phoneNumber" type="tel" maxlength="10" minlength="10"
                                        InputValidator="ct3" appPhoneNumber [(ngModel)]="contactInformation.phoneNumber"
                                        #phoneNumberCtrl="ngModel" [value]="contactInformation.phoneNumber"
                                        [appErrorAccess]="{ id: 'phoneNumber-error' ,submitted: isFormSubmit}"
                                        required />
                                    <label class="slide-label" for="phoneNumber"
                                        [ngClass]="{'error-field': phoneNumberCtrl.errors && phoneNumberCtrl.touched || (isFormSubmit && phoneNumberCtrl.errors), 'active-label': phoneNumberCtrl.value}">
                                        <span class="label-text"
                                            translate="contactInformation.labels.phoneNumber"></span>
                                    </label>
                                </div>

                            </div>
                            <div role="alert" class="error-message" id="phoneNumber-error"
                                *ngIf="phoneNumberCtrl.errors && phoneNumberCtrl.touched || (isFormSubmit && phoneNumberCtrl.errors)">
                                <span translate="contactInformation.errors.phoneNumber"></span>
                            </div>
                            <!-- <app-phone-number group="phoneNumber" [model]="contactInformation" key="phoneNumber" id="phoneNumber" [isFormSubmit]="isFormSubmit"></app-phone-number> -->
                        </div>
                        <div class="flex-30 form-col">
                            <div class="form-field" 
                            *ngIf="contactInformation.phoneType==='contactInformation.labels.phoneWork' || contactInformation.phoneType ==='contactInformation.labels.phoneOther'">
                                <div class="form-label-group">
                                    <input id="phoneExtension" name="phoneExtension"
                                        [(ngModel)]="contactInformation.phoneExtension" #phoneExtensionCtrl="ngModel"
                                        type="tel" InputValidator="ct3" maxlength="4" minlength="1" />
                                    <label class="slide-label" [ngClass]="{'active-label': phoneExtensionCtrl.value}"
                                        for="phoneExtension">
                                        <span class="label-text"
                                            translate="contactInformation.labels.phoneExtension"></span>
                                    </label>
                                </div>

                            </div>
                            <div class="form-field" *ngIf="isDataRationalizationEnabled">
                                <div class="form-label-group">
                                    <input id="phoneExtension" name="phoneExtension"
                                        [(ngModel)]="contactInformation.phoneExtension" #phoneExtensionCtrl="ngModel"
                                        type="tel" InputValidator="ct3" maxlength="4" minlength="1" />
                                    <label class="slide-label" [ngClass]="{'active-label': phoneExtensionCtrl.value}"
                                        for="phoneExtension">
                                        <span class="label-text"
                                            translate="contactInformation.labels.phoneExtension"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </section>

            <section id="addressSection">

                <h1 translate="contactInformation.titleWhereDoYouLive"></h1>
                <p [innerHtml]="'contactInformation.descriptionWhereDoYouLive' | translate"></p>
                <!-- Valdiate Primary Address -->
                <div class="alert alert-info" role="alert" *ngIf="prefillPostalCode.softWarningMsg && prefillPostalCode.postalCode !== contactInformation.postalCode">
                    <strong translate="contactInformation.eCIFMissMatch.titlePrimaryAddress"></strong>
                </div>

                <div class="form-wrapper">

                    <div class="form-row">

                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="streetNumberName" data-ana="mywealth:onboarding:contact:street" name="streetNumberName" [(ngModel)]="contactInformation.streetNumberName"
                                        type="text" appAddressLengthValidator #streetNumberNameCtrl="ngModel" appPOBoxValidator aria-live="assertive"
                                        allowPobox="false" addressAutoComplete InputValidator="ct13" [blockPaste]="true" required blockRRAddress blockRRAddress = "true"
                                        [appErrorAccess]="{ id: 'streetNumberName-error' ,submitted: isFormSubmit}"  
                                        autocomplete="new-password" />
                                    <label
                                        [ngClass]="{'error-field': streetNumberNameCtrl.errors && streetNumberNameCtrl.touched || (isFormSubmit && streetNumberNameCtrl.errors), 'active-label': streetNumberNameCtrl.value}"
                                        class='slide-label' for="streetNumberName">
                                        <span class="label-text"
                                            translate="contactInformation.labels.streetNumberName"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="streetNumberName-error"
                                *ngIf="(streetNumberNameCtrl?.errors?.required && streetNumberNameCtrl.touched || (isFormSubmit && streetNumberNameCtrl?.errors?.required))">
                                <span translate="contactInformation.errors.invalidStreetAddress"></span>
                            </div>
                            <div role="alert" class="error-message" id="streetNumberName-error"
                                *ngIf="streetNumberNameCtrl?.errors?.invalidPOBox && streetNumberNameCtrl.touched || (isFormSubmit && streetNumberNameCtrl?.errors?.invalidPOBox)">
                                <span translate="contactInformation.errors.invalidPOBox"></span>
                            </div>
                            <div role="alert" class="error-message" id="streetNumberName-error"
                                *ngIf="streetNumberNameCtrl?.errors?.streetNumberName">
                                <span translate="contactInformation.errors.addressError"></span>
                            </div>
                            <div role="alert" class="error-message" id= "streetNumberName-error" *ngIf="streetNumberNameCtrl?.errors?.invalidAddress && streetNumberNameCtrl.touched || (isFormSubmit && streetNumberNameCtrl?.errors?.invalidAddress)">
                                <span translate="contactInformation.errors.ruralRoute"></span>
                            </div>
                        </div>
                        <div class="flex-30 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input name="unit" id="unit" [(ngModel)]="contactInformation.unit" type="text"
                                        maxlength="5" appUnitValidator InputValidator="ct12" #unitCtrl="ngModel" />
                                    <label class="slide-label" for="unit" [ngClass]="{'active-label': unitCtrl.value}">
                                        <span class="label-text" translate="contactInformation.labels.unit"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message"
                                *ngIf="unitCtrl.errors && unitCtrl.touched || (isFormSubmit && unitCtrl.errors)">
                                <span translate="contactInformation.errors.invalidUnitCode"></span>
                            </div>
                        </div>

                    </div>
                    <div class="form-row">

                        <div class="flex-33 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="city" name="city" [(ngModel)]="contactInformation.city" type="text"
                                        maxlength="28" InputValidator="ct11" [blockPaste]="true"
                                        [appErrorAccess]="{ id: 'city-error' ,submitted: isFormSubmit}" required
                                        #cityCtrl="ngModel" />
                                    <label
                                        [ngClass]="{'error-field': cityCtrl.errors && cityCtrl.touched || (isFormSubmit && cityCtrl.errors), 'active-label': cityCtrl.value}"
                                        for="city" class="slide-label">
                                        <span class="label-text" translate="contactInformation.labels.city"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="city-error"
                                *ngIf="cityCtrl.errors && cityCtrl.touched || (isFormSubmit && cityCtrl.errors)">
                                <span translate="contactInformation.errors.invalidCity"></span>
                            </div>
                        </div>
                        <div class="flex-33 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <select id="province" name="province" [(ngModel)]="contactInformation.province"
                                        #provinceCtrl="ngModel"
                                        [appErrorAccess]="{ id: 'province-error' ,submitted: isFormSubmit}"
                                        (change)="checkAgeOfMajority()" required>
                                        <option *ngFor="let province of getProvinces()" [ngValue]="province.code"
                                            [selected]="province.code">
                                            {{ province.label }}
                                        </option>
                                    </select>
                                    <label
                                        [ngClass]="{'error-field': (provinceCtrl.errors && provinceCtrl.touched || (isFormSubmit && provinceCtrl.errors)) || provinceCtrl.errors?.notAgeOfMajority , 'active-label': provinceCtrl.value}"
                                        class="slide-label" for="province">
                                        <span class="label-text" translate="contactInformation.labels.province"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="province-error"
                                *ngIf="provinceCtrl?.errors?.selectionRequired && provinceCtrl.touched || (isFormSubmit && provinceCtrl.errors)">
                                <span translate="contactInformation.errors.provincePrimarySelectError"></span>
                            </div>
                            <div role="alert" class="error-message" id="province-error"
                                *ngIf="provinceCtrl?.errors?.notAgeOfMajority">
                                <span translate="contactInformation.errors.notAgeOfMajority"></span>
                            </div>
                        </div>
                        <div class="flex-33 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="postalCode" name="postalCode" type="text" minlength="1" maxlength="7"
                                        appPostalCode appPostalCodeValidator [(ngModel)]="contactInformation.postalCode"
                                        #postalCodeCtrl="ngModel"
                                        [appErrorAccess]="{ id: 'postalCode-error' ,submitted: isFormSubmit}" required
                                        [value]="contactInformation.postalCode | uppercase" />
                                    <label
                                        [ngClass]="{'error-field': postalCodeCtrl.errors && postalCodeCtrl.touched || (isFormSubmit && postalCodeCtrl.errors), 'active-label': postalCodeCtrl.value}"
                                        class='slide-label' for="postalCode">
                                        <span class="label-text"
                                            translate="contactInformation.labels.postalCode"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="postalCode-error"
                                *ngIf="postalCodeCtrl.errors && postalCodeCtrl.touched || (isFormSubmit && postalCodeCtrl.errors)">
                                <span translate="contactInformation.errors.invalidPostalCode"></span>
                            </div>

                        </div>

                    </div>

                    <!--<div class="form-row label-row">
                <label translate="contactInformation.labels.addressNumberOfYears"></label>
            </div>

            <div class="form-row">

                <div class="flex-elements form-col">
                    <div class="form-radio-checks radio-buttons">
                            <input id="addressNumberOfYearsYes"
                            type="radio"
                            name="addressNumberOfYears"
                            [(ngModel)]="contactInformation.addressNumberOfYears"
                            value="Yes"
                            (change)="updateAddressNumberOfYears()"
                            #addressNumberOfYears="ngModel"/>
                        <label class="prefix" for="addressNumberOfYearsYes" translate="contactInformation.labels.Yes"></label>
                    </div>
                    <div class="form-radio-checks radio-buttons">
                            <input id="addressNumberOfYearsNo"
                                    type="radio"
                                    name="addressNumberOfYears"
                                    [(ngModel)]="contactInformation.addressNumberOfYears"
                                    value="No"
                                    (change)="updateAddressNumberOfYears()"
                                    #addressNumberOfYears="ngModel"/>
                        <label class="prefix" for="addressNumberOfYearsNo" translate="contactInformation.labels.No"></label>
                    </div>
                        <div class="error-message" *ngIf="!contactInformation.addressNumberOfYears && isFormSubmit">
                        <span translate="contactInformation.errors.selectionRequired"></span>
                    </div>
                </div>

            </div>

                <div class="form-row label-row" >
                <label translate="contactInformation.labels.previousResidenceLocation"></label>
            </div>

            <div class="form-row">
                <div class="form-col">
                    <div class="form-radio-checks radio-default">
                        <input  id="previousResidenceLocationCanada"
                                type="radio"
                                name="previousResidenceLocation"
                                [(ngModel)]="contactInformation.previousResidenceLocation"
                                value="In Canada"
                                (change)="previousResidenceLocation()"
                                #previousResidenceLocationCtrl="ngModel"
                                required/>
                        <label class="prefix" for="previousResidenceLocationCanada" translate="contactInformation.labels.inCanada"></label>
                    </div>
                    <div class="form-radio-checks radio-default">
                        <input id="previousResidenceLocationOutsideCanada"
                                type="radio"
                                name="previousResidenceLocation"
                                [(ngModel)]="contactInformation.previousResidenceLocation"
                                value="Outside Canada"
                                (change)="previousResidenceLocation()"
                                #previousResidenceLocationCtrl="ngModel"
                                required/>
                        <label class="prefix" for="previousResidenceLocationOutsideCanada" translate="contactInformation.labels.outsideCanada"></label>
                    </div>

                    <div class="error-message" *ngIf="previousResidenceLocationCtrl.errors && previousResidenceLocationCtrl.touched || (isFormSubmit && previousResidenceLocationCtrl.errors)">
                        <span translate="contactInformation.errors.selectionRequired"></span> <br><br>
                    </div>

                    <div class="alert alert-yellow" >
                        <p>
                            <span translate="contactInformation.labels.outsideCanadaMessage"></span>
                        </p>
                    </div>
                </div>
            </div>
            -->

                </div>

            </section>

            <section id="mailingAddressSection">

                <h1 id="ariaSelectMailingAddress" translate="contactInformation.titleDifferentMailingAddress"></h1>

                <div class="form-wrapper">

                    <div role="radiogroup" id='parent-ariaSelectMailingAddress-error'
                        aria-labelledby="ariaSelectMailingAddress" class="form-row">

                        <div class="form-col">
                            <div class="form-radio-checks radio-default"
                                [ngClass]="{'error-field': sameMailingAddressCtrl.errors && sameMailingAddressCtrl.touched || (isFormSubmit && sameMailingAddressCtrl.errors)}">
                                <input id="receiveYourEmailYes" data-ana="mywealth:onboarding:contact:mail address"
                                    type="radio" name="sameMailingAddress"
                                    [(ngModel)]="contactInformation.sameMailingAddress" value="Yes"
                                    (change)="updateReceiveMail()" #sameMailingAddressCtrl="ngModel"
                                    [appErrorAccess]="{ id: 'ariaSelectMailingAddress-error', parentId: 'parent-ariaSelectMailingAddress-error' ,submitted: isFormSubmit}"
                                    required />
                                <label class="prefix" for="receiveYourEmailYes"
                                    translate="contactInformation.labels.primaryMailingAddress"></label>
                            </div>
                            <div class="form-radio-checks radio-default error-field"
                                [ngClass]="{'error-field': sameMailingAddressCtrl.errors && sameMailingAddressCtrl.touched || (isFormSubmit && sameMailingAddressCtrl.errors)}">
                                <input id="receiveYourEmailNo" data-ana="mywealth:onboarding:contact:mail address"
                                    type="radio" name="sameMailingAddress"
                                    [(ngModel)]="contactInformation.sameMailingAddress" value="No"
                                    (change)="updateReceiveMail()" #sameMailingAddressCtrl="ngModel"
                                    [appErrorAccess]="{ id: 'ariaSelectMailingAddress-error', parentId: 'parent-ariaSelectMailingAddress-error' ,submitted: isFormSubmit}"
                                    required />
                                <label class="prefix" for="receiveYourEmailNo"
                                    translate="contactInformation.labels.secondaryMailingAddress"></label>
                            </div>
                            <div role="alert" class="error-message" id="ariaSelectMailingAddress-error"
                                *ngIf="sameMailingAddressCtrl.errors && sameMailingAddressCtrl.touched || (isFormSubmit && sameMailingAddressCtrl.errors)">
                                <span translate="contactInformation.errors.receiveMailAddressError"></span>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <!-- differenet Mailing address start-->
            <section *ngIf="contactInformation.sameMailingAddress == 'No' " id="diffAddressSection">

                <div class="form-wrapper">

                    <div class="form-row">

                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="diffStreetNumberName" name="diffStreetNumberName"
                                        [(ngModel)]="contactInformation.diffStreetNumberName" type="text"
                                        aria-live="assertive" appAddressLengthValidator appPOBoxValidator
                                        allowPobox="true" addressAutoComplete InputValidator="ct13" [blockPaste]="true"
                                        #diffStreetNumberNameCtrl="ngModel"
                                        [required]="contactInformation.diffAddressRequired"
                                        [appErrorAccess]="{ id: 'diffStreetNumberName-error' ,submitted: isFormSubmit}"
                                        autocomplete="new-password" />
                                    <label [ngClass]="{'error-field': diffStreetNumberNameCtrl.errors && diffStreetNumberNameCtrl.touched,
                                'active-label': diffStreetNumberNameCtrl.value}" class='slide-label'
                                        for="diffStreetNumberName">
                                        <span class="label-text"
                                            translate="contactInformation.labels.streetNumberName"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="diffStreetNumberName-error"
                                *ngIf="(diffStreetNumberNameCtrl?.errors?.required && diffStreetNumberNameCtrl.touched || (isFormSubmit && diffStreetNumberNameCtrl?.errors?.required))">
                                <span translate="contactInformation.errors.invalidStreetAddress"></span>
                            </div>
                            <div role="alert" class="error-message" id="diffStreetNumberName-error"
                                *ngIf="diffStreetNumberNameCtrl?.errors?.streetNumberName">
                                <span translate="contactInformation.errors.addressError"></span>
                            </div>
                        </div>
                        <div class="flex-30 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="diffUnit" name="diffUnit" [(ngModel)]="contactInformation.diffUnit"
                                        type="text" maxlength="5" appUnitValidator InputValidator="ct12"
                                        #diffUnitCtrl="ngModel" />
                                    <label class="slide-label" for="diffUnit"
                                        [ngClass]="{'active-label': diffUnitCtrl.value}">
                                        <span class="label-text" translate="contactInformation.labels.unit"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message"
                                *ngIf="diffUnitCtrl.errors && diffUnitCtrl.touched || (isFormSubmit && diffUnitCtrl.errors)">
                                <span translate="contactInformation.errors.invalidUnitCode"></span>
                            </div>
                        </div>

                    </div>
                    <div class="form-row">

                        <div class="flex-33 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="diffCity" name="diffCity" [(ngModel)]="contactInformation.diffCity"
                                        type="text" maxlength="28" InputValidator="ct11" [blockPaste]="true"
                                        [appErrorAccess]="{ id: 'diffCity-error' ,submitted: isFormSubmit}"
                                        [required]="contactInformation.diffAddressRequired" #diffCityCtrl="ngModel" />
                                    <label
                                        [ngClass]="{'error-field': diffCityCtrl.errors && diffCityCtrl.touched || (isFormSubmit && diffCityCtrl.errors), 'active-label': diffCityCtrl.value}"
                                        for="diffCity" class="slide-label">
                                        <span class="label-text" translate="contactInformation.labels.city"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="diffCity-error"
                                *ngIf="diffCityCtrl.errors && diffCityCtrl.touched || (isFormSubmit && diffCityCtrl.errors)">
                                <span translate="contactInformation.errors.invalidCity"></span>
                            </div>
                        </div>
                        <div class="flex-33 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <select id="diffProvince" name="diffProvince"
                                        [(ngModel)]="contactInformation.diffProvince" #diffProvinceCtrl="ngModel"
                                        [appErrorAccess]="{ id: 'diffProvince-error' ,submitted: isFormSubmit}"
                                        [required]="contactInformation.diffAddressRequired"
                                        (change)="updateDiffProvince($event)">
                                        <option *ngFor="let province of getProvinces()" [ngValue]="province.code"
                                            [selected]="province.code">
                                            {{ province.label }}
                                        </option>
                                    </select>
                                    <label
                                        [ngClass]="{'error-field': diffProvinceCtrl.errors && diffProvinceCtrl.touched || (isFormSubmit && diffProvinceCtrl.errors), 'active-label': provinceCtrl.value}"
                                        class="slide-label" for="diffProvince">
                                        <span class="label-text" translate="contactInformation.labels.province"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="diffProvince-error"
                                *ngIf="diffProvinceCtrl.errors && diffProvinceCtrl.touched || (isFormSubmit && diffProvinceCtrl.errors)">
                                <span translate="contactInformation.errors.provinceSecondarySelectError"></span>
                            </div>
                        </div>
                        <div class="flex-33 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="diffPostalCode" name="diffPostalCode"
                                        [(ngModel)]="contactInformation.diffPostalCode" type="text"
                                        #diffPostalCodeCtrl="ngModel" appPostalCode appPostalCodeValidator minlength="1"
                                        maxlength="7" [required]="contactInformation.diffAddressRequired"
                                        [appErrorAccess]="{ id: 'diffPostalCode-error' ,submitted: isFormSubmit}"
                                        [value]="contactInformation.diffPostalCode | uppercase" />
                                    <label
                                        [ngClass]="{'error-field': diffPostalCodeCtrl.invalid && diffPostalCodeCtrl.touched || (isFormSubmit && diffPostalCodeCtrl.errors), 'active-label': diffPostalCodeCtrl.value}"
                                        class='slide-label' for="diffPostalCode">
                                        <span class="label-text"
                                            translate="contactInformation.labels.postalCode"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="diffPostalCode-error"
                                *ngIf="diffPostalCodeCtrl.invalid && diffPostalCodeCtrl.touched || (isFormSubmit && diffPostalCodeCtrl.errors)">
                                <span translate="contactInformation.errors.invalidPostalCode"></span>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <!-- Trusted contact person section start -->
            <section *ngIf="isMandatoryProject">

                <h1 translate="trustedContactPerson.title" class="display-inline" id="ariaTrustedPerson"></h1>

                <div class="more-info-wrapper">

                    <a role="button" id="addTCPTitleOpen" aria-controls="addTCPOpen"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'trustedContactPerson.ariaLabels.iconLabel' | translate }}"
                        data-ana="investorline:wealth:onboarding:tool tip:Trusted contact Person">
                        <span class="btn-icon-text-left" translate="linkButton.learnMore"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="addTCPOpen">
                        <p [innerHTML]="'trustedContactPerson.contextualHelp' | translate | safeHtml"></p>
                        <p>
                            <a role="button" id="addTCPTitleClose" aria-controls="addTCPTitleClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}"
                                data-ana="investorline:wealth:onboarding:tool tip:Trusted contact PersonbtnClose">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>


                <p translate="trustedContactPerson.description"></p>


                <div class="form-wrapper no-margin">

                    <div role="radiogroup" id='parent-trustedPerson-error' aria-labelledby="ariaTrustedPerson" class="form-row">

                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" *ngFor="let trustedPerson of trustedPersonList"
                                [ngClass]="{'error-field':  trustedPersonCtrl.invalid && trustedPersonCtrl.touched  || (isFormSubmit && trustedPersonCtrl.errors) }">
                                <input [attr.id]="'tcp'+trustedPerson.code"
                                    data-ana="mywealth:onboarding:contact:trusted person" type="radio"
                                    [name]="'tcp' +trustedPerson.code" #trustedPersonCtrl="ngModel"
                                    [(ngModel)]="contactInformation.trustedContactPerson.isTrustedPerson"
                                    [value]="trustedPerson.code"
                                    [appErrorAccess]="{ id: 'trustedPerson-error', parentId: 'parent-trustedPerson-error' ,submitted: isFormSubmit}"
                                    required />
                                <label class="prefix" [attr.for]="'tcp' + trustedPerson.code"
                                    [translate]="trustedPerson.label"></label>
                            </div>

                        </div>
                    </div>
                </div>

                <div *ngIf="contactInformation.trustedContactPerson.isTrustedPerson">
                    <section>
                        <div class="form-wrapper">
                            <div class="form-row">
                                <!-- <p translate="trustedContactPerson.enterDetailsMessage"></p> -->
                                <div class="flex-70 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
        
                                            <input id="tcpFirstName" data-ana="mywealth:onboarding:first name"
                                                name="tcpFirstName" type="text" minlength="2" appMinLengthValidator
                                                minLengthVal='2' [(ngModel)]="contactInformation.trustedContactPerson.firstname"
                                                #tcpfirstNameCtrl="ngModel" required InputValidator="ct19" [blockPaste]="true"
                                                [value]="tcpfirstNameCtrl.value" formatPipe="Capitalize"
                                                [appErrorAccess]="{id:'firstName-error',  submitted: isFormSubmit }" />
                                            <label
                                                [ngClass]="{'error-field': tcpfirstNameCtrl.invalid && tcpfirstNameCtrl.touched  || (isFormSubmit && tcpfirstNameCtrl.errors), 'active-label': tcpfirstNameCtrl.value}"
                                                class="slide-label" for="tcpFirstName">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.nameDetails.firstName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" id="firstName-error" class="error-message"
                                        *ngIf="tcpfirstNameCtrl.invalid && tcpfirstNameCtrl.touched  || (isFormSubmit && tcpfirstNameCtrl.errors) ||  (tcpfirstNameCtrl.touched && tcpfirstNameCtrl?.errors?.minLengthError)">
                                        <span translate="trustedContactPerson.errors.firstNameError"></span>
                                    </div>
                                </div>
                                <div class="flex-70 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpInitial" name="tcpInitial"
                                                [(ngModel)]="contactInformation.trustedContactPerson.initial" type="text"
                                                maxlength="20" InputValidator="ct18 ct5" formatPipe="Capitalize"
                                                #tcpInitialCtrl="ngModel" [value]="tcpInitialCtrl.value" />
                                            <label class="slide-label" for="tcpInitial"
                                                [ngClass]="{'active-label': tcpInitialCtrl.value}">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.nameDetails.initial"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div class="form-row">
                                <div class="flex-70 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpLastName" name="tcpLastName"
                                                [(ngModel)]="contactInformation.trustedContactPerson.lastname"
                                                data-ana="mywealth:onboarding:last name" type="text" minlength="2"
                                                appMinLengthValidator minLengthVal='2' InputValidator="ct19" [blockPaste]="true"
                                                #tcpLastNameCtrl="ngModel" required
                                                [value]="contactInformation.trustedContactPerson.lastname | capitalize:{capitalizeOnce: true}"
                                                [appErrorAccess]="{id:'lastName-error',  submitted: isFormSubmit }" />
                                            <label
                                                [ngClass]="{'error-field':tcpLastNameCtrl.invalid && tcpLastNameCtrl.touched  || (isFormSubmit && tcpLastNameCtrl.errors) ,'active-label': tcpLastNameCtrl.value}"
                                                class="slide-label" for="tcpLastName">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.nameDetails.lastName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" id="lastName-error" class="error-message"
                                        *ngIf="tcpLastNameCtrl.invalid && tcpLastNameCtrl.touched  || (isFormSubmit && tcpLastNameCtrl.errors)  || ( tcpLastNameCtrl.touched && tcpLastNameCtrl?.errors?.minLengthError)">
                                        <span translate="trustedContactPerson.errors.lastNameError"> </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <p id="tcpRelationshipDesc" translate="trustedContactPerson.labels.tcpRelationShip"></p>
                                <div class="flex-70 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input name="tcpRelationShip" id="tcpRelationShip"
                                                aria-describedby="tcpRelationshipDesc"
                                                [(ngModel)]="contactInformation.trustedContactPerson.tcpRelationShip"
                                                type="text" InputValidator="ct1"
                                                [value]="contactInformation.trustedContactPerson.tcpRelationShip"
                                                #tcpRelationShipCtrl="ngModel" required />
                                            <label
                                                [ngClass]="{'error-field': tcpRelationShipCtrl.errors && tcpRelationShipCtrl.touched  || (isFormSubmit && tcpRelationShipCtrl.errors), 'active-label': tcpRelationShipCtrl.value}"
                                                class="slide-label" for="tcpRelationShip"
                                                attr.aria-label="{{ 'trustedContactPerson.labels.tcpRelationShip' | translate }}"
                                                [ngClass]="{'active-label': tcpRelationShipCtrl.value}">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.labels.relationShip"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message"
                                        *ngIf="tcpRelationShipCtrl.errors && tcpRelationShipCtrl.touched || (isFormSubmit && tcpRelationShipCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpRelationShip"></span>
                                    </div>
                                </div>
                            </div>
        
        
                            <div class="form-row">
                                <p translate="trustedContactPerson.tcpInstructionmessage"></p>
                                <div class="flex-70 form-col">
                                    <div class="form-input-checks check-default">
                                        <input id="tcpHasInternationalNumber" type="checkbox" name="tcpHasInternationalNumber"
                                            [(ngModel)]="contactInformation.trustedContactPerson.isInternationalNumber"
                                            [value]="contactInformation.trustedContactPerson.isInternationalNumber"
                                            #tcpInternalContactNo="ngModel" />
                                        <label class="slide-label" for="tcpHasInternationalNumber"
                                            translate="trustedContactPerson.tcpInternationalNumber"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="contactInformation.trustedContactPerson.isInternationalNumber">
        
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpPhoneNumberHome" name="tcpPhoneNumberHome" type="tel" maxlength="20"
                                                minlength="3" InputValidator="ct3" 
                                                [(ngModel)]="contactInformation.trustedContactPerson.phoneNumberHome"
                                                #phoneNumberHomeCtrl="ngModel"
                                                [value]="contactInformation.trustedContactPerson.phoneNumberHome"
                                                [appErrorAccess]="{ id: 'phoneNumber-error' ,submitted: isFormSubmit}"
                                                [required]="!isTcpAddressCollected() && !isTcpEmailCollected() && !isTcpOtherPhoneCollected()" />
        
        
                                            <label class="slide-label" for="tcpPhoneNumberHome"
                                                [ngClass]="{'error-field': phoneNumberHomeCtrl.errors && phoneNumberHomeCtrl.touched || (isFormSubmit && phoneNumberHomeCtrl.errors), 'active-label': phoneNumberHomeCtrl.value}">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.labels.phoneNumberHome"></span>
                                            </label>
                                        </div>
        
                                    </div>
                                    <div role="alert" class="error-message" id="phoneNumberHome-error"
                                        *ngIf="phoneNumberHomeCtrl.errors && phoneNumberHomeCtrl.touched || (isFormSubmit && phoneNumberHomeCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpHomePhoneNumber"></span>
                                    </div>
        
                                </div>
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpPhoneNumberOther" name="tcpPhoneNumberOther" type="tel" maxlength="20"
                                                minlength="3" InputValidator="ct3" 
                                                [(ngModel)]="contactInformation.trustedContactPerson.phoneNumberOther"
                                                #phoneNumberOtherCtrl="ngModel"
                                                [value]="contactInformation.trustedContactPerson.phoneNumberOther"
                                                [appErrorAccess]="{ id: 'phoneNumber-error' ,submitted: isFormSubmit}"
                                                [required]="!isTcpAddressCollected() && !isTcpEmailCollected() && !isTcpHomePhoneCollected()" />
                                            <label class="slide-label" for="tcpPhoneNumberOther"
                                                [ngClass]="{'error-field': phoneNumberOtherCtrl.errors && phoneNumberHomeCtrl.touched || (isFormSubmit && phoneNumberOtherCtrl.errors), 'active-label': phoneNumberOtherCtrl.value}">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.labels.phoneNumberOther"></span>
                                            </label>
                                        </div>
        
                                    </div>
                                    <div role="alert" class="error-message" id="phoneNumberOther-error"
                                        *ngIf="phoneNumberOtherCtrl.errors && phoneNumberOtherCtrl.touched || (isFormSubmit && phoneNumberOtherCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpOtherPhoneNumber"></span>
                                    </div>
        
                                </div>
                            </div>
        
                            <div class="form-row" *ngIf="isValueNull(contactInformation.trustedContactPerson.isInternationalNumber) || !contactInformation.trustedContactPerson.isInternationalNumber">
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpPhoneNumberHome" name="tcpPhoneNumberHome" type="tel" maxlength="10"
                                                minlength="10" InputValidator="ct3" appPhoneNumber
                                                [(ngModel)]="contactInformation.trustedContactPerson.phoneNumberHome"
                                                #phoneNumberHomeCtrl="ngModel"
                                                [value]="contactInformation.trustedContactPerson.phoneNumberHome"
                                                [appErrorAccess]="{ id: 'phoneNumber-error' ,submitted: isFormSubmit}"
                                                [required]="!isTcpAddressCollected() && !isTcpEmailCollected() && !isTcpOtherPhoneCollected()" />
        
        
                                            <label class="slide-label" for="tcpPhoneNumberHome"
                                                [ngClass]="{'error-field': phoneNumberHomeCtrl.errors && phoneNumberHomeCtrl.touched || (isFormSubmit && phoneNumberHomeCtrl.errors), 'active-label': phoneNumberHomeCtrl.value}">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.labels.phoneNumberHome"></span>
                                            </label>
                                        </div>
        
                                    </div>
                                    <div role="alert" class="error-message" id="phoneNumberHome-error"
                                        *ngIf="phoneNumberHomeCtrl.errors && phoneNumberHomeCtrl.touched || (isFormSubmit && phoneNumberHomeCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpHomePhoneNumber"></span>
                                    </div>
        
                                </div>
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpPhoneNumberOther" name="tcpPhoneNumberOther" type="tel" maxlength="10"
                                                minlength="10" InputValidator="ct3" appPhoneNumber
                                                [(ngModel)]="contactInformation.trustedContactPerson.phoneNumberOther"
                                                #phoneNumberOtherCtrl="ngModel"
                                                [value]="contactInformation.trustedContactPerson.phoneNumberOther"
                                                [appErrorAccess]="{ id: 'phoneNumber-error' ,submitted: isFormSubmit}"
                                                [required]="!isTcpAddressCollected() && !isTcpEmailCollected() && !isTcpHomePhoneCollected()" />
                                            <label class="slide-label" for="tcpPhoneNumberOther"
                                                [ngClass]="{'error-field': phoneNumberOtherCtrl.errors && phoneNumberHomeCtrl.touched || (isFormSubmit && phoneNumberOtherCtrl.errors), 'active-label': phoneNumberOtherCtrl.value}">
                                                <span class="label-text"
                                                    translate="trustedContactPerson.labels.phoneNumberOther"></span>
                                            </label>
                                        </div>
        
                                    </div>
                                    <div role="alert" class="error-message" id="phoneNumberOther-error"
                                        *ngIf="phoneNumberOtherCtrl.errors && phoneNumberOtherCtrl.touched || (isFormSubmit && phoneNumberOtherCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpOtherPhoneNumber"></span>
                                    </div>
        
                                </div>
                            </div>
        
        
        
        
        
        
        
                            <div class="form-row">
                                <div class="flex-70 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpEmailAddress"
                                                data-ana="mywealth:onboarding:contact:trusted Person email"
                                                class="no-text-transform" type="text" name="tcpEmailAddress"
                                                [(ngModel)]="contactInformation.trustedContactPerson.emailAddress"
                                                maxlength="40" #tcpEmailAddressCtrl="ngModel" appEmailFormat
                                                InputValidator="ct20"
                                                pattern="^(?!.*\.{2})[a-zA-Z0-9._-]*[^\W_.]+@[^\W_]([.]?[^\W]*[\w-])*\.[a-zA-Z]{2,3}$"
                                                [appErrorAccess]="{ id: 'tcpEmailAddress-error', submitted: isFormSubmit}"
                                                [required]="!isTcpAddressCollected() && !isTcpPhoneCollected()" />
                                            <label
                                                [ngClass]="{'error-field': tcpEmailAddressCtrl.errors && tcpEmailAddressCtrl.touched  || (isFormSubmit && tcpEmailAddressCtrl.errors), 'active-label': tcpEmailAddressCtrl.value}"
                                                class="slide-label" for="tcpEmailAddress">
                                                <span class="label-text"
                                                    translate="contactInformation.labels.emailAddress"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" id="tcpEmailAddress-error" class="error-message"
                                        *ngIf="tcpEmailAddressCtrl.errors && tcpEmailAddressCtrl.touched || (isFormSubmit && tcpEmailAddressCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpEmailError"></span>
                                    </div>
                                </div>
                            </div>
        
                            <!-- <div class="form-row">
        
                                <div class="flex-70 form-col">
                                    <div class="form-field">
        
                                        <div class="form-label-group">
                                            <select id="tcpCountry" name="tcpCountry"
                                                [(ngModel)]="contactInformation.trustedContactPerson.country"
                                                #selectTcpCountryCtrl="ngModel" (change)="tcpCountryChange($event)"
                                                [appErrorAccess]="{ id: 'tcpCountryOption-error', submitted: isFormSubmit}"
                                                [required]="!isTcpEmailCollected() && !isTcpPhoneCollected()">
        
                                                <option *ngFor="let country of (references|| {}).countries"
                                                    [value]="country.code" [selected]="country.selected">
                                                    {{ country.label }}
                                                </option>
                                            </select>
                                            <label class="slide-label" for="tcpCountry"
                                                [ngClass]="{'error-field': selectTcpCountryCtrl?.invalid && selectTcpCountryCtrl?.touched || (isFormSubmit && selectTcpCountryCtrl?.errors), 'active-label': contactInformation.trustedContactPerson.country }">
                                                <span class="label-text"
                                                    translate="contactInformation.labels.selectCountry"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" id="tcpCountryOption-error" class="error-message"
                                        *ngIf="selectTcpCountryCtrl.invalid && selectTcpCountryCtrl.touched  || (isFormSubmit && selectTcpCountryCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpCountryError"> </span>
                                    </div>
                                </div>
                            </div> -->
        
        
                            <!-- <div class="form-row">
                                <div class="flex-70 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpStreetNumberName"
                                                data-ana="mywealth:onboarding:trusted Contact Person:street"
                                                name="tcpStreetNumberName"
                                                [(ngModel)]="contactInformation.trustedContactPerson.streetNumberName"
                                                type="text" appAddressLengthValidator #tcpStreetNumberNameCtrl="ngModel"
                                                aria-live="assertive" InputValidator="ct14" [blockPaste]="true"
                                                (focus)="onStreetNameChange($event)"
                                                [required]="!isTcpEmailCollected() && !isTcpPhoneCollected()"
                                                [appErrorAccess]="{ id: 'tcpStreetNumberName-error' ,submitted: isFormSubmit}"
                                                autocomplete="new-password"
                                                [SelectedCountry]="contactInformation.trustedContactPerson.country" />
        
                                            <label
                                                [ngClass]="{'error-field': tcpStreetNumberNameCtrl.errors && tcpStreetNumberNameCtrl.touched || (isFormSubmit && tcpStreetNumberNameCtrl.errors), 'active-label': tcpStreetNumberNameCtrl.value}"
                                                class='slide-label' for="tcpStreetNumberName">
                                                <span class="label-text"
                                                    translate="contactInformation.labels.streetNumberName"></span>
                                            </label>
                                        </div>
                                    </div>
        
                                    <div role="alert" class="error-message" id="tcpStreetNumberName-error"
                                        *ngIf="tcpStreetNumberNameCtrl.errors && tcpStreetNumberNameCtrl.touched || (isFormSubmit && tcpStreetNumberNameCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpStreetNameError"></span>
                                    </div>
                                </div>
                                <div class="flex-30 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input name="tcpUnit" id="tcpUnit"
                                                [(ngModel)]="contactInformation.trustedContactPerson.unit" type="text"
                                                maxlength="5" appUnitValidator InputValidator="ct12" #tcpUnitCtrl="ngModel" />
                                            <label class="slide-label" for="tcpUnit"
                                                [ngClass]="{'active-label': tcpUnitCtrl.value}">
                                                <span class="label-text" translate="contactInformation.labels.unit"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message"
                                        *ngIf="tcpUnitCtrl.errors && tcpUnitCtrl.touched || (isFormSubmit && tcpUnitCtrl.errors)">
                                        <span translate="contactInformation.errors.invalidUnitCode"></span>
                                    </div>
                                </div>
        
                            </div> -->
                            <!-- <div class="form-row">
                                <div class="flex-33 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="tcpCity" name="tcpCity"
                                                [(ngModel)]="contactInformation.trustedContactPerson.city" type="text"
                                                maxlength="28" InputValidator="ct11" [blockPaste]="true"
                                                [appErrorAccess]="{ id: 'tcp-city-error' ,submitted: isFormSubmit}"
                                                [required]="!isTcpEmailCollected() && !isTcpPhoneCollected()"
                                                #tcpCityCtrl="ngModel" />
                                            <label
                                                [ngClass]="{'error-field': tcpCityCtrl.errors && tcpCityCtrl.touched || (isFormSubmit && tcpCityCtrl.errors), 'active-label': tcpCityCtrl.value}"
                                                for="city" class="slide-label">
                                                <span class="label-text" translate="contactInformation.labels.city"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id="tcp-city-error"
                                        *ngIf="tcpCityCtrl.errors && tcpCityCtrl.touched || (isFormSubmit && tcpCityCtrl.errors)">
                                        <span translate="trustedContactPerson.errors.tcpCityError"></span>
                                    </div>
                                </div>
                                <ng-container *ngIf="isCanada || isUS">
                                    <div class="flex-33 form-col">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <select id="tcpProvince" name="tcpProvince"
                                                    [(ngModel)]="contactInformation.trustedContactPerson.province"
                                                    #tcpProvinceCtrl="ngModel"
                                                    [appErrorAccess]="{ id: 'tcpProvince-error' ,submitted: isFormSubmit}"
                                                    [required]="!isTcpEmailCollected() && !isTcpPhoneCollected()">
                                                    <option
                                                        *ngFor="let tcpProvince of getProvinces(contactInformation.trustedContactPerson.country)"
                                                        [ngValue]="tcpProvince.code" [selected]="tcpProvince.selected">
                                                        {{ tcpProvince.label }}
                                                    </option>
                                                </select>
                                                <label #tcpProviceLabelCtrl
                                                    [ngClass]="{'error-field': (isFormSubmit && tcpProvinceCtrl.errors) || tcpProvinceCtrl.errors?.notAgeOfMajority , 'active-label': tcpProvinceCtrl.value}"
                                                    class="slide-label" for="tcpProvince">
                                                    <span *ngIf="isCanada" class="label-text"
                                                        translate="contactInformation.labels.province"></span>
                                                    <span *ngIf="isUS" class="label-text"
                                                        translate="contactInformation.labels.state"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div role="alert" class="error-message" id="tcpProvince-error"
                                            *ngIf="tcpProvinceCtrl?.errors?.selectionRequired && tcpProvinceCtrl.touched || (isFormSubmit && tcpProvinceCtrl.errors)">
                                            <span *ngIf="isCanada"
                                                translate="trustedContactPerson.errors.tcpProvinceError"></span>
                                            <span *ngIf="isUS" translate="trustedContactPerson.errors.tcpStateError"></span>
                                        </div>
                                        <div role="alert" class="error-message" id="tcpProvince-error"
                                            *ngIf="tcpProvinceCtrl?.errors?.notAgeOfMajority">
                                            <span translate="contactInformation.errors.notAgeOfMajority"></span>
                                        </div>
                                    </div>
                                    <div class="flex-33 form-col">
                                        <div class="form-field">
                                            <div class="form-label-group">
                                                <ng-container *ngIf="isCanada">
                                                    <input id="tcpPostalCode" name="tcpPostalCode" type="text" minlength="1"
                                                        maxlength="7" appPostalCode appPostalCodeValidator
                                                        [(ngModel)]="contactInformation.trustedContactPerson.postalCode"
                                                        #tcpPostalCodeCtrl="ngModel"
                                                        [appErrorAccess]="{ id: 'tcpPostalCode-error' ,submitted: isFormSubmit}"
                                                        [required]="!isTcpEmailCollected() && !isTcpPhoneCollected()"
                                                        [value]="contactInformation.trustedContactPerson.postalCode | uppercase" />
                                                    <label
                                                        [ngClass]="{'error-field': tcpPostalCodeCtrl.errors && tcpPostalCodeCtrl.touched || (isFormSubmit && tcpPostalCodeCtrl.errors), 'active-label': tcpPostalCodeCtrl.value}"
                                                        class='slide-label' for="tcpPostalCode">
                                                        <span class="label-text"
                                                            translate="contactInformation.labels.postalCode"></span>
        
                                                    </label>
                                                    <div role="alert" class="error-message" id="tcpPostalCode-error"
                                                        *ngIf="tcpPostalCodeCtrl.errors && tcpPostalCodeCtrl.touched || (isFormSubmit && tcpPostalCodeCtrl.errors)">
                                                        <span translate="trustedContactPerson.errors.tcpPostalCodeError"></span>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="isUS">
                                                    <input id="tcpZipCode" name="tcpZipCode" type="text" minlength="1"
                                                        maxlength="12"
                                                        [(ngModel)]="contactInformation.trustedContactPerson.postalCode"
                                                        #tcpZipCodeCtrl="ngModel"
                                                        [appErrorAccess]="{ id: 'tcpZipCode-error' ,submitted: isFormSubmit}"
                                                        [required]="!isTcpEmailCollected() && !isTcpPhoneCollected()"
                                                        [value]="contactInformation.trustedContactPerson.postalCode | uppercase" />
        
                                                    <label
                                                        [ngClass]="{'error-field': tcpZipCodeCtrl.errors && tcpZipCodeCtrl.touched || (isFormSubmit && tcpZipCodeCtrl.errors), 'active-label': tcpZipCodeCtrl.value}"
                                                        class='slide-label' for="tcpZipCode">
                                                        <span class="label-text"
                                                            translate="contactInformation.labels.zipCode"></span>
                                                    </label>
                                                    <div role="alert" class="error-message" id="tcpZipCode-error"
                                                        *ngIf="tcpZipCodeCtrl.errors && tcpZipCodeCtrl.touched || (isFormSubmit && tcpZipCodeCtrl.errors)">
        
                                                        <span translate="trustedContactPerson.errors.tcpZipCodeError"></span>
                                                    </div>
                                                </ng-container>
        
        
                                            </div>
                                        </div>
        
                                    </div>
                                </ng-container>
                            </div> -->
        
        
        
        
                        </div>
                    </section>
        
        
                </div>
            </section>
            <!-- trusted contact person section ends -->

        </section>
     
        <section>
            <app-form-buttons [appInvalidForm]="appInvalidForm" [showFinishLater]="showFinishLater" formName="contact"
                [backRoute]="APP_ROUTES.personal_information"></app-form-buttons>
        </section>

    </div>

</form>

<!--
<pre> is this form valid? {{ contactInformationForm.valid }} </pre>
<pre> {{ contactInformationForm.value | json }} </pre>  -->