import { Injectable } from '@angular/core';

function _window(): any {
   // return the global native browser window object
   return window;
}

@Injectable()
export class WindowRefService {

  constructor() { }

  get nativeWindow(): any {
     return _window();
  }

  isIEBrowser() {
   return /msie\s|trident\//i.test(window.navigator.userAgent);
  }
   isIOS() {
   return [
     'iPad Simulator',
     'iPhone Simulator',
     'iPod Simulator',
     'iPad',
     'iPhone',
     'iPod'
   ].includes(navigator.userAgent)
   // iPad on iOS 13 detection
   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
 }

}
