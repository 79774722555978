<!-- <app-flood-light></app-flood-light> -->

<form #personalDetailsForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <app-critical-error *ngIf="systemCriticalError"></app-critical-error>
    <div class="container-menuView">

        <section>

            <app-invalid-form #appInvalidForm [isFormInvalid]="personalDetailsForm.invalid && isFormSubmit"
                translateTag="personalInformation"></app-invalid-form>
            <div role="alert" class="alert alert-danger" *ngIf="isReferenceCallFailed">
                <p>
                    <strong translate="personalInformation.errors.criticalError"></strong>
                    <br />
                    <span translate="personalInformation.errors.criticalErrorDescription"></span>
                </p>
            </div>

            <div class="aoda-off-screen-element" aria-atomic="true">
                <div id="deleteCountryButton" style="display: none;" role="alert" aria-live="polite">{{deletedCountry}}
                    {{
                    'personalInformation.ariaLabels.countryDeleted' | translate }}</div>
                <div id="emptyCountryDeleteButton" style="display: none;" role="alert" aria-live="polite">{{
                    'personalInformation.ariaLabels.countryFeildDeleted' | translate }}</div>
            </div>

            <section id="nameSection">
                <h1 translate="personalInformation.title"></h1>
                <p translate="personalInformation.description"></p>
                <!-- MWB-13838 Remove Prefix Fields Data Rationalization
                <div class="header-contextual-help">
                    <p id="ariaSelectPrefix" class="font-bold display-inline" translate="personalInformation.prefix"></p>
                    <app-contextual-help class="button-wrapper va-middle"
                    content="personalInformation.contextualHelp.prefix"
                     arialabel="personalInformation.ariaLabels.prefixLabel"
                     inputDataAna="investorline:wealth:onboarding:tool tip:prefix">
                    </app-contextual-help>
                </div> -->
                <div class="form-wrapper">

                    <!-- MWB-13838 Remove Prefix Fields Data Rationalization
                    <div role="radiogroup" aria-labelledby="ariaSelectPrefix" id='parent-ariaSelectPrefix-error' class="form-row">
                        <div class="flex-elements form-col">
                            <div class="form-radio-checks radio-buttons" *ngFor="let prefix of prefixes" [ngClass]="{'error-field': !personalInformation.prefix && isFormSubmit }">
                                <input [attr.id]="prefix.code == null ? 'null' : prefix.label" class="prefixel" type="radio" [attr.data-ana]="'mywealth:onboarding:prefix ' + prefix.label"
                                    name="prefix" [value]="prefix.code == null ? 'null' : prefix.code" [(ngModel)]="personalInformation.prefix"
                                    #myPrefix="ngModel" required (change)="selectPrefix(prefix)" [appErrorAccess]="{ id: 'ariaSelectPrefix-error', parentId: 'parent-ariaSelectPrefix-error', submitted: isFormSubmit}"
                                />

                                // added to overwrite NA to NONE on UI for both en and fr
                                <label *ngIf="prefix.code != null" class="prefix" [attr.for]="prefix.code == null ? 'null' : prefix.label">
                                    {{ prefix.label }}
                                </label>

                                <label *ngIf="prefix.code === null && lang ==='en'" class="prefix" [attr.for]="prefix.code == null ? 'null' : prefix.label"
                                    translate="personalInformation.prefixNone"></label>

                                <label *ngIf="prefix.code === null && lang ==='fr'" class="prefix" [attr.for]="prefix.code == null ? 'null' : prefix.label"
                                    translate="personalInformation.prefixNone"></label>

                            </div>
                            <div role="alert" id="ariaSelectPrefix-error" class="error-message" *ngIf="!personalInformation.prefix && isFormSubmit">
                                <span translate="personalInformation.errors.prefixRequired"></span>
                            </div>
                        </div>
                    </div> -->

                    <div class="form-row">
                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="firstName" data-ana="mywealth:onboarding:first name" name="firstName"
                                        type="text" minlength="2" appMinLengthValidator minLengthVal='2'
                                        [(ngModel)]="personalInformation.firstname" #firstnameCtrl="ngModel"
                                        [attr.disabled]="disableOnPrefillName" [readonly]="isCdbOlbUser || ilJourneyUser " required
                                        InputValidator="ct19" [blockPaste]="true" formatPipe="Capitalize"
                                        [value]="firstnameCtrl.value"
                                        [appErrorAccess]="{id:'firstName-error',  submitted: isFormSubmit }" />
                                    <label
                                        [ngClass]="{'error-field': firstnameCtrl.invalid && firstnameCtrl.touched  || (isFormSubmit && firstnameCtrl.errors), 'active-label': firstnameCtrl.value}"
                                        class="slide-label" for="firstName">
                                        <span class="label-text"
                                            translate="personalInformation.nameDetails.firstName"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="firstName-error" class="error-message"
                                *ngIf="firstnameCtrl.invalid && firstnameCtrl.touched  || (isFormSubmit && firstnameCtrl.errors) ||  (firstnameCtrl.touched &&firstnameCtrl?.errors?.minLengthError)">
                                <span translate="personalInformation.errors.firstNameError"></span>
                            </div>
                        </div>
                        <div class="flex-30 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="initial" name="initial" [(ngModel)]="personalInformation.initial"
                                        type="text" [attr.disabled]="disableOnPrefillName"
                                        [attr.maxlength]="(isCdbOlbUser || ilJourneyUser) ? null : 20" InputValidator="ct18 ct5"
                                        formatPipe="Capitalize" #initialCtrl="ngModel" [value]="initialCtrl.value"
                                        [readonly]="isCdbOlbUser || ilJourneyUser" />
                                    <label class="slide-label" for="initial"
                                        [ngClass]="{'active-label': initialCtrl.value}">
                                        <span class="label-text"
                                            translate="personalInformation.nameDetails.initial"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="lastName" name="lastName" [(ngModel)]="personalInformation.lastname"
                                        data-ana="mywealth:onboarding:last name" type="text" minlength="2"
                                        appMinLengthValidator minLengthVal='2' InputValidator="ct19"
                                        [attr.disabled]="disableOnPrefillName" [readonly]="isCdbOlbUser || ilJourneyUser"
                                        [blockPaste]="true" formatPipe="Capitalize" #lastnameCtrl="ngModel" required
                                        [value]="lastnameCtrl.value | capitalize:{capitalizeOnce: formService.forms.personalInformation.data?false:true}"
                                        [appErrorAccess]="{id:'lastName-error',  submitted: isFormSubmit }" />
                                    <label
                                        [ngClass]="{'error-field':lastnameCtrl.invalid && lastnameCtrl.touched  || (isFormSubmit && lastnameCtrl.errors) ,'active-label': lastnameCtrl.value}"
                                        class="slide-label" for="lastName">
                                        <span class="label-text"
                                            translate="personalInformation.nameDetails.lastName"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="lastName-error" class="error-message"
                                *ngIf="lastnameCtrl.invalid && lastnameCtrl.touched  || (isFormSubmit && lastnameCtrl.errors)  || ( lastnameCtrl.touched && lastnameCtrl?.errors?.minLengthError)">
                                <span translate="personalInformation.errors.lastNameError"> </span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="isAMLFunctionalityEnabled">
                        <div class="form-row">
                            <div class="form-col">
                                <div class="form-input-checks check-default">
                                    <input type="checkbox" (click)="onPreferredNameToggle()" id="likeToUsePreferredName"
                                        name="likeToUsePreferredName" #preferedNameCtrl
                                        [checked]="personalInformation.likeToUsePreferredName" />
                                    <label class="label-text" for="likeToUsePreferredName"
                                        translate="personalInformation.nameDetails.checkPreferredName"></label>
                                </div>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="personalInformation.likeToUsePreferredName">
                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="preferredFirstName" name="preferredFirstName"
                                            [(ngModel)]="personalInformation.preferredFirstName"
                                            data-ana="mywealth:onboarding:preferred first name" type="text"
                                            minlength="2" appMinLengthValidator minLengthVal='2' InputValidator="ct19"
                                            [blockPaste]="true" #preferredFirstNameCtrl="ngModel" required
                                            [value]="preferredFirstNameCtrl.value | capitalize:{capitalizeOnce: formService.forms.personalInformation.data?false:true}"
                                            [appErrorAccess]="{id:'lastName-error',  submitted: isFormSubmit }"
                                            formatPipe="Capitalize" />
                                        <label
                                            [ngClass]="{'error-field':preferredFirstNameCtrl.invalid && preferredFirstNameCtrl.touched  || (isFormSubmit && preferredFirstNameCtrl.errors) ,'active-label': preferredFirstNameCtrl.value}"
                                            class="slide-label" for="preferredFirstName">
                                            <span class="label-text"
                                                translate="personalInformation.nameDetails.preferredFirstName"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" id="preferredFirstName-error" class="error-message"
                                    *ngIf="preferredFirstNameCtrl.invalid && preferredFirstNameCtrl.touched  || (isFormSubmit && preferredFirstNameCtrl.errors)  || ( preferredFirstNameCtrl.touched && preferredFirstNameCtrl?.errors?.minLengthError)">
                                    <span translate="personalInformation.errors.preferredFirstNameError"> </span>
                                </div>
                            </div>
                            <div class="flex-30 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="preferredMiddleName" name="preferredMiddleName"
                                            [(ngModel)]="personalInformation.preferredMiddleName" type="text"
                                            maxlength="20" InputValidator="ct18 ct5" formatPipe="Capitalize"
                                            #preferredMiddleNameCtrl="ngModel"
                                            [value]="preferredMiddleNameCtrl.value" />
                                        <label class="slide-label" for="initial"
                                            [ngClass]="{'active-label': preferredMiddleNameCtrl.value}">
                                            <span class="label-text"
                                                translate="personalInformation.nameDetails.preferredMiddleName"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="personalInformation.likeToUsePreferredName">
                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="preferredLastName" name="preferredLastName"
                                            [(ngModel)]="personalInformation.preferredLastName"
                                            data-ana="mywealth:onboarding:preferred last name" type="text" minlength="2"
                                            appMinLengthValidator minLengthVal='2' InputValidator="ct19"
                                            [blockPaste]="true" #preferredLastNameCtrl="ngModel" required
                                            [value]="preferredLastNameCtrl.value | capitalize:{capitalizeOnce: formService.forms.personalInformation.data?false:true}"
                                            [appErrorAccess]="{id:'lastName-error',  submitted: isFormSubmit }"
                                            formatPipe="Capitalize" />
                                        <label
                                            [ngClass]="{'error-field':preferredLastNameCtrl.invalid &&preferredLastNameCtrl.touched  || (isFormSubmit && preferredLastNameCtrl.errors) ,'active-label': preferredLastNameCtrl.value}"
                                            class="slide-label" for="preferredLastName">
                                            <span class="label-text"
                                                translate="personalInformation.nameDetails.preferredLastName"></span>
                                        </label>
                                    </div>
                                </div>
                                <div role="alert" id="preferredLastName-error" class="error-message"
                                    *ngIf="preferredLastNameCtrl.invalid && preferredLastNameCtrl.touched  || (isFormSubmit && preferredLastNameCtrl.errors)  || ( preferredLastNameCtrl.touched && preferredLastNameCtrl?.errors?.minLengthError)">
                                    <span translate="personalInformation.errors.preferredLastNameError"> </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </section>

            <section id="birthdaySection">
                <h1 translate="personalInformation.titleBirthday"></h1>
                <p translate="personalInformation.titleBirthdayDescription"></p>
                <div class="alert alert-yellow" role="alert" *ngIf="prefillDOB.softWarningMsg && prefillDOB.dateOfBirth
                && prefillDOB.dateOfBirth !== personalInformation.dateOfBirth && dateOfBirthCtrl?.valid">
                    <p>
                        <strong
                            *ngIf="(prefillSIN.softWarningMsg && prefillSIN.sinNumber
                        && prefillSIN.sinNumber !== personalInformation.sinNumber && appSinNumberCtrl?.valid);else onlyBirthday"
                            translate="personalInformation.warnings.titleBirthdayAndSIN"></strong>
                        <ng-template #onlyBirthday>
                            <strong *ngIf="true" translate="personalInformation.warnings.titleBirthday"></strong>
                        </ng-template>
                        <br /><span translate="personalInformation.warnings.description"></span>
                    </p>
                </div>
                <div class="form-wrapper">
                    <div class="form-row">
                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <app-date-picker [readonly]="(disableOnPrefillDOB)" id="dateOfBirth"
                                        name="dateOfBirth" [showWeekdays]="true"
                                        [(ngModel)]="personalInformation.dateOfBirth" [isAgeValidation]="true"
                                        ngDefaultControl #dateOfBirthCtrl="ngModel">
                                    </app-date-picker>
                                </div>
                                <div class="form-field-button">
                                    <app-contextual-help class="button-wrapper"
                                        arialabel="personalInformation.ariaLabels.dateOfBirthLabel"
                                        content="personalInformation.contextualHelp.datePicker"
                                        inputDataAna="investorline:wealth:onboarding:tool tip:date of birth">
                                    </app-contextual-help>
                                </div>
                            </div>

                            <div role="alert" class="error-message"
                                *ngIf="dateOfBirthCtrl?.invalid && dateOfBirthCtrl?.touched || (isFormSubmit && dateOfBirthCtrl?.errors)">
                                <span *ngIf="dateOfBirthCtrl?.errors?.invalidDateOfBirth"
                                    translate="personalInformation.errors.invalidDateOfBirth"></span>
                                <span *ngIf="dateOfBirthCtrl?.errors?.invalidAgeUnderEighteen"
                                    translate="personalInformation.errors.invalidAgeUnderEighteen"></span>
                                <span *ngIf="dateOfBirthCtrl?.errors?.invalidNotUnder72"
                                    translate="personalInformation.errors.invalidNotUnder72"></span>
                                <span *ngIf="dateOfBirthCtrl?.errors?.notAgeOfMajority"
                                    translate="personalInformation.errors.notAgeOfMajority"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="sinSection">
                <h1 translate="personalInformation.titleSIN"></h1>
                <div class="alert alert-yellow" role="alert"
                    *ngIf="(prefillSIN.softWarningMsg && prefillSIN.sinNumber
                && prefillSIN.sinNumber !== personalInformation.sinNumber && appSinNumberCtrl?.valid) &&
                 (dateOfBirthCtrl?.invalid || prefillDOB.dateOfBirth == personalInformation.dateOfBirth || !prefillDOB.dateOfBirth)">
                    <p>
                        <strong translate="personalInformation.warnings.titleSIN"></strong>
                        <br /><span translate="personalInformation.warnings.description"></span>
                    </p>
                </div>
                <div class="form-wrapper">
                    <div class="form-row">
                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">

                                    <input data-ana="mywealth:onboarding:sin" id="appSinNumber" name="appSinNumber"
                                        [readonly]="(disableOnPrefillSin)" #appSinNumberCtrl="ngModel"
                                        [(ngModel)]="personalInformation.sinNumber" appSIN type="tel" minlength="9"
                                        maxlength="9"
                                        [appErrorAccess]="{id:'appSinNumber-error',  submitted: isFormSubmit }"
                                        InputValidator="ct3" appSinValidator appSpousalSinValidator required />
                                    <label
                                        [ngClass]="{'error-field': appSinNumberCtrl.invalid && appSinNumberCtrl.touched  || (isFormSubmit && appSinNumberCtrl.errors), 'active-label': appSinNumberCtrl.value}"
                                        class="slide-label" for="appSinNumber">
                                        <span class="label-text"
                                            translate="personalInformation.labels.sinNumber"></span>
                                    </label>

                                </div>
                                <div class="form-field-button">
                                    <app-contextual-help class="button-wrapper"
                                        arialabel="personalInformation.ariaLabels.sinLabel"
                                        content="personalInformation.contextualHelp.sinNumber"
                                        aria_labelledby="personalInformation.labels.sinNumber"
                                        inputDataAna="investorline:wealth:onboarding:tool tip:social insurance">
                                    </app-contextual-help>
                                </div>
                            </div>
                            <div role="alert" id="appSinNumber-error" class="error-message"
                                *ngIf="(!appSinNumberCtrl?.errors?.spousalSinNumber) && (appSinNumberCtrl.invalid && appSinNumberCtrl.touched || (isFormSubmit && appSinNumberCtrl.errors))">
                                <span
                                    [innerHTML]="'personalInformation.errors.sinNumberError' | translate | safeHtml"></span>
                            </div>
                            <div role="alert" id="appSinNumber-error" class="error-message"
                                *ngIf="appSinNumberCtrl?.errors?.spousalSinNumber && ! appSinNumberCtrl?.errors?.sinNumber">
                                <span
                                    [innerHTML]="'personalInformation.errors.spouseSinNumberNotSame' | translate | safeHtml"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Using SIN component -->
            </section>

            <!--Email address MWB-11345-->
            <section id="emailSection">

                <h1 translate="contactInformation.titledifferentEmailAddress"></h1>
                <p translate="contactInformation.descriptionEmailAddress"></p>

                <div class="form-wrapper">

                    <div class="form-row">
                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="emailAddress" data-ana="mywealth:onboarding:contact:email"
                                        class="no-text-transform" type="text" name="emailAddress"
                                        [(ngModel)]="personalInformation.emailAddress" maxlength="40"
                                        #emailAddressCtrl="ngModel" appEmailFormat InputValidator="ct20"
                                        pattern="^(?!.*\.{2})[a-zA-Z0-9._-]*[^\W_.]+@[^\W_]([.]?[^\W]*[\w-])*\.[a-zA-Z]{2,3}$"
                                        [appErrorAccess]="{ id: 'emailAddress-error', submitted: isFormSubmit}"
                                        required />
                                    <label
                                        [ngClass]="{'error-field': emailAddressCtrl.errors && emailAddressCtrl.touched  || (isFormSubmit && emailAddressCtrl.errors), 'active-label': emailAddressCtrl.value}"
                                        class="slide-label" for="emailAddress">
                                        <span class="label-text"
                                            translate="contactInformation.labels.emailAddress"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="emailAddress-error" class="error-message"
                                *ngIf="emailAddressCtrl.errors && emailAddressCtrl.touched || (isFormSubmit && emailAddressCtrl.errors)">
                                <span translate="contactInformation.errors.invalidEmail"></span>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            <!-- QUESTION: Are you a Canadian citizen? YES or NO -->
            <!-- ACCOUNT: THIS SHOWS FOR TFSA | RRSP | SRRSP | RRIF -->
            <!-- MWB-16259 (Q1)-->
            <section id="groupCitizenSection"
                *ngIf="((isCash || isMargin) && !isCRAtaxresidencyEnabled) || !(isCash || isMargin)">
                <div role="radiogroup" aria-labelledby="groupCitizen" id='parent-canadianCitizenship-error'>

                    <h1 id="groupCitizen" translate="personalInformation.titleSectionCanadaCitizenship"></h1>
                    <div class="form-wrapper">
                        <div class="form-row">
                            <app-confirmation-yes-no #canadianCitizenshipCtrl="ngModel" id="canadianCitizenship"
                                dataAna="mywealth:onboarding:canadiancitizenship" name="canadianCitizenship"
                                [isFormSubmit]="isFormSubmit" ngDefaultControl [(ngModel)]="_isCanadian"
                                [accessId]="{ id: 'canadianCitizenship-error', parentId: 'parent-canadianCitizenship-error',  submitted: isFormSubmit }"
                                errorMessage="personalInformation.errors.canadianCitizenshipError"
                                (change)="setCanadianCitizenship()">
                            </app-confirmation-yes-no>
                        </div>
                        <div role="alert" id="nocitizenshipError" class="error-message"
                            *ngIf="personalDetailsForm.controls['canadianCitizenship']?.errors?.isCitizenError">
                            <span translate="personalInformation.errors.noCitizenshipError"></span>
                        </div>
                    </div>
                </div>
            </section>

            <section id="groupCitizenCashMarginSection">
                <!-- QUESTION: Are you a Canadian citizen? YES or NO -->
                <!-- ACCOUNT: ONLY CASH/MARGIN -->
                <!-- MWB-16259-->

                <div id="groupCitizenCashMarginSection"
                    *ngIf="isCRAtaxresidencyEnabled && isCaaTaxResidencyCertificateEnabled && (isCash || isMargin)">
                    <h1 id="groupCitizen" class="add-margin b24px"
                        translate="personalInformation.titleSectionCitizenship"></h1>
                    <div role="radiogroup" aria-labelledby="groupCitizen" id='parent-canadianCitizenship-error'>
                        <h2 id="groupCitizen" translate="personalInformation.titleSectionCanadaCitizenship"></h2>
                        <div class="form-wrapper add-margin b8px">
                            <div class="form-row">
                                <app-confirmation-yes-no #canadianCitizenshipCtrl="ngModel" id="canadianCitizenship"
                                    dataAna="mywealth:onboarding:canadiancitizenship" name="canadianCitizenship"
                                    [isFormSubmit]="isFormSubmit" ngDefaultControl [(ngModel)]="_isCanadian"
                                    [accessId]="{ id: 'canadianCitizenship-error', parentId: 'parent-canadianCitizenship-error',  submitted: isFormSubmit }"
                                    errorMessage="personalInformation.errors.canadianCitizenshipError"
                                    (change)="setCanadianCitizenship()">
                                </app-confirmation-yes-no>
                            </div>
                            <div role="alert" id="nocitizenshipError" class="error-message"
                                *ngIf="personalDetailsForm.controls['canadianCitizenship']?.errors?.isCitizenError">
                                <span translate="personalInformation.errors.noCitizenshipError"></span>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- QUESTION: (Canadian Citizen [Y]) -> Are you a U.S. citizen?  YES or NO -->
                <!-- ACCOUNT:   CASH/MARGIN  -->
                <!-- MWB-16259-->
                <div id="groupUSCitizenSection"
                    *ngIf="isCRAtaxresidencyEnabled && isCaaTaxResidencyCertificateEnabled && (isCash || isMargin) ">
                    <div role="radiogroup" aria-labelledby="groupUSResident" id='parent-unitedStatesCitizen-error'>
                        <h2 id="groupUSResident" translate="personalInformation.titleSectionUSACitizenship"></h2>
                        <div class="form-wrapper add-margin b8px">
                            <div class="form-row">
                                <app-confirmation-yes-no id="unitedStatesCitizen" name="unitedStatesCitizen"
                                    [accessId]="{ id: 'unitedStatesCitizen-error', parentId: 'parent-unitedStatesCitizen-error',  submitted: isFormSubmit }"
                                    errorMessage="personalInformation.errors.usaCitizenError"
                                    [isFormSubmit]="isFormSubmit" (change)="setUsCitizenshipField(true)"
                                    [(ngModel)]="_usCitizen">
                                </app-confirmation-yes-no>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- QUESTION: (Canadian Citizen [Y]) -> Other Citizenship  YES or NO -->
                <!-- ACCOUNT:   TFSA & CASH/MARGIN  -->
                <!-- MWB-16259-->
                <div id="goupCashTfsaCitizenSection"
                    *ngIf="isCRAtaxresidencyEnabled && (isCash || isMargin || isTfsa)">
                    <div role="radiogroup" aria-labelledby="goupCashTfsaCitizen" id='parent-citizenshipTax-error'
                        *ngIf=" !(isCaaTaxResidencyCertificateEnabled && isCash || isMargin)?((isCash || isTfsa || isMargin) && isCanadian) : (isCash || isMargin)">
                        <h1 *ngIf="isTfsa && !(isCash || isMargin)" id="goupCashTfsaCitizen"
                            translate="personalInformation.titleSectionTFSAOtherCitizenship"></h1>

                        <h2 *ngIf="(isCash || isMargin) && isCRAtaxresidencyEnabled" id="goupCashTfsaCitizen"
                            translate="personalInformation.titleSectionCashMarginOtherCitizenshipCAA03"></h2>

                        <p *ngIf="!(isCash || isMargin)"
                            translate="personalInformation.descriptionSectionTFSAOtherCitizenship"></p>
                        <div class="form-wrapper add-margin b8px">
                            <div class="form-row" *ngIf="(isCash || isMargin) && isCRAtaxresidencyEnabled">
                                <app-confirmation-yes-no #otherCitizenshipCtrl="ngModel" id="otherCitizenship"
                                    name="otherCitizenship" [isFormSubmit]="isFormSubmit" [(ngModel)]="otherCitizenShip"
                                    [accessId]="{ id: 'otherCitizenshipError-error', parentId: 'parent-citizenshipTax-error',  submitted: isFormSubmit }"
                                    errorMessage="personalInformation.errors.otherCitizenshipError"
                                    (change)="setOtherCitizenship()">
                                </app-confirmation-yes-no>
                            </div>
                            <div class="form-row" *ngIf="!(isCash || isMargin) && isCRAtaxresidencyEnabled">
                                <app-confirmation-yes-no #otherCitizenshipCtrl="ngModel" id="otherCitizenship"
                                    name="otherCitizenship" [isFormSubmit]="isFormSubmit" [(ngModel)]="personalInformation.otherCitizenshipOrTaxResidency"
                                    [accessId]="{ id: 'otherCitizenshipError-error', parentId: 'parent-citizenshipTax-error',  submitted: isFormSubmit }"
                                    errorMessage="personalInformation.errors.otherCitizenshipError"
                                    (change)="setOtherCitizenship()">
                                </app-confirmation-yes-no>
                            </div>

                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isCRAtaxresidencyEnabled && (isCash || isMargin)" [ngTemplateOutlet]="TemplateOtherTaxResidencySection"
                    [ngTemplateOutletContext]="{isCountrySelection:true, uniqueID:'1', isShowshowCountries:otherCitizenShip == 'Yes'}"></ng-container>

            </section>

            <section *ngIf="isCaaTaxResidencyCertificateEnabled && (isCash || isMargin)">
                <!--Tax Residency-->
                <h1 class="add-margin b24px" *ngIf="isCRAtaxresidencyEnabled"
                    translate="personalInformation.titleSectionTaxResidency"></h1>

                <!-- QUESTION (Q1): (Are you tax resident of the United States? ? Yes or No   -->
                <!-- ACCOUNT:   CASH/MARGIN  -->
                <!-- MWB-16260-->
                <div id="CanadaTaxResidentSection">
                    <h2 id="groupTaxResident" *ngIf="isCRAtaxresidencyEnabled"
                        translate="personalInformation.titleSectionCanadaTaxResidency"></h2>
                    <h1 id="groupTaxResident" *ngIf="!isCRAtaxresidencyEnabled"
                        translate="personalInformation.titleSectionCanadaResident"></h1>
                    <div class="form-wrapper">
                        <div class="form-row" role="radiogroup" aria-labelledby="groupTaxResident">
                            <app-confirmation-yes-no #canadianTaxResidentCtrl="ngModel" id="canadianTaxResident"
                                ngDefaultControl name="canadianTaxResident"
                                [(ngModel)]="personalInformation.isCanadaTaxResidency" [isFormSubmit]="isFormSubmit"
                                [accessId]="{ id: 'canadianTaxResident-error', parentId: 'parent-canadianTaxResident-error',  submitted: isFormSubmit }"
                                errorMessage="personalInformation.errors.canadaTaxRequired"
                                (change)="setCanadianTaxResidency()">
                            </app-confirmation-yes-no>

                            <div class="error-message" role="alert" *ngIf="false">
                                <span translate="personalInformation.errors.canadaTaxRequired"></span>
                            </div>
                        </div>
                        <div class="alert alert-yellow" role="alert"
                            *ngIf="personalInformation.isCanadaTaxResidency == 'No'">
                            <p [innerHtml]="'personalInformation.canadaResidencyDescriptionForYourSD' | translate | safeHtml"
                                *ngIf="!isSubProductAD"></p>
                            <p [innerHtml]="'personalInformation.canadaResidencyDescriptionForYourAD' | translate | safeHtml"
                                *ngIf="isSubProductAD"></p>
                        </div>
                    </div>
                </div>
                <!-- QUESTION (Q2): (Are you tax resident of the United States? ? Yes or No   -->
                <!-- ACCOUNT:   CASH/MARGIN  -->
                <!-- MWB-16260-->
                <div id="USATaxResidentSection" *ngIf="isCRAtaxresidencyEnabled && !isHideRemainingQuestions">
                    <h2 id="groupUSATaxResident" translate="personalInformation.titleSectionUSATaxResidency"></h2>
                    <div class="form-wrapper">
                        <div *ngIf="_usCitizen ==='Yes' && isCRAtaxresidencyEnabled" class="header-contextual-help">
                            <p class="display-inline"
                               translate="personalInformation.descriptionSectionUnitedStatesResident"></p>
                            <app-contextual-help id="taxResidentUSAToolTip"
                                                 class="button-wrapper va-middle"
                                                 content="personalInformation.contextualHelp.taxResidentUSA"
                                                 arialabel="personalInformation.ariaLabels.taxResidentUSA"
                                                 inputDataAna="investorline:wealth:onboarding:tool tip: US Resident">
                            </app-contextual-help>
                        </div>

                        <div *ngIf="_usCitizen !=='Yes' && isCRAtaxresidencyEnabled"
                             class="form-row" role="radiogroup" aria-labelledby="groupUSATaxResident">
                            <app-confirmation-yes-no #usTaxResidentCtrl="ngModel"
                                id="unitesStatesResident"
                                ngDefaultControl name="unitesStatesResident"
                                [isFormSubmit]="isFormSubmit"
                                [(ngModel)]="usTaxResidentValue"
                                [isDisabled]="_usCitizen =='Yes'? true: false"
                                [accessId]="{ id: 'unitesStatesResident-error', parentId: 'parent-unitedStatesResident-error',  submitted: isFormSubmit }"
                                errorMessage="personalInformation.errors.USATaxRequired"
                                (change)="setUsTaxResidencyMaxCountry()">

                            </app-confirmation-yes-no>
                        </div>
                        <!-- United States  Tax Residency SIN Card -->
                        <div class="form-row" *ngIf="usTaxResidentValue=='Yes' && isCRAtaxresidencyEnabled">
                            <div class="flex-80 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <input id="appSinNumberUS" name="appSinNumberUS" type="tel" minlength="9"
                                            maxlength="9" required #appSinNumberUSCtrl="ngModel" appMinLengthValidator
                                            minLengthVal='9' maxlength='9' appSSN InputValidator="ct3"
                                            [(ngModel)]="personalInformation.usSINNumber"
                                            [appErrorAccess]="{ id: 'appSinNumberUS-error', submitted: isFormSubmit}" />
                                        <label class="slide-label" for="appSinNumberUS"
                                            [ngClass]="{'error-field': appSinNumberUSCtrl?.invalid && appSinNumberUSCtrl?.touched || (isFormSubmit && appSinNumberUSCtrl?.errors),'active-label': personalInformation.usSINNumber}">
                                            <span class="label-text"
                                                translate="personalInformation.labels.socialSecurityNumber"></span>
                                        </label>
                                    </div>

                                </div>
                                <div role="alert" id="appSinNumberUS-error" class="error-message"
                                    *ngIf="(appSinNumberUSCtrl.invalid && appSinNumberUSCtrl.touched) || (isFormSubmit && appSinNumberUSCtrl.errors)">
                                    <span
                                        [innerHTML]="'personalInformation.errors.ssnNumberError' | translate | safeHtml"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- below are title and section for other tax residency for R10 -->
                <div id="goupCashTfsaTaxCitizenSection"
                    *ngIf="isCRAtaxresidencyEnabled &&!isHideRemainingQuestions && (isCash || isMargin || isTfsa)">
                    <div role="radiogroup" aria-labelledby="goupCashTfsaCitizen" id='parent-citizenshipTax-error'
                        *ngIf=" !(isCaaTaxResidencyCertificateEnabled && isCash || isMargin)?((isCash || isTfsa || isMargin) && isCanadian) : (isCash || isMargin)">
                        <h2 *ngIf="(isCash || isMargin) && isCRAtaxresidencyEnabled" id="goupCashTfsaCitizen"
                            translate="personalInformation.titleSectionOtherTaxResidency"></h2>

                        <p *ngIf="!(isCash || isMargin)"
                            translate="personalInformation.descriptionSectionTFSAOtherCitizenship"></p>
                        <div class="form-wrapper add-margin b8px">
                            <div class="form-row">
                                    <app-confirmation-yes-no #otherCitizenshipOrTaxResidencyCtrl="ngModel"
                                        id="otherCitizenshipOrTaxResidency" name="otherCitizenshipOrTaxResidency"
                                        [isFormSubmit]="isFormSubmit"
                                        [(ngModel)]="personalInformation.otherCitizenshipOrTaxResidency"
                                        [accessId]="{ id: 'otherCitizenshipError-error', parentId: 'parent-citizenshipTax-error',  submitted: isFormSubmit }"
                                        [errorMessage]="(isCRAtaxresidencyEnabled ?'personalInformation.errors.otherTaxResidencyError':'personalInformation.errors.otherCitizenshipError') | translate"
                                        (change)="setOtherCitizenship()">
                                    </app-confirmation-yes-no>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isCRAtaxresidencyEnabled" [ngTemplateOutlet]="TemplateOtherTaxResidencySection"
                    [ngTemplateOutletContext]="{isCountrySelection:false, uniqueID:'2', isShowshowCountries:personalInformation.otherCitizenshipOrTaxResidency =='Yes'}"></ng-container>

            </section>


            <!-- QUESTION: (Canadian Citizen [Y]) -> Are you a tax resident or a citizen of the United States? YES or NO -->
            <!-- ACCOUNT:   TFSA  -->
            <!-- MWB-16260: Only have for TFSA and take out for Cash/Margin on line 401-->
            <!-- --- -->
            <section id="groupUSResidentSection"
                *ngIf="!isCRAtaxresidencyEnabled && isCaaTaxResidencyCertificateEnabled && (isCash || isMargin) && !isHideRemainingQuestions">
                <div role="radiogroup" aria-labelledby="groupUSResident" id='parent-unitedStatesResident-error'>
                    <h1 id="groupUSResident" translate="personalInformation.titleSectionUnitedStatesResident"></h1>
                    <div class="form-wrapper">
                        <div class="form-row">
                            <app-confirmation-yes-no #usTaxResidentCtrl="ngModel" id="unitesStatesResident"
                                ngDefaultControl name="unitesStatesResident" [isFormSubmit]="isFormSubmit"
                                [(ngModel)]="personalInformation.usCitizenship"
                                [accessId]="{ id: 'unitesStatesResident-error', parentId: 'parent-unitedStatesResident-error',  submitted: isFormSubmit }"
                                errorMessage="personalInformation.errors.usaTaxResidencyError"
                                (change)="setUsTaxResidency()">
                            </app-confirmation-yes-no>
                        </div>

                        <!-- United States Citizen & Tax Residency Checkboxes -->
                        <ng-container *ngIf="personalInformation.usCitizenship === 'Yes'">
                            <div class="form-row">
                                <div class="form-col">
                                    <div class="form-input-checks check-default"
                                        [ngClass]="{'error-field': residentOrCitizenUSCitizenCntrl?.invalid && residentOrCitizenUSCitizenCntrl?.touched || ((isFormSubmit && residentOrCitizenUSCitizenCntrl?.errors) || checkCitizenship())}">
                                        <input id="residentOrCitizenUSCitizen" type="checkbox"
                                            name="residentOrCitizenUSCitizen"
                                            [value]="personalInformation.usResidentCheckBox"
                                            #residentOrCitizenUSCitizenCntrl="ngModel" (change)="setUsTaxResidency()"
                                            [(ngModel)]="personalInformation.usResidentCheckBox"
                                            [appErrorAccess]="{ id: 'residentOrCitizenUSCitizen-error', submitted: isFormSubmit}" />
                                        <label for="residentOrCitizenUSCitizen"
                                            translate="personalInformation.labels.citizenOfThisCountry">
                                        </label>

                                    </div>
                                    <div role="alert" id="residentOrCitizenUSCitizen-error" class="error-message"
                                        *ngIf="(residentOrCitizenUSCitizenCntrl.invalid && residentOrCitizenUSCitizenCntrl.touched) || ((isFormSubmit && residentOrCitizenUSCitizenCntrl.errors) || checkCitizenship())">
                                        <span translate="personalInformation.errors.citizenshipCountryError"></span>
                                    </div>

                                    <div class="form-input-checks check-default"
                                        [ngClass]="{'error-field': residentOrCitizenUSResidentCtrl?.invalid && residentOrCitizenUSResidentCtrl?.touched || (isFormSubmit && residentOrCitizenUSResidentCtrl?.errors)}">
                                        <input id="residentOrCitizenUSResident"
                                            #residentOrCitizenUSResidentCtrl="ngModel"
                                            [(ngModel)]="personalInformation.usTaxResidentCheckBox" type="checkbox"
                                            name="residentOrCitizenUSResident" value="true"
                                            [disabled]="personalInformation.usResidentCheckBox" required
                                            [appErrorAccess]="{ id: 'residentOrCitizenUSResident-error', submitted: isFormSubmit}"
                                            (change)="analytics.triggerView('residentOrCitizenUSResident' + personalInformation.usTaxResidentCheckBox)" />
                                        <label for="residentOrCitizenUSResident"
                                            translate="personalInformation.labels.residentForThisCountry">
                                        </label>
                                    </div>
                                    <div role="alert" id="residentOrCitizenUSResident-error" class="error-message"
                                        *ngIf="(residentOrCitizenUSResidentCtrl.invalid && residentOrCitizenUSResidentCtrl.touched) || (isFormSubmit && residentOrCitizenUSResidentCtrl.errors) ">
                                        <span translate="personalInformation.errors.citizenshipTaxError"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- United States Citizen & Tax Residency SIN Card -->
                            <div class="form-row" *ngIf="personalInformation.usTaxResidentCheckBox">
                                <div class="flex-80 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="appSinNumberUS" name="appSinNumberUS" type="tel" minlength="9"
                                                maxlength="9" required #appSinNumberUSCtrl="ngModel"
                                                appMinLengthValidator minLengthVal='9' maxlength='9' appSSN
                                                InputValidator="ct3" [(ngModel)]="personalInformation.usSINNumber"
                                                [appErrorAccess]="{ id: 'appSinNumberUS-error', submitted: isFormSubmit}" />
                                            <label class="slide-label" for="appSinNumberUS"
                                                [ngClass]="{'error-field': appSinNumberUSCtrl?.invalid && appSinNumberUSCtrl?.touched || (isFormSubmit && appSinNumberUSCtrl?.errors),'active-label': personalInformation.usSINNumber}">
                                                <span class="label-text"
                                                    translate="personalInformation.labels.socialSecurityNumber"></span>
                                            </label>
                                        </div>

                                    </div>
                                    <div role="alert" id="appSinNumberUS-error" class="error-message"
                                        *ngIf="(appSinNumberUSCtrl.invalid && appSinNumberUSCtrl.touched) || (isFormSubmit && appSinNumberUSCtrl.errors)">
                                        <span
                                            [innerHTML]="'personalInformation.errors.ssnNumberError' | translate | safeHtml"></span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </section>

            <section *ngIf="!isCRAtaxresidencyEnabled">
                <div id="goupCashTfsaCitizenSection"
                    *ngIf="!isHideRemainingQuestions && (isCash || isMargin || isTfsa)">
                    <div role="radiogroup" aria-labelledby="goupCashTfsaCitizen" id='parent-citizenshipTax-error'
                        *ngIf=" !(isCaaTaxResidencyCertificateEnabled && isCash || isMargin)?((isCash || isTfsa || isMargin) && isCanadian) : (isCash || isMargin)">
                        <h1 *ngIf="isTfsa && !(isCash || isMargin)" id="goupCashTfsaCitizen"
                            translate="personalInformation.titleSectionTFSAOtherCitizenship"></h1>

                        <h1 *ngIf="(isCash || isMargin)" id="goupCashTfsaCitizen"
                            translate="personalInformation.titleSectionTFSAOtherCitizenshipCAA01"></h1>

                        <p *ngIf="!(isCash || isMargin)"
                            translate="personalInformation.descriptionSectionTFSAOtherCitizenship"></p>
                        <div class="form-wrapper add-margin b8px">
                            <div class="form-row">
                                <app-confirmation-yes-no #otherCitizenshipOrTaxResidencyCtrl="ngModel"
                                    id="otherCitizenshipOrTaxResidency" name="otherCitizenshipOrTaxResidency"
                                    [isFormSubmit]="isFormSubmit"
                                    [(ngModel)]="personalInformation.otherCitizenshipOrTaxResidency"
                                    [accessId]="{ id: 'otherCitizenshipError-error', parentId: 'parent-citizenshipTax-error',  submitted: isFormSubmit }"
                                    errorMessage="personalInformation.errors.otherCitizenshipError"
                                    (change)="setOtherCitizenship()">
                                </app-confirmation-yes-no>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Hide/Show this DIV if selection to Canadian Citizenship is YES - RRSP & SRRSP-->
            <!-- --- -->
            <section id="groupRrspSrrspCitizenSection">
                <div role="radiogroup" aria-labelledby="groupRrspSrrspCitizen" id='parent-otherCitizenship-error'
                    *ngIf="(isRRSP || isSRRSP || isRRIF) && isCanadian && !(isCash || isMargin || isTfsa)">
                    <h1 id="groupRrspSrrspCitizen" translate="personalInformation.titleSectionOtherCitizenship"></h1>
                    <p translate="personalInformation.descriptionSectionOtherCitizenship"></p>
                    <div class="form-wrapper">
                        <div class="form-row">
                            <app-confirmation-yes-no #otherCitizenshipOrTaxResidencyCtrl="ngModel"
                                id="otherCitizenshipOrTaxResidency" name="otherCitizenshipOrTaxResidency"
                                [isFormSubmit]="isFormSubmit"
                                [(ngModel)]="personalInformation.otherCitizenshipOrTaxResidency"
                                [accessId]="{ id: 'otherCitizenship-error', parentId: 'parent-otherCitizenship-error',  submitted: isFormSubmit }"
                                errorMessage="personalInformation.errors.otherCitizenshipError"
                                (change)="setOtherCitizenship()">
                            </app-confirmation-yes-no>
                        </div>
                    </div>
                </div>
            </section>



            <!-- Hide/Show this DIV if selection to Canadian Citizenship is NO - RRSP & SRRSP -->
            <!-- --- -->
            <section *ngIf="(isRRSP || isSRRSP || isRRIF) && isNotCanadian && !(isCash || isMargin || isTfsa) "
                id="titleNoCanadaCitizenSection">
                <h1 translate="personalInformation.titleSectionNoCanadaCitizenship"></h1>
                <p translate="personalInformation.descriptionSectionNoCanadaCitizenship"></p>
            </section>

            <!-- Hide/Show this DIV if selection to Canadian Citizenship is NO - TFSA/CASH -->
            <!-- --- -->
            <section
                *ngIf="!(isCaaTaxResidencyCertificateEnabled && isCash || isMargin) ? (isCash || isMargin||isTfsa) && isNotCanadian : false"
                id="titleTfsaNoCanadaCitizenSection">
                <h1 translate="personalInformation.titleSectionTFSANoCanadaCitizenship"></h1>
                <p translate="personalInformation.descriptionTFSASectionNoCanadaCitizenship"></p>
            </section>

            <!-- Logic for country selection and citizenship status -->
            <!-- --- -->

            <ng-container *ngIf="!isCRAtaxresidencyEnabled || (isCRAtaxresidencyEnabled && !(isCash || isMargin))"
                [ngTemplateOutlet]="TemplateOtherTaxResidencySection" [ngTemplateOutletContext]="{isCountrySelection:false, uniqueID:'3', isShowshowCountries:(personalInformation.otherCitizenshipOrTaxResidency =='Yes' || _isCanadian =='No')}"></ng-container>
        </section>

        <section>
            <app-form-buttons [appInvalidForm]="appInvalidForm" [disabled]="disableContinue"
                [showFinishLater]="showFinishLater" formName="personal"
                [backRoute]="APP_ROUTES.select_account"></app-form-buttons>
        </section>

    </div>



    <ng-template #TemplateOtherTaxResidencySection let-isCountrySelection="isCountrySelection" let-uniqueID="uniqueID"
        let-isShowshowCountries="isShowshowCountries">
        <!-- Logic for country selection and citizenship status -->
        <!-- --- -->
        <section *ngIf="!isCRAtaxresidencyEnabled? showCitizenshipCountries(): isShowshowCountries"
            id="otherTaxResidencySection">
            <div class="form-wrapper">
                <ng-container [ngTemplateOutlet]="TemplatecountrySelect" *ngIf="!isCRAtaxresidencyEnabled || (isCRAtaxresidencyEnabled && !isCountrySelection && personalInformation.otherCitizenshipOrTaxResidency == 'Yes' && (isValueNull(personalInformation.isCanadaTaxResidency) || personalInformation.isCanadaTaxResidency =='Yes') || (_isCanadian == 'No' && !(isCash || isMargin)) )" [ngTemplateOutletContext]="{isCountrySelection:isCountrySelection, uniqueID:uniqueID}"></ng-container>

                <ng-container [ngTemplateOutlet]="templateCitizenShipSelect" *ngIf="isCRAtaxresidencyEnabled && isCountrySelection"
                    [ngTemplateOutletContext]="{isCountrySelection:isCountrySelection, uniqueID:uniqueID}">
                </ng-container>

                <div class="form-row button-row"
                    *ngIf="showAddCountryButton(isCountrySelection)">
                    <a role="button" class="btn-icon" href="javascript://" [id]="addAnotherCountry"
                        (click)="addAnotherCountry(isCountrySelection, uniqueID)">
                        <span class="icon icon-large plus-circle-blue"></span>
                        <span class="btn-icon-text" translate="personalInformation.labels.addAnotherCountry"></span>
                    </a>
                </div>

            </div>
        </section>
    </ng-template>

<!-- Below template used to delect the tax resident for new and old UI based on flag and iscountrySelction variable-->
    <ng-template #TemplatecountrySelect let-isCountrySelection="isCountrySelection" let-uniqueID="uniqueID">

        <div *ngFor="let countryOption of personalInformation.selectedCountries; let index = index">
            <hr *ngIf="(index + 1 > 1) && (isTfsa || isCash || isMargin)" />

            <div *ngIf="index < maxTaxResidencyCountries">

                <div class="form-row">
                    <div class="flex-80 form-col">
                        <div class="form-field">
                            <div class="form-label-group">

                                <select [id]="countryOption.id"
                                    [name]="countryOption.id"
                                    [(ngModel)]="countryOption.code" (change)="updateFilteredCountriesList(index, isCountrySelection,countryOption)"
                                    #selectCitizenshipCountryCtrl="ngModel"
                                    [appErrorAccess]="{ id: 'countryOption-error', submitted: isFormSubmit}" required>

                                    <option *ngFor="let country of countryOption.countries" [value]="country.code">
                                        {{ country.label }}
                                    </option>
                                </select>

                                <label class="slide-label"
                                    [for]="countryOption.id"
                                    [ngClass]="{'error-field': selectCitizenshipCountryCtrl?.invalid && selectCitizenshipCountryCtrl?.touched || (isFormSubmit && selectCitizenshipCountryCtrl?.errors), 'active-label': countryOption.code }">
                                    <span class="label-text" translate="personalInformation.labels.countryOfCitizen"></span>
                                </label>
                            </div>
                            <div *ngIf="personalInformation.selectedCountries?.length > 1 && index !== 0"
                                class="form-field-button">
                                <a role="button" attr.aria-label="{{ 'ariaLabels.btnDeleteTrashCountry' | translate }}"
                                    attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}" class="btn-icon tooltip-show"
                                    href="javascript://" (click)="removeSelectedCountry(index, isCountrySelection)"
                                    id="closePersonal{{index+1}}">
                                    <span class="icon icon-large trash-blue"></span>
                                </a>
                            </div>
                        </div>
                        <!-- Errors for selectedCountries-->
                        <div role="alert" id="countryOption-error" class="error-message"
                            *ngIf="selectCitizenshipCountryCtrl.invalid && selectCitizenshipCountryCtrl.touched || (isFormSubmit && selectCitizenshipCountryCtrl.errors)">
                            <span translate="personalInformation.errors.citizenshipCountrySelectError"></span>
                        </div>
                    </div>
                </div>

                <div *ngIf="((isCash || isTfsa || isMargin)&&(countryOption.code!=''))">

                    <div class="form-row" *ngIf="!isCRAtaxresidencyEnabled || (isCRAtaxresidencyEnabled &&  isTfsa && !(isCash || isMargin))">
                        <div class="form-col">
                            <div class="form-input-checks check-default"
                                [ngClass]="{'error-field': citizenOfThisCountryCtrl?.invalid && citizenOfThisCountryCtrl?.touched || (isFormSubmit && citizenOfThisCountryCtrl?.errors)}">
                                <input id="citizenOfThisCountry{{index}}" #citizenOfThisCountryCtrl="ngModel"
                                    [(ngModel)]="countryOption.isCitizen" type="checkbox"
                                    (change)="(isCash || isMargin) && this.isCaaTaxResidencyCertificateEnabled?citizenshipChange(index, countryOption):citizenshipChange(index)"
                                    name="citizenOfThisCountry{{index}}"
                                    [required]="(isCash || isMargin) && this.isCaaTaxResidencyCertificateEnabled ? checkCitizenship() : !isCitizenOfAnyOtherCountry"
                                    [value]="(isCash || isMargin) && this.isCaaTaxResidencyCertificateEnabled ? countryOption.isCitizen:'true'"
                                    [appErrorAccess]="{ id: 'citizenOfThisCountry-error', submitted: isFormSubmit}" />
                                <label for="citizenOfThisCountry{{index}}"
                                    translate="personalInformation.labels.citizenOfThisCountry">
                                </label>
                            </div>
                            <div role="alert" id="citizenOfThisCountry-error" class="error-message"
                                *ngIf="(isCash || isMargin) && this.isCaaTaxResidencyCertificateEnabled ? checkCitizenship() : (citizenOfThisCountryCtrl.invalid && citizenOfThisCountryCtrl.touched) || (isFormSubmit && citizenOfThisCountryCtrl.errors)">
                                <span translate="personalInformation.errors.citizenshipCountryError"></span>
                            </div>
                            <!-- I have a tax residency in this country " -->
                            <div class="form-input-checks check-default"
                                [ngClass]="{'error-field': residentForThisCountryCtrl?.invalid && residentForThisCountryCtrl?.touched || (isFormSubmit && residentForThisCountryCtrl?.errors)}">
                                <input id="residentForThisCountry{{index}}"
                                    [disabled]="countryOption.code === '100001' && countryOption.isCitizen===true"
                                    #residentForThisCountryCtrl="ngModel" [required]="!countryOption.isCitizen"
                                    [(ngModel)]="countryOption.isTaxResident" type="checkbox"
                                    name="residentForThisCountry{{index}}" value="true"
                                    [appErrorAccess]="{ id: 'residentForThisCountry-error', submitted: isFormSubmit}"
                                    (change)="analytics.triggerView('ResidentForThisCountry' + countryOption.isTaxResident)" />
                                <label for="residentForThisCountry{{index}}"
                                    translate="personalInformation.labels.residentForThisCountry">
                                </label>
                            </div>
                            <div role="alert" id="residentForThisCountry-error" class="error-message"
                                *ngIf="(residentForThisCountryCtrl.invalid && residentForThisCountryCtrl.touched) || (isFormSubmit && residentForThisCountryCtrl.errors)">
                                <span translate="personalInformation.errors.citizenshipTaxError"></span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="(countryOption.code === '100001') && (countryOption.isTaxResident)">
                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="socialSecurityNumber{{index}}" name="socialSecurityNumber{{index}}"
                                        #socialSecurityNumberCtrl="ngModel" required appMinLengthValidator minLengthVal='9'
                                        maxlength='9' appSSN InputValidator="ct3"
                                        [appErrorAccess]="{ id: 'socialSecurityNumber-error', submitted: isFormSubmit}"
                                        [(ngModel)]="countryOption.ssn" type="tel" />
                                    <label class="slide-label"
                                        [ngClass]="{'error-field': socialSecurityNumberCtrl?.invalid && socialSecurityNumberCtrl?.touched || (isFormSubmit && socialSecurityNumberCtrl?.errors),'active-label': countryOption.ssn}"
                                        for="socialSecurityNumber{{index}}">
                                        <span class="label-text"
                                            translate="personalInformation.labels.socialSecurityNumber"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="socialSecurityNumber-error" class="error-message"
                                *ngIf="(socialSecurityNumberCtrl.invalid && socialSecurityNumberCtrl.touched) || (isFormSubmit && socialSecurityNumberCtrl.errors)">

                                <span translate="personalInformation.errors.selectionRequired"
                                    *ngIf="!socialSecurityNumberCtrl.value"></span>
                                <span translate="personalInformation.errors.ssnNumberError"
                                    *ngIf="socialSecurityNumberCtrl.touched && socialSecurityNumberCtrl.value &&socialSecurityNumberCtrl?.errors?.minLengthError"></span>

                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="(countryOption.code != '100001') && (countryOption.isTaxResident)">
                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="taxIdentificationNum{{index}}"
                                        [required]="(!countryOption.noTaxIdentificationNum)"
                                        name="taxIdentificationNum{{index}}" #taxIdentificationNumCtrl="ngModel"
                                        [readonly]="(countryOption.noTaxIdentificationNum)" maxlength="30"
                                        InputValidator="ct4 ct2" [(ngModel)]="countryOption.tin" type="text"
                                        [appErrorAccess]="{ id: 'taxIdentificationNum-error', submitted: isFormSubmit}" />
                                    <label class="slide-label"
                                        [ngClass]="{'error-field': taxIdentificationNumCtrl?.invalid && taxIdentificationNumCtrl?.touched || (isFormSubmit && taxIdentificationNumCtrl?.errors), 'active-label': countryOption.tin}"
                                        for="taxIdentificationNum{{index}}">
                                        <span class="label-text"
                                            translate="personalInformation.labels.taxIdentificationNum"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="taxIdentificationNum-error" class="error-message"
                                *ngIf="(taxIdentificationNumCtrl.invalid && taxIdentificationNumCtrl.touched) || (isFormSubmit && taxIdentificationNumCtrl.errors)">
                                <span translate="personalInformation.errors.citizenshipTaxNumberError" *ngIf="!isCRAtaxresidencyEnabled"></span>
                                <span translate="personalInformation.errors.tinNumberError" *ngIf="isCRAtaxresidencyEnabled"></span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="(countryOption.code != '100001') && (countryOption.isTaxResident)">
                        <div class="form-col">
                            <div class="form-input-checks check-default">
                                <input id="noTaxIdentificationNum{{index}}" #noTaxIdentificationNumCtrl="ngModel"
                                    type="checkbox" [(ngModel)]="countryOption.noTaxIdentificationNum"
                                    InputValidator="ct4 ct2" (change)="noTaxIdentificationChange(index)"
                                    name="noTaxIdentificationNum{{index}}" value="No tax identification number" />
                                <label class="prefix" for="noTaxIdentificationNum{{index}}"
                                    translate="personalInformation.labels.noTaxIdentificationNum"></label>
                            </div>
                        </div>
                    </div>

                    <div class="form-row"
                        *ngIf="(countryOption.code != '100001') && (countryOption.noTaxIdentificationNum) && (countryOption.isTaxResident)">
                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <select id="selectNoTaxReason{{index}}" #selectNoTaxReasonCtrl="ngModel"
                                        [(ngModel)]="countryOption.reasonSelected"
                                        [required]="countryOption.noTaxIdentificationNum" name="selectNoTaxReason{{index}}"
                                        otherNoTaxReason-error
                                        [appErrorAccess]="{ id: 'selectNoTaxReason-error', submitted: isFormSubmit}"
                                        (change)="updAnalytics(countryOption.reasonSelected)">

                                        <option *ngFor="let reason of taxIdentificationNumberMissingReason"
                                            [value]="reason.code">
                                            {{ reason.label }}
                                        </option>
                                    </select>
                                    <label class="slide-label" for="selectNoTaxReason{{index}}"
                                        [ngClass]="{'error-field': selectNoTaxReasonCtrl?.invalid && selectNoTaxReasonCtrl?.touched || (isFormSubmit && selectNoTaxReasonCtrl?.errors), 'active-label': countryOption.reasonSelected }">
                                        <span class="label-text"
                                            translate="personalInformation.labels.selectNoTaxReason"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="selectNoTaxReason-error" class="error-message"
                                *ngIf="(selectNoTaxReasonCtrl.invalid && selectNoTaxReasonCtrl.touched) || (isFormSubmit && selectNoTaxReasonCtrl.errors)">
                                <span translate="personalInformation.errors.citizenshipOtherReasonError"></span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row"
                        *ngIf="(countryOption.code != '100001') && (countryOption.reasonSelected==3) && (countryOption.noTaxIdentificationNum) && (countryOption.isTaxResident)">
                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="otherNoTaxReason{{index}}" name="otherNoTaxReason{{index}}" required
                                        maxlength="120" InputValidator="frenchAlphaNumneric" #otherNoTaxReasonCtrl="ngModel"
                                        [(ngModel)]="countryOption.otherReason" type="text"
                                        [appErrorAccess]="{ id: 'otherNoTaxReason-error', submitted: isFormSubmit}" />
                                    <label class="slide-label"
                                        [ngClass]="{'error-field': otherNoTaxReasonCtrl?.invalid && otherNoTaxReasonCtrl?.touched || (isFormSubmit && otherNoTaxReasonCtrl?.errors),'active-label': countryOption.otherReason}"
                                        for="otherNoTaxReason{{index}}">
                                        <span class="label-text" *ngIf="!isCRAtaxresidencyEnabled"
                                            translate="personalInformation.labels.otherNoTaxReason"></span>
                                        <span class="label-text" *ngIf="isCRAtaxresidencyEnabled"
                                            translate="personalInformation.labels.otherNoTaxReasonNew"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="otherNoTaxReason-error" class="error-message"
                                *ngIf="(otherNoTaxReasonCtrl.invalid && otherNoTaxReasonCtrl.touched) || (isFormSubmit && otherNoTaxReasonCtrl.errors)">
                                <span translate="personalInformation.errors.citizenshipOtherDescriptionError"></span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </ng-template>

<!-- Below teplate us sed to select the new ui of only Citizen select -->
    <ng-template #templateCitizenShipSelect let-isCountrySelection="isCountrySelection" let-uniqueID="uniqueID">

        <ng-container *ngIf="isCountrySelection">

            <div *ngFor="let countryOption of personalInformation.selectedCitizenCountries; let index = index">
                <hr *ngIf="(index + 1 > 1) && (isTfsa || isCash || isMargin)" />
                <div *ngIf="index < maxCountries">

                    <div class="form-row">
                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">

                                    <select [id]="countryOption.id+'_'+uniqueID"
                                        [name]="countryOption.id+'_'+uniqueID"
                                        [(ngModel)]="countryOption.code" (change)="updateFilteredCountriesList(index,isCountrySelection, countryOption)"
                                        #selectCitizenshipCountryCtrl="ngModel"
                                        [appErrorAccess]="{ id: 'countryOption-error', submitted: isFormSubmit}" required>

                                        <option *ngFor="let country of countryOption.countries" [value]="country.code">
                                            {{ country.label }}
                                        </option>
                                    </select>

                                    <label class="slide-label"
                                        [for]="countryOption.id+'_'+uniqueID"
                                        [ngClass]="{'error-field': selectCitizenshipCountryCtrl?.invalid && selectCitizenshipCountryCtrl?.touched || (isFormSubmit && selectCitizenshipCountryCtrl?.errors), 'active-label': countryOption.code }">
                                        <span class="label-text" translate="personalInformation.labels.countryOfCitizen"></span>
                                    </label>
                                </div>
                                <div *ngIf="personalInformation.selectedCitizenCountries?.length > 1 && index !== 0"
                                    class="form-field-button">
                                    <a role="button" attr.aria-label="{{ 'ariaLabels.btnDeleteTrashCountry' | translate }}"
                                        attr.tooltip="{{ 'ariaLabels.btnDelete' | translate }}" class="btn-icon tooltip-show"
                                        href="javascript://" (click)="removeSelectedCountry(index, isCountrySelection)"
                                        id="closePersonal{{index+1}}">
                                        <span class="icon icon-large trash-blue"></span>
                                    </a>
                                </div>
                            </div>
                            <!-- Errors for selectedCountries-->
                            <div role="alert" id="countryOption-error" class="error-message"
                                *ngIf="selectCitizenshipCountryCtrl.invalid && selectCitizenshipCountryCtrl.touched || (isFormSubmit && selectCitizenshipCountryCtrl.errors)">
                                <span translate="personalInformation.errors.citizenshipCountrySelectError" *ngIf="!isCRAtaxresidencyEnabled"></span>
                                <span translate="personalInformation.errors.TaxResidencySelectError" *ngIf="isCRAtaxresidencyEnabled"></span>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </ng-container >

    </ng-template>

</form>
