import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

    public overlay: boolean;
    private disableHideAction: boolean;

    constructor() {
        this.overlay = true;
    }

    setOverlay(overlay) {
        this.overlay = overlay;
    }

    setDisableHideAction(disableHideAction) {
        this.disableHideAction = disableHideAction;
    }


    show() {
        if (this.overlay === true) {
            $('.modal-overlay').show();
            $('.bmo-loading').show();
            this.overlay = true;
        }
    }

    hide() {
        if (this.disableHideAction) {
            return;
        }
        if (this.overlay === true) {
            $('.modal-overlay').hide();
        }
        $('.bmo-loading').hide();
    }
}
