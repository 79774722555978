import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormService } from '../../shared/service/form.service';
import { NgForm } from '@angular/forms';
import { ApplicationService } from '../../shared/service/application.service';
import { PersonalInformation } from '../personal-information/personal-information.component';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX, DOCUMENT_TYPE, APP_CONFIG, applicationDowngrage, CreditDecisionType, decisionType, DOCUMENT_STATUS, ACCOUNT_TYPE } from '../../config/app-config.constants';
import { DocumentTrackerService } from '../../shared/service/document-tracker.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { UserIdleService } from '../../../../node_modules/angular-user-idle';
import { Utils } from '../../shared/utils/utils.class';
import { LoaderService } from '../../shared/service/loader.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { Jquery } from '../../shared/utils/jquery.class';
import { COUNTRY_CODE, LANGUAGES, State } from "../../../app/config/app-config.constants";

class TermsConditions {
    acceptStep1: boolean;
    acceptStep2: boolean;
}


@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html'
})
export class TermsConditionsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    CreditDecisionType = CreditDecisionType;
    decisionType: any;
    public APP_ROUTES = APP_ROUTES;
    @ViewChild('termsConditionsForm', { static: true }) termsConditionsForm: NgForm;
    public isFormSubmit = false;
    public termsConditions: TermsConditions = new TermsConditions();
    @Output() leftNavigation: EventEmitter<any> = new EventEmitter();
    public criticalError = false; // hide and show errors on page
    public tryAgainError = false;
    public tnCSelected: Boolean = false;
    public isclicked = false;
    public response;
    public isRetry = false;
    public isSubProductAD: Boolean = false;
    public showFinishLater;
    public isDataRationalization4Enabled = false;
    public isMargin: Boolean = false;
    public isCashAccount: Boolean = false;
    public isTFSAAccount: Boolean = false;
    public isBranchAssisted: Boolean = false;
    public isMaoApp: Boolean = false;
    public isMultiAccountOpen: boolean;
    public isEnvLocalOrDit: boolean;
    amlFlagSelected: any;

    appNumber;
    public accountSetup;

    public url: any;
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    private retryCount: number = 0;
    public retryMax: number = 3;
    private retryAfter: number = 5000;
    private onExitApplicationPerformSaveSubscriber;
    public enableCommissionAndFees: boolean;
    public showOldContent: boolean = false;
    constructor(private formService: FormService,
        public titleService: Title,
        private winRef: WindowRefService,
        public applicationService: ApplicationService,
        public documentTrackerService: DocumentTrackerService,
        public analytics: AnalyticsService,
        private loaderService: LoaderService,
        private userIdle: UserIdleService) {

        this.formService.forms.termsConditions.isVisited = true;

        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {

                // console.log('getPartialSave: ', item);

                if (item === 'tncFrm') {
                    this.onFinishLater();
                }
            });
        this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
            if (value) {
                this.onFinishLater(true);
            }
        });

        this.formService.setActiveComponent('termsConditions', this);



        this.url = '/' + APP_ROUTES.terms_conditions;
    }

    ngAfterViewInit() {

        if (Utils.getNavigationDissabled()) {
            this.applicationService.setNavigationDisabled(true); // Disable Navigation -> Event Emitted
        }
        this.checkFormValidityOnSignOut();
        // this.analytics.setState('step 16');
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('TermsConditions');
    }

    ngOnInit() {

        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        this.isEnvLocalOrDit = window.location.href.includes('.dit') || window.location.href.includes('localhost');
        this.enableCommissionAndFees = this.applicationService.getCommissionAndFeesFeatureFlag();
        this.isDataRationalization4Enabled = this.applicationService.getDataRationalization4FeatureFlag();
        this.isMultiAccountOpen = this.applicationService.getMultiAccountOpenFeatureFlag();
        if (this.enableCommissionAndFees) {
            if (Utils.getApplicationInflightStatus() == true && Utils.getdocumentPackageStatus() === DOCUMENT_STATUS.ESignPending) {
                this.showOldContent = true;
            }
        }
        this.isSubProductAD = Utils.isSubProductAD();
        this.isBranchAssisted = Utils.getIsBranchAssisted();
        this.isMaoApp = (Utils.selectedMultiAccounts || []).length > 1 ? true : false;

        this.isMargin = Utils.isMargin();
        this.isCashAccount = Utils.isCash() ? true : false;
        this.isTFSAAccount = Utils.isTFSA() ? true : false;
        this.titleService.setTitle(Utils.getPageTitleESignature());

        this.termsConditions = new TermsConditions();  // coming to form for the first time
        this.formService.setActiveForm(this.termsConditionsForm);
        // setting docupload visited to false to track on returning to
        // next steps page to hide doc upload option, is set to true on docupload page
        Utils.setIsDocUploadSuccess(false);

        /** To perform save operation on signout*/
        /*this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });*/

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        setTimeout(() => {
            this.setGTM();
        }, 200);
    }

    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {
                if (this.termsConditionsForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }

    amlFlagSelect() {
        console.log(this.amlFlagSelected);
    }

    isTnCSelected() {
        if (this.termsConditions.acceptStep1 && this.termsConditions.acceptStep2) {
            // enables ESign button
            this.tnCSelected = true;
            this.criticalError = false;
        } else {
            // throws error
            this.tnCSelected = false;
            this.criticalError = true;
        }
    }
    esignCall(response) {
        this.loaderService.hide();
        this.showOrHideTCModal(false);
        this.documentTrackerService.getSigingUrl(response.packageId, response.signerId).subscribe(
            (res: any) => {
                if (res.url === null && !this.isRetry) {
                    this.isRetry = true;
                    this.esignCall(response);
                } else {
                    this.formService.setFormData('termsConditions', this.termsConditions);
                    this.formService.forms.termsConditions.isCompleted = true;
                    this.analytics.setEsign();
                    this.analytics.setapiCallDetails({ apiCallMade: 'false', apiCallType: '', apiCallResponseCode: '' });
                    window.location.href = res.url;
                }
            },
            err => {
                this.criticalError = false;
                this.tryAgainError = true;
                this.isclicked = false;
                this.loaderService.setDisableHideAction(false);
                this.showOrHideTCModal(false);
                this.loaderService.hide();
            });
    }

    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {
        this.preparePayload();
        // this should be as same as review pge method
        // console.log('onFinishLater: ');
        if (!this.termsConditionsForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }
        // console.log('payload all data ', this.formService.getPayload());
        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {

                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })

                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }


    saveOnSignOut() {
        this.preparePayload();

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {

                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);

                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }

    onSubmit() {
        // generate document

        let applicationID = '';

        if (this.termsConditions.acceptStep1 && this.termsConditions.acceptStep2) {
            if (this.amlFlagSelected !== undefined && this.amlFlagSelected !== null) {
                sessionStorage.setItem('amlFlag', this.amlFlagSelected);
            }
            this.formService.getActiveForm().isCompleted = true;
            this.isclicked = true;
            this.applicationService.setNavigationDisabled(true); // Disable Navigation -> Event Emitted
            this.applicationService.hidePageUndloadPopUp$.next(false);


            // this.loaderService.setDisableHideAction(true);
            // this.loaderService.show();

            /*  console.log('Utils.getIsInSession()= ', Utils.getIsInSession());
              let applicationID = (Utils.getIsInSession()===true)?
              Utils.getApplicationObject().applicationId:this.applicationService.appResponse.applicationId; */

            if (Utils.getIsInSession() === true && (this.applicationService.appResponse === undefined)) {
                applicationID = Utils.getApplicationObject().applicationId;
            } else {
                applicationID = this.applicationService.appResponse.applicationId;
            }

            this.analytics.setApplicationID(applicationID);
            if (Utils.isMargin() && Utils.getdocumentPackageStatus() != DOCUMENT_STATUS.ESignPending) {
               // this.documentTrackerService.creditCheckCall(applicationID).subscribe((res: any) => {
                     this.documentTrackerService.creditCheckCall(applicationID , this.decisionType).subscribe((res: any) => { //revert this code after dit testing and uncomment above commented code
                    const appObj = res.body;
                    if (!this.isMultiAccountOpen || this.isSubProductAD) {
                        if (appObj.accounts[0].accountFeatures.creditCheck &&
                            appObj.accounts[0].accountFeatures.creditCheck.hasOwnProperty('decisionType')
                            && (appObj.accounts[0].accountFeatures.creditCheck.decisionType == decisionType.ILDECLNE ||
                                appObj.accounts[0].accountFeatures.creditCheck.decisionType == decisionType.ILDWNGRD)) {
                            Utils.setDocumentsPackage(res.body);
                            this.downGrade();
                            return;
                        } else {
                            this.showOrHideTCModal(true);
                            this.docsPackage(applicationID);
                        }
                    }
                    else if ((this.isMultiAccountOpen && !this.isSubProductAD) || Utils.isBranchSDMultiAccountFlow()) {
                        for (let i in appObj.accounts) {
                            if (appObj.accounts[i].type == ACCOUNT_TYPE.MARGINACCOUNT && appObj.accounts[i].subTypes[0] == "2") {
                                if (appObj.accounts[i].accountFeatures.creditCheck &&
                                    appObj.accounts[i].accountFeatures.creditCheck.hasOwnProperty('decisionType')
                                    && (appObj.accounts[i].accountFeatures.creditCheck.decisionType == decisionType.ILDECLNE ||
                                        appObj.accounts[i].accountFeatures.creditCheck.decisionType == decisionType.ILDWNGRD)) {
                                    Utils.setDocumentsPackage(res.body);
                                    this.downGrade();
                                    return;
                                } else if (appObj.accounts[i].accountFeatures.creditCheck &&
                                    appObj.accounts[i].accountFeatures.creditCheck.hasOwnProperty('decisionType')
                                    && (appObj.accounts[i].accountFeatures.creditCheck.decisionType == decisionType.ILACCEPT ||
                                        appObj.accounts[i].accountFeatures.creditCheck.decisionType == decisionType.ILREVIEW)) {
                                    this.showOrHideTCModal(true);
                                    this.docsPackage(applicationID);
                                }
                            }
                        }
                    }
                    else {
                        this.showOrHideTCModal(true);
                        this.docsPackage(applicationID);
                    }
                }, error => {
                    this.setErrors();
                });

            }
            else {
                this.showOrHideTCModal(true);
                this.docsPackage(applicationID);
            }
        }

        setTimeout(() => {
            this.setGTMApplicationID(applicationID);
        }, 200);

    }

    showOrHideTCModal(flag: boolean) {
        if (flag) {
            this.loaderService.setOverlay(false);
            $('.bmo-terms-conditions, .modal-overlay').show();
            Jquery.modalFocusTrap('bmo-terms-conditions');
        } else {
            this.loaderService.setOverlay(true);
            $('.bmo-terms-conditions, .modal-overlay').hide();
            Jquery.modalDissableFocusTrap();
        }
    }

    docsPackage(applicationID) {
        this.loaderService.setDisableHideAction(true);
        this.loaderService.hide();
        if (Utils.getIsBranchReferral() === false) { // THIS is not a Branch Assisted Flow

            this.documentTrackerService.generateDocuments(applicationID).subscribe(
                (res: any) => {
                    const responseData = JSON.parse(res.body)[0];
                    if (responseData.packageId && responseData.signerId) {
                        this.loaderService.setDisableHideAction(false);
                        this.loaderService.hide();
                        this.showOrHideTCModal(false);
                        this.response = JSON.parse(res.body)[0];
                        Utils.packageID = this.response.packageId;
                        Utils.signerID = this.response.signerId;
                        this.esignCall(this.response);
                    } else {
                        this.retryCountCheck(applicationID);
                    }
                },
                error => {
                    if (error.status == 504 || error.status == 409) {
                        setTimeout(() => {
                            this.retryCountCheck(applicationID)
                        }, this.retryAfter)
                    }
                    else {
                        this.retryCountCheck(applicationID);
                    }

                });
        } else { // THIS is a Branch Assisted Flow
            this.documentTrackerService.generateSpecificDocuments(applicationID, DOCUMENT_TYPE.CUSTOMER_PACKAGE).subscribe(
                (res: any) => {
                    const responseData = JSON.parse(res.body)[0];
                    if (responseData.id && responseData.signers && responseData.signers[0].id) {
                        this.loaderService.setDisableHideAction(false);
                        this.loaderService.hide();
                        this.showOrHideTCModal(false);
                        this.response = JSON.parse(res.body)[0];
                        this.documentTrackerService.documentPackage = this.response;
                        this.applicationService.saveAndcontinue(this.termsConditionsForm, APP_ROUTES.branch_esignature);
                    } else {
                        this.retryCountCheck(applicationID);
                    }
                },
                error => {
                    if (error.status == 504 || error.status == 409) {
                        setTimeout(() => {
                            this.retryCountCheck(applicationID)
                        }, this.retryAfter)
                    }
                    else {
                        this.retryCountCheck(applicationID);
                    }
                });
        }
    }

    retryCountCheck(applicationID) {
        this.retryCount++;
        if (this.retryCount > this.retryMax) {
            this.setErrors();
            this.retryCount = 0;
        } else {
            this.docsPackage(applicationID);
        }
    }

    downGrade() {
        this.formService.setFormData('termsConditions', this.termsConditions);
        this.formService.forms.termsConditions.isCompleted = true;
        this.applicationService.saveAndcontinue(this.termsConditionsForm, APP_ROUTES.application_approval_downgrade);
    }

    ngOnDestroy() {
        this.formService.forms.termsConditions.isVisited = false;
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    setErrors() {
        this.criticalError = true;
        this.tryAgainError = false;
        this.isclicked = false;
        this.loaderService.setDisableHideAction(false);
        this.showOrHideTCModal(false);
        this.loaderService.hide();
    }

    preparePayload() {
        this.formService.getActiveForm().isCompleted = true;
        // getting all screen data from form service
        const accountDetail = this.formService.getFormData('accountDetail');
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
        const withdrawal = this.formService.getFormData('withdrawal');
        const consents = this.formService.getFormData('consents');
        const accountPassword = this.formService.getFormData('accountPassword');

        if ((Utils.getIsBranchReferral() === true && Utils.isCash() === true) || (Utils.getIsBranchReferral() === false)) {
            const bmoRelationship = this.formService.getFormData('bmoRelationship');
            this.formService.getPayload().setBMORelationship(bmoRelationship);
        }

        if (Utils.isSubProductAD()) {
            const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
            const investorProfile = this.formService.getFormData('investorProfile');
            const riskAndReturn = this.formService.getFormData('riskAndReturn');

            this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);
            this.formService.getPayload().setInvestorProfile(investorProfile);
            this.formService.getPayload().setRiskAndReturn(riskAndReturn);
        }
        /**
         * Updating the preferred language in the save call on submit of the application
         * primary applicant will be always the first entry in payload of parties
         * So updating the current language here updates primary applicant in fn setPersonalInformation
         */
        if (Utils.getLanguage() === "en") {
            personalInformation.currentLanguage = LANGUAGES.ENGLISH;
        } else {
            personalInformation.currentLanguage = LANGUAGES.FRENCH;
        }
        // putting all the data in payload before sending it to backend
        //this.formService.getPayload().setAccountDetails(accountDetail);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
        this.formService.getPayload().setConsents(consents);

        if (!Utils.getIsJourneyUser || (Utils.getIsJourneyUser && (this.isCashAccount || this.isMargin))) {
            this.formService.getPayload().setAccountPassword(accountPassword);
        }

        if (Utils.isSpousalRRSP()) {
            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

    }

    setGTM() {

        const gtmDataLayerForTnC: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleESignature(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.TERMS_CONDITIONS,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.terms_conditions,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForTnC);
        // console.log('tnc', this.winRef.nativeWindow.dataLayer);

    }

    setGTMApplicationID(applicationID) {

        const gtmDataLayerForTnC: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleESignature(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.actionComplete,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.terms_conditions,
            'applicationId': applicationID,
            'signerId': Utils.signerID,
            'packageId': Utils.packageID,
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForTnC);
        // console.log('tnc', this.winRef.nativeWindow.dataLayer);

    }

}
