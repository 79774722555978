import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    UntypedFormControl,
    ValidatorFn,
    Validator, AbstractControl
} from '@angular/forms';

import { Directive } from '@angular/core';

@Directive({
    selector: '[app-accountSetupPasswordValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: AccountSetupPasswordValidator,
            multi: true
        }
    ]
})
export class AccountSetupPasswordValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = this.AccountSetupPasswordValidator();
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    AccountSetupPasswordValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return {
                    lengthMustBeGreaterThanSeven: true,
                    noDigitsPresent: true,
                    noLowerCasePresent: true,
                    noUpperCasePresent: true,
                    noSpecialCharactersAllowed: true,
                };
            }
            const errors = {};
            const _password = control.value; // don't care about

            // length must be greater than 7
            if (_password.length < 8) {
                errors['lengthMustBeGreaterThanSeven'] = true;
            }

            // at least 1 number is required
            if (_password.replace(/\D+/g, '').length < 1) {
                errors['noDigitsPresent'] = true;
            }

            // at least 1 lowercase is required
            if (_password.replace(/[a-z]+/g).length < 1) {
                errors['noLowerCasePresent'] = true;
            }

            // console.log('_password.replace(/[A-Z]+/g).length: ', _password.replace(/[A-Z]+/g).length);

            // at least 1 uppercase is required
            if (_password.replace(/[A-Z]+/g).length < 1) {
                
                errors['noUpperCasePresent'] = true;
            }

            // // at least 1 letter is required
            // if (!_password.match(/[a-zA-Z]+/g)) {
            //     errors['noLettersPresent'] = true;
            // }

            // no special characters or spaces are allowed
            if (_password.replace(/[a-zA-Z0-9]/g, '').length > 0) {
                errors['noSpecialCharactersAllowed'] = true;
            }
            return errors;
        };
    }
}
