<div class="container-smallNoMenu margin-bottom" #pdfContent id="pdfContent">

    <p>
        <!-- <span *ngIf="!isOptions && !isProAccount && amlFlag">
            <span translate="nextByBranchPDF.accountNum" *ngIf="!isOptions && !isProAccount && amlFlag" ></span>&nbsp;
            <strong *ngIf="!isOptions && !isProAccount && amlFlag">{{ accountNo }}</strong><br />
        </span> -->
        <span [innerHTML]="'nextByMailPDF.applicationNum' | translate | safeHtml"></span> <strong>{{applicationNumber}}</strong>
    </p>
    <p>
        <span [innerHTML]="'nextByMailPDF.applicantName' | translate | safeHtml"></span> <strong>{{applicantName}}</strong>
    </p>

    <hr />
    <h1 translate="documentUpload.titleSection" class="add-margin t24px b24px"></h1>

        <h2 translate="nextByMailPDF.descriptionStep1"></h2>
        <p class="add-margin b24px" *ngIf="!amlEnabled" [innerHTML]="'nextByMailPDF.description01' | translate | safeHtml"></p>
        <p class="add-margin b24px" *ngIf="amlEnabled" [innerHTML]="'nextByMailPDF.description01_aml' | translate | safeHtml"></p>


    <div class="form-wrapper no-margin">
        <div class="form-row">
            <div class="flex-50">

                <h2 translate="nextByMailPDF.description02"></h2>

                <ul class="disc" *ngIf="!amlEnabled" >
                    <li [innerHTML]="'nextByMailPDF.ul01Item01' | translate | safeHtml"></li>
                    <li [innerHTML]="'nextByMailPDF.ul01Item02' | translate | safeHtml"></li>
                    <li [innerHTML]="'nextByMailPDF.ul01Item03' | translate | safeHtml"></li>
                    <li [innerHTML]="'nextByMailPDF.ul01Item04' | translate | safeHtml"></li>
                </ul>
                <ul class="disc" *ngIf="amlEnabled" >
                    <li [innerHTML]="'nextByMailPDF.ul01Item01_aml' | translate | safeHtml"></li>
                    <li [innerHTML]="'nextByMailPDF.ul01Item02_aml' | translate | safeHtml"></li>
                    <li [innerHTML]="'nextByMailPDF.ul01Item03_aml' | translate | safeHtml"></li>
                </ul>

            </div>
            <div class="flex-50">

                <h2 translate="nextByMailPDF.description03"></h2>

                <ul class="disc">
                    <li [innerHTML]="'nextByMailPDF.ul02Item01' | translate | safeHtml"></li>
                    <li [innerHTML]="'nextByMailPDF.ul02Item02' | translate | safeHtml"></li>
                    <li *ngIf="!amlEnabled"  [innerHTML]="'nextByMailPDF.ul02Item03' | translate | safeHtml"></li>
                    <li *ngIf="amlEnabled"  [innerHTML]="'nextByMailPDF.ul02Item03_aml' | translate | safeHtml"></li>
                </ul>

            </div>
        </div>
    </div>

    <h2 *ngIf="!amlEnabled" translate="nextByMailPDF.description04"></h2>
    <h3 *ngIf="amlEnabled" translate="nextByMailPDF.description04_aml"></h3>

    <ul class="disc" *ngIf="!amlEnabled">
        <li [innerHTML]="'nextByMailPDF.ul03Item01' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul03Item02' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul03Item03' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul03Item04' | translate | safeHtml"></li>
    </ul>

    <ul class="disc" *ngIf="amlEnabled">
        <li [innerHTML]="'nextByMailPDF.ul03Item01' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul03Item02_aml' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul03Item03_aml' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul03Item04_aml' | translate | safeHtml"></li>
    </ul>

    <h2 translate="nextByMailPDF.titleSection_RRIF"  *ngIf="isRIF"></h2>
    <p [innerHTML]="'nextByMailPDF.description01_RRIF' | translate | safeHtml"  *ngIf="isRIF"></p>
    <h3 translate="nextByMailPDF.description02_RRIF"  *ngIf="isRIF"></h3>

    <ul class="disc"  *ngIf="isRIF">
        <li [innerHTML]="'nextByMailPDF.ul01Item01_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item02_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item03_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item04_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul01Item05_RRIF' | translate | safeHtml"></li>
    </ul>

    <h3 translate="nextByMailPDF.description03_RRIF" *ngIf="isRIF" ></h3>
    <ul class="disc" *ngIf="isRIF">
        <li [innerHTML]="'nextByMailPDF.ul02Item01_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul02Item02_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul02Item03_RRIF' | translate | safeHtml"></li>
        <li [innerHTML]="'nextByMailPDF.ul02Item04_RRIF' | translate | safeHtml"></li>
    </ul>

    <span *ngIf="isProAccount">
        <h2 translate="nextByMailPDF.descriptionStep2Pro"></h2>
        <p *ngIf="!isCIROEnabled" [innerHTML]="'nextByMailPDF.description05' | translate | safeHtml"></p>
        <p *ngIf="isCIROEnabled" [innerHTML]="'nextByMailPDF.description05_ciro' | translate | safeHtml"></p>
    </span>

    <h2 *ngIf="!isProAccount" translate="nextByMailPDF.descriptionStep2"></h2>
    <p *ngIf="!isProAccount" translate="nextByMailPDF.description06"></p>
     <p *ngIf="!isProAccount" [innerHTML]="'nextByMailPDF.description07' | translate | safeHtml"></p>

    <h2 *ngIf="isProAccount" translate="nextByMailPDF.descriptionStep3"></h2>
     <p *ngIf="isProAccount" translate="nextByMailPDF.description06"></p>
     <p *ngIf="isProAccount" [innerHTML]="'nextByMailPDF.description07Pro' | translate | safeHtml"></p>

    <div class="form-row form-page-buttons hide-print">
        <div class="form-col left-side">
            <button class="primary" type="submit" onclick="window.print()" translate="button.print"></button>
        </div>
    </div>

</div>
