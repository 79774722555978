<nav id="main-nav">

    <ul class="main-navigation">

        <li *ngIf="!BILAssisted">
            <a *ngIf="!formService.forms.setExpectation.isVisited"
               data-ana="mywealth:onboarding:navigation:setExpectations" class="disabled" data-region="left navigation"
               href="javascript://" translate="mainNav.setExpectations" attr.aria-label="{{ 'mainNav.setExpectations' + 'mainNav.disabled' | translate }}" >
            </a>
            <a *ngIf="formService.forms.setExpectation.isVisited" [ngClass]="{completed: formService.forms.setExpectation.isCompleted}"
               [routerLink]="['/set-expectation']" [class.disabled]="isDisabled" [attr.tabindex]="tabIndexValue" id="set_expectation"
               data-ana="mywealth:onboarding:navigation:set-expectation" [queryParams]="queryParams" href="javascript://"
               data-region="left navigation" translate="mainNav.setExpectations" routerLinkActive="active" aria-current="page"></a>
        </li>
        <li>
            <a *ngIf="!formService.forms.selectAccount.isVisited"
               data-ana="mywealth:onboarding:navigation:selectAccount" class="disabled" data-region="left navigation"
               href="javascript://" translate="mainNav.selectAccount" attr.aria-label="{{ 'mainNav.ariaLabels.selectAccount' | translate }}">
            </a>
            <a *ngIf="formService.forms.selectAccount.isVisited" [ngClass]="{completed: formService.forms.selectAccount.isCompleted}"
               [routerLink]="['/select-account']" [class.disabled]="isDisabled || disableSelectAccount" [attr.tabindex]="tabIndexValue" id="select_account" (click)="setNavigationSA()"
               data-ana="mywealth:onboarding:navigation:selectAccount" [queryParams]="queryParams" href="javascript://"
               data-region="left navigation" translate="mainNav.selectAccount" routerLinkActive="active"></a>
        </li>

        <li>
            <a *ngIf="!formService.forms.personalInformation.isVisited"
               data-ana="mywealth:onboarding:navigation:personal" class="disabled" data-region="left navigation"
               href="javascript://" translate="mainNav.personalInformation" attr.aria-label="{{ 'mainNav.ariaLabels.personalInformation' | translate }}">
            </a>
            <a *ngIf="formService.forms.personalInformation.isVisited" [ngClass]="{completed: formService.forms.personalInformation.isCompleted}"
               [routerLink]="['/personal-information']" [class.disabled]="isDisabled || disablePersonalInformation" [attr.tabindex]="tabIndexValue" id="personal_information"
               data-ana="mywealth:onboarding:navigation:personal" [queryParams]="queryParams" href="javascript://"
               data-region="left navigation" translate="mainNav.personalInformation" routerLinkActive="active"></a>
        </li>
        <li>
            <a *ngIf="!formService.forms.contactInformation.isVisited"
               data-ana="mywealth:onboarding:navigation:contact" class="disabled" data-region="left navigation"
               href="javascript://" translate="mainNav.contactInformation" attr.aria-label="{{ 'mainNav.ariaLabels.contactInformation' | translate }}">
            </a>
            <a *ngIf="formService.forms.contactInformation.isVisited" data-region="left navigation"
               [class.disabled]="isDisabled || disableContactInformation" [attr.tabindex]="tabIndexValue" [ngClass]="{completed: formService.forms.contactInformation.isCompleted}"
               data-ana="mywealth:onboarding:navigation:contact" [routerLink]="['/contact-information']"
               [queryParams]="queryParams" routerLinkActive="active" href="javascript://"
               translate="mainNav.contactInformation" id="contact_information">
            </a>
        </li>
        <li>
            <a *ngIf="!formService.forms.employmentInformation.isVisited" class="disabled"
               data-region="left navigation" data-ana="mywealth:onboarding:navigation:employment" href="javascript://"
               translate="mainNav.employmentInformation" attr.aria-label="{{ 'mainNav.ariaLabels.employmentInformation' | translate }}">
            </a>
            <a *ngIf="formService.forms.employmentInformation.isVisited" data-region="left navigation"
               [class.disabled]="isDisabled || disableEmploymentInformation" [attr.tabindex]="tabIndexValue" data-ana="mywealth:onboarding:navigation:employment"
               [ngClass]="{completed: formService.forms.employmentInformation.isCompleted}"
               [routerLink]="['/employment-information']" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.employmentInformation" id="employment_information">
            </a>
        </li>
        <li>
            <a *ngIf="!formService.forms.familyInformation.isVisited" class="disabled"
               data-region="left navigation" data-ana="mywealth:onboarding:navigation:family" href="javascript://"
               translate="mainNav.familyInformation" attr.aria-label="{{ 'mainNav.ariaLabels.familyInformation' | translate }}">
            </a>
            <a *ngIf="formService.forms.familyInformation.isVisited" [class.disabled]="isDisabled || disableFamilyInformation" [attr.tabindex]="tabIndexValue"
               data-ana="mywealth:onboarding:navigation:family" data-region="left navigation"
               [ngClass]="{completed: formService.forms.familyInformation.isCompleted}"
               [routerLink]="['/family-information']" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.familyInformation" id="family_information">
            </a>
        </li>

         <li *ngIf="(hasBeneficiaryDetails || (BILAssisted && !branchSDMultiAccount)) && (beneficiaryCondition && !isBeneficiaryPageDisabled && !isCashAccount)">
            <a *ngIf="!formService.forms.beneficiaryInformation.isVisited" data-ana="mywealth:onboarding:navigation:beneficiary" data-region="left navigation"
               class="disabled" href="javascript://" translate="mainNav.beneficiary" attr.aria-label="{{ 'mainNav.ariaLabels.beneficiary' | translate }}">
            </a>
            <a *ngIf="formService.forms.beneficiaryInformation.isVisited" data-ana="mywealth:onboarding:navigation:beneficiary"
               [class.disabled]="isDisabled || disableBeneficiaryInformation" [attr.tabindex]="tabIndexValue" data-region="left navigation"
               [ngClass]="{completed: formService.forms.beneficiaryInformation.isCompleted}"
               [routerLink]="['/'+ APP_ROUTES.beneficiary]" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.beneficiary" id="beneficiary">
            </a>
        </li>
        <li *ngIf="(withdrawalCondition && !isWithdrawalPageDisabled)">
            <a *ngIf="!formService.forms.withdrawal.isVisited" data-ana="mywealth:onboarding:navigation:withdrawal" data-region="left navigation"
               class="disabled" href="javascript://" translate="mainNav.withdrawal" attr.aria-label="{{ 'mainNav.ariaLabels.withdrawal' | translate }}">
            </a>
            <a *ngIf="formService.forms.withdrawal.isVisited" data-ana="mywealth:onboarding:navigation:withdrawal" [class.disabled]="isDisabled || disableWithdrawal"
               [attr.tabindex]="tabIndexValue" data-region="left navigation"
               [ngClass]="{completed: formService.forms.withdrawal.isCompleted}"
               [routerLink]="['/'+ APP_ROUTES.withdrawal]" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.withdrawal" id="withdrawal">
            </a>
        </li>
        <li>

            <a *ngIf="!formService.forms.financialInformation.isVisited"
               data-ana="mywealth:onboarding:navigation:financial" data-region="left navigation"
               class="disabled" href="javascript://" translate="mainNav.financialInformation" attr.aria-label="{{ 'mainNav.ariaLabels.financialInformation' | translate }}">
            </a>
            <a *ngIf="formService.forms.financialInformation.isVisited"
               data-ana="mywealth:onboarding:navigation:financial" [class.disabled]="isDisabled || disableFinancialInformation" [attr.tabindex]="tabIndexValue"
               data-region="left navigation"
               [ngClass]="{completed: formService.forms.financialInformation.isCompleted, active: currentPage.financial}"
               [routerLink]="['/financial-details']" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.financialInformation" id="financial_details">
            </a>
        </li>
        <li *ngIf="adviceDirect">
            <a *ngIf="!formService.forms.investmentKnowledge.isVisited" data-ana="mywealth:onboarding:navigation:investmentKnowledge" data-region="left navigation"
                class="disabled" href="javascript://" translate="mainNav.investmentKnowledge" attr.aria-label="{{ 'mainNav.ariaLabels.investmentKnowledge' | translate }}"
               >
            </a>
            <a *ngIf="formService.forms.investmentKnowledge.isVisited"
                data-ana="mywealth:onboarding:navigation:investmentDetails" [class.disabled]="isDisabled || disbaleInvestmentKnowledge"
                data-region="left navigation" [ngClass]="{completed: formService.forms.investmentKnowledge.isCompleted}"
                [routerLink]="['/'+ APP_ROUTES.investment_knowledge]" [queryParams]="queryParams"
                routerLinkActive="active" href="javascript://" translate="mainNav.investmentKnowledge" id="investment_knowledge">
            </a>
        </li>
        <li *ngIf="adviceDirect">
            <a *ngIf="!formService.forms.riskAndReturn.isVisited" data-ana="mywealth:onboarding:navigation:RiskAndReturn" data-region="left navigation"
                tabindex="-1" class="disabled" href="javascript://" translate="mainNav.riskAndReturn"
                aria-hidden="true">
            </a>
            <a *ngIf="formService.forms.riskAndReturn.isVisited"
                data-ana="mywealth:onboarding:navigation:RiskAndReturn" [class.disabled]="isDisabled || disableRiskAndReturn"
                data-region="left navigation"
                [ngClass]="{completed: formService.forms.riskAndReturn.isCompleted}"
                [routerLink]="['/'+ APP_ROUTES.riskAndReturn]" [queryParams]="queryParams"
                routerLinkActive="active" href="javascript://" translate="mainNav.riskAndReturn" id="risk_and_return">
            </a>
        </li>
        <li *ngIf="adviceDirect">
            <a *ngIf="!formService.forms.investorProfile.isVisited" data-ana="mywealth:onboarding:navigation:investorProfile" data-region="left navigation"
                class="disabled" href="javascript://" translate="mainNav.investorProfile" attr.aria-label="{{ 'mainNav.ariaLabels.investorProfile' | translate }}">
            </a>
            <a *ngIf="formService.forms.investorProfile.isVisited"
               data-ana="mywealth:onboarding:navigation:investorProfile" [class.disabled]="isDisabled || disableInvestorProfile" [attr.tabindex]="tabIndexValue"
               data-region="left navigation" [ngClass]="{completed: formService.forms.investorProfile.isCompleted}"
               [routerLink]="['/'+ APP_ROUTES.investor_profile]" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.investorProfile" id="investor_profile">
            </a>
        </li>
        <li>
            <a *ngIf="!formService.forms.regulatoryDisclosures.isVisited"
               data-ana="mywealth:onboarding:navigation:regualtory" data-region="left navigation"
               class="disabled" href="javascript://" translate="mainNav.regulatory" attr.aria-label="{{ 'mainNav.ariaLabels.regulatory' | translate }}">
            </a>
            <a *ngIf="formService.forms.regulatoryDisclosures.isVisited"
               data-ana="mywealth:onboarding:navigation:regualtory" [class.disabled]="isDisabled || disableRegulatoryDisclosures" [attr.tabindex]="tabIndexValue"
               data-region="left navigation"
               [ngClass]="{completed: formService.forms.regulatoryDisclosures.isCompleted}"
               [routerLink]="['/regulatory-disclosures']" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.regulatory" id="regulatory_disclosures">
            </a>
        </li>
        <li>
            <a *ngIf="!formService.forms.consents.isVisited" class="disabled"
               data-region="left navigation" data-ana="mywealth:onboarding:navigation:consents" href="javascript://"
               translate="mainNav.consents" attr.aria-label="{{ 'mainNav.ariaLabels.consents' | translate }}">
            </a>
            <a *ngIf="formService.forms.consents.isVisited" data-region="left navigation"
               data-ana="mywealth:onboarding:navigation:consents" [class.disabled]="isDisabled || disableConsents" [attr.tabindex]="tabIndexValue"
               data-region="left navigation" [ngClass]="{completed: formService.forms.consents.isCompleted}"
               [routerLink]="['/consents']" [queryParams]="queryParams" routerLinkActive="active" href="javascript://"
               translate="mainNav.consents" id="consents">
            </a>
        </li>
        <!-- <li *ngIf="(BILAssisted && isCashAccount) || (!BILAssisted && (adviceDirect ||  selfDirected))"> -->
        <li *ngIf="((!BILAssisted) || (BILAssisted && !adviceDirect) || (BILAssisted && isCashAccount)) && !isOlbCdbUser && !isJourneysUser">
            <a *ngIf="!formService.forms.bmoRelationship.isVisited" data-region="left navigation"
               data-ana="mywealth:onboarding:navigation:bmo relationship" class="disabled"
               href="javascript://" translate="mainNav.bmoRelationship" attr.aria-label="{{ 'mainNav.ariaLabels.bmoRelationship' | translate }}">
            </a>
            <a *ngIf="formService.forms.bmoRelationship.isVisited" data-region="left navigation"
               [class.disabled]="isDisabled || disableBmoRelationship" [attr.tabindex]="tabIndexValue" data-ana="mywealth:onboarding:navigation:bmo relationship"
               [ngClass]="{completed: formService.forms.bmoRelationship.isCompleted}"
               [routerLink]="['/bmo-relationship']" [queryParams]="queryParams" routerLinkActive="active"
               href="javascript://" translate="mainNav.bmoRelationship" id="bmo_relationship">
            </a>
        </li>
        <li *ngIf="!isOlbCdbUser && !isJourneysUser">
            <a *ngIf="!formService.forms.accountPassword.isVisited" data-region="left navigation" id="test"
               data-ana="mywealth:onboarding:navigation:password" class="disabled" href="javascript://"
               translate="mainNav.accountPassword" attr.aria-label="{{ 'mainNav.ariaLabels.accountPassword' | translate }}">
            </a>
            <a *ngIf="formService.forms.accountPassword.isVisited" data-ana="mywealth:onboarding:navigation:password"
               data-region="left navigation" [ngClass]="{completed: formService.forms.accountPassword.isCompleted}"
               [class.disabled]="isDisabled || disableAccountPassword" [attr.tabindex]="tabIndexValue" [routerLink]="['/account-password']" [queryParams]="queryParams"
               routerLinkActive="active" href="javascript://" translate="mainNav.accountPassword" id="account_password">
            </a>
        </li>
        <li *ngIf="isOlbCdbUser || (isJourneysUser && isNonRegisteredAccount)">
            <a *ngIf="!formService.forms.accountPassword.isVisited" data-region="left navigation"
               data-ana="mywealth:onboarding:navigation:password" class="disabled" href="javascript://"
               translate="mainNav.accountAccess" attr.aria-label="{{ 'mainNav.ariaLabels.accountAccess' | translate }}">
            </a>
            <a *ngIf="formService.forms.accountPassword.isVisited" data-ana="mywealth:onboarding:navigation:password"
               data-region="left navigation" [ngClass]="{completed: formService.forms.accountPassword.isCompleted}"
               [class.disabled]="isDisabled || disableAccountPassword" [attr.tabindex]="tabIndexValue" [routerLink]="['/account-access']" [queryParams]="queryParams"
               routerLinkActive="active" href="javascript://" translate="mainNav.accountAccess" id="account_access">
            </a>
         </li>
        <li>
            <a *ngIf="!formService.forms.review.isVisited" data-region="left navigation"
               data-ana="mywealth:onboard ing:navigation:review" class="disabled" href="javascript://"
               translate="mainNav.review" attr.aria-label="{{ 'mainNav.ariaLabels.review' | translate }}">
            </a>
            <a *ngIf="formService.forms.review.isVisited" data-ana="mywealth:onboarding:navigation:review"
               data-region="left navigation" [ngClass]="{completed: formService.forms.review.isCompleted}"
               [class.disabled]="isDisabled || disableReview" [attr.tabindex]="tabIndexValue" [routerLink]="['/review']" [queryParams]="queryParams"
               routerLinkActive="active" href="javascript://" translate="mainNav.review" id="review">
            </a>
        </li>
        <li>
            <a *ngIf="!formService.forms.termsConditions.isVisited" class="disabled"
               data-region="left navigation" data-ana="mywealth:onboarding:navigation:esign" href="javascript://"
               translate="mainNav.termsConditions" attr.aria-label="{{ 'mainNav.ariaLabels.termsConditions' | translate }}">
            </a>
            <a *ngIf="formService.forms.termsConditions.isVisited" data-region="left navigation"
               [ngClass]="{completed: formService.forms.termsConditions.isCompleted}"
               data-ana="mywealth:onboarding:navigation:esign" [routerLink]="['/terms-conditions']"
               [queryParams]="queryParams" routerLinkActive="active" href="javascript://"
               translate="mainNav.termsConditions" id="terms_conditions">
            </a>
        </li>
    </ul>

</nav>

<div class="nav-modal-overlay"></div>
