<form #branchReferralForm="ngForm" (ngSubmit)="onSubmit()">

    <div class="container-noMenuView">

        <section>

            <app-invalid-form #appInvalidForm [isFormInvalid]="(branchReferralForm.invalid && isFormSubmit)" translateTag="branchReferral">
            </app-invalid-form>

            <section>
                <div class="alert alert-yellow" *ngIf="cmsMessage">
                    <a role="button" attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
                        attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}" class="btn-icon btn-close tooltip-show"
                        href="javascript://" (click)="onCloseCMS()">
                        <span class="icon icon-large close-grey"></span>
                    </a>
                    <p>
                        <strong *ngIf="cmsMessageTitle!==''">{{cmsMessageTitle}}</strong><br />
                        <span *ngIf="cmsMessage!==''">{{cmsMessage}}</span>
                    </p>
                </div>

                <h1 id="investmentPlatformDescribe" translate="branchReferral.titleChoosePlatform"></h1>

                <div class="form-wrapper">

                    <div role="group" aria-labelledby="investmentPlatformDescribe" class="form-row" id='parent-investmentPlatform-error'>

                        <!--Investment platforms-->
                        <div class="form-input-checks selection-card square-card" [ngClass]="{'error-field': investmentPlatformCtrl.errors && investmentPlatformCtrl.touched || (isFormSubmit && investmentPlatformCtrl.errors), 'active-label': investmentPlatformCtrl.value}">
                            <input id="selfPlatform" name="investmentPlatform" type="radio" value="SD"
                             [(ngModel)]="branchReferralObj.investmentPlatform"
                              data-ana="mywealth:onboarding:branch referral:platform:sd"
                                #investmentPlatformCtrl="ngModel" [appErrorAccess]="{id:'investmentPlatform-error', parentId: 'parent-investmentPlatform-error', submitted: isFormSubmit}" required />
                            <label class="small-card" for="selfPlatform">
                                <span class="check"></span>
                                <span class="image">
                                    <img src="assets/img/branch-referral/self-direct.svg" alt="" />
                                </span>
                                <span class="description" translate="branchReferral.labels.selfDirect"></span>
                            </label>
                        </div>

                        <div class="form-input-checks selection-card square-card" [ngClass]="{'error-field': investmentPlatformCtrl.errors && investmentPlatformCtrl.touched || (isFormSubmit && investmentPlatformCtrl.errors), 'active-label': investmentPlatformCtrl.value}">
                            <input id="advicePlatform" name="investmentPlatform" type="radio" value="AD" [(ngModel)]="branchReferralObj.investmentPlatform" data-ana="mywealth:onboarding:branch referral:platform:ad"
                                #investmentPlatformCtrl="ngModel" [appErrorAccess]="{id:'investmentPlatform-error', parentId: 'parent-investmentPlatform-error',  submitted: isFormSubmit}" required />
                            <label class="small-card" for="advicePlatform">
                                <span class="check"></span>
                                <span class="image">
                                    <img src="assets/img/branch-referral/advice-direct.svg" alt="" />
                                </span>
                                <span class="description" translate="branchReferral.labels.adviceDirect"></span>
                            </label>
                        </div>

                        <div role="alert" id ="investmentPlatform-error" class="error-message" *ngIf="investmentPlatformCtrl.errors && investmentPlatformCtrl.touched || (isFormSubmit && !branchReferralObj.investmentPlatform)">
                            <span [innerHtml]="'branchReferral.errors.investmentPlatformError'| translate | safeHtml"></span>
                        </div>

                    </div>

                </div>


            </section>

            <section>

                <h1 translate="branchReferral.titleReferralDetails"></h1>
                <p translate="branchReferral.descriptionReferralDetails01"></p>

                <div class="form-wrapper">

                    <div class="form-row">
                        <div class="flex-70 form-col">
                            <div class="form-field">
                                <div class="form-label-group">

                                    <!--Branch personnel designation-->
                                    <select id="branchPersonnelDesignation" name="branchPersonnelDesignation" [(ngModel)]="branchReferralObj.branchPersonnel"
                                        #selectBranchPersonnelCtrl="ngModel" (change)="updateBranchPersonnelDesignation($event)"
                                        data-ana="mywealth:onboarding:branch referral:role"
                                        [appErrorAccess]="{id:'branchPersonnelDesignation-error',  submitted: isFormSubmit}" required>

                                        <option *ngFor="let designation of branchPersonnelList" [value]="designation.code" [selected]="designation.selected">
                                            {{designation.label}}
                                        </option>

                                    </select>
                                    <label [ngClass]="{'error-field': selectBranchPersonnelCtrl.errors && selectBranchPersonnelCtrl.touched || (isFormSubmit && selectBranchPersonnelCtrl.errors), 'active-label': selectBranchPersonnelCtrl.value}"
                                        class="slide-label" for="branchPersonnelDesignation">
                                        <span class="label-text" translate="branchReferral.labels.branchPersonalDesignation"></span>
                                    </label>

                                </div>
                            </div>
                            <div role="alert" id="branchPersonnelDesignation-error" class="error-message" *ngIf="selectBranchPersonnelCtrl.errors && selectBranchPersonnelCtrl.touched || (isFormSubmit && selectBranchPersonnelCtrl.errors)">
                                <span [innerHtml]="'branchReferral.errors.referralDetailsError'| translate | safeHtml"></span>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- HIDE this entire DIV if no selections are made from the "branchPersonnelDesignation" drop-down-->
                <!-- --- -->
                <div *ngIf="branchReferralObj.branchPersonnel != null || branchReferralObj.branchPersonnel != ''">

                    <!-- SHOW this DIV for Personal/Senior Banker and Branch Manager OR FP -->
                    <!-- --- -->
                    <app-personal-banker-or-financial-planner [branchReferralObj]="branchReferralObj" [isFormSubmit]="isFormSubmit" [form]="branchReferralForm"></app-personal-banker-or-financial-planner>

                    <!-- SHOW this DIV for Personal/Senior Banker AND FP -->
                    <!-- --- -->
                    <app-personal-banker-and-financial-planner [branchReferralObj]="branchReferralObj" [isFormSubmit]="isFormSubmit" [form]="branchReferralForm"></app-personal-banker-and-financial-planner>

                </div>

                <!--checkbox-->
                <div class="form-wrapper">
                    <div class="form-row">
                        <div class="form-col">

                            <div class="form-input-checks check-default" [ngClass]="{'error-field': iAcceptTwoStepCheckCtrl.errors && isFormSubmit}">
                                <input id="iAcceptTwoStep" type="checkbox"  data-ana="mywealth:onboarding:branch referral:certify" name="iAcceptTwoStep" [(ngModel)]="branchReferralObj.checkCertify" #iAcceptTwoStepCheckCtrl="ngModel"
                                [appErrorAccess]="{id:'iAcceptTwoStep-error',  submitted: isFormSubmit}"  required />
                                <label for="iAcceptTwoStep" [innerHtml]="'branchReferral.labels.checkCertify'| translate | safeHtml"></label>
                            </div>

                            <div role="alert" id="iAcceptTwoStep-error" class="error-message" *ngIf="iAcceptTwoStepCheckCtrl.errors && iAcceptTwoStepCheckCtrl.touched || (isFormSubmit && iAcceptTwoStepCheckCtrl.errors)">
                                <span [innerHtml]="'branchReferral.errors.certifyError'| translate | safeHtml"></span>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

        </section>

        <!--Save and continue button-->
        <section>
            <div class="form-wrapper">
                <div class="form-row form-page-buttons">
                    <div class="form-col left-side">
                    </div>
                    <div class="form-col right-side">
                        <button [disabled]="(!branchReferralObj.checkCertify) || (branchReferralForm.invalid)" class="primary" type="submit" translate="button.saveContinue"></button>
                    </div>
                </div>
            </div>

        </section>

    </div>

</form>
