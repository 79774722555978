import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FormService } from '../../shared/service/form.service';
export interface ICanDeactivateComponent {
  canDeactivate: Function;
}
@Injectable()
export class CanDeactivateGuard<T extends ICanDeactivateComponent> implements CanDeactivate<T> {

  constructor(private formService: FormService) { }

  canDeactivate() {
    // console.log("this.formService.getActiveForm().valid--", this.formService.getActiveForm().valid);
    //  console.log("this.formService.getActiveForm(false).isCompleted--", this.formService.getActiveForm(false).isCompleted);

    if (this.formService.getActiveForm().valid || !this.formService.getActiveForm(false).isCompleted) {
      if (this.formService.getActiveComponent() !== undefined &&
        typeof this.formService.getActiveComponent().resetComponentObjectAttr !== 'undefined') {
        this.formService.getActiveComponent().resetComponentObjectAttr();
      }
      return true;
    }
    this.formService.getActiveComponent().isFormSubmit = true;
    return false;
  }
}
