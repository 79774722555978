import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    UntypedFormControl,
    ValidatorFn,
    Validator, AbstractControl
} from '@angular/forms';

import {Router} from '@angular/router';

import { Directive } from '@angular/core';
import { FormService } from '../../shared/service/form.service';
import { Utils } from '../utils/utils.class';

@Directive({
    selector: '[appSpousalSinValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: SpousalSinValidator,
            multi: true
        }
    ]
})
export class SpousalSinValidator implements Validator {
    validator: ValidatorFn;
    public page;

    constructor( public formService: FormService, private router: Router) {
        this.validator = this.SpousalSinValidator();
        router.events.subscribe((url: any) => console.log());

         this.page = router.url.substring(
            router.url.lastIndexOf('/') + 1,
            router.url.lastIndexOf('?')
        );
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    SpousalSinValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            let sin;
            if (control && control.value !== null && control.value !== undefined) {
            sin = control.value.replace(/[-\s.]/g, '');
            }
            if (typeof sin === 'number') {
                sin = sin.toString();
            }

            if (sin !== '') {

            if ((this.page === 'family-information') &&
            (this.formService.getFormData('personalInformation') != null)) {
                 if ( this.compareSinValues(sin, this.formService.getFormData('personalInformation').sinNumber.replace(/[-\s.]/g, '')) ) {
                    return {
                        spousalSinNumber: {
                            spousalSinError: true,
                        }
                    };

                 }

            }
            if ((this.page === 'personal-information') &&
             ( this.formService.getFormData('familyInformation') != null)) {
                if ( this.compareSinValues(sin, this.formService.getFormData('familyInformation').sinNumber.replace(/[-\s.]/g, '')) ) {
                    return {
                        spousalSinNumber: {
                            spousalSinError: true,
                        }
                    };

                 }
            }
            return null;

        }

        };
    }

compareSinValues(currentSIN, priviousSIN) {
    return (currentSIN === priviousSIN ? true : false);

}

}
