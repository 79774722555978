 <!-- Show this when the authentication is successful -->
        <!-- --- -->

        <section *ngIf=show>

          <div class="flex-maxis-center content-center">

              <h1 translate="modalLogIn.modal2Title"></h1>

              <p translate="modalLogIn.section2Content"></p>

          </div>

          <div class="form-wrapper no-margin">

              <div class="form-row form-page-buttons">
                  <button class="primary element-center" (click)="closeModel()" type="submit" translate="button.ok"></button>
              </div>

          </div>

      </section>