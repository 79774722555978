<a role="button"
   attr.aria-label="{{ arialabel | translate }}"
   attr.tooltip="{{ 'linkButton.moreInformation' | translate }}"
   [attr.data-ana] = "inputDataAna"
   class="btn-icon icon-large tooltip-show"
   href="javascript://">
    <img src="assets/img/info-blue.svg" alt="" />
</a>
<div role="dialog"
    class="contextual-help position-right"
    attr.aria-labelledby="{{ aria_labelledby | translate }}">
    <div class="contextual-help-arrow">
        <div class="corner-cut"></div>
    </div>
    <a role="button"
       attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
       attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
       class="btn-icon contextual-help-close"
       href="javascript://"
       [attr.data-ana] = "inputDataAna + 'btnClose'">
        <span class="icon icon-large close-grey"></span>
    </a>
    <span class="contextual-help-content" [innerHTML]="(content | translate).replace('[XXXXXXXX]', changeContent)  | safeHtml" *ngIf="changeContent"></span>
    <span class="contextual-help-content" [innerHTML]="content | translate | safeHtml" *ngIf="!changeContent"></span>
</div>

