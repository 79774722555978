import { APP_ROUTES } from './../../../config/app-config.constants';
import { Component, Input, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormService } from '../../service/form.service';
import { ApplicationService } from '../../service/application.service';
import { applicationRoutes } from '../../../app-routing.module';
import { WindowRefService } from '../../service/window-ref.service';
import { InvalidFormComponent } from '../../../sections/invalid-form/invalid-form.component';
import * as $ from 'jquery';
import { Utils } from '../../utils/utils.class';
@Component({
    selector: 'app-form-buttons',
    templateUrl: './form-buttons.component.html'
})
export class FormButtonsComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() fieldName: string;
    @Input() translation: string;
    @Input() disabled = false;
    @Input() isBackDisabled = false;
    @Input() buttonLabel = 'button.continue';
    @Input() appInvalidForm: InvalidFormComponent; // this inputs deal with to focus on <app-invalid-form> on form error or not
    @Input() dataAnaValue = '';
    @Input() showFinishLater = false;
    hideBackButton = false;
    prevRoute: any;
    @Input() formName: string;
    @Input() backRoute;
    public isCdbOlbUser: boolean = false;
    public isEnforceFinishLaterEnabled: Boolean = false;
    public applicationSaved = false;
    constructor(private location: Location, private router: Router, private formService: FormService,
        private appService: ApplicationService, private activeRoute: ActivatedRoute, private winRef: WindowRefService, public renderer: Renderer2) {
    }

    ngOnInit() {

        this.appService.getNavigationDisabled()
            .subscribe(item => {
                // console.log(' getNavigationDisabled item: ', item);
                this.isBackDisabled = item; // Disable or Enable  Backbutton
            });
            Utils.setBackRoute(this.backRoute);
         /* if (this.router.url.match(/personal-information/)) {
            this.hideBackButton = true;
        }  */

        // this.setPreviousRoute();

        // setting data-ana for esign button
        if (this.buttonLabel === 'button.eSign') {
            this.dataAnaValue = 'Investorline:wealth:onboarding:terms and conditions - esign';
        }

        this.appService.getFinishLater()
            .subscribe(item => {
                // console.log(' getFinishLater item: ', item);
                this.showFinishLater = item; // Disable or Enable  Backbutton
            });
            this.isCdbOlbUser = Utils.getIsOLBUser();
            this.isEnforceFinishLaterEnabled = this.appService.getEnforceFinishLaterFeatureFlag();
    }

    ngAfterViewInit() {
        this.applicationSaved = Utils.getIsInSession();
    }

    onBack() {
        if (this.formService.getActiveForm().valid || !this.formService.getActiveForm(false).isCompleted) {
            if (this.formService.getActiveComponent() !== undefined &&
                typeof this.formService.getActiveComponent().resetComponentObjectAttr === 'function') {
                this.formService.getActiveComponent().resetComponentObjectAttr();
            }
            if (this.backRoute === APP_ROUTES.select_account) {
            Utils.setIsSelectAccountClicked(true);
            Utils.backToSelectAccountPage = true;
            }
            this.appService.saveAndcontinue(null, this.backRoute);
        } else {
            this.formService.getActiveComponent().isFormSubmit = true;
        }
    }

    onContinue() {
        if (this.appInvalidForm && this.appInvalidForm.getIsFormInvalid) {
            this.appInvalidForm.focusAgain();
            return;
        }
        this.winRef.nativeWindow.scrollTo(0, 0); // Scrolls to the top.
        const ele = this.renderer.selectRootElement('#bilHeader', true);
        if (ele) {
           ele.focus();   
        }   
    }

    onFinishLater() {

        // console.log('onFinishLater () this.formName: ', this.formName);

        // this method will send event with form name
        if (this.formName === 'personal') {
            this.appService.setPartialSave('personal');

        } else if (this.formName === 'contact') {
            this.appService.setPartialSave('contact');
            // console.log('setPartialSave: contact ');

        } else if (this.formName === 'employment') {

            this.appService.setPartialSave('employment');
            // console.log('setPartialSave: employment ');

        } else if (this.formName === 'family') {

            this.appService.setPartialSave('family');
            // console.log('setPartialSave: family ');

        } else if (this.formName === 'beneficiary') {

            this.appService.setPartialSave('beneficiary');

        } else if (this.formName === 'finFrm') {

            this.appService.setPartialSave('finFrm');
            // console.log('setPartialSave: finFrm ');

        } else if (this.formName === 'investmentKnowledge') {

            this.appService.setPartialSave('investmentKnowledge');
            // console.log('setPartialSave: investmentKnowledge ');

        } else if (this.formName === 'riskAndReturn') {

            this.appService.setPartialSave('investmentObjectivesFrm');
            // console.log('setPartialSave: investmentObjectivesFrm ');

        } else if (this.formName === 'investorProfile') {

            this.appService.setPartialSave('investorProfileForm');
            // console.log('setPartialSave: investorProfileForm ');

        } else if (this.formName === 'regulatory') {

            this.appService.setPartialSave('regulatory');
            // console.log('setPartialSave: regulatory ');

        } else if (this.formName === 'consentsfrm') {

            this.appService.setPartialSave('consentsfrm');
            // console.log('setPartialSave: consentsfrm ');

        } else if (this.formName === 'BRFrm') {

            this.appService.setPartialSave('BRFrm');
            // console.log('setPartialSave: BRFrm ');

        } else if (this.formName === 'acctPwdFrm') {

            this.appService.setPartialSave('acctPwdFrm');
            // console.log('setPartialSave: acctPwdFrm ');

        } else if (this.formName === 'reviewFrm') {

            this.appService.setPartialSave('reviewFrm');
            // console.log('setPartialSave: reviewFrm ');

        } else if (this.formName === 'tncFrm') {

            this.appService.setPartialSave('tncFrm');
            // console.log('setPartialSave: tncFrm ');

        } else if (this.formName === 'withdrawal') {

            this.appService.setPartialSave('withdrawal');
            // console.log('setPartialSave: tncFrm ');

        }
        
    }

    setPreviousRoute() {
        if (this.activeRoute.snapshot.url[0] !== undefined) {
            const activeRoutePath = this.activeRoute.snapshot.url[0].path;
            const allPaths = applicationRoutes[0].children.concat(applicationRoutes[1].children, applicationRoutes[2].children)
                .map(x => x.path)
                .filter(y => [APP_ROUTES.celebration, APP_ROUTES.celebration_review].indexOf(y) === -1);
            this.prevRoute = allPaths[allPaths.indexOf(activeRoutePath) - 1];
        }
    }
}
