// @created:
// @updated: Aravind Anantharaman

import { WealthSource } from './../financial-details/financial-details.component';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubscriptionLike as ISubscription, Subscription } from 'rxjs';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { FormService } from '../../shared/service/form.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { COUNTRY_CODE, LANGUAGES, State } from "../../../app/config/app-config.constants";
import {
    APP_ROUTES, APP_CONFIG, SUCCESS_RESPONSE, LOCATION_INDEX, ANALYTICS,
    INVESTMENT_KNOWLEDGE_TYPES, productTypeAD, productTypeSD, QUEBEC, RIF_PAYMENT_METHOD, WITHDRAWAL_FREQUENCY_DROPDOWN_FR, WITHDRAWAL_FREQUENCY_DROPDOWN_EN,
    RSM_REFERRAL_ROLE, SCREENER_QUESTION_OPTION_CODES, INVESTMENT_KNOWLEDGE_LEVEL, LANGUAGE_ENGLISH, CASH, ACCOUNT_TYPE_ID
} from './../../config/app-config.constants';
import { find, values, cloneDeep, get } from 'lodash';
import { EmploymentInfoService } from '../../shared/service/employment-info.service';
import { EMPLOYMENTSTATUS } from '../../config/app-config.constants';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { SelectAccount } from '../select-account/select-account.component';
import { AccountDetail } from '../account-detail/account-detail.component';
import { Utils } from '../../shared/utils/utils.class';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { PersonalInformation, SelectedCountry } from '../personal-information/personal-information.component';

@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    providers: [EmploymentInfoService]
})

export class ReviewComponent implements OnInit, OnDestroy, AfterViewInit {

    public APP_ROUTES = APP_ROUTES;
    public productTypeAD = productTypeAD;
    public productTypeSD = productTypeSD;
    public selectAccount: SelectAccount = new SelectAccount();
    public accountDetail: AccountDetail = new AccountDetail();
    public disableButton: Boolean = false;
    public disableDocButton: Boolean = false;
    // public showAccountCardNumber: Boolean = false;
    public BILAssisted;
    public BranchAssisted: boolean;
    public isSubProductAD: Boolean = null;
    public isCashAccount: boolean = false;
    public isRRSPAccount: Boolean = false;
    public isTFSAAccount: Boolean = false;
    public isSpousalRRSP: Boolean = false;
    public isRIFAccount: Boolean = false;
    public isMargin: boolean = false;
    public isRRIF: Boolean = false;
    public lang: string;
    public provinces: any;
    public states: any;
    public error: any;
    public content: any;
    public moment: any;
    public employmentInformation: any;
    public familyInformation: any;
    public beneficiaryInformation: any;
    public withdrawal: any;
    public regulatoryDisclosures: any;
    public investmentKnowledge: any;
    public investmentObjectives: any;
    public riskAndReturn: any;
    public investorProfile: any;
    public branchReferral: any;
    public INVESTMENT_KNOWLEDGE_TYPES: any = INVESTMENT_KNOWLEDGE_TYPES;
    public RIF_PAYMENT_METHOD: any = RIF_PAYMENT_METHOD;
    public accountTypeId = ACCOUNT_TYPE_ID;
    public isMultiAccountOpen: boolean = false;
    public optionTradingMultiAccountEligible: any;
    public CASH_CODE = CASH.type;

    public employmentInfoDropDownData: any = {
        occupationLabel: '',
        jobStatusLabel: '',
        natureOfBusinessLabel: '',
        provinceLabel: '',
        countryLabel: '',
        stateLabel: '',
        prevOccupationLabel: '',
        bmoGroupOption: ''
    };
    public familyInfoDropDownData: any = {
        occupationLabel: '',
        jobStatusLabel: '',
        natureOfBusinessLabel: '',
        bmoGroupOption: '',
        maritalStatusLabel: '',
        spousalMaritalStatusLabel: ''
    };
    public regulatoryDropDownData: any = {
        domesticTitle: ''
    };
    public withdrawalFormData: any = {
        frequency: '',
        startMonth: '',
        rifAmountType: '',
        timeOfMonth: ''
    };
    public RETIRED: any = EMPLOYMENTSTATUS.RETIRED;
    public COUNTRYCANADA: any = EMPLOYMENTSTATUS.COUNTRY.CANADA;
    public COUNTRYUSA: any = EMPLOYMENTSTATUS.COUNTRY.USA;
    public NOTPRESENTLYEMPLOYED: any = EMPLOYMENTSTATUS.NOTPRESENTLYEMPLOYED;
    public isBMONetwork: boolean = false;
    public criticalError = false; // hide and show errors on page
    public UI_CONSTANTS: any;
    public reference: any;
    public isPromocode = false;
    public showFinishLater;
    public isNonQuebec = false;
    public hasBeneficiaries = this.formService.getPayload().accounts[0].hasBeneficiaries;
    public enableRSM: boolean = false;
    public isEmploymentDetailsRetired: Boolean = false;
    appNumber;
    public accountSetup;

    public url: any;

    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();

    public queryParams = {};
    public adviceDirect = false;
    public selfDirected = false;
    isApplicantPBclient: boolean;
    public primaryApplicant: any;
    public countryListTfsaCash: any;
    public usCitizenshipTaxResidencyString: any;
    public isOlbCdbUser: Boolean = false;
    private onExitApplicationPerformSaveSubscriber;
    public isCeightSixEnabled = false;
    public investmentKnowledgeLevel = INVESTMENT_KNOWLEDGE_LEVEL;
    public isSTPOptionsEnabled: any;
    public isDataRationalizationEnabled: boolean = false;
    public isDataRationalization4Enabled = false;
    public isAMLFunctionalityEnabled: boolean = false;
    public rsmReferalRole: any;
    public isCsaCfrAttorneyEnabled: boolean = false;
    public isCaaTaxResidencyCertificateEnabled: boolean = false;
    public isNewContentR5Enabled: boolean = false;
    public isCIROEnabled: boolean = false;
    public setExpectation: any;
    public screenerQ1Options = SCREENER_QUESTION_OPTION_CODES.SQ1;
    public screenerQ2Options = SCREENER_QUESTION_OPTION_CODES.SQ2;
    isMultiAccountOpenFlow: boolean = false;
    isBranchSDMultiAccountOpenFlow: boolean = false;
    isJourneyUser: boolean = false;
    isNonRegisteredAccount: boolean = false;
    getCountriesNew: any = [];

    constructor(private http: HttpClient,
        public titleService: Title,
        private sanitizer: DomSanitizer,
        private winRef: WindowRefService,
        public formService: FormService,
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private empInfoService: EmploymentInfoService,
        public analytics: AnalyticsService) {

        this.formService.forms.review.isVisited = true;
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'reviewFrm') {
                    this.onFinishLater();
                }
            });
        this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
            if (value) {
                this.onFinishLater(true);
            }
        });
        if (Utils.isSubProductAD()) {
            this.adviceDirect = true;
        } else {
            this.selfDirected = true;
        }

        this.moment = moment;
        this.formService.setActiveComponent('review', this);
        this.UI_CONSTANTS = APP_CONFIG.UI_CONSTANTS_MAP;


        this.url = '/' + APP_ROUTES.review;
    }

    ngAfterViewInit() {
        // this.analytics.setState('step 14');
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('finalReview');

        // this.analytics.setvPageTitle(Utils.getPageTitleFinalReview());
        // this.analytics.setOriginalLocation(Utils.getPageTitleFinalReview());
        // this.analytics.setvURL(Utils.getvURL());
    }

    ngOnInit() {

        this.isCsaCfrAttorneyEnabled = this.applicationService.getCsaCfrAttorneyFeatureFlag();
        this.isCeightSixEnabled = this.applicationService.getC86FeatureFlag();
        this.isSTPOptionsEnabled = this.applicationService.getSTPOptionsFeatureFlag();
        this.isDataRationalizationEnabled = this.applicationService.getDataRationalizationFeatureFlag();
        this.isDataRationalization4Enabled = this.applicationService.getDataRationalization4FeatureFlag();
        this.isAMLFunctionalityEnabled = this.applicationService.getAMLFunctionalityFlag();
        this.isCaaTaxResidencyCertificateEnabled = this.applicationService.getCaaTaxResidencyCertificateFlag();
        this.isMultiAccountOpen = this.applicationService.getMultiAccountOpenFeatureFlag();
        this.isMultiAccountOpenFlow = Utils.isMultiAccountFlow();
        this.isBranchSDMultiAccountOpenFlow = Utils.isBranchSDMultiAccountFlow();
        this.isCIROEnabled = this.applicationService.getCIROEnabledFeatureFlag();
        this.isJourneyUser = Utils.getIsJourneyUser();
        this.isEmploymentDetailsRetired = Utils.isEmploymentDetails();
        this.isNewContentR5Enabled = this.applicationService.getNewContentR5EnabledFeatureFlag();
        if (Utils.isSubProductAD()) {

            this.queryParams = {
                lang: Utils.getLanguage(),
                subProduct: Utils.getSubProduct()
            };

        } else {

            this.queryParams = {
                lang: Utils.getLanguage()
            };

        }
        if ((this.formService.forms.personalInformation.data.usResidentCheckBox || this.formService.forms.personalInformation.data.usTaxResidentCheckBox) && this.isCaaTaxResidencyCertificateEnabled) {
            this.usCitizenshipTaxResidencyString = this.setUsCitizenShipLabel();
        }
        if (Utils.getMboxDisable() && Utils.getMboxEdit()) {
            this.queryParams['mboxEdit'] = Utils.getMboxEdit();
            this.queryParams['mboxDisable'] = Utils.getMboxDisable();
        }

        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
            // if (this.formService.forms.bmoRelationship.isVisited === true && this.formService.forms.bmoRelationship.data !== null
            //     && this.formService.forms.bmoRelationship.data.isApplicantExistingPBClient) {
            //     this.isApplicantPBclient = this.formService.forms.bmoRelationship.data.isApplicantExistingPBClient;

            // }

        }

        this.titleService.setTitle(Utils.getPageTitleFinalReview());
        this.primaryApplicant = this.applicationService.getPrimaryApplicant();

        this.applicationService.fetchReferences(this.route);
        if (this.applicationService.appReferences !== undefined) {
            if (this.applicationService.appReferences.isReferenceCallFailed) {
                this.criticalError = true;
            }
        }
        this.reference = this.applicationService.appReferences.references;
        this.employmentInformation = this.formService.getFormData('employmentInformation');
        this.familyInformation = this.formService.getFormData('familyInformation');
        this.regulatoryDisclosures = this.formService.getFormData('regulatoryDisclosures');
        this.investmentKnowledge = this.formService.getFormData('investmentKnowledge');
        this.riskAndReturn = this.formService.getFormData('riskAndReturn');
        this.branchReferral = this.formService.getFormData('branchReferral');

        this.investorProfile = this.formService.getFormData('investorProfile');
        this.beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');
        this.isNonQuebec = this.formService.getPayload().parties[0].personal.residence.primaryAddress.province !== QUEBEC;
        // this.investorProfile = this.formService.getPayload().getPrimaryAccount().investmentObjectives;
        this.withdrawal = this.formService.getFormData('withdrawal');

        this.selectAccount = this.formService.getFormData('selectAccount');
        this.accountDetail = this.formService.getFormData('accountDetail');
        // this.isCashAccount = Utils.isCash() ? true : false;
        this.isRRSPAccount = Utils.isRRSP() ? true : false;
        this.isTFSAAccount = Utils.isTFSA() ? true : false;
        this.isRIFAccount = Utils.isRIF() ? true : false;
        this.isSpousalRRSP = Utils.isSpousalRRSP() ? true : false;
        // this.isMargin = Utils.isMargin() ? true : false;
        this.isCashAccount = this.applicationService.isCashAccount();
        this.isMargin = this.applicationService.isMargin();
        this.isNonRegisteredAccount = this.isMargin || this.isCashAccount;
        this.isRRIF = Utils.isRIF() ? true : false;
        this.BILAssisted = Utils.getIsBranchReferral();
        this.BranchAssisted = Utils.getIsBranchAssisted();
        this.isSubProductAD = Utils.isSubProductAD();
        if (this.selectAccount.tradingKnowledgeLevel == this.investmentKnowledgeLevel.Limited || this.selectAccount.tradingKnowledgeLevel == this.investmentKnowledgeLevel.Knowledgeable || this.selectAccount.tradingKnowledgeLevel == this.investmentKnowledgeLevel.Expert) {
            this.selectAccount.hasTradingKnowledge = true;
        }
        else if (this.selectAccount.tradingKnowledgeLevel == this.investmentKnowledgeLevel.None) {
            this.selectAccount.hasTradingKnowledge = false;
        }

        this.setExpectation = this.formService.getFormData('setExpectation');
        // select active lang
        this.route.queryParams.subscribe(params => {
            if (params.lang) {
                this.lang = params.lang;
            } else {
                this.lang = 'en';
            }
        });


        this.disableButton = true;
        this.disableDocButton = true;
        this.parseEmploymentDropDownData();
        //this.parsepersonalInformationDropDownData();
        this.parseRegulatoryDropDownData();
        this.parseFamilyDropDownData();
        this.isPromocodeApplied();
        this.parseWithdrawalData();

        this.formService.setActiveForm({ valid: true });

        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data === 'clicked on ' + this.url) {

                this.applicationService.validForm = true;
            }
        });

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data === 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        setTimeout(() => {
            this.setGTM();
        }, 200);
        this.isOlbCdbUser = Utils.getIsOLBUser();
        this.isJourneyUser = Utils.getIsJourneyUser();

        if (!this.BranchAssisted && !this.isOlbCdbUser && !this.isJourneyUser) {
            let cmsMessageObject = Utils.getCmsMessageObject();
            if (cmsMessageObject) {
                this.enableRSM = cmsMessageObject.selfServe.enableRSM;
                if (this.enableRSM) {
                    this.rsmReferalRole = RSM_REFERRAL_ROLE;
                    this.isBMONetwork = Utils.getIsBMONetwork();
                }
            }
        }
        this.optionTradingMultiAccountEligible = this.optionTradingMultiAccountElibigle();
        this.getCountriesNew = this.getCountries();
    }
    optionTradingMultiAccountElibigle() {
        if (this.isMultiAccountOpen && this.selfDirected &&
            ((this.selectAccount.selectedAccountTypes?.includes(this.CASH_CODE) && this.selectAccount.selectedAccountTypes?.length > 2) ||
                (!this.selectAccount.selectedAccountTypes?.includes(this.CASH_CODE) && this.selectAccount.selectedAccountTypes?.length > 1))) {
            return true;
        } else {
            return false;
        }

    }
    ngOnDestroy() {
        // this.retrieveApp.unsubscribe;
        //  this.docPackage.unsubscribe;

        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }
    getCountries() {
        if (this.isTFSAAccount || this.isCashAccount || this.isMargin) {

            this.countryListTfsaCash = cloneDeep(this.formService.forms['personalInformation'].data['selectedCountries']);
            this.countryListTfsaCash = this.countryListTfsaCash.filter((val) => val.code);
            if (this.applicationService.getCRAtaxresidencyEnabledFeatureFlag()) {
                let citizenCountry = this.formService.forms['personalInformation'].data['selectedCitizenCountries'];
                citizenCountry = citizenCountry.filter((val) => val.code);
                const personalInformation = new PersonalInformation();

                if (citizenCountry?.length > 0) {
                    citizenCountry.forEach((value) => {
                        if (this.countryListTfsaCash?.length > 0) {
                            let val = find(this.countryListTfsaCash, { code: value.code }) as any;
                            if (val) {
                                val.isCitizen = true;
                            } else {
                                this.createCitizenCountry(value, this.countryListTfsaCash.length);
                            }
                        } else {
                            this.createCitizenCountry(value, personalInformation.countryIndex++);
                        }
                    });
                }

            }

            if (this.countryListTfsaCash.length > 0) {
                if ((!this.countryListTfsaCash[0].code) && this.isCaaTaxResidencyCertificateEnabled && (this.isCashAccount || this.isMargin)) {
                    return '';
                }
            }
            return this.countryListTfsaCash;
        } else {
            return '';
        }
    }

    createCitizenCountry(citiCountry, cashIndex) {
        if (citiCountry.code && citiCountry.id) {
            const c = new SelectedCountry(citiCountry.id.slice(citiCountry.id.length - 1), citiCountry.countries, true);
            c.code = citiCountry.code;
            c.isCitizen = true;
            this.countryListTfsaCash.push(c);
        }
    }

    get showAccessFundsDebitCard() {
        return (this.isOlbCdbUser || (this.isJourneyUser && (this.isCashAccount || this.isMargin) && get(Utils.getApplicationObject(), 'accounts[0].ecifCardNumberList',[])?.length > 0))
         && this.data('accountPassword', 'hasBMODebitCard') ===  'No';
    }


    /**
     * Extract data from formService in a short hand way
     * @param formName Name of the form to extract data from
     * @param fieldName Name of the field in the form
     */
    data(formName, fieldName) {
        if (this.formService.forms[formName].data !== null && this.formService.forms[formName].data[fieldName] !== null) {
            return this.formService.forms[formName].data[fieldName];

        }
        return '';
    }
    getLabels(data) {

        const selectedOptions = [];
        if (data instanceof Array) {
            data.forEach(element => {

                if (element.checked === true) {
                    selectedOptions.push(' ' + element.label); // space should be there before each value MWB-4720
                }

            });

        }
        return selectedOptions;
    }
    getLabelsTrading(data) {

        const selectedOptions = [];
        if (data instanceof Array) {
            data.forEach(element => {

                if (element.checked === true) {
                    if (element.code == '3') { // margin spreads
                        if (Utils.getLanguage() == 'fr') { // french
                            selectedOptions.push(' ' + element.label + '(marge seulement)');
                        } else { //english
                            selectedOptions.push(' ' + element.label + '(margin only)');
                        }
                    } else {
                        selectedOptions.push(' ' + element.label);
                    }


                }

            });

        }
        return selectedOptions;
    }
    getCountryProvince(countryCode) {
        let countryObj = this.reference.countries.filter((ref) => ref.code === countryCode);
        return countryObj[0].stateProvinces;
    }

    getLabelFromReferences(formName, fieldName, sourceArray: any[]) {


        let code = this.data(formName, fieldName);

        if (fieldName === 'ilRiskTolerance') {
            code = code.toString();
        }
        const labelInvExp = [];
        if (fieldName === 'pastExperience') {
            code.forEach(element => {
                const temp = sourceArray.filter((val) => {
                    return val.code === element;
                });
                if (temp.length > 0) {
                    labelInvExp.push(' ' + temp[0].label.toString());
                }
            });
            return labelInvExp;
        }
        const label = sourceArray.filter((val) => {
            return val.code === code;
        });
        return label.length ? label[0].label.toString() : '';

    }

    getLabelFromReferencesData(formName, fieldName, sourceArray: any[]) {
        let code = this.data(formName, fieldName);
        const label = sourceArray.filter((val) => {
            return val.code == code;
        });
        return label.length ? label[0].label.toString() : '';

    }

    getLabelFromReferencesWithCode(code, sourceArray: any[]) {
        const label = sourceArray.filter((val) => {
            return val.code === code;
        });
        return label.length ? label[0].label.toString() : '';

    }

    isPromocodeApplied() {
        if (this.selectAccount.promocode) {
            this.isPromocode = true;
        } else {
            this.isPromocode = false;
        }
    }

    parseWithdrawalData() {
        if (this.withdrawal && this.applicationService.appReferences) {
            const startMonth = find(this.applicationService.appReferences.references.rifStartMonth, (month) => {
                return month.code == this.withdrawal.startMonth;
            });
            this.withdrawalFormData.startMonth = startMonth.label;
            const frequencyList = this.applicationService.isCurrentLangFrench() ? WITHDRAWAL_FREQUENCY_DROPDOWN_FR : WITHDRAWAL_FREQUENCY_DROPDOWN_EN;
            const frequency = find(frequencyList, (list) => {
                return list.code == this.withdrawal.frequency;
            });
            this.withdrawalFormData.frequency = frequency.label;
            const greaterThanMinimumAmountType = find(this.applicationService.appReferences.references.rifAmountType, (month) => {
                return month.code == this.withdrawal.greaterThanMinimumAmountType;
            });
            this.withdrawalFormData.greaterThanMinimumAmountType = greaterThanMinimumAmountType ? greaterThanMinimumAmountType.label : '';
            const timeOfMonth = find(this.applicationService.appReferences.references.rifMidEndMonth, (month) => {
                return month.code == this.withdrawal.whenInMonth;
            });
            this.withdrawalFormData.timeOfMonth = timeOfMonth.label;
        }

    }

    /* parsepersonalInformationDropDownData() {

        if (this.formService.forms.personalInformation.data.prefix && this.formService.forms.personalInformation.data.prefix !== 'null') {
            const prefix = find(this.applicationService.appReferences.references.titles, (titles) => {
                return titles.code === this.formService.forms.personalInformation.data.prefix;
            });
            this.formService.forms.personalInformation.data.prefixLabel = prefix.label;

        }
    } */
    parseRegulatoryDropDownData() {
        if (this.regulatoryDisclosures && this.applicationService.appReferences && this.regulatoryDisclosures.selectDomesticTitle) {
            const occupationPepOptions = find(this.applicationService.appReferences.references.occupationTypes, (occupation) => {
                return occupation.code === this.regulatoryDisclosures.selectDomesticTitle;
            });
            this.regulatoryDropDownData.domesticTitle = occupationPepOptions.label;
        }
    }


    parseFamilyDropDownData() {
        if (this.familyInformation && this.applicationService.appReferences) {
            if (!this.isSpousalRRSP) {
                this.familyInfoDropDownData.maritalStatusLabel =
                    find(this.applicationService.appReferences.references.maritalStatuses, (status) => {
                        return status.code === this.familyInformation.maritalStatus;
                    }).label;
            }
            if (this.isSpousalRRSP) {
                this.familyInfoDropDownData.spousalMaritalStatusLabel =
                    find(this.applicationService.appReferences.references.maritalStatuses, (status) => {
                        return status.code === this.familyInformation.spousalMaritalStatus;
                    }).label;
            }



            if (this.familyInformation.employmentStatus) {
                const jobLabelObj = find(this.applicationService.appReferences.references.employmentStatuses, (jobStatus) => {
                    return jobStatus.code === this.familyInformation.employmentStatus;
                });
                this.familyInfoDropDownData.jobStatusLabel = jobLabelObj.label;

                const empStatusObj: any = find(this.empInfoService.getEmploymentStatusOptions(), (empStatus: any) => {
                    return empStatus.code === this.familyInformation.employmentStatus;
                });
                if (empStatusObj.code !== EMPLOYMENTSTATUS.NOTPRESENTLYEMPLOYED && empStatusObj.code !== EMPLOYMENTSTATUS.RETIRED) {
                    if (this.familyInformation.selectOccupation && this.familyInformation.selectOccupation != null) {
                        const occLabelObj = find(this.applicationService.appReferences.references.occupationTypes, (occupation) => {
                            return occupation.code === this.familyInformation.selectOccupation;
                        });
                        this.familyInfoDropDownData.occupationLabel = occLabelObj.label;
                    }
                    if (this.familyInformation.selectNatureOfBusiness && this.familyInformation.selectNatureOfBusiness != null) {
                        // tslint:disable-next-line:max-line-length
                        const natureOfBusinessLabelObj = find(this.applicationService.appReferences.references.businessNatures, (business) => {
                            return business.code === this.familyInformation.selectNatureOfBusiness;
                        });

                        this.familyInfoDropDownData.natureOfBusinessLabel = natureOfBusinessLabelObj.label;
                    }
                    if (this.familyInformation && this.familyInformation.bmoGroupOption) {
                        const bmoGroupOptionObj = find(this.applicationService.appReferences.references.bmoCompanyCodes, (group) => {
                            return group.code === this.familyInformation.bmoGroupOption;
                        });
                        this.familyInfoDropDownData.bmoGroupOption = bmoGroupOptionObj.label;
                    }
                }
            }
        }

    }
    setIsSelectAccountClicked() {
        Utils.setIsSelectAccountClicked(true);
        Utils.backToSelectAccountPage = true;
    }

    parseEmploymentDropDownData() {

        if (this.employmentInformation && this.applicationService.appReferences) {
            const empStatusObj: any = find(this.empInfoService.getEmploymentStatusOptions(), (empStatus: any) => {
                return empStatus.code === this.employmentInformation.employmentStatus;
            });
            this.employmentInfoDropDownData.jobStatusLabel = empStatusObj.label;
            if (empStatusObj.code === EMPLOYMENTSTATUS.RETIRED && !this.isEmploymentDetailsRetired || (empStatusObj.code === EMPLOYMENTSTATUS.RETIRED && this.isEmploymentDetailsRetired && this.employmentInformation.selectPreviousOccupation && this.employmentInformation.selectNatureOfBusiness) ) {
                const prevOccLabelObj = find(this.applicationService.appReferences.references.occupationTypes, (occupation) => {
                    return occupation.code === this.employmentInformation.selectPreviousOccupation;
                });
                this.employmentInfoDropDownData.prevOccupationLabel = prevOccLabelObj.label;

                if (this.employmentInformation.selectNatureOfBusiness && this.employmentInformation.selectNatureOfBusiness != null) {
                    const natureOfBusinessLabelObj = find(this.applicationService.appReferences.references.businessNatures, (business) => {
                        return business.code === this.employmentInformation.selectNatureOfBusiness;
                    });
                    this.employmentInfoDropDownData.natureOfBusinessLabel = natureOfBusinessLabelObj.label;
                }
            }
            if (empStatusObj.code !== EMPLOYMENTSTATUS.NOTPRESENTLYEMPLOYED && empStatusObj.code !== EMPLOYMENTSTATUS.RETIRED) {
                const occLabelObj = find(this.applicationService.appReferences.references.occupationTypes, (occupation) => {
                    return occupation.code === this.employmentInformation.selectOccupation;
                });
                this.employmentInfoDropDownData.occupationLabel = occLabelObj.label;
                const natureOfBusinessLabelObj = find(this.applicationService.appReferences.references.businessNatures, (business) => {
                    return business.code === this.employmentInformation.selectNatureOfBusiness;
                });
                this.employmentInfoDropDownData.natureOfBusinessLabel = natureOfBusinessLabelObj.label;
                this.provinces = this.empInfoService.getProvinceOptions();
                if (this.employmentInformation.selectCountry === EMPLOYMENTSTATUS.COUNTRY.CANADA) {
                    const provinceLabelObj = find(this.provinces, (province) => {
                        return province.code === this.employmentInformation.province;
                    });
                    this.employmentInfoDropDownData.provinceLabel = provinceLabelObj.label;
                }
                if (this.employmentInformation.selectCountry === EMPLOYMENTSTATUS.COUNTRY.USA) {
                    this.states = this.empInfoService.getStateOptions();
                    const stateLabelObj = find(this.states, (state) => {
                        return state.code === this.employmentInformation.state;
                    });
                    if (stateLabelObj.label !== undefined) {
                        this.employmentInfoDropDownData.stateLabel = stateLabelObj.label;
                    }
                }
                const countryLabelObj = find(this.applicationService.appReferences.references.countries, (country) => {
                    return country.code === this.employmentInformation.selectCountry;
                });
                this.employmentInfoDropDownData.countryLabel = countryLabelObj?.label || '';

                if (this.employmentInformation && this.employmentInformation.bmoGroupOption) {
                    const bmoGroupOptionObj = find(this.applicationService.appReferences.references.bmoCompanyCodes, (group) => {
                        return group.code === this.employmentInformation.bmoGroupOption;
                    });
                    this.employmentInfoDropDownData.bmoGroupOption = bmoGroupOptionObj.label;
                }

            }
        }
    }
    get parseWealthSources(): string {
        if (this.formService.forms['financialInformation'].data && this.applicationService.appReferences) {
            let wealthSourceString = '';
            const wealthSources = this.applicationService.appReferences.references.wealthSources
                .map(a => new WealthSource(a.code, a.label));
            for (const entry of wealthSources) {
                if (this.formService.forms['financialInformation'].data.sourcesOfWealth.indexOf(entry.code) > -1) {
                    wealthSourceString += entry.label + ', ';
                }
            }
            return wealthSourceString.replace(/,\s*$/, '');
        }
        return '';
    }

    get parseInvestAssetPercentage(): string {
        if (this.formService.forms['financialInformation'].data && this.applicationService.appReferences) {
            let investAssetPer = '';
            const InvestAsset = this.applicationService.appReferences.references.investAssetPercentage;
            for (const entry of InvestAsset) {
                if (this.formService.forms['financialInformation'].data.saveInvestibleAsset && this.formService.forms['financialInformation'].data.saveInvestibleAsset.indexOf(entry.code) > -1) {
                    investAssetPer = entry.label;
                }
            }
            return investAssetPer;
        }
        return '';
    }

    getConsentLabel(formName, fieldName) { // To process the label with respect to the value from the form

        if (this.data(formName, fieldName) === 'ProxiesAnualReports') {
            return 'consents.labels.selected';
        } else if (this.data(formName, fieldName) === 'Proxies') {
            return 'consents.labels.selected';
        } else if (this.data(formName, fieldName) === 'electronic') {
            return 'consents.labels.taxDocsElectronic';
        } else if (this.data(formName, fieldName) === 'electronicMail') {
            return 'consents.labels.taxDocsElectronicMail';
        } else if (!this.data(formName, fieldName)) {
            return 'consents.labels.noConsent';
        } else if (this.data(formName, fieldName)) {
            return 'consents.labels.consent';
        } else {
            return '';
        }
    }



    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {
        // console.log('onFinishLater: ');


        this.preparePayload();

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {

                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })

                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
            });

    }

    saveOnSignOut() {
        this.preparePayload();

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {

                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);

                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
            });
    }

    onSubmit() {

        this.preparePayload();

        // call backend save
        this.applicationService.saveWithRetry(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {

                    this.formService.forms.review.isCompleted = true;

                    if (!this.formService.forms.celebrationReview.isVisited) {
                        this.formService.forms.celebrationReview.isVisited = true;
                        if (Utils.getIsBranchReferral() === false) {
                            this.applicationService.saveAndcontinue(null, APP_ROUTES.celebration_review);
                            return;
                        }
                    }
                    this.applicationService.saveAndcontinue(null, APP_ROUTES.terms_conditions);

                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onSubmit err: ', err);
                this.criticalError = true;
            });
    }


    preparePayload() {

        this.formService.getActiveForm().isCompleted = true;

        // getting all screen data from form service
        const accountDetail = this.formService.getFormData('accountDetail');
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
        const withdrawal = this.formService.getFormData('withdrawal');
        const consents = this.formService.getFormData('consents');
        if ((Utils.getIsBranchReferral() === true && Utils.isCash() === true) || (Utils.getIsBranchReferral() === false)) {
            const bmoRelationship = this.formService.getFormData('bmoRelationship');
            this.formService.getPayload().setBMORelationship(bmoRelationship);
        }
        const accountPassword = this.formService.getFormData('accountPassword');


        if (Utils.isSubProductAD()) {
            const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
            const investorProfile = this.formService.getFormData('investorProfile');
            const riskAndReturn = this.formService.getFormData('riskAndReturn');

            this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);
            this.formService.getPayload().setInvestorProfile(investorProfile);
            this.formService.getPayload().setRiskAndReturn(riskAndReturn);
        }

        /**
         * Updating the preferred language in the save call on submit of the application
         * primary applicant will be always the first entry in payload of parties
         * So updating the current language here updates primary applicant in fn setPersonalInformation
         */
        if (Utils.getLanguage() === "en") {
            personalInformation.currentLanguage = LANGUAGES.ENGLISH;
        } else {
            personalInformation.currentLanguage = LANGUAGES.FRENCH;
        }


        // putting all the data in payload before sending it to backend
        //this.formService.getPayload().setAccountDetails(accountDetail);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
        this.formService.getPayload().setConsents(consents);
        if (!Utils.getIsJourneyUser || (Utils.getIsJourneyUser && (this.isCashAccount || this.isMargin))) {
            this.formService.getPayload().setAccountPassword(accountPassword);
        }
        if (this.isRRIF)
            this.formService.getPayload().setWithdrawalInformation(withdrawal);

        if (Utils.isSpousalRRSP()) {
            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        this.formService.getPayload().pageStatus["finalReview"] = true;

    }

    setGTM() {

        const gtmDataLayerForReview: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleFinalReview(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.REVIEW_DETAILS,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.review,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForReview);
        // console.log('review', this.winRef.nativeWindow.dataLayer);

    }
    public isValueNull(value) {
        if (value === '' || value === undefined || value === null) {
            return true;
        }
        return false;
    }

    mapCanadaTaxResidencyValue(taxResidency) {
        const isEnglish = (Utils.getLanguage() && Utils.getLanguage() === LANGUAGE_ENGLISH) ? true : false;
        if (isEnglish) {
            return taxResidency === 'Yes' ? State.yes : State.no;
        }
        return taxResidency === 'Yes' ? State.yesFrench : State.noFrench;
    }

    isTcpAdrressAvailable() {
        let unit = this.data('contactInformation', 'trustedContactPerson').unit;
        let streetname = this.data('contactInformation', 'trustedContactPerson').streetNumberName;
        let city = this.data('contactInformation', 'trustedContactPerson').city;
        let province = this.data('contactInformation', 'trustedContactPerson').province;
        let postalCode = this.data('contactInformation', 'trustedContactPerson').postalCode;
        return unit || streetname || city || province || postalCode;
    }
    setUsCitizenShipLabel() {
        const isEnglish = (Utils.getLanguage() && Utils.getLanguage() === LANGUAGE_ENGLISH) ? true : false;
        //    const countryLabel = this.getLabelFromReferencesWithCode(this.formService.forms.personalInformation.data.selectedCountries[0]?.usa.code,this.applicationService.appReferences.references.countries)
        const countryLabel = this.getLabelFromReferencesWithCode(COUNTRY_CODE.USA, this.applicationService.appReferences.references.countries)
        if (isEnglish) {
            return [countryLabel, this.formService.forms.personalInformation.data.usResidentCheckBox ? State.yes : State.no, this.formService.forms.personalInformation.data.usSINNumber].join(' / ');

        }

        return [countryLabel, this.formService.forms.personalInformation.data.usResidentCheckBox ? State.yesFrench : State.noFrench, this.formService.forms.personalInformation.data.usSINNumber].join(' / ');


    }

    public getSQ1Response() {
        return this.setExpectation?.investmentServiceOptionCode === this.screenerQ1Options.A ? 'setExpectations.labels.yesInvestedOnline' : 'setExpectations.labels.yesComfortableOnline';
    }

    public getSQ2Response() {
        return this.setExpectation?.adviceRequiredOptionCode === this.screenerQ2Options.A ? 'setExpectations.labels.noAdviceRequiredSD' : 'setExpectations.labels.noAdviceRequiredAD';
    }
}

