<form #branchSaveProgressForm="ngForm" (ngSubmit)="onSaveApplication()">

    <div class="container-noMenuView">

        <section>

            <app-critical-error *ngIf="criticalError"></app-critical-error>

            <section>

                <h1 translate="branchSaveProgress.titlePage"></h1>
                <p [innerHTML]="'branchSaveProgress.descriptionPage' | translate | safeHtml"></p>

                <p>
                    <span class="font-bold"
                        [innerHTML]="'branchSaveProgress.applicationNumber' | translate | safeHtml"></span>
                    <span>{{appNumber}}</span>
                </p>

                <div class="form-wrapper">
                    <div class="form-row">

                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="createPwd" name="password" type="password" maxlength="8"
                                        [(ngModel)]="passwordObj.password" #accSetupPwdCtrl="ngModel"
                                        aria-describedby="passwordCheck1 passwordCheck2" InputValidator='ct4'
                                        appAccSetupPasswordValidator
                                        [appErrorAccess]="{id:'accSetupPwd-error',  submitted: isFormSubmit, hardCodeAriaDescribedById:'passwordCheck1 passwordCheck2' }"
                                        required />
                                    <label class="slide-label" for="createPwd"
                                        [ngClass]="{'error-field':isError(accSetupPwdCtrl), 'active-label':accSetupPwdCtrl.value}">
                                        <span class="label-text"
                                            translate="branchSaveProgress.labels.createPassword"></span>
                                    </label>
                                    <a role="button" *ngIf="(passwordObj?.password?.length > 0 || passwordObj?.confirmPassword?.length > 0);else disabledIcon"
                                        (click)="togglePasswordVisibility('createPwd' , 'confirmPassword')"
                                       class="btn-icon reveal-password"
                                       attr.aria-label="{{'accountPassword.ariaLabels.showPassword' | translate}}"
                                       attr.aria-pressed="{{passwordHide == true ? 'false' : 'true'}}"
                                       href="javascript://">
                                       <span [class]="passwordHide == true ? 'icon icon-large password-hide-blue' : 'icon icon-large password-show-blue'"></span>
                                    </a>
                                    <ng-template #disabledIcon>
                                    <a role="button"  aria-disabled="true"
                                       class="disabled btn-icon reveal-password"
                                       attr.aria-label="{{'accountPassword.ariaLabels.disabledShowPassword' | translate}}"
                                       href="javascript://">
                                       <span [class]="passwordHide == true ? 'icon icon-large password-hide-grey' : 'icon icon-large password-show-grey'"></span>
                                    </a>
                                </ng-template>
                                </div>
                            </div>
                            <div role="alert" id="accSetupPwd-error" class="error-message"
                                *ngIf="isError(accSetupPwdCtrl)">
                                <span translate="branchSaveProgress.errors.passwordFormat"></span>
                            </div>
                            <div class="password-checks">
                                <div class="bmo-container-flex">
                                    <div id="passwordCheck1" class="flex-40">
                                        <span class="icon-inline icon-error"
                                            [ngClass]="accSetupPwdCtrl?.errors?.lengthMustBeGreaterThanSeven ? 'icon-error' : 'icon-check'"
                                            translate="branchSaveProgress.labels.maxChar"></span>
                                        <span class="icon-inline icon-error"
                                            [ngClass]="accSetupPwdCtrl?.errors?.noDigitsPresent ? 'icon-error' : 'icon-check'"
                                            translate="branchSaveProgress.labels.minNumber"></span>
                                        <span class="icon-inline icon-error"
                                            [ngClass]="accSetupPwdCtrl?.errors?.noLowerCasePresent ? 'icon-error' : 'icon-check'"
                                            translate="branchSaveProgress.labels.minLowCase"></span>
                                    </div>
                                    <div id="passwordCheck2" class="flex-40">
                                        <span class="icon-inline icon-error"
                                            [ngClass]="accSetupPwdCtrl?.errors?.noUpperCasePresent ? 'icon-error' : 'icon-check'"
                                            translate="branchSaveProgress.labels.minUpCase"></span>
                                        <span class="icon-inline icon-error"
                                            [ngClass]="accSetupPwdCtrl?.errors?.noSpecialCharactersAllowed ? 'icon-error' : 'icon-check'"
                                            translate="branchSaveProgress.labels.noSpecialChar">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-row">

                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="confirmPassword" name="confirmPassword" type="password"
                                        aria-describedby="confirmPassword" maxlength="8"
                                        [(ngModel)]="passwordObj.confirmPassword" #accSetupConfirmPwdCtrl="ngModel"
                                        [appEqualTo]="accSetupPwdCtrl" InputValidator='ct4'
                                        [appErrorAccess]="{id:'accSetupConfirmPwd-error',  submitted: isFormSubmit, hardCodeAriaDescribedById :'confirmPassword'}"
                                        required />
                                    <label class="slide-label" for="confirmPassword" [ngClass]="{'error-field':isError(accSetupConfirmPwdCtrl),
                                    'active-label':accSetupConfirmPwdCtrl.value}">
                                        <span class="label-text"
                                            translate="branchSaveProgress.labels.confirmPassword"></span>
                                    </label>
                                </div> 
                            </div>
                            <div role="alert" id="accSetupConfirmPwd-error" class="error-message"
                                *ngIf="isError(accSetupConfirmPwdCtrl)">
                                <span translate="branchSaveProgress.errors.passwordMatch"></span>
                            </div>
                        </div>

                    </div>
                </div>


            </section>

        </section>

        <section>

            <div class="form-wrapper">
                <div class="form-row form-page-buttons">
                    <div class="form-col left-side">
                        <!-- Leave blank if button not needed -->
                    </div>
                    <div class="form-col right-side">
                        <button [disabled]="(branchSaveProgressForm.invalid)" class="primary" type="submit"
                            translate="button.saveApplication"></button>
                    </div>
                </div>
            </div>

        </section>

    </div>

</form>