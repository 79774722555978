import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Utils } from "../../shared/utils/utils.class";
import { APP_ROUTES, BILJ, productTypeAD, productTypeSD } from "../../config/app-config.constants";
import { Router } from "@angular/router";
import { ApplicationService } from '../../../app/shared/service/application.service';
@Component({
  selector: 'app-journey-login',
  templateUrl: './journey-login.component.html'
})
export class JourneyLoginComponent implements OnInit {
  @ViewChild('journeyForm', { static: true }) journeyForm: NgForm;
  public JourneyECIFId: any;
  public loginId: any;
  public isFormSubmit: any;
  public redirection = false;
  constructor(private router: Router, private applicationService: ApplicationService) { }

  ngOnInit(): void {
    if (this.applicationService.getQueryParams("response_type")) {
      this.redirection = true;
      this.router.navigate(['/' + APP_ROUTES.set_expectation], {
        queryParams: {
          lang: this.applicationService.getQueryParams("lang"),
          dest: BILJ,
          subProduct: productTypeSD,
          code: this.applicationService.getQueryParams("code_challenge"),
          state: this.applicationService.getQueryParams("state"),
          ecifID: Utils.getJourneyUserEcifId(),
          logInId: Utils.getJourneyUserLoginId()
        }
      });
      this.applicationService.hidePageUndloadPopUp$.next(false);
      setTimeout(() => {
        window.location.reload();
      }, 0);
    } else {
      this.redirection = false
    }
  }

  onSubmit() {

    this.isFormSubmit = true;
    if (this.journeyForm.valid) {
      Utils.setIsJourneyUser(true);
      // Utils.setMockOLBUser(true);
      Utils.setJourneyUserEcifId(this.JourneyECIFId);
      Utils.setJourneyUserLoginId(this.loginId);
      const queryParams = {
        lang: Utils.getLanguage(),
        dest: BILJ,
        subProduct: productTypeSD,
        ecifID: this.JourneyECIFId,
        logInId: this.loginId
      };
      this.router.navigate(['/' + APP_ROUTES.set_expectation], {
        queryParams
      });
      this.applicationService.hidePageUndloadPopUp$.next(false);
      setTimeout(() => {
        window.location.reload();
      }, 0);

    }
  }

  onCancel() {

  }

}
