import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Jquery } from '../../utils/jquery.class';
import { ApplicationService } from '../../service/application.service';

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html'
})
export class CookieModalComponent implements OnInit, AfterViewInit {

  private isCookieEnabled: boolean;
  @Input() showModal: boolean;

  constructor(
    private applicationService: ApplicationService,
  ) { }

  ngOnInit(): void {
    this.isCookieEnabled = navigator.cookieEnabled;

  }

  ngAfterViewInit() {
    if (this.showModal) {      
      $('.modal-overlay-component').show(); 
      $('.bmo-cookie-enabled').show();
      Jquery.modalFocusTrap('bmo-cookie-enabled');
   }
  }
  hideCookieEnabled() {
    this.applicationService.cookieStorage.setItem('cookie','passed');
    console.log(this.applicationService.cookieStorage.getItem('cookie'));
    
    $('.modal-overlay-component').hide();
    $('.bmo-cookie-enabled').hide();
    Jquery.modalDissableFocusTrap();
  }

}
