<input [id]="id"
       [name]="name"
       #sinNumberCtrl="ngModel"
       [(ngModel)]="value"
       appSIN
       type="tel"
       minlength="9"
       maxlength="9"
       (blur)="onTouched($event)"
       InputValidator="ct3" />

<label [ngClass]="{'error-field': isInvalid || isSinConflict, 'active-label': sinNumberCtrl.value}" class="slide-label" for="sinNumber">
    <span class="label-text" translate="personalInformation.labels.sinNumber"></span>
</label>
