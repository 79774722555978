<!-- Circular graph -->
<!-- The entire SVG can be moved inside a component if need it -->
<!-- --- -->
<div [ngModelGroup]="graph">

  <input tabindex="-1" id="total" name="total" type="hidden" value="" graphValueValidator [(ngModel)]="totalValue" #totalValueCtrl="ngModel">

  <span class="off-screen">{{ labelContent | translate }} {{ 'investmentObjectives.labels.total' | translate }} {{totalValue}}%</span>

  <svg aria-hidden='true' viewBox="0 0 36 36" class="graph-circular blue">

    <title></title>

    <path class="circle-bg" d="M18 2.0845  a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
    <path class="circle" [attr.stroke-dasharray]="totalValue + ' ' + (100 - totalValue)"
      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />

    <text x="18" y="19" class="percentage">{{totalValue}}%</text>
    <text x="18" y="23" class="total" translate="investmentObjectives.labels.total"></text>

  </svg>

</div>