/**
 * @purpose: Regulatory Disclosures
 * @ created
 * @ last updated by: Hari (9 May, 2018)
 */
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { filter, isNil, find } from 'lodash';
import { EMPLOYMENTSTATUS } from '../../config/app-config.constants';

@Injectable()
export class EmploymentInfoService {

    bmoGroupOptions: Array<object>;
    employmentStatusOptions: Array<object>;
    occuupationOptions: Array<object>;
    natureOfBusinessOptions: Array<object>;
    countryOptions: Array<object>;
    provinceOptions: Array<object>;
    stateOptions: Array<object>;
    references: any;
    bmoProductsOptionsEn: Array<object>;
    bmoProductsOptionsFr: Array<object>;
    memberFirmList: Array<object>;
    occupationPepOptions: Array<object>;

    constructor(private route: ActivatedRoute, public applicationService: ApplicationService) {
        this.applicationService.fetchReferences(this.route);
        this.references = this.applicationService.appReferences.references;
        this.bmoGroupOptions = this.references.bmoCompanyCodes;
        this.employmentStatusOptions = filter(this.references.employmentStatuses, (status) => {
            return !status.expired;
        });
        this.occuupationOptions = this.references.occupationTypes;

        // BUG-6587
        // filtered the array for removing 'unemployed' option from nature of business dropdown
        this.natureOfBusinessOptions = filter(this.references.businessNatures, (natureOfBusiness) => {
            return !natureOfBusiness.expired;
        });
        this.countryOptions = this.references.countries;
        const filteredCanadaProvince = find(this.references.countries, (country) => {
            return country.code === EMPLOYMENTSTATUS.COUNTRY.CANADA;
        });
        this.provinceOptions = filteredCanadaProvince.stateProvinces;
        const filteredUsaStates = find(this.references.countries, (country) => {
            return country.code === EMPLOYMENTSTATUS.COUNTRY.USA;
        });
        this.stateOptions = filteredUsaStates.stateProvinces;
        this.bmoGroupOptions = this.references.bmoCompanyCodes;
        this.memberFirmList = this.references.iirocOrganizations;
        this.occupationPepOptions = filter(this.references.occupationTypes, (occupation) => {
            return occupation.politicallyExposedOccupation === true;
        });
    }

    /**
     * @description: Fetch BMO Group Options
     * @param: none
     * @return: array
     */
    getBMOGroupOptions(): Array<object> {
        // For now return static value. RDM integration later.
        return this.bmoGroupOptions;
    }

    /**
     * @description: Fetch employment status Options
     * @param: none
     * @return: array
     */
    getEmploymentStatusOptions(): Array<object> {
        return this.employmentStatusOptions;
    }

    /**
     * @description: Fetch occupation Options
     * @param: none
     * @return: array
     */
    getOccupationOptions(): Array<object> {
        if (this.occuupationOptions) {
            // to remove spacing issue on GOUVERNEMENT – AUTRES:
            this.occuupationOptions.map(x => x['label'] = x['label'].trim());
        }
        return this.occuupationOptions;
    }

    /**
     * @description: Fetch nature of business Options
     * @param: none
     * @return: array
     */
    getNatureOfBusinessOptions(): Array<object> {
        return this.natureOfBusinessOptions;
    }

    /**
     * @description: Fetch country Options
     * @param: none
     * @return: array
     */
    getCountryOptions(): Array<object> {
        return this.countryOptions;
    }

    /**
     * @description: Fetch Province Options
     * @param: none
     * @return: array
     */
    getProvinceOptions(): Array<object> {
        return this.provinceOptions;
    }

    /**
     * @description: Fetch State Options
     * @param: none
     * @return: array
     */
    getStateOptions(): Array<object> {
        return this.stateOptions;
    }

    /**
     * @description: check the occupation is political exposed
     * @param: occupation code
     * @return: boolean
     */
    isOccupationPep(occupationCode): boolean {
        this.applicationService.fetchReferences(this.route);
        this.references = this.applicationService.appReferences.references;
        const pepPerson = find(this.references.occupationTypes, (occupation) => {
            return occupation.code === occupationCode;
        });
        if (pepPerson && pepPerson.politicallyExposedOccupation) {
            return true;
        }
        return false;
    }

    /**
     * @description: Fetch occupations of political exposed person
     * @param: none
     * @return: array
     */
    getOccupationPep(): Array<object> {
        return this.occupationPepOptions;
    }

    /**
     * @description: Fetch member firm Options
     * @param: none
     * @return: array
     */
    getMemberFirmList(): Array<object> {
        return this.memberFirmList;
    }

}
