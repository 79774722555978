import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS ,HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as $ from 'jquery';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserIdleModule } from 'angular-user-idle';

// Modules
import { RoutingModule } from './app-routing.module';
import { DatePickerModule } from './shared/components/date-picker/date-picker.module';

// Sections
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { PersonalInformationComponent } from './sections/personal-information/personal-information.component';
import { ContactInformationComponent } from './sections/contact-information/contact-information.component';
import { EmploymentInformationComponent } from './sections/employment-information/employment-information.component';
import { ReviewComponent } from './sections/review/review.component';
import { FooterComponent } from './sections/footer/footer.component';
import { HeaderComponent } from './sections/header/header.component';
import { SignOutComponent } from './shared/components/sign-out/sign-out.component';
import { NavigationComponent } from './sections/navigation/navigation.component';
import { CelebrationComponent } from './sections/celebration/celebration.component';
import { FinancialDetailsComponent } from './sections/financial-details/financial-details.component';
import { RegulatoryDisclosuresComponent } from './sections/regulatory-disclosures/regulatory-disclosures.component';
import { ConsentsComponent } from './sections/consents/consents.component';
import { BmoRelationshipComponent } from './sections/bmo-relationship/bmo-relationship.component';
import { AccountPasswordComponent } from './sections/account-password/account-password.component';
import { CelebrationReviewComponent } from './sections/celebration-review/celebration-review.component';
import { TermsConditionsComponent } from './sections/terms-conditions/terms-conditions.component';
import { NextBybranchPdfComponent } from './sections/next-bybranch-pdf/next-bybranch-pdf.component';
import { NextBymailPdfComponent } from './sections/next-bymail-pdf/next-bymail-pdf.component';
import { DocumentUploadComponent } from './sections/document-upload/document-upload.component';
import { AccountDetailComponent } from './sections/account-detail/account-detail.component';
import { ApplicationApprovalDeclinedComponent } from './sections/application-approval-declined/application-approval-declined.component';
import { ApplicationApprovalDowngradeComponent } from './sections/application-approval-downgrade/application-approval-downgrade.component';
import { InvestmentKnowledgeComponent } from './sections/investment-knowledge/investment-knowledge.component';
import { InvestmentObjectivesComponent } from './sections/investment-objectives/investment-objectives.component';
import { InvestorProfileComponent } from './sections/investor-profile/investor-profile.component';
import { BranchReferralComponent } from './sections/branch-referral/branch-referral.component';
import { BranchEsignatureComponent } from './sections/branch-esignature/branch-esignature.component';
import { BranchSaveProgressComponent } from './sections/branch-save-progress/branch-save-progress.component';
import { TechnicalErrorComponent } from './sections/technical-error/technical-error.component';
import { BeneficiaryComponent } from './sections/beneficiary/beneficiary.component';
import { BeneficiaryInformationComponent } from './sections/beneficiary/beneficiary-information/beneficiary-information.component';

// Components
import { AppComponent } from './app.component';

// Services
import { ConfigResolverService } from './config/external-config/service/config-resolver.service';
import { ApplicationService } from './shared/service/application.service';
import { WindowRefService } from './shared/service/window-ref.service';
import { ConfigResolver } from './shared/resolver/config.resolver';
import { ServiceLocator } from './shared/service/service-locator.service';

// Directives
import { SharedModule } from './shared/shared.module';
import { PostalCodeDirective } from './shared/directive/postal-code.directive';
import { EmailFormatDirective } from './shared/directive/email-format.directive';
// Pipes
import { CapitalizePipe } from './shared/pipes/capitalize.pipe';
import { FormService } from './shared/service/form.service';
import { FormatPostalCodePipe } from './shared/pipes/format-postal-code.pipe';
import { FormatPhoneNumberPipe } from './shared/pipes/format-phone-number.pipe';
import { FamilyInformationComponent } from './sections/family-information/family-information.component';
import { SelectAccountComponent } from './sections/select-account/select-account.component';
import { SetExpectationComponent } from './sections/set-expectation/set-expectation.component';
import { AlphabeticalSortPipe } from './shared/pipes/alphabeticalSort.pipe';
import { EmploymentInfoService } from './shared/service/employment-info.service';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { ReferenceResolver } from './shared/resolver/reference.resolver';
import { environment } from '../environments/environment';
import { DocumentTrackerService } from './shared/service/document-tracker.service';
import { CustomHttpInterceptor } from './shared/interceptor/http-interceptor.interceptor';
import { AlphabeticalDescendingSortPipe } from './shared/pipes/alphabetical-descending-sort.pipe';
import { SessionResolver } from './shared/resolver/session.resolver';
import { NextStepsComponent } from './sections/next-steps/next-steps.component';
import { RetrieveApplicationResolver } from './shared/resolver/retrieve-application.resolver';
import { AnalyticsService } from './shared/service/analytics.service';
import { LoaderService } from './shared/service/loader.service';
import { PartyService } from './shared/service/party.service';
import { PrefillService } from './shared/service/prefill.service';
import { CheckForPartialSaveService } from './shared/service/check-for-partial-save.service';
import { AccountTypeService } from './shared/service/account-type.service';
import { AccountTypesResolver } from './shared/resolver/account-types.resolver';
import { FloodLightComponent } from './shared/components/flood-light/flood-light.component';
import { InvalidFormComponent } from './sections/invalid-form/invalid-form.component';
import { Utils } from './shared/utils/utils.class';
import { ZipCodePipe } from './shared/pipes/zip-code.pipe';
import { WithNavComponent } from './layouts/with-nav/with-nav.component';
import { NoNavComponent } from './layouts/no-nav/no-nav.component';
import { NoFooterComponent } from './layouts/no-footer/no-footer.component';
import { ProgressBarService } from './shared/service/progress-bar.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CanActivateGuard } from './shared/guards/can-activate.guard';
import { BranchAssistedComponent } from './sections/branch-assisted/branch-assisted.component';
import { AccountsResolver } from './shared/resolver/accounts.resolver';
import { MasterResolver } from './shared/resolver/master.resolver';
import { CanBranchActivateGuard } from './shared/guards/can-branch-activate.guard';
import { RiskAndReturnComponent } from './sections/risk-and-return/risk-and-return.component';
import { WithdrawalComponent } from './sections/withdrawal/withdrawal.component';
import { TechnicalErrorBranchurlComponent } from './sections/technical-error-branchurl/technical-error-branchurl.component';
import { TechnicalErrorSessionComponent } from './sections/technical-error-session/technical-error-session.component';
import { CMSMessageResolver } from './shared/resolver/cmsmessage.resolver';
import { CdbOlbComponent } from './sections/cdb-olb/cdb-olb.component';
import { CdbOlbService } from './shared/service/cdb-olb.service';
import { LocalStorageModule } from 'angular-2-local-storage';
import { ContextualSearchComponent } from './shared/components/contextual-search/contextual-search.component';
import { JourneyLoginComponent } from './sections/journey-login/journey-login.component';
//import { AccountAccessComponent } from './sections/account-access/account-access.component';

export function init(configService: ConfigResolverService<any>) {
    if (environment.name === 'prod-assisted') {
        return () =>configService.setConfig('./assets/config/prod.BRANCH-ASSITED.env.json');

    } else if (environment.name === 'prod-selfserve') {
        return () =>configService.setConfig('./assets/config/prod.SELF_SERVE.env.json');

    } else if (environment.name === 'sit-ba') {
        return () => configService.setConfig('./assets/config/sit.BA.env.json');

    } else if (environment.name === 'sit-ss') {
        return () => configService.setConfig('./assets/config/sit.SS.env.json');

    } else if (environment.name === 'sit') {
        return () => configService.setConfig('./assets/config/sit.env.json');

    } else {
        return () => configService.setConfig('./assets/config/dev.env.json');
    }

  }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({

    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        SignOutComponent,
        DashboardComponent,
        NavigationComponent,
        PersonalInformationComponent,
        ContactInformationComponent,
        EmploymentInformationComponent,
        ReviewComponent,
        CapitalizePipe,
        PostalCodeDirective,
        FormatPostalCodePipe,
        FormatPhoneNumberPipe,
        FamilyInformationComponent,
        SelectAccountComponent,
        SetExpectationComponent,
        AlphabeticalSortPipe,
        EmailFormatDirective,
        CelebrationComponent,
        FinancialDetailsComponent,
        RegulatoryDisclosuresComponent,
        ConsentsComponent,
        BmoRelationshipComponent,
        AccountPasswordComponent,
        CelebrationReviewComponent,
        TermsConditionsComponent,
        NextBybranchPdfComponent,
        NextBymailPdfComponent,
        AlphabeticalDescendingSortPipe,
        NextStepsComponent,
        DocumentUploadComponent,
        InvalidFormComponent,
        AccountDetailComponent,
        ApplicationApprovalDeclinedComponent,
        ApplicationApprovalDowngradeComponent,
        ZipCodePipe,
        WithNavComponent,
        NoNavComponent,
        NoFooterComponent,
        InvestmentKnowledgeComponent,
        InvestmentObjectivesComponent,
        InvestorProfileComponent,
        BranchAssistedComponent,
        BranchReferralComponent,
        BranchEsignatureComponent,
        BranchSaveProgressComponent,
        TechnicalErrorComponent,
        BeneficiaryComponent,
        BeneficiaryInformationComponent,
        RiskAndReturnComponent,
        WithdrawalComponent,
        TechnicalErrorBranchurlComponent,
        TechnicalErrorSessionComponent,
        CdbOlbComponent,
       // AccountAccessComponent
        ContextualSearchComponent,
       JourneyLoginComponent
    ],
    imports: [
        BrowserModule,
        RoutingModule,
        HttpClientJsonpModule,
        HttpClientModule,
        LocalStorageModule.forRoot({
            prefix: 'isam',
            storageType: 'sessionStorage'
        }) ,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),

        // for testing purpose, time configurations are changed..
        // UserIdleModule.forRoot({idle: 180 , timeout: 60, ping: 30}),

        // impending screen will be shown after 29 mins(1730 secs has 10 sec buffer) and timeout screen a minute later (60 secs)
        // PROD - Configuration
        UserIdleModule.forRoot({ idle: 1730, timeout: 60, ping: 300 }),
        // UserIdleModule.forRoot({idle: 300 , timeout: 60, ping: 180}),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        DatePickerModule
            ],
    providers: [
        ConfigResolverService,
        { provide: APP_INITIALIZER, useFactory: init, deps: [ConfigResolverService, HttpClient], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
        ReferenceResolver,
        MasterResolver,
        SessionResolver,
        AccountsResolver,
        AccountTypesResolver,
        RetrieveApplicationResolver,
        AnalyticsService,
        ApplicationService,
        HttpClient,
        WindowRefService,
        FormService,
        FormatPostalCodePipe,
        FormatPhoneNumberPipe,
        EmploymentInfoService,
        CanDeactivateGuard,
        CanActivateGuard,
        CanBranchActivateGuard,
        DocumentTrackerService,
        LoaderService,
        PartyService,
        PrefillService,
        CheckForPartialSaveService,
        AccountTypeService,
        ProgressBarService,
        CMSMessageResolver,
        CdbOlbService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        ServiceLocator.injector = this.injector;
    }
}
