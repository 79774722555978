import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { NgForm } from '@angular/forms';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { FormService } from '../../shared/service/form.service';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import {COUNTRY_CODE} from '../../config/app-config.constants';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigResolverService } from "../../config/external-config/service/config-resolver.service";
import { Utils } from '../utils/utils.class';
import { AnalyticsService } from '../service/analytics.service';

@Directive({
  selector: '[addressAutoComplete]'
})

export class AddressAutoCompleteDirective {

  public activeForm: NgForm;
  public isCurrentLangEnglish: boolean;
  public isCurrentLangFrench: boolean;
  public provinces: any;
  public countryCode: any;
  public references: any;
  public provinceData : any;
  


  constructor(
            private applicationService: ApplicationService,
            private elem: ElementRef,
            private winRef: WindowRefService,
            private formService: FormService,
            private route: ActivatedRoute,
            private http: HttpClient,
            private analyticsService : AnalyticsService, 
            private configService: ConfigResolverService<any>) {

      this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
      this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
  }

    ngOnInit() {
        this.applicationService.fetchReferences(this.route);
        this.references = this.applicationService.appReferences.references;
       
        if (this.applicationService.appReferences !== undefined ) {
            this.provinces = this.applicationService.appReferences.references.countries[0].stateProvinces;
        }
         if (!Utils.apiKeyValue) {
            Utils.apiKeyValue = this.applicationService.getCanadaPostKey();
            // const config = this.configService.getConfig();
            // const cannadaAPICall = config.CHANNELS_API.CONTEXT_ROOT + config.CANNADA_POSTKEY.CANADAPOSTKEY_URI;
            // let header = config.CANNADA_POSTKEY.HEADER;
            // header = new HttpHeaders(header);
            // header['workflow_id'] = localStorage.getItem('appId');
            // this.http.get(cannadaAPICall, { headers: header })
            //     .toPromise()
            //     .then(response => {
            //         Utils.apiKeyValue = response["apiKeyValue"];
            //     })
            //     .catch(e => {
            //         this.analyticsService.setapiCallDetails({
            //             apiCallMade: 'true', apiCallType: "cannadaAPICall" , 
            //             apiCallResponseCode: e.error?.errorInfo ? e.status?.toString() + "-" + e.error?.errorInfo.errorCode.toString() : e.status?.toString()

            //         });
            //         Utils.apiKeyValue = null;
            //     });
        }
    }

    @Input() set SelectedCountry(countryCode: any) {
        this.countryCode = countryCode ;
    }

    @HostListener('keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        // could need to capture key events?
        //$('.pcaitem').attr("tabindex","1");
        //$(document).on('mouseover', '.pcaselected', ()=> {$(".pcaselected").attr("aria-live","assertive")});
       // $(document).on('mouseout', '.pcaselected', ()=> {$(".pcaselected").removeAttr("aria-live")});
    }

    @HostListener('focus', ['$event'])
    focus(event) {
        this.activeForm = this.formService.getActiveForm(true);
        this.linkCanadaPostApi();
          
    }

    public isValueNull(value) {
        if (value === '' || value === undefined || value === null) {
            return true;
        }
        return false;
    }

    linkCanadaPostApi() {
        let canPostControl;
        const {pca} = this.winRef.nativeWindow;
        const fields = [{
            element: this.elem.nativeElement.id,
            field: 'Line1',
            mode: pca.fieldMode.SEARCH
        }];
        const MaxSuggestions = 50;
        const MaxResults = 300;
        const minItems = 1;
        const maxItems = 10;
        const options = {
            key: Utils.apiKeyValue,
            languagePreference: '',
            maxItems: maxItems,
            minItems: minItems,
            search: {
                maxSuggestions: MaxSuggestions,
                maxResults: MaxResults
            },
            bar: {
                showCountry: false,
                showLogo: false,
                logoLink: false
            }
        };

        if (this.isCurrentLangEnglish) {
            options.languagePreference = 'en-US';
        } else if (this.isCurrentLangFrench) {
            options.languagePreference = 'fr-CA';
        }

        canPostControl = new pca.Address(fields, options);
        canPostControl.setCountry(this.getCountryIsoCode(this.countryCode));
        

        canPostControl.listen('populate', (address) => {
            // match province returned with provinces from application service to get appropriate code
            this.provinces = this.loadProvince(this.countryCode);
            let provinceData;

            if(!this.isValueNull(this.provinces)) {
                 provinceData = this.provinces.stateProvinces.find(function (obj) {
                    return obj.isoCode === address.ProvinceCode;
                });
            }
           
            
            if (this.elem.nativeElement.id === 'streetNumberName') {
                this.activeForm.form.controls['streetNumberName'].setValue(address.Line1);
                this.activeForm.form.controls['city'].setValue(address.City);
                this.activeForm.form.controls['province'].setValue(provinceData.code);
                this.activeForm.form.controls['postalCode'].setValue(address.PostalCode);
            } else if (this.elem.nativeElement.id === 'diffStreetNumberName') {
                this.activeForm.form.controls['diffStreetNumberName'].setValue(address.Line1);
                this.activeForm.form.controls['diffCity'].setValue(address.City);
                this.activeForm.form.controls['diffProvince'].setValue(provinceData.code);
                this.activeForm.form.controls['diffPostalCode'].setValue(address.PostalCode);
            } else if (this.elem.nativeElement.id === 'prevStreetNumberName') {
                // this.activeForm.form.controls['prevStreetNumberName'].setValue(address.Line1);
                // this.activeForm.form.controls['prevCity'].setValue(address.City);
                // this.activeForm.form.controls['prevProvince'].setValue(provinceData.code);
                // this.activeForm.form.controls['prevPostalCode'].setValue(address.PostalCode);
            } else if(this.elem.nativeElement.id === 'tcpStreetNumberName') {
                const tcpCountryCtrl = this.activeForm.form.controls['tcpCountry'];
                this.activeForm.form.controls['tcpStreetNumberName'].setValue(address.Line1);
                this.activeForm.form.controls['tcpCity'].setValue(address.City);
                if(tcpCountryCtrl.value === COUNTRY_CODE.CANADA){
                    this.activeForm.form.controls['tcpProvince'].setValue(provinceData.code);
                    this.activeForm.form.controls['tcpPostalCode'].setValue(address.PostalCode);
                }
                else if(tcpCountryCtrl.value === COUNTRY_CODE.USA){
                    // uncomment after implementing canada autocomplete
                    this.activeForm.form.controls['tcpProvince'].setValue(provinceData.code);
                    this.activeForm.form.controls['tcpZipCode'].setValue(address.PostalCode);
                    
                }
            }
            this.updateLabels();
        });

    }

    updateLabels() {
        $('input, select').each(function (index) {
            if (($(this).val() !== '') && ($(this).val() !== null)) {
                $(this).next('label').addClass('active-label');
            } else {
                $(this).next('label').removeClass('active-label');
            }
        });
    }

    loadProvince(code: any=COUNTRY_CODE.CANADA) {
        return this.getprovince(code);
    }
    getprovince(code) {
     
        this.references = this.applicationService.appReferences.references;
       return this.references.countries.find(function (match) {
            if (code === match.code) {
                this.provinces =[];
               return   this.provinces = match.stateProvinces;
            }

        }.bind(this));


    }
    getCountryIsoCode(code: string = COUNTRY_CODE.CANADA) {
        this.references = this.applicationService.appReferences.references
        let country = this.references.countries.find(country => {
            return country.code === code;
        })
        return country.isoCode;
    }

}
