<div class="form-field" [ngModelGroup]="group">
    <div class="form-label-group">
        <input [id]="id"
                data-ana="mywealth:onboarding:financial:net worth"
                name="netWorth"
                type="tel"
                [(ngModel)]="model[key]"
                #netWorthCtrl="ngModel"
                maxlength="9"
                appCurrency
                InputValidator="ct3"
                required />
                <label class="slide-label" for="netWorth" [ngClass]="{'error-field':isError(netWorthCtrl), 'active-label':netWorthCtrl.value}">
                    <span class="label-text" translate="financialDetails.labels.netWorth"></span>
                </label>
    </div>
</div>
<div role="alert" class="error-message" *ngIf="isError(netWorthCtrl)">
    <span translate="financialDetails.errors.mustEnterValue"></span>
</div>
