<!-- <app-flood-light></app-flood-light> -->

<form #employmentDetailsForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

        <section>

            <app-critical-error *ngIf="criticalError"></app-critical-error>
            <app-invalid-form #appInvalidForm [isFormInvalid]="employmentDetailsForm.invalid && isFormSubmit" translateTag="employmentDetails"></app-invalid-form>

            <section>

                <h1 translate="employmentDetails.titleEmploymentDetails"></h1>
                <p translate="employmentDetails.descriptionEmploymentDetails"></p>

                <div class="form-wrapper">
                    <div class="form-row">

                        <div class="flex-80 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <select id="selectEmploymentStatus"
                                            data-ana="mywealth:onboarding:employment:job status"
                                            name="selectEmploymentStatus"
                                            [(ngModel)]="employmentInformation.employmentStatus"
                                            #selectEmploymentStatusCtrl="ngModel"
                                            (change)="setEmploymentStatus()"
                                            [appErrorAccess]="{id:'selectEmploymentStatus-error',  submitted: isFormSubmit}"
                                            required>

                                        <option *ngFor="let status of employmentStatus" [value]="status.code" [selected]="status.selected">
                                            {{status.label}}
                                        </option>
                                    </select>
                                    <label class="slide-label" for="selectEmploymentStatus" [ngClass]="{'error-field': selectEmploymentStatusCtrl.errors && selectEmploymentStatusCtrl.touched || (isFormSubmit && selectEmploymentStatusCtrl.errors), 'active-label': selectEmploymentStatusCtrl.value}">
                                        <span class="label-text" translate="employmentDetails.labels.jobStatus"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" class="error-message" id="selectEmploymentStatus-error" *ngIf="selectEmploymentStatusCtrl.errors && selectEmploymentStatusCtrl.touched || (isFormSubmit && selectEmploymentStatusCtrl.errors)">
                                <span translate="employmentDetails.errors.jobStatusError"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="employmentInformation.employmentStatus">

                    <section>
                        <h2 translate="employmentDetails.subtitleJobDescription" *ngIf="employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && ((employmentInformation.employmentStatus !== RETIRED || !isEmploymentDetailsRetired) || employmentInformation.employmentStatus == RETIRED && isShowPreOccAndNatureOfBusiness && isEmploymentDetailsRetired)"></h2>
                        <p translate="employmentDetails.subDescriptionJobDescription" *ngIf="employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED"></p>
                        <div class="form-wrapper" *ngIf="employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && ((employmentInformation.employmentStatus !== RETIRED || !isEmploymentDetailsRetired) || employmentInformation.employmentStatus == RETIRED && isShowPreOccAndNatureOfBusiness && isEmploymentDetailsRetired)">
                            <div class="form-row" *ngIf="employmentInformation.employmentStatus !== RETIRED">
                                <div *ngIf="!isDataRationalizationEnabled" class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <select id="selectOccupation"
                                                    name="selectOccupation"
                                                    data-ana="mywealth:onboarding:employment:occupation"
                                                    [(ngModel)]="employmentInformation.selectOccupation"
                                                    #selectOccupationCtrl="ngModel"
                                                    [appErrorAccess]="{id:'selectOccupation-error',  submitted: isFormSubmit}"
                                                    required>

                                                <option *ngFor="let occupation of currentOccupation | alphabeticalSort:'label'" [value]="occupation.code" [selected]="occupation.selected">
                                                    {{occupation.label}}
                                                </option>
                                            </select>
                                            <label [ngClass]="{'error-field': selectOccupationCtrl.errors && selectOccupationCtrl.touched || (isFormSubmit && selectOccupationCtrl.errors), 'active-label': selectOccupationCtrl.value}"
                                                   class="slide-label" for="selectOccupation">
                                                <span class="label-text" translate="employmentDetails.labels.selectOccupation"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id="selectOccupation-error" *ngIf="selectOccupationCtrl.errors && selectOccupationCtrl.touched || (isFormSubmit && selectOccupationCtrl.errors)">
                                        <span translate="employmentDetails.errors.selectOccupation"></span>
                                    </div>
                                </div>
                                <div *ngIf="isDataRationalizationEnabled" class="flex-50 form-col">
                                      <app-contextual-search
                                                    nameAttr="selectOccupation"
                                                    label="employmentDetails.labels.selectOccupation"
                                                    [CurrentValue]=""
                                                    (changeEventEmitter)="setOccupation($event)"
                                                    [section]="section + 'Occupation'+i"
                                                    [form]="employmentDetailsForm"
                                                    [listItems]="currentOccupation"
                                                    [placeholder]="Occupation" [isDisabled]="false"
                                                    [listType]="'state'" [isRequired]="true"
                                                    [isFormSubmit]="isFormSubmit"
                                                    [selectedCode] = "employmentInformation.selectOccupation"
                                                    [errorID] = "'selectOccupation-error'"
                                                    [errorMsg] = "'employmentDetails.errors.selectOccupation'"
                                                    >
                                        </app-contextual-search>
                                </div>
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="jobTitle"
                                                   type="text"
                                                   name="jobTitle"
                                                   maxlength="50"
                                                   InputValidator="ct15"
                                                   [blockPaste]="true"
                                                   [(ngModel)]="employmentInformation.jobTitle"
                                                   [appErrorAccess]="{id:'jobTitle-error',  submitted: isFormSubmit}"
                                                   required
                                                   #jobTitle="ngModel" />
                                            <label class="slide-label" for="jobTitle" [ngClass]="{'error-field': isFormSubmit && jobTitle.errors, 'active-label': jobTitle.value}">
                                                <span class="label-text" translate="employmentDetails.labels.jobTitle"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id="jobTitle-error" *ngIf="jobTitle.errors && jobTitle.touched || (isFormSubmit && jobTitle.errors)">
                                        <span translate="employmentDetails.errors.jobTitle"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- This row is only if "Retired" is selected from "selectEmploymentStatus" drop-down-->
                            <div class="form-row" *ngIf="employmentInformation.employmentStatus === RETIRED">
                                <div *ngIf="!isDataRationalizationEnabled" class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <select id="selectPreviousOccupation"
                                                    name="selectPreviousOccupation"
                                                    [(ngModel)]="employmentInformation.selectPreviousOccupation"
                                                    #selectPreviousOccupationCtrl="ngModel"
                                                    [appErrorAccess]="{id:'selectPreviousOccupation-error',  submitted: isFormSubmit}"
                                                    required>

                                                <option *ngFor="let previousOccup of previousOccupation | alphabeticalSort:'label'" [value]="previousOccup.code" [selected]="previousOccup.selected">
                                                    {{previousOccup.label}}
                                                </option>
                                            </select>
                                            <label [ngClass]="{'error-field': selectPreviousOccupationCtrl.errors && selectPreviousOccupationCtrl.touched || (isFormSubmit && selectPreviousOccupationCtrl.errors), 'active-label': selectPreviousOccupationCtrl.value}"
                                                   class="slide-label" for="selectPreviousOccupation">
                                                <span class="label-text" translate="employmentDetails.labels.selectPreviousOccupation"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id="selectPreviousOccupation-error" *ngIf="selectPreviousOccupationCtrl.errors && selectPreviousOccupationCtrl.touched || (isFormSubmit && selectPreviousOccupationCtrl.errors)">
                                        <span translate="employmentDetails.errors.previousOccupationError"></span>
                                    </div>
                                </div>
                                <div  *ngIf="isDataRationalizationEnabled" class="flex-50 form-col">
                                   
                                            <app-contextual-search
                                                    nameAttr="selectPreviousOccupation"
                                                    label="employmentDetails.labels.selectPreviousOccupation"
                                                    [CurrentValue]=""
                                                    (changeEventEmitter)="setPreviousOccupation($event)"
                                                    [section]="section + 'Occupation'+i"
                                                    [form]="employmentDetailsForm"
                                                    [listItems]="previousOccupation"
                                                    [placeholder]="Occupation" [isDisabled]="false"
                                                    [listType]="'state'" [isRequired]="true"
                                                    [isFormSubmit]="isFormSubmit"
                                                    [selectedCode] = "employmentInformation.selectPreviousOccupation"
                                                    [errorID] = "'selectPreviousOccupation-error'"
                                                    [errorMsg] = "'employmentDetails.errors.previousOccupationError'"
                                                    >
                                                </app-contextual-search>
                                        
                                </div>
                            </div>

                            <!-- Do not show for Presently unemployed or (retired && student/homemaker/unemployed) -->
                            <div class="form-row" *ngIf="employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && !(employmentInformation.employmentStatus === RETIRED &&
                            (employmentInformation.selectPreviousOccupation === HOMEMAKER ||
                            employmentInformation.selectPreviousOccupation === STUDENT ||
                            employmentInformation.selectPreviousOccupation === UNEMPLOYED))">
                                        <div *ngIf="!isDataRationalizationEnabled" class="flex-50 form-col">
                                            <div class="form-field">
                                                <div class="form-label-group">
                                                    <select id="selectNatureOfBusiness"
                                                            name="selectNatureOfBusiness"
                                                            data-ana="mywealth:onboarding:employment:industry"
                                                            [(ngModel)]="employmentInformation.selectNatureOfBusiness"
                                                            #selectNatureOfBusinessCtrl="ngModel"
                                                            [appErrorAccess]="{id:'selectNatureOfBusiness-error',  submitted: isFormSubmit}"
                                                            required>
        
                                                        <option *ngFor="let natureOfBusiness of currentNatureOfBusiness | alphabeticalSort:'label'" [value]="natureOfBusiness.code"
                                                                [selected]="natureOfBusiness.selected">
                                                            {{natureOfBusiness.label}}
                                                        </option>
                                                    </select>
                                                    <label [ngClass]="{'error-field': selectNatureOfBusinessCtrl.errors && selectNatureOfBusinessCtrl.touched || (isFormSubmit && selectNatureOfBusinessCtrl.errors), 'active-label': selectNatureOfBusinessCtrl.value}"
                                                           class="slide-label" for="selectNatureOfBusiness">
                                                        <span class="label-text" translate="employmentDetails.labels.selectNatureOfBusiness"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div role="alert" class="error-message" id="selectNatureOfBusiness-error" *ngIf="selectNatureOfBusinessCtrl.errors && selectNatureOfBusinessCtrl.touched || (isFormSubmit && selectNatureOfBusinessCtrl.errors)">
                                                <span translate="employmentDetails.errors.occupationError"></span>
                                            </div>
        
                                        </div>
                                        <div *ngIf="isDataRationalizationEnabled" class="flex-50 form-col">
                                   
                                            <app-contextual-search
                                            nameAttr="selectNatureOfBusiness"
                                            label="employmentDetails.labels.selectNatureOfBusiness"
                                            [CurrentValue]=""
                                            (changeEventEmitter)="settNatureOfBusiness($event)"
                                            [section]="section + 'currentNatureOfBusiness'+i"
                                            [form]="employmentDetailsForm"
                                            [listItems]="currentNatureOfBusiness"
                                            [placeholder]="Nature" [isDisabled]="false"
                                            [listType]="'state'" [isRequired]="true"
                                            [isFormSubmit]="isFormSubmit"
                                            [selectedCode] = "employmentInformation.selectNatureOfBusiness"
                                            [errorID] = "'selectNatureOfBusiness-error'"
                                             [errorMsg] = "'employmentDetails.errors.occupationError'"
                                            >
                                        </app-contextual-search>
                                       

                                </div>
                            </div>
                        </div>

                    </section>

                    <section>

                        <h2 translate="employmentDetails.subtitleEmployerDescription" *ngIf="employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED"></h2>
                        <div class="form-wrapper" *ngIf="employmentInformation.employmentStatus !== NOTPRESENTLYEMPLOYED && employmentInformation.employmentStatus !== RETIRED">
                            <div class="form-row">
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="employerName"
                                                   type="text"
                                                   name="employerName"
                                                   data-ana="mywealth:onboarding:employment:company"
                                                   maxlength="30"
                                                   minlength="1"
                                                   [(ngModel)]="employmentInformation.employerName"
                                                   (ngModelChange)="onEmployerNameChange()"
                                                   #employerNameCtrl="ngModel"
                                                   [appErrorAccess]="{id:'employerName-error',  submitted: isFormSubmit}"
                                                   [value]="employerNameCtrl.value"
                                                   formatPipe="Capitalize"
                                                   InputValidator="ct15"
                                                   [blockPaste]="true"
                                                   required />
                                            <label [ngClass]="{'error-field': employerNameCtrl.errors && employerNameCtrl.touched || (isFormSubmit && employerNameCtrl.errors), 'active-label': employerNameCtrl.value}"
                                                   class="slide-label" for="employerName">
                                                <span class="label-text" translate="employmentDetails.labels.employerName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id="employerName-error" *ngIf="employerNameCtrl.errors && employerNameCtrl.touched || (isFormSubmit && employerNameCtrl.errors)">
                                        <span translate="employmentDetails.errors.employerName"></span>
                                    </div>
                                </div>
                                <div class="flex-50 form-col" *ngIf="employmentInformation.isBMOEmployee">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <select id="bmoGroup" 
                                            name="bmoGroup" 
                                            [(ngModel)]="employmentInformation.bmoGroupOption" 
                                            #bmoGroupCtrl="ngModel" 
                                            [appErrorAccess]="{id:'bmoGroup-error',  submitted: isFormSubmit}"
                                            required>

                                                <option *ngFor="let bmoGroup of bmoGroupOptions | alphabeticalDescendingSort:'label'" [value]="bmoGroup.code">{{bmoGroup.label}}</option>
                                            </select>
                                            <label class="slide-label" for="bmoGroup" [ngClass]="{'error-field': bmoGroupCtrl.errors && bmoGroupCtrl.touched || (isFormSubmit && bmoGroupCtrl.errors), 'active-label': bmoGroupCtrl.value}">
                                                <span class="label-text" translate="employmentDetails.labels.bmoGroupListName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id="bmoGroup-error" *ngIf="bmoGroupCtrl.errors && bmoGroupCtrl.touched || (isFormSubmit && bmoGroupCtrl.errors)">
                                        <span translate="employmentDetails.errors.selectionRequired"></span>
                                    </div>
                                </div>

                            </div>
                             <!-- commented for MWB-13882 -->
                             <ng-container *ngIf="isAMLFunctionalityEnabled">
                             <div class="form-row">
                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <select id="selectCountry"
                                                    name="selectCountry"
                                                    [(ngModel)]="employmentInformation.selectCountry"
                                                    #selectCountryCtrl="ngModel"
                                                    [appErrorAccess]="{id:'selectCountry-error',  submitted: isFormSubmit}"
                                                    (change)="onCountryChange($event)"
                                                    required>

                                                <option *ngFor="let country of currentCountry" [value]="country.code">
                                                    {{country.label}}
                                                </option>
                                            </select>
                                            <label class="slide-label" for="selectCountry" [ngClass]="{'error-field': selectCountryCtrl.errors && selectCountryCtrl.touched || (isFormSubmit && selectCountryCtrl.errors),
                                'active-label': selectCountryCtrl.value}">
                                                <span class="label-text" translate="employmentDetails.labels.selectCountry"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message"  id="selectCountry-error" *ngIf="selectCountryCtrl.errors && selectCountryCtrl.touched || (isFormSubmit && selectCountryCtrl.errors)">
                                        <span translate="employmentDetails.errors.invalidCountry"></span>
                                    </div>
                                </div>
                            </div> 
                             <div class="form-row">
                                <div class="flex-70 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="streetNumberName"
                                                   name="streetNumberName" 
                                                   type="text" 
                                                   appAddressLengthValidator
                                                   data-ana="mywealth:onboarding:employment:address"
                                                   [(ngModel)]="employmentInformation.streetNumberName"
                                                   #streetNumberNameCtrl="ngModel"
                                                   InputValidator="ct14"
                                                   (focus)="onStreetNameChange($event)"
                                                   [appErrorAccess]="{ id: 'streetNumberName-error' ,submitted: isFormSubmit}"
                                                   required
                                                   autocomplete="new-password"
                                                   [blockPaste]="true"
                                                   [SelectedCountry]="employmentInformation.selectCountry"
                                                   aria-live="assertive"
                                                    />
                                            <label [ngClass]="{'error-field': streetNumberNameCtrl.errors && streetNumberNameCtrl.touched || (isFormSubmit && streetNumberNameCtrl.errors),
                            'active-label': streetNumberNameCtrl.value}" class="slide-label" for="streetNumberName">
                                                <span class="label-text" translate="employmentDetails.labels.streetNumberName"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id= "streetNumberName-error" *ngIf="streetNumberNameCtrl?.errors?.required && streetNumberNameCtrl.touched || (isFormSubmit && streetNumberNameCtrl?.errors?.required)">
                                        <span translate="employmentDetails.errors.invalidStreetNumberName"></span>
                                    </div>
                                    <div role="alert" class="error-message" id= "streetNumberName-error" *ngIf="streetNumberNameCtrl?.errors?.streetNumberName">
                                        <span translate="contactInformation.errors.addressError"></span>
                                    </div>
                                </div>

                                <div class="flex-30 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="unit"
                                                   name="unit"
                                                   type="text"
                                                   maxlength="5"
                                                   [(ngModel)]="employmentInformation.unit"
                                                   #unitCtrl="ngModel"
                                                   [appErrorAccess]="{ id: 'unit-error' ,submitted: isFormSubmit}"
                                                   appUnitValidator
                                                   InputValidator="ct12" />
                                            <label class="slide-label" for="unit" [ngClass]="{'active-label': unitCtrl.value}">
                                                <span class="label-text" translate="contactInformation.labels.unit"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id= "unit-error" *ngIf="unitCtrl.errors && unitCtrl.touched || (isFormSubmit && unitCtrl.errors)">
                                        <span translate="contactInformation.errors.invalidUnitCode"></span>
                                    </div>

                                </div>
                            </div> 
                             <div class="form-row">
                                <div class="flex-33 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="city"
                                                   name="city"
                                                   type="text" maxlength="28" [(ngModel)]="employmentInformation.city"
                                                   InputValidator="ct13"
                                                   [appErrorAccess]="{ id: 'city-error' ,submitted: isFormSubmit}"
                                                   #cityCtrl="ngModel" required />
                                            <label [ngClass]="{'error-field': cityCtrl.errors && cityCtrl.touched || (isFormSubmit && cityCtrl.errors), 'active-label': cityCtrl.value}"
                                                   class="slide-label" for="city">
                                                <span class="label-text" translate="contactInformation.labels.city"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message"  id= "city-error" *ngIf="cityCtrl.errors && cityCtrl.touched || (isFormSubmit && cityCtrl.errors)">
                                        <span translate="employmentDetails.errors.invalidCity"></span>
                                    </div>
                                </div>

                               
                                <div class="flex-33 form-col" *ngIf="employmentInformation.selectCountry === COUNTRYCANADA">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <select id="province" 
                                            name="province" 
                                            [(ngModel)]="employmentInformation.province" 
                                            #provinceCtrl="ngModel"
                                            [appErrorAccess]="{ id: 'province-error' ,submitted: isFormSubmit}" 
                                            required>

                                                <option *ngFor="let province of provinces" [value]="province.code" [selected]="province.selected">
                                                    {{province.label}}
                                                </option>
                                            </select>
                                            <label [ngClass]="{'error-field': provinceCtrl.errors && provinceCtrl.touched || (isFormSubmit && provinceCtrl.errors), 'active-label': provinceCtrl.value}"
                                                   class="slide-label" for="province">
                                                <span class="label-text" *ngIf="employmentInformation.selectCountry === COUNTRYCANADA" translate="contactInformation.labels.province">
                                                </span>
                                                <span class="label-text" *ngIf="employmentInformation.selectCountry === COUNTRYUSA" translate="contactInformation.labels.state">
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id= "province-error" *ngIf="provinceCtrl.errors && provinceCtrl.touched || (isFormSubmit && provinceCtrl.errors)">
                                        <span translate="employmentDetails.errors.invalidProvince"></span>
                                    </div>
                                </div>
                                <div class="flex-33 form-col" *ngIf="employmentInformation.selectCountry === COUNTRYCANADA">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="postalCode" 
                                                   name="postalCode" 
                                                   class="text-transform-uppercase" 
                                                   type="text" 
                                                   minlength="1" 
                                                   maxlength="7" 
                                                   [(ngModel)]="employmentInformation.postalCode"
                                                   #postalCodeCtrl="ngModel"
                                                   [appErrorAccess]="{ id: 'postalCode-error' ,submitted: isFormSubmit}"
                                                   appPostalCodeValidator 
                                                   appPostalCode 
                                                   [value]="employmentInformation.postalCode | uppercase" required />
                                            <label [ngClass]="{'error-field': postalCodeCtrl.errors && postalCodeCtrl.touched || (isFormSubmit && postalCodeCtrl.errors), 'active-label': postalCodeCtrl.value}"
                                                   class="slide-label" for="postalCode">
                                                <span class="label-text" translate="contactInformation.labels.postalCode"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id= "postalCode-error" *ngIf="postalCodeCtrl.errors && postalCodeCtrl.touched || (isFormSubmit && postalCodeCtrl.errors)">
                                        <span translate="employmentDetails.errors.invalidPostalCode"></span>
                                    </div>
                                </div>

                              
                                <div class="flex-33 form-col" *ngIf="employmentInformation.selectCountry === COUNTRYUSA">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <select id="state" 
                                                    name="state" 
                                                    [(ngModel)]="employmentInformation.state" 
                                                    #stateCtrl="ngModel" 
                                                    [appErrorAccess]="{ id: 'state-error' ,submitted: isFormSubmit}"
                                                    required>

                                                <option *ngFor="let state of states" [value]="state.code" [selected]="state.selected">
                                                    {{state.label}}
                                                </option>
                                            </select>
                                            <label [ngClass]="{'error-field': stateCtrl.errors && stateCtrl.touched || (isFormSubmit && stateCtrl.errors), 'active-label': stateCtrl.value}"
                                                   class="slide-label" for="province">
                                                <span class="label-text" translate="contactInformation.labels.state"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id= "state-error" *ngIf="stateCtrl.errors && stateCtrl.touched || (isFormSubmit && stateCtrl.errors)">
                                        <span translate="employmentDetails.errors.invalidProvince"></span>
                                    </div>
                                </div>
                                <div class="flex-33 form-col" *ngIf="employmentInformation.selectCountry === COUNTRYUSA">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="zipCode"
                                                   name="zipCode"
                                                   class="text-transform-uppercase"
                                                   type="tel"
                                                   maxlength="12"
                                                   [(ngModel)]="employmentInformation.zipCode"
                                                   #zipCodeCtrl="ngModel"
                                                   [appErrorAccess]="{ id: 'zipCode-error' ,submitted: isFormSubmit}"
                                                   InputValidator="ct3"
                                                   formatPipe="zip"
                                                   appZipCodeValidator 
                                                   required/>
                                            <label [ngClass]="{'error-field': zipCodeCtrl.errors && zipCodeCtrl.touched || (isFormSubmit && zipCodeCtrl.errors), 'active-label': zipCodeCtrl.value}"
                                                   class="slide-label" for="zipCode">
                                                <span class="label-text" translate="contactInformation.labels.zipCode"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message" id= "zipCode-error" *ngIf="zipCodeCtrl.errors && zipCodeCtrl.touched || (isFormSubmit && zipCodeCtrl.errors)">
                                        <span translate="employmentDetails.errors.invalidZipCode"></span>
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                        </div>

                    </section>

                </div>

            </section>

        </section>

        <section>
            <app-form-buttons [appInvalidForm]="appInvalidForm"  [showFinishLater]="showFinishLater" formName="employment" [backRoute]="APP_ROUTES.contact_information"></app-form-buttons>
        </section>

    </div>

</form>

