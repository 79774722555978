/**
 * last updated by: Abhinav Jain (25 April, 2018)
 */

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Payload } from './payload.class';
import { Utils } from '../utils/utils.class';
import * as moment from 'moment';
import { APP_ROUTES, PROVINCES_WHERE_AGE19_IS_MAJORITY } from '../../config/app-config.constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FormService {
    private payload: Payload;
    private $setisApplicanPBclient = new BehaviorSubject(null)

    public forms = {
        branchReferral: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        setExpectation: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        accountSelection: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        personalInformation: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        contactInformation: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        employmentInformation: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        selectAccount: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        accountDetail: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        familyInformation: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        beneficiaryInformation: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        withdrawal: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        celebrationInformation: {
            isVisited: true
        },
        financialInformation: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        investmentKnowledge: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        investmentObjectives: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        riskAndReturn: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        investorProfile: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        regulatoryDisclosures: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        consents: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        bmoRelationship: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        accountPassword: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        review: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
        celebrationReview: {
            isVisited: true
        },
        termsConditions: {
            isVisited: false,
            data: null,
            isCompleted: false
        },
    };
    // public activeFormComponent;
    public activeComponentName;
    public activeForm;
    public activeComponentObj;
    constructor() {

        /**Create a party ref Id */
        let partyRefId = Utils.getUUID();
        Utils.setPartyRefId(partyRefId);

        this.payload = new Payload();

        this.setPartyRefIdInPayload(Utils.getPartyRefId());

    }

    setisApplicantPBclient(value) {
        this.$setisApplicanPBclient.next(value)
    }

    getisApplicantPBclient() {
        return this.$setisApplicanPBclient.asObservable();
    }

    // this method will be called on when form that user which user is filling is valid.
    // once the form is valid and user moves on we are saving forms data (model) in this service
    // with formname as object data will hold model
    setFormData(formName, model) {
        this.forms[formName].data = model;
    }

    getFormData(formName) {
        return this.forms[formName].data;
    }

    setPartyRefIdInPayload(refId) {
        this.payload.parties[0].partyRefId = refId;
        for(let i in this.payload.accounts){
            this.payload.accounts[i].primaryApplicantPartyRefId = refId;
        }
        
    }

    getPayload() {
        return this.payload;
    }

    getPrimaryAccount() {
        return this.payload.accounts[0];
    }
    getPrimaryApplicant() {
        return this.payload.parties[0];
    }
    setPrimaryAccount(account) {
        this.payload.accounts[0] = account;
    }
    setPrimaryApplicant(party) {
        this.payload.parties[0] = party;
    }
    // clearing payload party when session is ended on UI
    clearPayload() {
        this.payload.parties = [];
    }

    /**
     * @description: Set the current component name and object for page validation purposes (on back button click on navigation)
     * @param: component name
     * @return: none
     */
    setActiveComponent(componentName, componentObj) {
        this.activeComponentName = componentName;
        this.activeComponentObj = componentObj;
    }

    /**
     * @description: Set the current active form for page validation purposes (on back button click / on navigation)
     * @param: form object
     * @return: none
     */
    setActiveForm(ngForm) {
        this.activeForm = ngForm;
    }

    /**
     * @description: Set the current active form for page validation purposes (on back button click / on navigation)
     * @param: boolean
     * @return: {object}
     */
    getActiveForm(ngForm = true) {
        if (ngForm) {
            return this.activeForm;
        }
        return this.forms[this.activeComponentName];
    }

    /**
     * @description: Get the current active component object for page validation purposes (on back button click / on navigation)
     * @param: none
     * @return: {object}
     */
    getActiveComponent() {
        return this.activeComponentObj;
    }

    /**
    * @description: Method to check the 'age of majoirty', returns true if the applicant is of
    * of 'age of majoirty' for the selected province and dob
    * @param: dob, province
    * @return: boolean
    */
    isAgeOfMajorityForProvince(dob: string, provinceCode: string): boolean {

        // If any one of the value is empty, then validation cannot be performed
        // so it will return true..
        if (!(dob && provinceCode)) {
            return true;
        }

        const todayMinus19Years = moment().subtract(19, 'year');
        const dobMoment = moment(dob, 'YYYY-MM-DD');

        // if DOB is NOT earlier than todayMinus19Years
        if (!(dobMoment.isBefore(todayMinus19Years))) {

            // if the DOB is less than 19 years, then check if the province accepts the age as majority
            const found = PROVINCES_WHERE_AGE19_IS_MAJORITY
                .find(x => x === provinceCode);

            // If the user has selected one of those states...
            return (found) ? false : true;
        }

        return true;
    }

}
