import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';
@Component({
  selector: 'app-personal-banker-or-financial-planner',
  templateUrl: './personal-banker-or-financial-planner.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class PersonalBankerOrFinancialPlannerComponent implements OnInit {

  @Input() branchReferralObj: any;
  @Input() isFormSubmit: boolean;
  @Input() form: NgForm;

  constructor() {
  }

  ngOnInit() {
  }

  isError(ctrl: any) {

    if (ctrl) {
      return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
    }
  }

}
