import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ApplicationService } from '../../service/application.service';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils/utils.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigResolverService } from '../../../config/external-config/service/config-resolver.service';
import { AccountDetail } from '../../../sections/account-detail/account-detail.component';
import { EmploymentInformation } from '../../../sections/employment-information/employment-information.component';
import { PersonalInformation } from '../../../sections/personal-information/personal-information.component';
import { ContactInformation } from '../../../sections/contact-information/contact-information.component';
import { FinancialInformation } from './../../../sections/financial-details/financial-details.component';
import { FamilyInformation } from './../../../sections/family-information/family-information.component';
import { PartyService } from '../../../shared/service/party.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormService } from '../../../shared/service/form.service';
import { SelectAccount } from '../../../sections/select-account/select-account.component';
import { RegulatoryDisclosure } from '../../../sections/regulatory-disclosures/regulatory-disclosures.component';
import { AccountPassword } from '../../../sections/account-password/account-password.component';
import { AccountTypeService } from '../../../shared/service/account-type.service';
import {
    APP_ROUTES, ANALYTICS, ACCOUNT_TYPE, SUCCESS_RESPONSE, FORBIDDEN, UNAUTHORIZED,
    NOT_FOUND, STATUS_CODE_NOTFOUND, STATUS_CODE_FORBIDDEN,
    STATUS_CODE_UNAUTHORIZED, SPOUSAL_RRSP_ACCOUNT, MARGIN,
    STATUS_CODE_UNAUTHORIZED_CODES, STATUS_CODE_FORBIDDEN_CODES, STATUS_CODE_NOTFOUND_CODES, CDB
} from '../../../config/app-config.constants';
import { BmoRelationship } from '../../../sections/bmo-relationship/bmo-relationship.component';
import { Consents } from '../../../sections/consents/consents.component';
import { NgForm } from '@angular/forms';
import { AnalyticsService } from '../../service/analytics.service';
import { Jquery } from '../../../shared/utils/jquery.class';
import * as $ from 'jquery';
import { InvestorProfile } from '../../../sections/investor-profile/investor-profile.component';
import { InvestmentKnowledge } from '../../../sections/investment-knowledge/investment-knowledge.component';
import { RiskAndReturn } from '../../../sections/risk-and-return/risk-and-return.component';
import { CdbOlbService } from '../../../shared/service/cdb-olb.service';
class ResumeLogin {

    appNumber = '';
    appPwd = '';

    /*  // sample obj
     appNumber = 'IL00009590';
     appPwd = 'Evarose1'; */

}
@Component({
    selector: 'app-resume-application',
    templateUrl: './resume-application.component.html'
})
export class ResumeApplicationComponent implements OnInit, OnDestroy {

    @ViewChild('resAppForm', { static: true }) resumeApplicaitionForm: NgForm;
    public isCurrentLangEnglish: Boolean = null;
    public isCurrentLangFrench: Boolean = null;
    @Output() dismiss: EventEmitter<any> = new EventEmitter();
    public resumeApplication = null;
    public isFormSubmit: Boolean = false;
    public criticalError: Boolean = false;
    private config: any = null;
    public selectAccount: SelectAccount = new SelectAccount();

    public accountDetail: AccountDetail;
    public contactInformation: ContactInformation;
    public personalInformation: PersonalInformation;
    public employmentInformation: EmploymentInformation;
    public familyInformation: FamilyInformation;
    public financialInformation: FinancialInformation;
    public investorProfile: InvestorProfile;
    public investorProfileArray: InvestorProfile[];
    public riskAndReturn: RiskAndReturn;
    public investmentKnowledge: InvestmentKnowledge = new InvestmentKnowledge();
    public regulatoryInformation: RegulatoryDisclosure;
    public accountPasswordInformation: AccountPassword;
    public bmoRelationship: BmoRelationship;
    public consents: Consents;
    public lang: any;
    public isLoginSuccessful: boolean;
    public applicationObject;
    public isAttemptsExceeded: boolean;
    public isPasswordExpired: boolean;
    public isLoginInvalid: boolean;
    public CancelledMargin: boolean;
    public redirectDowngradePage: boolean;
    public isAppNumNotEntered: boolean;
    public isPasswordNotEntered: boolean;
    private OLAPUrl_en = '';
    private OLAPUrl_fr = '';
    isBranchAssisted: boolean;
    public passwordHide: Boolean = true;
    enableOlb = false;
    resumeApplicationResponse: any;
    public isMultiAccountOpen: boolean = false;
    public isEnvLocalOrDit: boolean;
    public isSubProductAD : boolean;

    constructor(public applicationService: ApplicationService,
        public accountTypeService: AccountTypeService,
        public titleService: Title,
        private formService: FormService,
        private translate: TranslateService,
        public http: HttpClient,
        private configService: ConfigResolverService<any>,
        private partyService: PartyService,
        public analytics: AnalyticsService,
        private route: ActivatedRoute,
        private router: Router,
        private cdbOlbService: CdbOlbService) {

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.analytics.setPageName(ANALYTICS.PAGE_NAME.RESUME_APP_OVERLAY);

        if (this.isCurrentLangEnglish) {
            this.translate.use('en');
            this.lang = 'en';
        } else if (this.isCurrentLangFrench) {
            this.translate.use('fr');
            this.lang = 'fr';
        }
    }

    ngOnInit() {

        this.resumeApplication = new ResumeLogin();
        this.isLoginSuccessful = false; // Defaulting to false
        this.isBranchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
        this.isSubProductAD = Utils.isSubProductAD();

        // setting title of the page
        this.route.queryParams.subscribe(params => {

            this.titleService.setTitle('Online Investment Application | InvestorLine | BMO');
            // English is set as Default

            if (params.lang === 'fr') {
                this.titleService.setTitle('BMO Ligne d\'action - Demande d\'ouverture de compte en ligne -');
            }

        });
        this.applicationService.getReferences(this.lang);
        let cmsMessageObject = Utils.getCmsMessageObject();
        this.enableOlb = cmsMessageObject.selfServe.enableOrDisableOlb;
        this.isEnvLocalOrDit = window.location.href.includes('dit') || window.location.href.includes('localhost');
        this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.RESUME_APPLICATION);
    }

    ngAfterViewInit(): void {
        $('.modal-overlay').show();
        $('.bmo-log-in').show();
        Jquery.modalFocusTrap('bmo-log-in');
        Jquery.modalScrollHeight();

    }


    signIn() {

        this.isFormSubmit = true;
        this.isAttemptsExceeded = false;
        this.isPasswordExpired = false;
        this.isLoginInvalid = false;
        this.criticalError = false;
        this.CancelledMargin = false;
        this.redirectDowngradePage = false;
        this.isAppNumNotEntered = false;
        this.isPasswordNotEntered = false;

        if (!this.resumeApplication.appPwd || !this.resumeApplication.appNumber) {
            if (!this.resumeApplication.appPwd) {
                this.isPasswordNotEntered = true;
                document.getElementById('appPwd').focus();
            }
            if (!this.resumeApplication.appNumber) {
                this.isAppNumNotEntered = true;
                document.getElementById('appNumber').focus();
            }
        }
        else if (!this.isAlphanumeric(this.resumeApplication.appNumber) || !this.isAlphanumeric(this.resumeApplication.appPwd)) {
            this.criticalError = true;
            this.isPasswordExpired = true;
            document.getElementById('appNumber').focus();
        } else
        if (this.resumeApplicaitionForm.valid === true) {
            this.isMultiAccountOpen = this.applicationService.getMultiAccountOpenFeatureFlag();

            this.config = this.configService.getConfig();
            const signInURL = this.config.CHANNELS_API.CONTEXT_ROOT + this.config.CHANNELS_API.LOGIN_URI;
            // console.log('signInURL: ', signInURL);
            let headers = this.config.CHANNELS_API.LOGIN_HEADER;
            headers = new HttpHeaders(headers);
            // console.log('headers: ', headers);

            const payload = { applicationNumber: this.resumeApplication.appNumber, password: this.resumeApplication.appPwd };
            // console.log('payload: ', payload);

            this.http.post(signInURL, payload, { headers: headers, observe: 'response' })
                .toPromise()
                .then(response => {
                    if (response.status === SUCCESS_RESPONSE) {
                        this.resumeApplicationResponse = response
                        this.applicationObject = response.body;
                        Utils.setSubProduct(this.applicationObject.applicationSubProduct);
                        if (Utils.isSubProductAD() || !this.isMultiAccountOpen) {
                            console.log(response);
                            console.log('response 200 ok');
                            if (this.applicationObject.errorCode) {
                                console.log('error object exist');

                                if (STATUS_CODE_UNAUTHORIZED_CODES.includes(this.applicationObject.errorCode)) { // Invalid Password
                                    this.isPasswordExpired = true;

                                } else if (STATUS_CODE_FORBIDDEN_CODES.includes(this.applicationObject.errorCode)) { // Invalid Attempts
                                    this.isAttemptsExceeded = true;

                                } else if (STATUS_CODE_NOTFOUND_CODES.includes(this.applicationObject.errorCode)) {  // No Application found or Password expired
                                    this.isPasswordExpired = true;

                                    }
                                    this.criticalError = true;

                                }
                                else {
                                    console.log('else part executed');
                                    if (this.applicationObject.accounts[0].accountFeatures.creditCheck &&
                                        this.applicationObject.accounts[0].accountFeatures.creditCheck.hasOwnProperty('decisionType') &&
                                        (this.applicationObject.accounts[0].accountFeatures.creditCheck.decisionType == 'ILDECLNE' ||
                                            this.applicationObject.accounts[0].accountFeatures.creditCheck.decisionType == 'ILDWNGRD')) {
                                        if (this.applicationObject.accounts[0].accountFeatures.creditCheck.userDecision !== null) {
                                            this.CancelledMargin = true;
                                            return;
                                        } else if (this.applicationObject.accounts[0].accountFeatures.creditCheck.userDecision === null) {
                                            this.redirectDowngradePage = true;
                                        }
                                    }
                                    this.isLoginSuccessful = true;
                                    this.applicationService.pageTitles();

                                    setTimeout(() => {
                                        Jquery.modalFocusTrap('bmo-log-in');
                                    }, 100);
                                    //this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.RESUME_APPLICATION_COMPLETED);
                                    this.applicationService.setResumeLink(false);  // since user has logged in ... hide it

                                    this.applicationService.applicationNumber = this.applicationObject.applicationNumber;
                                    this.applicationService.applicationStatus = this.applicationObject.status;
                                    this.applicationService.applicationNumber$.next(this.applicationObject.applicationNumber);
                                    Utils.setApplicationData(this.applicationObject);

                                    // console.log('applicatinObject: ', this.applicationObject);

                                    const isUserInSession: boolean = JSON.parse(response.headers.get('isinsession'));
                                    // console.log('isUserInSession: ', isUserInSession);

                                    const authenticationLevel = response.headers.get('am-eai-auth-level');
                                    // console.log('authenticationLevel: ', authenticationLevel);
                                    if (this.applicationObject.branchReferral && this.applicationObject.branchReferral.isBranchReferral === true) {
                                        Utils.setIsBranchAssisted(this.applicationObject.branchReferral.isBranchReferral);
                                        Utils.setIsBranchReferral(this.applicationObject.branchReferral.isBranchReferral);
                                        if (this.applicationObject.applicationId) {
                                            Utils.setapplicationID(this.applicationObject.applicationId);
                                        }

                                        // set page status
                                        Utils.setPageStatus(this.applicationObject.pageStatus);
                                        Utils.resumeApplicationLogin = true;

                                    }
                                    if (isUserInSession === true) {
                                        Utils.setIsInSession(true);
                                    } else if (isUserInSession === false) {
                                        Utils.setIsInSession(false);
                                    }
                                    this.applicationService.triggerApplicationResumed();

                                    Utils.setApplicationObject(this.applicationObject); // setting at global level


                                    /**Set sub-product*/
                                    Utils.setSubProduct(this.applicationObject.applicationSubProduct);
                                    this.applicationService.setAccountFlow(this.applicationObject.applicationSubProduct);

                                    Utils.setPrimaryApplicant(this.applicationObject.parties[0]);
                                    this.applicationService.checkHasTradingKnowledge(this.applicationObject.accounts[0].accountFeatures.optionsTrading.knowledge);
                                    /*sign-out stuff*/
                                    Utils.setsaveDoneOnSignOut(false);

                                    /**URL Change for SD/AD */
                                    let navParamenter: NavigationExtras = {};

                                    if (Utils.isSubProductAD()) {
                                        navParamenter = {
                                            'queryParams': {
                                                'lang': Utils.getLanguage(),
                                                'subProduct': Utils.getSubProduct()
                                            }
                                        };
                                    } else {
                                        navParamenter = {
                                            'queryParams': {
                                                'lang': Utils.getLanguage()
                                            }
                                        };
                                    }
                                    if (!Utils.getIsBranchAssisted()) {
                                        Utils.setAliveCheckNetwork(false); // not to trigger RSM checknetworktype api on resume application flow
                                    }
                                    const _url = ['/select-account/'];
                                    this.router.navigate(_url, navParamenter);

                                    if (this.applicationObject.accounts[0].type == ACCOUNT_TYPE.RRSP
                                        && this.applicationObject.accounts[0].isSpousal === true) {
                                        this.selectAccount.selectedAccount = ACCOUNT_TYPE.SRRSP;
                                        this.formService.getPayload().setAccountType(this.selectAccount);
                                        this.selectAccount.selectedAccount = SPOUSAL_RRSP_ACCOUNT;
                                        Utils.setSelectedAccountType(SPOUSAL_RRSP_ACCOUNT.type);
                                        this.formService.setFormData('selectAccount', this.selectAccount);
                                    } else if (this.applicationObject.accounts[0].type == ACCOUNT_TYPE.CASH && this.applicationObject.accounts[0].subTypes.includes('2')) {
                                        this.selectAccount.selectedAccount = ACCOUNT_TYPE.MARGIN;
                                        this.formService.getPayload().setAccountType(this.selectAccount);
                                        this.selectAccount.selectedAccount = MARGIN;
                                        Utils.setSelectedAccountType(MARGIN.type);
                                        this.formService.setFormData('selectAccount', this.selectAccount);
                                    } else {
                                        this.selectAccount.selectedAccount = this.accountTypeService.accountTypeMetaData.find(
                                            x => x.type == this.applicationObject.accounts[0].type);
                                        Utils.setSelectedAccountType(this.selectAccount.selectedAccount.type);
                                        this.formService.setFormData('selectAccount', this.selectAccount);
                                    }

                                    this.partyService.setAccountTypeOnResumeApplication(this.applicationObject);

                                    this.applicationService.selectAccount$.next(this.applicationObject.accounts[0]);

                                    this.accountTypeService.setSelectedAccount(this.selectAccount.selectedAccount);
                                    if (this.redirectDowngradePage) {
                                        Utils.resumeApplicationDowngrade = true;
                                        const primaryAccount = this.partyService.getPrimaryAccount(this.applicationObject);
                                        if (Utils.nullCheck(primaryAccount.accountFeatures)) {
                                            Utils.callSelectAccount = true;
                                            this.partyService.setAccountDetailsForm(this.applicationObject);
                                        }
                                        this.partyService.markAllPagesComplete();
                                        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.application_approval_downgrade);
                                    } else {
                                        this.partyService.onResumeApplicationPrepopulate();
                                    }
                                }


                            }
                            else if (!Utils.isSubProductAD() && this.isMultiAccountOpen) {
                                this.signInMultiAccountType();
                            }
                        }

                        else {
                            console.log('critical errror');
                            this.criticalError = true;
                        }
                        // console.log('response status on Login : ', response.status);



                    })
                    .catch(e => {
                        console.log('catch executed');
                        // console.log('error : ', e);
                        this.criticalError = true;
                        if ((e.status === FORBIDDEN) && (e.error.errorCode === STATUS_CODE_FORBIDDEN)) { // Invalid Attempts
                            this.isAttemptsExceeded = true;

                        } else if ((e.status === NOT_FOUND) && (e.error.errorCode === STATUS_CODE_NOTFOUND)) {
                            // No Application found or Password expired
                            this.isPasswordExpired = true;

                        } else if ((e.status === UNAUTHORIZED) && (e.error.errorCode === STATUS_CODE_UNAUTHORIZED)) {  // Invalid Password
                            this.isPasswordExpired = true;

                        }
                        this.analytics.setapiCallDetails({
                            apiCallMade: 'true', apiCallType: "resumeApp",
                            apiCallResponseCode: e.error?.errorInfo ? e.status?.toString() + "-" + e.error?.errorInfo.errorCode.toString() : e.status?.toString()

                        });
                    });

            } else { // If form is not valid
                this.criticalError = true;
                this.isLoginInvalid = true;
            }
    }

    signInMultiAccountType() {

        if (this.applicationObject.errorCode) {
            console.log('error object exist');

            if (STATUS_CODE_UNAUTHORIZED_CODES.includes(this.applicationObject.errorCode)) { // Invalid Password
                this.isPasswordExpired = true;

            } else if (STATUS_CODE_FORBIDDEN_CODES.includes(this.applicationObject.errorCode)) { // Invalid Attempts
                this.isAttemptsExceeded = true;

            } else if (STATUS_CODE_NOTFOUND_CODES.includes(this.applicationObject.errorCode)) {  // No Application found or Password expired
                this.isPasswordExpired = true;

            }
            this.criticalError = true;

        }
        else {
            console.log('else part executed');
            // if (this.applicationObject.accounts[0].accountFeatures.creditCheck &&
            //     this.applicationObject.accounts[0].accountFeatures.creditCheck.hasOwnProperty('decisionType') &&
            //     (this.applicationObject.accounts[0].accountFeatures.creditCheck.decisionType == 'ILDECLNE' ||
            //         this.applicationObject.accounts[0].accountFeatures.creditCheck.decisionType == 'ILDWNGRD')) {
            //     if (this.applicationObject.accounts[0].accountFeatures.creditCheck.userDecision !== null) {
            //         this.CancelledMargin = true;
            //         return;
            //     } else if (this.applicationObject.accounts[0].accountFeatures.creditCheck.userDecision === null) {
            //         this.redirectDowngradePage = true;
            //     }
            // }
            this.isLoginSuccessful = true;
            this.applicationService.pageTitles();

            setTimeout(() => {
                Jquery.modalFocusTrap('bmo-log-in');
            }, 100);
            //this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.RESUME_APPLICATION_COMPLETED);
            this.applicationService.setResumeLink(false);  // since user has logged in ... hide it

            this.applicationService.applicationNumber = this.applicationObject.applicationNumber;
            this.applicationService.applicationStatus = this.applicationObject.status;
            this.applicationService.applicationNumber$.next(this.applicationObject.applicationNumber);
            Utils.setApplicationData(this.applicationObject);

            // console.log('applicatinObject: ', this.applicationObject);

            const isUserInSession: boolean = JSON.parse(this.resumeApplicationResponse.headers.get('isinsession'));
            // console.log('isUserInSession: ', isUserInSession);

            const authenticationLevel = this.resumeApplicationResponse.headers.get('am-eai-auth-level');
            // console.log('authenticationLevel: ', authenticationLevel);
            if (this.applicationObject.branchReferral && this.applicationObject.branchReferral.isBranchReferral === true) {
                Utils.setIsBranchAssisted(this.applicationObject.branchReferral.isBranchReferral);
                Utils.setIsBranchReferral(this.applicationObject.branchReferral.isBranchReferral);
                if (this.applicationObject.applicationId) {
                    Utils.setapplicationID(this.applicationObject.applicationId);
                }

                // set page status
                Utils.setPageStatus(this.applicationObject.pageStatus);
                Utils.resumeApplicationLogin = true;

            }
            if (isUserInSession === true) {
                Utils.setIsInSession(true);
            } else if (isUserInSession === false) {
                Utils.setIsInSession(false);
            }
            this.applicationService.triggerApplicationResumed();

            Utils.setApplicationObject(this.applicationObject); // setting at global level


            /**Set sub-product*/
            Utils.setSubProduct(this.applicationObject.applicationSubProduct);
            this.applicationService.setAccountFlow(this.applicationObject.applicationSubProduct);

            Utils.setPrimaryApplicant(this.applicationObject.parties[0]);

            /*sign-out stuff*/
            Utils.setsaveDoneOnSignOut(false);

            /**URL Change for SD/AD */
            let navParamenter: NavigationExtras = {};

            if (Utils.isSubProductAD()) {
                navParamenter = {
                    'queryParams': {
                        'lang': Utils.getLanguage(),
                        'subProduct': Utils.getSubProduct()
                    }
                };
            } else {
                navParamenter = {
                    'queryParams': {
                        'lang': Utils.getLanguage()
                    }
                };
            }
            if (!Utils.getIsBranchAssisted()) {
                Utils.setAliveCheckNetwork(false); // not to trigger RSM checknetworktype api on resume application flow
            }
            const _url = ['/select-account/'];
            this.router.navigate(_url, navParamenter);

            let multiAccountApplication: any = [];
            let selectedAccount: any = [];
            for (let i = 0; i < this.applicationObject.accounts.length; i++) {
                if (this.applicationObject.accounts[i].type == ACCOUNT_TYPE.RRSP
                    && this.applicationObject.accounts[i].isSpousal === true) {
                    this.selectAccount.selectedAccountTypes[i] = ACCOUNT_TYPE.SRRSP;
                    selectedAccount = SPOUSAL_RRSP_ACCOUNT;
                } else if (this.applicationObject.accounts[i].type == ACCOUNT_TYPE.CASH && this.applicationObject.accounts[i].subTypes.includes('2')) {
                    this.selectAccount.selectedAccountTypes[i] = '100544-MARGIN';
                    selectedAccount = MARGIN;
                } else {
                    this.selectAccount.selectedAccountTypes[i] = this.applicationObject.accounts[i].type;
                    selectedAccount = this.accountTypeService.accountTypeMetaData.find(
                        x => x.type == this.applicationObject.accounts[i].type);
                }
                multiAccountApplication.push(selectedAccount);
            }
            this.formService.getPayload().setMultiAccountTypes(this.selectAccount);
            this.formService.setFormData('selectAccount', this.selectAccount);
            this.partyService.setMultiAccountTypeOnResumeApplication(this.applicationObject);
            this.applicationService.selectAccount$.next(this.applicationObject.accounts[0]);
            this.accountTypeService.setMultiSelectedAccount(multiAccountApplication);

            if (this.redirectDowngradePage) {
                Utils.resumeApplicationDowngrade = true;
                const primaryAccount = this.partyService.getPrimaryAccount(this.applicationObject);
                if (Utils.nullCheck(primaryAccount.accountFeatures)) {
                    Utils.callSelectAccount = true;
                    this.partyService.setAccountDetailsForm(this.applicationObject);
                }
                this.partyService.markAllPagesComplete();
                this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.application_approval_downgrade);
            } else {
                this.partyService.onResumeMultiAccountApplicationPrepopulate();
            }
        }
    }

    dismissResumeApplication() {

        this.dismiss.emit();
        Jquery.modalDissableFocusTrap(this.applicationService.resumeAppID);
        (this.isLoginSuccessful === true) ? this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.RESUME_APPLICATION_COMPLETED)
            : this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.RESUME_APPLICATION);
    }


    onResumeApplicationModalSigninButton() {
        this.hideResumeApplicationModal();
        if (!this.applicationService.getActualOlbLoginFeatureFlag()) {
            this.router.navigate(['/CDB/login'], { skipLocationChange: true }); // mock CDB  login
        } else {
            this.applicationService.goToLoginPage(CDB);
            // this.cdbOlbService.goToLoginPage();
        }
    }
    onResumeJourneySigninButton() {
        this.hideResumeApplicationModal();
        this.router.navigate(['/journey/login'], { skipLocationChange: true }); // mock Journey  login

    }



    openOLAPSite() {
        if (this.configService.getConfig() !== null) {
            this.OLAPUrl_en = this.configService.getConfig().ILOLAPWEB_EN;
            this.OLAPUrl_fr = this.configService.getConfig().ILOLAPWEB_FR;
        }
        const olapURL = (this.isCurrentLangEnglish) ?
            this.OLAPUrl_en
            : this.OLAPUrl_fr;
        window.open(olapURL, 'self');
    }

    hideResumeApplicationModal() {
        $('.bmo-log-in, .modal-overlay-component').hide();
        Jquery.modalDissableFocusTrap();
    }


    onClose(event) {

        this.dismiss.emit();

        Jquery.modalDissableFocusTrap(this.applicationService.resumeAppID);


        (this.isLoginSuccessful === true) ? this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.RESUME_APPLICATION_COMPLETED)
            : this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.RESUME_APPLICATION);
    }

    isAlphanumeric(input) {
        var letterNumber = /^[0-9a-zA-Z]+$/;
        if (input.match(letterNumber)) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        $('.modal-overlay').hide();
        $('.bmo-log-in').hide();
    }

    @HostListener("keydown", ["$event"])
    onKeyPress(event: KeyboardEvent) {
        if (event.code == "Escape") {
            this.dismissResumeApplication();
        }
    }
    togglePasswordVisibility(id: string) {
        this.passwordHide = !this.passwordHide;
        Utils.passwordVisibilityToggle(id);
    }
}
