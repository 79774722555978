<form #cdbOlbForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
<div class="bmo-wrapper">
    
    <div style="width: 350px;margin: auto;">
        <h1>CDB/OLB LOGIN</h1>
<div class="flex-30 form-col">
    <div class="form-field">
        <div class="form-label-group">
            <input [(ngModel)]="OLBUserECIFId" #ecifIdCtrl="ngModel" name="ecifId"  type="text"  required>
            <label  class="slide-label">
                <span class="label-text">
                    ECIF ID 
                </span>
            </label>
        </div>
        
    </div>
    <div role="alert" id="ecifId-error" class="error-message" 
    *ngIf="(ecifIdCtrl?.errors && ecifIdCtrl?.touched) || (isFormSubmit && ecifIdCtrl.errors)  ">
        <span>Please enter valid ECIF ID</span>
    </div>
    <br>
    

    <div  class="form-field">
        <div class="form-label-group">
            <input [(ngModel)]="cardNumber" #cardCtrl="ngModel" minlength="16" maxlength="16" name="card"  type="text"  required>
            <label  class="slide-label">
                <span class="label-text">
                   16 Digit Card Number 
                </span>
            </label>
        </div>
    </div>
    <div role="alert" id="ecifId-error" class="error-message"
     *ngIf="(cardCtrl?.errors && cardCtrl?.touched) || (isFormSubmit && cardCtrl.errors) ">
        <span>Please enter 16 digit card number</span>
    </div>
   
</div>
<div class="form-col right-side flex-xaxis-center">
    <button (click)="onCancel()"  type="button" class="tertiary">Cancel</button>
    <button  type="submit" class="primary" >Submit</button>
</div>
</div>
</div>
</form>