<!-- Modal Log in - resume application and welcome back message -->

<div 
    role="dialog" 
    [attr.aria-label]="(isLoginSuccessful) ? ('modalLogIn.modal2Title' | translate) : ('button.resumeApplication' | translate)" 
    class="bmo-modal bmo-log-in">

    <a role="button"
       attr.aria-label="{{ 'modalLogIn.ariaLabels.close' | translate }}"
       attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
       class="btn-icon btn-close tooltip-show"
       href="javascript://" (click)="dismissResumeApplication()">
        <span class="icon icon-large close-grey"></span>
    </a>

    <form #resAppForm="ngForm" (ngSubmit)="signIn()" novalidate>
       <div class="modal-content-scroll no-margin content-left">

        <section *ngIf="!isLoginSuccessful">

            <div class="flex-maxis-center content-left" id="OLBResumeApplication">

                <div class="flex-elements flex-col justify-space-between">

                    <section>

                        <div role="alert" class="alert alert-danger" *ngIf="criticalError">
                            <p>
                                <strong>
                                    <span *ngIf="!isPasswordExpired" translate="appErrors.criticalError"></span>
                                    <span *ngIf="isPasswordExpired" translate="modalLogIn.errors.passwordExpiredTitle"></span>
                                </strong>
                                <br />
                                <span *ngIf="isAttemptsExceeded" translate="modalLogIn.errors.attemptsExceeded"></span>
                                <span *ngIf="isLoginInvalid" translate="modalLogIn.errors.invalidLogin"></span>
                                <span *ngIf="isPasswordExpired" translate="modalLogIn.errors.passwordExpired"></span>
                                <a *ngIf="isPasswordExpired"
                                   href="javascript://"
                                   (click)="openOLAPSite()"
                                   translate="modalLogIn.labels.applicationSiteOLAP"
                                   attr.aria-label="{{ 'modalLogIn.ariaLabels.applicationSiteOLAP' | translate }}"
                                   id="applicationSiteOLAPID"
                                   role="link">
                                </a>
                                <span *ngIf="isPasswordExpired">.</span>
                                <span *ngIf="!isAttemptsExceeded && !isLoginInvalid && !isPasswordExpired" translate="appErrors.criticalErrorDescriptionResume"></span>
                            </p>
                        </div>
                        <div role="alert" class="alert alert-danger" *ngIf="isPasswordNotEntered || isAppNumNotEntered">
                            <p >
                                <strong>
                                    <span *ngIf="isPasswordNotEntered && isAppNumNotEntered" translate="modalLogIn.errors.appPasswordNotEntered"></span>
                                    <span *ngIf="isPasswordNotEntered && !isAppNumNotEntered" translate="modalLogIn.errors.passwordNotEntered"></span>
                                    <span *ngIf="!isPasswordNotEntered && isAppNumNotEntered" translate="modalLogIn.errors.applicationNotEntered"></span>
                                </strong>
                                <br />
                                <span *ngIf="isPasswordNotEntered && isAppNumNotEntered" translate="modalLogIn.errors.appPasswordNotEntered1"></span>
                                <span *ngIf="isPasswordNotEntered && !isAppNumNotEntered" translate="modalLogIn.errors.passwordNotEntered1"></span>
                                <span *ngIf="!isPasswordNotEntered && isAppNumNotEntered" translate="modalLogIn.errors.applicationNotEntered1"></span>

                            </p>
                        </div>

                        <div role="alert" class="alert alert-danger" *ngIf="CancelledMargin">
                            <p>
                                <strong>
                                    <span translate="appErrors.marginDowngradeResumeTitle"></span>
                                </strong>
                                <br />
                                <span translate="appErrors.marginDowngradeResume"></span>
                            </p>
                        </div>

                        <ng-container *ngIf="!isBranchAssisted && enableOlb">
                            <h1 [innerHtml]="'modalLogIn.modal2Title' | translate | safeHtml"></h1>
                            <p class="add-margin b40px" [innerHtml]="'modalLogIn.section1Content' | translate | safeHtml"></p>
                            <h2 [innerHtml]="'modalLogIn.modal3Title' | translate | safeHtml"></h2>
                            <p [innerHtml]="'modalLogIn.section3Content' | translate | safeHtml"></p>

                            <div class="form-wrapper">
                                <div class="form-row form-page-buttons flex-maxis-center">

                                    <div class="form-col left-side">
                                        <button class="primary" data-ana="mywealth:onboarding:resume:sign in" type="button" attr.aria-label="{{ 'modalLogIn.ariaLabels.signIn' | translate }}"
                                                translate="modalLogIn.labels.signIn" (click)="onResumeApplicationModalSigninButton()"></button>
                                    </div>
                                    <div class="form-col right-side">
                                        <!-- Leave empty-->
                                         <!-- below code is for journey temperary button ony for dit environment -->
                                         <ng-container *ngIf="isEnvLocalOrDit && !isSubProductAD">
                                            <button class="primary"  type="button" (click)="onResumeJourneySigninButton()" > Journey Login</button>
                                         <!-- ------------------------- -->

                                         </ng-container>


                                    </div>

                                </div>
                            </div>

                            <h2 [innerHtml]="'modalLogIn.modal4Title' | translate | safeHtml"></h2>
                            <p [innerHtml]="'modalLogIn.section4Content' | translate | safeHtml"></p>
                        </ng-container>

                        <h2 *ngIf="isBranchAssisted || !enableOlb" [innerHtml]="'modalLogIn.modal1Title' | translate | safeHtml"></h2>

                        <div class="form-wrapper">
                            <div class="form-row">

                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="appNumber" name="appNumber" type="text"
                                                   data-ana="mywealth:onboarding:resume:application number"
                                                   maxlength="10" [(ngModel)]="resumeApplication.appNumber"
                                                   #appNumberCtrl="ngModel"
                                                   attr.aria-label="{{ 'modalLogIn.ariaLabels.accountNumber' | translate }}"
                                                   aria-describedby="passwordCheck1 passwordCheck2" aria-required="true" />
                                            <label class="slide-label" for="appNumber"
                                                   [ngClass]="{'error-field': (isFormSubmit && !appNumberCtrl.value) ,'active-label': appNumberCtrl.value}">
                                                <span class=" label-text"
                                                      translate="modalLogIn.labels.accountNumber"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message"
                                         *ngIf="(isFormSubmit && !appNumberCtrl.value)">
                                        <span translate="modalLogIn.errors.accountNumberRequired"></span>
                                    </div>
                                </div>

                                <div class="flex-50 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <input id="appPwd" name="appPwd" type="password"
                                                   data-ana="mywealth:onboarding:resume:password"
                                                   [(ngModel)]="resumeApplication.appPwd" #appPwdCtrl="ngModel"
                                                   maxlength="8"
                                                   attr.aria-label="{{ 'modalLogIn.ariaLabels.accountPassword' | translate }}"
                                                   aria-describedby="confirmPassword" aria-required="true" />
                                            <label class="slide-label" for="appPwd"
                                                   [ngClass]="{'error-field':(isFormSubmit && !appPwdCtrl.value) , 'active-label': appPwdCtrl.value}">
                                                <span class="label-text"
                                                      translate="modalLogIn.labels.accountPassword"></span>
                                            </label>
                                            <a role="button" *ngIf="(resumeApplication?.appPwd?.length > 0);else disabledEyes"
                                                (click)="togglePasswordVisibility('appPwd')"
                                            class="btn-icon reveal-password"
                                            attr.aria-label="{{'accountPassword.ariaLabels.showPassword' | translate}}"
                                            attr.aria-pressed="{{passwordHide == true ? 'false' : 'true'}}"
                                            href="javascript://">
                                            <span [class]="passwordHide == true ? 'icon icon-large password-hide-blue' : 'icon icon-large password-show-blue'"></span>
                                         </a>
                                         <ng-template #disabledEyes>
                                            <a role="button"  aria-disabled="true"
                                               class="disabled btn-icon reveal-password"
                                               attr.aria-label="{{'accountPassword.ariaLabels.disabledShowPassword' | translate}}"
                                               href="javascript://">
                                               <span [class]="passwordHide == true ? 'icon icon-large password-hide-grey' : 'icon icon-large password-show-grey'"></span>
                                            </a>
                                        </ng-template>
                                        </div>
                                    </div>
                                    <div role="alert" class="error-message"
                                         *ngIf="(isFormSubmit && !appPwdCtrl.value)" >
                                        <span translate="modalLogIn.errors.passwordRequired"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="form-row form-page-buttons flex-maxis-center">
                                <div class="form-col left-side">
                                    <button class="primary" type="submit" *ngIf="!isBranchAssisted"
                                            data-ana="mywealth:onboarding:resume:continue"
                                            attr.aria-label="{{ 'modalLogIn.ariaLabels.continue' | translate }}"
                                            translate="modalLogIn.labels.continue"></button>
                                </div>
                                <div class="form-col right-side">
                                    <button class="primary" type="submit" *ngIf="isBranchAssisted"
                                            attr.aria-label="{{ 'modalLogIn.ariaLabels.branchSignIn' | translate }}"
                                            translate="modalLogIn.labels.signIn"></button>
                                </div>

                            </div>
                        </div>

                    </section>

                </div>

            </div>

        </section>


        <app-login-success-popup (close)="onClose($event)"
                                 [show]="isLoginSuccessful">
        </app-login-success-popup>
    </div>
    </form>




    <!--   for ui testing  -->
    <!-- <pre>

    resAppForm value :

    {{ resAppForm.value | json }}


    resAppForm valid  : {{ resAppForm.valid }}

    </pre> -->

</div>


<div class="modal-overlay-component"></div>
