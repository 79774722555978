<!-- Application Sign out - once an account exists -->
<!-- --- -->
<div *ngIf="showSignoutPopup">
    <div class="bmo-modal bmo-sign-out">

        <a role="button"
           attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
           attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
           (click)="closeModel()"
           class="btn-icon btn-close tooltip-show"
           href="javascript://">
            <span class="icon icon-large close-grey"></span>
        </a>

        <section>

            <div class="flex-maxis-center content-center">

                <!-- Show this image if all fields are saved/completed (if validation success)-->
                <!-- --- -->
                <div *ngIf="valid">
                    <img class="artwork-header" src="assets/img/artwork-locked-avatar.svg" alt="" />
                </div>

                <!-- Show this image and message if any fields are not saved/completed (if validation fails)-->
                <!-- -- -->
                <div *ngIf="!valid">

                    <img class="artwork-header" src="assets/img/artwork-alert-sm.svg" alt="" />

                    <p class="font-bold" [innerHTML]="'modalSignOut.section1Content01' | translate | safeHtml"></p>

                </div>

                <p [innerHTML]="'modalSignOut.section1Content02' | translate | safeHtml"></p>

                <p>
                    <span [innerHTML]="'modalSignOut.section1Content03' | translate | safeHtml"></span>
                    <span>{{applicationNumber}}</span>
                </p>

            </div>

            <div class="form-row form-page-buttons flex-maxis-center">

                <div class="form-col left-side">
                    <button class="secondary" type="submit" translate="button.cancel" (click)="closeModel()"></button>
                </div>
                <div class="form-col right-side">
                    <button class="primary" type="submit" translate="button.signMeOut" (click)="signOut()"></button>
                </div>

            </div>

        </section>

    </div>

    <div class="modal-overlay-component"></div>
</div>
