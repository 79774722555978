import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { LANGUAGES, ACCOUNT_CODES } from '../../config/app-config.constants';
import { Utils } from '../../shared/utils/utils.class';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';

@Component({
    selector: 'app-next-bymail-pdf',
    templateUrl: './next-bymail-pdf.component.html'
})
export class NextBymailPdfComponent implements OnInit, AfterViewInit {
    @ViewChild('pdfContent', {static: true}) pdfContent: ElementRef;
    public accountNo: string;
    public applicationNumber: number;
    public applicantName: string;
    public isProAccount: Boolean = false;
    public lang: string = null;
    public isCurrentLangEnglish: Boolean = false;
    public isCurrentLangFrench: Boolean = false;
    public isOptions: Boolean = false;
    public amlFlag: Boolean = false;
    amlEnabled: boolean;
    public isRIF: boolean = false;
    public accountDetails: any;
    public isCIROEnabled: boolean = false;

    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        private route: ActivatedRoute,
        private configService: ConfigResolverService<any>,
        private translate: TranslateService) { }

    ngOnInit() {

        this.applicationService.setResumeLink(false);  // hide resume application link on Next-bymail

        this.amlEnabled = this.configService.getConfig().AML_ENABLED === 'true' ? true : false;
        this.isCIROEnabled = this.applicationService.getCIROEnabledFeatureFlag();

        if (this.route.snapshot.data) {

            const application = Utils.getNextStepsData();
            const appReferences = this.route.snapshot.data['reference'];
            const accountTypes = this.route.snapshot.data['accountTypes'];

            this.translate.get('pageTitle.completion').subscribe((res) => {
                this.titleService.setTitle(res);
            });

            if (application !== undefined && application.preferredLanguage === LANGUAGES.ENGLISH) {
                this.lang = 'en';
                this.translate.use('en');
            } else if (application !== undefined && application.preferredLanguage === LANGUAGES.FRENCH) {
                this.lang = 'fr';
                this.translate.use('fr');
            }

            // Just append tilte and middle name to the first name.
            let applicantTitle = '';
            if (appReferences && accountTypes.length > 0) {
                const labelName = this.lang === 'en' ? 'productNameEn' : 'productNameFr';
                // application.accountTypeTitle = accountTypes.find(
                //     x => x.type === application.accountType)[labelName];
                applicantTitle = (application.title !== null) ? appReferences.references.titles.find(
                    x => x.code === application.title).label : '';
            }

            application.title = applicantTitle;
            application.middleName = application && application.middleName ? application.middleName : '';

            if (application !== undefined && application !== null) {
                this.applicationNumber = application.applicationNumber;
                this.applicantName = [application.title, application.firstName, application.middleName, application.lastName]
                    .join(' ').trim();
                this.isProAccount = application.isProAccount;
                this.accountDetails = application.accounts;
                for (let i in this.accountDetails) {
                    this.accountNo = this.accountDetails[0].accountNumber; // for Single Account flow
                    this.isRIF = this.accountDetails[i].accountType === ACCOUNT_CODES.RIF ? true : false;
                }
            }

            if (Utils.hasOwnProperty(application, 'amlFlag')) {
                this.amlFlag = application.amlFlag;
            } else {
                //console.log('aml flag is missing');
            }

            if (Utils.hasOwnProperty(application, 'isOptionFlag')) {
                this.isOptions = application.accounts.some(account => account.isOptionFlag === true);
            } else {
                //console.log('isOptions flag is missing');
            }
        }

    }

    ngAfterViewInit() {
        // this.applicationService.domToPdf(this.pdfContent.nativeElement);
    }
}

