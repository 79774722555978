<main class="site-body" *ngIf="isCookieEnabled">
    <div class="bmo-wrapper">
        <div class="bmo-container-flex">
            <router-outlet (activate)='routeChanges()'></router-outlet>
        </div>
    </div>
</main>

<!-- When Cookies are not enabled isCookieEnabled-->
<!-- !isCookieEnabled -->
<main class="site-body"  *ngIf="!isCookieEnabled">
    <div class="bmo-wrapper">
        <div class="bmo-container-flex">
            <div class="flex-fill">
                <div class="container-smallNoMenu content-center">
                    <section>
                        <h1 translate="modalCookieEnabled.title1Modal"></h1>
                        <p [innerHtml]="'modalCookieEnabled.section1Content' | translate | safeHtml"></p>
                        <div class="form-row form-page-buttons content-center">
                            <button class="primary element-center" translate="button.continue"  attr.aria-label="{{ 'button.ariaLabels.continue' | translate }}"
                            (click)="continue()"></button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</main>

<footer  id="footerMyWealthBil" *ngIf="isCookieEnabled">
    <div class="bmo-wrapper">
        <app-footer></app-footer>
    </div>
</footer>
