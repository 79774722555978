<!--
Start of DoubleClick Floodlight Tag: Please do not remove
Activity name of this tag: WM_ILSD_STEP<x>_OLAP_Consents_En
This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
Creation Date: 07/20/2018
-->

<div *ngIf="show">
    <iframe 
        [src]="constructedUrl"
        height="1" 
        width="1"
        frameborder="0"
        style="display:none">
    </iframe>

    <!-- This block will get executed only when the JS is disabled, Dev team feels this is not required to be placed 
    in an angular application, as the application itself will not load when JS is disabled.-->
    <!--<noscript>
        <iframe
            [src]="getUrl(true)"
            height="1" 
            width="1"
            frameborder="0"
            style="display:none">
        </iframe>
    </noscript>-->

</div>
<!-- End of DoubleClick Floodlight Tag: Please do not remove -->