<!-- <app-flood-light></app-flood-light> -->

<form #documentUploadForm="ngForm" novalidate>

    <div class="container-noMenuView">

        <section id="documentUploadSection">

            <!-- <div class="alert alert-danger" role="alert" *ngIf="isReferenceCallFailed">
                <p>
                    <strong translate="documentUpload.errors.criticalError"></strong><br />
                    <span translate="documentUpload.errors.criticalErrorDescription"></span>
                </p>

            </div> -->
            <app-critical-error *ngIf="isReferenceCallFailed"></app-critical-error>

            <!-- Doc upload only to verify identity (new to BMO) -->
            <section *ngIf="showIdentityUpload">
                <h1  translate="documentUpload.titleSection"></h1>

                <p [innerHTML]="'documentUpload.descriptionSection01' | translate | safeHtml"></p>
                <p *ngIf="amlEnabled && showIdentityUpload" translate="documentUpload.descriptionSection01_aml"></p>
                <p *ngIf="amlEnabled && showIdentityUpload" translate="documentUpload.descriptionSection01_aml_subHeading"></p>

                <ul id="showIdentityUploadSection"  class="check-list">
                    <li [innerHTML]="'documentUpload.requiredItem01' | translate | safeHtml"></li>
                    <li *ngIf="!amlEnabled" [innerHTML]="'documentUpload.requiredItem02' | translate | safeHtml"></li>
                    <li *ngIf="amlEnabled" [innerHTML]="'documentUpload.requiredItem02_aml' | translate | safeHtml"></li>
                    <li *ngIf="!amlEnabled" [innerHTML]="'documentUpload.requiredItem03' | translate | safeHtml"></li>
                    <li *ngIf="amlEnabled" [innerHTML]="'documentUpload.requiredItem03_aml' | translate | safeHtml"></li>
                    <li *ngIf="!amlEnabled" [innerHTML]="'documentUpload.requiredItem04' | translate | safeHtml"></li>
                    <li *ngIf="amlEnabled" [innerHTML]="'documentUpload.requiredItem04_aml' | translate | safeHtml"></li>

                </ul>
                <div class="alert alert-yellow">
                    <p>
                        <span translate="documentUpload.warningMessage01"></span>
                    </p>
                </div>
                <p class="font-bold" translate="documentUpload.descriptionSection02a"></p>
                <p [innerHTML]="'documentUpload.descriptionSection02b' | translate | safeHtml"></p>

                <div class="more-info-wrapper add-margin t16px b24px">

                    <a role="button"  aria-controls="scanHelp"
                        aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'documentUpload.ariaLabels.iconLabel' | translate }}">
                        <span class="btn-icon-text-left" translate="documentUpload.labels.helpScan"></span>
                        <span class="icon icon-large chevron-down-blue"></span>
                    </a>

                    <div class="more-info-content new-bmo-card-content" id="scanHelp">
                        <p [innerHTML]="'documentUpload.contextualHelp.p1' | translate | safeHtml"></p>
                        <p [innerHTML]="'documentUpload.contextualHelp.p2' | translate | safeHtml"></p>
                        <p [innerHTML]="'documentUpload.contextualHelp.p3' | translate | safeHtml"></p>
                        <p>
                            <a role="button" id="scanHelpClose" aria-controls="scanHelpClose"
                                aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                <span class="icon icon-large chevron-up-blue"></span>
                            </a>
                        </p>

                    </div>

                </div>

                <div class="bmo-container-flex bmo-container-docupload" *ngIf="showIdentityUpload" >
                    <div class="flex-50">

                        <p class="font-bold no-margin" translate="documentUpload.labels.groupA" id="ariaGroupA"></p>

                        <div class="form-wrapper">

                            <div class="form-row">
                                <div class="flex-90 form-col">
                                    <div class="form-field">
                                        <div class="form-label-group">
                                            <select aria-describedby="ariaGroupA" id="selectDocumentListA"
                                                    name="selectDocumentListA"
                                                    (change)="fetchFileName($event.target.value,'A')"
                                                    [(ngModel)]="documentUpload.documentA"
                                                    #selectDocumentGroupACtrl="ngModel"
                                                    required
                                                    data-ana="mywealth:doc upload:group a">

                                                <option *ngFor="let doc of docListA"
                                                        [value]="doc.code"
                                                        [selected]="doc.selected"
                                                        [attr.data-ana]="'mywealth:doc upload:group a ' + doc.label"> {{doc.label}} </option>
                                            </select>
                                            <label class="slide-label" for="selectDocumentListA" [ngClass]="{'error-field': selectDocumentGroupACtrl.errors && selectDocumentGroupACtrl.touched || (isFormSubmit && selectDocumentGroupACtrl.errors), 'active-label': selectDocumentGroupACtrl.value}">
                                                <span class="label-text" [innerHTML]="'documentUpload.labels.selectDocument' | translate | safeHtml"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="error-message" *ngIf="false" role="alert" >
                                        <span translate="documentUpload.errors.selectionRequired"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Document upload - Show this row only if a selection has been made! -->
                            <div class="form-row" *ngIf="selectDocumentGroupACtrl.value != ''">

                                <div class="upload-card-wrapper">
                                    <div class="upload-card" appDragDrop (onDocDropped)="onListAfileSelected($event,'drag')">

                                        <!-- Hide this DIV when when sowing the uploaded document -->
                                        <div class="note-upload">
                                            <p class="note-upload">
                                                <span [innerHTML]="'documentUpload.labels.noteDrag' | translate | safeHtml"></span>
                                                <a href="javascript://" (click)="fileInputGroupA.click()" translate="documentUpload.labels.linkBrowse" data-ana="mywealth:doc upload:browse" attr.aria-label="{{ 'documentUpload.ariaLabels.browse' | translate }}"></a>
                                                <span [innerHTML]="'documentUpload.labels.noteToUpload' | translate | safeHtml"></span>
                                                <!-- HIDDEN element that does the "upload process" -->
                                                <input class="hidden-element" aria-hidden="true" type="file" #fileInputGroupA id="uploadA" name="uploadA" (change)="onListAfileSelected($event,'browse')" accept="application/pdf,image/tiff,image/png,image/jpeg"/>
                                            </p>
                                            <p class="note-constraints" translate="documentUpload.labels.selectDocumentDescription_RRIF"></p>
                                            <p class="note-constraints" translate="documentUpload.labels.noteConstraints"></p>
                                        </div>
                                        <!-- Hide this DIV when there is no document uploaded -->
                                        <div class="list-file justify-space-between" *ngIf="listA.name != null && listA.name != ''">
                                            <span class="icon-inline icon-document">{{listA.name}}</span>&nbsp;
                                            <a href="javascript://" translate="documentUpload.labels.linkRemove" (click)="removeFile('A')" data-ana="mywealth:doc upload:remove" role="button" id="docUploadRemoveBtn1"></a>
                                        </div>

                                    </div>
                                </div>
                                <div class="error-message" *ngIf="listA.removed" role="alert" >
                                    <span translate="documentUpload.errors.RRIFremoved"></span>
                                </div>
                                <div class="error-message" *ngIf="listA.error" role="alert" >
                                    <span translate="documentUpload.errors.notPDFError"></span>
                                </div>
                                <div class="success-message" *ngIf="listA.success" role="alert" >
                                    <span translate="documentUpload.errors.successUpload"></span>
                                </div>

                            </div>
                            <!-- END - Document upload -->

                        </div>

                    </div>
                    <div class="flex-50">

                        <p class="font-bold no-margin" translate="documentUpload.labels.groupB" id="ariaGroupB"></p>

                        <div class="form-wrapper">

                            <div class="form-row">
                                <div class="flex-90 form-col">
                                    <div class="form-field" >
                                        <div class="form-label-group">
                                            <select id="selectDocumentListB"
                                                    name="selectDocumentListB"
                                                    aria-describedby="ariaGroupB"
                                                    (change)="fetchFileName($event.target.value,'B')"
                                                    [(ngModel)]="documentUpload.documentB"
                                                    #selectDocumentGroupBCtrl="ngModel"
                                                    required
                                                    data-ana="mywealth:doc upload:group b">

                                                <option *ngFor="let doc of docListB"
                                                        [value]="doc.code"
                                                        [selected]="doc.selected"
                                                        [attr.data-ana]="'mywealth:doc upload:group b ' + doc.label"> {{doc.label}} </option>
                                            </select>
                                            <label class="slide-label" for="selectDocumentListB" [ngClass]="{'error-field': selectDocumentGroupBCtrl.errors && selectDocumentGroupBCtrl.touched || (isFormSubmit && selectDocumentGroupBCtrl.errors), 'active-label': selectDocumentGroupBCtrl.value}">
                                                <span class="label-text" [innerHTML]="'documentUpload.labels.selectDocument' | translate | safeHtml" ></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="error-message" *ngIf="false" role="alert" >
                                        <span translate="documentUpload.errors.selectionRequired"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Document upload - Show this row only if a selection has been made! -->
                            <div class="form-row" *ngIf="selectDocumentGroupBCtrl.value != ''">
                                <div class="upload-card-wrapper">
                                    <div class="upload-card" appDragDrop (onDocDropped)="onListBfileSelected($event,'drag')">
                                        <!-- Hide this DIV when when sowing the uploaded document -->
                                        <div class="note-upload">
                                            <p class="note-upload">
                                                <span [innerHTML]="'documentUpload.labels.noteDrag' | translate | safeHtml"></span>
                                                <a href="javascript://" (click)="fileInputGroupB.click()" translate="documentUpload.labels.linkBrowse" data-ana="mywealth:doc upload:browse" attr.aria-label="{{ 'documentUpload.ariaLabels.browse' | translate }}"></a>
                                                <span [innerHTML]="'documentUpload.labels.noteToUpload' | translate | safeHtml"></span>
                                                <!-- HIDDEN element that does the "upload process" -->
                                                <input class="hidden-element" aria-hidden="true" type="file" #fileInputGroupB id="uploadB" name="uploadB" (change)="onListBfileSelected($event,'browse')" accept="application/pdf,image/tiff,image/png,image/jpeg" />
                                            </p>
                                            <p class="note-constraints" translate="documentUpload.labels.selectDocumentDescription_RRIF"></p>
                                            <p class="note-constraints" translate="documentUpload.labels.noteConstraints"></p>
                                        </div>
                                        <!-- Hide this DIV when there is no document uploaded -->
                                        <div class="list-file justify-space-between" *ngIf="listB.name != null && listB.name != ''">
                                            <span class="icon-inline icon-document">{{listB.name}}</span>&nbsp;
                                            <a href="javascript://" translate="documentUpload.labels.linkRemove" (click)="removeFile('B')"  data-ana="mywealth:doc upload:remove" role="button" id="docUploadRemoveBtn2"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="error-message" *ngIf="listB.removed" role="alert" >
                                    <span translate="documentUpload.errors.RRIFremoved"></span>
                                </div>
                                <div class="error-message" *ngIf="listB.error" role="alert" >
                                    <span translate="documentUpload.errors.notPDFError"></span>
                                </div>
                                <div class="success-message" *ngIf="listB.success" role="alert" >
                                    <span translate="documentUpload.errors.successUpload"></span>
                                </div>
                            </div>
                            <!-- END - Document upload -->

                        </div>
                    </div>
                </div>
            </section>

            <!-- Doc upload for a void cheque -->
            <section *ngIf="showVoidCheqUpload">
                <h1 translate="documentUpload.titleSection_RRIF"></h1>
                    <p  translate="documentUpload.descriptionSection01_RRIF"></p>

                    <p translate="documentUpload.Section01SubHeading_RRIF" class="font-bold"></p>
                    <p  translate="documentUpload.Section01SubHeadingdescription_RRIF"></p>
                    <ul class="check-list">
                        <li [innerHTML]="'documentUpload.Section01requiredItem01_RRIF' | translate | safeHtml"></li>
                        <li [innerHTML]="'documentUpload.Section01requiredItem02_RRIF' | translate | safeHtml"></li>
                        <li [innerHTML]="'documentUpload.Section01requiredItem03_RRIF' | translate | safeHtml"></li>
                        <li [innerHTML]="'documentUpload.Section01requiredItem04_RRIF' | translate | safeHtml"></li>
                        <li [innerHTML]="'documentUpload.Section01requiredItem05_RRIF' | translate | safeHtml"></li>
                    </ul>


                    <p translate="documentUpload.Section02SubHeading_RRIF" class="font-bold"></p>
                    <p  translate="documentUpload.Section02SubHeadingdescription_RRIF"></p>

                    <ul class="check-list">
                        <li [innerHTML]="'documentUpload.Section02requiredItem01_RRIF' | translate | safeHtml"></li>
                        <li [innerHTML]="'documentUpload.Section02requiredItem02_RRIF' | translate | safeHtml"></li>
                        <li [innerHTML]="'documentUpload.Section02requiredItem03_RRIF' | translate | safeHtml"></li>
                        <li [innerHTML]="'documentUpload.Section02requiredItem04_RRIF' | translate | safeHtml"></li>
                    </ul>


                    <div class="bmo-container-flex bmo-container-docupload">
                        <div class="flex-50">
                            <div class="form-wrapper">

                                <div class="form-row" >

                                    <div class="upload-card-wrapper">
                                        <div class="upload-card" appDragDrop (onDocDropped)="onListCfileSelected($event,'drag')">

                                            <!-- Hide this DIV when when sowing the uploaded document -->
                                            <div class="note-upload">
                                                <p class="note-upload">
                                                    <a href="javascript://"
                                                    (click)="fileInputGroupC.click()"
                                                    translate="documentUpload.labels.selectDocument_RRIF"
                                                    attr.aria-label="{{ 'documentUpload.ariaLabels.browse' | translate }}"></a>
                                                    <span [innerHTML]="'documentUpload.labels.noteToUpload_RRIF' | translate | safeHtml"></span>
                                                    <!-- HIDDEN element that does the "upload process" -->
                                                    <input class="hidden-element" aria-hidden="true" type="file" #fileInputGroupC id="uploadRRIF" name="uploadRRIF"
                                                    (change)="onListCfileSelected($event,'browse')" accept="application/pdf,image/tiff,image/png,image/jpeg"/>
                                                </p>
                                                <p class="note-constraints" translate="documentUpload.labels.selectDocumentDescription_RRIF"></p>
                                                <p class="note-constraints" translate="documentUpload.labels.noteConstraints_RRIF"></p>
                                            </div>

                                            <!-- Hide this DIV when there is no document uploaded -->

                                            <div class="list-file justify-space-between" *ngIf="listC.name != null && listC.name != ''">
                                                <span class="icon-inline icon-document">{{listC.name}}</span>&nbsp;
                                                <a href="javascript://" translate="documentUpload.labels.linkRemove" (click)="removeFile('C')" role="button" id="docUploadRemoveBtnRRIF"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="error-message" *ngIf="listC.removed" role="alert" >
                                        <span translate="documentUpload.errors.RRIFremoved"></span>
                                    </div>
                                    <div class="error-message" *ngIf="listC.error" role="alert" >
                                        <span translate="documentUpload.errors.notPDFError_RRIF"></span>
                                    </div>
                                    <div class="success-message" *ngIf="listC.success" role="alert" >
                                        <span translate="documentUpload.errors.successUpload"></span>
                                    </div>

                                </div>
                                <!-- END - Document upload -->

                            </div>
                        </div>

                    </div>
            </section>





        </section>

        <section id="submitDocumentButtonSection">

            <div class="form-row form-page-buttons">
                <!-- <div class="form-col left-side">
                    <button class="secondary" type="button" (click)="onCancel()" translate="button.cancel" data-ana="mywealth:doc upload:cancel"></button>
                </div> -->
                <div class="form-col left-side"></div>
                <div class="form-col right-side">

                    <button *ngIf="isDataRationalization4Enabled" attr.aria-label="{{ 'button.ariaLabels.skip' | translate }}"
                    class="tertiary" id="docSkipButton" [disabled]="listA.success || listB.success || listC.success"  (click)="skipDocumentUpload()" type="button"  translate="button.skip"></button>

                    <button attr.aria-label="{{ 'button.ariaLabels.submitButton' | translate }}"
                        [ngClass]="(disableSubmitDocument) ? 'disabled' : 'primary'"
                        [attr.aria-disabled]="disableSubmitDocument ? true : false" translate="button.submitDocument"
                        (click)="submitDocuments()" data-ana="mywealth:doc upload:submit docs"
                        id="submitDocument"></button>

                </div>
            </div>

        </section>

    </div>

</form>

<div class="bmo-modal bmo-doc-upload" role="dialog">
    <form>
        <a role="button"
           attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
           attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
           class="btn-icon btn-close tooltip-show"
           (click)="cancelSubmitDocument()"
           href="javascript://"
           data-ana="mywealth:doc upload:done:close x">
            <span class="icon icon-large close-grey"></span>
        </a>

        <!-- SHOW this div when the documents for confirmation message - before starting the upload -->
        <!-- --- -->
        <div class="container-modalView content-left" *ngIf="confirmUpload" role="status">

            <div aria-atomic="true">
                <section aria-live="polite">
                    <h1 translate="documentUpload.modalDocUpload.modalConfirmationTitle"></h1>
                    <p translate="documentUpload.modalDocUpload.modalConfirmationDescription"></p>
                </section>
            </div>

            <section>
                <div class="form-wrapper no-margin">
                    <div class="form-row form-page-buttons justify-space-between">
                        <div class="form-col left-side">
                            <button class="secondary" type="submit" translate="button.no" (click)="cancelSubmitDocument()" data-ana="mywealth:doc upload:confirm submit:no"></button>
                        </div>
                        <div class="form-col right-side">
                            <button class="primary" type="button" translate="button.yes" (click)="startUploadDocs()" data-ana="mywealth:doc upload:confirm submit:yes"></button>
                        </div>
                    </div>
                </div>
            </section>

        </div>

        <!-- SHOW this div when the documents are uploading -->
        <!-- --- -->
        <div aria-atomic="true">
            <div aria-live="polite" role="alert">
                <div class="content-left" *ngIf="uploadInProgress" role="status">

                    <div class="loading spinner"></div>
                        <section>
                            <h1 translate="documentUpload.modalDocUpload.modalLoadingTitle"></h1>
                            <p translate="documentUpload.modalDocUpload.modalLoadingDescription"></p>
                        </section>
                    
                </div>
            </div>
        </div>

        <!-- SHOW this div when the documents have finished uploading -->
        <!-- --- -->
        <div class="container-modalView content-left"  role="status"  aria-live="polite" *ngIf="uploadComplete">

            <div class="loading complete"></div>

            <section>
                <h1 translate="documentUpload.modalDocUpload.modalCompleteTitle"></h1>
                <p translate="documentUpload.modalDocUpload.modalCompleteDescription"></p>
            </section>

            <section>
                <div class="form-wrapper no-margin">
                    <div class="form-row form-page-buttons justify-space-between">
                        <div class="form-col left-side">
                            <button class="primary element-center" type="submit" translate="button.continue" (click)="onCancel()" data-ana="mywealth:doc upload:done:continue"></button>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </form>
</div>

