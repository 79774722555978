<div class="flex-elements form-col">
    
    <div class="form-radio-checks radio-buttons"
        [ngClass]="{'error-field':selectedCitizenshipCtrl.invalid && selectedCitizenshipCtrl.touched || (isFormSubmit && selectedCitizenshipCtrl.errors), 
        'active-label':selectedCitizenshipCtrl.value}">
        <input [id]="selectedOption1"
            [name]="selectedOption1"
            [attr.data-ana]="dataAna +' ' +'yes'"
            #selectedCitizenshipCtrl="ngModel"
            [(ngModel)]="value"
            type="radio"
            [value]="valueOne" 
            [appErrorAccess]='accessId'
            [disabled]="isDisabled"
            required />
        <label class="prefix" [for]="selectedOption1" translate="personalInformation.labels.yesCanadianCitizenship"></label>
    </div>
    
    <div class="form-radio-checks radio-buttons" 
        [ngClass]="{'error-field':selectedCitizenshipCtrl.invalid && selectedCitizenshipCtrl.touched || (isFormSubmit && selectedCitizenshipCtrl.errors), 
        'active-label':selectedCitizenshipCtrl.value}">      
        <input [id]="selectedOption2"
            [name]="selectedOption2"
            [attr.data-ana]="dataAna +' ' +'no'"
            #selectedCitizenshipCtrl="ngModel"
            [(ngModel)]="value"
            type="radio"
            [value]="valueTwo" 
            [disabled]="isDisabled"
            [appErrorAccess]='accessId'
            required />
        <label class="prefix" [for]="selectedOption2" translate="personalInformation.labels.noCanadianCitizenship"></label>
        <!-- must updated translate labels" -->
    </div>
    
    <div [id] ="accessId['id']" class="error-message" *ngIf="selectedCitizenshipCtrl.errors && selectedCitizenshipCtrl.dirty || (isFormSubmit && selectedCitizenshipCtrl.errors)">
        <span [translate]="errorMessage"></span>
    </div>
    
</div>