<form>

    <section>

        <div class="container-smallNoMenu content-center">
        
            <div class="celebration-img application-denied"></div>
        
            <h1 translate="applicationApprovalDeclined.titleDeclined"></h1>

            <div class="note-white">
                <p translate="applicationApprovalDeclined.descriptionDeclined01"></p>
                <p translate="applicationApprovalDeclined.descriptionDeclined02"></p>
                <p translate="applicationApprovalDeclined.descriptionDeclined03"></p>
                <p translate="applicationApprovalDeclined.descriptionDeclined04"></p>
            </div>

            <div class="form-wrapper">
                <div class="form-row form-single-button flex-maxis-center">
                
                    <button class="primary" type="submit" translate="button.close"></button>

                </div>
            </div>
        
        </div>

    </section>

</form>

<!-- SHOW this modal confirmation when the Close button is clicked -->
<!-- --- -->
<div class="bmo-modal bmo-denied">
    <form>
        <a role="button"
           attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
           attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
           class="btn-icon btn-close tooltip-show"
           href="javascript://">
            <span class="icon icon-large close-grey"></span>
        </a>
        <div class="modal-content-scroll no-margin content-left">

            <section>

                <div class="header-img alert-sm"></div>
                <div class="form-wrapper no-margin">

                    <div class="form-row flex-maxis-center content-center">
                        <h2 translate="applicationApprovalDeclined.modalDeclined.modalTitle"></h2>
                    </div>

                    <div class="form-row form-page-buttons flex-maxis-center">

                        <div class="form-col left-side">
                            <button class="secondary" type="submit" translate="button.yes"></button>
                        </div>
                        <div class="form-col right-side">
                            <button class="primary" type="submit" translate="button.no"></button>
                        </div>

                    </div>
                </div>

            </section>

        </div>
    </form>
</div>
