import { TranslateService } from '@ngx-translate/core';
import { AccountTypeService } from './../../service/account-type.service';
import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../service/application.service';
import { ConfigResolverService } from '../../../config/external-config/service/config-resolver.service';

@Component({
  selector: 'app-account-name',
  templateUrl: './account-name.component.html'
})
export class AccountNameComponent implements OnInit {
  public accountType = [];
  applicationNumber: string = '';
  isBranchAssisted: boolean;

  constructor(private accountTypeService: AccountTypeService, private translateService: TranslateService, private applicationService: ApplicationService, private configService: ConfigResolverService<any>,) { }

  ngOnInit() {
    this.isBranchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
    this.accountTypeService.accountType.subscribe(data => {
      this.accountType = data;
      // this.translateService.get(this.accountType).subscribe(
      //   translation => {
      //     this.accountTypeService.accountTypeTitle = translation;
      //   });
    });

    this.applicationService.applicationNumber$.subscribe((appNum) => {
      if (appNum !== null && appNum !== undefined && appNum !== '') {
        this.applicationNumber = appNum;
      }
    });
 
  }

}
