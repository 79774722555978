 <!-- #AD flow -->
 <ul class="help-drop-down" 
 id="helpApplicationNavID" 
 aria-labelledby="helpApplicationShowID" 
 #dropdownMenu 
 *ngIf="showNav && isSubProductAD"
 aria-hidden="false">
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help" 
         (click)="hideHelpNav()" 
         href="javascript://" 
         [attr.aria-hidden]="!showNav" 
         aria-controls="helpApplicationNavID" 
         attr.aria-label="{{ 'button.close' | translate }}"
         role="button" id="hideNav">
             <span class="font-bold"  translate="header.Help">
                 <span class="icon icon-small chevron-up-blue" aria-hidden="true"></span>
             </span>
         </a>
     </li>
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help:faq" 
         (click)="openFaq()" 
         attr.aria-label="{{ 'header.labels.FAQ' | translate }}">
             <span class="icon icon-small book" aria-hidden="true"></span>
             <span translate="header.FAQ"></span>
             <span class="icon icon-small external" aria-hidden="true"></span>
         </a>
     </li>
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help:how advicedirect works" 
         (click)="showAdWorks()"
         role="button" id="adWorks"
         attr.aria-label="{{ 'header.labels.aDWorks' | translate }}">
             <span class="icon icon-small how-ad-works" aria-hidden="true"></span>
             <span translate="header.aDWorks"></span>
             <span class="icon icon-small external" aria-hidden="true"></span>
         </a>
     </li> 
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help:let us contact you" 
         (click)="openLetUsContactYou()"
         attr.aria-label="{{ 'header.labels.contactYou' | translate }}">
             <span class="icon icon-small contact-you" aria-hidden="true"></span>
             <span translate="header.contactYou"></span>
             <span class="icon icon-small external" aria-hidden="true"></span>
         </a>
     </li>
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help:call us" 
         (click)="hideContactUsEnabled()"
         attr.aria-label="{{ 'header.labels.contactUs' | translate }}"
         role="button" id="contactUs" (keydown.Tab)="tabCloseNav()">
             <span class="icon icon-small contact-us" aria-hidden="true"></span>
             <span translate="header.contactUs"></span>
             <span class="icon icon-small external" aria-hidden="true"></span>
         </a>
     </li>
 </ul> 

<!--<ng-template #sdTemplate> -->
 <ul class="help-drop-down"  
 id="helpApplicationNavID" 
 aria-labelledby="helpApplicationShowID"  
 #dropdownMenu 
 *ngIf="showNav && !isSubProductAD" 
 aria-hidden="false">
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help" 
         (click)="hideHelpNav()" 
         href="javascript://" 
         [attr.aria-hidden]="!showNav" 
         attr.aria-label="{{ 'button.close' | translate }}"
         role="button" id="hideHelp"
         aria-controls="helpApplicationNavID">
             <span class="font-bold"  translate="header.Help">
                 <span class="icon icon-small chevron-up-blue" aria-hidden="true"></span>
             </span>
         </a>
     </li>
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help:faq" 
         (click)="openFaq()"
         attr.aria-label="{{ 'header.labels.FAQ' | translate }}">
             <span class="icon icon-small book" aria-hidden="true"></span>
             <span translate="header.FAQ"></span>
             <span class="icon icon-small external" aria-hidden="true"></span>
         </a>
     </li>
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help:let us contact you" 
         (click)="openLetUsContactYou()"
         attr.aria-label="{{ 'header.labels.contactYou' | translate }}">
             <span class="icon icon-small contact-you" aria-hidden="true"></span>
             <span translate="header.contactYou"></span>
             <span class="icon icon-small external" aria-hidden="true"></span>
         </a>
     </li>
     <li>
         <a href="javascript://" 
         data-ana="investorline:wealth:onboarding:help:call us" 
         (click)="hideContactUsEnabled()"
         attr.aria-label="{{ 'header.labels.contactUs' | translate }}"
         role="button" id="contactUs" (keydown.Tab)="tabCloseNav()">
             <span class="icon icon-small contact-us" aria-hidden="true"></span>
             <span translate="header.contactUs"></span>
             <span class="icon icon-small external" aria-hidden="true"></span>
         </a>
     </li>
 </ul>
<!-- </ng-template>  -->

<!-- #CDB Account -->
<ul class="cdb-drop-down" 
id="cdbOlbAccountNavID" 
aria-labelledby="cdbOlbAccountShowID" 
#dropdownMenu 
*ngIf="showCdbOlbNav"
aria-hidden="false">
    <li>
        <a href="javascript://" 
        data-ana="investorline:wealth:onboarding:cdbOlbAccount:first name" 
        (click)="closeCdbOlbNav()" (keydown)="closeCdbOlbNavKey($event)"
        href="javascript://" 
        [attr.aria-hidden]="!showCdbOlbNav" 
        aria-controls="cdbOlbAccountNavID"
        attr.aria-label="{{ 'button.close' | translate }}"
        role="button" id="hideCdbOlbAccount">
            <span class="icon icon-small profile-blue" aria-hidden="true"></span>
            <span class="truncate font-bold">
                <span class="truncate-title">{{clientFirstName}}</span>
                <span class="icon icon-small chevron-up-blue" aria-hidden="true"></span>
            </span>
        </a>
    </li>
    <li>
        <a href="javascript://"  (keydown.Tab)="tabCloseCdbOlbNav()" (click)="onExitApplication()" (keypress)="exitApplicationKey($event)"
        data-ana="investorline:wealth:onboarding:cdbOlbAccount:exit application" 
        attr.aria-label="{{ 'header.labels.exitApplication' | translate }}"
		role="button">
            <span class="icon icon-small exit-blue" aria-hidden="true"></span>
            <span translate="header.exitApplication"></span>
        </a>
    </li>
</ul> 