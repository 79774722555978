<div class="form-field">
    <div class="form-label-group">
<input 
        [hidden]="dynamicInputValidation"
        id="{{nameAttr}}"
       type="text"
       role="combobox"
       aria-autocomplete="list"
       [attr.aria-controls]="'aria'+nameAttr+'Control'"
       class="contextual-field"
       [(ngModel)]="query"
       name="{{nameAttr}}"
       (keyup)="filter($event)"
       (click)="openCountryListOnClick()"
       (input)="onChange()"
       (keydown.enter) = "openCountryList() ; false"
       (blur) = "onBlurEvent()"
       (focusout)="onFocusOut($event)"
       [required]="isRequired"
       [disabled]="isDisabled"
       [maxlength]="maxlength"
       #country="ngModel"
       [appErrorAccess]="{ id: errorID, submitted: isFormSubmit}"
       [attr.aria-expanded]="filteredList.length > 0"
        [class.ng-touched.ng-invalid]="country.invalid"
        (keydown)="onComboboxKeyDown($event)"
       />
<label id="aria{{nameAttr}}Label" class="slide-label" for="{{nameAttr}}" [hidden]="dynamicInputValidation"
[ngClass]="{'error-field': country.errors && country.touched || (isFormSubmit && country.errors), 'active-label': country.value}">
    <span class="label-text" translate="{{label | translate}}"></span>
</label>
<div (focusout)="onFocusOut($event)" id="aria{{nameAttr}}Control" class="contextual-search"
 [style.display]="(filteredList.length >0 && listExpandFlag != false) ? 'block' : 'none' " >
    <ul class="list listItems" role="listbox" [attr.aria-labelledby]="'aria'+nameAttr+'Label'">
        <li *ngFor="let item of filteredList ; let i = index"  (keydown)="onComboboxKeyDown($event,i)" >
            <a href="#" (click)="select(item,$event)" tabindex="-1" role="option">{{item.label}}</a>
        </li>
    </ul>
</div>
</div>
</div>
<div role="alert" class="error-message" id="{{errorID}}" *ngIf="country.errors && country.touched || (isFormSubmit && country.errors)">
    <span translate="{{errorMsg}}"></span>
</div>