import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from './../../shared/service/form.service';
import { Component, OnInit } from '@angular/core';
import { Utils } from '../../shared/utils/utils.class';
import { APP_ROUTES, LANGUAGE_ENGLISH, LANGUAGE_FRENCH } from '../../config/app-config.constants';
import { ApplicationService } from '../../shared/service/application.service';
import { AnalyticsService } from '../../shared/service/analytics.service';


@Component({
  selector: 'app-technical-error-session',
  templateUrl: './technical-error-session.component.html'
})
export class TechnicalErrorSessionComponent implements OnInit {

  public lang: string = null;
  url: string;

    constructor(private formService: FormService,
        private translate: TranslateService,
        public titleService: Title,
        private applicationService: ApplicationService,
        private analytics: AnalyticsService
        
    ) {
        this.lang = Utils.getLanguage();
        if (this.lang !== undefined || this.lang !== null) {
            this.translate.use(this.lang);
        } else {
            this.translate.use(LANGUAGE_ENGLISH);
        }
        this.url = '/' + APP_ROUTES.technical_error_session;
    }

  ngOnInit(): void {
    
    if(sessionStorage.getItem('appLaunched')){
      sessionStorage.removeItem('appLaunched');
    }
    this.applicationService.stopUserIdlePing(true);
    this.applicationService.setResumeLink(false);
    this.applicationService.showHelpOnHeaders(false);

  }

  ngAfterViewInit() {
    this.analytics.setPageState(this.url);
    this.analytics.setState("");
  }

}
