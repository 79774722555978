import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { FormService } from '../../shared/service/form.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { Utils } from '../../shared/utils/utils.class';
import { APP_ROUTES, SUCCESS_RESPONSE, URL, ANALYTICS, EMPTY_STRING } from '../../config/app-config.constants';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';

export class AccountInvestmentObjectives {
    income: number = null;
    shortTerm: number = null;
    mediumTerm: number = null;
    longTerm: number = null;
    total: number = 0;
}

@Component({
    selector: 'app-investment-objectives',
    templateUrl: './investment-objectives.component.html'
})

export class InvestmentObjectivesComponent implements OnInit, OnDestroy, AfterViewInit {

    public APP_ROUTES = APP_ROUTES;
    public EMPTY_STRING = EMPTY_STRING;
    public accountInvestmentObjectivesObj: AccountInvestmentObjectives;

    private isCurrentLangEnglish: boolean = false;
    private isCurrentLangFrench: boolean = false;

    public showFinishLater: boolean;
    public isFormSubmit: boolean;
    public criticalError: boolean = false;
    public invalidTotal: boolean = false;
    public isCompleted: boolean = false;

    public url: any;

    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    public accountSetup;
    public appNumber;

    // For accessibility purposes
    @ViewChild('incomeCtrl', {static: true}) incomeCtrl: UntypedFormControl;
    @ViewChild('shortTermCtrl', {static: true}) shortTermCtrl: UntypedFormControl;
    @ViewChild('mediumTermCtrl', {static: true}) mediumTermCtrl: UntypedFormControl;
    @ViewChild('longTermCtrl', {static: true}) longTermCtrl: UntypedFormControl;

    @ViewChild('investmentObjectivesForm', {static: true}) investmentObjectivesForm: NgForm;

    constructor(public formService: FormService,
        private route: ActivatedRoute,
        private titleService: Title,
        private analytics: AnalyticsService,
        private winRef: WindowRefService,
        private applicationService: ApplicationService) {

        this.accountInvestmentObjectivesObj = new AccountInvestmentObjectives();
        this.formService.forms.investmentObjectives.isVisited = true;
        this.formService.setActiveComponent('investmentObjectives', this);

        if (Utils.getLanguage() == 'fr') {
            this.isCurrentLangFrench = true;
        } else {
            this.isCurrentLangEnglish = true;
        }

        this.url = URL.INVESTMENT_OBJECTIVES;
    }

    ngOnInit() {

        /**Title */
        this.titleService.setTitle(Utils.getPageTitleInvestmentObjectives());

        /*HIDE/SHOW Finish later & signout button*/
        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        /*set active form*/
        this.formService.setActiveForm(this.investmentObjectivesForm);

        // already visited form and now visiting back again (Pre=population)
        if (this.formService.forms.investmentObjectives.data != null) {
            this.accountInvestmentObjectivesObj = this.formService.getFormData('investmentObjectives');
        }

        if (!this.accountInvestmentObjectivesObj.total) {
            this.calculateTotal();
        }

        /**Finish later & Sign-out */
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'investmentObjectivesFrm') {
                    this.onFinishLater();
                }
            });


        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {
                this.finishLaterSubscriber.unsubscribe();
            }
        });


        setTimeout(() => {
            this.setGTM();
        }, 200);

    }

    ngAfterViewInit() {
        this.checkFormValidityOnSignOut();
        this.analytics.setPageState(this.url);
    }

    resetComponentObjectAttr() {
    }

    ngOnDestroy() {

        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('investmentObjectives', this.accountInvestmentObjectivesObj);
        this.finishLaterSubscriber.unsubscribe();
    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {

                if (this.investmentObjectivesForm.valid && this.accountInvestmentObjectivesObj.total == 100) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }

    hideAccountSetup() {
        this.accountSetup = false;
    }

    isError(ctrl: any) {
        return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
    }

    calculateTotal() {
        this.accountInvestmentObjectivesObj.total = 0;

        this.validateValues();

        for (const val in this.accountInvestmentObjectivesObj) {
            if (val !== 'total') {
                if (parseInt(this.accountInvestmentObjectivesObj[val], 10) <= 100
                    && parseInt(this.accountInvestmentObjectivesObj[val], 10) >= 0) {
                    // if value is valid
                    this.accountInvestmentObjectivesObj.total += parseInt(this.accountInvestmentObjectivesObj[val], 10);
                } else if (this.accountInvestmentObjectivesObj[val] == null) {
                    this.accountInvestmentObjectivesObj[val] = '';
                }
            }
        }

        this.setInvalidTotal();
    }

    validateValues() {
        for (const val in this.accountInvestmentObjectivesObj) {
            if (this.accountInvestmentObjectivesObj[val] && (val !== 'total')) {
                if (parseInt(this.accountInvestmentObjectivesObj[val], 10) <= 100
                    && parseInt(this.accountInvestmentObjectivesObj[val], 10) >= 0) { // val > 0 && val < 100

                    this.accountInvestmentObjectivesObj[val] = parseInt(this.accountInvestmentObjectivesObj[val], 10);
                } else {
                    this.accountInvestmentObjectivesObj[val] = 0;
                }
            }
        }
    }

    setInvalidTotal() {
        /**Check if total is 100% else throw error.*/
        if (this.accountInvestmentObjectivesObj.total != 100) {
            this.invalidTotal = true;
        } else {
            this.invalidTotal = false;
        }
    }
    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {

        // setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {

            if (this.incomeCtrl.errors) { // gross Annual Income
                document.getElementById('income').focus();

            } else if (this.shortTermCtrl.errors) { // net Liquid Assets
                document.getElementById('shortTerm').focus();

            } else if (this.mediumTermCtrl.errors) { // net Worth
                document.getElementById('mediumTerm').focus();

            } else if (this.longTermCtrl.errors) { // wealth Source
                document.getElementById('longTerm').focus();
            }

        }, 250);

    }


    onSubmit() {
        this.isFormSubmit = true;

        /**Check if total is 100% else throw error.*/
        this.setInvalidTotal();
        this.validateValues();

        if (!this.investmentObjectivesForm.valid || this.invalidTotal) {
            this.setFocus();

        } else {

            this.formService.forms.investmentObjectives.isCompleted = true;
            this.isCompleted = true;
            this.formService.setFormData('investmentObjectives', this.accountInvestmentObjectivesObj);
            this.formService.getPayload().setInvestmentObjectives(this.accountInvestmentObjectivesObj);

            // console.log('on submit object  is--', this.formService.getPayload());

            if (Utils.getIsInSession() === false) {
                this.saveAndReroute();
            } else {
                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        if (res.status === SUCCESS_RESPONSE) {
                            this.saveAndReroute();
                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }
        }
    }

    onFinishLater() {
        this.formService.setFormData('investmentObjectives', this.accountInvestmentObjectivesObj);


        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const investmentKnowledge = this.formService.getFormData('investmentKnowledge');


        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);

        // save current page if form is valid
        if (!this.formService.forms.investmentObjectives.isCompleted) {
            const emptyinvestmentObjectives = new AccountInvestmentObjectives();
            this.formService.getPayload().setInvestmentObjectives(emptyinvestmentObjectives);
        } else {
            if (this.investmentObjectivesForm.valid) {
                this.validateValues();
                this.formService.getPayload().setInvestmentObjectives(this.accountInvestmentObjectivesObj);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true; // show error when save fails on top of page
            });
    }

    saveOnSignOut() {
        this.validateValues();

        this.formService.forms.investmentObjectives.isCompleted = true;
        this.formService.setFormData('investmentObjectives', this.accountInvestmentObjectivesObj);

        // save current page if form is valid
        if (!this.formService.forms.investmentObjectives.isCompleted) {
            const emptyinvestmentObjectives = new AccountInvestmentObjectives();
            this.formService.getPayload().setInvestmentObjectives(emptyinvestmentObjectives);
        } else {
            if (this.investmentObjectivesForm.valid) {
                this.validateValues();
                this.formService.getPayload().setInvestmentObjectives(this.accountInvestmentObjectivesObj);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true; // show error when save fails on top of page
            });
    }

    saveAndReroute() {
        if (Utils.isSubProductAD()) { // Go to Investor Profile
            this.applicationService.saveAndcontinue(this.investmentObjectivesForm, APP_ROUTES.investor_profile);
        } else {
            this.applicationService.saveAndcontinue(this.investmentObjectivesForm, APP_ROUTES.regulatory_disclosures);
        }
    }


    setGTM() {

        const gtmDataLayerForInvestmentObj: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleContactInformation(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.INVESTMENT_OBJECTIVES,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.investment_objectives,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForInvestmentObj);
        // console.log('investment objectives', this.winRef.nativeWindow.dataLayer);

    }
}