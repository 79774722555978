import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-net-worth',
  templateUrl: './net-worth.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class NetWorthComponent {

    @Input() group;
    @Input() model;
    @Input() key;
    @Input() id;
    @Input() isFormSubmit;

  constructor() { }

  isError(ctrl: any) {
    return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
}

}
