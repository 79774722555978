import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../service/application.service';

import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { AccountTypeService } from '../service/account-type.service';

@Injectable()
export class AccountTypesResolver implements Resolve<any> {

    constructor(private applicationService: ApplicationService,
                private route: ActivatedRoute,
                private accountTypeService: AccountTypeService) {}

    resolve(route: ActivatedRouteSnapshot) {

        // Only after the setup call, other calls has to be made...
        return this.accountTypeService.getAccounts();
    }
}
