import { RIGHT_ARROW, LEFT_ARROW, TAB, ESCAPE, SHIFT } from './../../config/app-config.constants';
import { ElementRef, Inject, Directive, OnDestroy, AfterContentInit, Input, NgZone, Injectable, HostListener } from '@angular/core';
import {DOCUMENT} from '@angular/common';


/**
 * Author Dilraj Singh 2018
 * Put [cdkTrapFocus] directive tag in the appropriate place you wish to set up the focus trap. By default the following set up
 * flags are set, pay class attention to which items have brackets and which one doesn't:
 *
 *
 * [cdkTrapFocus]
 *  [cdkTrapFocusInitialItem]="false" cdkTrapFocusNextItem="false" [cdkTrapFocusEscape]="true" [cdkClickOutsideRefocuses]="true"
 *
 *
 *    - if [cdkTrapFocusInitialItem] is set to false by default. If set to 'true' it will refocus on the initial element.
 *        cdkTrapFocusNextItem must be false.
 *        Therefore to work, it must be appear as [cdkTrapFocusInitialItem]="true" cdkTrapFocusNextItem="false"
 *
 *    - if cdkTrapFocusNextItem is set to false by default. Do not declare it is as 'true' to make it work. Simply,
 *        declare a string with no spaces with the first number being positive and the second being negative. E.g. "1,-3"
 *        You can specify how far forward and back too e.g.  [cdkTrapFocusNextItem]="1,-3" will move forward one, or back -3 elements
 *        on tab/click or shift+tab, respectively. Choose numbers that make sense! TabIndex of -1 will be ignored.
 *        cdkTrapFocusInitialItem must be set to 'false'.
 *        Therefore to work, it must be appear as [cdkTrapFocusInitialItem]="false" cdkTrapFocusNextItem="1,-3"
 *
 *    - if [cdkTrapFocusEscape] is set to 'true' by default. If set to 'true' escape will escape the focus trap.
 *    - if [cdkClickOutsideRefocuses] is set to 'true' by default. It refocuses on an outside click event back in to the focus trap region.
 *       If set to 'false' clicking outside of the focus Trap region will lose focus.
 *
 *   Only way to set focus is via className or id named 'selected'.
 *   When this class is set, the focus trap will automatically set focus on that item.
 *
 *   Tab index of -1 are included except for cdkTrapFocusNextItem. (Possible future implementation may ignore it)
 *
 *   Focusable items are based on candidateSelectors list. Feel free to edit it!
 */
@Directive({
    selector: '[cdkTrapFocus]',
    exportAs: 'cdkTrapFocus',
  })
  export class CdkTrapFocus implements OnDestroy, AfterContentInit {
    private _document: Document;

    /** Underlying FocusTrap instance. */
    focusTrap: FocusTrap;

    /** Previously focused element to restore focus to upon destroy when using cdkTrapFocusInitialItem. */
    private _previouslyFocusedElement: HTMLElement | null = null;

    /** Focus on next element upon destroy when using cdkTrapFocusNextItem. */
    private _focusOnNextElement: any | null = null;

    /** used for cdkTrapFocusNextItem so we know if the user shift+tab, also known as shift leffted in */
    private isShiftLeft = false;

    /** Whether the focus trap is active. */
    @Input('cdkTrapFocus')
    get enabled(): boolean { return this.focusTrap.enabled; }
    set enabled(value: boolean) {
      this.focusTrap.enabled = value;

      if (!this.focusTrap.enabled) {
        this.ngOnDestroy();
        return;
      }
     this.ngAfterContentInit();
    }

    /**
     * cdkClickOutsideRefocuses when a click event outside the focustrap area is clicked
     * this will refocus back on to the focus trap's first focusable or selected element.
     * Set to 'true' by default. Set to 'false' to disable'.
     */
    @Input('cdkClickOutsideRefocuses')
    get cdkClickOutsideRefocuses(): boolean { return this._cdkClickOutsideRefocuses; }
    public _cdkClickOutsideRefocuses = true;

    /**
     * Pressing the Escape key will deactive the focus trap, set to 'true' by default.
     */
    @Input('cdkTrapFocusEscape')
    get cdkTrapFocusEscape(): boolean { return this._cdkTrapFocusEscape; }
    private _cdkTrapFocusEscape = true;

    @Input('cdkTrapFocusInitialItem')
    get cdkTrapFocusInitialItem(): boolean { return this._cdkTrapFocusInitialItem; }
    set cdkTrapFocusInitialItem(value: boolean) { this._cdkTrapFocusInitialItem = value; }
    private _cdkTrapFocusInitialItem = false; // if _cdkTrapFocusNextItem has values (i.e. true), then this will not work

    @Input('cdkTrapFocusNextItem')
    get cdkTrapFocusNextItem(): string { return this._cdkTrapFocusNextItem; }
    set cdkTrapFocusNextItem(value: string) { // must be of format "#,-#"
      this._cdkTrapFocusNextItem = false;
      if (value && value.split(',').length === 2) {
        this._focusNextItem_Add = +value.split(',')[0];
        this._focusNextItem_Subtract = +value.split(',')[1];
        if (typeof this._focusNextItem_Add === 'number' && typeof this._focusNextItem_Subtract === 'number'
          && this._focusNextItem_Add > 0 && this._focusNextItem_Subtract < 0) {
          this._cdkTrapFocusNextItem = true;
        }
      }
    }
    private _cdkTrapFocusNextItem; // if cdkTrapFocusInitialItem is true, then then this will not work
    private _focusNextItem_Add;
    private _focusNextItem_Subtract;

    // used this flag inconjunction with _cdkTrapFocusNextItem to get the tab/click or shifttab event once upon initilizing the directive
    private initializedDirective = false;

    constructor(
        private _elementRef: ElementRef,
        private _ngZone: NgZone,
        @Inject(DOCUMENT) _document: any) {
          this._document = _document;
          this.focusTrap = new FocusTrap(this._elementRef.nativeElement, this._ngZone, this._document);
          this.focusTrap._cdkClickOutsideRefocuses = this._cdkClickOutsideRefocuses;
          this.initializedDirective = true;
    }

    ngAfterContentInit() {
      // if _cdkTrapFocusNextItem is true, then this will not work
      if (this._cdkTrapFocusInitialItem && !this._cdkTrapFocusNextItem) {
        this._previouslyFocusedElement = this._document.activeElement as HTMLElement;
      }
      if (this._cdkTrapFocusNextItem && !this._cdkTrapFocusInitialItem) {
        // get currently focused item just like in _cdkTrapFocusInitialItem
        this._focusOnNextElement = this._document.activeElement as HTMLElement;
      }

      return new Promise<boolean>(resolve => {
        this.focusTrap.attachAnchors();
      });
    }

    @HostListener('document:keydown', ['$event'])
    onEscapeKey(event: KeyboardEvent) {
      if (this._cdkTrapFocusEscape && (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === ESCAPE)) {
        this.ngOnDestroy();
      }
      this.reFocusOnClickOutsideEvent(event);
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
      // part of _cdkTrapFocusNextItem
      if (this.initializedDirective) {
        this.initializedDirective = false;
        // event.key and shiftKey are for chrome and firefox, and event.which is for IE
        this.isShiftLeft = event && (event.key === 'Tab' && event.shiftKey  || event.which === SHIFT);
      }
    };

    /**
     * If the user focus somehow is on an element behind the focus trap area in the case of a popup
     * it will refocus on the first element in the focusTrap area unless _cdkClickOutsideRefocuses is set to false
     * This can happen if the user clicks on the browser url and then hits tab.
     */
    reFocusOnClickOutsideEvent(event: KeyboardEvent) {
      if (!this._cdkClickOutsideRefocuses || this._elementRef.nativeElement.contains(event.target)) {
        return;
      }
      this.focusTrap.focusFirstTabbableElement();
    }

    ngOnDestroy() {
      this.focusTrap.destroy();

      if (this._previouslyFocusedElement) {
        this._previouslyFocusedElement.focus();
        this._previouslyFocusedElement = null;
        return;
      }
      if (this._focusOnNextElement) {
        // allCandidates will include elements in the _elementRef so they must be filtered out
        const allCandidates = this._document.querySelectorAll(this.focusTrap.candidateSelectors.join(',')) as NodeListOf<HTMLElement>;

        const candidatesForNextItem = [];
        for (let i = 0, l = allCandidates.length; i < l; i++) {
          if (!this._elementRef.nativeElement.contains(allCandidates[i]) && allCandidates[i].tabIndex >= 0) {

            candidatesForNextItem.push(allCandidates[i]);
          }
        }

        for (let i = 0, l = candidatesForNextItem.length; i < l; i++) {
          // no next item, do nothing
          if ((i + this._focusNextItem_Add) === candidatesForNextItem.length) {
            return;
          }
          // knowing that the next element exists, find the current active element from the candidates list, and ensure it
          // is not a part of our focus trap region
          if (this._focusOnNextElement === candidatesForNextItem[i]) {

            if (this.isShiftLeft) {
              if ((i - 1) <= 0) {
                return; // no previous element, do nothing
              }
              this._focusOnNextElement = candidatesForNextItem[i + this._focusNextItem_Subtract];
              this._focusOnNextElement.focus();
              this._focusOnNextElement = null;
              return;
            } // end of shift+tab check

            this._focusOnNextElement = candidatesForNextItem[i + this._focusNextItem_Add];
            // found it!
            this._focusOnNextElement.focus();
            this._focusOnNextElement = null;
            return;
          }
        } // end of for loop
      }
    }
  }


export class FocusTrap {
  private _startAnchor: HTMLElement | null;
  private _endAnchor: HTMLElement | null;

  public _cdkClickOutsideRefocuses;

  private _candidateSelectedTabIndex: number;
  private _tabbableCurrentlyFocusedIndex: number;
  // private tabbableNodes; // considered making a global but decided otherwise

  /** Whether the focus trap is active. */
  get enabled(): boolean { return this._enabled; }
  set enabled(val: boolean) {
    this._enabled = val;

    if (this._startAnchor && this._endAnchor) {
      this._startAnchor.tabIndex = this._endAnchor.tabIndex = this._enabled ? 0 : -1;
    }
  }
  private _enabled = true;

  constructor(
    private _element: HTMLElement,
    private _ngZone: NgZone,
    public _document: Document
    ) {
  }

  // list of tabbable elements that are considered, can be edited!
  public candidateSelectors = [
    'input',
    'select',
    'a[href]',
    'textarea',
    'button',
    '[tabindex]',
  ];

  /** Destroys the focus trap by cleaning up the anchors. */
  destroy() {
    if (this._startAnchor && this._startAnchor.parentNode) {
      this._startAnchor.parentNode.removeChild(this._startAnchor);
    }

    if (this._endAnchor && this._endAnchor.parentNode) {
      this._endAnchor.parentNode.removeChild(this._endAnchor);
    }

    this._startAnchor = this._endAnchor = null;
  }

  /**
   * Inserts the anchors into the DOM. This is usually done automatically
   * in the constructor, but can be deferred for cases like directives with `*ngIf`.
   */
  attachAnchors(): void {
    if (!this._startAnchor) {
      this._startAnchor = this._createAnchor();
    }

    if (!this._endAnchor) {
      this._endAnchor = this._createAnchor();
    }
    this._ngZone.runOutsideAngular((event: Event) => {
      this._document!.addEventListener('click', () => {
        if (this._cdkClickOutsideRefocuses) {
          this.focusFirstTabbableElement('reload');
        }
      });
      this._element!.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowRight' || event.keyCode === RIGHT_ARROW) {
          this._getNextTabbableElement('ArrowRight');
        }
        if (event.key === 'ArrowLeft' || event.keyCode === LEFT_ARROW) {
          this._getNextTabbableElement('ArrowLeft');
        }

      })
      this._startAnchor!.addEventListener('focus', () => {
        this.focusLastTabbableElement();
      });

      this._endAnchor!.addEventListener('focus', () => {
        this.focusFirstTabbableElement('start');
      });

      if (this._element.parentNode) {
        this._element.parentNode.insertBefore(this._startAnchor!, this._element);
        this._element.parentNode.insertBefore(this._endAnchor!, this._element.nextSibling);
        this.focusFirstTabbableElement();
      }
    });
  }




  _getNextTabbableElement(direction: string) {
    if (this._getFirstTabbableElement(this._element, direction)) {
      this._getFirstTabbableElement(this._element, direction).focus();
    }
  }

  /**
   * Get the specified boundary element of the trapped region.
   * @param bound The boundary to get ('start' or 'end' of trapped region). 'reload' will
   * @returns The boundary element.
   */
  private _getRegionBoundary(bound: 'start' | 'end' | 'reload'): HTMLElement | null {

    if (bound === 'start') {
      return this._getFirstTabbableElement(this._element, 'start');
    }
    if (bound === 'reload') {
      return this._getFirstTabbableElement(this._element, 'reload');
    }
    if (bound === 'end') {
      return this._getLastTabbableElement(this._element);
    }

    // worst case scenario just get focus on the last tabbable element
    this._getLastTabbableElement(this._element);
  }


  /**
   * Focuses the first tabbable element within the focus trap region.
   * @returns Whether focus was moved successfuly.
   */
  focusFirstTabbableElement(action?: string): boolean {
      const redirectToElement = this._getRegionBoundary('start');
      const redirectToElement2 = this._getRegionBoundary('reload');

    if (redirectToElement2 && action !== 'start') {
        redirectToElement2.focus();
        return !!redirectToElement2;
    }

    if (redirectToElement) {
      redirectToElement.focus();
    }

    return !!redirectToElement;
  }

  /**
   * Focuses the last tabbable element within the focus trap region.
   * @returns Whether focus was moved successfuly.
   */
  focusLastTabbableElement(): boolean {
    const redirectToElement = this._getRegionBoundary('end');

    if (redirectToElement) {
      redirectToElement.focus();
    }

    return !!redirectToElement;
  }

  /** Get the first tabbable element from a DOM subtree (inclusive). */
  public _getFirstTabbableElement(root?: HTMLElement, direction?: string): HTMLElement | null {

    this._candidateSelectedTabIndex = null;
    this._tabbableCurrentlyFocusedIndex = null;

    const basicTabbables = [];
    const orderedTabbables = []; // considered using but decided otherwise,not used

    const candidates = root.querySelectorAll(this.candidateSelectors.join(',')) as NodeListOf<HTMLElement>;
    for (let i = 0, l = candidates.length; i < l; i++) {
      const candidate = candidates[i];
      const candidateIndex = candidate.tabIndex;

      if (
        candidateIndex < 0
      //  || (candidate.tagName === 'INPUT' && candidate.type === 'hidden') || candidate.disabled
      ) {
        continue;
      }
      if (candidateIndex >= 0) {

        basicTabbables.push(candidate); 

        // only way to set focus is via className or id
        if (candidate.className === 'selected' || candidate.id === 'selected') {
          this._candidateSelectedTabIndex = i;
        }
        if (this._document.activeElement === candidate) {
          this._tabbableCurrentlyFocusedIndex = basicTabbables.indexOf(candidate);
        }
      } else {
        orderedTabbables.push({
          tabIndex: candidateIndex,
          node: candidate,
        });
      }
    } // end of for loop

    const tabbableNodes = orderedTabbables
    .sort(function(a, b) {
      return a.tabIndex - b.tabIndex;
    })
    .map(function(a) {
      return a.node;
    });

    Array.prototype.push.apply(tabbableNodes, basicTabbables);

    if (tabbableNodes.length > 0 && direction === 'ArrowRight' && this._element.contains(this._document.activeElement)) {
      this._tabbableCurrentlyFocusedIndex++;
      this._tabbableCurrentlyFocusedIndex = (this._tabbableCurrentlyFocusedIndex === tabbableNodes.length) ? 0 : 
          this._tabbableCurrentlyFocusedIndex;
      return tabbableNodes[this._tabbableCurrentlyFocusedIndex];
    }

    if (tabbableNodes.length > 0 && direction === 'ArrowLeft' && this._element.contains(this._document.activeElement)) {
      this._tabbableCurrentlyFocusedIndex--;
      this._tabbableCurrentlyFocusedIndex = (this._tabbableCurrentlyFocusedIndex < 0) ? tabbableNodes.length - 1 : 
          this._tabbableCurrentlyFocusedIndex;
      return tabbableNodes[this._tabbableCurrentlyFocusedIndex];
    }
    if ((tabbableNodes.length > 0 ? tabbableNodes[0] : null) && direction === 'start') {
        return  tabbableNodes[0];
    }

    // reload event
    if (candidates.length > 0 && this._candidateSelectedTabIndex ) {
      return candidates[this._candidateSelectedTabIndex];
    }

    return;
  }

  /** Get the last tabbable element from a DOM subtree (inclusive). */
  private _getLastTabbableElement(root: HTMLElement): HTMLElement | null {
    const tabbableNodes = [];
    const candidates = root.querySelectorAll(this.candidateSelectors.join(',')) as NodeListOf<HTMLElement>;
    for (let i = 0, l = candidates.length; i < l; i++) {
      const candidate = candidates[i];
      const candidateIndex = candidate.tabIndex;

      if (
        candidateIndex < 0
      ) {
        continue;
      }
      if (candidateIndex >= 0) {
        tabbableNodes.push(candidate);
      }
    }
    return tabbableNodes.length > 0 ? tabbableNodes[tabbableNodes.length - 1] : null;
  }

  /** Creates an anchor element. */
  private _createAnchor(): HTMLElement {
    const anchor = this._document.createElement('div');
    anchor.tabIndex = this._enabled ? 0 : -1;
    anchor.classList.add('cdk-visually-hidden');
    anchor.classList.add('cdk-focus-trap-anchor');
    return anchor;
  }
}
